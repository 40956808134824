import React from 'react';
import { MAINTENANCE_ORDER_STATUS } from '../constants';

const showMaintenanceStatusOnBig = (status) => {
  if (status === MAINTENANCE_ORDER_STATUS.NEW.status_code) {
    return (
      <div className="text-orange-400  rounded ">
        <i className="fa-solid fa-bolt mr-1"></i>{' '}
        {MAINTENANCE_ORDER_STATUS.NEW.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.PENDING.status_code) {
    return (
      <div className="text-yellow-400  rounded ">
        <i className="fa-solid fa-circle-pause mr-1"></i>
        {MAINTENANCE_ORDER_STATUS.PENDING.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code) {
    return (
      <div className="text-teal-400  rounded ">
        <i className="fa-solid fa-stamp mr-1"></i>{' '}
        {MAINTENANCE_ORDER_STATUS.APPROVE.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.SUCCESS.status_code) {
    return (
      <div className="text-green-400  rounded ">
        {' '}
        <i className="fa-solid fa-circle-check mr-1"></i>{' '}
        {MAINTENANCE_ORDER_STATUS.SUCCESS.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.CENCLE.status_code) {
    return (
      <div className="text-red-400  rounded ">
        <i className="fa-solid fa-circle-xmark mr-1"></i>
        {MAINTENANCE_ORDER_STATUS.CENCLE.description}
      </div>
    );
  }
  return <div className="text-gray-400  rounded ">{'-'}</div>;
};

export default showMaintenanceStatusOnBig;
