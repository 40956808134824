import React from 'react';
import _ from 'lodash';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
} from '@mui/material';
import { Check } from 'react-feather';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Link } from 'react-router-dom';

import { currencyFormatter } from '../../../utils/functions';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const BillTable = ({
  bills,
  page,
  size,
  total,
  handleChangePage = () => {},
  handleChangeRowsPerPage = () => {},
  handleDelete = () => {},
  handleSuccess = () => {},
  handlePrint = () => {},
}) => {
  const renderEachBookingData = (eachBooingData) => {
    if (eachBooingData?.from_vehicle_schedule) {
      return <div></div>;
    }
    return (
      <div>
        {' '}
        {eachBooingData?.running_number}{' '}
        {eachBooingData?.individual_input?.job?.name}{' '}
        {dayjs(eachBooingData?.date).format('D MMM BB')}
      </div>
    );
  };

  const renderBookingData = (bookingData) =>
    _.map(bookingData, (eachBooking) => renderEachBookingData(eachBooking));

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">วันที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">เลขที่บิล</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ลูกค้า / งาน</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold"> ราคา</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(bills) ? (
              bills.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {(page - 1) * size + index + 1}
                  </TableCell>{' '}
                  <TableCell>
                    {dayjs(row?.date)?.format('D MMM BBBB')}
                  </TableCell>
                  <TableCell>
                    <div className="">{row?.invoice_number}</div>
                    <div className="flex justify-center">
                      {row?.completed && (
                        <div className="flex px-1 bg-green-300 rounded-md">
                          <Check size="16" className="self-center mx-1" />
                          <div className="self-center mx-1">สำเร็จแล้ว</div>
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="font-semibold">
                      {row?.customer?.short
                        ? row?.customer?.short
                        : row?.customer?.name}
                    </div>
                    {renderBookingData(row?.bookings)}
                  </TableCell>{' '}
                  <TableCell>{currencyFormatter.format(row?.price)}</TableCell>
                  <TableCell>
                    <div className="flex flex-wrap gap-1">
                      <Link to={`bill/view/${row?._id}`}>
                        <Button variant="contained" size="small" color="info">
                          รายละเอียด
                        </Button>
                      </Link>{' '}
                      <Link to={`bill/edit/${row?._id}`}>
                        <Button
                          variant="contained"
                          size="small"
                          color="warning"
                        >
                          แก้ไข
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        disabled={row?.completed}
                        onClick={() => handleDelete(row?._id)}
                      >
                        ลบ
                      </Button>
                    </div>
                    <div className="flex flex-wrap gap-1 my-2">
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => handlePrint(row)}
                      >
                        พิมพ์
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        disabled={row?.completed}
                        onClick={() => handleSuccess(row?._id)}
                      >
                        สำเร็จ
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

BillTable.propTypes = {
  bills: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleToInfoPage: PropTypes.func,
  handleToEditPage: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSuccess: PropTypes.func,
};

export default BillTable;
