/* eslint-disable import/no-unresolved */
import React from 'react';
import _ from 'lodash';

import Chart from 'react-apexcharts';

export default function BillBookingChart({ data }) {
  const NewProductTransactionDay = _.sortBy(
    _.map(data?.analyzed, (e) => {
      const data = {
        total_price: e?.total_price,
        date: e?._id?.month,
      };
      return data;
    }),
    ['date'],
  );
  const NewPickupOrderTransactionDay = _.sortBy(
    _.map(data?.analyzed, (e) => {
      const data = {
        count: e.count,
        date: e?._id?.month,
      };
      return data;
    }),
    ['date'],
  );

  const generateDayWiseTimeSeries = (count) => {
    let i = 0;
    const series = [];

    while (i < count) {
      series.push([
        NewProductTransactionDay[i].date,
        NewProductTransactionDay[i].total_price?.toFixed(2),
      ]);
      i += 1;
    }
    console.log('series', series);
    return series;
  };
  const generatePickupWiseTimeSeries = (count) => {
    let i = 0;
    const series = [];

    while (i < count) {
      series.push([
        NewPickupOrderTransactionDay[i].date,
        NewPickupOrderTransactionDay[i].count?.toFixed(2),
      ]);
      i += 1;
    }
    console.log('series', series);
    return series;
  };

  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    series: [
      {
        name: 'รายได้',
        data: generateDayWiseTimeSeries(_.size(NewProductTransactionDay)),
      },
      {
        name: 'จำนวนรายการ',
        data: generatePickupWiseTimeSeries(
          _.size(NewPickupOrderTransactionDay),
        ),
      },
    ],

    title: {
      text: 'ภาพรวม',
      align: 'left',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: [
      {
        labels: {
          offsetX: 14,
          offsetY: -5,
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        opposite: true,
        tickAmount: 4,
        seriesName: 'Linear',
        labels: {
          formatter: (value) => `${value} รายการ`,
        },
      },
    ],
  };

  return (
    <div>
      <div className="w-full xl:justify-self-center">
        <Chart
          options={options}
          series={options?.series}
          type="line"
          width="100%"
        />
      </div>
    </div>
  );
}
