/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Card } from '@mui/material';
import _ from 'lodash';
import img_trailer from '../../../assets/img/trailer.jpg';

export default function TrailerWheelInfo({
  wheelTrailerTail,
  setOpen,
  setWheelTypeSelect,
  setWheeTailInfo,
  setPosition,
  maintain,
  setAddWheel,
  wheelInTrailertail,
}) {
  const renderTrailerFrom = (wheel, wheelData) => {
    // console.log('Testtwheel', wheelData);

    const wheelTailModal = (wheel) => {
      if (!maintain) {
        return (
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setWheeTailInfo(
                // _.filter(wheelData, { position: selectedWheel?.position }),
                wheel,
              );
              setOpen(true);
            }}
          >
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        );
      }
      if (maintain) {
        return (
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setWheelTypeSelect(wheelData);
            }}
          >
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        );
      }
    };
    const addWheelToTrailertail = (position) => (
      <IconButton
        color="primary"
        size="small"
        onClick={() => {
          setAddWheel(true);
          setPosition(position);
        }}
      >
        <OpenInNewIcon fontSize="inherit" />
      </IconButton>
    );
    const wheels = wheelInTrailertail;
    if (wheel <= 4) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      return (
        <div>
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>

            <div className="flex w-full justify-center">
              <div className="w-1/3 pt-6 ">
                <div className="flex justify-end">
                  <div className="font-bold">
                    เลขยาง1 : {wheel1?.wheelNumber}
                    {wheel1?.wheelNumber
                      ? wheelTailModal(wheel1)
                      : addWheelToTrailertail(1)}
                  </div>
                </div>
                <div className="flex justify-end pt-24">
                  <div className="font-bold">
                    เลขยาง3 : {wheel3?.wheelNumber}
                    {wheel3?.wheelNumber
                      ? wheelTailModal(wheel3)
                      : addWheelToTrailertail(3)}
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <img src={img_trailer}></img>
              </div>
              <div className="w-1/3 pt-6 ">
                <div className="font-bold">
                  เลขยาง2 : {wheel2?.wheelNumber}{' '}
                  {wheel2?.wheelNumber
                    ? wheelTailModal(wheel2)
                    : addWheelToTrailertail(2)}
                </div>
                <div className="flex  pt-36">
                  <div className="font-bold">
                    เลขยาง4 : {wheel4?.wheelNumber}{' '}
                    {wheel4?.wheelNumber
                      ? wheelTailModal(wheel4)
                      : addWheelToTrailertail(4)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 8) {
      const wheel1 = _.find(wheelData, { position: 1 });
      const wheel2 = _.find(wheelData, { position: 2 });
      const wheel3 = _.find(wheelData, { position: 3 });
      const wheel4 = _.find(wheelData, { position: 4 });
      const wheel5 = _.find(wheelData, { position: 5 });
      const wheel6 = _.find(wheelData, { position: 6 });
      const wheel7 = _.find(wheelData, { position: 7 });
      const wheel8 = _.find(wheelData, { position: 8 });
      console.log('wheel3', wheel3);
      return (
        <div>
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end pt-40">
                  <div className="flex pt-10 ">
                    <div className="font-bold pr-4">
                      เลขยาง1 : {wheel1?.wheelNumber}
                      {wheel1?.wheelNumber
                        ? wheelTailModal(wheel1)
                        : addWheelToTrailertail(1)}
                    </div>
                    <div className="font-bold">
                      เลขยาง2 : {wheel2?.wheelNumber}{' '}
                      {wheel2?.wheelNumber
                        ? wheelTailModal(wheel2)
                        : addWheelToTrailertail(2)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-8">
                  <div className="font-bold pr-4">
                    เลขยาง5 : {wheel5?.wheelNumber}{' '}
                    {wheel5?.wheelNumber
                      ? wheelTailModal(wheel5)
                      : addWheelToTrailertail(5)}
                  </div>
                  <div className="font-bold">
                    เลขยาง6 : {wheel6?.wheelNumber}{' '}
                    {wheel6?.wheelNumber
                      ? wheelTailModal(wheel6)
                      : addWheelToTrailertail(6)}
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <img src={img_trailer}></img>
              </div>
              <div className="pt-12">
                <div className=" pt-40 ">
                  <div className="flex pt-10 ">
                    <div className="font-bold pr-4">
                      เลขยาง3 : {wheel3?.wheelNumber}
                      {wheel3?.wheelNumber
                        ? wheelTailModal(wheel3)
                        : addWheelToTrailertail(3)}
                    </div>
                    <div className="font-bold">
                      เลขยาง4 : {wheel4?.wheelNumber}{' '}
                      {wheel2?.wheelNumber
                        ? wheelTailModal(wheel4)
                        : addWheelToTrailertail(4)}
                    </div>
                  </div>
                  <div className="flex  pt-8">
                    <div className="font-bold pr-4">
                      เลขยาง7 : {wheel7?.wheelNumber}{' '}
                      {wheel7?.wheelNumber
                        ? wheelTailModal(wheel7)
                        : addWheelToTrailertail(7)}
                    </div>
                    <div className="font-bold">
                      เลขยาง8 : {wheel8?.wheelNumber}{' '}
                      {wheel8?.wheelNumber
                        ? wheelTailModal(wheel8)
                        : addWheelToTrailertail(8)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }

    if (wheel <= 12) {
      const wheel1 = _.find(wheelData, { position: 1 });
      const wheel2 = _.find(wheelData, { position: 2 });
      const wheel3 = _.find(wheelData, { position: 3 });
      const wheel4 = _.find(wheelData, { position: 4 });
      const wheel5 = _.find(wheelData, { position: 5 });
      const wheel6 = _.find(wheelData, { position: 6 });
      const wheel7 = _.find(wheelData, { position: 7 });
      const wheel8 = _.find(wheelData, { position: 8 });
      const wheel9 = _.find(wheelData, { position: 9 });
      const wheel10 = _.find(wheelData, { position: 10 });
      const wheel11 = _.find(wheelData, { position: 11 });
      const wheel12 = _.find(wheelData, { position: 12 });
      return (
        <div>
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12  ">
                <div className="flex justify-end pt-16">
                  <div className="font-bold pr-4 ">
                    เลขยาง1 : {wheel1?.wheelNumber}{' '}
                    {wheel1?.wheelNumber
                      ? wheelTailModal(wheel1)
                      : addWheelToTrailertail(1)}
                  </div>
                  <div className="font-bold  ">
                    เลขยาง2:{wheel2?.wheelNumber}
                    {wheel2?.wheelNumber
                      ? wheelTailModal(wheel2)
                      : addWheelToTrailertail(2)}
                  </div>
                </div>
                <div className="flex justify-end pt-4">
                  <div className="font-bold pr-4">
                    เลขยาง5 : {wheel5?.wheelNumber}{' '}
                    {wheel5?.wheelNumber
                      ? wheelTailModal(wheel5)
                      : addWheelToTrailertail(5)}
                  </div>
                  <div className="font-bold">
                    เลขยาง6 : {wheel6?.wheelNumber}{' '}
                    {wheel6?.wheelNumber
                      ? wheelTailModal(wheel6)
                      : addWheelToTrailertail(6)}
                  </div>
                </div>
                <div className="flex justify-end pt-4">
                  <div className="font-bold pr-4">
                    เลขยาง9 : {wheel9?.wheelNumber}{' '}
                    {wheel9?.wheelNumber
                      ? wheelTailModal(wheel9)
                      : addWheelToTrailertail(9)}
                  </div>
                  <div className="font-bold">
                    เลขยาง10: {wheel10?.wheelNumber}{' '}
                    {wheel10?.wheelNumber
                      ? wheelTailModal(wheel10)
                      : addWheelToTrailertail(10)}
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <img src={img_trailer}></img>
              </div>
              <div className=" pt-12 ">
                <div className="flex justify-end pt-16">
                  <div className="font-bold  ">
                    เลขยาง3 : {wheel3?.wheelNumber}{' '}
                    {wheel3?.wheelNumber
                      ? wheelTailModal(wheel3)
                      : addWheelToTrailertail(3)}
                  </div>
                  <div className="font-bold  ">
                    เลขยาง4:{wheel4?.wheelNumber}
                    {wheel4?.wheelNumber
                      ? wheelTailModal(wheel4)
                      : addWheelToTrailertail(4)}
                  </div>
                </div>
                <div className="flex  pt-4">
                  <div className="font-bold ">
                    เลขยาง7 : {wheel7?.wheelNumber}
                    {wheel7?.wheelNumber
                      ? wheelTailModal(wheel7)
                      : addWheelToTrailertail(7)}
                  </div>
                  <div className="font-bold">
                    เลขยาง8 : {wheel8?.wheelNumber}
                    {wheel8?.wheelNumber
                      ? wheelTailModal(wheel8)
                      : addWheelToTrailertail(8)}
                  </div>
                </div>
                <div className="flex pt-4">
                  <div className="font-bold ">
                    เลขยาง11 : {wheel11?.wheelNumber}
                    {wheel11?.wheelNumber
                      ? wheelTailModal(wheel11)
                      : addWheelToTrailertail(11)}
                  </div>
                  <div className="font-bold">
                    เลขยาง12: {wheel12?.wheelNumber}
                    {wheel12?.wheelNumber
                      ? wheelTailModal(wheel12)
                      : addWheelToTrailertail(12)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  };
  return (
    <div>
      <div className="pt-2">
        {renderTrailerFrom(
          wheelTrailerTail?.trailerTail?.type?.wheel,
          wheelTrailerTail?.wheels,
        )}
      </div>
    </div>
  );
}
