/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Card, Box, Modal } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as actions from '../../redux/actions';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import TrailerWheelInfo from '../../components/Forms/WMS/TrailerWheelInfo';
import WheelTailInfoCard from './WheelTailInfoCard';
import AddWheelTrailertail from './AddWheelTrailertail';
import WheelMaintananceHisTrailerTable from '../../components/Table/WMS/WheelMaintananceHisTrailerTable';

const style = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function InfoWheelTrailertail({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [open, setOpen] = useState(false);
  const { handleSubmit, control } = useForm();
  const [wheelTailInfo, setWheeTailInfo] = useState(false);
  const [wheelTypeSelect, setWheelTypeSelect] = useState();
  const [position, setPosition] = useState();
  const handleClose = () => setOpen(false);
  const [addWheel, setAddWheel] = useState(false);
  const modalClose = () => setAddWheel(false);
  const wheelTrailerTail = useSelector((state) => state.WheelTrailerTail);
  const wheel = useSelector((state) => state.wheel);
  const wheelTransection = useSelector((state) => state.wheelTransection);
  const wheelSupply = useSelector((state) => state.wheelSupply);

  useEffect(() => {
    dispatch(actions.wheelTrailerTailGet(id));
    dispatch(actions.wheelSupplyAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.wheelTransectionAll({
        trailertail: wheelTrailerTail?.trailer,
        page,
        size,
      }),
    );
    return () => {};
  }, [wheelTrailerTail, page, size]);
  useEffect(() => {
    dispatch(
      actions.wheelAll({ statusWheel: 'ready', supply: wheelTypeSelect }),
    );
    return () => {};
  }, [wheelTypeSelect]);
  const renderViweTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;

  const renderBasicInfo = () => (
    <div className="pt-4">
      <Card>
        <div className="py-4">
          <h1 className="pl-4 pt-2 font-bold">ข้อมูลรถ</h1>
          <div className="p-4">
            <div className="flex ">
              <h1 className="font-bold w-1/6">ป้ายทะเบียน</h1>
              <h1 className="pl-6">
                {wheelTrailerTail?.trailerTail?.licence_number}
              </h1>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  const renderInfoTrailer = () => (
    <TrailerWheelInfo
      wheelTrailerTail={wheelTrailerTail}
      setOpen={setOpen}
      setWheelTypeSelect={setWheelTypeSelect}
      setWheeTailInfo={setWheeTailInfo}
      setPosition={setPosition}
      maintain={false}
      setAddWheel={setAddWheel}
      wheelInTrailertail={wheelTrailerTail?.rows?.wheelTypeSelect}
    />
  );
  const prop = {
    name,
    page,
    size,
    setName,
    setPage,
    setSize,
  };
  const renderTable = () => (
    <WheelMaintananceHisTrailerTable
      {...prop}
      wheelTransection={wheelTransection}
    />
  );

  const propModal = {
    wheel,
    setWheelTypeSelect,
    wheelTrailerTail,
    control,
    Controller,
    position,
    wheelSupply,
  };

  const submitAddWheel = async (data) => {
    const dataSubmit = {
      ...data,
      trailertail: wheelTrailerTail?.trailerTail?._id,
      supply: wheelTypeSelect,
      statusWheel: 'usable',
      position,
    };
    await dispatch(actions.wheelCreate(dataSubmit));
    await dispatch(actions.wheelTrailerTailGet(id));
    modalClose();
  };
  const renderModalAddWheel = () => (
    <Modal
      open={addWheel}
      onClose={modalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(submitAddWheel)}>
          <AddWheelTrailertail {...propModal} />
        </form>
      </Box>
    </Modal>
  );

  const renderInfoWheelModal = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <WheelTailInfoCard wheeltail={wheelTailInfo} />
      </Box>
    </Modal>
  );

  return (
    <>
      {renderViweTitle()}
      {renderBackButton()}
      {renderBasicInfo()}
      {renderInfoTrailer()}
      {renderModalAddWheel()}
      {renderInfoWheelModal()}
      {renderTable()}
    </>
  );
}
