/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Button } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import BillHeaderTable from 'components/Table/BMS/BillHeaderTable';

export default function BillHeader({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const billHeader = useSelector((state) => state.billHeader);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  useEffect(() => {
    dispatch(actions.billHeaderAll({}));
    return () => {};
  }, []);

  const tableFunction = {
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleDelete: async (id) => {
      const confirm = window.confirm('ยืนยันการบิล');
      if (confirm) {
        await dispatch(actions.billHeaderDelete(id));
        await dispatch(actions.billHeaderAll({}));
      }
    },
    handleEdit: async (id) => {
      history.push(`bill-header/edit/${id}`);
    },
    name,
    page,
    size,
    total,
    billHeader,
  };

  const renderTableList = () => <BillHeaderTable {...tableFunction} />;
  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <Link to="bill-header/create" className="self-center">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );
  return (
    <div>
      {renderTitle()}
      {renderTableList()}
    </div>
  );
}
