import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { Clear } from '@mui/icons-material';

function WheelInfoMaintenanceCard({
  wheelSelect,
  setWheelSelect,
  control,
  Controller,
}) {
  return (
    <div className="py-1">
      <Card>
        <CardContent>
          <div className="flex justify-between">
            <div className="font-bold">ยางที่เลือก</div>
            <div>
              <IconButton color="error" onClick={() => setWheelSelect()}>
                <Clear />
              </IconButton>
            </div>
          </div>
          <div className="text-xl font-bold ">
            เลขยาง : {wheelSelect?.wheelNumber}
          </div>
          <div>
            วันที่ใส่ : {dayjs(wheelSelect?.dateIn).format('DD MMMM BBBB')}{' '}
          </div>
          <div>เลขไมล์วันใส่ยาง : {wheelSelect?.mileageIn} </div>
          <div>ดอกยางวันใส่ : {wheelSelect?.tread} </div>
          <div className="flex justify-end">
            {/* <Stack direction="row" spacing={2}>
              <Button variant="contained">เปลี่ยนยาง</Button>
              <Button variant="contained" color="warning">
                สลับยาง
              </Button>
            </Stack> */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

WheelInfoMaintenanceCard.propTypes = {
  wheelSelect: PropTypes.object,
  control: PropTypes.object,
  Controller: PropTypes.object,
  setWheelSelect: PropTypes.object,
};

export default WheelInfoMaintenanceCard;
