import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';

function WheelHistory({ wheelTransection }) {
  console.log('wheelTransection', wheelTransection);
  return (
    <div className="py-2">
      <Card>
        <CardContent>
          <div className="py-1 font-bold  ">ประวัติการใช้ยาง</div>
          <Table>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <h1 className="font-bold">ลำดับ </h1>
                    </TableCell>
                    <TableCell align="center">
                      <h1 className="font-bold">วันที่</h1>
                    </TableCell>
                    <TableCell align="center">
                      <h1 className="font-bold">ป้ายทะเบียน</h1>
                    </TableCell>
                    <TableCell align="center">
                      <h1 className="font-bold">ตำแหน่ง</h1>
                    </TableCell>
                    <TableCell align="center">
                      <h1 className="font-bold">ไมล์วันใส่</h1>
                    </TableCell>
                    <TableCell align="center">
                      <h1 className="font-bold">ไมล์วันถอด</h1>
                    </TableCell>
                    <TableCell align="center">
                      <h1 className="font-bold">สาเหตุ</h1>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.isEmpty(
                    _.filter(wheelTransection?.rows, { repairStatus: false }),
                  ) ? (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        ไม่มีข้อมูล
                      </TableCell>{' '}
                    </TableRow>
                  ) : (
                    _.map(
                      _.filter(wheelTransection?.rows, { repairStatus: false }),
                      (each, index) => (
                        <TableRow key={each?._id}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {dayjs(each?.createdAt).format('DD/MM/BB')}
                          </TableCell>
                          <TableCell align="center">
                            {each?.vehicle?.licence_number && (
                              <div>หัวรถ {each?.vehicle?.licence_number}</div>
                            )}
                            {each?.trailertail?.licence_number && (
                              <div>
                                หางรถ {each?.trailertail?.licence_number}
                              </div>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {each?.position || '-'}
                          </TableCell>
                          <TableCell align="center">
                            {each?.mileageIn || '-'}
                          </TableCell>
                          <TableCell align="center">
                            {each?.mileageOut || '-'}
                          </TableCell>
                          <TableCell align="center">
                            {each?.cause || '-'}
                          </TableCell>
                        </TableRow>
                      ),
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}

WheelHistory.propTypes = {
  wheelTransection: PropTypes.object,
};

export default WheelHistory;
