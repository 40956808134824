/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import { MaterialLotReport } from 'components/Report/index';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { currencyFormatter } from '../../utils/functions';
import { MATERIAL_STOCK_STATUS } from '../../utils/constants';

export default function DetailMaterialStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id, placeId } = useParams();
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;

  useEffect(() => {
    dispatch(
      actions.materialGet(id, {
        placeId: placeId === 'all' ? '' : placeId,
        status: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
      }),
    );
    if (placeId !== 'all') {
      dispatch(actions.placeGet(placeId));
    }
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const renderTitle = () => (
    <ViewTitle title={`${title} ${place?.name || ''}`} subtitle={subtitle} />
  );

  const handlePrintPdf = async () => {
    await MaterialLotReport(material, information);
  };

  const renderPrintButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        variant="contained"
        color="primary"
        onClick={() => handlePrintPdf()}
      >
        พิมพ์
      </Button>
    </div>
  );

  const displayMaterialLot = () => (
    <TableContainer>
      <Table className="border-lg mb-4">
        <colgroup>
          <col width="5%" />
          <col width="8%" />
          <col width="8%" />
          {imsSetting?.display?.lot_number && <col width="8%" />}
          <col width="8%" />
          <col width="15%" />
          <col width="10%" />
          {imsSetting?.display?.cost && <col width="5%" />}
          <col width="5%" />
          <col width="5%" />
          <col width="15%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> หมายเลขอ้างอิงของระบบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> วันที่ซื้อ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> วันที่รับวัสดุ</div>
            </TableCell>
            {imsSetting?.display?.lot_number && (
              <TableCell>
                <div className="font-bold"> หมายเลขล็อต</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> เลขที่บิล</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> คู่ค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ผู้รับวัสดุ</div>
            </TableCell>
            {imsSetting?.display?.cost && (
              <TableCell>
                <div className="font-bold"> ราคาต่อหน่วย</div>
              </TableCell>
            )}{' '}
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            {imsSetting?.display?.cost && (
              <TableCell>
                <div className="font-bold"> ราคารวม</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> คงเหลือ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> หมายเหตุ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(material?.stocklots) ? (
            material?.stocklots.map((_lot, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <b>{_lot?.running_number || '-'}</b>
                </TableCell>
                <TableCell>
                  {dayjs(_lot?.purchase_date)
                    .locale('th')
                    .format('DD MMM BBBB')}
                </TableCell>
                <TableCell>
                  {dayjs(_lot?.receipt_date).locale('th').format('DD MMM BBBB')}
                </TableCell>
                {imsSetting?.display?.lot_number && (
                  <TableCell>{_lot?.lot_number || '-'}</TableCell>
                )}
                <TableCell>
                  <b>{_lot?.bill_number || '-'}</b>
                </TableCell>
                <TableCell>
                  <div>{_lot?.supplier?.name} </div>
                  {_lot?.source ? (
                    <div>แหล่งวัสดุ : {_lot?.source}</div>
                  ) : (
                    <div></div>
                  )}
                </TableCell>
                <TableCell>
                  <div>{_lot?.recipient_name}</div>
                  <div>{_lot?.order_department?.name}</div>
                </TableCell>{' '}
                {imsSetting?.display?.cost && (
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  <TableCell>
                    {currencyFormatter.format(_lot?.price / _lot?.quantity)} บาท
                  </TableCell>
                )}{' '}
                <TableCell>
                  {currencyFormatter.format(_lot?.quantity)} {material?.unit}
                </TableCell>
                {imsSetting?.display?.cost && (
                  <TableCell>
                    {currencyFormatter.format(_lot?.price)} บาท
                  </TableCell>
                )}
                <TableCell>
                  {currencyFormatter.format(_lot?.amount)} {material?.unit}
                </TableCell>
                <TableCell>{_lot?.remark || ' - '}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={12}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (material.isLoading || material.rows) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div>
            <BackButton />
          </div>
          <div className="mt-2 text-xl">
            <b>{material?.type_code}</b> {material?.name}
          </div>
          <div>{renderPrintButton()}</div>
        </div>{' '}
        <div>
          <Card>{displayMaterialLot()}</Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
