import { useState, useEffect } from 'react';

export default function Showtime() {
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );

  useEffect(() => {
    const updateTime = () => {
      let time = new Date().toLocaleTimeString();
      setCurrentTime(time);
    };
    setInterval(updateTime, 1000);
    return () => {};
  }, []);

  return (
    <div className="flex justify-center rounded-lg  text-xl border-solid border-8 border-slate-600 ... p-6 m-12">
      {currentTime}
    </div>
  );
}
