import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import { ReportHeader } from './common';
import _ from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (rows) => {
  const productList = _.map(rows, (row, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: [
        {
          text: `${row?.individual_input?.job?.name}\n `,
          style: 'header2',
        },
        {
          text: ` -${row?.individual_input?.job?.start}`,
        },
        {
          text: `\nวันที่ ${dayjs(row?.date).format('DD MMM BBBB')} `,
          style: 'header2',
        },
      ],
    },
    {
      text: row?.individual_input?.job?.customer?.short
        ? row?.individual_input?.job?.customer?.short
        : row?.individual_input?.job?.customer?.name,
      alignment: 'right',
    },

    {
      text: `${row?.individual_input?.vehicle} / ${
        row?.individual_input?.trailer_tail || '-'
      }`,
      alignment: 'center',
    },
    {
      text: row?.individual_input?.container,
      alignment: 'right',
    },
    {
      text: row?.remark,
      alignment: 'right',
    },
    {
      text: `${
        // eslint-disable-next-line no-unsafe-optional-chaining
        row?.bill_number?.prefix + row?.bill_number?.running_number || ''
      }`,
      alignment: 'center',
    },
  ]);

  return productList;
};

const BillSummaryReport = async (row, information) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  // console.log('info', information);
  // console.log('row', row);
  const genItemBody = genBody(row);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 80, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'Report',
    },
    header: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: information?.name,
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Report',
                style: 'header',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '20%', '13%', '18%', '15%', '15%', '15%'],
          body: [
            [
              {
                text: 'No.',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'Work',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'Customer',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'truck/tail',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'Cntr',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'Remark',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'Bill No.',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
      header2: {
        bold: true,
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default BillSummaryReport;
