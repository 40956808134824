/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Button, Card, Chip, Paper } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const WheelMaintananceHistoryTable = ({
  wheelMaintenanceHistory,
  success,
  page,
  name,
  size,
  setPage,
  setName,
  setSize,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value));
    setPage(1);
  };
  return (
    <div>
      <Card>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {/* <colgroup>
              <col width="10%" />
              <col width="20%" />
              <col width="15%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup> */}
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {' '}
                  <h1 className="font-bold">เลขที่ </h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">ป้ายทะเบียน</h1>
                </TableCell>

                <TableCell align="center">
                  <h1 className="font-bold">วันที่แจ้ง</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">วันที่สำเร็จ</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">สถานะงาน</h1>
                </TableCell>
                <TableCell align="center">ดำเนินการ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wheelMaintenanceHistory?.rows?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              ) : (
                wheelMaintenanceHistory?.rows?.map((each, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {each?.vehicle?.licence_number && (
                        <div>หัวรถ {each?.vehicle?.licence_number}</div>
                      )}
                      {each?.trailer_tail?.licence_number && (
                        <div>หางรถ {each?.trailer_tail?.licence_number}</div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(each?.createdAt).format('DD/MM/BBBB')}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(each?.updatedAt).format('DD/MM/BBBB')}
                    </TableCell>
                    <TableCell align="center">
                      {each.statusSuccess ? (
                        <Chip label="สำเร็จ" color="success"></Chip>
                      ) : each.statusCancle ? (
                        <Chip label="ยกเลิก" color="error"></Chip>
                      ) : (
                        <div className="bg-red-500"></div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        component={Link}
                        to={`/wms/history/detail/${each._id}`}
                      >
                        ตรวจสอบ
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={wheelMaintenanceHistory?.total}
          rowsPerPage={size || 15}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
};
export default WheelMaintananceHistoryTable;
