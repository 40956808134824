import React from 'react';
import { Card, TableBody, TableCell, TableRow, Table } from '@mui/material';
import PropTypes from 'prop-types';

export default function WheelSupplyDetail({ wheelSupply }) {
  return (
    <div className="pt-4">
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">ยี่ห้อ</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">{wheelSupply?.brand || '-'}</h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">ประเภทยาง</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">{wheelSupply?.type || '-'}</h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">ขนาด (นิ้ว)</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">{wheelSupply?.size || '-'} </h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">ชนิดยาง</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">{wheelSupply?.wheelType || '-'} </h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">สัปดาห์ผลิต</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">{wheelSupply?.mfg || '-'} </h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">ราคา (บาท)</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">{wheelSupply?.price || '-'} </h1>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}
WheelSupplyDetail.propTypes = {
  wheelSupply: PropTypes.object,
};

WheelSupplyDetail.defaultProps = {
  wheelSupply: '',
};
