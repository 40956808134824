import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import WheelMaintananceListTable from '../../components/Table/WMS/WheelMaintananceListTable';
import * as actions from '../../redux/actions';
import { WheelMaintananceOrderTable } from '../../components/Table';
// eslint-disable-next-line react/prop-types
export default function WheelOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const wheelRegister = useSelector((state) => state.WheelRegister);
  const wheelMaintenance = useSelector((state) => state.WheelMaintenance);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const prop = {
    name,
    page,
    size,
    setName,
    setPage,
    setSize,
    wheelMaintenance,
  };
  console.log(wheelMaintenance);
  useEffect(() => {
    dispatch(actions.wheelRegisterAll({}));
    dispatch(actions.wheelMaintenanceAll({ name, page, size }));

    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Button variant="contained" component={Link} to={'/wms/wms/create'}>
        เพิ่ม
      </Button>
    </div>
  );
  const renderTable = () => (
    <div>
      <WheelMaintananceListTable wheelRegister={wheelRegister} {...prop} />
    </div>
  );
  const renderTableOrder = () => (
    <div>
      <WheelMaintananceOrderTable
        wheelNonSuccess={wheelMaintenance?.rows}
        {...prop}
      />
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {renderAddButtom()}
      {renderTableOrder()}
    </div>
  );
}
WheelOrder.PropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
WheelOrder.defaultProps = {
  title: '',
  subtitle: '',
};
