export const SCHEDULE_STATUS = {
  SCHEDULE_PENDING: {
    status_code: 'SCHEDULE_PENDING',
    description: 'กำลังดำเนินการ',
  },
  SCHEDULE_SUCCESS: {
    status_code: 'SCHEDULE_SUCCESS',
    description: 'สำเร็จ',
  },
  SCHEDULE_CANCEL: {
    status_code: 'SCHEDULE_CANCEL',
    description: 'ยกเลิก',
  },
  SCHEDULE_STARTED: {
    status_code: 'SCHEDULE_STARTED',
    description: 'รอดำเนินการ',
  },
  SCHEDULE_BILLED: {
    status_code: 'SCHEDULE_BILLED',
    description: 'ออกบิล',
  },
  SCHEDULE_CALENDAR: {
    status_code: 'SCHEDULE_CALENDAR',
    description: 'ทุกรายการยกเว้นยกเลิก',
  },
};
export default SCHEDULE_STATUS;
