/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import {
  TextField,
  Box,
  Autocomplete,
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';

export function DriverParingForm({
  control,
  errors,
  employee,
  vehicle,
  driver,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'drivers',
  });

  const renderEachForm = (fieldId, index) => (
    <TableRow key={fieldId}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Controller
          name={`drivers[${index}].employee`}
          control={control}
          defaultValue={driver ? driver?.employee : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              disableClearable
              name={field.name}
              defaultValue={field.value}
              ref={field.ref}
              options={_.map(employee.rows, (row, index) => ({
                index: index + 1,
                ...row,
              }))}
              autoHighlight
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {`${option.index} | ${option.firstname} ${option.lastname}`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="คนขับ"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  helperText={errors.employee && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name={`drivers[${index}].vehicle`}
          control={control}
          rules={{ required: false }}
          defaultValue={driver ? driver?.vehicle : undefined}
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              ref={field.ref}
              defaultValue={field.value}
              options={_.map(vehicle.rows, (row, index) => ({
                index: index + 1,
                ...row,
              }))}
              autoHighlight
              getOptionLabel={(option) => ` ${option.licence_number} `}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {`${option.index} | ${option?.type?.name} ${
                    option.licence_number
                  } ${
                    option?.driver
                      ? `คนขับปัจจุบัน ${option?.driver?.employee?.firstname} ${option?.driver?.employee?.lastname}`
                      : ''
                  }`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="รถ"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  helperText={errors.driver && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={() => remove(index)}
        >
          ลบ
        </Button>
      </TableCell>
    </TableRow>
  );

  return (
    <div>
      <div>
        <Table>
          <TableHead>
            <TableCell>
              <div className="font-bold">ลำดับที่ </div>
            </TableCell>
            <TableCell>
              <div className="font-bold">คนขับ </div>
            </TableCell>
            <TableCell>
              <div className="font-bold">รถ </div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold">ดำเนินการ </div>
            </TableCell>
          </TableHead>
          <TableBody>
            {_.map(fields, (eachField, index) =>
              renderEachForm(eachField?.id, index),
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-start my-2">
        <Button size="small" variant="contained" onClick={() => append({})}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );
}

DriverParingForm.propTypes = {
  employee: PropTypes.object,
  errors: PropTypes.shape({
    employee: PropTypes.object,
  }),
  vehicle: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  control: PropTypes.object,
  driver: PropTypes.object,
};

export default DriverParingForm;
