import {
  LOCATION_TYPE_ALL,
  LOCATION_TYPE_GET,
  LOCATION_TYPE_PUT,
  LOCATION_TYPE_DEL,
  LOCATION_TYPE_POST,
  LOCATION_TYPE_LOADING,
  LOCATION_TYPE_ERROR,
} from '../../actions/types';

const initialState = {
  locationType: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case LOCATION_TYPE_LOADING:
      return { isLoading: true, isCompleted: true };
    case LOCATION_TYPE_ERROR:
      return { isLoading: false, isCompleted: false };
    case LOCATION_TYPE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOCATION_TYPE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOCATION_TYPE_POST:
      return { isLoading: false, isCompleted: true };
    case LOCATION_TYPE_PUT:
      return { isLoading: false, isCompleted: true };
    case LOCATION_TYPE_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
