import React from 'react';
import { MAINTENANCE_LEVEL } from '../constants';

const showMaintenanceLevel = (level) => {
  if (level === MAINTENANCE_LEVEL.SCHEDULE.status_code) {
    return (
      <div className="bg-green-400 px-2 rounded text-center font-semibold">
        {MAINTENANCE_LEVEL.SCHEDULE.description}
      </div>
    );
  }
  if (level === MAINTENANCE_LEVEL.GENERAL.status_code) {
    return (
      <div className="bg-yellow-400 px-2 rounded text-center font-semibold">
        {MAINTENANCE_LEVEL.GENERAL.description}
      </div>
    );
  }
  if (level === MAINTENANCE_LEVEL.ACCIDENT.status_code) {
    return (
      <div className="bg-red-400 px-2 rounded text-center font-semibold">
        {MAINTENANCE_LEVEL.ACCIDENT.description}
      </div>
    );
  }
  if (level === MAINTENANCE_LEVEL.OVERHAUL.status_code) {
    return (
      <div className="bg-orange-400 px-2 rounded text-center font-semibold">
        {MAINTENANCE_LEVEL.OVERHAUL.description}
      </div>
    );
  }
  return (
    <div className="bg-gray-300 px-2 rounded text-center font-semibold">
      {'-'}
    </div>
  );
};

export default showMaintenanceLevel;
