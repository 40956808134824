/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';

export default function FilterCardWheel({
  statusWheel,
  setStatusWheel,
  wheelTypeSelect,
  setWheelTypeSelect,
  name,
  setName,
  wheelSupply,
}) {
  const handleChange = (event) => {
    setStatusWheel(event.target.value);
  };
  const handlefindChange = (event) => {
    setName(event.target.value);
  };
  const handleTypeChange = (event) => {
    setWheelTypeSelect(event.target.value);
  };
  return (
    <div className="pb-2">
      <Card>
        <div className="flex">
          <div className="p-4 w-full">
            <TextField
              fullWidth
              size="small"
              label="ค้นหารหัสยาง"
              value={name}
              onChange={handlefindChange}
            />
          </div>
          <div className="p-4 w-full">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">สถานะยาง</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusWheel}
                size="small"
                label="สถานะยาง"
                onChange={handleChange}
              >
                <MenuItem value={'ready'}>พร้อมใช้งาน</MenuItem>
                <MenuItem value={'usable'}>ใช้งาน</MenuItem>
                <MenuItem value={'old'}>หมดสภาพ</MenuItem>
                <MenuItem value={''}>ทั้งหมด</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="p-4 w-full">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">ยี่ห้อยาง</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={wheelTypeSelect?.brand}
                size="small"
                label="ชนิดยาง"
                onChange={handleTypeChange}
              >
                <MenuItem value={''}>ทั้งหมด</MenuItem>
                {_.map(wheelSupply?.rows, (each) => (
                  <MenuItem value={each}>{each?.brand}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Card>
    </div>
  );
}
