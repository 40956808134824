import React from 'react';
import _ from 'lodash';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { currencyFormatter, dayjs } from '../../../utils/functions';

const MaterialCard = ({ materialArrays, grid = true, maintenanceOrderId }) => (
  <Card>
    <CardContent>
      <div className="flex justify-between">
        <div className="font-semibold">วัสดุที่เบิกมา</div>
        <div>
          <Link to={`/cmms/material-usage/${maintenanceOrderId}`}>
            <Button startIcon={<i className="fas fa-list"></i>}>
              ประวัติการใช้
            </Button>
          </Link>
        </div>
      </div>
      <div
        className={`my-2  ${
          grid ? 'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2' : ''
        }`}
      >
        {_.isEmpty(materialArrays) && <div>ไม่มีวัสดุที่เบิกมาในงานนี้</div>}
        {console.log('materialArrays', materialArrays)}
        {_.map(materialArrays, (_material, index) => (
          <div key={index} className="border rounded-md p-4">
            <div className="font-semibold">
              {_material?.material?.type_code}
            </div>
            <div>{_material?.material?.name}</div>

            <div className="text-xl my-2">
              {_material?.amount} {_material?.material?.unit}{' '}
            </div>
            <div className="text-sm">แหล่งที่มา</div>
            {console.log('_material', _material)}
            {_.map(_material?.origin, (eachOrigin, originIndex) => (
              <div key={`origin${originIndex}`} className="my-2">
                <div className="text-sm font-semibold">
                  {eachOrigin?.material_stock_lot?.supplier?.name || ''}
                </div>
                <div className="text-xs">
                  ราคาต่อหน่วย{' '}
                  {currencyFormatter.format(eachOrigin?.price_per_unit)} บาท
                  จำนวน {eachOrigin?.amount} {_material?.material?.unit}
                </div>
                <div className="text-xs">
                  รับเข้าวันที่{' '}
                  {dayjs(eachOrigin?.material_stock_lot?.reciept_date).format(
                    'D MMM BBBB',
                  )}
                </div>
                <div className="text-xs">
                  หมายเลขอ้างอิงของระบบ{' '}
                  {eachOrigin?.material_stock_lot?.running_number || '-'}
                </div>
              </div>
            ))}

            <div className="text-sm font-semibold my-2">
              ราคารวม {currencyFormatter.format(_material?.total_price)} บาท (
              เฉลี่ย {currencyFormatter.format(_material?.average_price) || ''}{' '}
              บาท/หน่วย)
            </div>
          </div>
        ))}
      </div>
    </CardContent>
  </Card>
);

MaterialCard.propTypes = {
  materialArrays: PropTypes.arrayOf(
    PropTypes.shape({
      materialInfo: PropTypes.object,
      material_stock_lot: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      amount: PropTypes.number,
    }),
  ),
  grid: PropTypes.bool,
  maintenanceOrderId: PropTypes.string,
};

export default MaterialCard;
