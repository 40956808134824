import React from 'react';
import { Card, TableBody, TableCell, TableRow, Table } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function WheelTailInfoCard(wheeltail) {
  //   const wheelDetail = _.orderBy(wheeltail?.wheeltail, ['dateIn'], ['desc']);
  const wheelDetail = wheeltail?.wheeltail;
  console.log('Test', wheelDetail);
  return (
    <div>
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">เลขยาง</h1>
              </TableCell>
              <TableCell>
                <h1>{wheelDetail?.wheelNumber}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold"></h1>
              </TableCell>
              <TableCell>
                <h1></h1>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <h1 className="font-bold">วันที่ผลิต</h1>
              </TableCell>
              <TableCell>
                <h1>{wheelDetail?.supply?.mfg}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">วันที่ใช้</h1>
              </TableCell>
              <TableCell>
                <h1>{dayjs(wheelDetail?.dateIn).format('DD/MM/BBBB')}</h1>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <h1 className="font-bold">เลขไมล์วันใส่ (กม.)</h1>
              </TableCell>
              <TableCell>
                <h1>{wheelDetail?.mileageIn}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">เลขไมล์วันถอด (กม.)</h1>
              </TableCell>
              <TableCell>
                <h1>{wheelDetail?.mileageOut}</h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="font-bold text-l">ระยะทางใช้งาน (กม.)</h1>
              </TableCell>
              <TableCell>
                <h1>{wheelDetail?.mileageOut - wheelDetail?.mileageIn || 0}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">ดอกยาง</h1>
              </TableCell>
              <TableCell>
                <h1>{wheelDetail?.tread}</h1>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}
WheelTailInfoCard.propTypes = {
  wheeltail: PropTypes.object,
};
