import {
  VEHICLE_ALL,
  VEHICLE_GET,
  VEHICLE_DEL,
  VEHICLE_PUT,
  VEHICLE_POST,
  VEHICLE_LOADING,
  VEHICLE_ERROR,
} from '../../actions/types';

const initialState = {
  vehicle: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case VEHICLE_LOADING:
      return { isLoading: true, isCompleted: true };
    case VEHICLE_ERROR:
      return { isLoading: false, isCompleted: false };
    case VEHICLE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case VEHICLE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case VEHICLE_POST:
      return { isLoading: false, isCompleted: true };
    case VEHICLE_PUT:
      return { isLoading: false, isCompleted: true };
    case VEHICLE_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
