import {
  DRIVER_ALL,
  DRIVER_GET,
  DRIVER_DEL,
  DRIVER_PUT,
  DRIVER_POST,
  DRIVER_LOADING,
  DRIVER_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const driverCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/driver`, {
      ...payload,
    });
    dispatch({ type: DRIVER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DRIVER_ERROR });
    throw new Error(error);
  }
};

export const driverAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/driver?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: DRIVER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DRIVER_ERROR });
    throw new Error(error);
  }
};

export const driverGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/driver/${id}`,
    );
    if (status === 200) {
      dispatch({ type: DRIVER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DRIVER_ERROR });
    throw new Error(error);
  }
};

export const driverPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/driver/${id}`,
      payload,
    );
    dispatch({ type: DRIVER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DRIVER_ERROR });
    throw new Error(error);
  }
};
export const driverDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/driver/${id}`,
    );
    dispatch({ type: DRIVER_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DRIVER_ERROR });
    throw new Error(error);
  }
};
