import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { dayjs, dateDurationGenerator } from '../../utils/functions';

function ReportBillBooking({ title, subtitle }) {
  const dispatch = useDispatch();
  const billBooking = useSelector((state) => state.billBooking);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [monthArray, setMonthArray] = useState([]);

  useEffect(() => {
    dispatch(
      actions.billBookingAll({
        page,
        size,
        completed: '',
      }),
    );
  }, [page, size]);

  useEffect(() => {
    setStartDate(dayjs(billBooking?.range?.range_first).toDate());
    setEndDate(dayjs(billBooking?.range?.range_last).toDate());
    return () => {};
  }, [billBooking]);

  useEffect(() => {
    const dateArray = dateDurationGenerator(startDate, endDate);
    setMonthArray(dateArray);
    return () => {};
  }, [startDate, endDate]);

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  const renderTable = () => (
    <div className="my-4">
      <TableContainer component={Paper}>
        <Paper>
          <Table>
            <TableHead>
              <TableCell>
                <div className="font-semibold"> ลำดับที่ </div>
              </TableCell>
              <TableCell>
                <div className="font-semibold"> เดือน</div>
              </TableCell>
              <TableCell>
                <div className="font-semibold"> รายละเอียด</div>
              </TableCell>
            </TableHead>
            <TableBody>
              {_.map(monthArray, (eachMonth, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{dayjs(eachMonth)?.format('MMMM BBBB')}</TableCell>
                  <TableCell>
                    <Link
                      to={`/bms/bill-booking/report/monthy/${dayjs(eachMonth)
                        .startOf('month')
                        .format('YYYY-MM-DD')}/${dayjs(eachMonth)
                        .endOf('month')
                        .format('YYYY-MM-DD')}`}
                    >
                      <Button
                        component={Button}
                        variant="outlined"
                        size="small"
                      >
                        รายละเอียด
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>
    </div>
  );

  if (billBooking.isLoading) {
    return <Loading />;
  }
  if (!billBooking.isLoading && billBooking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex">
          <BackButton />
        </div>
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

ReportBillBooking.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReportBillBooking.defaultProps = {
  title: '',
  subtitle: '',
};

export default ReportBillBooking;
