import {
    MAINTENANCE_TOPIC_ALL,
    MAINTENANCE_TOPIC_GET,
    MAINTENANCE_TOPIC_PUT,
    MAINTENANCE_TOPIC_DEL,
    MAINTENANCE_TOPIC_POST,
    MAINTENANCE_TOPIC_RESET,
    MAINTENANCE_TOPIC_LOADING,
    MAINTENANCE_TOPIC_ERROR,
  } from '../../actions/types';
  
  const initialState = {
    maintenanceTopics: null,
    isLoading: true,
    isCompleted: true,
  };
  // eslint-disable-next-line func-names
  export default function (state = initialState, action) {
    switch (action.type) {
      case MAINTENANCE_TOPIC_ALL:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case MAINTENANCE_TOPIC_GET:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case MAINTENANCE_TOPIC_POST:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_TOPIC_PUT:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_TOPIC_DEL:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_TOPIC_RESET:
        return { MAINTENANCE_TOPIC: null, isLoading: true, isCompleted: true };
      case MAINTENANCE_TOPIC_LOADING:
        return { isLoading: true, isCompleted: true };
      case MAINTENANCE_TOPIC_ERROR:
        return { isLoading: false, isCompleted: false };
      default:
        return state;
    }
  }
  