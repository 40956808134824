import React from 'react';
import { Card, CardContent } from '@mui/material';

export default function MachineryDocumentCard() {
  return (
    <div className="py-2">
      <Card>
        <CardContent>
          <div>
            <div className="text-xl font-bold ">เอกสาร</div>
            <div className="w-full lg:w-1/4 m-2 px-2">
              ไม่มีรายการเอกสารที่เกี่ยวข้อง
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
