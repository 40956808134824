/* eslint-disable no-underscore-dangle */
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function UserForm({
  errors,
  user,
  department,
  roletype,
  Controller,
  control,
  displayEmployeeForm = false,
}) {
  console.log('user', user);
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-1">
        <Controller
          name={'username'}
          control={control}
          defaultValue={user?.userData ? user?.userData?.uid?.username : ''}
          rules={{ required: true, pattern: /^[a-z0-9-_.]/ }}
          render={({ field }) => (
            <TextField
              {...field}
              required
              label="ชื่อผู้ใช้"
              fullWidth
              size={'small'}
              helperText={
                <span>
                  {' '}
                  ตัวอักษรภาษาอังกฤษตัวเล็ก และ/หรือ ตัวเลข และ/หรือ . - _
                  เท่านั้น{' '}
                  {errors.username && (
                    <span className="font-semibold text-red-400">
                      กรุณาใส่ข้อมูล และใส่ให้ตรงกับฟอร์แมตที่ต้องการ
                    </span>
                  )}
                </span>
              }
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'password'}
          control={control}
          defaultValue={user ? user?.password : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสผ่าน"
              required
              type="password"
              fullWidth
              size={'small'}
              helperText={errors.password && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'confirmPassword'}
          control={control}
          defaultValue={user ? user.password : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ยืนยันรหัสผ่าน"
              required
              type="password"
              fullWidth
              size={'small'}
              helperText={errors.password && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      {displayEmployeeForm ? (
        <>
          <div className="w-1/2 px-1 py-1">
            <Controller
              name={'firstname'}
              control={control}
              defaultValue={user ? user.firstname : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อ"
                  required
                  fullWidth
                  size={'small'}
                  helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-1/2 px-1 py-1">
            <Controller
              name={'lastname'}
              control={control}
              defaultValue={user ? user.lastname : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="นามสกุล"
                  required
                  fullWidth
                  size={'small'}
                  helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'department'}
              control={control}
              defaultValue={user ? user?.department?._id : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    แผนก
                  </InputLabel>
                  <Select
                    {...field}
                    label="แผนก"
                    required
                    size={'small'}
                    fullWidth
                  >
                    {_.size(department?.rows) ? (
                      _.map(department.rows, (row) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'role'}
              control={control}
              defaultValue={user ? user?.role?._id : ''}
              // defaultValue={user?.rows ? '' : user?.roles[0]?.role?.id}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl required sx={{ minWidth: 120 }} fullWidth="true">
                  <InputLabel id="type" size={'small'}>
                    บทบาท
                  </InputLabel>
                  <Select {...field} label="บทบาท" size={'small'} fullWidth>
                    {_.size(roletype?.rows) ? (
                      _.map(roletype.rows, (row) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

UserForm.propTypes = {
  department: PropTypes.object,
  roletype: PropTypes.object,
  errors: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
    firstname: PropTypes.object,
    lastname: PropTypes.object,
  }),
  user: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  displayEmployeeForm: PropTypes.bool,
};

UserForm.defaultProps = {
  user: null,
};

export default UserForm;
