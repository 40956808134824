import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import { Button } from '@mui/material';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { MaintenanceOrderCard } from '../../components/Card';
import * as actions from '../../redux/actions';
import { CONFIG } from '../../utils/constants';
import { ReturnMaterialForm } from '../../components/Forms';
import { handleReturnMaterial } from '../../utils/functions';

const MaterialReturn = ({ title, subtitle }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const me = useSelector((state) => state.me);
  const place = useSelector((state) => state.place);

  const material = useSelector((state) => state.material);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );

  const {
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const getProcessMaterial = async () => {
    try {
      dispatch(
        actions.processMaterialAll({
          maintenanceOrder: params.id,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.log('Get Process Material Error', error);
    }
  };

  const getMaintenanceOrder = async () => {
    try {
      dispatch(actions.maintenanceOrderGet(params.id));
    } catch (error) {
      console.error('Get Maintenance Order Error', error);
    }
  };

  const getAllMaterials = () => {
    try {
      dispatch(actions.materialAll({ size: CONFIG.infiniteLimit, page: 1 }));
    } catch (error) {
      console.error('Get All Materials fail', error);
    }
  };

  const getAllMaterialTxnType = () => {
    try {
      dispatch(actions.materialTransactionTypeAll({}));
    } catch (error) {
      console.error('Get All Material Transaction Type Error', error);
    }
  };

  const getAllPlace = () => {
    try {
      dispatch(actions.placeAll({}));
    } catch (error) {
      console.error('Get All Place Error', error);
    }
  };

  useEffect(() => {
    getProcessMaterial();
    getMaintenanceOrder();
    getAllMaterials();
    getAllMaterialTxnType();
    getAllPlace();

    return () => {};
  }, [params]);

  useEffect(() => {
    const analyzedMaterials = maintenanceOrder?.process_materials;
    if (!_.isEmpty(analyzedMaterials)) {
      setValue(
        'material',
        _.map(analyzedMaterials, (_eachAnalyzed) => ({
          ..._eachAnalyzed,
          resultAmount: _eachAnalyzed?.amount,
          lot: _.map(_eachAnalyzed?.origin, (eachOrigin) => ({
            ...eachOrigin,
            amount: 0,
          })),
        })),
      );
    }

    return () => {};
  }, [maintenanceOrder]);

  const onSubmit = async (data) => {
    console.log('Return Material Data', data);
    try {
      await handleReturnMaterial({
        dispatch,
        data,
        me,
        maintenanceOrderId: params.id,
      });
      history.goBack();
    } catch (error) {
      console.error('Return Material Error', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  const renderMaintenanceOrder = () => (
    <div>
      <MaintenanceOrderCard
        maintenanceOrder={maintenanceOrder}
        showDate={false}
        showDriver={false}
        showImages={false}
        showStatus={false}
      />
    </div>
  );

  const renderReturnForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ReturnMaterialForm
          control={control}
          material={material}
          materialTransactionType={materialTransactionType}
          watch={watch}
          setValue={setValue}
          errors={errors}
          place={place}
        />
        <div className="flex justify-end my-2">
          <Button variant="contained" type="submit">
            ยืนยันการคืน
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {renderBackButton()}
      {renderMaintenanceOrder()}
      {renderReturnForm()}
    </div>
  );
};

MaterialReturn.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default MaterialReturn;
