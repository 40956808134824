/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export function OrderCard({
  value,
  setCancle,
  setSuccess,
  toDetail,
  type,
  status,
  constant,
}) {
  const history = useHistory();

  const genCard = () =>
    _.map(value, (_value) => (
      <div key={_value?.id} className="w-full lg:w-4/12 p-2 cursor-pointer">
        <Card>
          <div className="p-4">
            <div className="flex flex-wrap ">
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 font-semibold">
                ทะเบียนรถ
              </div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 text-sm">{`${_value?.vehicle?.licence_number}`}</div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 font-semibold">
                พนักงานขับ
              </div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 text-sm">
                {`${_value?.driver?.employee?.firstname} ${_value?.driver?.employee?.lastname}`}{' '}
              </div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 font-semibold">
                วันที่คาดว่าจัดส่ง
              </div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 text-sm">
                {`${dayjs(_value?.tart_date_expected).format('DD/MM/BBBB')}`}{' '}
              </div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 font-semibold">
                วันที่คาดว่าส่งมอบ
              </div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 text-sm">{`${dayjs(
                _value?.end_date_expected,
              ).format('DD/MM/BBBB')}`}</div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 font-semibold">
                จำนวนรายการ
              </div>
              <div className="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 py-2 text-sm">{`${_.size(
                _value?.orders,
              )} รายการ`}</div>
            </div>
            <div className="flex justify-end">
              <div className="mr-2 py-1 ">
                <Button
                  variant="contained"
                  color={'info'}
                  size={'small'}
                  onClick={() => history.push(toDetail + _value?.id)}
                >
                  รายละเอียด
                </Button>
              </div>
              {type === 'distribution' ? (
                <>
                  {_.map(status, (_status) => {
                    if (
                      _status.status_code ===
                      constant.DISTRIBUTION_SUCCESS.status_code
                    ) {
                      return (
                        <div className="mr-2 py-1" key={_status.id}>
                          <Button
                            key={_status.id}
                            variant="contained"
                            color={'success'}
                            size={'small'}
                            onClick={() => {
                              setSuccess({
                                orderId: _value?.id,
                                statusId: _status?.id,
                                statusCode: _status?.status_code,
                              });
                            }}
                          >
                            {`${_status.description}`}
                          </Button>
                        </div>
                      );
                    }
                    if (
                      _status.status_code ===
                      constant.DISTRIBUTION_CANCEL.status_code
                    ) {
                      return (
                        <div className="mr-2 py-1" key={_status.id}>
                          <Button
                            key={_status.id}
                            variant="contained"
                            color={'error'}
                            size={'small'}
                            onClick={() => {
                              setCancle({
                                orderId: _value?.id,
                                statusId: _status?.id,
                                statusCode: _status?.status_code,
                              });
                            }}
                          >
                            {`${_status.description}`}
                          </Button>
                        </div>
                      );
                    }
                    return <div key={_status.id}></div>;
                  })}
                </>
              ) : (
                <>
                  {_.map(status, (_status) => {
                    if (
                      _status.status_code ===
                      constant.SHIPMENT_SUCCESS.status_code
                    ) {
                      return (
                        <div className="mr-2 py-1" key={_status.id}>
                          <Button
                            key={_status.id}
                            variant="contained"
                            color={'success'}
                            size={'small'}
                            onClick={() => {
                              setSuccess({
                                orderId: _value?.id,
                                statusId: _status?.id,
                                statusCode: _status?.status_code,
                              });
                            }}
                          >
                            {`${_status.description}`}
                          </Button>
                        </div>
                      );
                    }
                    if (
                      _status.status_code ===
                      constant.SHIPMENT_CANCEL.status_code
                    ) {
                      return (
                        <div className="mr-2 py-1" key={_status.id}>
                          <Button
                            key={_status.id}
                            variant="contained"
                            color={'error'}
                            size={'small'}
                            onClick={() => {
                              setCancle({
                                orderId: _value?.id,
                                statusId: _status?.id,
                                statusCode: _status?.status_code,
                              });
                            }}
                          >
                            {`${_status.description}`}
                          </Button>
                        </div>
                      );
                    }
                    return <div key={_status.id}></div>;
                  })}
                </>
              )}
            </div>
          </div>
        </Card>
      </div>
    ));

  return (
    <div className="flex-auto">
      <div className="relative flex flex-wrap">{genCard()}</div>
    </div>
  );
}

OrderCard.propTypes = {
  value: PropTypes.array,
  setCancle: PropTypes.func,
  setSuccess: PropTypes.func,
  toDetail: PropTypes.string,
};

OrderCard.defaultProps = {
  value: '',
  setCancle: '',
  setSuccess: '',
  toDetail: '',
};

export default OrderCard;
