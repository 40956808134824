import React from 'react';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import relateTime from 'dayjs/plugin/relativeTime';
import { Link } from 'react-router-dom';

import dayjs from '../../../utils/functions/thDayjs';

dayjs.extend(relateTime);

const VehicleCard = ({
  vehicleDetail,
  showLink = false,
  tail,
  container,
  vehicle,
}) => (
  <Card>
    <div className="p-4">
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/5 rounded-md m-2 p-4 border flex justify-center">
          <div>
            {vehicle && <i className="fas fa-cogs text-6xl"></i>}
            {container && <i className="fa-solid fa-box text-6xl"></i>}
          </div>
        </div>
        <div className="w-full lg:w-1/4 m-2 px-2">
          <div className="text-3xl">
            {vehicleDetail?.licence_number}{' '}
            {showLink && (
              <Link to={`/fms/vehicle/detail/${vehicleDetail?._id}`}>
                <Button>
                  <i className="fas fa-external-link-alt"></i>
                </Button>
              </Link>
            )}
          </div>
          {/* <div>ประเภทเครื่องจักร : {vehicleDetail?.type?.name} </div> */}
          <div>ชื่ออังกฤษ : {vehicleDetail?.engname} </div>
          <div>ชื่อไทย : {vehicleDetail?.engname} </div>
          <div>
            {vehicleDetail?.attribute?.brand} -{' '}
            {vehicleDetail?.attribute?.generation}
          </div>
        </div>
      </div>
    </div>
  </Card>
);

VehicleCard.propTypes = {
  vehicleDetail: PropTypes.object,
  showLink: PropTypes.bool,
  tail: PropTypes.object,
  container: PropTypes.object,
  vehicle: PropTypes.object,
};

export default VehicleCard;
