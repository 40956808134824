import dayjs from 'dayjs';

export const headerBill = ({ header }, data) => [
  {
    alignment: 'center',
    margin: [0, 0, 0, 12],
    text: [
      {
        text: header,
        fontSize: '18',
        bold: true,
      },
    ],
  },
  {
    alignment: 'between',
    // margin: [20, 4, 20, 0],
    table: {
      widths: ['60%', '20%', '20%'],
      body: [
        [
          {
            text: 'Customer:',
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: 'Invoice No ',
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: data?.invoice_number,
            style: 'body1',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: `TO ${data?.customer?.name}`,
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: 'Date ',
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: dayjs(data?.date).format('DD/MM/YYYY'),
            style: 'body1',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: `${data?.customer?.address?.house_number || ''} ${
              data?.customer?.address?.road || ''
            } ${data?.customer?.address?.subdistrict || ''} ${
              data?.customer?.address?.district || ''
            } ${data?.customer?.address?.province || ''} ${
              data?.customer?.address?.country || ''
            } ${data?.customer?.address?.postcode || ''}`,
            rowSpan: 2,
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: 'Credit ',
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            style: 'body1',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: ' ',
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: 'Sales Order ',
            style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: '#',
            style: 'body1',
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: `Tel. ${data?.customer?.telephone || '-'} Fax ${
              data?.fax || '-'
            } Tax ID ${data?.taxId || '-'}`,
            //   style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: 'Salesman ',
            //   style: 'body1',
            border: [false, false, false, false],
          },
          {
            text: '- ',
            //   style: 'body1',
            border: [false, false, false, false],
          },
        ],
      ],
    },
  },
  {
    alignment: 'left',
    margin: [0, 0, 0, 12],
    text: [
      {
        text: `SHIPMENT : ${
          data?.bookings?.[0]?.individual_input?.job?.name
        }   ${
          data?.bookings?.[0]?.date <= 15
            ? `${'1-15/' + dayjs(data?.bookings?.[0]?.date).format('MM/YYYY')}`
            : `${
                '16-' +
                dayjs(data?.bookings?.[0]?.date)
                  .endOf('month')
                  .format('DD/MM/YYYY')
              }`
        }`,
        fontSize: '12',
        bold: true,
      },
    ],
  },
];

export default headerBill;
