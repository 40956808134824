// common type
export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DEL = 'USER_DEL';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

// feature type

// CRM
export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';

export const CUSTOMER_TYPE_ALL = 'CUSTOMER_TYPE_ALL';
export const CUSTOMER_TYPE_GET = 'CUSTOMER_TYPE_GET';
export const CUSTOMER_TYPE_POST = 'CUSTOMER_TYPE_POST';
export const CUSTOMER_TYPE_PUT = 'CUSTOMER_TYPE_PUT';
export const CUSTOMER_TYPE_DEL = 'CUSTOMER_TYPE_DEL';
export const CUSTOMER_TYPE_LOADING = 'CUSTOMER_TYPE_LOADING';
export const CUSTOMER_TYPE_ERROR = 'CUSTOMER_TYPE_ERROR';

// HRMS
export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DEL = 'DEPARTMENT_DEL';
export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';

export const ROLETYPES_ALL = 'ROLETYPES_ALL';
export const ROLETYPES_GET = 'ROLETYPES_GET';
export const ROLETYPES_POST = 'ROLETYPES_POST';
export const ROLETYPES_PUT = 'ROLETYPES_PUT';
export const ROLETYPES_DEL = 'ROLETYPES_DEL';
export const ROLETYPES_LOADING = 'ROLETYPES_LOADING';
export const ROLETYPES_ERROR = 'ROLETYPES_ERROR';

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';

export const DRIVER_ALL = 'DRIVER_ALL';
export const DRIVER_GET = 'DRIVER_GET';
export const DRIVER_POST = 'DRIVER_POST';
export const DRIVER_PUT = 'DRIVER_PUT';
export const DRIVER_DEL = 'DRIVER_DEL';
export const DRIVER_LOADING = 'DRIVER_LOADING';
export const DRIVER_ERROR = 'DRIVER_ERROR';

export const LOCATION_ALL = 'LOCATION_ALL';
export const LOCATION_GET = 'LOCATION_GET';
export const LOCATION_POST = 'LOCATION_POST';
export const LOCATION_PUT = 'LOCATION_PUT';
export const LOCATION_DEL = 'LOCATION_DEL';
export const LOCATION_LOADING = 'LOCATION_LOADING';
export const LOCATION_ERROR = 'LOCATION_ERROR';

export const LOCATION_TYPE_ALL = 'LOCATION_TYPE_ALL';
export const LOCATION_TYPE_GET = 'LOCATION_TYPE_GET';
export const LOCATION_TYPE_POST = 'LOCATION_TYPE_POST';
export const LOCATION_TYPE_PUT = 'LOCATION_TYPE_PUT';
export const LOCATION_TYPE_DEL = 'LOCATION_TYPE_DEL';
export const LOCATION_TYPE_LOADING = 'LOCATION_TYPE_LOADING';
export const LOCATION_TYPE_ERROR = 'LOCATION_TYPE_ERROR';

// FMS
export const VEHICLETYPES_ALL = 'VEHICLETYPES_ALL';
export const VEHICLETYPES_GET = 'VEHICLETYPES_GET';
export const VEHICLETYPES_POST = 'VEHICLETYPES_POST';
export const VEHICLETYPES_PUT = 'VEHICLETYPES_PUT';
export const VEHICLETYPES_DEL = 'VEHICLETYPES_DEL';
export const VEHICLETYPES_LOADING = 'VEHICLETYPES_LOADING';
export const VEHICLETYPES_ERROR = 'VEHICLETYPES_ERROR';

export const VEHICLE_ALL = 'VEHICLE_ALL';
export const VEHICLE_GET = 'VEHICLE_GET';
export const VEHICLE_POST = 'VEHICLE_POST';
export const VEHICLE_PUT = 'VEHICLE_PUT';
export const VEHICLE_DEL = 'VEHICLE_DEL';
export const VEHICLE_LOADING = 'VEHICLE_LOADING';
export const VEHICLE_ERROR = 'VEHICLE_ERROR';

export const VEHICLE_SCHEDULE_ALL = 'VEHICLE_SCHEDULE_ALL';
export const VEHICLE_SCHEDULE_GET = 'VEHICLE_SCHEDULE_GET';
export const VEHICLE_SCHEDULE_POST = 'VEHICLE_SCHEDULE_POST';
export const VEHICLE_SCHEDULE_PUT = 'VEHICLE_SCHEDULE_PUT';
export const VEHICLE_SCHEDULE_DEL = 'VEHICLE_SCHEDULE_DEL';
export const VEHICLE_SCHEDULE_LOADING = 'VEHICLE_SCHEDULE_LOADING';
export const VEHICLE_SCHEDULE_ERROR = 'VEHICLE_SCHEDULE_ERROR';

export const TRAILER_TAIL_TYPE_ALL = 'TRAILER_TAIL_TYPE_ALL';
export const TRAILER_TAIL_TYPE_GET = 'TRAILER_TAIL_TYPE_GET';
export const TRAILER_TAIL_TYPE_POST = 'TRAILER_TAIL_TYPE_POST';
export const TRAILER_TAIL_TYPE_PUT = 'TRAILER_TAIL_TYPE_PUT';
export const TRAILER_TAIL_TYPE_DEL = 'TRAILER_TAIL_TYPE_DEL';
export const TRAILER_TAIL_TYPE_LOADING = 'TRAILER_TAIL_TYPE_LOADING';
export const TRAILER_TAIL_TYPE_ERROR = 'TRAILER_TAIL_TYPE_ERROR';

export const TRAILER_TAIL_ALL = 'TRAILER_TAIL_ALL';
export const TRAILER_TAIL_GET = 'TRAILER_TAIL_GET';
export const TRAILER_TAIL_POST = 'TRAILER_TAIL_POST';
export const TRAILER_TAIL_PUT = 'TRAILER_TAIL_PUT';
export const TRAILER_TAIL_DEL = 'TRAILER_TAIL_DEL';
export const TRAILER_TAIL_LOADING = 'TRAILER_TAIL_LOADING';
export const TRAILER_TAIL_ERROR = 'TRAILER_TAIL_ERROR';

export const CONTAINER_ALL = 'CONTAINER_ALL';
export const CONTAINER_GET = 'CONTAINER_GET';
export const CONTAINER_POST = 'CONTAINER_POST';
export const CONTAINER_PUT = 'CONTAINER_PUT';
export const CONTAINER_DEL = 'CONTAINER_DEL';
export const CONTAINER_LOADING = 'CONTAINER_LOADING';
export const CONTAINER_ERROR = 'CONTAINER_ERROR';

// Information
export const INFORMATION_ALL = 'INFORMATION_ALL';
export const INFORMATION_PUT = 'INFORMATION_PUT';
export const INFORMATION_LOADING = 'INFORMATION_LOADING';
export const INFORMATION_ERROR = 'INFORMATION_ERROR';

// IMS
export const MATERIAL_TYPE_ALL = 'MATERIAL_TYPE_ALL';
export const MATERIAL_TYPE_GET = 'MATERIAL_TYPE_GET';
export const MATERIAL_TYPE_POST = 'MATERIAL_TYPE_POST';
export const MATERIAL_TYPE_PUT = 'MATERIAL_TYPE_PUT';
export const MATERIAL_TYPE_DEL = 'MATERIAL_TYPE_DEL';
export const MATERIAL_TYPE_LOADING = 'MATERIAL_TYPE_LOADING';
export const MATERIAL_TYPE_ERROR = 'MATERIAL_TYPE_ERROR';

// TMS
export const DELIVERY_ORDER_ALL = 'DELIVERY_ORDER_ALL';
export const DELIVERY_ORDER_GET = 'DELIVERY_ORDER_GET';
export const DELIVERY_ORDER_POST = 'DELIVERY_ORDER_POST';
export const DELIVERY_ORDER_PUT = 'DELIVERY_ORDER_PUT';
export const DELIVERY_ORDER_DEL = 'DELIVERY_ORDER_DEL';
export const DELIVERY_ORDER_LOADING = 'DELIVERY_ORDER_LOADING';
export const DELIVERY_ORDER_ERROR = 'DELIVERY_ORDER_ERROR';
export const DELIVERY_ORDER_HISTORY = 'DELIVERY_ORDER_HISTORY';

export const VEHICLE_ASSIGNMENT_ALL = 'VEHICLE_ASSIGNMENT_ALL';
export const VEHICLE_ASSIGNMENT_GET = 'VEHICLE_ASSIGNMENT_GET';
export const VEHICLE_ASSIGNMENT_POST = 'VEHICLE_ASSIGNMENT_POST';
export const VEHICLE_ASSIGNMENT_PUT = 'VEHICLE_ASSIGNMENT_PUT';
export const VEHICLE_ASSIGNMENT_DEL = 'VEHICLE_ASSIGNMENT_DEL';
export const VEHICLE_ASSIGNMENT_LOADING = 'VEHICLE_ASSIGNMENT_LOADING';
export const VEHICLE_ASSIGNMENT_ERROR = 'VEHICLE_ASSIGNMENT_ERROR';

// CMMS
export const MAINTENANCE_ORDER_ALL = 'MAINTENANCE_ORDER_ALL';
export const MAINTENANCE_ORDER_GET = 'MAINTENANCE_ORDER_GET';
export const MAINTENANCE_ORDER_POST = 'MAINTENANCE_ORDER_POST';
export const MAINTENANCE_ORDER_PUT = 'MAINTENANCE_ORDER_PUT';
export const MAINTENANCE_ORDER_DEL = 'MAINTENANCE_ORDER_DEL';
export const MAINTENANCE_ORDER_LOADING = 'MAINTENANCE_ORDER_LOADING';
export const MAINTENANCE_ORDER_ERROR = 'MAINTENANCE_ORDER_ERROR';
export const MAINTENANCE_ORDER_RESET = 'MAINTENANCE_ORDER_RESET';

export const MAINTENANCE_TYPE_ALL = 'MAINTENANCE_TYPE_ALL';
export const MAINTENANCE_TYPE_GET = 'MAINTENANCE_TYPE_GET';
export const MAINTENANCE_TYPE_POST = 'MAINTENANCE_TYPE_POST';
export const MAINTENANCE_TYPE_PUT = 'MAINTENANCE_TYPE_PUT';
export const MAINTENANCE_TYPE_DEL = 'MAINTENANCE_TYPE_DEL';
export const MAINTENANCE_TYPE_LOADING = 'MAINTENANCE_TYPE_LOADING';
export const MAINTENANCE_TYPE_ERROR = 'MAINTENANCE_TYPE_ERROR';
export const MAINTENANCE_TYPE_RESET = 'MAINTENANCE_TYPE_RESET';

export const MAINTENANCE_TOPIC_ALL = 'MAINTENANCE_TOPIC_ALL';
export const MAINTENANCE_TOPIC_GET = 'MAINTENANCE_TOPIC_GET';
export const MAINTENANCE_TOPIC_POST = 'MAINTENANCE_TOPIC_POST';
export const MAINTENANCE_TOPIC_PUT = 'MAINTENANCE_TOPIC_PUT';
export const MAINTENANCE_TOPIC_DEL = 'MAINTENANCE_TOPIC_DEL';
export const MAINTENANCE_TOPIC_LOADING = 'MAINTENANCE_TOPIC_LOADING';
export const MAINTENANCE_TOPIC_ERROR = 'MAINTENANCE_TOPIC_ERROR';
export const MAINTENANCE_TOPIC_RESET = 'MAINTENANCE_TOPIC_RESET';

export const MAINTENANCE_HISTORY_ALL = 'MAINTENANCE_HISTORY_ALL';
export const MAINTENANCE_HISTORY_GET = 'MAINTENANCE_HISTORY_GET';
export const MAINTENANCE_HISTORY_POST = 'MAINTENANCE_HISTORY_POST';
export const MAINTENANCE_HISTORY_PUT = 'MAINTENANCE_HISTORY_PUT';
export const MAINTENANCE_HISTORY_DEL = 'MAINTENANCE_HISTORY_DEL';
export const MAINTENANCE_HISTORY_LOADING = 'MAINTENANCE_HISTORY_LOADING';
export const MAINTENANCE_HISTORY_ERROR = 'MAINTENANCE_HISTORY_ERROR';
export const MAINTENANCE_HISTORY_RESET = 'MAINTENANCE_HISTORY_RESET';

export const GARAGE_ALL = 'GARAGE_ALL';
export const GARAGE_GET = 'GARAGE_GET';
export const GARAGE_POST = 'GARAGE_POST';
export const GARAGE_PUT = 'GARAGE_PUT';
export const GARAGE_DELETE = 'GARAGE_DELETE';
export const GARAGE_LOADING = 'GARAGE_LOADING';
export const GARAGE_ERROR = 'GARAGE_ERROR';
export const GARAGE_RESET = 'GARAGE_RESET';

// IMS

export const MATERIALTYPES_ALL = 'MATERIALTYPES_ALL';
export const MATERIALTYPES_GET = 'MATERIALTYPES_GET';
export const MATERIALTYPES_POST = 'MATERIALTYPES_POST';
export const MATERIALTYPES_PUT = 'MATERIALTYPES_PUT';
export const MATERIALTYPES_DEL = 'MATERIALTYPES_DEL';
export const MATERIALTYPES_LOADING = 'MATERIALTYPES_LOADING';
export const MATERIALTYPES_ERROR = 'MATERIALTYPES_ERROR';

export const MATERIAL_ALL = 'MATERIAL_ALL';
export const MATERIAL_GET = 'MATERIAL_GET';
export const MATERIAL_POST = 'MATERIAL_POST';
export const MATERIAL_PUT = 'MATERIAL_PUT';
export const MATERIAL_DEL = 'MATERIAL_DEL';
export const MATERIAL_LOADING = 'MATERIAL_LOADING';
export const MATERIAL_ERROR = 'MATERIAL_ERROR';

export const MATERIAL_TRANSACTION_TYPES_ALL = 'MATERIAL_TRANSACTION_TYPES_ALL';
export const MATERIAL_TRANSACTION_TYPES_GET = 'MATERIAL_TRANSACTION_TYPES_GET';
export const MATERIAL_TRANSACTION_TYPES_POST =
  'MATERIAL_TRANSACTION_TYPES_POST';
export const MATERIAL_TRANSACTION_TYPES_PUT = 'MATERIAL_TRANSACTION_TYPES_PUT';
export const MATERIAL_TRANSACTION_TYPES_DEL = 'MATERIAL_TRANSACTION_TYPES_DEL';
export const MATERIAL_TRANSACTION_TYPES_LOADING =
  'MATERIAL_TRANSACTION_TYPES_LOADING';
export const MATERIAL_TRANSACTION_TYPES_ERROR =
  'MATERIAL_TRANSACTION_TYPES_ERROR';

export const MATERIAL_REQUEST_ALL = 'MATERIAL_REQUEST_ALL';
export const MATERIAL_REQUEST_GET = 'MATERIAL_REQUEST_GET';
export const MATERIAL_REQUEST_POST = 'MATERIAL_REQUEST_POST';
export const MATERIAL_REQUEST_PUT = 'MATERIAL_REQUEST_PUT';
export const MATERIAL_REQUEST_DEL = 'MATERIAL_REQUEST_DEL';
export const MATERIAL_REQUEST_LOADING = 'MATERIAL_REQUEST_LOADING';
export const MATERIAL_REQUEST_ERROR = 'MATERIAL_REQUEST_ERROR';

export const MATERIAL_WITHDRAW_ALL = 'MATERIAL_WITHDRAW_ALL';
export const MATERIAL_WITHDRAW_GET = 'MATERIAL_WITHDRAW_GET';
export const MATERIAL_WITHDRAW_POST = 'MATERIAL_WITHDRAW_POST';
export const MATERIAL_WITHDRAW_PUT = 'MATERIAL_WITHDRAW_PUT';
export const MATERIAL_WITHDRAW_DEL = 'MATERIAL_WITHDRAW_DEL';
export const MATERIAL_WITHDRAW_LOADING = 'MATERIAL_WITHDRAW_LOADING';
export const MATERIAL_WITHDRAW_ERROR = 'MATERIAL_WITHDRAW_ERROR';

export const PLACE_ALL = 'PLACE_ALL';
export const PLACE_GET = 'PLACE_GET';
export const PLACE_POST = 'PLACE_POST';
export const PLACE_PUT = 'PLACE_PUT';
export const PLACE_DEL = 'PLACE_DEL';
export const PLACE_LOADING = 'PLACE_LOADING';
export const PLACE_ERROR = 'PLACE_ERROR';

export const MATERIAL_TRANSACTION_ALL = 'MATERIAL_TRANSACTION_ALL';
export const MATERIAL_TRANSACTION_GET = 'MATERIAL_TRANSACTION_GET';
export const MATERIAL_TRANSACTION_POST = 'MATERIAL_TRANSACTION_POST';
export const MATERIAL_TRANSACTION_PUT = 'MATERIAL_TRANSACTION_PUT';
export const MATERIAL_TRANSACTION_DEL = 'MATERIAL_TRANSACTION_DEL';
export const MATERIAL_TRANSACTION_SEARCH = 'MATERIAL_TRANSACTION_SEARCH';
export const MATERIAL_TRANSACTION_LOADING = 'MATERIAL_TRANSACTION_LOADING';
export const MATERIAL_TRANSACTION_ERROR = 'MATERIAL_TRANSACTION_ERROR';

export const MATERIAL_STOCK_LOT_ALL = 'MATERIAL_STOCK_LOT_ALL';
export const MATERIAL_STOCK_LOT_GET = 'MATERIAL_STOCK_LOT_GET';
export const MATERIAL_STOCK_LOT_POST = 'MATERIAL_STOCK_LOT_POST';
export const MATERIAL_STOCK_LOT_PUT = 'MATERIAL_STOCK_LOT_PUT';
export const MATERIAL_STOCK_LOT_DEL = 'MATERIAL_STOCK_LOT_DEL';
export const MATERIAL_STOCK_LOT_LOADING = 'MATERIAL_STOCK_LOT_LOADING';
export const MATERIAL_STOCK_LOT_ERROR = 'MATERIAL_STOCK_LOT_ERROR';
export const MATERIAL_STOCK_LOT_DECREASE = 'MATERIAL_STOCK_LOT_DECREASE';
export const MATERIAL_STOCK_LOT_INCREASE = 'MATERIAL_STOCK_LOT_INCREASE';

// Notify

export const NOTIFY_REQ_TOKEN = 'NOTIFY_REQ_TOKEN';
export const NOTIFY_DIRECT = 'NOTIFY_DIRECT';
export const NOTIFY_DEPARTMENT = 'NOTIFY_DEPARTMENT';
export const NOTIFY_LOADING = 'NOTIFY_LOADING';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';

// MMS

export const MAINTENANCE_PROCESS_ALL = 'MAINTENANCE_PROCESS_ALL';
export const MAINTENANCE_PROCESS_GET = 'MAINTENANCE_PROCESS_GET';
export const MAINTENANCE_PROCESS_POST = 'MAINTENANCE_PROCESS_POST';
export const MAINTENANCE_PROCESS_PUT = 'MAINTENANCE_PROCESS_PUT';
export const MAINTENANCE_PROCESS_DELETE = 'MAINTENANCE_PROCESS_DELETE';
export const MAINTENANCE_PROCESS_ERROR = 'MAINTENANCE_PROCESS_ERROR';
export const MAINTENANCE_PROCESS_LOADING = 'MAINTENANCE_PROCESS_LOADING';
export const MAINTENANCE_PROCESS_RESET = 'MAINTENANCE_PROCESS_RESET';

export const COMMENT_ALL = 'COMMENT_ALL';
export const COMMENT_GET = 'COMMENT_GET';
export const COMMENT_POST = 'COMMENT_POST';
export const COMMENT_PUT = 'COMMENT_PUT';
export const COMMENT_DELETE = 'COMMENT_DELETE';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const COMMENT_LOADING = 'COMMENT_LOADING';
export const COMMENT_RESET = 'COMMENT_RESET';

export const PROCESS_TRANSACTION_ALL = 'PROCESS_TRANSACTION_ALL';
export const PROCESS_TRANSACTION_GET = 'PROCESS_TRANSACTION_GET';
export const PROCESS_TRANSACTION_POST = 'PROCESS_TRANSACTION_POST';
export const PROCESS_TRANSACTION_PUT = 'PROCESS_TRANSACTION_PUT';
export const PROCESS_TRANSACTION_DELETE = 'PROCESS_TRANSACTION_DELETE';
export const PROCESS_TRANSACTION_LOADING = 'PROCESS_TRANSACTION_LOADING';
export const PROCESS_TRANSACTION_ERROR = 'PROCESS_TRANSACTION_ERROR';
export const PROCESS_TRANSACTION_RESET = 'PROCESS_TRANSACTION_RESET';

export const PROCESS_MATERIAL_ALL = 'PROCESS_MATERIAL_ALL';
export const PROCESS_MATERIAL_GET = 'PROCESS_MATERIAL_GET';
export const PROCESS_MATERIAL_POST = 'PROCESS_MATERIAL_POST';
export const PROCESS_MATERIAL_PUT = 'PROCESS_MATERIAL_PUT';
export const PROCESS_MATERIAL_DELETE = 'PROCESS_MATERIAL_DELETE';
export const PROCESS_MATERIAL_LOADING = 'PROCESS_MATERIAL_LOADING';
export const PROCESS_MATERIAL_ERROR = 'PROCESS_MATERIAL_ERROR';
export const PROCESS_MATERIAL_RESET = 'PROCESS_MATERIAL_RESET';

// jms
export const JOB_ALL = 'JOB_ALL';
export const JOB_GET = 'JOB_GET';
export const JOB_POST = 'JOB_POST';
export const JOB_PUT = 'JOB_PUT';
export const JOB_DELETE = 'JOB_DELETE';
export const JOB_LOADING = 'JOB_LOADING';
export const JOB_ERROR = 'JOB_ERROR';
export const JOB_RESET = 'JOB_RESET';

export const ZONE_PRICE_ALL = 'ZONE_PRICE_ALL';
export const ZONE_PRICE_GET = 'ZONE_PRICE_GET';
export const ZONE_PRICE_POST = 'ZONE_PRICE_POST';
export const ZONE_PRICE_PUT = 'ZONE_PRICE_PUT';
export const ZONE_PRICE_DELETE = 'ZONE_PRICE_DELETE';
export const ZONE_PRICE_LOADING = 'ZONE_PRICE_LOADING';
export const ZONE_PRICE_ERROR = 'ZONE_PRICE_ERROR';
export const ZONE_PRICE_RESET = 'ZONE_PRICE_RESET';

export const BILL_BOOKING_ALL = 'BILL_BOOKING_ALL';
export const BILL_BOOKING_GET = 'BILL_BOOKING_GET';
export const BILL_BOOKING_POST = 'BILL_BOOKING_POST';
export const BILL_BOOKING_PUT = 'BILL_BOOKING_PUT';
export const BILL_BOOKING_DELETE = 'BILL_BOOKING_DELETE';
export const BILL_BOOKING_LOADING = 'BILL_BOOKING_LOADING';
export const BILL_BOOKING_ERROR = 'BILL_BOOKING_ERROR';
export const BILL_BOOKING_RESET = 'BILL_BOOKING_RESET';

export const BILL_ALL = 'BILL_ALL';
export const BILL_GET = 'BILL_GET';
export const BILL_POST = 'BILL_POST';
export const BILL_PUT = 'BILL_PUT';
export const BILL_DELETE = 'BILL_DELETE';
export const BILL_LOADING = 'BILL_LOADING';
export const BILL_ERROR = 'BILL_ERROR';
export const BILL_RESET = 'BILL_RESET';

export const BILL_HEADER_ALL = 'BILL_HEADER_ALL';
export const BILL_HEADER_GET = 'BILL_HEADER_GET';
export const BILL_HEADER_POST = 'BILL_HEADER_POST';
export const BILL_HEADER_PUT = 'BILL_HEADER_PUT';
export const BILL_HEADER_DELETE = 'BILL_HEADER_DELETE';
export const BILL_HEADER_LOADING = 'BILL_HEADER_LOADING';
export const BILL_HEADER_ERROR = 'BILL_HEADER_ERROR';
export const BILL_HEADER_RESET = 'BILL_HEADER_RESET';

// wms

export const WHEEL_ALL = 'WHEEL_ALL';
export const WHEEL_GET = 'WHEEL_GET';
export const WHEEL_POST = 'WHEEL_POST';
export const WHEEL_PUT = 'WHEEL_PUT';
export const WHEEL_DELETE = 'WHEEL_DELETE';
export const WHEEL_LOADING = 'WHEEL_LOADING';
export const WHEEL_ERROR = 'WHEEL_ERROR';
export const WHEEL_RESET = 'WHEEL_RESET';

export const WHEEL_MAINTENANCE_ALL = 'WHEEL_MAINTENANCE_ALL';
export const WHEEL_MAINTENANCE_GET = 'WHEEL_MAINTENANCE_GET';
export const WHEEL_MAINTENANCE_POST = 'WHEEL_MAINTENANCE_POST';
export const WHEEL_MAINTENANCE_PUT = 'WHEEL_MAINTENANCE_PUT';
export const WHEEL_MAINTENANCE_DELETE = 'WHEEL_MAINTENANCE_DELETE';
export const WHEEL_MAINTENANCE_LOADING = 'WHEEL_MAINTENANCE_LOADING';
export const WHEEL_MAINTENANCE_ERROR = 'WHEEL_MAINTENANCE_ERROR';
export const WHEEL_MAINTENANCE_RESET = 'WHEEL_MAINTENANCE_RESET';

export const WHEEL_REGISTER_ALL = 'WHEEL_REGISTERE_ALL';
export const WHEEL_REGISTER_GET = 'WHEEL_REGISTER_GET';
export const WHEEL_REGISTER_POST = 'WHEEL_REGISTER_POST';
export const WHEEL_REGISTER_PUT = 'WHEEL_REGISTER_PUT';
export const WHEEL_REGISTER_DELETE = 'WHEEL_REGISTER_DELETE';
export const WHEEL_REGISTER_LOADING = 'WHEEL_REGISTER_LOADING';
export const WHEEL_REGISTER_ERROR = 'WHEEL_REGISTER_ERROR';
export const WHEEL_REGISTER_RESET = 'WHEEL_REGISTER_RESET';

export const WHEEL_TYPE_ALL = 'WHEEL_TYPE_ALL';
export const WHEEL_TYPE_GET = 'WHEEL_TYPE_GET';
export const WHEEL_TYPE_POST = 'WHEEL_TYPE_POST';
export const WHEEL_TYPE_PUT = 'WHEEL_TYPE_PUT';
export const WHEEL_TYPE_DELETE = 'WHEEL_TYPE_DELETE';
export const WHEEL_TYPE_LOADING = 'WHEEL_TYPE_LOADING';
export const WHEEL_TYPE_ERROR = 'WHEEL_TYPE_ERROR';
export const WHEEL_TYPE_RESET = 'WHEEL_TYPE_RESET';

export const WHEEL_MAINTENANCE_HISTORY_ALL = 'WHEEL_MAINTENANCE_HISTORY_ALL';
export const WHEEL_MAINTENANCE_HISTORY_GET = 'WHEEL_MAINTENANCE_HISTORY_GET';
export const WHEEL_MAINTENANCE_HISTORY_POST = 'WHEEL_MAINTENANCE_HISTORY_POST';
export const WHEEL_MAINTENANCE_HISTORY_PUT = 'WHEEL_MAINTENANCE_HISTORY_PUT';
export const WHEEL_MAINTENANCE_HISTORY_DELETE =
  'WHEEL_MAINTENANCE_HISTORY_DELETE';
export const WHEEL_MAINTENANCE_HISTORY_LOADING =
  'WHEEL_MAINTENANCE_HISTORY_LOADING';
export const WHEEL_MAINTENANCE_HISTORY_ERROR =
  'WHEEL_MAINTENANCE_HISTORY_ERROR';
export const WHEEL_MAINTENANCE_HISTORY_RESET =
  'WHEEL_MAINTENANCE_HISTORY_RESET';

export const WHEEL_SUPPLY_ALL = 'WHEEL_SUPPLY_ALL';
export const WHEEL_SUPPLY_GET = 'WHEEL_SUPPLY_GET';
export const WHEEL_SUPPLY_POST = 'WHEEL_SUPPLY_POST';
export const WHEEL_SUPPLY_PUT = 'WHEEL_SUPPLY_PUT';
export const WHEEL_SUPPLY_DELETE = 'WHEEL_SUPPLY_DELETE';
export const WHEEL_SUPPLY_LOADING = 'WHEEL_SUPPLY_LOADING';
export const WHEEL_SUPPLY_ERROR = 'WHEEL_SUPPLY_ERROR';
export const WHEEL_SUPPLY_RESET = 'WHEEL_SUPPLY_RESET';

export const WHEEL_TRANSECTION_ALL = 'WHEEL_TRANSECTION_ALL';
export const WHEEL_TRANSECTION_GET = 'WHEEL_TRANSECTION_GET';
export const WHEEL_TRANSECTION_POST = 'WHEEL_TRANSECTION_POST';
export const WHEEL_TRANSECTION_PUT = 'WHEEL_TRANSECTION_PUT';
export const WHEEL_TRANSECTION_DELETE = 'WHEEL_TRANSECTION_DELETE';
export const WHEEL_TRANSECTION_LOADING = 'WHEEL_TRANSECTION_LOADING';
export const WHEEL_TRANSECTION_ERROR = 'WHEEL_TRANSECTION_ERROR';
export const WHEEL_TRANSECTION_RESET = 'WHEEL_TRANSECTION_RESET';

export const MECHINERY_ALL = 'MECHINERY_ALL';
export const MECHINERY_GET = 'MECHINERY_GET';
export const MECHINERY_POST = 'MECHINERY_POST';
export const MECHINERY_PUT = 'MECHINERY_PUT';
export const MECHINERY_DELETE = 'MECHINERY_DELETE';
export const MECHINERY_LOADING = 'MECHINERY_LOADING';
export const MECHINERY_ERROR = 'MECHINERY_ERROR';
export const MECHINERY_RESET = 'MECHINERY_RESET';

export const MAINTENANCE_NOTIFY_ALL = 'MAINTENANCE_NOTIFY_ALL';
export const MAINTENANCE_NOTIFY_GET = 'MAINTENANCE_NOTIFY_GET';
export const MAINTENANCE_NOTIFY_POST = 'MAINTENANCE_NOTIFY_POST';
export const MAINTENANCE_NOTIFY_PUT = 'MAINTENANCE_NOTIFY_PUT';
export const MAINTENANCE_NOTIFY_DELETE = 'MAINTENANCE_NOTIFY_DELETE';
export const MAINTENANCE_NOTIFY_LOADING = 'MAINTENANCE_NOTIFY_LOADING';
export const MAINTENANCE_NOTIFY_ERROR = 'MAINTENANCE_NOTIFY_ERROR';
export const MAINTENANCE_NOTIFY_RESET = 'MAINTENANCE_NOTIFY_RESET';

export const REFUELTRANSITION_ALL = 'REFUELTRANSITION_ALL';
export const REFUELTRANSITION_GET = 'REFUELTRANSITION_GET';
export const REFUELTRANSITION_POST = 'REFUELTRANSITION_POST';
export const REFUELTRANSITION_PUT = 'REFUELTRANSITION_PUT';
export const REFUELTRANSITION_DELETE = 'REFUELTRANSITION_DELETE';
export const REFUELTRANSITION_LOADING = 'REFUELTRANSITION_LOADING';
export const REFUELTRANSITION_ERROR = 'REFUELTRANSITION_ERROR';
export const REFUELTRANSITION_RESET = 'REFUELTRANSITION_RESET';

export const MILEAGETRANSECTION_ALL = 'MILEAGETRANSACTION_ALL';
export const MILEAGETRANSECTION_GET = 'MILEAGETRANSACTION_GET';
export const MILEAGETRANSECTION_POST = 'MILEAGETRANSACTION_POST';
export const MILEAGETRANSECTION_PUT = 'MILEAGETRANSACTION_PUT';
export const MILEAGETRANSECTION_DELETE = 'MILEAGETRANSACTION_DELETE';
export const MILEAGETRANSECTION_LOADING = 'MILEAGETRANSACTION_LOADING';
export const MILEAGETRANSECTION_ERROR = 'MILEAGETRANSACTION_ERROR';
export const MILEAGETRANSECTION_RESET = 'MILEAGETRANSACTION_RESET';

export const ALLWORKTRANSACTION_ALL = 'ALLWORKTRANSACTION_ALL';
export const ALLWORKTRANSACTION_GET = 'ALLWORKTRANSACTION_GET';
export const ALLWORKTRANSACTION_POST = 'ALLWORKTRANSACTION_POST';
export const ALLWORKTRANSACTION_PUT = 'ALLWORKTRANSACTION_PUT';
export const ALLWORKTRANSACTION_DELETE = 'ALLWORKTRANSACTION_DELETE';
export const ALLWORKTRANSACTION_LOADING = 'ALLWORKTRANSACTION_LOADING';
export const ALLWORKTRANSACTION_ERROR = 'ALLWORKTRANSACTION_ERROR';
export const ALLWORKTRANSACTION_RESET = 'ALLWORKTRANSACTION_RESET';
export const ALLWORKTRANSACTION_CREATE = 'ALLWORKTRANSACTION_CREATE';
export const ALLWORKTRANSACTION_UPDATE = 'ALLWORKTRANSACTION_UPDATE';

export const WHEELTRAILERTAIL_PUT = 'WHEELTRAILERTAIL_PUT';
export const WHEELTRAILERTAIL_GET = 'WHEELTRAILERTAIL_GET';
export const WHEELTRAILERTAIL_POST = 'WHEELTRAILERTAIL_POST';
export const WHEELTRAILERTAIL_ALL = 'WHEELTRAILERTAIL_ALL';
export const WHEELTRAILERTAIL_DELETE = 'WHEELTRAILERTAIL_DELETE';
export const WHEELTRAILERTAIL_LOADING = 'WHEELTRAILERTAIL_LOADING';
export const WHEELTRAILERTAIL_ERROR = 'WHEELTRAILERTAIL_ERROR';
export const WHEELTRAILERTAIL_RESET = 'WHEELTRAILERTAIL_RESET';
export const WHEELTRAILERTAIL_CREATE = 'WHEELTRAILERTAIL_CREATE';
export const WHEELTRAILERTAIL_UPDATE = 'WHEELTRAILERTAIL_UPDATE';
