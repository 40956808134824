import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { BillHeaderForm } from 'components/Forms/BMS/BillHeaderForm';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

export default function BillHeaderCreate({ title, subtitle }) {
  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = async (data, event) => {
    try {
      const payload = {
        ...data,
      };
      event.preventDefault();
      await dispatch(actions.billHeaderCreate(payload));
      await dispatch(actions.billHeaderAll());
      alert('สำเร็จ');
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <Card className="p-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <BillHeaderForm
          Controller={Controller}
          control={control}
          errors={errors}
        />
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </Card>
  );

  return (
    <div>
      {renderTitle()}
      {renderForm()}
    </div>
  );
}
