/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import _ from 'lodash';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  IconButton,
} from '@mui/material';
import { ArrowRight } from 'react-feather';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Link } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';

import { currencyFormatter } from '../../../utils/functions';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const BillSummaryTable = ({
  billBookings,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
  showActionButton = true,
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่/No.</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">วันที่/Date</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">งาน/Work</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ลูกค้า/Customer</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">รก/หางรถ / Truck / tail</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ตู้คอนเทนเนอร์ / Cntr</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">หมายเหตุ / remark</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">หมายเลขบิล / Bill No.</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(billBookings) ? (
            billBookings.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {(page - 1) * size + index + 1}
                </TableCell>{' '}
                <TableCell component="th" scope="row">
                  {dayjs(row?.date).format('D MMM BBBB')}
                </TableCell>{' '}
                <TableCell>
                  <div>
                    <div>
                      {row?.individual_input?.job?.name}{' '}
                      <Link to={`/bms/bill-booking/view/${row?._id}`}>
                        <IconButton size="small">
                          <LaunchIcon fontSize="inherit" />
                        </IconButton>
                      </Link>{' '}
                    </div>
                    <div className="flex gap-2 self-center">
                      {row?.individual_input?.job?.start}
                      <ArrowRight size="16" className="self-center" />{' '}
                      {row?.individual_input?.job?.destination}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    {row?.individual_input?.job?.customer?.short
                      ? row?.individual_input?.job?.customer?.short
                      : row?.individual_input?.job?.customer?.name || ''}
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    {row?.individual_input?.vehicle} /
                    {row?.individual_input?.trailer_tail || '-'}
                  </div>
                </TableCell>
                <TableCell>{row?.individual_input?.container}</TableCell>{' '}
                <TableCell>{row?.remark}</TableCell>
                <TableCell>
                  {row?.bill_number?.prefix +
                    row?.bill_number?.running_number || ''}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={handleChangePage}
    />
  </Paper>
);

BillSummaryTable.propTypes = {
  billBookings: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleToInfoPage: PropTypes.func,
  handleToEditPage: PropTypes.func,
  handleDelete: PropTypes.func,
  showActionButton: PropTypes.bool,
};

export default BillSummaryTable;
