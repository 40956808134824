/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash';
import { Label } from '@mui/icons-material';
import { colors } from '@mui/material';

export default function BarChartOrder({ data }) {
  const [tickAmount, setTickAmount] = useState(data?.length);
  const nn = [data[0]?.maintenance_topic?.name || data[0]?.name];
  const series = [
    { name: 'จำนวน', data: [data[0]?.count || data[0]?.value] },
    // { name: 'dd', data: [5] },
  ];
  console.log('seirie', series[0], series[0].data[0]);
  _.map(data, (row, index) => {
    if (index > 0) {
      series[0].data.push(row?.count || row?.value);
      nn.push(row?.maintenance_topic?.name || row?.name);
    }
  });

  console.log('seirie', series);
  const options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    stroke: {
      width: [0, 4],
    },

    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          position: 'center', // top, center, bottom
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    yaxis: {
      logBase: 10,
      min: undefined,
      max: undefined,
      stepSize: undefined,
      floating: false,
      decimalsInFloat: undefined,
    },
    xaxis: {
      // type: 'category',
      categories: nn,
      //type: 'numeric',
      //labels: nn,
      tickAmount,
    },
  };
  console.log('series', series);
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
}
