/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';

import { dayjs } from '../../../utils/functions';
import { ASSIGNMENT_TYPE } from '../../../utils/constants';
import { DatePicker } from '../../Datepicker';

const BillBookingForm = ({
  control,
  job,
  watch,
  setValue,
  errors,
  billBooking,
}) => {
  const settingPrice = (gasPrice) => {
    // console.log('ราคาน้ำมันที่ต้องการค้นหา', gasPrice);
    const selectedJob = watch('individual_input.job');
    // console.log('งานที่เลือก', selectedJob);
    const jobAssignmentType = selectedJob?.assignment_type;

    if (jobAssignmentType === ASSIGNMENT_TYPE.EXPORT.status_code) {
      console.log('ตรวจพบว่าเป็น Export');
      const foundedPrice = _.find(
        selectedJob?.zone_price?.export_cost_matrix,
        (eachCostMatrix) =>
          eachCostMatrix?.gas_price === parseInt(gasPrice, 10),
      );
      console.log('พบคู่อันดับราคา', foundedPrice);
      setValue('price', foundedPrice?.price);
    } else {
      console.log('ตรวจพบว่าเป็น Import');
      const foundedPrice = _.find(
        selectedJob?.zone_price?.import_cost_matrix,
        (eachCostMatrix) =>
          eachCostMatrix?.gas_price === parseInt(gasPrice, 10),
      );
      console.log('พบคู่อันดับราคา', foundedPrice);
      setValue('price', foundedPrice?.price);
    }
  };

  const renderIndividualInput = () => (
    <div className="w-full flex flex-wrap">
      <div className="w-full lg:w-1/3 px-1 py-2">
        <Controller
          name="individual_input.vehicle"
          control={control}
          defaultValue={
            billBooking ? billBooking?.individual_input?.vehicle : ''
          }
          render={({ field }) => (
            <TextField size="small" {...field} fullWidth label="ทะเบียนหัวรถ" />
          )}
        />
      </div>

      <div className="w-full lg:w-1/3 px-1 py-2">
        <Controller
          name="individual_input.trailer_tail"
          control={control}
          defaultValue={
            billBooking ? billBooking?.individual_input?.trailer_tail : ''
          }
          render={({ field }) => (
            <TextField size="small" {...field} fullWidth label="ทะเบียนหางรถ" />
          )}
        />
      </div>

      <div className="w-full lg:w-1/3 px-1 py-2">
        <Controller
          name="individual_input.container"
          control={control}
          defaultValue={
            billBooking ? billBooking?.individual_input?.container : ''
          }
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              fullWidth
              label="ตู้คอนเทนเนอร์"
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        {!_.isEmpty(job?.rows) && (
          <Controller
            name="individual_input.job"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(e, value) => {
                  field.onChange(value);
                  if (value?.is_fixed_cost) {
                    setValue('price', value?.fixed_cost);
                  }
                }}
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={job?.rows}
                autoHighlight
                getOptionLabel={(option) => `${option?.name}`}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    required
                    fullWidth
                    label="งาน"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    helperText={
                      errors.individual_input?.job && 'กรุณาใส่ข้อมูล'
                    }
                  />
                )}
              />
            )}
          />
        )}
      </div>

      {watch('individual_input.job')?.is_fixed_cost === false && (
        <div className="w-full  px-1 py-2">
          <Controller
            name="individual_input.gas_price"
            control={control}
            defaultValue={
              billBooking ? billBooking?.individual_input?.gas_price : ''
            }
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                onChange={(event, value) => {
                  field.onChange(event.target.value);
                  settingPrice(event.target.value);
                }}
                fullWidth
                label="ราคาน้ำมัน"
              />
            )}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name="booking_number"
          control={control}
          defaultValue={billBooking ? billBooking?.booking_number : ''}
          render={({ field }) => (
            <TextField size="small" {...field} fullWidth label="Booking No." />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="date"
          control={control}
          defaultValue={
            billBooking
              ? dayjs(billBooking?.start).format('YYYY-MM-DD')
              : dayjs().format('YYYY-MM-DD')
          }
          render={({ field }) => (
            <DatePicker
              label="วันที่"
              {...field}
              setValue={(value) => field.onChange(value)}
              value={field.value}
            />
          )}
        />
      </div>
      {/* <div className="w-full px-1 py-2">
        <div className="border w-full rounded-md p-2">
          <div className="flex w-full justify-center">
            <Controller
              name="from_vehicle_schedule"
              control={control}
              render={({ field }) => (
                <div className="flex gap-2">
                  <div className="self-center">
                    <Typography>กรอกเอง</Typography>
                  </div>
                  <FormControlLabel
                    label="จากการจัดรถ"
                    control={<Switch {...field} />}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div> */}
      {!watch('from_vehicle_schedule') && renderIndividualInput()}{' '}
      <div className="w-full px-1 py-2">
        <Controller
          name="price"
          control={control}
          defaultValue={billBooking ? billBooking?.price : ''}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              fullWidth
              label="ราคา"
              disabled={!watch('individual_input.job')?.is_fixed_cost}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="remark"
          control={control}
          defaultValue={billBooking ? billBooking?.remark : ''}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              fullWidth
              label="หมายเหตุ"
              multiline
              rows={3}
            />
          )}
        />
      </div>
    </div>
  );
};

BillBookingForm.propTypes = {
  control: PropTypes.object,
  job: {
    rows: PropTypes.arrayOf(PropTypes.object),
  },
  watch: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  billBooking: PropTypes.object,
};

export default BillBookingForm;
