import {
  COMMENT_ALL,
  COMMENT_GET,
  COMMENT_PUT,
  COMMENT_DELETE,
  COMMENT_POST,
  COMMENT_RESET,
  COMMENT_LOADING,
  COMMENT_ERROR,
} from '../../actions/types';

const initialState = {
  maintenanceProcess: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case COMMENT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case COMMENT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case COMMENT_POST:
      return { isLoading: false, isCompleted: true };
    case COMMENT_PUT:
      return { isLoading: false, isCompleted: true };
    case COMMENT_DELETE:
      return { isLoading: false, isCompleted: true };
    case COMMENT_RESET:
      return { COMMENT: null, isLoading: true, isCompleted: true };
    case COMMENT_LOADING:
      return { isLoading: true, isCompleted: true };
    case COMMENT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
