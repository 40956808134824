import {
  MAINTENANCE_TYPE_ALL,
  MAINTENANCE_TYPE_GET,
  MAINTENANCE_TYPE_DEL,
  MAINTENANCE_TYPE_PUT,
  MAINTENANCE_TYPE_POST,
  MAINTENANCE_TYPE_LOADING,
  MAINTENANCE_TYPE_ERROR,
  MAINTENANCE_TYPE_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const maintenanceTypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TYPE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/maintenance-type`,
      payload,
    );
    dispatch({ type: MAINTENANCE_TYPE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TYPE_ERROR });
    throw new Error(error);
  }
};

export const maintenanceTypeAll =
  ({ name = '', size = '', page = 1 }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/maintenance-type?name=${name}&size=${size}&page=${page}`,
      );
      if (status === 200) {
        dispatch({ type: MAINTENANCE_TYPE_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: MAINTENANCE_TYPE_ERROR });
      throw new Error(error);
    }
  };

export const maintenanceTypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/maintenance-type/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MAINTENANCE_TYPE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TYPE_ERROR });
    throw new Error(error);
  }
};

export const maintenanceTypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TYPE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/maintenance-type/${id}`,
      payload,
    );
    dispatch({ type: MAINTENANCE_TYPE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TYPE_ERROR });
    throw new Error(error);
  }
};
export const maintenanceTypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TYPE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/maintenance-type/${id}`,
    );
    dispatch({ type: MAINTENANCE_TYPE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TYPE_ERROR });
    throw new Error(error);
  }
};

export const maintenanceTypeReset = () => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TYPE_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TYPE_ERROR });
    throw new Error(error);
  }
};
