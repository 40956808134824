import dayjs from 'dayjs';

export const dateDurationGenerator = (startDate, endDate) => {
  const selectedMonthArray = [];
  const firstSelectedMonth = dayjs(startDate).month();
  const firstSelectedYear = dayjs(startDate).year();
  const lastSelectedMonth = dayjs(endDate).month();
  const lastSelectedYear = dayjs(endDate).year();
  let runningYear = firstSelectedYear;

  while (runningYear <= lastSelectedYear) {
    if (runningYear === firstSelectedYear) {
      if (runningYear === lastSelectedYear) {
        let runningMonth = firstSelectedMonth;
        while (runningMonth <= lastSelectedMonth) {
          const runningDate = dayjs()
            .month(runningMonth)
            .year(runningYear)
            .toDate();
          selectedMonthArray.push(runningDate);
          runningMonth += 1;
        }
      } else {
        let runningMonth = firstSelectedMonth;
        while (runningMonth <= 11) {
          const runningDate = dayjs()
            .month(runningMonth)
            .year(runningYear)
            .toDate();
          selectedMonthArray.push(runningDate);
          runningMonth += 1;
        }
      }
    } else if (runningYear === lastSelectedYear) {
      let runningMonth = 0;
      while (runningMonth <= lastSelectedMonth) {
        const runningDate = dayjs()
          .month(runningMonth)
          .year(runningYear)
          .toDate();
        selectedMonthArray.push(runningDate);
        runningMonth += 1;
      }
    } else {
      let runningMonth = 0;
      while (runningMonth <= 11) {
        const runningDate = dayjs()
          .month(runningMonth)
          .year(runningYear)
          .toDate();
        selectedMonthArray.push(runningDate);
        runningMonth += 1;
      }
    }
    runningYear += 1;
  }

  return selectedMonthArray;
};

export default dateDurationGenerator;
