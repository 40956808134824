/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import { TextField, Box, Autocomplete } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function DriverForm({
  Controller,
  control,
  errors,
  employee,
  vehicle,
  driver,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-1">
        <Controller
          name={'employee'}
          control={control}
          defaultValue={driver ? driver?.employee : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              disableClearable
              name={field.name}
              defaultValue={field.value}
              ref={field.ref}
              options={_.map(employee.rows, (row, index) => ({
                index: index + 1,
                ...row,
              }))}
              autoHighlight
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname || ''}`
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {`${option.index} | ${option.firstname} ${
                    option.lastname || ''
                  }`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="คนขับ"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  helperText={errors.employee && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'vehicle'}
          control={control}
          rules={{ required: false }}
          defaultValue={driver ? driver?.vehicle : undefined}
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              ref={field.ref}
              defaultValue={field.value}
              options={_.map(vehicle.rows, (row, index) => ({
                index: index + 1,
                ...row,
              }))}
              autoHighlight
              getOptionLabel={(option) => ` ${option.licence_number} `}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {`${option.index} | ${option?.type?.name} ${
                    option.licence_number
                  } ${
                    option?.driver
                      ? `คนขับปัจจุบัน ${option?.driver?.employee?.firstname} ${option?.driver?.employee?.lastname}`
                      : ''
                  }`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="รถ"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  helperText={errors.driver && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        />
      </div>
    </div>
  );
}

DriverForm.propTypes = {
  employee: PropTypes.object,
  errors: PropTypes.shape({
    employee: PropTypes.object,
  }),
  vehicle: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  Controller: PropTypes.func,
  control: PropTypes.object,
  driver: PropTypes.object,
};

export default DriverForm;
