/* eslint-disable react/prop-types */
import React from 'react';
import { Card, TableContainer, Chip, Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import WheelMaintenHistoryTrailertailTable from './WheelMaintenHistoryTrailertailTable';

export default function WheelMaintananceHisTrailerTable({
  wheelTransection,
  page,
  size,
  setPage,
  setSize,
}) {
  console.log('wheelTransection', wheelTransection);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 5));
    setPage(1);
  };

  return (
    <div className="pt-4  ">
      <Card>
        <h3 className="p-4 text-xl font-bold">ประวัติการเปลี่ยนยาง</h3>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {' '}
                  <h1 className="font-bold">ลำดับ </h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">วันที่</h1>
                </TableCell>

                <TableCell align="center">
                  <h1 className="font-bold">รายการ</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">ตำแหน่งยาง</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">เลขไมล์</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">หมายเลขยาง</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">สาเหตุ</h1>
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wheelTransection?.rows?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              ) : (
                wheelTransection?.rows?.map((each, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {dayjs(each?.createdAt).format('DD/MM/BBBB')}
                    </TableCell>
                    <TableCell align="center">
                      {each?.topic === 'IN' ? (
                        <Chip label={'ใส่ยาง'} color="success" />
                      ) : (
                        <Chip label={'ถอดยาง'} color="warning" />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {each?.position || each?.wheel?.position}
                    </TableCell>
                    <TableCell align="center">
                      {each?.topic === 'IN'
                        ? each?.mileageIn || '-'
                        : each?.mileageOut || '-'}
                    </TableCell>
                    <TableCell align="center">
                      {each?.wheel?.wheelNumber}
                    </TableCell>
                    <TableCell align="center">{each?.cause || ' -'}</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={wheelTransection?.total}
          rowsPerPage={size || 15}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
}
WheelMaintenHistoryTrailertailTable.PropTypes = {
  wheelTransection: PropTypes.object,
  success: PropTypes.object,
  page: PropTypes.object,
  size: PropTypes.bool,
  setPage: PropTypes.object,
  setSize: PropTypes.object,
};
WheelMaintenHistoryTrailertailTable.defaultProps = {
  wheelTransection: '',
  success: false,
  page: '',
  size: '',
  setPage: '',
  setSize: '',
};
