import {
  CONTAINER_ALL,
  CONTAINER_GET,
  CONTAINER_POST,
  CONTAINER_PUT,
  CONTAINER_DEL,
  CONTAINER_LOADING,
  CONTAINER_ERROR,
} from '../../actions/types';

const initialState = {
  container: null,
  isLoading: true,
  isCompleted: true,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
    switch (action.type) {
      case CONTAINER_LOADING:
        return { isLoading: true, isCompleted: true };
      case CONTAINER_ERROR:
        return { isLoading: false, isCompleted: false };
      case CONTAINER_ALL:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case CONTAINER_GET:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case CONTAINER_POST:
        return { isLoading: false, isCompleted: true };
      case CONTAINER_PUT:
        return { isLoading: false, isCompleted: true };
      case CONTAINER_DEL:
        return { isLoading: false, isCompleted: true };
      default:
        return state;
    }
  }
