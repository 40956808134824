import {
  BILL_ALL,
  BILL_GET,
  BILL_DELETE,
  BILL_PUT,
  BILL_POST,
  BILL_LOADING,
  BILL_ERROR,
  BILL_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const billCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: BILL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/bill`,
      payload,
    );
    dispatch({ type: BILL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_ERROR });
    throw new Error(error);
  }
};

export const billAll =
  ({
    size = '',
    page = 1,
    name = '',
    completed = '',
    date = '',
    startDate = '',
    endDate = '',
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/bill?size=${size}&page=${page}&name=${name}&completed=${completed}&date=${date}&start_date=${startDate}&end_date=${endDate}`,
      );
      if (status === 200) {
        dispatch({ type: BILL_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: BILL_ERROR });
      throw new Error(error);
    }
  };

export const billGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/bill/${id}`,
    );
    if (status === 200) {
      dispatch({ type: BILL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_ERROR });
    throw new Error(error);
  }
};

export const billPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: BILL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/bill/${id}`,
      payload,
    );
    dispatch({ type: BILL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_ERROR });
    throw new Error(error);
  }
};
export const billDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: BILL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/bill/${id}`,
    );
    dispatch({ type: BILL_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_ERROR });
    throw new Error(error);
  }
};

export const billReset = () => async (dispatch) => {
  try {
    dispatch({ type: BILL_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_ERROR });
    throw new Error(error);
  }
};
