import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { CONFIG } from '../../utils/constants';
import { BillBookingForm } from '../../components/Forms';

function CreateBillBooking({ title, subtitle }) {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(actions.jobAll({ page: 1, size: CONFIG.infiniteLimit }));
  }, []);

  const handleSubmitBillBooking = async (data) => {
    try {
      await dispatch(actions.billBookingCreate(data));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert('สร้างรายการออกบิลไม่สำเร็จ');
      console.error('Error on Create Bill', error);
    }
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-4">
      <form onSubmit={handleSubmit(handleSubmitBillBooking)}>
        <Card>
          <CardContent>
            <BillBookingForm
              control={control}
              errors={errors}
              job={job}
              setValue={setValue}
              watch={watch}
            />
            <div className="flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );

  if (job.isLoading) {
    return <Loading />;
  }

  if (!job.isLoading && job.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

CreateBillBooking.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateBillBooking.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateBillBooking;
