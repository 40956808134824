export const HeaderMaintenanceReportType1 = (info, cmmsSetting) => [
  {
    table: {
      widths: ['20%', '60%', '20%'],
      margin: [20, 0],
      heights: ['auto'],
      body: [
        [
          cmmsSetting?.allow_left_logo
            ? {
                image: `${info?.image_logo}`,
                fit: [100, 100],
                alignment: 'center',
                margin: [0, 20],
                border: [false, false, false, false],
              }
            : { text: '', border: [false, false, false, false] },
          {
            alignment: 'center',
            margin: [0, 0, 0, 12],
            border: [false, false, false, false],
            text: [
              {
                text: `${info?.owner?.name}\n`,
                fontSize: '18',
                bold: true,
              },
              {
                text: `${info?.owner?.address?.house_number || ''} ${
                  info?.owner?.address?.village_number
                    ? `หมู่ที่${info?.owner?.address?.village_number}`
                    : ''
                } ${info?.owner?.address?.road || ''} ${
                  info?.owner?.address?.subdistrict || ''
                } ${info?.owner?.address?.district || ''} ${
                  info?.owner?.address?.province || ''
                } ${info?.owner?.address?.postcode || ''}\n`,
                fontSize: '10',
              },
              {
                text: `โทร. ${info?.owner.telephone || '-'} แฟกซ์ ${
                  info?.owner.fax || '-'
                }\n`,
                fontSize: '10',
              },
              {
                text: `อีเมล ${info?.owner?.email || '-'} เลขที่ผู้เสียภาษี ${
                  info?.owner?.tax || '-'
                }\n`,
                fontSize: '10',
              },
            ],
          },
          cmmsSetting?.allow_right_logo
            ? {
                image: `${info?.image_subLogo}`,
                fit: [100, 100],
                alignment: 'center',
                margin: [0, 20],
                border: [false, false, false, false],
              }
            : {
                text: '',
                border: [false, false, false, false],
              },
        ],
      ],
    },
  },
];
export default HeaderMaintenanceReportType1;
