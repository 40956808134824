/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import {
  showDeliveryOrderStatus,
  addressToString,
  dayjs,
  currencyFormatter,
} from '../../../utils/functions';

const DeliveryOrderCard = ({ deliveryOrder, handleReady, handleCancle }) => {
  const renderActionButton = () => (
    <div>
      <div className="flex  gap-1">
        <Link to={`/tms/delivery-order/edit/${deliveryOrder?._id}`}>
          <Button variant="contained" size="small" color="warning">
            แก้ไข
          </Button>
        </Link>
        <div>
          <Button
            variant="contained"
            size="small"
            color="success"
            disabled={deliveryOrder?.ready}
            onClick={() => handleReady(deliveryOrder?._id)}
          >
            พร้อม
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            color="error"
            disabled={deliveryOrder?.completed || deliveryOrder?.deleted}
            onClick={() => handleCancle(deliveryOrder?._id)}
          >
            ยกเลิก
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="w-full flex justify-between">
        <div className="font-semibold text-lg">
          <div className="flex gap-2">
            <div>รายการจัดส่งสินค้า</div>
            {showDeliveryOrderStatus({ ...deliveryOrder })}{' '}
          </div>
        </div>{' '}
        <div>{renderActionButton()}</div>
      </div>
      <div className="w-full my-1 px-1 flex flex-wrap font-sansserif">
        <div className="w-full lg:w-1/2 ">
          <div className="w-full text-sm py-2 font-semibold">ชื่องาน</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.job?.name}
          </div>
        </div>{' '}
        <div className="w-full lg:w-1/2">
          <div className="w-full text-sm py-2 font-semibold">เลขที่รายการ</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.running_number}
          </div>
        </div>{' '}
        <div className="w-full ">
          <div className="w-full text-sm py-2 font-semibold">ลูกค้า</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.customer?.name}
            <div> {addressToString(deliveryOrder?.customer?.address)} </div>
          </div>
        </div>{' '}
        <div className="w-full lg:w-1/2 ">
          <div className="w-full text-sm py-2 font-semibold">สถานที่รับ</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.origin || '-'}
          </div>
        </div>
        <div className="w-full lg:w-1/2 ">
          <div className="w-full text-sm py-2 font-semibold">สถานที่ส่ง</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.destination || '-'}
          </div>
        </div>
        <div className="w-full lg:w-1/2 ">
          <div className="w-full text-sm py-2 font-semibold">
            วันที่รับสินค้า
          </div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {dayjs(deliveryOrder?.start_date).format('D MMM BBBB')}
          </div>
        </div>
        <div className="w-full lg:w-1/2 ">
          <div className="w-full text-sm py-2 font-semibold">วันส่งสินค้า </div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {dayjs(deliveryOrder?.end_date).format('D MMM BBBB')}
          </div>
        </div>
        <div className="w-full lg:w-1/3 ">
          <div className="w-full text-sm py-2 font-semibold">
            เลขที่ใบแจ้งหนี้
          </div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.invoice_number || '-'}
          </div>
        </div>
        <div className="w-full lg:w-1/3 ">
          <div className="w-full text-sm py-2 font-semibold">
            จำนวนหีบห่อ หรือ พาเลท
          </div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.amount || '-'} ชิ้น
          </div>
        </div>
        <div className="w-full lg:w-1/3 ">
          <div className="w-full text-sm py-2 font-semibold">
            หมายเลขตู้คอนเทนเนอร์
          </div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.container?.licence_number}
          </div>
        </div>
        <div className="w-full lg:w-1/2 ">
          <div className="w-full text-sm py-2 font-semibold">น้ำหนักรวม</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.weight} กิโลกรัม
          </div>
        </div>
        <div className="w-full lg:w-1/2 ">
          <div className="w-full text-sm py-2 font-semibold">ราคา</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {currencyFormatter.format(deliveryOrder?.price)} บาท
          </div>
        </div>
        <div className="w-full ">
          <div className="w-full text-sm py-2 font-semibold">หมายเหตุ</div>
          <div className="rounded border-2 p-2 mr-2 text-sm">
            {deliveryOrder?.remark || '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

DeliveryOrderCard.propTypes = {
  deliveryOrder: PropTypes.object,
  handleReady: PropTypes.func,
  handleCancle: PropTypes.func,
};

export default DeliveryOrderCard;
