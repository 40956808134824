import {
  BILL_HEADER_ALL,
  BILL_HEADER_GET,
  BILL_HEADER_POST,
  BILL_HEADER_PUT,
  BILL_HEADER_DELETE,
  BILL_HEADER_LOADING,
  BILL_HEADER_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const billHeaderCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: BILL_HEADER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/bill-header`,
      {
        ...payload,
      },
    );
    dispatch({ type: BILL_HEADER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_HEADER_ERROR });
  }
};

export const billHeaderAll =
  ({ name = '', size = '', page = 1 }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/bill-header?name=${name}&size=${size}&page=${page}`,
      );
      if (status === 200) {
        dispatch({ type: BILL_HEADER_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: BILL_HEADER_ERROR });
    }
  };

export const billHeaderGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/bill-header/${id}`,
    );
    if (status === 200) {
      dispatch({ type: BILL_HEADER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_HEADER_ERROR });
  }
};

export const billHeaderPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: BILL_HEADER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/bill-header/${id}`,
      payload,
    );
    dispatch({ type: BILL_HEADER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_HEADER_ERROR });
  }
};

export const billHeaderDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: BILL_HEADER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/bill-header/${id}`,
    );
    dispatch({ type: BILL_HEADER_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_HEADER_ERROR });
  }
};
