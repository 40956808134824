import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ViewTitle } from '../../components/ViewTitle';

import * as actions from '../../redux/actions';
import MachineryDetailCard from '../../components/Card/MECH/MachineryDetailCard';
import { BackButton } from '../../components/Button';
import MachineryDocumentCard from '../../components/Card/MECH/MachineryDocumentCard';

export default function DetailMachinery({ title, subtitle }) {
  const params = useParams();
  const dispatch = useDispatch();

  const machinery = useSelector((state) => state.mechinery);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderDetailMachinery = () => (
    <MachineryDetailCard machinery={machinery} />
  );

  const renderDocument = () => <MachineryDocumentCard />;

  const renderBackButton = () => <BackButton />;

  useEffect(() => {
    dispatch(actions.mechineryGet(params?.id));
    return () => {};
  }, []);

  return (
    <div>
      {renderTitle()}
      {renderBackButton()}
      {renderDetailMachinery()}
      {renderDocument()}
    </div>
  );
}
DetailMachinery.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMachinery.defaultProps = {
  title: '',
  subtitle: '',
};
