import {
  MATERIAL_WITHDRAW_ALL,
  MATERIAL_WITHDRAW_GET,
  MATERIAL_WITHDRAW_DEL,
  MATERIAL_WITHDRAW_PUT,
  MATERIAL_WITHDRAW_POST,
  MATERIAL_WITHDRAW_LOADING,
  MATERIAL_WITHDRAW_ERROR,
} from '../../actions/types';

const initialState = {
  materialWithdraw: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIAL_WITHDRAW_LOADING:
      return { isLoading: true, isCompleted: true };
    case MATERIAL_WITHDRAW_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MATERIAL_WITHDRAW_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_WITHDRAW_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_WITHDRAW_POST:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_WITHDRAW_PUT:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_WITHDRAW_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
