import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import _ from 'lodash';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

const ReturnMaterialForm = ({
  control,
  materialTransactionType,
  watch,
  setValue,
  errors,
}) => {
  const { fields } = useFieldArray({ control, name: 'material' });

  const materials = _.filter(fields, (e) => e.amount > 0);

  console.log('fieldss');
  return (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="">
            <div className="w-full lg:w-1/2 my-2 px-2">
              <Controller
                control={control}
                name="material_transaction_type"
                defaultValue={
                  _.filter(materialTransactionType?.rows, {
                    direction: 'add',
                  })?.[0]?._id
                }
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>วิธีการดำเนินการ</InputLabel>
                    <Select
                      {...field}
                      fullWidth
                      label="วิธีการดำเนินการ"
                      required
                    >
                      {_.map(
                        _.filter(materialTransactionType?.rows, {
                          direction: 'add',
                        }),
                        (_transactionType, index) => (
                          <MenuItem key={index} value={_transactionType?._id}>
                            {_transactionType?.name}{' '}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 my-2 px-2">
              <Controller
                control={control}
                name="remark"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={2}
                    label="หมายเหตุ"
                  />
                )}
              />
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ลำดับที่</TableCell>
                <TableCell>วัสดุ</TableCell>
                <TableCell>จำนวนที่มี</TableCell>
                <TableCell>ต้องการคืน</TableCell>
                <TableCell>ล็อตที่ต้องการคืน</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(materials, (_field, index) => (
                <TableRow key={_field.id}>
                  <TableCell> {index + 1} </TableCell>
                  <TableCell> {_field?.material?.name} </TableCell>
                  <TableCell>
                    {_field?.amount} {_field?.material?.unit}{' '}
                  </TableCell>
                  {!watch(`material[${index}].enable_return`) && (
                    <TableCell colSpan={2}>
                      <Controller
                        control={control}
                        name={`material[${index}].enable_return`}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            label="คืนวัสดุชิ้นนี้"
                            control={<Checkbox {...field} />}
                          />
                        )}
                      />
                    </TableCell>
                  )}
                  {watch(`material[${index}].enable_return`) && (
                    <TableCell>
                      <div className="flex gap-1">
                        <Controller
                          control={control}
                          name={`material[${index}].enable_return`}
                          render={({ field }) => (
                            <FormControlLabel
                              label="คืนวัสดุชิ้นนี้"
                              control={
                                <Checkbox
                                  {...field}
                                  defaultChecked={field.value}
                                  onChange={(event) => {
                                    field.onChange(event.target.checked);
                                    setValue(
                                      `material[${index}].material_stock_lot`,
                                      'NO',
                                    );
                                    setValue(`material[${index}].amount`, 0);
                                  }}
                                />
                              }
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                  )}
                  {watch(`material[${index}].enable_return`) && (
                    <TableCell>
                      <div className="flex flex-col">
                        {_.map(_field?.origin, (eachOrigin, originIndex) => (
                          <div
                            key={`ORG-${originIndex}`}
                            className="my-2 flex gap-2 w-full justify-between"
                          >
                            <div>
                              {originIndex + 1}. หมายเลขอ้างอิง{' '}
                              {eachOrigin?.material_stock_lot?.running_number}
                              <br /> คู่ค้า{' '}
                              {eachOrigin?.material_stock_lot?.supplier?.name ||
                                '-'}
                              <br />
                              หมายเลขล็อต{' '}
                              {eachOrigin?.material_stock_lot?.lot_number ||
                                '-'}
                              <br />
                              จำนวนในการเบิก {eachOrigin?.amount}{' '}
                              {_field?.material?.unit || ''}
                            </div>
                            <div>
                              <Controller
                                control={control}
                                name={`material[${index}].lot[${originIndex}].amount`}
                                rules={{
                                  max: eachOrigin?.amount,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    size="small"
                                    label="จำนวนที่จะคืน"
                                    helperText={
                                      errors?.material?.[index]?.lot?.[
                                        originIndex
                                      ]?.amount
                                        ? 'การใส่จำนวนผิดพลาด หรือ อาจจะใส่เกินจากที่สต็อกมีอยู่'
                                        : ''
                                    }
                                  />
                                )}
                              />{' '}
                            </div>
                          </div>
                        ))}
                      </div>

                      <Controller
                        name={`material[${index}].resultAmount`}
                        control={control}
                        value={_field?.amount}
                        render={({ field }) => (
                          <input type="hidden" {...field} />
                        )}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

ReturnMaterialForm.propTypes = {
  control: PropTypes.object,
  materialTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
};

export default ReturnMaterialForm;
