import React from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

const OneZonePricingForm = ({ control }) => {
  const pricingCost = [
    13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    32, 33, 34, 35, 36, 37, 38, 39, 40,
  ];

  const renderPricingCostMatrix = (shippingType) =>
    _.map(pricingCost, (eachPrice, priceIndex) => (
      <div className="flex my-2 " key={priceIndex}>
        <div className="w-1/4 px-2">
          <Controller
            name={`${shippingType}_cost_matrix[${priceIndex}].gas_price`}
            control={control}
            defaultValue={eachPrice}
            render={({ field }) => (
              <TextField {...field} size="small" label="ราคาน้ำมัน" disabled />
            )}
          />
        </div>
        <div className="w-3/4 px-2">
          <Controller
            name={`${shippingType}_cost_matrix[${priceIndex}].price`}
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField {...field} size="small" label="ราคา" />
            )}
          />
        </div>
      </div>
    ));

  return (
    <div>
      {' '}
      <Card>
        <CardContent>
          <div className="my-2">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label="ชื่อโซน" fullWidth />
              )}
            />
          </div>
          <div className="text-lg font-semibold">นำเข้า</div>
          <div className="grid grid-cols-2 lg:grid-cols-4">
            {renderPricingCostMatrix('import')}
          </div>
          <div className="text-lg font-semibold">ส่งออก</div>
          <div className="grid grid-cols-2 lg:grid-cols-4">
            {renderPricingCostMatrix('export')}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

OneZonePricingForm.propTypes = { control: PropTypes.object };

export default OneZonePricingForm;
