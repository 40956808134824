import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { VehicleForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { dayjs } from '../../utils/functions';

export default function EditVehicle({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const vehicle = useSelector((state) => state.vehicle);
  const [FileOpen, setFileOpen] = useState(false);
  const [file, setFile] = useState([]);
  const vehicletype = useSelector((state) => state.vehicletype);
  const history = useHistory();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      licence_number: vehicle.licence_number,
      type: vehicle.type,
      attribute: {
        ...vehicle?.attribute,
        buy_date: vehicle?.attribute?.buy_date
          ? dayjs(vehicle?.attribute?.buy_date).format('YYYY-MM-DD')
          : '',
      },
    },
  });

  useEffect(() => {
    dispatch(actions.vehicleGet(id));
    dispatch(actions.vehicletypeAll({ size: null }));
    return () => {};
  }, []);

  useEffect(() => {
    setValue(
      'attribute.buy_date',
      vehicle?.attribute?.buy_date
        ? dayjs(vehicle?.attribute?.buy_date).format('YYYY-MM-DD')
        : null,
    );
    setValue(
      'attribute.insurance_start',
      vehicle?.attribute?.insurance_start
        ? dayjs(vehicle?.attribute?.insurance_start).format('YYYY-MM-DD')
        : null,
    );
    setValue(
      'attribute.insureance_end',
      vehicle?.attribute?.insureance_end
        ? dayjs(vehicle?.attribute?.insureance_end).format('YYYY-MM-DD')
        : null,
    );

    return () => {};
  }, [vehicle]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.vehiclePut(id, data));
      await dispatch(actions.vehicleGet(id));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (vehicle.isLoading || vehicle.rows) {
    return <Loading />;
  }
  if (!vehicle.isLoading && vehicle.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <VehicleForm
                errors={errors}
                vehicle={vehicle}
                vehicletype={vehicletype}
                control={control}
                Controller={Controller}
                watch={watch}
                setValue={setValue}
                setFile={setFile}
                setFileOpen={setFileOpen}
                file={file}
                FileOpen={FileOpen}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  EditVehicle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  EditVehicle.defaultProps = {
    title: '',
    subtitle: '',
  };

  return <Error />;
}
