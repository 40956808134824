/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake';
import 'pdfmake-thaifont-2/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import imageToBase64 from '../../utils/functions/imageToBase64';
import THBText from 'thai-baht-text';
import currencyFormat from '../../utils/functions/currencyFormat';
import HeaderMaintenanceReportType1 from './maintenanceOrder/HeadMaintenanceOrderType1';
import { HeaderMaintenanceReportType2 } from './maintenanceOrder/HeadMaintenanceOrderType2';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const genMaterial = (topic) => {
  return _.map(topic, (_topic, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 12,
      colSpan: 1,
      alignment: 'center',
    },
    {
      text: '',
      border: [true, false, true, true],
      fontSize: 12,
      colSpan: 1,
    },
    {
      text: '',
      border: [true, false, true, true],
      fontSize: 12,
      colSpan: 1,
      alignment: 'center',
    },
  ]);
};

async function printPDF(order, info, me) {
  const {
    setting: { cmms: cmmsSetting },
  } = info;

  const genTopic = (topic) => {
    return _.map(topic, (_topic, index) => [
      {
        text: index + 1,
        border: [true, false, true, true],
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${_topic?.topic?.name}`,
        border: [true, false, true, true],
        colSpan: cmmsSetting?.attach_topic_amount ? 1 : 2,
      },
      cmmsSetting?.attach_topic_amount
        ? {
            text: `${_topic?.topic?.amount || 1}`,
            border: [true, false, true, true],
            alignment: 'center',
            colSpan: 1,
          }
        : {},
      {
        text: '',
        border: [true, false, true, true],
        alignment: 'center',
      },
    ]);
  };

  const genLine = (topic) => {
    if (!cmmsSetting?.printed_maintenance_order?.show_empty_space_topic) {
      return [];
    }

    return _.map(topic, (_topic, index) => [
      {
        text: '',
        border: [true, false, true, true],
        colSpan: 1,
        color: 'white',
        margin: [0, 8],
        alignment: 'center',
      },
      {
        text: '',
        border: [true, false, true, true],
        colSpan: cmmsSetting?.attach_topic_amount ? 1 : 2,
      },
      cmmsSetting?.attach_topic_amount
        ? {
            text: '',
            border: [true, false, true, true],
            colSpan: 1,
          }
        : {},
      {
        text: '',
        border: [true, false, true, true],
        colSpan: 1,
        alignment: 'center',
      },
    ]);
  };

  let imageLeftBase64 = '';
  if (cmmsSetting?.allow_left_logo) {
    imageLeftBase64 = await imageToBase64(info?.image_subLogo);
  }

  const maintenanceTopic = genTopic(order.maintenance_topic, order?.price);
  const materialBody = ['', '', ''];
  console.log('materialBody', materialBody);
  const maintenanceMaterial = genMaterial(materialBody);
  const maintenanceline = genLine(materialBody);
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 175, 40, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบแจ้งซ่อม',
    },
    header: [
      {
        text: `${dayjs().format('DD/MM/BBBB')}`,
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      cmmsSetting?.allow_left_logo || cmmsSetting?.allow_right_logo
        ? HeaderMaintenanceReportType1(info, cmmsSetting)
        : HeaderMaintenanceReportType2(info),
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: cmmsSetting?.printed_maintenance_order?.additional_title
          ? cmmsSetting?.printed_maintenance_order?.additional_title
          : 'ใบแจ้งซ่อม',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
      },
    ],

    content: [
      {
        style: 'tableExample',
        margin: [10, 0, 10, 2],
        fontSize: 10,
        table: {
          widths: ['10%', '40%', '15%', '30%'],
          body: [
            [
              {
                text: 'วันที่',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(order?.start_date).format('D MMMM  BBBB')}`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: cmmsSetting?.attach_time ? 'เวลา' : '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: cmmsSetting?.attach_time
                  ? dayjs(order?.start_date)?.format('HH:mm')
                  : '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เครื่องจักร',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: ` ${order?.vehicle?.licence_number || ''}  ${
                  order?.vehicle?.engname || ''
                }  ${order?.vehicle?.thainame || '-'} `,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                // colSpan: 1,
                // bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                //   order?.trailer_tail?.licence_number || '-'
                // } `,
                // colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              cmmsSetting?.printed_maintenance_order?.show_garage
                ? {
                    text: 'อู่ซ่อม',
                    colSpan: 1,
                    bold: true,
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
              cmmsSetting?.printed_maintenance_order?.show_garage
                ? {
                    text: `${order?.garage?.name || 'อู่ภายใน'}`,
                    colSpan: 1,
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                //   order?.mileage
                //     ? `${currencyFormat.format(order?.mileage)} km`
                //     : '-'
                // }`,
                // colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [10, 20, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        layout: {
          fillColor(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        table: {
          widths: ['10%', '*', '10%', '30%'],
          heights: [15],
          headerRows: 1,
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการซ่อม',
                colSpan: cmmsSetting?.attach_topic_amount ? 1 : 2,
                alignment: 'center',
                border: [true, true, true, true],
              },
              cmmsSetting?.attach_topic_amount
                ? {
                    text: 'จำนวน',
                    colSpan: 1,
                    alignment: 'center',

                    border: [true, true, true, true],
                  }
                : {},
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...maintenanceTopic,
            ...maintenanceline,
          ],
        },
      },
      cmmsSetting?.printed_maintenance_order?.show_empty_space_material
        ? {
            margin: [10, 20, 10, 0],
            style: 'tableExample',
            layout: {
              fillColor(rowIndex, node, columnIndex) {
                return rowIndex === 0 ? '#c5cae9' : null;
              },
            },
            fontSize: 11,
            table: {
              widths: ['15%', '*', '19%'],
              heights: [15],
              headerRows: 1,

              body: [
                [
                  {
                    text: 'ลำดับ',
                    colSpan: 1,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'รายการอะไหล่',
                    colSpan: 1,
                    alignment: 'center',

                    border: [true, true, true, true],
                  },
                  {
                    text: 'จำนวน',
                    colSpan: 1,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                ],
                ...maintenanceMaterial,
              ],
            },
          }
        : {},
      cmmsSetting?.printed_maintenance_order?.additional_remark
        ? {
            margin: [10, 20, 0, 20],
            fontSize: 11,
            columns: [
              {
                text: 'หมายเหตุ : ',
                bold: true,
                width: '15%',
              },
              {
                text: `${
                  cmmsSetting?.printed_maintenance_order?.additional_remark ||
                  ''
                }`,
              },
            ],
          }
        : {},
      {
        margin: [10, 10, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          body: [
            [
              cmmsSetting?.printed_maintenance_order?.sign_informer
                ? {
                    text: 'ลงชื่อ ...................................... ผู้แจ้ง',
                    colSpan: 1,
                    // bold: true,
                    alignment: 'center',
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
              cmmsSetting?.printed_maintenance_order?.sign_maintener
                ? {
                    text: 'ลงชื่อ ...................................... ช่าง',
                    colSpan: 1,
                    // bold: true,
                    alignment: 'center',
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
              cmmsSetting?.printed_maintenance_order?.sign_allower
                ? {
                    text: 'ลงชื่อ ...................................... ผู้อนุมัติ',
                    colSpan: 1,
                    fontSize: 12,
                    alignment: 'center',
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
            ],
            [
              cmmsSetting?.printed_maintenance_order?.sign_informer
                ? {
                    text: '(......................................)',
                    colSpan: 1,
                    // bold: true,
                    fontSize: 12,
                    alignment: 'center',
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
              cmmsSetting?.printed_maintenance_order?.sign_maintener
                ? {
                    text: '(......................................)',
                    colSpan: 1,
                    // bold: true,
                    fontSize: 12,
                    alignment: 'center',
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
              cmmsSetting?.printed_maintenance_order?.sign_allower
                ? {
                    text: '(......................................)',
                    colSpan: 1,
                    // bold: true,
                    fontSize: 12,
                    alignment: 'center',
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
            ],
          ],
        },
      },
      cmmsSetting?.printed_maintenance_order?.sign_chief_maintenaner
        ? {
            margin: [10, 20, 10, 0],
            style: 'tableExample',
            fontSize: 11,
            table: {
              heights: [15],
              widths: ['20%', '*', '20%'],
              body: [
                [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: 'ลงชื่อ ................................................ หัวหน้าช่าง',
                    colSpan: 1,
                    // bold: true,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  { text: '', border: [false, false, false, false] },
                ],
                [
                  { text: '', border: [false, false, false, false] },
                  {
                    text: `( ${
                      cmmsSetting?.printed_maintenance_order
                        ?.chief_maintener_name ||
                      '...................................................'
                    } )`,
                    colSpan: 1,
                    // bold: true,
                    fontSize: 12,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  { text: '', border: [false, false, false, false] },
                ],
              ],
            },
          }
        : {},
      cmmsSetting?.printed_maintenance_order?.sign_driver
        ? {
            margin: [10, 30, 10, 0],
            style: 'tableExample',
            fontSize: 11,
            table: {
              heights: [15],
              widths: ['20%', '*', '20%'],
              body: [
                [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: 'ลงชื่อ ................................................ พนักงานขับรถ',
                    colSpan: 1,
                    // bold: true,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  { text: '', border: [false, false, false, false] },
                ],
                [
                  { text: '', border: [false, false, false, false] },
                  {
                    text: `( ${order?.driver?.employee?.firstname || ''}   ${
                      order?.driver?.employee?.lastname || ''
                    }  )`,
                    colSpan: 1,
                    // bold: true,
                    fontSize: 12,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  { text: '', border: [false, false, false, false] },
                ],
              ],
            },
          }
        : {},
      cmmsSetting?.printed_maintenance_order?.show_comment_box
        ? {
            margin: [10, 20, 0, 5],
            fontSize: 12,
            columns: [
              {
                text: 'ความคิดเห็น ',
                bold: true,
              },
            ],
          }
        : {},
      cmmsSetting?.printed_maintenance_order?.show_comment_box
        ? {
            margin: [10, 0, 10, 20],
            fontSize: 12,
            columns: [
              {
                text: '................................................................................................................................................................................................\n................................................................................................................................................................................................\n................................................................................................................................................................................................\n................................................................................................................................................................................................ ',
              },
            ],
          }
        : {},
      {
        margin: [10, 20, 0, 10],
        columns: [
          cmmsSetting?.printed_maintenance_order?.sign_transaction_creator
            ? {
                text: `ผู้ทำรายการ ${me?.userData?.firstname || ''}  ${
                  me?.userData?.lastname || ''
                }`,
                bold: true,
                fontSize: 11,
              }
            : {},
          cmmsSetting?.printed_maintenance_order?.sign_acceptance
            ? {
                text: 'ลงชื่อ ........................................... ผู้รับเครื่องจักรหลังซ่อม',
                bold: true,
                fontSize: 11,
              }
            : {},
        ],
      },
    ],
    footer: [
      {
        margin: [20, 0, 0, 20],
        fontSize: 12,

        columns: [],
      },
    ],
  };

  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
