import {
  MAINTENANCE_PROCESS_ALL,
  MAINTENANCE_PROCESS_GET,
  MAINTENANCE_PROCESS_PUT,
  MAINTENANCE_PROCESS_DELETE,
  MAINTENANCE_PROCESS_POST,
  MAINTENANCE_PROCESS_RESET,
  MAINTENANCE_PROCESS_LOADING,
  MAINTENANCE_PROCESS_ERROR,
} from '../../actions/types';

const initialState = {
  maintenanceProcess: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_PROCESS_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAINTENANCE_PROCESS_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAINTENANCE_PROCESS_POST:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_PROCESS_PUT:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_PROCESS_DELETE:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_PROCESS_RESET:
      return { MAINTENANCE_PROCESS: null, isLoading: true, isCompleted: true };
    case MAINTENANCE_PROCESS_LOADING:
      return { isLoading: true, isCompleted: true };
    case MAINTENANCE_PROCESS_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
