import React from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function Profile({ title, subtitle }) {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      EDIT PROFILE
    </div>
  );
}

Profile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Profile.defaultProps = {
  title: '',
  subtitle: '',
};

export default Profile;
