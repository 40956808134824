import {
  JOB_ALL,
  JOB_GET,
  JOB_DELETE,
  JOB_PUT,
  JOB_POST,
  JOB_LOADING,
  JOB_ERROR,
  JOB_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const jobCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: JOB_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/job`,
      payload,
    );
    dispatch({ type: JOB_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: JOB_ERROR });
    throw new Error(error);
  }
};

export const jobAll =
  ({ size = '', page = 1, name = '', customer = '', isFixedCost = '' }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/job?size=${size}&page=${page}&name=${name}&customer=${customer}&isFixedCost=${isFixedCost}`,
      );
      if (status === 200) {
        dispatch({ type: JOB_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: JOB_ERROR });
      throw new Error(error);
    }
  };

export const jobGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/job/${id}`,
    );
    if (status === 200) {
      dispatch({ type: JOB_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: JOB_ERROR });
    throw new Error(error);
  }
};

export const jobPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: JOB_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/job/${id}`,
      payload,
    );
    dispatch({ type: JOB_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: JOB_ERROR });
    throw new Error(error);
  }
};
export const jobDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: JOB_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/job/${id}`,
    );
    dispatch({ type: JOB_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: JOB_ERROR });
    throw new Error(error);
  }
};

export const jobReset = () => async (dispatch) => {
  try {
    dispatch({ type: JOB_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: JOB_ERROR });
    throw new Error(error);
  }
};
