/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
import WheelSupplyTable from '../../components/Table/WMS/WheelSupplyTable';
import React, { useEffect, useState } from 'react';
import { ViewTitle } from '../../components/ViewTitle';
import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading';
import { Button, Stack } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

export default function WheelSupply({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const prop = {
    name,
    page,
    size,
    setName,
    setPage,
    setSize,
    handleEdit: (id) => {
      history.push(`/wms/wheel-supply/detail/${id}`);
    },
  };
  const renderTable = () => (
    <WheelSupplyTable wheelSupply={wheelSupply} {...prop} />
  );
  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          component={Link}
          to={'/wms/wheel-supply/create'}
        >
          เพิ่ม
        </Button>
        {/* <Button variant="contained" color="success" onClick={handleCSVOpen}>
          อัปโหลดยาง
        </Button> */}
      </Stack>
    </div>
  );

  useEffect(() => {
    dispatch(actions.wheelSupplyAll({ name, page, size }));
    return () => {};
  }, [name, page, size]);

  if (wheelSupply.isCompleted && !wheelSupply.isLoading) {
    return (
      <div>
        {renderTitle()}
        {renderAddButtom()}
        {renderTable()}
      </div>
    );
  }
  if (!wheelSupply.isCompleted && wheelSupply.isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return <div>Page Not found</div>;
  }
}
