import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { InformationForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function Information({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const [logo, setLogo] = useState([]);
  const [subLogo, setSubLogo] = useState([]);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    dispatch(actions.informationAll());
    return () => {};
  }, [logo, subLogo]);

  const onSubmit = async (data, event) => {
    try {
      const payload = {
        ...data,
        image_logo: logo[0]?.data_url,
        image_subLogo: subLogo[0]?.data_url,
      };
      event.preventDefault();
      await dispatch(actions.informationPut(information._id, payload));
      await dispatch(actions.informationAll());
      setLogo([]);
      setSubLogo([]);
      alert('สำเร็จ');
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <Card className="p-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InformationForm
          Controller={Controller}
          control={control}
          errors={errors}
          information={information}
          logo={logo}
          subLogo={subLogo}
          setLogo={setLogo}
          setSubLogo={setSubLogo}
        />
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </Card>
  );

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
Information.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Information.defaultProps = {
  title: '',
  subtitle: '',
};

export default Information;
