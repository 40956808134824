import React from 'react';

const showVehicleScheduleStatus = ({
  started,
  pending,
  completed,
  billed,
  deleted,
}) => {
  if (deleted) {
    return (
      <div className="bg-red-300 px-2 rounded text-center font-semibold">
        ยกเลิก
      </div>
    );
  }
  if (billed) {
    return (
      <div className="bg-cyan-300 px-2 rounded text-center font-semibold">
        ออกบิลแล้ว
      </div>
    );
  }
  if (completed) {
    return (
      <div className="bg-green-300 px-2 rounded text-center font-semibold">
        สำเร็จ
      </div>
    );
  }
  if (pending) {
    return (
      <div className="bg-blue-300 px-2 rounded text-center font-semibold">
        กำลังดำเนินการ{' '}
      </div>
    );
  }
  if (started) {
    return (
      <div className="bg-indigo-400 px-2 rounded text-center font-semibold">
        รอดำเนินการ{' '}
      </div>
    );
  }
};

export default showVehicleScheduleStatus;
