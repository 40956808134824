/* eslint-disable camelcase */
import { Button } from '@mui/material';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function ImageUpload({
  images,
  setImages,
  preview_size,
  maxNumber,
}) {
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div>
          <div className="flex justify-center">
            {imageList.map((image, index) => (
              <div key={index} className="my-4">
                <div className="flex justify-end text-red-500">
                  <i
                    className="fas fa-window-close cursor-pointer"
                    aria-hidden="true"
                    onClick={() => onImageRemove(index)}
                  ></i>
                </div>

                <img src={image.data_url} alt="" width={preview_size} />
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            {_.size(imageList) === maxNumber ? (
              <>
                {' '}
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  size="sm"
                  {...dragProps}
                >
                  อัพโหลดรูปภาพ
                </Button>
              </>
            ) : (
              <>
                {' '}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onImageUpload}
                  size="sm"
                  {...dragProps}
                >
                  อัพโหลดรูปภาพ
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </ImageUploading>
  );
}

ImageUpload.defaultProps = {
  preview_size: '250',
  maxNumber: 5,
};

ImageUpload.propTypes = {
  preview_size: PropTypes.string,
  images: PropTypes.array,
  setImages: PropTypes.func,
  maxNumber: PropTypes.number,
};
