import React from 'react';
import { Avatar, Card, CardContent, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';

import { MAINTENANCE_ORDER_STATUS } from '../../../utils/constants';
import {
  showMaintenanceStatusOnBig,
  showMaintenanceLevelOnBig,
} from '../../../utils/functions';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const MaintenanceOrderCard = ({
  maintenanceOrder,
  showMaintanenceWithdraw = true,
  showMaintanance = true,
  showDriver = true,
  showAllower = false,
}) => {
  const information = useSelector((state) => state.information);

  const cmmsSetting = information?.setting?.cmms;

  return (
    <div className="my-2">
      <Card>
        <CardContent>
          {showMaintanance && showMaintanenceWithdraw && (
            <div>
              <div className="flex flex-wrap ">
                <div className="w-1/2 lg:w-1/6  px-2 py-1">
                  <div className=" text-sm font-semibold">
                    หมายเลขการซ่อมบำรุง
                  </div>
                  <div className="text-2xl">
                    {maintenanceOrder?.running_number || '-'}
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/4 px-2 lg:pl-4 py-1">
                  {maintenanceOrder?.maintenance_type?.name ? (
                    <div className=" text-sm font-semibold">
                      {' '}
                      ประเภทการซ่อมบำรุง
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="text-2xl">
                    {maintenanceOrder?.maintenance_type?.name}
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/5 px-2 lg:pl-4 py-1">
                  <div className=" text-sm font-semibold">ผู้แจ้งซ่อม</div>
                  <div className="flex gap-1 my-1">
                    <div>
                      <Avatar src={maintenanceOrder?.informer?.image?.url}>
                        {maintenanceOrder?.informer?.firstname}{' '}
                        {maintenanceOrder?.informer?.lastname}
                      </Avatar>
                    </div>
                    <div className="text-base lg:text-lg self-center">
                      {maintenanceOrder?.informer?.firstname}{' '}
                      <span className="text-base">
                        {maintenanceOrder?.informer?.lastname}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/5 px-2 lg:pl-4 py-1">
                  <div className=" text-sm font-semibold">
                    ระดับการซ่อมบำรุง
                  </div>
                  <div className="text-base lg:text-2xl my-1">
                    {showMaintenanceLevelOnBig(
                      maintenanceOrder?.maintenance_level,
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-1/6 px-2 lg:pl-4 py-1">
                  <div className=" text-sm font-semibold">สถานะ</div>
                  <div className="text-base lg:text-2xl my-1 ">
                    {showMaintenanceStatusOnBig(maintenanceOrder?.status)}
                  </div>
                </div>
              </div>
              <hr className="my-4" />
            </div>
          )}
          <div className="flex flex-wrap justify-evenly">
            <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
              <div className=" text-sm font-semibold">วันที่แจ้งซ่อม</div>{' '}
              <div className="flex gap-3">
                <div className="self-center text-2xl">
                  <i className="fa-solid fa-calendar"></i>
                </div>
                <div className="lg:text-xl mt-2 ">
                  {dayjs(maintenanceOrder?.start_date).format('D MMM BBBB ')}{' '}
                  {cmmsSetting?.attach_time && (
                    <div className="text-sm">
                      <i className="fa-regular fa-clock mr-2"></i>
                      {dayjs(maintenanceOrder?.start_date).format('HH:mm')}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
              <div className=" text-sm font-semibold">วันที่ซ่อมสำเร็จ</div>
              {maintenanceOrder?.status ===
              MAINTENANCE_ORDER_STATUS.SUCCESS.status_code ? (
                <div className="flex gap-3">
                  <div className="self-center text-2xl">
                    <i className="fa-solid fa-calendar"></i>
                  </div>
                  <div className="lg:text-xl mt-2 ">
                    {dayjs(maintenanceOrder?.end_date).format('D MMM BBBB ')}{' '}
                    {cmmsSetting?.attach_time && (
                      <div className="text-sm">
                        <i className="fa-regular fa-clock mr-2"></i>
                        {dayjs(maintenanceOrder?.end_date).format('HH:mm')}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-xl  mt-2 ">-</div>
              )}
            </div>
            {maintenanceOrder?.vehicle?.licence_number && (
              <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
                <div className=" text-sm font-semibold">
                  เครื่องจักร
                  <Link
                    to={`/dashboard/maintenance-order/vehicle/detail/${maintenanceOrder?.vehicle?._id}`}
                  >
                    <IconButton size="small">
                      <ExternalLinkIcon size="16" />
                    </IconButton>
                  </Link>
                </div>
                <div className="flex gap-3">
                  <div className="self-center">
                    <i className="fas fa-cogs text-2xl"></i>
                  </div>
                  <div className="text-xl mt-2 ">
                    {maintenanceOrder?.vehicle?.licence_number}
                  </div>
                </div>
                {/* <div className="text-sm">
                  <i className="fa-solid fa-gauge-simple-high mr-1"></i>
                  {maintenanceOrder?.mileage || '-'} km
                </div> */}
              </div>
            )}
            {maintenanceOrder?.machinery?.name && (
              <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
                <div className=" text-sm font-semibold">เครื่องจักร</div>

                <div className="gap-3">
                  <div className="text-xl mt-2 ">
                    {maintenanceOrder?.machinery?.name}
                  </div>
                  <div>{maintenanceOrder?.machinery?.licence_number}</div>
                </div>
              </div>
            )}
            {maintenanceOrder?.trailer_tail?.licence_number && (
              <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
                <div className=" text-sm font-semibold">หางรถ</div>

                <div className="flex gap-3">
                  <div className="self-center">
                    <i className="fa-solid fa-trailer text-2xl mr-1"></i>
                  </div>
                  <div className="text-xl mt-2 ">
                    {maintenanceOrder?.trailer_tail?.licence_number}
                  </div>
                </div>
              </div>
            )}
            {maintenanceOrder?.trailer_tail?.licence_number && (
              <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
                <div className=" text-sm font-semibold">ตู้คอนเทนเนอร์</div>

                <div className="flex gap-3">
                  <div className="self-center">
                    <i className="fa-solid fa-caravan text-2xl"></i>
                  </div>
                  <div className="text-xl mt-2 ">
                    {maintenanceOrder?.vehicle?.licence_number}
                  </div>
                </div>
              </div>
            )}
            {maintenanceOrder?.container?.licence_number && (
              <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
                <div className=" text-sm font-semibold">ตู้คอนเทนเนอร์</div>

                <div className="flex gap-3">
                  <div className="self-center">
                    <i className="fa-solid fa-trailer text-2xl mr-1"></i>
                  </div>
                  <div className="text-xl mt-2 ">
                    {maintenanceOrder?.container?.licence_number}
                  </div>
                </div>
              </div>
            )}
            {/* {showDriver && !maintenanceOrder?.machinery?.name && (
              <div className="w-1/2 lg:w-1/5 my-2 lg:pl-4">
                <div className=" text-sm font-semibold">พนักงานขับรถ</div>

                <div className="flex gap-3 my-1">
                  <div className="self-center">
                    <Avatar
                      src={maintenanceOrder?.driver?.image?.url}
                      title={`${maintenanceOrder?.driver?.firstname}{' '}
                      ${maintenanceOrder?.driver?.lastname}`}
                    ></Avatar>
                  </div>
                  <div className="text-base lg:text-lg self-center">
                    {maintenanceOrder?.driver?.firstname}{' '}
                    <span className="text-base">
                      {maintenanceOrder?.driver?.lastname}
                    </span>
                  </div>
                </div>
              </div>
            )} */}
            {showAllower && maintenanceOrder?.allower?.firstname && (
              <div className="w-1/2 lg:w-1/5 my-1 lg:pl-4">
                <div className=" text-sm font-semibold">ผู้อนุมัติ</div>

                <div className="flex gap-3 my-1">
                  <div className="self-center">
                    <Avatar src={maintenanceOrder?.allower?.image?.url}>
                      {maintenanceOrder?.allower?.firstname}{' '}
                      {maintenanceOrder?.allower?.lastname}
                    </Avatar>
                  </div>
                  <div className="text-base lg:text-lg self-center">
                    {maintenanceOrder?.allower?.firstname}{' '}
                    <span className="text-base">
                      {maintenanceOrder?.allower?.lastname}
                    </span>
                    <div className="text-sm my-1">
                      <i className="fa-regular fa-clock mr-2"></i>
                      {dayjs(maintenanceOrder?.allow_date).format(
                        'D MMM BBBB HH:mm',
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {maintenanceOrder?.cause && (
              <div className="w-1/2 lg:w-1/5 my-1 lg:pl-4">
                <div className="text-sm font-semibold">สาเหตุ</div>
                <div className="flex gap-3">
                  <div className="self-center">
                    <i className="fa-solid fa-bug-slash text-2xl"></i>
                  </div>
                  <div className="mt-2 ">{maintenanceOrder?.cause || ''}</div>
                </div>
              </div>
            )}
            {maintenanceOrder?.modify && (
              <div className="w-1/2 lg:w-1/5 my-1 lg:pl-4">
                <div className="text-sm font-semibold">วิธีการแก้ไข</div>
                <div className="flex gap-3">
                  <div className="self-center">
                    <i className="fa-solid fa-wrench text-2xl"></i>
                  </div>
                  <div className="mt-2 ">{maintenanceOrder?.modify || ''}</div>
                </div>
              </div>
            )}
            {!maintenanceOrder?.is_self_maintenance &&
              maintenanceOrder?.garage && (
                <div className="w-1/2 lg:w-1/5 my-1 lg:pl-4">
                  <div className="text-sm font-semibold">อู่ซ่อมบำรุง</div>
                  <div className="flex gap-3">
                    <div className="self-center">
                      <i className="fa-solid fa-screwdriver-wrench text-2xl"></i>
                    </div>
                    <div className="mt-2 ">
                      {maintenanceOrder?.garage?.name || ''}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

MaintenanceOrderCard.propTypes = {
  maintenanceOrder: PropTypes.object,
  showDriver: PropTypes.bool,
  showAllower: PropTypes.bool,
  showMaintanence: PropTypes.bool,
};

export default MaintenanceOrderCard;
