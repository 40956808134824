import React from 'react';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import relateTime from 'dayjs/plugin/relativeTime';

import dayjs from '../../../utils/functions/thDayjs';

dayjs.extend(relateTime);

const VehicleAttributeCard = ({ vehicleDetail }) => {
  const { attribute = {} } = vehicleDetail;
  const { machine = {} } = vehicleDetail;

  return (
    <Card>
      <div className="p-4">
        {/* <div className="grid grid-cols-6">
          <div className="col-span-2">
            <div className="font-semibold">รูปแบบรถ</div>
            <div className="">{vehicleDetail?.type?.name}</div>
          </div>
        </div> */}
        <div className="flex flex-wrap ">
          <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            ประเภทเครื่องจักร{' '}
          </div>
          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {vehicleDetail?.type?.name}
          </div>
          {/* <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            พนักงานขับรถ{' '}
          </div> */}
          {/* <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {vehicleDetail?.driver?.employee?.firstname || '-'}{' '}
            {vehicleDetail?.driver?.employee?.lastname || ''}
          </div> */}
          <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            แบรนด์{' '}
          </div>
          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {attribute?.brand || '-'}
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            ปีที่ซื้อ{' '}
          </div>
          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {attribute?.buy_year || '-'}
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            งานที่ใช้
          </div>
          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {machine?.note || '-'}
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            แรงม้า
          </div>
          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {machine?.horsepower || '-'}
          </div>
          <hr className="w-full my-2" />
          {/* <div className="w-full text-lg py-2 font-semibold">ประกันภัย</div> */}
          {/* <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            บริษัทประกัน
          </div> */}
          {/* <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {attribute?.insurance_brand || '-'}
          </div> */}
          {/* <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            เลขที่กรมธรรม์
          </div>
          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {attribute?.insurance_number || '-'}
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            วันทีเริ่มต้น
          </div>

          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {dayjs(attribute?.insurance_start).format('D MMMM BBBB')}
          </div>
          <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            วันที่สินสุด
          </div> */}
          {/* 
          <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            {dayjs(attribute?.insurance_end).format('D MMMM BBBB')}
          </div>
          <hr className="w-full my-2" /> */}

          {/* <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
            ภาษีและ พ.ร.บ. ของรถ
          </div> */}
          {/* <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
            <div>เลขที่ พ.ร.บ. {attribute?.invoice_number || '-'}</div>
            <div>
              วันที่ต้องต่ออายุ พ.ร.บ.{' '}
              {dayjs(attribute?.invoice_date).format('D MMMM BBBB')}
            </div>
          </div> */}
        </div>
      </div>
    </Card>
  );
};

VehicleAttributeCard.propTypes = {
  vehicleDetail: PropTypes.object,
};

export default VehicleAttributeCard;
