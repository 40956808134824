/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import VehcleInfoMaintenanceFrom from '../../components/Forms/WMS/VehcleInfoMaintenanceFrom';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';

export default function CreateWheelMaintenOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const wheelTrailer = useSelector((state) => state.WheelTrailerTail);
  const wheelRegister = useSelector((state) => state.WheelRegister);
  const wheel = useSelector((state) => state.wheel);
  const garage = useSelector((state) => state.garage);
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'wheelArray',
  });
  const [arrayWheel, setArrayWheel] = useState([]);
  const [vehicleSelect, setVehicleSelect] = useState(0);
  const [tailertailSelect, setTailertailSelect] = useState(0);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState('');
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    dispatch(actions.wheelRegisterAll({}));
    dispatch(actions.wheelTrailerTailAll({}));
    dispatch(actions.garageAll({}));
    dispatch(actions.wheelAll({}));
  }, [name, page, size]);

  const handleSave = async (data) => {
    try {
      const confirm = window.confirm('บันทึกข้อมูล');
      if (confirm) {
        const dataSubmit = {
          ...data,
          wheelOrder: data?.arrayWheel,
        };
        await dispatch(actions.wheelMaintenanceCreate(dataSubmit));
        await dispatch(actions.wheelMaintenanceAll({}));
        history.goBack();
        console.log('data', dataSubmit);
      }
    } catch (error) {}
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderVehcle = () => (
    <div>
      <form onSubmit={handleSubmit(handleSave)}>
        <VehcleInfoMaintenanceFrom
          wheelRegister={wheelRegister}
          wheelTrailer={wheelTrailer}
          wheel={wheel}
          garage={garage}
          control={control}
          Controller={Controller}
          setValue={setValue}
          fields={fields}
          append={append}
          remove={remove}
          setVehicleSelect={setVehicleSelect}
          setTailertailSelect={setTailertailSelect}
          tailertailSelect={tailertailSelect}
          vehicleSelect={vehicleSelect}
          date={date}
          watch={watch}
          reset={reset}
          setDate={setDate}
          arrayWheel={arrayWheel}
          setArrayWheel={setArrayWheel}
        />
      </form>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <div className="py-2">
        <BackButton />
      </div>
      {renderVehcle()}
    </div>
  );
}
