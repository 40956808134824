import dayjs from 'dayjs';

export const invoiceBody = (rows) =>
  _.map(rows, (order, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: `Pick up ${dayjs(order?.date).format('DD/MM/YYYY') || '-'} Truck ${
        order?.individual_input?.vehicle || ''
      } Cont No. ${
        order?.individual_input?.container || ''
      } \n Booking Number: ${
        order?.booking_number || ''
      } \n Transport charge from ${order?.individual_input?.job?.start} to ${
        order?.individual_input?.job?.destination
      } `,
      style: 'tableBody1',
    },
    {
      text: `${
        order?.individual_input?.gas_price
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,') || ''
      }`,
      alignment: 'right',
      style: 'tableBody1',
    },
    {
      text: `THB ${
        order?.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || ''
      }`,
      alignment: 'right',
      style: 'tableBody1',
    },
    {
      text: `THB ${
        order?.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || ''
      }`,
      alignment: 'right',
      style: 'tableBody1',
    },
  ]);

export default invoiceBody;
