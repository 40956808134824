import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

function DetailJob({ title, subtitle }) {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job);
  const params = useParams();

  useEffect(() => {
    dispatch(actions.jobGet(params.id));
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );
  const renderContent = () => <div className="my-2 "></div>;

  if (job.isLoading) {
    return <Loading />;
  }
  if (!job.isLoading && job.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderContent()}
      </div>
    );
  }
  return <Error />;
}

DetailJob.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailJob.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailJob;
