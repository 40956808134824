/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable vars-on-top */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-underscore-dangle */
import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import { Backdrop, Card, Fade, Modal } from '@mui/material';
import moment from 'moment';
import CalendarNavigate from '../../components/Calendar/CalendarNavigate';
import DayView from '../../components/Calendar/DayView';

import { ViewTitle } from '../../components/ViewTitle';
import { VehicleScheduleCard, DeliveryOrderCard } from '../../components/Card';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { CONFIG, SCHEDULE_STATUS } from '../../utils/constants';
import {
  convertVehicleScheduelToEvent,
  convertDeliveryOrderToEvent,
} from '../../utils/functions';
import 'moment/locale/th';
import '../../assets/styles/react-big-calendar.css';

moment.locale('th');

const localizer = momentLocalizer(moment);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

const VehicleScheduleDashboard = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const container = useSelector((state) => state.container);
  const vehicleSchedule = useSelector((state) => state.vehicleSchedule);
  const deliveryOrders = useSelector((state) => state.deliveryOrders);
  const [filterDate, setFilterDate] = useState(moment().toDate());
  const [scheduleType, setScheduleType] = useState('');
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [isModalOn, setIsModalOn] = useState(false);
  const [isDayView, setIsDayView] = useState(false);

  const getVehicleSchedule = () => {
    dispatch(
      actions.vehicleScheduleAll({
        page: 1,
        size: CONFIG.infiniteLimit,
        start: moment(filterDate).startOf('month').toISOString(),
        end: moment(filterDate).endOf('month').toISOString(),
        scheduleType,
        statusCode: SCHEDULE_STATUS.SCHEDULE_CALENDAR.status_code,
      }),
    );
  };

  useEffect(() => {
    getVehicleSchedule();
    dispatch(
      actions.deliveryOrderAll({
        page: 1,
        size: CONFIG.infiniteLimit,
        assignmentType: scheduleType,
      }),
    );
    dispatch(actions.customerAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.containerAll({ page: 1, size: CONFIG.infiniteLimit }));
    return () => {};
  }, [filterDate, scheduleType]);

  useEffect(() => {
    if (vehicleSchedule.isCompleted && deliveryOrders.isCompleted) {
      const vehicleScheduleEvents = _.size(vehicleSchedule.rows)
        ? convertVehicleScheduelToEvent(vehicleSchedule.rows)
        : [];

      const deliveryOrderEvents = _.size(deliveryOrders.rows)
        ? convertDeliveryOrderToEvent(deliveryOrders.rows)
        : [];

      setEvents([...vehicleScheduleEvents, ...deliveryOrderEvents]);
    }

    return () => {};
  }, [vehicleSchedule, deliveryOrders]);

  const deliveryOrderEventFunction = {
    handleReady: async (id) => {
      try {
        const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะเป็น พร้อม');
        if (confirm) {
          await dispatch(actions.deliveryOrderPut(id, { ready: true }));
          dispatch(
            actions.deliveryOrderAll({ page: 1, size: CONFIG.infiniteLimit }),
          );
          setIsModalOn(false);
          setSelectedEvent();
        }
      } catch (error) {
        alert('แก้ไขคำสั่งจัดส่งไม่ถูกต้อง', error?.message);
      }
    },
    handleCancle: async (id) => {
      try {
        await dispatch(actions.deliveryOrderPut(id, { deleted: true }));
        dispatch(
          actions.deliveryOrderAll({ page: 1, size: CONFIG.infiniteLimit }),
        );
        setIsModalOn(false);
        setSelectedEvent();
      } catch (error) {
        alert('แก้ไขคำสั่งจัดส่งไม่ถูกต้อง', error?.message);
      }
    },
  };

  const vehicleScheduleEventFunction = {
    handlePending: async (id) => {
      try {
        const confirm = window.confirm(
          'ยืนยันการเปลี่ยนสถานะเป็น กำลังดำเนินการ',
        );
        if (confirm) {
          await dispatch(actions.vehicleSchedulePut(id, { pending: true }));
          getVehicleSchedule();
          setIsModalOn(false);
        }
      } catch (error) {
        alert('เปลี่ยนสถานะไม่ถูกต้อง', error?.message);
      }
    },
    handleComplete: async (id) => {
      try {
        const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะเป็น สำเร็จ');
        if (confirm) {
          await dispatch(actions.vehicleSchedulePut(id, { completed: true }));
          getVehicleSchedule();
          setIsModalOn(false);
        }
      } catch (error) {
        alert('เปลี่ยนสถานะไม่ถูกต้อง', error?.message);
      }
    },
    handleBill: async (id) => {
      try {
        const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะเป็น ออกบิลแล้ว');
        if (confirm) {
          await dispatch(actions.vehicleSchedulePut(id, { billed: true }));
          getVehicleSchedule();
          setIsModalOn(false);
        }
      } catch (error) {
        alert('เปลี่ยนสถานะไม่ถูกต้อง', error?.message);
      }
    },
    handleCancle: async (id) => {
      try {
        const confirm = window.confirm('ยืนยันการยกเลิกรายการเดินรถ');
        if (confirm) {
          await dispatch(actions.vehicleScheduleDelete(id, { deleted: true }));
          getVehicleSchedule();
          setIsModalOn(false);
        }
      } catch (error) {
        alert('เปลี่ยนสถานะไม่ถูกต้อง', error?.message);
      }
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderButton = () => (
    <div className="flex justify-start pb-4">
      <BackButton />
    </div>
  );

  const rendeEventDayView = (props) => {
    console.log('props', props);
    return <div>แสดงผลรายวัน</div>;
  };

  const renderToolbar = (props) => (
    <CalendarNavigate
      date={props.date}
      label={props.label}
      localizer={props.localizer}
      onNavigate={props.onNavigate}
      onView={props.onView}
      view={props.view}
      views={props.views}
      setFilterDate={setFilterDate}
      setScheduleType={setScheduleType}
      isDayView={isDayView}
      setIsDayView={setIsDayView}
    />
  );

  const { defaultDate } = useMemo(
    () => ({
      defaultDate: moment().toDate(),
    }),
    [],
  );

  const handleSelectEvent = (event) => {
    if (event?.isVehicleSchedule) {
      const selectedVehicleSchedule = _.find(
        vehicleSchedule?.rows,
        (_vehicleSchedule) => _vehicleSchedule?._id === event?.id,
      );

      setSelectedEvent({ ...selectedVehicleSchedule, isVehicleSchedule: true });
      setIsModalOn(true);
    } else {
      const selectedDeliveryOrder = _.find(
        deliveryOrders?.rows,
        (_deliveryOrder) => _deliveryOrder?._id === event?.id,
      );

      setSelectedEvent({ ...selectedDeliveryOrder, isVehicleSchedule: false });
      setIsModalOn(true);
    }
  };

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  };

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOn}
      onClose={() => setIsModalOn(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOn}>
        <Card
          sx={style}
          className="max-w-4xl  max-h-screen   overflow-y-auto my-4"
        >
          {selectedEvent?.isVehicleSchedule ? (
            <VehicleScheduleCard
              vehicleSchedule={selectedEvent}
              {...vehicleScheduleEventFunction}
            />
          ) : (
            <DeliveryOrderCard
              deliveryOrder={selectedEvent}
              {...deliveryOrderEventFunction}
            />
          )}
        </Card>
      </Fade>
    </Modal>
  );

  const renderCalendar = () => (
    <div style={{ height: '100vh' }}>
      <Calendar
        culture={'th'}
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        views={{ month: true, week: true, day: DayView }}
        messages={{
          week: 'สัปดาห์',
          day: 'วัน',
          month: 'เดือน',
          previous: 'ก่อนหน้า',
          next: 'ถัดไป',
          today: 'วันนี้',
          showMore: (total) => `มีอีก ${total} รายการ`,
        }}
        events={events || []}
        localizer={localizer}
        eventPropGetter={eventStyleGetter}
        style={{
          height: 'block',
        }}
        components={{
          toolbar: renderToolbar,
          dayColumnWrapper: (props) => <div> dayColumnWrapper</div>,
          day: {
            event: rendeEventDayView,
            header: (props) => <div> Header</div>,
          },
        }}
        onSelectEvent={handleSelectEvent}
      />
    </div>
  );

  if (customer.isLoading || container?.isLoading) {
    return <Loading />;
  }

  if (
    !customer.isLoading &&
    customer.isCompleted &&
    !container?.isLoading &&
    container?.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderButton()}
        {renderModal()}
        <div>{renderCalendar()}</div>
      </div>
    );
  }
  return <Error />;
};

VehicleScheduleDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

VehicleScheduleDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default VehicleScheduleDashboard;
