import {
  PROCESS_TRANSACTION_ALL,
  PROCESS_TRANSACTION_GET,
  PROCESS_TRANSACTION_DELETE,
  PROCESS_TRANSACTION_PUT,
  PROCESS_TRANSACTION_POST,
  PROCESS_TRANSACTION_LOADING,
  PROCESS_TRANSACTION_ERROR,
  PROCESS_TRANSACTION_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const processTransactionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_TRANSACTION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process-transaction`,
      payload,
    );
    dispatch({ type: PROCESS_TRANSACTION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const processTransactionAll =
  ({ size = '', page = 1, maintenanceOrder = '', employee = '' }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/process-transaction?size=${size}&page=${page}&maintenanceOrder=${maintenanceOrder}&employee=${employee}`,
      );
      if (status === 200) {
        dispatch({ type: PROCESS_TRANSACTION_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: PROCESS_TRANSACTION_ERROR });
      throw new Error(error);
    }
  };

export const processTransactionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/process-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PROCESS_TRANSACTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const processTransactionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_TRANSACTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/process-transaction/${id}`,
      payload,
    );
    dispatch({ type: PROCESS_TRANSACTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
export const processTransactionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_TRANSACTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/process-transaction/${id}`,
    );
    dispatch({ type: PROCESS_TRANSACTION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const processTransactionReset = () => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_TRANSACTION_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
