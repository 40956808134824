import {
  GARAGE_ALL,
  GARAGE_GET,
  GARAGE_PUT,
  GARAGE_DELETE,
  GARAGE_POST,
  GARAGE_RESET,
  GARAGE_LOADING,
  GARAGE_ERROR,
} from '../../actions/types';

const initialState = {
  job: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function GarageReducer(state = initialState, action) {
  switch (action.type) {
    case GARAGE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case GARAGE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case GARAGE_POST:
      return { isLoading: false, isCompleted: true };
    case GARAGE_PUT:
      return { isLoading: false, isCompleted: true };
    case GARAGE_DELETE:
      return { isLoading: false, isCompleted: true };
    case GARAGE_RESET:
      return { GARAGE: null, isLoading: true, isCompleted: true };
    case GARAGE_LOADING:
      return { isLoading: true, isCompleted: true };
    case GARAGE_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
