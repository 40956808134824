import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import * as action from '../../redux/actions';
import Loading from '../../components/Loading';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Notify({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const notify = useSelector((state) => state.notify);
  const location = useLocation();
  const [error, setError] = useState();
  const query = useQuery();

  useEffect(() => {
    // dispatch(action.getMe());
    dispatch(action.informationAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    const tokenRequestPayload = {
      accessCode: query.get('code'),
      employeeId: query.get('state'),
    };

    console.log('Token Response Payload', tokenRequestPayload);
    const requestNotifyToken = async () => {
      try {
        if (tokenRequestPayload.accessCode && tokenRequestPayload.employeeId) {
          await dispatch(action.requestNotifyToken(tokenRequestPayload));
        } else {
          setError(
            'ไม่สามารถลงทะเบียนการแจ้งเตือนกับ Line Notify ได้ เนื่องจากข้อมูลที่ได้รับมาจาก Line Server อาจไม่ถูกต้อง',
          );
        }
      } catch (err) {
        console.error('Notify Request Token error', err);
        setError(err?.message?.toString());
      }
    };

    requestNotifyToken();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderNotifyStatus = () => {
    if (error) {
      return error;
    }
    if (notify?.isLoading) {
      return 'กำลังลงทะเบียนการใช้งานการแจ้งเตือน';
    }
    if (!information?.isLoading && notify?.isCompleted) {
      return 'การลงทะเบียนการแจ้งเตือนเสร็จสิ้น';
    }
    return 'ลงทะเบียนการแจ้งเตือนไม่สำเร็จ';
  };

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <Card>
          <CardContent>
            <div className="my-2">
              <div className="text-lg text-center">
                Code: {query.get('code')}
                {renderNotifyStatus()}
              </div>
              <div className="flex justify-center my-2">
                <Link to="/">
                  <Button color="primary" variant="contained">
                    กลับ
                  </Button>
                </Link>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return <Loading isLoading />;
}

Notify.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Notify.defaultProps = {
  title: '',
  subtitle: '',
};

export default Notify;
