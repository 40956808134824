import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import dayjs from '../../utils/functions/thDayjs';

function MaintenanceNotifies({ title, subtitle }) {
  const dispatch = useDispatch();
  const maintenanceNotify = useSelector((state) => state.maintenanceNotify);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  useEffect(() => {
    dispatch(actions.maintenanceNotifyAll({ name, page, size }));
  }, [name, page, size]);

  useEffect(() => {
    setTotal(maintenanceNotify?.total);
    return () => {};
  }, [maintenanceNotify]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const displayDurationStatus = (duration) => {
    const durationThreshold = 2000; //FIXME: use data from database
    if (durationThreshold >= 0) {
      if (durationThreshold < 2000) {
        return <div className="text-yellow-500">{duration}</div>;
      }
      return <div>{duration}</div>;
    }
    if (durationThreshold < 0) {
      if (durationThreshold > -2000) {
        return <div className="text-red-800">{duration}</div>;
      }
      return <div className="text-red-500">{duration}</div>;
    }
    return <div>-</div>;
  };
  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ป้ายทะเบียน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เลขไมล์ปัจจุบัน</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold"> อะไหล่ที่ต้องเปลี่ยน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เลขไมล์เป้าหมาย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผลต่าง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(maintenanceNotify.rows) ? (
                maintenanceNotify.rows.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="font-semibold text-base">
                        {row?.vehicle && (
                          <div>
                            หัวรถ {row?.vehicle?.licence_number}{' '}
                            <div className="font-normal">
                              {row?.attribute?.brand}{' '}
                              {row?.attribute?.generation}
                            </div>
                          </div>
                        )}
                        {row?.trailer_tail && (
                          <div>หางรถ {row?.trailer_tail?.licence_number}</div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {row?.vehicle && <div>{row?.vehicle?.mileage}</div>}
                      {row?.trailer_tail && (
                        <div>{row?.trailer_tail?.mileage}</div>
                      )}
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">
                        {row?.material?.type_code}
                      </span>{' '}
                      {row?.material?.name}
                    </TableCell>
                    <TableCell>{row?.mileage}</TableCell>
                    <TableCell>
                      {displayDurationStatus(
                        row?.mileage - row?.vehicle?.mileage,
                      )}{' '}
                      กม.
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Link to={`tail/detail/${row?._id}`}>
                          <Button variant="contained" size="small">
                            รายละเอียด{' '}
                          </Button>
                        </Link>
                        {/* <Link to={`/fms/vehicle/edit/${row?._id}`}>
                          <Button
                            variant="contained"
                            color="warning"
                            size="small"
                          >
                            แก่ไขข้อมูลรถ{' '}
                          </Button>
                        </Link> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (maintenanceNotify.isLoading) {
    return <Loading />;
  }
  if (!maintenanceNotify.isLoading && maintenanceNotify.isCompleted) {
    return (
      <div>
        {renderTitle()}

        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

MaintenanceNotifies.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaintenanceNotifies.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaintenanceNotifies;
