/* eslint-disable func-names */
import {
  DELIVERY_ORDER_ALL,
  DELIVERY_ORDER_GET,
  DELIVERY_ORDER_POST,
  DELIVERY_ORDER_PUT,
  DELIVERY_ORDER_DEL,
  DELIVERY_ORDER_LOADING,
  DELIVERY_ORDER_ERROR,
  DELIVERY_ORDER_HISTORY,
} from '../../actions/types';

const initialState = {
  deliveryOrder: null,
  isLoading: true,
  isCompleted: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELIVERY_ORDER_LOADING:
      return { isLoading: true, isCompleted: true };
    case DELIVERY_ORDER_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case DELIVERY_ORDER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DELIVERY_ORDER_HISTORY:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DELIVERY_ORDER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DELIVERY_ORDER_POST:
      return { isLoading: false, isCompleted: true };
    case DELIVERY_ORDER_PUT:
      return { isLoading: false, isCompleted: true };
    case DELIVERY_ORDER_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
