import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function ContainerForm({ errors, container, Controller, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'licence_number'}
          control={control}
          defaultValue={container ? container.licence_number : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ป้ายทะเบียน"
              required
              fullWidth
              size={'small'}
              helperText={errors.licence_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={container ? container.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              rows={3}
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'size'}
          control={control}
          defaultValue={container ? container.size : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ขนาด"
              fullWidth
              size={'small'}
              helperText={errors.size && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'size'}
          control={control}
          defaultValue={container ? container.weight : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="น้ำหนักที่รองรับได้ (ตัน)"
              fullWidth
              size={'small'}
              helperText={errors.weight && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

ContainerForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    type_code: PropTypes.object,
  }),
  container: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

ContainerForm.defaultProps = {
  container: null,
};

export default ContainerForm;
