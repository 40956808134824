/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

export default function RBCToolbar(props) {
  const { setFilterDate, setScheduleType } = props;
  const goToBack = () => {
    const { view } = props;
    const mDate = props.date;
    let newDate = new Date();
    if (view === 'month') {
      newDate = moment(mDate).startOf('month').subtract(1, 'month');
    } else if (view === 'week') {
      newDate = moment(mDate).startOf('week').subtract(1, 'week');
    } else if (view === 'day') {
      newDate = moment(mDate).startOf('day').subtract(1, 'day');
    }

    setFilterDate(newDate);

    props.onNavigate('prev', newDate);
  };

  const goToDay = () => {
    const newDate = new Date();
    setFilterDate(newDate);
    props.onNavigate('prev', newDate);
  };

  const goToNext = () => {
    const { view } = props;
    const mDate = props.date;
    let newDate;
    if (view === 'month') {
      newDate = moment(mDate).startOf('month').add(1, 'month');
    } else if (view === 'week') {
      newDate = moment(mDate).startOf('week').add(1, 'week');
    } else if (view === 'day') {
      newDate = moment(mDate).startOf('day').add(1, 'day');
    }
    setFilterDate(newDate);
    props.onNavigate('next', newDate);
  };

  return (
    <>
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={() => props.onView('month')}>
            เดือน
          </button>
          <button type="button" onClick={() => props.onView('week')}>
            สัปดาห์
          </button>
          <button
            type="button"
            onClick={() => {
              props.onView('day');
              props.setIsDayView(!props.isDayView);
            }}
          >
            วัน
          </button>
        </span>
        <span className="rbc-btn-group">
          <button type="button" onClick={() => setScheduleType('IMPORT')}>
            Import
          </button>
          <button type="button" onClick={() => setScheduleType('EXPORT')}>
            Export
          </button>
          <button type="button" onClick={() => setScheduleType('')}>
            All
          </button>
        </span>

        <span className="rbc-toolbar-label"> {props?.label}</span>

        <div>
          <span className="rbc-btn-group">
            <button type="button" onClick={goToDay}>
              วันนี้
            </button>
            <button type="button" onClick={goToBack}>
              ก่อนหน้า
            </button>
            <button type="button" onClick={goToNext}>
              ถัดไป
            </button>
          </span>
        </div>
      </div>
    </>
  );
}
