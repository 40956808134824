import React from 'react';
import PropTypes from 'prop-types';

export default function ViewTitle({ title, subtitle }) {
  return (
    <div className="py-1 mb-4 px-2 cursor-pointer flex">
      <div className="w-1 h-16 bg-gradient-to-b from-indigo-600 to-purple-600   "></div>{' '}
      <div className="pl-4 py-2">
        <div className="text-xl  font-semibold text-gray-600 hover:text-black  px-2 ">
          {title}
        </div>
        <div className="text-xs  text-gray-600 hover:text-black  px-2 ">
          {subtitle}
        </div>
      </div>
    </div>
  );
}

ViewTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViewTitle.defaultProps = {
  title: '',
  subtitle: '',
};
