/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardContent,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';

// eslint-disable-next-line react/prop-types
export default function WheelSupplyAddForm({
  control,
  Controller,
  wheel,
  wheelType,
  selectType,
  setSelectType,
}) {
  // console.log('wheelType in edir form', wheelType);
  const handleChange = (event) => {
    setSelectType(event.target.value);
  };
  return (
    <div className="pt-2">
      <div>
        <Card>
          <CardContent>
            <div className="flex">
              <div className="w-1/2">
                <div className="w-full px-1 py-2">
                  <Controller
                    name={'brand'}
                    control={control}
                    defaultValue={wheel ? wheel?.brand : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="ยี่ห้อ"
                        fullWidth
                        // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>

                <div className="w-full px-1 py-2">
                  <Controller
                    name={'type'}
                    control={control}
                    defaultValue={wheel ? wheel?.type : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="ชนิดยาง"
                        fullWidth
                        placeholder="11R"
                      />
                    )}
                  />
                </div>
                <div className="w-full px-1 py-2">
                  <Controller
                    name={'size'}
                    control={control}
                    defaultValue={wheel ? wheel?.size : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="ขนาดยาง (นิ้ว)"
                        fullWidth
                        // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-full px-1 py-2">
                  <Controller
                    name={'mfg'}
                    control={control}
                    defaultValue={wheel ? wheel?.mfg : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="สัปดาห์ที่ผลิต"
                        fullWidth
                        // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>

                <div className="w-full px-1 py-2">
                  <Controller
                    name={'seller'}
                    control={control}
                    defaultValue={wheel ? wheel?.seller : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="คู่ค้า"
                        fullWidth
                        // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full px-1 py-2">
                  <Controller
                    name={'price'}
                    control={control}
                    defaultValue={wheel ? wheel?.price : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="ราคา"
                        fullWidth
                        // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end pb-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
