/* eslint-disable no-underscore-dangle */
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function MaintenanceTypesForm({
  errors,
  maintenanceTypes,
  Controller,
  control,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={maintenanceTypes ? maintenanceTypes?.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              required
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

MaintenanceTypesForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
  }),
  maintenanceTypes: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

MaintenanceTypesForm.defaultProps = {
  maintenanceTypes: null,
};

export default MaintenanceTypesForm;
