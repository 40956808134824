import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import {
  MaterialStockForm,
  AddMaterialForm,
  DecreaseMaterialForm,
} from '../../components/Forms';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import {
  MATERIAL_TRANSACTION_TYPE,
  MATERIAL_STOCK_STATUS,
  CONFIG,
} from '../../utils/constants';

const defaultValues = {
  material_transaction_type: '',
  bill_number: '',
  place: '',
  type: '',
  supplier: '',
  quantity: '',
  price: '',
  material: '',
  remark: '',
  source: '',
  production_date: new Date(),
  expiration_date: new Date(),
  purchase_date: new Date(),
  receipt_date: new Date(),
  issue_date: new Date(),
};

const CreateMaterialStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const material = useSelector((state) => state.material);
  const placeaddstock = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const customer = useSelector((state) => state.customer);
  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.me);
  const materialType = useSelector((state) => state.materialType);
  const vehicle = useSelector((state) => state.vehicle);
  const trailerTail = useSelector((state) => state.trailerTail);
  const customerType = useSelector((state) => state.customerType);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues: { material: material?.rows?.[0] } });

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(2000);
  const [type, setType] = useState(null);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [selectMaterialLot, setSelectMaterialLot] = useState([]);
  const [filterMaterial, setFilterMaterial] = useState([]);
  const [findPlace, setFindPlace] = useState([]);
  const [filterPlace, setFilterPlace] = useState(null);

  useEffect(() => {
    dispatch(
      actions.vehicleAll({ name: '', size: CONFIG.infiniteLimit, page: 1 }),
    );
    dispatch(
      actions.trailerTailAll({ name: '', size: CONFIG.infiniteLimit, page: 1 }),
    );
    dispatch(actions.materialTransactionTypeAll(''));
    dispatch(actions.placeAll(''));
    dispatch(actions.customerAll(''));
    dispatch(actions.departmentAll(''));
    dispatch(actions.customerTypeAll({ size: CONFIG.infiniteLimit }));
    dispatch(actions.meGet(''));
    dispatch(actions.materialTypeAll({ page: 1, size: CONFIG.infiniteLimit }));
    return () => {};
  }, [name, page, findPlace]);

  useEffect(() => {
    dispatch(
      actions.materialAll({
        name: '',
        size: CONFIG.infiniteLimit,
        page: 1,
        sortDesc: true,
        placeaddstock: watch('place')?._id || id,
      }),
    );

    return () => {};
  }, [watch('place')]);

  const handleCreateMaterial = async (data) => {
    try {
      await dispatch(actions.materialInsertOneWithStocklot(data));
      dispatch(
        actions.materialAll({
          name: '',
          size: CONFIG.infiniteLimit,
          page: 1,
          sortDesc: true,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถสร้างวัสดุใหม่ได้ ${error?.message}`);
      dispatch(
        actions.materialAll({
          name: '',
          size: CONFIG.infiniteLimit,
          page: 1,
          sortDesc: true,
        }),
      );
    }
  };

  const handleCreateMaterialWithoutStock = async (data) => {
    try {
      await dispatch(actions.materialCreate(data));
      dispatch(
        actions.materialAll({
          name: '',
          size: CONFIG.infiniteLimit,
          page: 1,
          sortDesc: true,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถสร้างวัสดุใหม่ได้ ${error?.message}`);
      dispatch(
        actions.materialAll({
          name: '',
          size: CONFIG.infiniteLimit,
          page: 1,
          sortDesc: true,
        }),
      );
    }
  };

  const handleCreateCustomer = async (data) => {
    try {
      await dispatch(actions.customerCreate(data));
      dispatch(
        actions.customerAll({
          name: '',
          size: CONFIG.infiniteLimit,
          page: 1,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถสร้างคู่ค้า/ลูกค้าใหม่ได้ ${error?.message}`);
      dispatch(
        actions.customerAll({
          name: '',
          size: CONFIG.infiniteLimit,
          page: 1,
        }),
      );
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      if (
        type?.direction === MATERIAL_TRANSACTION_TYPE[0].direction &&
        _.isEmpty(selectMaterial)
      ) {
        alert('กรุณาเลือกวัสดุ');
      } else if (
        type?.direction === MATERIAL_TRANSACTION_TYPE[1].direction &&
        _.isEmpty(selectMaterialLot)
      ) {
        alert('กรุณาเลือกล็อตวัสดุ');
      } else if (
        type?.direction === MATERIAL_TRANSACTION_TYPE[0].direction &&
        !_.isEmpty(selectMaterial)
      ) {
        data.material = selectMaterial;
        data.status = MATERIAL_STOCK_STATUS.IN_STOCK.status_code;
        data.recording_date = new Date();
        event.preventDefault();
        await dispatch(actions.materialStockLotCreate(data));
        reset(defaultValues);
        setSelectMaterial([]);
        alert('สำเร็จ');
        await dispatch(
          actions.materialAll({
            name,
            page,
            size: CONFIG.infiniteLimit,
          }),
        );
        history.goBack();
      } else if (
        type?.direction === MATERIAL_TRANSACTION_TYPE[1].direction &&
        !_.isEmpty(selectMaterialLot)
      ) {
        console.log('selectMaterialLot', selectMaterialLot);
        data.materialLots = selectMaterialLot;
        event.preventDefault();
        await dispatch(actions.materialStockLotDecrease(data));
        reset(defaultValues);
        setSelectMaterialLot([]);
        alert('สำเร็จ');
        await dispatch(
          actions.materialAll({
            name,
            page,
            size: CONFIG.infiniteLimit,
          }),
        );
        history.goBack();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-4">
      <div className="py-2">
        <MaterialStockForm
          Controller={Controller}
          control={control}
          errors={errors}
          placeaddstock={placeaddstock.rows}
          findPlace={findPlace}
          setFindPlacep={setFindPlace}
          materialTransactionType={materialTransactionType.rows}
          customer={customer.rows}
          department={department.rows}
          me={me.userData}
          type={type}
          setType={setType}
          setFilterPlace={setFilterPlace}
          setSelectMaterial={setSelectMaterial}
          setSelectMaterialLot={setSelectMaterialLot}
          customerType={customerType?.rows}
          handleCreateCustomer={handleCreateCustomer}
        />
      </div>
    </Card>
  );

  const renderSelectMaterial = () => {
    if (type?.direction === MATERIAL_TRANSACTION_TYPE[0].direction) {
      return (
        <AddMaterialForm
          material={material.rows}
          selectMaterial={selectMaterial}
          setSelectMaterial={setSelectMaterial}
          handleCreateMaterial={handleCreateMaterialWithoutStock}
          materialType={materialType?.rows}
        />
      );
    }
    if (type?.direction === MATERIAL_TRANSACTION_TYPE[1].direction) {
      return (
        <DecreaseMaterialForm
          material={material.rows}
          place={placeaddstock.rows}
          type={type}
          selectMaterialLot={selectMaterialLot}
          setSelectMaterialLot={setSelectMaterialLot}
          filterMaterial={filterMaterial}
          setFilterMaterial={setFilterMaterial}
          filterPlace={filterPlace}
          setFilterPlace={setFilterPlace}
          materialType={materialType?.rows}
          customer={customer?.rows}
          handleCreateMaterial={handleCreateMaterial}
          materialState={material}
          vehicle={vehicle}
          trailerTail={trailerTail}
        />
      );
    }
  };

  if (!material.isLoading && !material.isCompleted) {
    return <Error message={material?.message} />;
  }
  return (
    <div>
      <div className="flex justify-between">
        <div>{renderTitle()}</div>
      </div>
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row flex-wrap">
          <div className="w-full px-1 py-2">{renderForm()}</div>
          <div className="w-full  px-1 py-2">{renderSelectMaterial()}</div>
        </div>
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

CreateMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateMaterialStockLot;
