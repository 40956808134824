import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  BillBookings,
  CreateBillBooking,
  EditBillBooking,
  Bills,
  CreateBill,
  EditBill,
  DetailBill,
  DetailBillBooking,
  ReportBillBooking,
  MonthyBillBookings,
  BillSummary,
  DashBoardBooking,
} from '../views/BMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function BMS() {
  const module = 'BMS';
  const prefix = '/bms';
  const name = 'การออกบิล';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const { pathname } = useLocation();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : BMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/bill-booking`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/dashboard`}>
                <DashBoardBooking title={'แดชบอร์ด'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill-booking`}>
                <BillBookings title={'Booking งาน'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill-summary`}>
                <BillSummary title={'สรุปงาน'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill-booking/create`}>
                <CreateBillBooking title={'สร้างBooking'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill-booking/edit/:id`}>
                <EditBillBooking title={'แก้ไขBooking'} subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/bill-booking/view/:id`}>
                <DetailBillBooking
                  title={'รายละเอียดรายการออกบิล'}
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/bill-booking/report`}>
                <ReportBillBooking
                  title={'Booking งานรายเดือน'}
                  subtitle={name}
                />
              </Route>{' '}
              <Route
                exact
                path={`${prefix}/bill-booking/report/monthy/:startDate/:endDate`}
              >
                <MonthyBillBookings
                  title={'รายการออกบิลรายเดือน'}
                  subtitle={name}
                />
              </Route>{' '}
              {/* {accessRight(me, module, 1) && <Switch></Switch>} */}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default BMS;
