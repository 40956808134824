import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Button, Card, CardContent } from '@mui/material';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { PricingTable } from '../../components/Table';

function DetailPricing({ title, subtitle }) {
  const dispatch = useDispatch();
  const zonePrice = useSelector((state) => state.zonePrice);
  const params = useParams();

  useEffect(() => {
    dispatch(actions.zonePriceGet(params.id));
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderContent = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="text-lg font-semibold">
            {' '}
            ชื่อโซน {zonePrice?.name}{' '}
          </div>
          <div className="text-lg"> นำเข้า </div>
          <PricingTable selectedCostMatrix={zonePrice?.import_cost_matrix} />
          <div className="text-lg"> ส่งออก </div>
          <PricingTable selectedCostMatrix={zonePrice?.export_cost_matrix} />
        </CardContent>
      </Card>
    </div>
  );

  if (zonePrice.isLoading) {
    return <Loading />;
  }
  if (!zonePrice.isLoading && zonePrice.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderContent()}
      </div>
    );
  }
  return <Error />;
}

DetailPricing.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailPricing.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailPricing;
