import { green, red, yellow } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    amber: {
      main: '#ffc107',
    },
    primary: {
      main: '#556cd6',
      light: '#96BAFF',
      dark: '#3f51b5',
    },
    cyan: {
      main: '#00bcd4',
    },
    blue: {
      main: '#2196f3',
      contrastText: '#FFFFFF',
    },
    black: {
      main: '#212121',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#009688',
    },
    indigo: {
      main: '#3f51b5',
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: yellow[800],
    },
    success: {
      main: green[500],
    },
    lightGreen: {
      main: '#c8e6c9',
    },
    gray: {
      main: '#9e9e9e',
    },
    background: {
      default: '#F4F5F7',
    },
    dark: {
      default: '#000000',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans Thai Looped',
      'Noto Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
