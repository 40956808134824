import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, TextField, InputAdornment, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BillSummaryTable } from '../../components/Table';
import { dayjs } from '../../utils/functions';
import { BackButton } from '../../components/Button';
import { BillSummaryReport } from '../../components/Report';

function MonthyBillBookings({ title, subtitle }) {
  const dispatch = useDispatch();
  const billBooking = useSelector((state) => state.billBooking);
  const information = useSelector((state) => state.information);

  const params = useParams();

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  useEffect(() => {
    dispatch(
      actions.billBookingAll({
        name,
        page,
        size,
        completed: '',
        deleted: false,
        startDate: dayjs(params.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(params.endDate).format('YYYY-MM-DD'),
      }),
    );
  }, [name, page, size]);

  useEffect(() => {
    setTotal(billBooking?.total);
    return () => {};
  }, [billBooking]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const tableFunction = {
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleDelete: async (id) => {},
    page,
    size,
    total,
    showActionButton: false,
    billBookings: billBooking?.rows,
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle
        title={`${title} ${dayjs(params.startDate).format('MMMM BBBB')}`}
        subtitle={subtitle}
      />
      <div className="self-center">
        <Button
          variant="contained"
          onClick={() => BillSummaryReport(billBooking?.rows, information)}
        >
          พิมพ์
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap">
        <div className="w-full lg:w-2/4 md:w-1/2 px-2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-4">
      <BillSummaryTable {...tableFunction} />
    </div>
  );

  if (billBooking.isLoading) {
    return <Loading />;
  }
  if (!billBooking.isLoading && billBooking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

MonthyBillBookings.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MonthyBillBookings.defaultProps = {
  title: '',
  subtitle: '',
};

export default MonthyBillBookings;
