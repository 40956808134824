import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { MaterialUsageTable } from '../../components/Table';
import * as actions from '../../redux/actions';

function DetailMaterialUsage({ title, subtitle }) {
  const processMaterial = useSelector((state) => state.processMaterials);
  const dispatch = useDispatch();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  // Recieve Maintenance Order Id as Parameter
  useEffect(() => {
    dispatch(
      actions.processMaterialAll({ page, size, maintenanceOrder: params.id }),
    );
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (processMaterial?.rows) {
      setTotal(processMaterial?.total);
    }

    return () => {};
  }, [processMaterial]);

  const handleChangePage = (event, currPage) => {
    setPage(currPage + 1);
  };

  const handleChangeRowPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  if (processMaterial?.isLoading) {
    return <Loading />;
  }

  if (!processMaterial?.isLoading && processMaterial?.isCompleted) {
    return (
      <div>
        <ViewTitle subtitle={subtitle} title={title} />
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <MaterialUsageTable
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
            page={page}
            processMaterial={processMaterial}
            size={size}
            total={total}
          />
        </div>
      </div>
    );
  }

  return <Error />;
}

DetailMaterialUsage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default DetailMaterialUsage;
