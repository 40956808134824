import dayjs from 'dayjs';

export const ReportHeader = (info, { language } = { language: 'th' }) => [
  {
    text: `${dayjs().format('DD/MM/BBBB')}`,
    alignment: 'right',
    margin: [10, 10, 10, 10],
    fontSize: '10',
  },
  {
    alignment: 'center',
    margin: [0, 0, 0, 12],
    text: [
      {
        text: `${info?.owner?.name}\n`,
        fontSize: '16',
        bold: true,
      },
      {
        text: `${info?.owner?.address?.house_number || ''} ${
          info?.owner?.address?.village_number
            ? `${language === 'th' ? 'หมู่' : 'Moo'} ${
                info?.owner?.address?.village_number
              }`
            : ''
        } ${info?.owner?.address?.road || ''} ${
          info?.owner?.address?.subdistrict || ''
        } ${info?.owner?.address?.district || ''} ${
          info?.owner?.address?.province || ''
        } ${info?.owner?.address?.postcode || ''}\n`,
        fontSize: '10',
      },
      {
        text: `${language === 'th' ? 'โทร' : 'Tel'} ${
          info?.owner?.telephone || '-'
        } ${language === 'th' ? 'แฟกซ์' : 'Fax'} ${info?.owner.fax || '-'}\n`,
        fontSize: '10',
      },
      {
        text: `${language === 'th' ? 'อีเมล' : 'Email'} ${
          info?.owner?.email || '-'
        } ${language === 'th' ? 'เลขประจำตัวผู้เสียภาษี' : 'Tax ID'} ${
          info?.owner?.tax || '-'
        }\n`,
        fontSize: '10',
      },
    ],
  },
];

export default ReportHeader;
