import React from 'react';
import _ from 'lodash';

import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

export default function DounutChart({ data }) {
  const labels = ['ค่าบำรุงรักษา', 'ค่าอะไหล่', 'รายจ่ายเพิ่มเติม'];
  return (
    <div>
      <ReactApexChart
        type="donut"
        series={[
          data?.total_maintenance_topic,
          data?.total_material_price,
          data?.total_outer_service_price,
        ]}
        options={{
          labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          legend: {
            position: 'top',
          },
        }}
      />
    </div>
  );
}

DounutChart.propTypes = {
  data: PropTypes.object,
};
