/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import SupportIcon from '@mui/icons-material/Support';
import MaterialCard from '../MMS/MaterialCard';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import _ from 'lodash';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export default function CardDetailWheelMaintenance({
  wheelMaintenance,
  wheelTransection,
  processmaterials,
  handleWheelMaterial = () => {},
  handSuccessJob = () => {},
  handleAcessJob = () => {},
  handleWheelMaintenance = () => {},
  handleCancleJob = () => {},
  handleWheelRepair = () => {},
  handleWheelRetread = () => {},
  handleDelete = () => {},
  handleToMaterialRemove = () => {},
}) {
  const history = useHistory();
  console.log('processmaterials', processmaterials);
  console.log('wheelTransection', wheelTransection.rows);
  const renderActionButton = (row) => (
    <div className="p-4 flex justify-end ">
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          disabled={
            wheelMaintenance?.statusJob || wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handleAcessJob(wheelMaintenance._id);
          }}
        >
          ดำเนินการ
        </Button>

        <Button
          variant="contained"
          color="secondary"
          disabled={
            !wheelMaintenance?.statusJob ||
            wheelMaintenance?.statusSuccess ||
            wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handleWheelMaterial(wheelMaintenance._id);
          }}
        >
          เบิกวัสดุ
        </Button>
        <Button
          variant="contained"
          disabled={
            !wheelMaintenance?.statusJob ||
            wheelMaintenance?.statusSuccess ||
            wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handleToMaterialRemove(wheelMaintenance._id);
          }}
        >
          คืนวัสดุ
        </Button>

        <Button
          variant="contained"
          disabled={
            !wheelMaintenance?.statusJob ||
            wheelMaintenance?.statusSuccess ||
            wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handleWheelRepair(wheelMaintenance?.vehiclesRegister?._id);
          }}
        >
          ซ่อมยาง/บันทึกรายจ่าย
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !wheelMaintenance?.statusJob ||
            wheelMaintenance?.statusSuccess ||
            wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handleWheelRetread(wheelMaintenance?.vehiclesRegister?._id);
          }}
        >
          หล่อดอกยาง
        </Button>
        <Button
          variant="contained"
          color={'warning'}
          disabled={
            !wheelMaintenance?.statusJob ||
            wheelMaintenance?.statusSuccess ||
            wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handleWheelMaintenance(wheelMaintenance?.vehiclesRegister?._id);
          }}
        >
          เปลี่ยนยาง
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={
            !wheelMaintenance?.statusJob ||
            wheelMaintenance?.statusSuccess ||
            wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handSuccessJob(wheelMaintenance._id, wheelMaintenance);
          }}
        >
          เสร็จสิ้น
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={
            wheelMaintenance?.statusSuccess || wheelMaintenance?.statusCancle
          }
          onClick={() => {
            handleCancleJob(wheelMaintenance._id, wheelMaintenance);
          }}
        >
          ยกเลิก
        </Button>
      </Stack>
    </div>
  );

  const renderMeterial = () => (
    <div className="my-2">
      <MaterialCard
        materialArrays={wheelMaintenance?.process_materials}
        maintenanceOrderId={wheelMaintenance?._id}
      />
    </div>
  );
  const renderMaintenance = () => (
    <div className="py-2">
      <Card>
        <div className="p-2">
          <h1 className="font-bold p-2">รายการแจ้งซ่อม</h1>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold">ลำดับ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold">ตำแหน่งยาง</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold">เลขยาง</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold">เลขไมล์วันใส่</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold">สาเหตุ</h1>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wheelMaintenance?.wheelOrder?.map((each, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <h1>{index + 1}</h1>
                  </TableCell>
                  <TableCell>
                    <h1>{each?.position}</h1>
                  </TableCell>
                  <TableCell>
                    <h1>{each?.wheel_Id?.wheelNumber}</h1>
                  </TableCell>
                  <TableCell>
                    <h1>{each?.wheel_Id?.mileageIn}</h1>
                  </TableCell>
                  <TableCell>
                    <h1>{each?.cause}</h1>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  );
  const renderDetail = () => (
    <Card>
      <div className="p-2">
        <h1 className="font-bold p-2">ข้อมูลรถ</h1>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <h1 className="text-sm font-bold">วันที่แจ้ง</h1>
              </TableCell>
              <TableCell>
                {dayjs(wheelMaintenance?.createdAt).format('DD/MM/BBBB')}
              </TableCell>
              <TableCell>
                <h1 className="text-sm font-bold">ประเภทการซ่อม</h1>
              </TableCell>
              <TableCell>{wheelMaintenance?.type_maintenance || ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-sm font-bold">ป้ายทะเบียนหัวรถ</h1>
              </TableCell>
              <TableCell>
                {wheelMaintenance?.vehicle?.licence_number || ''}
              </TableCell>
              <TableCell>
                <h1 className="text-sm font-bold">ป้ายทะเบียนหางรถ</h1>
              </TableCell>
              <TableCell>
                {wheelMaintenance?.trailer_tail?.licence_number || ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-sm font-bold">เลขไมล์</h1>
              </TableCell>
              <TableCell>{wheelMaintenance?.mileage || ''}</TableCell>

              <TableCell>
                <h1 className="text-sm font-bold">อู่ซ่อม</h1>
              </TableCell>
              <TableCell>
                {wheelMaintenance?.garage?.name || 'ซ่อมเอง'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Card>
  );

  const renderDetailWheelMaintenanse = () => (
    <div className="pt-2">
      <Card>
        <div className="p-2">
          <div className="font-bold ">รายการเปลี่ยนยาง</div>
          <div>
            <div className="font-bold">
              ยางเข้า <ArrowCircleDownIcon />{' '}
            </div>
            <div className="lg:flex flex-wrap">
              {_.isEmpty(wheelMaintenance?.wheelTransection) ? (
                <div className="p-2">
                  <Card sx={{ width: 280 }}>
                    <div className="p-4  ">
                      <div>
                        <div className="text-xl py-4 ">
                          ยังไม่มีการเปลี่ยนยาง
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ) : (
                _.map(
                  _.filter(wheelMaintenance?.wheelTransection, { topic: 'IN' }),
                  (each) => (
                    <div className="p-2">
                      <Card sx={{ width: 280 }}>
                        <div className="p-4 flex ">
                          <div className="py-2 px-1">
                            <SupportIcon
                              sx={{ fontSize: 40, color: 'green' }}
                            />
                          </div>
                          <div>
                            <div className="text-xl font-bold ">
                              เลขยาง: {each?.wheel?.wheelNumber}
                            </div>
                            <div>ตำแหน่งยาง: {each?.wheel?.position}</div>
                            <div>ยี่ห้อ: {each?.wheel?.supply?.brand}</div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ),
                )
              )}
            </div>
            <div className="font-bold">
              ยางออก <ArrowCircleUpIcon />{' '}
            </div>
            <div className="lg:flex flex-wrap">
              {_.isEmpty(wheelMaintenance?.wheelTransection) ? (
                <div className="p-2">
                  <Card sx={{ width: 280 }}>
                    <div className="p-4 ">
                      <div>
                        <div className="text-xl py-4 ">
                          ยังไม่มีการเปลี่ยนยาง
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ) : (
                _.map(
                  _.filter(wheelMaintenance?.wheelTransection, {
                    topic: 'OUT',
                  }),
                  (each) => (
                    <div className="p-2">
                      <Card sx={{ width: 280 }}>
                        <div className="p-4 flex">
                          <div className="py-2 px-1">
                            <SupportIcon sx={{ fontSize: 40 }} />
                          </div>
                          <div>
                            <div className="text-xl font-bold ">
                              เลขยาง: {each?.wheel?.wheelNumber}
                            </div>
                            <div>ตำแหน่งยาง: {each?.wheel?.position}</div>
                            <div>ยี่ห้อ: {each?.wheel?.supply?.brand}</div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ),
                )
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  const renderTransection = () => (
    <div className="py-2">
      <Card>
        <div className="p-2">
          <div className="font-bold">รายการซ่อม/ค่าใช้จ่ายเพิ่มเติม</div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold"> ลำดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.isEmpty(wheelTransection.rows) ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <div className="text-center">ไม่มีรายการซ่อม</div>
                  </TableCell>
                </TableRow>
              ) : (
                _.map(wheelTransection.rows, (e, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div>
                        <div>{e?.repairTopic}</div>
                        {e?.wheel && <div>เลขยาง {e?.wheel?.wheelNumber}</div>}
                      </div>
                    </TableCell>
                    <TableCell>{e?.price}</TableCell>
                    <TableCell>
                      <div>
                        <Button
                          onClick={() => handleDelete(e?._id)}
                          variant="contained"
                          color="error"
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  );

  return (
    <div className="pt-2">
      {renderDetail()}
      {renderActionButton()}
      {renderMeterial()}
      {renderMaintenance()}
      {renderTransection()}
      {renderDetailWheelMaintenanse()}
    </div>
  );
}

CardDetailWheelMaintenance.propTypes = {
  wheelMaintenance: PropTypes.object,
  handSuccessJob: PropTypes.func,
  handleAcessJob: PropTypes.func,
  handleWheelMaintenance: PropTypes.func,
  handleCancleJob: PropTypes.func,
  wheelTransection: PropTypes.object,
  handleWheelRepair: PropTypes.func,
  handleWheelRetread: PropTypes.func,
  handleDelete: PropTypes.func,
};
