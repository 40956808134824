import React, { useState } from 'react';
import { TextField, MenuItem, FormControl, Autocomplete } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export function MaterialWithoutStockMiniForm({
  errors,
  material,
  control,
  materialType,
  initialMaterialName,
}) {
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;
  return (
    <div className="flex flex-row flex-wrap w-full">
      <div className="font-semibold">ข้อมูลวัสดุ</div>
      {imsSetting?.attribute?.type_code && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'type_code'}
            control={control}
            defaultValue={material ? material?.type_code : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รหัสวัสดุ"
                fullWidth
                size={'small'}
                helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      <div className="w-full  px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={initialMaterialName || ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อวัสดุ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={material ? material?.unit : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยวัสดุ"
              fullWidth
              size={'small'}
              helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'material_type'}
          control={control}
          defaultValue={materialType?.[0]}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <Autocomplete
                {...field}
                fullWidth
                size="small"
                options={materialType}
                autoHighlight
                getOptionLabel={(option) => `${option?.name}`}
                renderOption={(props, option) => (
                  <MenuItem {...props}>{option?.name} </MenuItem>
                )}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="ประเภทวัสดุ"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        />
      </div>

      {imsSetting?.attribute?.register_number && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'register_number'}
            control={control}
            defaultValue={material ? material?.register_number : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขทะเบียน / Serial Number"
                fullWidth
                size={'small'}
                helperText={errors.register_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

MaterialWithoutStockMiniForm.propTypes = {
  errors: PropTypes.object,
  material: PropTypes.object,
  control: PropTypes.object,
  materialType: PropTypes.arrayOf(PropTypes.object),
  initialMaterialName: PropTypes.string,
};

MaterialWithoutStockMiniForm.defaultProps = {
  material: null,
  materialType: null,
};

export default MaterialWithoutStockMiniForm;
