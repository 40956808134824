import {
  MAINTENANCE_TOPIC_ALL,
  MAINTENANCE_TOPIC_GET,
  MAINTENANCE_TOPIC_DEL,
  MAINTENANCE_TOPIC_PUT,
  MAINTENANCE_TOPIC_POST,
  MAINTENANCE_TOPIC_LOADING,
  MAINTENANCE_TOPIC_ERROR,
  MAINTENANCE_TOPIC_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const maintenanceTopicCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TOPIC_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/maintenance-topic`,
      payload,
    );
    dispatch({ type: MAINTENANCE_TOPIC_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TOPIC_ERROR });
    throw new Error(error);
  }
};

export const maintenanceTopicAll =
  ({ name = '', type = '', size = '', page = 1 }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/maintenance-topic?name=${name}&type=${type}&size=${size}&page=${page}`,
      );
      if (status === 200) {
        dispatch({ type: MAINTENANCE_TOPIC_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: MAINTENANCE_TOPIC_ERROR });
      throw new Error(error);
    }
  };

export const maintenanceTopicGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/maintenance-topic/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MAINTENANCE_TOPIC_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TOPIC_ERROR });
    throw new Error(error);
  }
};

export const maintenanceTopicPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TOPIC_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/maintenance-topic/${id}`,
      payload,
    );
    dispatch({ type: MAINTENANCE_TOPIC_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TOPIC_ERROR });
    throw new Error(error);
  }
};
export const maintenanceTopicDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TOPIC_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/maintenance-topic/${id}`,
    );
    dispatch({ type: MAINTENANCE_TOPIC_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TOPIC_ERROR });
    throw new Error(error);
  }
};

export const maintenanceTopicReset = () => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_TOPIC_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_TOPIC_ERROR });
    throw new Error(error);
  }
};
