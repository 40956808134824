import {
  BILL_HEADER_ALL,
  BILL_HEADER_GET,
  BILL_HEADER_PUT,
  BILL_HEADER_DELETE,
  BILL_HEADER_POST,
  BILL_HEADER_RESET,
  BILL_HEADER_LOADING,
  BILL_HEADER_ERROR,
} from '../../actions/types';

const initialState = {
  billHeader: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function BillHeaderReducer(state = initialState, action) {
  switch (action.type) {
    case BILL_HEADER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BILL_HEADER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BILL_HEADER_POST:
      return { isLoading: false, isCompleted: true };
    case BILL_HEADER_PUT:
      return { isLoading: false, isCompleted: true };
    case BILL_HEADER_DELETE:
      return { isLoading: false, isCompleted: true };
    case BILL_HEADER_RESET:
      return { BILL: null, isLoading: true, isCompleted: true };
    case BILL_HEADER_LOADING:
      return { isLoading: true, isCompleted: true };
    case BILL_HEADER_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
