/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { Button, Card, IconButton } from '@mui/material';

import _ from 'lodash';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import img_pickup from '../../../assets/img/pickUp.PNG';
import img_truck from '../../../assets/img/truck_wheel.PNG';

const VehicleWheelInfo = ({
  wheelRegister,
  setOpen,
  setWheelInfo,
  setWheelSelect,
  maintain,
  wheelInVehicle,
  addWheelButton,
  setAddWheel,
  setPosition,
}) => {
  const renderVehicleFrom = (wheel, wheelData) => {
    const wheelModal = (wheel) => {
      if (!maintain) {
        return (
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setWheelInfo(wheel);
              setOpen(true);
            }}
          >
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        );
      }
      if (maintain) {
        return (
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              setWheelSelect(wheel);
            }}
          >
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        );
      }
    };
    const addWheelToVehicle = (position) => (
      <IconButton
        color="primary"
        size="small"
        onClick={() => {
          setAddWheel(true);
          setPosition(position);
        }}
      >
        <OpenInNewIcon fontSize="inherit" />
      </IconButton>
    );

    const wheels = wheelInVehicle;

    if (wheel <= 4) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });

      return (
        <div>
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>

            <div className="flex w-full justify-center">
              <div className="w-1/3 pt-6 ">
                <div className="flex justify-end">
                  <div className="font-bold">
                    เลขยาง1 : {wheel1?.wheelNumber}
                    {wheel1?.wheelNumber
                      ? wheelModal(wheel1)
                      : addWheelToVehicle(1)}
                  </div>
                </div>
                <div className="flex justify-end pt-36">
                  <div className="font-bold">
                    เลขยาง3 : {wheel3?.wheelNumber}
                    {wheel3?.wheelNumber
                      ? wheelModal(wheel3)
                      : addWheelToVehicle(3)}
                  </div>
                </div>
              </div>
              <div>
                <img src={img_pickup}></img>
              </div>
              <div className="w-1/3 pt-6 ">
                <div className="font-bold">
                  เลขยาง2 : {wheel2?.wheelNumber}{' '}
                  {wheel2?.wheelNumber
                    ? wheelModal(wheel2)
                    : addWheelToVehicle(2)}
                </div>
                <div className="flex  pt-36">
                  <div className="font-bold">
                    เลขยาง4 : {wheel4?.wheelNumber}{' '}
                    {wheel4?.wheelNumber
                      ? wheelModal(wheel4)
                      : addWheelToVehicle(4)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 6) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      const wheel5 = _.find(wheels, { position: 5 });
      const wheel6 = _.find(wheels, { position: 6 });
      return (
        <div>
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end">
                  <div className="font-bold">
                    เลขยาง1 : {wheel1?.wheelNumber}
                    {wheel1?.wheelNumber
                      ? wheelModal(wheel1)
                      : addWheelToVehicle(1)}
                  </div>
                </div>
                <div className="flex justify-end pt-56">
                  <div className="font-bold pr-4">
                    เลขยาง3 : {wheel3?.wheelNumber}{' '}
                    {wheel3?.wheelNumber
                      ? wheelModal(wheel3)
                      : addWheelToVehicle(3)}
                  </div>
                  <div className="font-bold">
                    เลขยาง4 : {wheel4?.wheelNumber}{' '}
                    {wheel4?.wheelNumber
                      ? wheelModal(wheel4)
                      : addWheelToVehicle(4)}
                  </div>
                </div>
              </div>
              <div>
                <img src={img_truck}></img>
              </div>
              <div className=" pt-12 ">
                <div className="flex">
                  <div className="font-bold ">
                    เลขยาง2 : {wheel2?.wheelNumber}{' '}
                    {wheel2?.wheelNumber
                      ? wheelModal(wheel2)
                      : addWheelToVehicle(2)}
                  </div>
                </div>
                <div className="flex  pt-56">
                  <div className="font-bold pr-4">
                    เลขยาง5 : {wheel5?.wheelNumber}{' '}
                    {wheel5?.wheelNumber
                      ? wheelModal(wheel5)
                      : addWheelToVehicle(5)}
                  </div>
                  <div className="font-bold">
                    เลขยาง6 : {wheel6?.wheelNumber}{' '}
                    {wheel6?.wheelNumber
                      ? wheelModal(wheel6)
                      : addWheelToVehicle(6)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 10) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      const wheel5 = _.find(wheels, { position: 5 });
      const wheel6 = _.find(wheels, { position: 6 });
      const wheel7 = _.find(wheels, { position: 7 });
      const wheel8 = _.find(wheels, { position: 8 });
      const wheel9 = _.find(wheels, { position: 9 });
      const wheel10 = _.find(wheels, { position: 10 });
      return (
        <div>
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end">
                  <div className="font-bold">
                    เลขยาง1 : {wheel1?.wheelNumber}
                    {wheel1?.wheelNumber
                      ? wheelModal(wheel1)
                      : addWheelToVehicle(1)}
                  </div>
                </div>
                <div className="flex justify-end pt-56">
                  <div className="font-bold pr-4">
                    เลขยาง3 : {wheel3?.wheelNumber}
                    {wheel3?.wheelNumber
                      ? wheelModal(wheel3)
                      : addWheelToVehicle(3)}
                  </div>
                  <div className="font-bold">
                    เลขยาง4: {wheel4?.wheelNumber}
                    {wheel4?.wheelNumber
                      ? wheelModal(wheel4)
                      : addWheelToVehicle(4)}
                  </div>
                </div>
                <div className="flex justify-end pt-6">
                  <div className="font-bold pr-4">
                    เลขยาง7: {wheel7?.wheelNumber}
                    {wheel7?.wheelNumber
                      ? wheelModal(wheel7)
                      : addWheelToVehicle(7)}
                  </div>
                  <div className="font-bold">
                    เลขยาง8 : {wheel8?.wheelNumber}
                    {wheel8?.wheelNumber
                      ? wheelModal(wheel8)
                      : addWheelToVehicle(8)}
                  </div>
                </div>
              </div>
              <div>
                <img src={img_truck}></img>
              </div>
              <div className=" pt-12 ">
                <div className="flex">
                  <div className="font-bold ">
                    เลขยาง2 :{wheel2?.wheelNumber}
                    {wheel2?.wheelNumber
                      ? wheelModal(wheel2)
                      : addWheelToVehicle(2)}
                  </div>
                </div>
                <div className="flex  pt-56">
                  <div className="font-bold pr-4">
                    เลขยาง5 : {wheel5?.wheelNumber}
                    {wheel5?.wheelNumber
                      ? wheelModal(wheel5)
                      : addWheelToVehicle(5)}
                  </div>
                  <div className="font-bold">
                    เลขยาง6 : {wheel6?.wheelNumber}
                    {wheel6?.wheelNumber
                      ? wheelModal(wheel6)
                      : addWheelToVehicle(6)}
                  </div>
                </div>
                <div className="flex pt-6">
                  <div className="font-bold pr-4">
                    เลขยาง9 : {wheel9?.wheelNumber}
                    {wheel9?.wheelNumber
                      ? wheelModal(wheel9)
                      : addWheelToVehicle(9)}
                  </div>
                  <div className="font-bold">
                    เลขยาง10 : {wheel10?.wheelNumber}
                    {wheel10?.wheelNumber
                      ? wheelModal(wheel10)
                      : addWheelToVehicle(10)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 12) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      const wheel5 = _.find(wheels, { position: 5 });
      const wheel6 = _.find(wheels, { position: 6 });
      const wheel7 = _.find(wheels, { position: 7 });
      const wheel8 = _.find(wheels, { position: 8 });
      const wheel9 = _.find(wheels, { position: 9 });
      const wheel10 = _.find(wheels, { position: 10 });
      const wheel11 = _.find(wheels, { position: 11 });
      const wheel12 = _.find(wheels, { position: 12 });
      return (
        <div>
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end">
                  <div className="font-bold">
                    เลขยาง1 : {wheel1?.wheelNumber}{' '}
                    {wheel1?.wheelNumber
                      ? wheelModal(wheel1)
                      : addWheelToVehicle(1)}
                  </div>
                </div>
                <div className="flex justify-end pt-4">
                  <div className="font-bold">
                    เลขยาง3 : {wheel3?.wheelNumber}{' '}
                    {wheel3?.wheelNumber
                      ? wheelModal(wheel3)
                      : addWheelToVehicle(3)}
                  </div>
                </div>
                <div className="flex justify-end pt-44">
                  <div className="font-bold pr-4">
                    เลขยาง5 : {wheel5?.wheelNumber}{' '}
                    {wheel5?.wheelNumber
                      ? wheelModal(wheel5)
                      : addWheelToVehicle(5)}
                  </div>
                  <div className="font-bold">
                    เลขยาง6 : {wheel6?.wheelNumber}{' '}
                    {wheel6?.wheelNumber
                      ? wheelModal(wheel6)
                      : addWheelToVehicle(6)}
                  </div>
                </div>
                <div className="flex justify-end pt-6">
                  <div className="font-bold pr-4">
                    เลขยาง9 : {wheel9?.wheelNumber}{' '}
                    {wheel9?.wheelNumber
                      ? wheelModal(wheel9)
                      : addWheelToVehicle(9)}
                  </div>
                  <div className="font-bold">
                    เลขยาง10: {wheel10?.wheelNumber}{' '}
                    {wheel10?.wheelNumber
                      ? wheelModal(wheel10)
                      : addWheelToVehicle(10)}
                  </div>
                </div>
              </div>
              <div>
                <img src={img_truck}></img>
              </div>
              <div className=" pt-12 ">
                <div className="font-bold ">
                  เลขยาง2 :{wheel2?.wheelNumber}
                  {wheel2?.wheelNumber
                    ? wheelModal(wheel2)
                    : addWheelToVehicle(2)}
                </div>
                <div className="font-bold pt-4 ">
                  เลขยาง4 :{wheel4?.wheelNumber}
                  {wheel4?.wheelNumber
                    ? wheelModal(wheel4)
                    : addWheelToVehicle(4)}
                </div>

                <div className="flex  pt-44">
                  <div className="font-bold pr-4">
                    เลขยาง7 : {wheel7?.wheelNumber}
                    {wheel7?.wheelNumber
                      ? wheelModal(wheel7)
                      : addWheelToVehicle(7)}
                  </div>
                  <div className="font-bold">
                    เลขยาง8 : {wheel8?.wheelNumber}
                    {wheel8?.wheelNumber
                      ? wheelModal(wheel8)
                      : addWheelToVehicle(8)}
                  </div>
                </div>
                <div className="flex pt-6">
                  <div className="font-bold pr-4">
                    เลขยาง11 : {wheel11?.wheelNumber}
                    {wheel11?.wheelNumber
                      ? wheelModal(wheel11)
                      : addWheelToVehicle(11)}
                  </div>
                  <div className="font-bold">
                    เลขยาง12: {wheel12?.wheelNumber}
                    {wheel12?.wheelNumber
                      ? wheelModal(wheel12)
                      : addWheelToVehicle(12)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <div className="pt-2">
          {renderVehicleFrom(
            wheelRegister?.vehicle?.type?.wheel,
            wheelRegister?.wheels,
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleWheelInfo;
