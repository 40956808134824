import dayjs from 'dayjs';
import invoiceBody from './body';
import { renderRemark } from './renderRemark';
export const invoiceTable = (info, row, { invoice }) => [
  {
    alignment: 'between',
    // margin: [20, 4, 20, 0],
    table: {
      widths: ['5%', '50%', '10%', '15%', '15%'],
      body: [
        [
          {
            text: 'No.:',
            style: 'tableHeader',
          },
          {
            text: 'Item/Description',
            style: 'tableHeader',
          },
          {
            text: 'oil Price ',
            style: 'tableHeader',
          },
          {
            text: 'Unit Price ',
            style: 'tableHeader',
          },
          {
            text: 'Amount ',
            style: 'tableHeader',
          },
        ],
        ...invoiceBody(row?.bookings),
        ...renderRemark(row?.additional_price),
        [
          {
            text: 'Remark:',
            colSpan: 2,
            border: [true, false, false, false],
          },
          {
            text: '',
          },
          {
            text: '',
            border: [false, false, false, false],
          },
          {
            text: 'SUBTOTAL',
            border: [false, false, true, false],
            alignment: 'right',
          },
          {
            text: row?.price?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            alignment: 'right',
            border: [true, false, true, false],
          },
        ],
        [
          {
            text: 'Please transfer to:',
            border: [true, false, false, false],
            colSpan: 2,
          },
          {
            text: '',
          },
          {
            text: '',
            border: [false, false, false, false],
          },
          {
            text: '',
            border: [false, false, true, false],
          },
          {
            text: '',
            alignment: 'right',
            border: [true, false, true, false],
          },
        ],
        [
          {
            text: info?.owner?.name,
            colSpan: 2,
            border: [true, false, false, false],
          },
          {
            text: '',
          },
          {
            text: '',
            border: [false, false, false, false],
          },
          {
            text: '',
            border: [false, false, true, false],
          },
          {
            text: '',
            alignment: 'right',
            border: [true, false, true, false],
          },
        ],
        [
          {
            text: `Bank Account: ${info?.bank?.name || '-'} / ${
              info?.bank?.accountNumber || '-'
            } `,
            colSpan: 2,
            border: [true, false, false, false],
          },
          {
            text: '',
          },
          invoice
            ? {
                text: 'At Amount 1.00%',
                border: [false, false, true, false],
                colSpan: 2,
                alignment: 'right',
              }
            : {
                text: '',
                border: [false, false, true, false],
                colSpan: 2,
              },
          {
            text: '',
          },
          invoice
            ? {
                text: (row?.price * 0.01)
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                border: [true, false, true, false],

                alignment: 'right',
              }
            : {
                text: '',
                border: [true, false, true, false],
              },
        ],
        [
          {
            text: ` ${info?.bank?.name || ''}  ${info?.bank?.road || ''}  ${
              info?.bank?.district || ''
            }  ${info?.bank?.province || ''}  ${info?.bank?.zipCode || ''} `,
            colSpan: 2,
            border: [true, false, false, false],
            style: 'tableBody1',
          },
          {
            text: '',
          },
          invoice
            ? {
                text: 'Total Invoice',
                border: [false, false, true, true],
                colSpan: 2,
                alignment: 'right',
              }
            : {
                text: '',
                border: [false, false, true, true],
                colSpan: 2,
              },
          {
            text: '',
          },
          invoice
            ? {
                text: `${
                  (row?.price - row?.price * 0.01)
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'
                }`,
                border: [true, false, true, true],
                alignment: 'right',
                style: 'tableBody1',
              }
            : {
                text: '',
                border: [true, false, true, true],
              },
        ],
        [
          {
            text: 'A bove mentioned goods in good order an condition',
            colSpan: 2,
            border: [true, true, false, false],
          },
          {
            text: '',
          },
          {
            text: `For ${row?.customer?.name || ''}`,
            colSpan: 3,
            style: 'tableBody1',
            border: [false, false, true, false],
          },
          {
            text: '',
            border: [false, false, true, false],
          },
          {
            text: '',
          },
        ],
        [
          {
            text: 'Receiver.....................................Date......../......../........',
            colSpan: 3,
            margin: [0, 20],
            border: [true, false, false, true],
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: 'Authorized by.....................................',
            colSpan: 2,
            margin: [0, 20],
            border: [false, false, true, true],
            style: 'tableBody1',
          },
          {
            text: '',
          },
        ],
      ],
    },
  },
];

export default invoiceTable;
