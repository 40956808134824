import React, { useState } from 'react';
import _ from 'lodash';
import {
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { List as ListIcon, Grid as GridIcon } from 'react-feather';

import { currencyFormatter } from '../../../utils/functions';

const OuterServiceCard = ({ outerServices, showFullPanel = false }) => {
  const [view, setView] = useState(
    window.localStorage.getItem('default_view') || 'card',
  );

  const handleSetView = (event, newView) => {
    if (newView !== null) {
      window.localStorage.setItem('default_view', newView);
      setView(newView);
    }
  };

  const columns = [
    {
      field: 'index',
      headerName: 'ลำดับที่',
    },
    {
      field: 'title',
      headerName: 'รายการ',
      width: showFullPanel ? 300 : 150,
    },
    {
      field: 'garage',
      headerName: 'อู่ซ่อม',
      valueGetter: (params) => `${params?.row?.garage?.name || '-'} `,
      width: showFullPanel ? 300 : 150,
    },
    {
      field: 'price',
      headerName: 'ราคา',
      type: 'number',
    },
  ];

  const renderCardView = () => (
    <div className="flex flex-wrap my-1">
      {_.map(outerServices, (outerServices, index) => (
        <div
          key={index}
          className={`w-full ${showFullPanel ? 'md:w-1/6' : 'md:w-1/3'}  p-1 `}
        >
          <div className="p-2 border rounded-md h-full ">
            <div className="font-semibold my-1">{outerServices?.title}</div>
            <div className="font-sansserif text-sm md:mt-4">
              <i className="fa-solid fa-screwdriver-wrench mr-2"></i>
              {outerServices?.garage?.name}
            </div>
            <div className="font-sansserif text-sm">
              <i className="fa-solid fa-money-bill-wave mr-2"></i>{' '}
              {currencyFormatter.format(outerServices?.price)} บาท
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderTableView = () => (
    <div className="my-2">
      {' '}
      <div
        style={{
          height: _.size(outerServices) * 65 + 100,
          width: '100%',
        }}
      >
        <DataGrid
          rows={_.map(outerServices, (eachTopic, index) => ({
            ...eachTopic,
            id: index,
            index: index + 1,
          }))}
          columns={columns}
          pageSize={_.size(outerServices)}
          rowsPerPageOptions={[5]}
        />
      </div>
    </div>
  );

  return (
    <div className="my-2 h-full">
      <Card className="h-full">
        <CardContent>
          <div className="flex justify-between">
            <div className="font-semibold self-center">
              การซ่อมบำรุงจากภายนอก (ค่าแรงนอกประกอบการซ่อมบำรุง)
            </div>
            <div>
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleSetView}
              >
                <ToggleButton value="card">
                  <GridIcon size={16} />
                </ToggleButton>
                <ToggleButton value="table">
                  {' '}
                  <ListIcon size={16} />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          {view === 'card' && renderCardView()}
          {view === 'table' && renderTableView()}
        </CardContent>
      </Card>
    </div>
  );
};

OuterServiceCard.propTypes = {
  outerServices: PropTypes.arrayOf(PropTypes.object),
  showFullPanel: PropTypes.bool,
};

export default OuterServiceCard;
