import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

const MaterialStockTable = ({
  material,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  placeId,
}) => (
  <div>
    {' '}
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ชื่อวัสดุ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> คงเหลือ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ราคา/หน่วย</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ราคารวม</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(material?.rows) ? (
              material?.rows.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {(page - 1) * size + index + 1}
                  </TableCell>
                  <TableCell>
                    <div>
                      <span className="font-bold">{row?.type_code}</span>{' '}
                      <span>{row?.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(row?.total)} {row?.unit}
                    {row?.total <= row?.minimum_amount && (
                      <div className="text-sm text-red-600 font-semibold">
                        {' '}
                        <i className="fas fa-sort-down"></i>{' '}
                        {currencyFormatter.format(
                          (row?.minimum_amount || 0) - row?.total,
                        )}{' '}
                        {row?.unit}
                      </div>
                    )}
                    {row?.total >= row?.maximum_amount && (
                      <div className="text-sm text-yellow-600 font-semibold">
                        {' '}
                        <i className="fas fa-sort-up"></i>{' '}
                        {currencyFormatter.format(
                          (row?.total || 0) - row?.maximum_amount,
                        )}{' '}
                        {row?.unit}
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <div>{currencyFormatter.format(row?.value_per_unit)}</div>{' '}
                  </TableCell>
                  <TableCell>
                    <div>{currencyFormatter.format(row?.total_value)}</div>{' '}
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-row flex-wrap gap-1">
                      <Link
                        to={`/ims/material-stock/${row?._id}/${
                          placeId || 'all'
                        }`}
                      >
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                        >
                          รายละเอียด
                        </Button>
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  </div>
);

MaterialStockTable.propTypes = {
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  placeId: PropTypes.string,
};

export default MaterialStockTable;
