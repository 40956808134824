/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardContent,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

// eslint-disable-next-line react/prop-types
export default function WheelAddFormArr({
  control,
  Controller,
  wheelSupply,
  fields,
  append,
  remove,
}) {
  // console.log('wheelType in edir form', wheelType);
  const handleDelete = (index) => {
    remove(index);
  };
  const handleAdd = () => {
    append({
      wheelNumber: '',
    });
  };
  return (
    <div className="pt-2">
      <div>
        <Card>
          <CardContent>
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
                  <TableHead className="bg-indigo-500  ">
                    <TableRow>
                      <TableCell>
                        {' '}
                        <h1 className="text-center font-bold text-white">
                          ลำดับ
                        </h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="text-center font-bold text-white">
                          เลขยาง
                        </h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="text-center font-bold text-white">
                          ยี่ห้อยาง
                        </h1>
                      </TableCell>

                      <TableCell>
                        <h1 className="text-center font-bold text-white">
                          หมายเหตุ
                        </h1>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(fields, (item, index) => (
                      <TableRow
                        key={item?.id}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="pb-1 text-center">{index + 1}</div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="py-1">
                            <Controller
                              name={`wheelArray.${index}.wheelNumber`}
                              control={control}
                              // defaultValue={item?.price}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  label="เลขยาง"
                                  size="small"
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className=" px-1 py-2">
                            <Controller
                              name={`wheelArray.${index}.supply`}
                              control={control}
                              // defaultValue={
                              //   wheel ? wheel?.supply?._id : ''
                              // }
                              rules={{ required: true }}
                              render={({ field }) => (
                                <FormControl fullWidth size="small">
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    fullWidth
                                    size="small"
                                  >
                                    ยี่ห้อ
                                  </InputLabel>
                                  <Select {...field} label="ประเภทยาง">
                                    {_.map(wheelSupply?.rows, (each) => (
                                      <MenuItem
                                        key={each?._id}
                                        value={each?._id}
                                      >
                                        {each?.brand}: {each?.type}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </div>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <div className="py-1">
                            <Controller
                              name={`wheelArray.${index}.note`}
                              control={control}
                              // defaultValue={item?.price}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  label="หมายเหตุ"
                                  size="small"
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="error"
                            variant="contained"
                            className="w-full "
                            onClick={() => {
                              handleDelete(index);
                            }}
                          >
                            <Delete />
                            ลบ
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>{' '}
                </Table>
              </TableContainer>{' '}
              <div className="text-center p-4 lg:w-1/2 ">
                <Button variant="contained" onClick={() => handleAdd()}>
                  เพิ่ม
                </Button>
              </div>
            </div>
            <div className="flex justify-end pb-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
