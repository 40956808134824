import {
  VEHICLETYPES_ALL,
  VEHICLETYPES_GET,
  VEHICLETYPES_DEL,
  VEHICLETYPES_PUT,
  VEHICLETYPES_POST,
  VEHICLETYPES_LOADING,
  VEHICLETYPES_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const vehicletypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLETYPES_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/vehicle-type`,
      {
        ...payload,
      },
    );
    dispatch({ type: VEHICLETYPES_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLETYPES_ERROR });
  }
};

export const vehicletypeAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = null, page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/vehicle-type?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: VEHICLETYPES_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLETYPES_ERROR });
  }
};

export const vehicletypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/vehicle-type/${id}`,
    );
    if (status === 200) {
      dispatch({ type: VEHICLETYPES_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLETYPES_ERROR });
  }
};

export const vehicletypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLETYPES_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/vehicle-type/${id}`,
      payload,
    );
    dispatch({ type: VEHICLETYPES_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLETYPES_ERROR });
  }
};
export const vehicletypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLETYPES_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/vehicle-type/${id}`,
    );
    dispatch({ type: VEHICLETYPES_DEL, payload: data });
  } catch (error) {
    const { status } = error.request;
    console.error(error);

    if (status === 403) {
      const message = 'ไม่สามารถลบข้อมูลเนื่องจากยังคงมีข้อมูลอยู่ในหมวดหมู่';
      dispatch({ type: VEHICLETYPES_ERROR, payload: { message } });
      throw new Error(message);
    } else {
      const message = 'เกิดข้อผิดพลาด';
      dispatch({ type: VEHICLETYPES_ERROR, payload: { message } });
      throw new Error(message);
    }
  }
};
