import {
  MAINTENANCE_ORDER_ALL,
  MAINTENANCE_ORDER_GET,
  MAINTENANCE_ORDER_DEL,
  MAINTENANCE_ORDER_PUT,
  MAINTENANCE_ORDER_POST,
  MAINTENANCE_ORDER_LOADING,
  MAINTENANCE_ORDER_ERROR,
  MAINTENANCE_ORDER_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const maintenanceOrderCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_ORDER_LOADING });
    if (payload?.file) {
      const formData = new FormData();
      formData.append('file', payload?.file);

      const { data: uploadData } = await api.post(
        `${process.env.REACT_APP_API_URL}/upload/file`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/maintenance-order`,
        {
          ...payload,
          file: uploadData?.id,
        },
      );
      dispatch({ type: MAINTENANCE_ORDER_POST, payload: data });
    } else {
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/maintenance-order`,
        {
          ...payload,
        },
      );
      dispatch({ type: MAINTENANCE_ORDER_POST, payload: data });
    }
  } catch (error) {
    // console.error(error);
    // console.log(error?.response?.data?.error?.message);
    dispatch({ type: MAINTENANCE_ORDER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const maintenanceOrderAll =
  ({
    size = '',
    page = 1,
    vehicle = '',
    includeHistory = false,
    start = '',
    end = '',
    fetchHistory = false,
    name = '',
    status = '',
    fillter = false,
  }) =>
  async (dispatch) => {
    try {
      const { data, status: reqStatus } = await api.get(
        `${process.env.REACT_APP_API_URL}/maintenance-order?size=${size}&page=${page}&vehicle=${vehicle}&includeHistory=${includeHistory}&start=${start}&end=${end}&fetchHistory=${fetchHistory}&name=${name}&status=${status}&fillter=${fillter}`,
      );
      if (reqStatus === 200) {
        dispatch({ type: MAINTENANCE_ORDER_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: MAINTENANCE_ORDER_ERROR });
      throw new Error(error);
    }
  };

export const maintenanceOrderGet =
  (id, params = { fetchHistory: true }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/maintenance-order/${id}?fetchHistory=${params?.fetchHistory}`,
      );
      if (status === 200) {
        dispatch({ type: MAINTENANCE_ORDER_GET, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: MAINTENANCE_ORDER_ERROR });
      throw new Error(error);
    }
  };

export const maintenanceOrderPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_ORDER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/maintenance-order/${id}`,
      payload,
    );
    dispatch({ type: MAINTENANCE_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_ORDER_ERROR });
    throw new Error(error);
  }
};
export const maintenanceOrderDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_ORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/maintenance-order/${id}`,
    );
    dispatch({ type: MAINTENANCE_ORDER_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_ORDER_ERROR });
    throw new Error(error);
  }
};

export const maintenanceOrderReset = () => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_ORDER_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_ORDER_ERROR });
    throw new Error(error);
  }
};
