/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import Button from 'components/Button';

export function CustomerForm({
  errors,
  customerType,
  customer,
  Controller,
  control,
  customerName,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          defaultValue={customer ? customer?.type?._id : ''}
          render={({ field }) => (
            <FormControl required sx={{ minWidth: 120 }} fullWidth>
              <InputLabel id="type" size={'small'}>
                หมวดหมู่
              </InputLabel>
              <Select
                {...field}
                label="หมวดหมู่"
                size={'small'}
                required
                autoWidth
              >
                {_.size(customerType?.rows) ? (
                  _.map(customerType.rows, (row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2"></div>
      <div className="w-full px-1 py-2 font-semibold">ข้อมูลบริษัท</div>
      <div className="w-full px-1 py-1 lg:flex ">
        <Controller
          name={'name'}
          control={control}
          defaultValue={customer ? customer.name : customerName || ''}
          render={({ field }) => (
            <TextField
              {...field}
              required
              label="ชื่อบริษัท"
              fullWidth
              size={'small'}
            />
          )}
        />
        <div className="px-1 w-full  lg:w-1/2">
          <Controller
            name={'short'}
            control={control}
            defaultValue={customer ? customer.short : ''}
            render={({ field }) => (
              <TextField {...field} label="ตัวย่อ" fullWidth size={'small'} />
            )}
          />
        </div>
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'taxId'}
          control={control}
          defaultValue={customer ? customer.taxId : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขประจำตัวผู้เสียภาษี"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 font-semibold">ที่อยู่</div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.house_number'}
          control={control}
          defaultValue={customer ? customer?.address?.house_number : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="บ้านเลขที่"
              fullWidth
              size={'small'}
              helperText={errors?.address?.house_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.village_number'}
          control={control}
          defaultValue={customer ? customer?.address?.village_number : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมู่บ้าน"
              fullWidth
              size={'small'}
              helperText={errors?.address?.village_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.road'}
          control={control}
          defaultValue={customer ? customer?.address?.road : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ถนน"
              fullWidth
              size={'small'}
              helperText={errors?.address?.road && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.subdistrict'}
          control={control}
          defaultValue={customer ? customer?.address?.subdistrict : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ตำบล"
              fullWidth
              size={'small'}
              helperText={errors?.address?.subdistrict && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.district'}
          control={control}
          defaultValue={customer ? customer?.address?.district : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="อำเภอ"
              fullWidth
              size={'small'}
              helperText={errors?.address?.district && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.province'}
          control={control}
          defaultValue={customer ? customer?.address?.province : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="จังหวัด"
              fullWidth
              size={'small'}
              helperText={errors?.address?.province && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.postcode'}
          control={control}
          defaultValue={customer ? customer?.address?.postcode : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสไปรษณีย์"
              fullWidth
              size={'small'}
              helperText={errors?.address?.postcode && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'address.country'}
          control={control}
          defaultValue={customer ? customer?.address?.country : 'ไทย'}
          render={({ field }) => (
            <TextField
              {...field}
              label="ประเทศ"
              fullWidth
              size={'small'}
              helperText={errors?.address?.country && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 font-semibold">ข้อมูลช่องทางติดต่อ</div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'telephone'}
          control={control}
          defaultValue={customer ? customer.telephone : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรกลาง"
              fullWidth
              size={'small'}
              helperText={errors.telephone && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'fax'}
          control={control}
          defaultValue={customer ? customer.fax : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Fax"
              fullWidth
              size={'small'}
              helperText={errors.fax && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'email'}
          control={control}
          defaultValue={customer ? customer.email : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมลกลาง"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2 font-semibold">ผู้ติดต่อหลัก</div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contact.firstname'}
          control={control}
          defaultValue={customer ? customer?.contact?.[0]?.firstname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contact.lastname'}
          control={control}
          defaultValue={customer ? customer?.contact?.[0]?.lastname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="นามสกุล"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contact.nickname'}
          control={control}
          defaultValue={customer ? customer?.contact?.[0]?.nickname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อเล่น"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contact.position'}
          control={control}
          defaultValue={customer ? customer?.contact?.[0]?.position : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ตำแหน่ง"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contact.telephone'}
          control={control}
          defaultValue={customer ? customer?.contact?.[0]?.telephone : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="โทร"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contact.email'}
          control={control}
          defaultValue={customer ? customer?.contact?.[0]?.email : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมล"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'contact.note'}
          control={control}
          defaultValue={customer ? customer?.contact?.[0]?.note : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              // helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="text-center">
        <Button size="medium " onClick={() => {}}></Button>
      </div>
      <div className="w-full px-1 py-2 font-semibold">ผู้ติดต่อรองคนที่1</div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contactreserve.firstname'}
          control={control}
          defaultValue={
            customer ? customer?.contactreserve?.[0]?.firstname : ''
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              // helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contactreserve.lastname'}
          control={control}
          defaultValue={customer ? customer?.contactreserve?.[0]?.lastname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="นามสกุล"
              fullWidth
              size={'small'}
              // helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contactreserve.nickname'}
          control={control}
          defaultValue={customer ? customer?.contactreserve?.[0]?.nickname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อเล่น"
              fullWidth
              size={'small'}
              // helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contactreserve.position'}
          control={control}
          defaultValue={customer ? customer?.contactreserve?.[0]?.position : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ตำแหน่ง"
              fullWidth
              size={'small'}
              // helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contactreserve.telephone'}
          control={control}
          defaultValue={
            customer ? customer?.contactreserve?.[0]?.telephone : ''
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="โทร"
              fullWidth
              size={'small'}
              // helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'contactreserve.email'}
          control={control}
          defaultValue={customer ? customer?.contactreserve?.[0]?.email : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมล"
              fullWidth
              size={'small'}
              // helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'contactreserve.note'}
          control={control}
          defaultValue={customer ? customer?.contactreserve?.[0]?.note : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              // helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2 font-semibold">ข้อมูลทางการเงิน</div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'credit_term'}
          control={control}
          defaultValue={customer ? customer?.credit_term : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="เครดิต"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

CustomerForm.propTypes = {
  customerType: PropTypes.object,
  location: PropTypes.object,
  errors: PropTypes.shape({
    firstname: PropTypes.object,
    lastname: PropTypes.object,
    address: PropTypes.object,
  }),
  customer: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  customerName: PropTypes.string,
};

CustomerForm.defaultProps = {
  customer: null,
};

export default CustomerForm;
