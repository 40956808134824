/* eslint-disable react/prop-types */
import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function BillHeaderForm({ errors, billHeader, Controller, control }) {
  console.log('information', billHeader);
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.name'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อบริษัท / หน่วยงาน เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.website'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.website : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เว็บไซต์เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.telephone'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.telephone : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.telephone && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.fax'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.fax : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="แฟกซ์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.fax && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.email'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.email : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'owner.tax'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.tax : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.tax && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.house_number'}
          control={control}
          defaultValue={
            billHeader ? billHeader.owner?.address?.house_number : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.house_number && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.village_number'}
          control={control}
          defaultValue={
            billHeader ? billHeader.owner?.address?.village_number : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมู่ที่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.village_number && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.road'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.address?.road : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ถนน"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.road && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.subdistrict'}
          control={control}
          defaultValue={
            billHeader ? billHeader.owner?.address?.subdistrict : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ตำบล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.subdistrict && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.district'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.address?.district : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อำเภอ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.district && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.province'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.address?.province : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จังหวัด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.province && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.postcode'}
          control={control}
          // eslint-disable-next-line react/prop-types
          defaultValue={billHeader ? billHeader.owner?.address?.postcode : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสไปรษณีย์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.postcode && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.country'}
          control={control}
          defaultValue={billHeader ? billHeader.owner?.address?.country : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ประเทศ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.country && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full">
        <h1>ข้อมูลธนาคาร</h1>
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'bank.name'}
          control={control}
          defaultValue={billHeader ? billHeader.bank?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ธนาคาร"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.bank?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'bank.accountNumber'}
          control={control}
          defaultValue={billHeader ? billHeader.bank?.accountNumber : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่บัญชี"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.bank?.accountNumber && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'bank.road'}
          control={control}
          defaultValue={billHeader ? billHeader.bank?.road : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ถนน"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.bank?.road && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'bank.district'}
          control={control}
          defaultValue={billHeader ? billHeader.bank?.district : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ถนน"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.bank?.district && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'bank.province'}
          control={control}
          defaultValue={billHeader ? billHeader.bank?.province : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จังหวัด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.bank?.province && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'bank.zipCode'}
          control={control}
          defaultValue={billHeader ? billHeader?.bank?.zipCode : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสไปรษณีย์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.bank?.zipCode && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

BillHeaderForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    owner: PropTypes.object,
    sponsor: PropTypes.object,
  }),
  Controller: PropTypes.func,
  control: PropTypes.object,
};

BillHeaderForm.defaultProps = {
  information: null,
};

export default BillHeaderForm;
