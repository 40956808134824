import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, Check, X as XIcon, Trash } from 'react-feather';
import { Card, CardContent, Chip, IconButton } from '@mui/material';

const MaterialWithdrawRequestCard = ({
  materialRequests,
  handleDeleteMaterialRequest = () => {},
}) => (
  <div>
    <Card>
      <CardContent>
        <div className="font-semibold">การขอเบิกวัสดุ</div>
        <div className="my-1 grid grid-flow-row grid-cols-3">
          {_.map(materialRequests, (eachMaterialRequest, index) => (
            <div key={index} className="border rounded-md p-4">
              <div className="flex justify-between">
                <div>
                  <div className="text-sm font-semibold">เลขที่รายการ </div>
                  <div>{eachMaterialRequest?.running_number}</div>
                </div>
                <div>
                  <IconButton
                    variant="contained"
                    color="inherit"
                    disabled={
                      eachMaterialRequest?.deleted ||
                      eachMaterialRequest?.completed
                    }
                    onClick={() =>
                      handleDeleteMaterialRequest(eachMaterialRequest?._id)
                    }
                  >
                    <Trash />
                  </IconButton>
                </div>
              </div>

              <div className="text-sm mt-2 font-semibold">วัสดุที่ขอเบิก </div>
              <ul>
                {_.map(
                  eachMaterialRequest?.material,
                  (eachMaterial, materialIndex) => (
                    <li
                      className="list-disc list-inside font-sansserif text-sm"
                      key={`material${index}-${materialIndex}`}
                    >
                      {eachMaterial?.material?.type_code || ''}{' '}
                      {eachMaterial?.material?.name} จำนวน{' '}
                      {eachMaterial?.quantity}{' '}
                      {eachMaterial?.material?.unit || ''}
                    </li>
                  ),
                )}
              </ul>
              <div className="text-sm mt-2 font-semibold">สถานะ </div>
              <div className="my-2">
                {eachMaterialRequest?.completed && (
                  <Chip
                    size="small"
                    icon={<Check size="12" />}
                    color="success"
                    label="อนุมัติ"
                  />
                )}
                {eachMaterialRequest?.deleted && (
                  <Chip
                    size="small"
                    icon={<XIcon size="12" />}
                    color="error"
                    label="ปฏิเสธ"
                  />
                )}
                {!eachMaterialRequest?.deleted &&
                  !eachMaterialRequest?.completed && (
                    <Chip
                      size="small"
                      color="warning"
                      icon={<Loader size="12" />}
                      label="อยู่ระหว่างรอพิจารณา"
                    />
                  )}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  </div>
);

MaterialWithdrawRequestCard.propTypes = {
  materialRequests: PropTypes.arrayOf(PropTypes.object),
  handleDeleteMaterialRequest: PropTypes.func,
};

export default MaterialWithdrawRequestCard;
