/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { ArrowRight, Check } from 'react-feather';

import { dayjs, currencyFormatter } from '../../../utils/functions';
import { ASSIGNMENT_TYPE } from '../../../utils/constants';

const BillBookingCard = ({ billBooking }) => {
  return (
    <div>
      <Card>
        <div className="p-4">
          <div className="flex flex-wrap ">
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              เลขที่รายการ
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 text-lg font-semibold">
              {billBooking?.running_number}
              <div>
                {billBooking?.completed && (
                  <div className="flex px-1 bg-green-300 rounded-md">
                    <Check size="16" className="self-center mx-1" />
                    <div className="self-center mx-1">ออกบิลแล้ว</div>
                  </div>
                )}
              </div>
            </div>{' '}
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              เลข Booking
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 text-lg font-semibold">
              {billBooking?.booking_number}
            </div>{' '}
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              วันที่
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
              {dayjs(billBooking?.date).format('D MMMM BBBB')}
            </div>
            {!billBooking?.from_vehicle_schedule && (
              <div className="w-full flex flex-wrap">
                <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold ">
                  ชื่องาน
                </div>
                <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                  {billBooking?.individual_input?.job?.name}
                  <div className="flex gap-2">
                    <div
                      className={`  bg-${
                        ASSIGNMENT_TYPE?.[
                          billBooking?.individual_input?.job?.assignment_type
                        ]?.color
                      }-300 rounded-md px-2`}
                    >
                      {
                        ASSIGNMENT_TYPE?.[
                          billBooking?.individual_input?.job?.assignment_type
                        ]?.description
                      }
                    </div>
                    <div className="flex gap-2 self-center">
                      {billBooking?.individual_input?.job?.start}{' '}
                      <ArrowRight size="16" className="self-center" />{' '}
                      {billBooking?.individual_input?.job?.destination}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold ">
                  ลูกค้า
                </div>
                <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                  {billBooking?.individual_input?.job?.customer?.name} (
                  {billBooking?.individual_input?.job?.customer?.short})
                </div>
                <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold ">
                  รูปแบบการคิดเงิน
                </div>
                <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                  {' '}
                  {billBooking?.individual_input?.job?.is_fixed_cost
                    ? 'ราคาคงที่'
                    : 'แปรผันตามราคาน้ำมัน'}
                  {!billBooking?.individual_input?.job?.is_fixed_cost && (
                    <div className="w-full flex gap-2">
                      <div className="font-semibold">ราคาน้ำมัน </div>
                      {currencyFormatter.format(
                        billBooking?.individual_input?.gas_price,
                      )}{' '}
                      บาท
                    </div>
                  )}
                </div>{' '}
                <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold ">
                  ราคา
                </div>
                <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                  {' '}
                  {currencyFormatter.format(billBooking?.price)} บาท
                </div>
                <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold ">
                  ยานพาหนะ
                </div>
                <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                  <div>
                    <b>หัวรถ</b> {billBooking?.individual_input?.vehicle || '-'}{' '}
                  </div>
                  <div>
                    <b>หางรถ</b>{' '}
                    {billBooking?.individual_input?.trailer_tail || '-'}{' '}
                  </div>
                  <div>
                    <b>ตู้คอนเทนเนอร์</b>{' '}
                    {billBooking?.individual_input?.container || '-'}{' '}
                  </div>
                </div>
              </div>
            )}
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              หมายเหตุ
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
              {billBooking?.remark || '-'}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

BillBookingCard.propTypes = { billBooking: PropTypes.object };

export default BillBookingCard;
