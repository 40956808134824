/* eslint-disable import/no-unresolved */
/* eslint-disable no-sparse-arrays */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currencyFormatter } from 'utils/functions';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const renderTransection = (data) =>
  _.map(data, (each, index) => [
    {
      text: index + 1,
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.repairTopic || '', alignment: 'center', colSpan: 1 },
    {
      text: currencyFormatter.format(each?.price) || '',
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.note || '', alignment: 'center', colSpan: 1 },
  ]);

const renderUseTransection = (data) =>
  _.map(data, (each, index) => [
    {
      text: index + 1,
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: dayjs(each?.createdAt).format('DD/MM/BB') || '',
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: each?.vehicle?.licence_number || '',
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.position || '', alignment: 'center', colSpan: 1 },
    { text: each?.mileageIn || '', alignment: 'center', colSpan: 1 },
    { text: each?.mileageOut || '-', alignment: 'center', colSpan: 1 },
  ]);

const WheelReport = async (info, row, transection) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  const transectionWheel = renderTransection(
    _.filter(transection?.rows, { repairStatus: true }),
  );
  const transectionUseWheel = renderUseTransection(
    _.filter(transection?.rows, { repairStatus: false }),
  );

  const avgMileage =
    parseInt(row?.mileageOut || 0) - parseInt(row?.mileageIn || 0);

  const allPrice =
    _.sumBy(
      _.filter(transection?.rows, { repairStatus: true }),
      (e) => e?.price,
    ) + row?.supply?.price;

  const bathperKilo = allPrice / avgMileage;

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 150, 20, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '12',
    },
    header: [
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: 'สรุปรายการใช้ยาง',
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [40, 20, 40, 0],
        table: {
          widths: ['15%', '35%', '15%', '30%'],
          body: [
            [
              {
                text: 'เลขยาง',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.wheelNumber || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: 'ยี่ห้อ',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.supply?.brand || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ขนาด',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.supply?.size || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: 'ชนิดยาง',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.supply?.type || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],

    info: { title: 'สรุปรายการใช้ยาง' },

    content: [
      { text: 'วิเคราะห์ความคุ้มค่า', margin: [20, 0, 20, 4], bold: true },
      {
        margin: [20, 0, 20, 0],

        table: {
          widths: ['15%', '35%', '20%', '20%'],
          body: [
            [
              {
                text: 'เลขไมล์วันใส่',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.mileageIn || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },

              {
                text: 'เลขไมล์วันถอด',
                border: [false, false, false, false],
              },

              {
                text: row?.mileageOut || '-',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ราคายาง',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: currencyFormatter.format(row?.supply?.price) || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },

              {
                text: 'ค่าใช้จ่ายเพิ่มเติม',
                border: [false, false, false, false],
              },

              {
                text:
                  currencyFormatter.format(
                    _.sumBy(
                      _.filter(transection?.rows, { repairStatus: true }),
                      (e) => e?.price,
                    ),
                  ) || '-',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 0, 2, 0],
        table: {
          widths: ['auto', '25%', 'auto', '30%'],
          body: [
            [
              {
                text: 'จำนวนกิโลเมตรที่ใช้งาน',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },

              {
                text: `${avgMileage} ` || 0,
                border: [false, false, false, false],
                colSpan: 1,
              },
              {
                text: 'เฉลี่ย',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: currencyFormatter.format(bathperKilo) + '\tบาท/กิโลเมตร',
                colSpan: 1,
                bold: true,
                border: [false, false, false, true],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 2,
          widths: ['10%', '19%', '23%', '10%', '19%', '19%'],
          body: [
            [
              {
                text: 'รายการใช้ยาง',
                bold: true,
                margin: [0, 4],
                border: [false, false, false, true],
                colSpan: 6,
              },
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {
                text: 'ลำดับ',
                alignment: 'center',
                colSpan: 1,
              },
              { text: 'วันที่', alignment: 'center', colSpan: 1 },
              { text: 'ป้ายทะเบียน', alignment: 'center', colSpan: 1 },
              { text: 'ตำแหน่ง', alignment: 'center', colSpan: 1 },
              { text: 'ไมล์วันใส่', alignment: 'center', colSpan: 1 },
              { text: 'ไมล์วันถอด', alignment: 'center', colSpan: 1 },
            ],
            ...transectionUseWheel,
          ],
        },
      },
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 2,
          widths: ['10%', '30%', '30%', '30%'],
          body: [
            [
              {
                text: 'รายการค่าใช้จ่าย',
                bold: true,
                margin: [0, 4],
                border: [false, false, false, true],
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'ลำดับ',
                alignment: 'center',
                colSpan: 1,
              },
              { text: 'รายการ', alignment: 'center', colSpan: 1 },
              { text: 'ราคา', alignment: 'center', colSpan: 1 },
              { text: 'หมายเหตุ', alignment: 'center', colSpan: 1 },
            ],
            ...transectionWheel,
            [
              {
                text: 'รวม',
                alignment: 'center',
                colSpan: 3,
              },
              { text: '', alignment: 'center', colSpan: 1 },
              { text: 'หมายเหตุ', alignment: 'center', colSpan: 1 },
              {
                text: currencyFormatter.format(
                  _.sumBy(
                    _.filter(transection?.rows, { repairStatus: true }),
                    (e) => e?.price,
                  ),
                ),
                alignment: 'center',
                colSpan: 1,
              },
            ],
          ],
        },
      },
    ],
    footer: [{}],

    styles: {
      headerPage: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default WheelReport;
