import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import { Dashboard } from '../views/DASHBOARD';
import ByVehicleMaintenanceOrder from '../views/DASHBOARD/ByVehicleMaintenanceOrder';
import MaintenanceHistorys from '../views/DASHBOARD/MaintenanceHistorys';
import DetailMaintenanceOrderHistory from '../views/CMMS/DetailMaintenanceOrderHistory';
import DetailByVehicleMaintenanceOrder from '../views/DASHBOARD/DetailByVehicleMaintenanceOrder';
import DetailMaintenanceHistory from '../views/DASHBOARD/DetailMaintenanceHistory';
import MonthyMaintenanceAnalysis from '../views/DASHBOARD/MonthyMaintenanceAnalysis';
import DetailMaintenanceOrder from '../views/CMMS/DetailMaintenanceOrder';

export function DASHBOARD() {
  const module = 'DASHBOARD';
  const prefix = '/dashboard';
  const name = 'แดชบอร์ด';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const me = useSelector((state) => state.me);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    dispatch(actions.informationAll());
    dispatch(actions.meGet());

    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }

  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/maintenance-dashboard`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/maintenance-dashboard`}>
                <Dashboard
                  title={'แดชบอร์ดการแจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-order/vehicle`}>
                <ByVehicleMaintenanceOrder
                  title={'การแจ้งบำรุงรักษาตามเครื่อง'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-historys`}>
                <MaintenanceHistorys
                  title={'ประวัติการแจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/maintenance-order/vehicle/detail/:id`}
              >
                <DetailByVehicleMaintenanceOrder
                  title={'การแจ้งบำรุงรักษาตามเครื่องจักร'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-history/detail/:id`}>
                <DetailMaintenanceHistory
                  title={'ประวัติการแจ้งบำรุงรักษาประจำเดือน'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/monthy-maintenance-analysis/:id`}>
                <MonthyMaintenanceAnalysis
                  title="แดชบอร์ดวิเคราะห์การบำรุงรักษา"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-orders/detail/:id`}>
                <DetailMaintenanceOrder
                  title="รายละเอียดการแจ้งบำรุงรักษา"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <div></div>;
}

export default DASHBOARD;
