/* eslint-disable import/no-named-as-default */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  TextField,
  InputAdornment,
  Button,
  ButtonGroup,
} from '@mui/material';
import { useHistory, Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { DeliveryOrderTable } from '../../components/Table';
import { DetailDeliveryOrderHistory } from '../../components/Report';

const DeliveryOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const deliveryOrders = useSelector((state) => state.deliveryOrders);
  const information = useSelector((state) => state.information);
  const [assignmentType, setAssignmentType] = useState('');
  const [jobStatus, setJobStatus] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const getDeliveryOrders = () =>
    dispatch(
      actions.deliveryOrderAll({ name, page, size, assignmentType, jobStatus }),
    );
  useEffect(() => {
    getDeliveryOrders();
    return () => {};
  }, [name, page, size, assignmentType, jobStatus]);

  useEffect(() => {
    setTotal(deliveryOrders?.total);
    return () => {};
  }, [deliveryOrders]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const tableProps = {
    page,
    size,
    total,
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleReady: async (id) => {
      try {
        const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะเป็น พร้อม');
        if (confirm) {
          await dispatch(actions.deliveryOrderPut(id, { ready: true }));
          getDeliveryOrders();
        }
      } catch (error) {
        alert('แก้ไขคำสั่งจัดส่งไม่ถูกต้อง', error?.message);
      }
    },
    handleCancle: async (id) => {
      try {
        await dispatch(actions.deliveryOrderPut(id, { deleted: true }));
        getDeliveryOrders();
      } catch (error) {
        alert('แก้ไขคำสั่งจัดส่งไม่ถูกต้อง', error?.message);
      }
    },
    handleToEditPage: (id) => {
      history.push(`/tms/delivery-order/edit/${id}`);
    },
    handleToInfoPage: (id) => {
      history.push(`/tms/delivery-order/detail/${id}`);
    },
    handlePrint: (data) => {
      DetailDeliveryOrderHistory(data, information);
    },
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderButton = () => (
    <div className="pb-4">
      <Link to={'/tms/create-delivery-order'}>
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearchType = () => (
    <div>
      <div className="flex flex-wrap pb-2">
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          color="gray"
        >
          <Button onClick={() => setAssignmentType('IMPORT')}>
            <div className="text-black">IMPORT</div>
          </Button>
          <Button onClick={() => setAssignmentType('EXPORT')}>
            <div className="text-black">EXPORT</div>
          </Button>
          <Button onClick={() => setAssignmentType('')}>
            <div className="text-black">ALL</div>
          </Button>
        </ButtonGroup>
      </div>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        color="gray"
      >
        <Button onClick={() => setJobStatus(true)}>
          <div className="text-black">ดำเนินการ</div>
        </Button>
        <Button onClick={() => setJobStatus(false)}>
          <div className="text-black">รอดำเนินการ</div>
        </Button>
      </ButtonGroup>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row justify-between">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size="small"
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="self-center">
          <div>{renderSearchType()}</div>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <DeliveryOrderTable deliveryOrders={deliveryOrders} {...tableProps} />
    </div>
  );

  if (deliveryOrders.isLoading) {
    return <Loading />;
  }

  if (!deliveryOrders.isLoading && deliveryOrders.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="self-center">{renderButton()}</div>
        </div>

        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
};

DeliveryOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DeliveryOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default DeliveryOrder;
