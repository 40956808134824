import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Grid as GridIcon, List as TableIcon } from 'react-feather';

import { Link } from 'react-router-dom';
import * as actions from '../../redux/actions';
import { FinishMainatenanceOrderForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { MaintenanceOrderTable } from '../../components/Table';
import { MaintenanceOrderListCard } from '../../components/Card';
import {
  MAINTENANCE_ORDER_STATUS,
  CONFIG,
  PROCESS_TXN_TYPE,
} from '../../utils/constants';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};
const defaultValue = { licence_number: '', type: '' };

function MaintenanceOrders({ title, subtitle }) {
  const dispatch = useDispatch();
  const maintenanceOrders = useSelector((state) => state.maintenanceOrders);
  const information = useSelector((state) => state.information);
  const maintenanceTopic = useSelector((state) => state.maintenanceTopics);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [statusCode, setStatusCode] = useState('');
  const [memoModalOpen, setMemoModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [displayMode, setDisplayMode] = useState(
    window.innerWidth <= 760 ? 'card' : 'table',
  );

  const handleOpenMemo = () => setMemoModalOpen(true);
  const handleCloseMemo = () => {
    reset(defaultValue);
    setMemoModalOpen(false);
  };

  const fetchMaintenanceOrder = () => {
    try {
      dispatch(
        actions.maintenanceOrderAll({
          name,
          page,
          size,
          status: selectedStatus,
        }),
      );
    } catch (error) {
      console.error('Fetch Maintenance Order Error', error);
    }
  };

  useEffect(() => {
    fetchMaintenanceOrder();
  }, [name, page, size, selectedStatus]);

  useEffect(() => {
    dispatch(
      actions.maintenanceTopicAll({ page: 1, size: CONFIG.infiniteLimit }),
    );

    return () => {};
  }, []);

  useEffect(() => {
    setTotal(maintenanceOrders?.total);
    return () => {};
  }, [maintenanceOrders]);

  useEffect(() => {
    setValue('maintenance_topic', selectedOrder?.maintenance_topic);
    setValue('maintenance_level', selectedOrder?.maintenance_level);
    setValue('is_self_maintenance', selectedOrder?.is_self_maintenance);
    setValue('garage', selectedOrder?.garage);
    setValue('cause', selectedOrder?.cause);
    setValue('outer_services', selectedOrder?.outer_services);

    return () => {};
  }, [selectedOrder, memoModalOpen]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const onSubmitMemo = async (data) => {
    try {
      await dispatch(
        actions.maintenanceOrderPut(selectedOrder?._id, {
          ...data,
          status: MAINTENANCE_ORDER_STATUS.SUCCESS.status_code,
          process: data.process,
        }),
      );
      reset(defaultValue);
      alert('สำเร็จ');
      handleCloseMemo();
      await fetchMaintenanceOrder();
    } catch (error) {
      console.error(error);
    }
  };

  const handlePushToCreate = () => {
    history.push('maintenance-order/create');
  };

  const handleChangeDisplayMode = (event, newDisplayMode) => {
    if (newDisplayMode !== null) {
      setDisplayMode(newDisplayMode);
    }
  };

  const tableProp = {
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleOpenMemo,
    handleUpdateStatus: async (id, status) => {
      const confirm = window.confirm('ยืนยันการอัพเดตสถานะ');
      if (confirm) {
        try {
          await dispatch(actions.maintenanceOrderPut(id, { status }));
          await fetchMaintenanceOrder();
        } catch (error) {
          console.error(error);
        }
      }
    },
    handlePushToDetail: (id) => {
      history.push(`/cmms/maintenance-orders/detail/${id}`);
    },
    handleApprove: async (id) => {
      try {
        const confirm = window.confirm('ยืนยันการอนุมัติงานซ่อมบำรุง');
        if (confirm) {
          await dispatch(
            actions.maintenanceOrderPut(id, {
              allower: me?.userData?._id,
              allow_date: new Date(),
              // Add Employee Id and TransactionType for keep the transaction
              employee: me?.userData?._id,
              transaction_type: PROCESS_TXN_TYPE.PROCESS_APPROVE.status_code,
              status: MAINTENANCE_ORDER_STATUS.APPROVE.status_code,
            }),
          );
          await fetchMaintenanceOrder();
        }
      } catch (error) {
        window.alert(`การอนุมัติงานผิดพลาด ${error?.message}`);
      }
    },
    maintenanceOrders: maintenanceOrders?.rows,
    showMaintenanceLevel: information?.setting?.cmms?.show_maintenance_level,
    page,
    setSelectedOrder,
    size,
    total,
    setPage,
    setSize,
    showMaintenanceType: information?.setting?.cmms?.show_maintenance_type,
    useSparePart: information?.setting?.cmms?.use_spared_part,
    showAllowButton: !information?.setting?.cmms?.automatic_work_accept,
    maintenanceTopic,
    process: maintenanceOrders?.row?.process,
  };

  const renderTitle = () => (
    <div className="flex justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <div className="self-center flex gap-2">
        <Button
          variant="contained"
          onClick={() => {
            handlePushToCreate();
          }}
        >
          เพิ่ม
        </Button>{' '}
        <Link to="/dashboard/maintenance-historys">
          <Button variant="contained">ประวัติการซ่อม</Button>
        </Link>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap justify-between">
        <div className="w-full md:w-1/2 my-1">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/4 lg:w-1/6 my-1 flex justify-end">
          <FormControl fullWidth size={'small'}>
            <InputLabel> สถานะ</InputLabel>
            <Select
              fullWidth
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
              }}
              label="สถานะ"
            >
              <MenuItem value="" key="">
                ทุกสถานะ
              </MenuItem>
              <MenuItem value={MAINTENANCE_ORDER_STATUS.NEW.status_code}>
                {MAINTENANCE_ORDER_STATUS.NEW?.description}
              </MenuItem>
              <MenuItem value={MAINTENANCE_ORDER_STATUS.APPROVE.status_code}>
                {MAINTENANCE_ORDER_STATUS.APPROVE?.description}
              </MenuItem>
              <MenuItem value={MAINTENANCE_ORDER_STATUS.PENDING.status_code}>
                {MAINTENANCE_ORDER_STATUS.PENDING?.description}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-1/2 md:w-1/6 my-1 lg:w-1/12 ">
          <ToggleButtonGroup
            value={displayMode}
            exclusive
            onChange={handleChangeDisplayMode}
            aria-label="text alignment"
          >
            <ToggleButton value="table">
              <TableIcon size={16} />
            </ToggleButton>
            <ToggleButton value="card">
              <GridIcon size={16} />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const renderMaintenanceDetail = () => (
    <div>
      <div className="flex flex-wrap py-2">
        <div className="w-full md:w-1/2">
          <div className="my-1 font-semibold">ผู้แจ้ง</div>
          <div>
            {selectedOrder?.informer?.firstname}{' '}
            {selectedOrder?.informer?.lastname}
          </div>
          <div className="my-1 mt-2 font-semibold">ประเภทการบำรุงรักษา</div>
          <div>{selectedOrder?.maintenance_type?.name}</div>
        </div>
        <div className="w-full md:w-1/2">
          <div className="my-1 font-semibold">ปัญหาที่พบ</div>
          <div>{selectedOrder?.cause || '-'}</div>
        </div>
      </div>
      {!_.isEmpty(selectedOrder?.maintenance_image) && (
        <div className="my-2 font-semibold "> รูปภาพประกอบ</div>
      )}
      <div className="flex gap-2 overflow-x-auto my-2">
        {_.map(
          selectedOrder?.maintenance_image,
          (_maintenanceImage, imgIndex) => (
            <img
              key={imgIndex}
              className="w-48 p-2"
              src={_maintenanceImage?.url}
            />
          ),
        )}
      </div>
    </div>
  );

  const renderMemoModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={memoModalOpen}
      onClose={handleCloseMemo}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={memoModalOpen}>
        <Card
          sx={style}
          className="max-w-4xl  max-h-screen   overflow-y-auto my-4"
        >
          <div className=" font-semibold text-xl">บันทึกผลการแจ้งซ่อม</div>
          {renderMaintenanceDetail()}
          <div className="my-2 ">
            <form onSubmit={handleSubmit(onSubmitMemo)}>
              <div>
                <FinishMainatenanceOrderForm
                  control={control}
                  errors={errors}
                />
              </div>

              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseMemo}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <MaintenanceOrderTable {...tableProp} />
    </div>
  );

  const renderCardList = () => (
    <div className="my-2">
      <MaintenanceOrderListCard {...tableProp} />
    </div>
  );

  if (maintenanceOrders.isLoading) {
    return <Loading />;
  }
  if (!maintenanceOrders.isLoading && maintenanceOrders.isCompleted) {
    return (
      <div>
        <div>
          {renderTitle()}
          {/* {renderExportButton()} */}
        </div>
        {renderMemoModal()}
        {renderSearch()}
        {displayMode === 'table' ? renderTable() : renderCardList()}
      </div>
    );
  }
  return <Error />;
}

MaintenanceOrders.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaintenanceOrders.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaintenanceOrders;
