/* eslint-disable no-underscore-dangle */
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Divider,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ImageUpload } from '../../ImageUpload';
import { EMPLOYEE_STATUS } from '../../../utils/constants';
import { Box } from '@mui/system';
import dayjs from 'dayjs';

export function EmployeeForm({
  errors,
  department,
  roletype,
  employee,
  Controller,
  control,
  addUser,
  setAddUser,
  image,
  setImage,
  watch,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      {console.log('dff', addUser)}
      {addUser != null ? (
        <div className="w-full px-1 py-1">
          <FormControlLabel
            value="end"
            defaultValue={addUser}
            control={<Checkbox color="primary" />}
            label="สร้างพนักงานพร้อมบัญชีผู้ใช้"
            labelPlacement="end"
            name="addUser"
            onChange={(e) => {
              setAddUser(!addUser);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {addUser === true ? (
        <>
          <div className="w-full px-1 py-1">
            <Controller
              name={'username'}
              control={control}
              defaultValue={employee ? employee.username : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้ใช้"
                  required
                  fullWidth
                  size={'small'}
                  helperText={errors.username && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="flex w-full">
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'password'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="รหัสผ่าน"
                    type="password"
                    required
                    fullWidth
                    size={'small'}
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'confirmPassword'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    required
                    fullWidth
                    size={'small'}
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="w-2/5 px-1 py-1">
        <Controller
          name={'firstname'}
          control={control}
          defaultValue={employee ? employee.firstname : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              fullWidth
              size={'small'}
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-2/5 px-1 py-1">
        <Controller
          name={'lastname'}
          control={control}
          defaultValue={employee ? employee.lastname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="นามสกุล"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/5 px-1 py-1">
        <Controller
          name={'nickname'}
          control={control}
          defaultValue={employee ? employee.nickname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อเล่น"
              fullWidth
              size={'small'}
              helperText={errors.nickname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'department'}
          control={control}
          defaultValue={employee ? employee?.department?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl required sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                แผนก
              </InputLabel>
              <Select {...field} label="แผนก" size={'small'} fullWidth>
                {_.size(department?.rows) ? (
                  _.map(department.rows, (row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'role'}
          control={control}
          defaultValue={employee ? employee?.role?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl required sx={{ minWidth: 120 }} fullWidth="true">
              <InputLabel id="type" size={'small'}>
                บทบาท
              </InputLabel>
              <Select {...field} label="บทบาท" size={'small'} fullWidth>
                {_.size(roletype?.rows) ? (
                  _.map(roletype.rows, (row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <hr className="my-6 w-full" />
      <div className="w-full px-1 py-1">
        <Controller
          name={'tel'}
          control={control}
          defaultValue={employee ? employee.tel : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเลขโทรศัพท์"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-1">
        <Controller
          name={'attribute.start_date'}
          control={control}
          defaultValue={
            employee
              ? dayjs(employee?.attribute?.start_date).format('YYYY-MM-DD')
              : ''
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="วันเริ่มทำงาน"
              type="date"
              size="small"
              fullWidth
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-1">
        <Controller
          name={'attribute.status'}
          control={control}
          defaultValue={employee ? employee?.attribute?.status : ''}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>สถานะ</InputLabel>
              <Select
                fullWidth
                size="small"
                {...field}
                label="สถานะ"
                placeholder="เลือกสถานะ"
              >
                {_.map(EMPLOYEE_STATUS, (eachStatus, index) => (
                  <MenuItem key={index} value={eachStatus.status_code}>
                    <div className="flex gap-2">
                      <div
                        className={`bg-${eachStatus.color}-500  w-8 self-center rounded-md px-2 h-4 `}
                      ></div>
                      <div className="px-2">{eachStatus.description}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {watch('attribute.status') === 'DEPART' ||
      employee?.attribute?.status === 'DEPART' ? (
        <div className="w-full lg:w-1/2 px-1 py-1">
          <Controller
            name={'attribute.date_resign'}
            control={control}
            defaultValue={
              employee
                ? dayjs(employee?.attribute?.date_resign).format('YYYY-MM-DD')
                : ''
            }
            render={({ field }) => (
              <TextField
                {...field}
                label="วันที่ลาออก"
                type="date"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="w-full lg:w-1/2 px-1 py-1">
        <Controller
          name={'attribute.id_card_number'}
          control={control}
          defaultValue={employee ? employee?.attribute?.id_card_number : ''}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              label="หมายเลขบัตรประจำตัวประชาชน"
            />
          )}
        />
      </div>{' '}
      <div className="w-full lg:w-1/2 px-1 py-1">
        <Controller
          name={'attribute.sso_number'}
          control={control}
          defaultValue={employee ? employee?.attribute?.sso_number : ''}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              label="หมายเลขประกันสังคม"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <ImageUpload images={image} maxNumber={1} setImages={setImage} />
      </div>
    </div>
  );
}

EmployeeForm.propTypes = {
  department: PropTypes.object,
  roletype: PropTypes.object,
  errors: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
    firstname: PropTypes.object,
    lastname: PropTypes.object,
    nickname: PropTypes.object,
  }),
  addUser: PropTypes.object,
  setAddUser: PropTypes.func,
  employee: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.object,
  image: PropTypes.arrayOf(PropTypes.object),
  setImage: PropTypes.func,
};

EmployeeForm.defaultProps = {
  employee: null,
  addUser: null,
};

export default EmployeeForm;
