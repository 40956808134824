/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ViewTitle } from '../../components/ViewTitle';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { BackButton } from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import { useHistory } from 'react-router';
import Loading from 'components/Loading';
import WheelAddFormArr from '../../components/Forms/WMS/WheelAddFormArr';

export default function AddWheelStore({ title, subtitle }) {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      wheelArray: [{ wheelNumber: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'wheelArray',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const wheelType = useSelector((state) => state.wheelType);
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const [selectType, setSelectType] = useState();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  useEffect(() => {
    dispatch(actions.wheelTypeAll({}));
    dispatch(actions.wheelSupplyAll({}));
    return () => {};
  }, []);
  console.log('selextType', selectType);
  const onSubmitData = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      try {
        const dataSubmit = { ...data, arr: data.wheelArray };

        await dispatch(actions.wheelCreate(dataSubmit));
        await dispatch(actions.wheelAll({}));
        history.push('/wms/store');
      } catch (error) {
        console.log('error', error);
        alert('บันทึกข้อมูลผิดพลาด');
      }
    }
  };

  const renderWheelAddForm = () => (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <WheelAddFormArr
        control={control}
        Controller={Controller}
        wheelType={wheelType}
        selectType={selectType}
        wheelSupply={wheelSupply}
        setSelectType={setSelectType}
        fields={fields}
        append={append}
        remove={remove}
      />
    </form>
  );
  if (wheelType.isLoading || !wheelType.isCompleted) {
    return <Loading />;
  }
  if (!wheelType.isLoading || wheelType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderWheelAddForm()}
      </div>
    );
  }
}
