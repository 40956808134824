/* eslint-disable no-underscore-dangle */
import {
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function MaintenanceTopicsForm({
  errors,
  maintenanceTopics,
  maintenanceTypes,
  Controller,
  control,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          defaultValue={maintenanceTopics?.type}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              disableClearable
              name={field.name}
              ref={field.ref}
              defaultValue={field.value}
              options={maintenanceTypes.rows}
              autoHighlight
              getOptionLabel={(option) =>
                `${option.type_code} | ${option.name}`
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {`${option.type_code || ''} | ${option.name}`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="ประเภทการบำรุงรักษา"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  helperText={errors.type && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={maintenanceTopics ? maintenanceTopics?.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={maintenanceTopics ? maintenanceTopics?.detail : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              multiline
              rows={3}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'require_confirmation'}
          control={control}
          defaultValue={
            maintenanceTopics ? maintenanceTopics?.require_confirmation : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              label="ต้องอนุมัติการแจ้งซ่อม"
              control={<Checkbox {...field} checked={field.value} />}
            />
          )}
        />
      </div>
    </div>
  );
}

MaintenanceTopicsForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
  }),
  maintenanceTopics: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  maintenanceTypes: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

MaintenanceTopicsForm.defaultProps = {
  maintenanceTopics: null,
  maintenanceTypes: null,
};

export default MaintenanceTopicsForm;
