/* eslint-disable no-sparse-arrays */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _ from 'lodash';
import headerBill from './Bill/headerBill';
import { invoiceTable } from './Bill';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const Invoice = async (info, row) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  // console.log('info', info);
  console.log('row', row);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 150, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '12',
    },
    header: [
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: info?.owner?.name,
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `${info?.owner?.address?.house_number || ''}  ${
                  info?.owner?.address?.road || ''
                } ${info?.owner?.address?.subdistrict || ''} ${
                  info?.owner?.address?.district || ''
                } ${info?.owner?.address?.province || ''} ${
                  info?.owner?.address?.country || ''
                } ${info?.owner?.address?.postcode || ''}`,
                style: 'headerPageAddress',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `Tel. ${info?.owner?.telephone || '-'} FAX ${
                  info?.owner?.fax || '-'
                } `,
                style: 'headerPageAddress',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `Tax ID ${info?.owner?.tax || '-'} `,
                style: 'headerPageAddress',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      headerBill({ header: 'INVOICE' }, row),
      invoiceTable(info, row, { invoice: true }),

      { text: '', pageBreak: 'before' },
      headerBill({ header: 'FREIGHT CHARGES' }, row),
      invoiceTable(info, row, { invoice: false }),
    ],
    footer: [],

    styles: {
      headerPage: {
        fontSize: 16,
        bold: true,
        alignment: 'left',
      },
      headerPageAddress: {
        fontSize: 14,
        alignment: 'left',
      },
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
      body1: {
        fontSize: 12,
      },
      tableHeader: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      tableBody1: {
        fontSize: 11,
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default Invoice;
