import {
  BILL_ALL,
  BILL_GET,
  BILL_PUT,
  BILL_DELETE,
  BILL_POST,
  BILL_RESET,
  BILL_LOADING,
  BILL_ERROR,
} from '../../actions/types';

const initialState = {
  bill: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function BillReducer(state = initialState, action) {
  switch (action.type) {
    case BILL_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BILL_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BILL_POST:
      return { isLoading: false, isCompleted: true };
    case BILL_PUT:
      return { isLoading: false, isCompleted: true };
    case BILL_DELETE:
      return { isLoading: false, isCompleted: true };
    case BILL_RESET:
      return { BILL: null, isLoading: true, isCompleted: true };
    case BILL_LOADING:
      return { isLoading: true, isCompleted: true };
    case BILL_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
