import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import {
  MaintenanceOrders,
  MaintenanceTypes,
  MaintenanceTopics,
  EditMaintenanceType,
  EditMaintenanceTopics,
  EditMaintenanceOrder,
  CreateMaintenanceOrders,
  MaterialReturn,
  MaterialWithdraw,
  Garages,
  EditGarage,
  MaterialWithdrawRequest,
  IndividualMaterialRequest,
  CreateIndividualMaterialRequest,
  DetailMaterialUsage,
  ContainerMaintenance,
  TailMaintenanceOrder,
  DetailByTailertailMaintenance,
  DetailByContainerMaintenance,
  MaintenanceNotifies,
} from '../views/CMMS';
import DetailMaintenanceOrder from '../views/CMMS/DetailMaintenanceOrder';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function CMMS() {
  const module = 'CMMS';
  const prefix = '/cmms';
  const name = 'จัดการการแจ้งบำรุงรักษา';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const me = useSelector((state) => state.me);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : CMMS');
    dispatch(actions.informationAll());
    dispatch(actions.meGet());

    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }

  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/maintenance-orders`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/maintenance-orders`}>
                <MaintenanceOrders
                  title={'รายการแจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-orders/edit/:id`}>
                <EditMaintenanceOrder
                  title={'แก้ไขรายการแจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-types`}>
                <MaintenanceTypes
                  title={'ประเภทการบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-topics`}>
                <MaintenanceTopics
                  title={'หัวข้อการบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/garages`}>
                <Garages title={'อู่ซ่อมบำรุง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/maintenance-types/edit/:id`}>
                <EditMaintenanceType
                  title={'แก้ไขประเภทการแจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-topics/edit/:id`}>
                <EditMaintenanceTopics
                  title={'แก้ไขหัวข้อการแจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/garages/edit/:id`}>
                <EditGarage title={'อู่ซ่อมบำรุง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/maintenance-order/create`}>
                <CreateMaintenanceOrders
                  title={'แจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/maintenance-order/create/:vehicleId`}
              >
                <CreateMaintenanceOrders
                  title={'แจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-return/:id`}>
                <MaterialReturn title={'คืนวัสดุ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-withdraw/:id`}>
                <MaterialWithdraw title={'เบิกวัสดุ'} subtitle={name} />
              </Route>
              {/* <Route exact path={`${prefix}/maintenance-order/vehicle`}>
                <ByVehicleMaintenanceOrder
                  title={'การแจ้งบำรุงรักษาตามคันรถ'}
                  subtitle={name}
                />
              </Route> */}
              <Route exact path={`${prefix}/maintenance-order/container`}>
                <ContainerMaintenance
                  title={'การแจ้งบำรุงรักษาตามตู้คอนเทนเนอร์'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-order/tail`}>
                <TailMaintenanceOrder
                  title={'การแจ้งบำรุงรักษาตามหางรถ'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-order/tail/detail/:id`}>
                <DetailByTailertailMaintenance
                  title={'การแจ้งบำรุงรักษาหางรถ'}
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/maintenance-order/container/detail/:id`}
              >
                <DetailByContainerMaintenance
                  title={'การแจ้งบำรุงรักษาตู้คอนเทนเนอร์'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-withdraw-request/:id`}>
                <MaterialWithdrawRequest
                  title="การร้องขอการเบิกวัสดุ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/individual-material-request`}>
                <IndividualMaterialRequest
                  title="การขอเบิกวัสดุโดยตรง"
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/individual-material-request/create`}
              >
                <CreateIndividualMaterialRequest
                  title="ขอเบิกวัสดุ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-usage/:id`}>
                <DetailMaterialUsage
                  title="ประวัติการใชัวัสดุ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-notify`}>
                <MaintenanceNotifies
                  title="การแจ้งเตือนการซ่อมบำรุง"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/maintenance-orders/detail/:id`}>
                <DetailMaintenanceOrder
                  title={'รายละเอียดการแจ้งบำรุงรักษา'}
                  subtitle={name}
                />
              </Route>{' '}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default CMMS;
