import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../components/Loading';
import CardDetailWheelMaintenance from '../../components/Card/WMS/DetailWheelMaintenance';
import * as actions from '../../redux/actions';

import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailWheelMaintenanceHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const wheelMaintenanceHistory = useSelector(
    (state) => state.WheelMaintenanceHistory,
  );
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const wheelTransection = useSelector((state) => state.wheelTransection);
  const processmaterials = useSelector((state) => state.processMaterials);
  const wheelMaintenance = wheelMaintenanceHistory;
  console.log('wheelMaintenance', wheelMaintenance);

  useEffect(() => {
    dispatch(actions.wheelMaintenanceHistoryGet(id));
    dispatch(actions.wheelTransectionAll({ wms: id }));
    dispatch(actions.processMaterialAll({ maintenanceOrder: id }));

    return () => {};
  }, []);

  const props = {
    handSuccessJob: async (id, data) => {},
    handleAcessJob: async (id) => {
      try {
        const confirm = window.confirm('เริ่มงาน');
        if (confirm) {
          await dispatch(actions.wheelMaintenancePut(id, { statusJob: true }));
          await dispatch(actions.wheelMaintenanceHistoryCreate());
          await dispatch(actions.wheelMaintenanceGet(id));
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleWheelMaintenance: (id) =>
      history.push(`/wms/wms/wheelMaintenance/${id}`),
  };
  console.log('wheelMaintenanceHistory', wheelMaintenanceHistory);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  const renderDetail = () => (
    <CardDetailWheelMaintenance
      wheelMaintenance={wheelMaintenance}
      maintenanceOrder={maintenanceOrder}
      processmaterials={processmaterials}
      wheelTransection={wheelTransection}
      {...props}
    />
  );
  if (
    wheelMaintenanceHistory.isLoading &&
    !wheelMaintenanceHistory.isCompleted
  ) {
    return <Loading />;
  }
  if (
    !wheelMaintenanceHistory.isLoading &&
    wheelMaintenanceHistory.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderDetail()}
      </div>
    );
  }
}
