import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Employees,
  EditEmployee,
  Departments,
  EditDepartment,
  RoleType,
  EditRoleType,
  Drivers,
  Users,
  EditUser,
  EditDriver,
  DriverParing,
  CreateUserFromEmployee,
} from '../views/HRMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function HRMS() {
  const module = 'HRMS';
  const prefix = '/hrms';
  const name = 'ทรัพยกรบุคคล';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : HRMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());

    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/employee`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/employee`}>
                <Employees title="จัดการพนักงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/employee/edit/:id`}>
                <EditEmployee title="แก้ไขข้อมูลพนักงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/employee/create-user/:id`}>
                <CreateUserFromEmployee
                  title="สร้างผู้ใช้งานจากพนักงาน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/driver`}>
                <Drivers title="จัดการคนขับ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/driver/edit/:id`}>
                <EditDriver title="แก้ไขคนขับรถ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/driver/paring`}>
                <DriverParing title="จับคู่คนขับรถ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/user`}>
                <Users title="จัดการผู้ใช้" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/user/edit/:id`}>
                <EditUser title="แก้ไขข้อมูล" subtitle={name} />
              </Route>

              {accessRight(me, module, 1) && (
                <Switch>
                  <Route exact path={`${prefix}/department`}>
                    <Departments title="จัดการแผนก" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/department/edit/:id`}>
                    <EditDepartment title="แก้ไขข้อมูลแผนก" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/role-types`}>
                    <RoleType title="จัดการบทบาท" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/role-types/edit/:id`}>
                    <EditRoleType title="แก้ไขข้อมูลบทบาท" subtitle={name} />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default HRMS;
