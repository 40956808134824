import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Card,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createFilterOptions,
  Box,
  FormControl,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import MaterialAndAddStockForm from './MaterialAndAddStockForm';
import RetainingMaterialAddStockForm from './RetainingMaterialAddStockForm';

import { MATERIAL_STOCK_STATUS } from '../../../utils/constants';
import dayjs from '../../../utils/functions/thDayjs';

const resetValues = {
  material: '',
  addMaterialLots: [],
};

export function DecreaseMaterialForm({
  material,
  place,
  type,
  selectMaterialLot,
  setSelectMaterialLot,
  filterMaterial,
  setFilterMaterial,
  materialType,
  handleCreateMaterial,
  handleAddStockMaterial = () => {},
  customer,
  materialState,
  allowMaterialLotFillIn = false,
  vehicle,
  trailerTail,
  customerType,
}) {
  const [reload, setReload] = useState(false);
  const [openAddMaterial, setOpenAddMaterial] = useState(false);
  const [openAddStockMaterial, setOpenAddStockMaterial] = useState(false);
  const [addedMaterialName, setAddedMaterialName] = useState('');
  const addMaterialLots = useState([]);
  const filter = createFilterOptions();

  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;

  const {
    control: materialCreateControl,
    handleSubmit: handleMaterialCreateSubmit,
    watch: materialCreateWatch,
    reset: materialCreateReset,
  } = useForm({});

  const {
    control: handleAddStockMaterialControl,
    handleSubmit: handleAddStockMaterialSubmit,
    reset: handleAddStockReset,
  } = useForm({});

  const {
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const checkMaterialLot = () => {
    _.map(material, (_material, index) => {
      const filterLot = _.filter(_material?.stocklots, function filterLot(lot) {
        return (
          // lot.place?._id === filterPlace?.id &&
          lot.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code
        );
      });
      if (!_.isEmpty(filterLot)) {
        const data = {
          ..._material,
          stocklots: filterLot,
        };
        filterMaterial[index] = data;
      } else {
        filterMaterial[index] = null;
      }
    });
    const filterNoLot = _.filter(filterMaterial, function filterLot(lot) {
      return lot != null;
    });
    setFilterMaterial(filterNoLot);
  };
  useEffect(() => {
    checkMaterialLot();
    return () => {};
  }, [reload, type, material]);

  const handleAddMaterialLots = () => {
    const getQuantity = [];
    let quantityMistake = false;
    let quantityLess = false;
    _.map(addMaterialLots[0]?.stocklots, (lot, index) => {
      console.log('lot', lot);
      if (
        !_.isEmpty(getValues(`quantity${index}`)) &&
        getValues(`quantity${index}`) !== '0' &&
        parseFloat(getValues(`quantity${index}`)) >= 0 &&
        getValues(`quantity${index}`) <= (lot?.amount || 0)
      ) {
        const lotData = {
          quantity: getValues(`quantity${index}`),
          amount:
            parseInt(lot?.amount, 10) -
            parseInt(getValues(`quantity${index}`), 10),
          lotId: lot?._id,
          materialId: getValues('material'),
          place: lot?.place?._id,
          stocklot: lot,
          vehicle: getValues(`vehicle[${index}]`),
          tralier_tail: getValues(`trailer_tail[${index}]`),
          material: addMaterialLots[0],
        };
        getQuantity.push(lotData);
      } else if (parseFloat(getValues(`quantity${index}`)) < 0) {
        quantityMistake = true;
      } else if (getValues(`quantity${index}`) > (lot?.amount || 0)) {
        quantityLess = true;
      }
    });
    if (quantityMistake) {
      alert('จำนวนวัสดุของท่านติดลบอยู่');
    } else if (quantityLess) {
      alert('จำนวนวัสดุที่ท่านต้องการมากกว่าจำนวนที่มีคลังวัสดุ');
    } else if (getValues('material') === '') {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      _.map(getQuantity, (_getQuantity) => {
        selectMaterialLot.push(_getQuantity);
        setSelectMaterialLot(selectMaterialLot);
      });
      reset(resetValues);
      setReload(!reload);
    }
  };

  const showAdviceMaterial = () => {
    if (
      localStorage.getItem('ERP.IMS.DISABLE_SHOW_CAN_ADD_MATERIAL_ADVICE') ===
      'true'
    ) {
      return <div></div>;
    }

    return (
      <div className="my-2">
        <Alert severity="info">
          สามารถพิมพ์ชื่อวัสดุอื่น ๆ ที่ยังไม่ได้เพื่มในคลัง หรือวัสดุอื่น ๆ
          ที่ไม่ต้องการเพิ่มในคลัง แต่ใช้เฉพาะการซ่อมครั้งนี้ก็ได้เช่นกัน
          โดยพิมพ์ชื่อ แล้วกดเพิ่มลงระบบ
          <div className="flex justify-end">
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                localStorage.setItem(
                  'ERP.IMS.DISABLE_SHOW_CAN_ADD_MATERIAL_ADVICE',
                  'true',
                );
              }}
            >
              ทราบแล้ว ไม่ต้องแสดงอีก
            </Button>
          </div>
        </Alert>
      </div>
    );
  };

  const handleRemoveMaterialLot = (index) => {
    selectMaterialLot.splice(index, 1);
    setReload(!reload);
  };

  const showMaterialLots = () => {
    if (getValues('material')) {
      const findStockLots = _.find(filterMaterial, {
        id: getValues('material')._id,
      });
      addMaterialLots[0] = findStockLots;
      return (
        <div className="px-1">
          <Card>
            <TableContainer>
              <Table size="small" className="border-lg">
                <colgroup>
                  {imsSetting?.display?.lot_number && <col width="10%" />}
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <TableHead className="bg-blue-100">
                  <TableRow>
                    {imsSetting?.display?.lot_number && (
                      <TableCell>
                        <div className="font-bold">ล็อตที่</div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="font-bold"> หมายเลขอ้างอิงของระบบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> หมายเลขล็อต</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> รายละเอียด</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> คงเหลือ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> จำนวนที่เบิก</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(findStockLots) ? (
                    findStockLots?.stocklots.map((lot, index) => (
                      <TableRow key={lot.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{lot?.running_number || '-'}</TableCell>
                        {imsSetting?.display?.lot_number && (
                          <TableCell>
                            <div className="w-24">{lot?.lot_number || '-'}</div>
                          </TableCell>
                        )}
                        <TableCell>
                          <div className="w-24">
                            <div className="text-xs">
                              {lot?.place?.name || ''}
                            </div>
                            {dayjs(lot?.receipt_date).format('D MMM BB')}{' '}
                            <div className="text-xs">{lot?.supplier?.name}</div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="w-24">
                            {lot?.amount} {findStockLots?.unit}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="w-36">
                            <Controller
                              name={`quantity${index}`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="จำนวนที่เบิก"
                                  fullWidth
                                  type={'Number'}
                                  size={'small'}
                                  helperText={
                                    parseFloat(field.value) < 0 &&
                                    'กรุณาเบิกใส่ตัวเลขที่เป็นบวก'
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="w-full  py-2">
                            {!_.isEmpty(vehicle?.rows) && (
                              <Controller
                                name={`vehicle[${index}]`}
                                control={control}
                                rules={{ required: false }}
                                defaultValue={vehicle?.rows?.[0]}
                                render={({ field }) => (
                                  <Autocomplete
                                    size={'small'}
                                    {...field}
                                    onChange={(e, newValue) => {
                                      field.onChange(newValue);
                                    }}
                                    options={vehicle?.rows}
                                    getOptionLabel={(option) =>
                                      `${option?.type?.name || ''} ${
                                        option?.licence_number
                                      }`
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="เครื่องจักรที่เบิกไปใช้ (ถ้ามี)"
                                      />
                                    )}
                                  />
                                )}
                              />
                            )}
                          </div>
                          <div className="w-full py-2">
                            {!_.isEmpty(trailerTail?.rows) && (
                              <Controller
                                name={`trailer_tail[${index}]`}
                                control={control}
                                rules={{ required: false }}
                                defaultValue={trailerTail?.rows?.[0]}
                                render={({ field }) => (
                                  <Autocomplete
                                    size={'small'}
                                    {...field}
                                    onChange={(e, newValue) => {
                                      field.onChange(newValue);
                                    }}
                                    options={trailerTail?.rows}
                                    getOptionLabel={(option) =>
                                      `${option?.type?.name || ''} ${
                                        option?.licence_number
                                      }`
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="หางรถที่เบิกไปใช้ (ถ้ามี)"
                                      />
                                    )}
                                  />
                                )}
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key={''}>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="flex justify-center my-2">
              {allowMaterialLotFillIn && getValues('material') && (
                <Button
                  variant="outlined"
                  size={'small'}
                  onClick={() => setOpenAddStockMaterial(true)}
                >
                  เติมวัสดุลงในคลัง
                </Button>
              )}
            </div>
          </Card>
        </div>
      );
    }
  };

  const renderAddMaterialDialog = () => (
    <Dialog open={openAddMaterial} onClose={() => setOpenAddMaterial(false)}>
      <form>
        <DialogTitle>เพิ่มวัสดุ</DialogTitle>
        <DialogContent>
          <MaterialAndAddStockForm
            control={materialCreateControl}
            errors={errors}
            materialType={materialType}
            place={place}
            initialMaterialName={addedMaterialName}
            customer={customer}
            customerType={customerType}
            watch={materialCreateWatch}
          />
        </DialogContent>
        <DialogActions>
          <div className="my-2">
            <Button
              variant="contained"
              type="button"
              onClick={handleMaterialCreateSubmit(async (data) => {
                await handleCreateMaterial(data);
                setOpenAddMaterial(false);
                materialCreateReset();
                setAddedMaterialName('');
              })}
            >
              เพิ่ม
            </Button>
          </div>
        </DialogActions>{' '}
      </form>
    </Dialog>
  );

  const renderAddStockToMaterialDialog = () => (
    <Dialog
      open={openAddStockMaterial}
      onClose={() => setOpenAddStockMaterial(false)}
    >
      <form>
        <DialogTitle>เติมวัสดุลงในคลัง</DialogTitle>
        <DialogContent>
          <RetainingMaterialAddStockForm
            control={handleAddStockMaterialControl}
            customer={customer}
            place={place}
            errors={errors}
            selectedMaterial={getValues('material')}
          />
        </DialogContent>
        <DialogActions>
          <div className="my-2">
            <Button
              variant="contained"
              type="button"
              onClick={handleAddStockMaterialSubmit(async (data, event) => {
                const combinedData = {
                  ...data,
                  material: getValues('material')?._id,
                };

                await handleAddStockMaterial(combinedData);
                setOpenAddStockMaterial(false);
                handleAddStockReset();
                // resetAddStockForm({});
              })}
            >
              เพิ่ม
            </Button>
          </div>
        </DialogActions>{' '}
      </form>
    </Dialog>
  );
  const materialOptions = () => (
    <Card>
      <div className="w-full flex flex-row flex-wrap p-4">
        <div className="w-full px-1 py-2">
          {showAdviceMaterial()}
          {!_.isEmpty(material) && (
            <Controller
              name={'material'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    size={'small'}
                    {...field}
                    options={_.map(material, (eachMaterial, index) => ({
                      ...eachMaterial,
                      index: index + 1,
                    }))}
                    defaultValue={field?.value}
                    getOptionLabel={(option) => {
                      // Input Case
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option?.inputValue) {
                        return option?.inputValue;
                      }
                      // Default case
                      return `${option?.type_code || ' '}  ${option?.name}`;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          fontSize: 15,
                          '& > span': { mr: '10px', fontSize: 18 },
                        }}
                        {...props}
                        key={`option-${option?._id}`}
                      >
                        {option?.index} {option?.type_code || ' '}{' '}
                        {option?.name}
                      </Box>
                    )}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setTimeout(() => {
                          setOpenAddMaterial(true);
                          setAddedMaterialName(newValue);
                        });
                      } else if (newValue && newValue?.inputValue) {
                        setOpenAddMaterial(true);
                        setAddedMaterialName(newValue?.inputValue);
                      }
                      // Default Case
                      else {
                        field.onChange(newValue);
                        setReload(!reload);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params?.inputValue !== '') {
                        filtered.push({
                          inputValue: params?.inputValue,
                          name: `เพิ่ม ${params?.inputValue}  ลงในระบบ`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="วัสดุ" />
                    )}
                  />{' '}
                </FormControl>
              )}
            />
          )}
        </div>
        <div className="w-full">{showMaterialLots()}</div>
        <div className="px-1 py-2">
          <Button
            variant="contained"
            size={'small'}
            color={'success'}
            onClick={() => handleAddMaterialLots()}
          >
            เพิ่ม
          </Button>
        </div>
      </div>
    </Card>
  );

  const displayMaterial = () => (
    <Card>
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">หมายเลขอ้างอิงของระบบ</div>
              </TableCell>
              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ชื่อวัสดุ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(selectMaterialLot) ? (
              selectMaterialLot.map((_material, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {_material?.stocklot?.running_number || '-'}
                  </TableCell>
                  {imsSetting?.display?.lot_number && (
                    <TableCell>
                      <div className="w-16">
                        {' '}
                        {_material?.stocklot?.lot_number || '-'}
                      </div>
                    </TableCell>
                  )}
                  <TableCell>
                    <div className="w-24">
                      <span className="font-bold mr-2">
                        {_material?.material?.type_code || ''}
                      </span>
                      <span>{_material?.material?.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="w-24">
                      {' '}
                      {_material?.quantity} {_material?.material?.unit}
                    </div>
                    <div className="w-24">
                      {' '}
                      ไปที่ {_material?.vehicle?.licence_number || ''}{' '}
                      {_material?.trailer_tail?.licence_number || ''}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => handleRemoveMaterialLot(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const showSelectMaterialLot = () => displayMaterial();

  return (
    <div>
      {renderAddMaterialDialog()}
      {renderAddStockToMaterialDialog()}
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12">
          {!materialState?.isLoading &&
            materialState?.isCompleted &&
            materialOptions()}
          {materialState?.isLoading && (
            <div>
              <LinearProgress />
            </div>
          )}
        </div>
        <div className="w-full lg:w-6/12 lg:pl-2">
          {showSelectMaterialLot()}
        </div>
      </div>
    </div>
  );
}

DecreaseMaterialForm.propTypes = {
  material: PropTypes.arrayOf(PropTypes.object),
  place: PropTypes.object,
  type: PropTypes.object,
  selectMaterialLot: PropTypes.object,
  setSelectMaterialLot: PropTypes.func,
  filterMaterial: PropTypes.object,
  setFilterMaterial: PropTypes.func,
  filterPlace: PropTypes.object,
  setFilterPlace: PropTypes.func,
  materialType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  materialState: {
    isLoading: PropTypes.bool,
    isCompleted: PropTypes.bool,
  },
  handleCreateMaterial: PropTypes.func,
  customer: PropTypes.arrayOf(PropTypes.object),
  handleAddStockMaterial: PropTypes.func,
  allowMaterialLotFillIn: PropTypes.bool,
  vehicle: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  trailerTail: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  customerType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

DecreaseMaterialForm.defaultProps = {
  material: null,
  place: null,
  type: null,
  selectMaterialLot: null,
  filterMaterial: null,
  filterPlace: null,
};

export default DecreaseMaterialForm;
