import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  FormControlLabel,
  Autocomplete,
  Box,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

const IndividualMaterialRequestForm = ({
  control,
  vehicle,
  trailerTail,
  watch,
  errors,
}) => (
  <div className="flex flex-wrap">
    <div className="w-full justify-center flex border rounded-md ">
      <div className="">
        <Controller
          control={control}
          name="use_trailer_tail"
          defaultValue={false}
          render={({ field }) => (
            <div className="flex gap-2 p-2">
              <div className="self-center font-sansserif">หัวรถ</div>
              <FormControlLabel label="หางรถ" control={<Switch {...field} />} />
            </div>
          )}
        />
      </div>
    </div>
    <div className="w-full lg:w-1/2 py-2 px-2">
      <div className="font-semibold my-2">หัวรถ</div>
      {!_.isEmpty(vehicle?.rows) && (
        <Controller
          name={'vehicle'}
          control={control}
          defaultValue={vehicle?.rows?.[0]}
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              ref={field.ref}
              defaultValue={field.value}
              options={vehicle?.rows}
              autoHighlight
              disabled={watch('use_trailer_tail')}
              getOptionLabel={(option) =>
                `ป้ายทะเบียน ${option?.licence_number || ''}`
              }
              size="small"
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {`ป้ายทะเบียน ${option?.licence_number || ''}`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="รถ (หัวรถ)"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  helperText={errors.vehicle && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        />
      )}
    </div>
    <div className="w-full lg:w-1/2 py-2 px-2">
      <div className="font-semibold my-2">หางรถ</div>
      {!_.isEmpty(trailerTail?.rows) && (
        <Controller
          name={'trailer_tail'}
          control={control}
          defaultValue={trailerTail?.rows?.[0]}
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              ref={field.ref}
              defaultValue={field.value}
              size="small"
              options={trailerTail?.rows}
              autoHighlight
              disabled={!watch('use_trailer_tail')}
              getOptionLabel={(option) =>
                `ป้ายทะเบียน ${option?.licence_number || ''}`
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {`ป้ายทะเบียน ${option?.licence_number || ''}`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="หางรถ"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  helperText={errors.trailerTail && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        />
      )}
    </div>
  </div>
);

IndividualMaterialRequestForm.propTypes = {
  control: PropTypes.object,
  vehicle: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  trailerTail: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  errors: PropTypes.object,
};

export default IndividualMaterialRequestForm;
