import {
  TRAILER_TAIL_ALL,
  TRAILER_TAIL_GET,
  TRAILER_TAIL_POST,
  TRAILER_TAIL_PUT,
  TRAILER_TAIL_DEL,
  TRAILER_TAIL_LOADING,
  TRAILER_TAIL_ERROR,
} from '../../actions/types';

const initialState = {
  trailerTail: null,
  isLoading: true,
  isCompleted: true,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case TRAILER_TAIL_LOADING:
      return { isLoading: true, isCompleted: true };
    case TRAILER_TAIL_ERROR:
      return { isLoading: false, isCompleted: false };
    case TRAILER_TAIL_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case TRAILER_TAIL_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case TRAILER_TAIL_POST:
      return { isLoading: false, isCompleted: true };
    case TRAILER_TAIL_PUT:
      return { isLoading: false, isCompleted: true };
    case TRAILER_TAIL_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
