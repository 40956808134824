import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  TextField,
  Autocomplete,
  Box,
  Card,
  CardContent,
  Button,
  FormHelperText,
  createFilterOptions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  AlertTitle,
} from '@mui/material';
import _ from 'lodash';

import { dayjs } from '../../../utils/functions';
import { DatePicker } from '../../Datepicker';

const DeliveryOrderForm = ({
  control,
  errors,
  deliveryOrder,
  container,
  job,
  selectJob,
  setSelectJob,
  handleCreateJob,
  handleCreateContainer,
  setValue,
  watch,
}) => {
  const [addContainerOpen, setAddContainerOpen] = useState(false);
  const [dialogContainerName, setDialogContianerNumber] = useState('');
  const [dialogContainerSize, setDialogContainerSize] = useState('');
  const [dialogContainerWeight, setDialogContainerWeight] = useState(26);
  const filter = createFilterOptions();

  const renderDialog = () => (
    <Dialog
      open={addContainerOpen}
      onClose={() => {
        setAddContainerOpen(false);
        setDialogContianerNumber('');
      }}
    >
      <DialogTitle>เพิ่มตู้คอนเทนเนอร์</DialogTitle>
      <DialogContent>
        <div className="py-2 mx-3 w-48">
          <TextField
            onChange={(event) => {
              setDialogContianerNumber(event.target.value);
            }}
            size="small"
            label="หมายเลขตู้คอนเทนเนอร์"
            fullWidth
            value={dialogContainerName}
          />
        </div>
        <div className="py-2 mx-3 w-48">
          <TextField
            onChange={(event) => {
              setDialogContainerSize(event.target.value);
            }}
            size="small"
            label="ขนาดตู้"
            fullWidth
            value={dialogContainerSize}
          />
        </div>
        <div className="py-2 mx-3 w-48">
          <TextField
            onChange={(event) => {
              setDialogContainerWeight(event.target.value);
            }}
            size="small"
            label="น้ำหนักที่รองรับได้ (ตัน)"
            fullWidth
            value={dialogContainerWeight}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCreateContainer({
              licence_number: dialogContainerName,
              size: dialogContainerSize,
              weight: dialogContainerWeight,
            });
            setAddContainerOpen(false);
            setDialogContianerNumber('');
            setDialogContainerSize('');
            setDialogContainerWeight(26);
          }}
          variant="contained"
        >
          สร้าง
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {renderDialog()}
      <Card>
        <CardContent>
          <div className="w-full flex flex-wrap">
            <div className="w-1/2 py-2 px-2">
              <Controller
                name={'job'}
                control={control}
                defaultValue={
                  deliveryOrder ? deliveryOrder?.job_template : job?.rows?.[0]
                }
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    disableClearable
                    name={field.name}
                    ref={field.ref}
                    defaultValue={field.value}
                    options={job?.rows}
                    autoHighlight
                    fullWidth
                    size="small"
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          fontSize: 15,
                          '& > span': { mr: '10px', fontSize: 18 },
                        }}
                        {...props}
                      >
                        {option.name || ''}
                      </Box>
                    )}
                    onChange={(e, value) => {
                      field.onChange(value._id);
                      setSelectJob(value);
                      setValue('price', value?.fixed_cost);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        label="งาน"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        helperText={errors?.job && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                )}
              />
            </div>{' '}
            <div className="py-2 px-2">
              <Button variant="contained" onClick={handleCreateJob}>
                เพิ่มงานใหม่
              </Button>
            </div>
          </div>
          <div className="bg-gray-100 p-2 m-2 rounded-md">
            <div className="flex flex-wrap w-full gap-2 mx-2">
              <label className="py-1 ">งานที่เลือก</label>
              <div className="px-2 py-1 rounded-md bg-orange-400">
                <label className="text-white">
                  {' '}
                  {selectJob?.assignment_type}{' '}
                </label>
              </div>
            </div>
            <div className="flex w-full mx-2">
              <div className="w-full lg:w-1/3">
                {' '}
                <b>ลูกค้า</b>
                <p>
                  {selectJob?.customer?.name ||
                    deliveryOrder?.customer?.name ||
                    job?.rows?.[0]?.customer?.name}
                </p>
                <label>
                  {selectJob?.customer?.address?.village_number ||
                    deliveryOrder?.customer?.address?.village_number ||
                    job?.rows?.[0]?.customer?.address?.village_number}{' '}
                </label>
                <label>
                  {selectJob?.customer?.address?.house_number ||
                    deliveryOrder?.customer?.address?.house_number ||
                    job?.rows?.[0]?.customer?.address?.house_number}{' '}
                </label>
                <label>
                  ถ.
                  {selectJob?.customer?.address?.road ||
                    deliveryOrder?.customer?.address?.road ||
                    job?.rows?.[0]?.customer?.address?.road}{' '}
                </label>
                <label>
                  ต.
                  {selectJob?.customer?.address?.subdistrict ||
                    deliveryOrder?.customer?.address?.subdistrict ||
                    job?.rows?.[0]?.customer?.address?.subdistrict}{' '}
                </label>
                <label>
                  อ.
                  {selectJob?.customer?.address?.district ||
                    deliveryOrder?.customer?.address?.district ||
                    job?.rows?.[0]?.customer?.address?.district}{' '}
                </label>
                <label>
                  จ.
                  {selectJob?.customer?.address?.province ||
                    deliveryOrder?.customer?.address?.province ||
                    job?.rows?.[0]?.customer?.address?.province}{' '}
                </label>
                <label>
                  ประเทศ
                  {selectJob?.customer?.address?.country ||
                    deliveryOrder?.customer?.address?.country ||
                    job?.rows?.[0]?.customer?.address?.country}{' '}
                </label>
                <label>
                  {selectJob?.customer?.address?.postcode ||
                    deliveryOrder?.customer?.address?.postcode ||
                    job?.rows?.[0]?.customer?.address?.postcode}
                </label>
              </div>
              <div className="w-full lg:w-1/3">
                <b>สถานที่รับ</b>
                <p>
                  {selectJob?.start ||
                    deliveryOrder?.origin ||
                    job?.rows?.[0]?.start}
                </p>
              </div>
              <div className="w-full lg:w-1/3">
                <b>สถานที่ส่ง</b>
                <p>
                  {selectJob?.destination ||
                    deliveryOrder?.job_template?.destination ||
                    job?.rows?.[0]?.destination}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full ">
            <div className="w-full my-2"></div>{' '}
            {deliveryOrder && (
              <div className="w-full lg:w-1/2 p-2">
                <Controller
                  name="origin"
                  control={control}
                  defaultValue={deliveryOrder ? deliveryOrder?.origin : null}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={'number'}
                      fullWidth
                      rows={2}
                      multiline
                      size="small"
                      label="สถานที่รับ"
                    />
                  )}
                />
              </div>
            )}
            {deliveryOrder && (
              <div className="w-full lg:w-1/2 p-2">
                <Controller
                  name="destination"
                  control={control}
                  defaultValue={
                    deliveryOrder ? deliveryOrder?.destination : null
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={'number'}
                      fullWidth
                      rows={2}
                      multiline
                      size="small"
                      label="สถานที่ส่ง"
                    />
                  )}
                />
              </div>
            )}
            <div className="w-full lg:w-2/4 p-2">
              <Controller
                name="invoice_number"
                control={control}
                defaultValue={
                  deliveryOrder ? deliveryOrder?.invoice_number : null
                }
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="เลขที่ใบแจ้งหนี้"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </div>
            {selectJob?.assignment_type === 'IMPORT' && (
              <div className="w-1/2  flex flex-wrap">
                <div className="w-full p-2 lg:w-1/2">
                  <Controller
                    name={'start_date'}
                    control={control}
                    defaultValue={
                      deliveryOrder
                        ? dayjs(deliveryOrder?.start_date).format('YYYY-MM-DD')
                        : dayjs().format('YYYY-MM-DD')
                    }
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        setValue={field.onChange}
                        value={field.value}
                        label="วันเคลียร์สินค้า"
                      />
                    )}
                  />
                  {errors?.start_date && (
                    <FormHelperText>กรุณาใส่วันเคลียร์สินค้า</FormHelperText>
                  )}
                </div>
                <div className="w-full p-2 lg:w-1/2">
                  <Controller
                    name={'end_date'}
                    control={control}
                    defaultValue={
                      deliveryOrder
                        ? dayjs(deliveryOrder?.end_date).format('YYYY-MM-DD')
                        : dayjs().format('YYYY-MM-DD')
                    }
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        setValue={field.onChange}
                        value={field.value}
                        label="วันส่งสินค้า"
                      />
                    )}
                  />
                  {errors?.end_date && (
                    <FormHelperText>กรุณาใส่วันส่งสินค้า</FormHelperText>
                  )}
                </div>
              </div>
            )}
            {selectJob?.assignment_type === 'EXPORT' && (
              <div className="w-1/2  flex flex-wrap">
                <div className="w-full p-2 lg:w-1/2">
                  <Controller
                    name={'start_date'}
                    control={control}
                    defaultValue={
                      deliveryOrder
                        ? dayjs(deliveryOrder?.start_date).format('YYYY-MM-DD')
                        : dayjs().format('YYYY-MM-DD')
                    }
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        setValue={field.onChange}
                        value={field.value}
                        label="วันรับสินค้า"
                      />
                    )}
                  />
                  {errors?.start_date && (
                    <FormHelperText>กรุณาใส่วันรับสินค้า</FormHelperText>
                  )}
                </div>
                <div className="w-full p-2 lg:w-1/2">
                  <Controller
                    name={'end_date'}
                    control={control}
                    defaultValue={
                      deliveryOrder
                        ? dayjs(deliveryOrder?.end_date).format('YYYY-MM-DD')
                        : dayjs().format('YYYY-MM-DD')
                    }
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        setValue={field.onChange}
                        value={field.value}
                        label="วันเคลียร์สินค้า"
                      />
                    )}
                  />
                  {errors?.end_date && (
                    <FormHelperText>กรุณาใส่วันเคลียร์สินค้า</FormHelperText>
                  )}
                </div>
              </div>
            )}
            <div className="w-full lg:w-1/2 p-2 flex flex-wrap">
              {!_.isEmpty(container?.rows) && (
                <Controller
                  name={'container'}
                  control={control}
                  rules={{ required: false }}
                  defaultValue={deliveryOrder ? deliveryOrder?.container : null}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={container?.rows}
                      autoHighlight
                      fullWidth
                      size="small"
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option?.inputValue) {
                          return option?.inputValue;
                        }
                        return `${option.licence_number} ${
                          option.description || ''
                        } ขนาด ${option?.size || '-'} น้ำหนัก ${
                          option.weight || '-'
                        } ตัน`;
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: 15,
                            '& > span': { mr: '10px', fontSize: 18 },
                          }}
                          {...props}
                        >
                          {option.licence_number || ''}{' '}
                          {option.description || ''} ขนาด {option.size || '-'}{' '}
                          รองรับได้ {option.weight || '-'} ตัน
                        </Box>
                      )}
                      onChange={(e, value) => {
                        if (typeof value === 'string') {
                          setTimeout(() => {
                            setAddContainerOpen(true);
                            setDialogContianerNumber(value);
                          });
                        } else if (value && value?.inputValue) {
                          setAddContainerOpen(true);
                          setDialogContianerNumber(value?.inputValue);
                        } else {
                          field.onChange(value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="ตู้คอนเทนเนอร์"
                          inputProps={{
                            ...params.inputProps,
                          }}
                          helperText={errors?.container && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params?.inputValue !== '') {
                          filtered.push({
                            inputValue: params?.inputValue,
                            licence_number: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                          });
                        }

                        return filtered;
                      }}
                    />
                  )}
                />
              )}
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="price"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.price : null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    defaultValue={
                      selectJob?.fixed_cost || job?.rows?.[0]?.fixed_cost
                    }
                    fullWidth
                    type={'number'}
                    size="small"
                    label="ราคา"
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="amount"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.amount : null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="จำนวนหีบห่อ/พาเลท"
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="unit"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.unit : null}
                render={({ field }) => (
                  <TextField {...field} fullWidth size="small" label="หน่วย" />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="size"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.size : null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="booking size"
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="weight"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.weight : null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="น้ำหนักรวม (ตัน)"
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="refuel"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.refuel : null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="น้ำมันที่ใช้ (ลิตร)"
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="distance"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.distance : null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="ระยะทาง (กิโลเมตร)"
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <Controller
                name="remark"
                control={control}
                defaultValue={deliveryOrder ? deliveryOrder?.remark : null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="หมายเหตุ"
                    minRows={5}
                    multiline={true}
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 p-2">
              {parseFloat(watch('weight')) >
                parseFloat(watch('container.weight')) && (
                <div>
                  <Alert severity="warning">
                    <AlertTitle>
                      น้ำหนักสูงกว่าน้ำหนักที่รองรับได้ของตู้คอนเทนเนอร์
                    </AlertTitle>
                    <div className="font-sansserif">
                      น้ำหนักที่ตู้คอนเทนเนอร์นี้รับได้คือ{' '}
                      {parseFloat(watch('container.weight'))} ตัน
                    </div>
                  </Alert>
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

DeliveryOrderForm.propTypes = {
  control: PropTypes.func,
  job: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  container: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  deliveryOrder: PropTypes.object,
  errors: PropTypes.object,
  selectJob: PropTypes.object,
  setSelectJob: PropTypes.func,
  handleCreateJob: PropTypes.func,
  handleCreateContainer: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
};

export default DeliveryOrderForm;
