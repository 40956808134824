import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { DetailVehicleScheduleForm } from '../../components/Forms';

const DetailVehicleSchedule = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const vehicleSchedule = useSelector((state) => state.vehicleSchedule);

  const getVehicleSchedule = async () => {
    try {
      await dispatch(actions.vehicleScheduleGet(params?.id));
    } catch (error) {
      console.error('Error on Fetch Vehicle Schedule', error);
    }
  };

  useEffect(() => {
    getVehicleSchedule();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderButton = () => (
    <div className="flex justify-start pb-4">
      <BackButton />
    </div>
  );

  if (vehicleSchedule.isLoading || vehicleSchedule.rows) {
    return <Loading />;
  }

  if (
    !vehicleSchedule.rows &&
    !vehicleSchedule.isLoading &&
    vehicleSchedule.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderButton()}
        <div className="mt-2">
          <DetailVehicleScheduleForm vehicleSchedule={vehicleSchedule} />
        </div>
      </div>
    );
  }
  return <Error />;
};

DetailVehicleSchedule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailVehicleSchedule.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailVehicleSchedule;
