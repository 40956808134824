import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Button, Card, Chip, Paper } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const WheelMaintananceOrderTable = ({
  wheelNonSuccess,
  size,
  setSize,
  page,
  setPage,
  wheelMaintenance,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    console.log('row', event.target.value);
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  console.log('wheelNonSuccess', wheelNonSuccess);
  return (
    <div>
      <Card>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {' '}
                  <h1 className="font-bold">เลขที่ </h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">ป้ายทะเบียน</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">ประเภทการซ่อม</h1>
                </TableCell>

                <TableCell align="center">
                  <h1 className="font-bold">วันที่</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">สถานะงาน</h1>
                </TableCell>
                <TableCell align="center">ดำเนินการ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wheelNonSuccess?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              ) : (
                wheelNonSuccess?.map((each, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {each?.vehicle?.licence_number && (
                        <div>หัวรถ {each?.vehicle?.licence_number}</div>
                      )}
                      {each?.trailer_tail?.licence_number && (
                        <div>หางรถ {each?.trailer_tail?.licence_number}</div>
                      )}

                      {/* <Chip label="หัว" /> */}
                    </TableCell>
                    <TableCell align="center">
                      {each?.type_maintenance}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(each?.createdAt).format('DD/MM/BBBB')}
                    </TableCell>
                    <TableCell align="center">
                      {each.statusJob ? (
                        <Chip label="กำลังดำเนินการ" color="success" />
                      ) : (
                        <Chip label="รอกำลังดำเนินการ" color="warning" />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        component={Link}
                        to={`/wms/detail/${each._id}`}
                      >
                        ตรวจสอบ
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={wheelMaintenance?.total}
          rowsPerPage={size || 5}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
};
WheelMaintananceOrderTable.propTypes = {
  wheelNonSuccess: PropTypes.object,
  page: PropTypes.object,
  name: PropTypes.object,
  size: PropTypes.object,
  setPage: PropTypes.object,
  setName: PropTypes.object,
  length: PropTypes.number,
  setSize: PropTypes.object,
  subtitle: PropTypes.string,
};

export default WheelMaintananceOrderTable;
