export const ROLE_LEVEL = {
  LOW: {
    label: 'น้อย',
    value: 0,
  },
  MEDIUM: {
    label: 'กลาง',
    value: 1,
  },
  HIGH: {
    label: 'สูง',
    value: 2,
  },
};
export default ROLE_LEVEL;
