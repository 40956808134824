/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { BillHeaderForm } from '../../components/Forms/BMS/BillHeaderForm';
import Loading from 'components/Loading';
import { BackButton } from 'components/Button';
export default function BillHeaderEdit({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const billHeader = useSelector((state) => state.billHeader);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    dispatch(actions.billHeaderGet(params?.id));
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      const payload = {
        ...data,
      };
      event.preventDefault();
      await dispatch(actions.billHeaderPut(params.id, payload));
      await dispatch(actions.billHeaderAll());
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <Card className="p-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <BillHeaderForm
          Controller={Controller}
          control={control}
          errors={errors}
          billHeader={billHeader}
        />
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </Card>
  );
  if (billHeader.isLoading) {
    return <Loading />;
  }
  if (!billHeader.isLoading || billHeader.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        {renderForm()}
      </div>
    );
  } else {
    return <Loading />;
  }
}
