import Customers from './Customers';
import EditCustomer from './EditCustomer';
import DetailCustomer from './DetailCustomer';
import CustomerTypes from './CustomerTypes';
import EditCustomerType from './EditCustomerType';

export {
  Customers,
  CustomerTypes,
  DetailCustomer,
  EditCustomer,
  EditCustomerType,
};
export default {
  Customers,
  CustomerTypes,
  DetailCustomer,
  EditCustomer,
  EditCustomerType,
};
