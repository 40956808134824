export const ASSIGNMENT_TYPE = {
  EXPORT: {
    status_code: 'EXPORT',
    description: 'ส่งออก',
    color: 'yellow',
  },
  IMPORT: {
    status_code: 'IMPORT',
    description: 'นำเข้า',
    color: 'purple',
  },
};

export default ASSIGNMENT_TYPE;
