/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';

import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import groupByMaterialType from '../../utils/functions/groupByMaterialType';
import currencyFormatter from '../../utils/functions/currencyFormat';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

// sumItemProp
const sumItemProp = (items, key) =>
  _.reduce(
    items,
    (result, currentValue) => {
      result += parseInt(currentValue[key], 10);
      return result;
    },
    0,
  );

// แสดงแต่ละรายการปลา
const genBody = (items) => {
  const itemInArray = _.map(items, (_item) => _item);
  const maxRow = _.max(itemInArray);
  const newItems = _.map(maxRow, (_e, count) =>
    _.map(items, (item) => {
      if (item[count]?.type.name) {
        return {
          text: `${item[count].buying_weight}x${item[count]?.amount} ${
            item[count].fraction ? '(เศษ)' : ''
          }`,
          alignment: 'center',
        };
      }
      return '';
    }),
  );
  // not use
  const addCol = _.map(newItems, (row) => [...row]);
  const sumRow = _.map(itemInArray, (type) => ({
    text: `รวม : ${currencyFormatter.format(
      sumItemProp(type, 'buying_total_weight'),
    )} กก.`,
    alignment: 'center',
    bold: true,
  }));

  const sumRowAddCol = [...sumRow];

  // แถวข้อมูลรายการปลามาเชื่อมกับแถวรวมน้ำหนักซึ่งเป็นแถวเดียว
  return [...addCol, sumRowAddCol];
};

const genWith = (items) => _.map(items, () => '*');

const sumTotalItem = (items, mode) => {
  if (mode === 'full') {
    return _.reduce(
      items,
      (result, currentValue) => {
        if (!currentValue.fraction) result += currentValue.amount;
        return result;
      },
      0,
    );
  }
  if (mode === 'fraction') {
    return _.reduce(
      items,
      (result, currentValue) => {
        if (currentValue.fraction) result += currentValue.amount;
        return result;
      },
      0,
    );
  }
  if (mode === 'all') {
    return _.reduce(
      items,
      (result, currentValue) => {
        result += currentValue.amount;
        return result;
      },
      0,
    );
  }
  return 0;
};

const printPDF = (order, info) => {
  const { pa_running_number, ship_licence_number, createdAt } = order;

  const items = groupByMaterialType(order.duplicate_items, 'type');
  const genItemBody = genBody(items);

  const docDefinition = {
    header: {
      columns: [
        {
          text: `${dayjs().format('DD/MM/BBBB')}`,
          alignment: 'right',
          margin: [10, 10, 10, 10],
          fontSize: '10',
        },
      ],
    },
    footer(currentPage, pageCount) {
      return [
        {
          text: `${currentPage.toString()} of ${pageCount}`,
          alignment: 'right',
          margin: [10, 10, 10, 10],
          fontSize: '10',
        },
      ];
    },

    pageSize: 'A5',
    pageOrientation: 'landscape',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: `เลขที่เอกสาร : ${pa_running_number}`,
    },
    content: [
      {
        text: `เลขที่ : ${pa_running_number}`,
        alignment: 'right',
        fontSize: '10',
        bold: true,
      },
      {
        text: [
          {
            text: `${info?.name}\n`,
            fontSize: '10',
          },
          {
            text: `โทร. ${info?.owner.telephone}\t`,
          },
          {
            text: `แฟกซ์ ${info?.owner.fax}\n`,
          },
        ],
        alignment: 'center',
        fontSize: '10',
        bold: true,
      },
      '\n',
      {
        columns: [
          {
            text: [
              {
                text: `ชื่อเรือ : ${ship_licence_number}`,
                alignment: 'center',
                bold: true,
              },
            ],
          },
          {
            text: [
              {
                text: `วัน เดือน ปี : ${dayjs(createdAt).format(
                  'DD-MM-BBBB',
                )}\n`,
                alignment: 'center',
                bold: true,
              },
            ],
          },
        ],
      },
      '\n',
      {
        table: {
          widths: [...genWith(_.keys(items))],
          headerRows: 1,
          body: [
            [
              ..._.map(items, (itemm, index) => ({
                text: index,
                alignment: 'center',
                fontSize: '10',
              })),
            ],
            ...genItemBody,
          ],
        },
      },
      '\n',
      {
        columns: [
          {
            table: {
              widths: [100, 'auto'],
              body: [
                [
                  { text: '', rowSpan: 2, border: [true, true, true, false] },
                  `เต็ม\t${currencyFormatter.format(
                    sumTotalItem(order.duplicate_items, 'full'),
                  )}\tกระบะ`,
                ],
                [
                  '',
                  `เศษ\t${currencyFormatter.format(
                    sumTotalItem(order.duplicate_items, 'fraction'),
                  )}\tกระบะ`,
                ],
                [
                  {
                    text: 'รวม',
                    alignment: 'center',
                    border: [true, false, true, false],
                  },
                  `รวม\t${currencyFormatter.format(
                    order?.duplicate_total_amount,
                  )}\tกระบะ`,
                ],
                [
                  { text: '', rowSpan: 2, border: [true, false, true, true] },
                  {
                    text: [
                      {
                        text: 'น้ำหนักรวม\t',
                      },
                      {
                        text: `${currencyFormatter.format(
                          order?.duplicate_buying_total_weight,
                        )}\tกิโลกรัม`,
                        alignment: 'right',
                      },
                    ],
                  },
                ],
                [
                  '',
                  {
                    text: [
                      {
                        text: `จำนวนเงิน\t ${currencyFormatter.format(
                          order?.duplicate_buying_total_price,
                        )}\tบาท`,
                      },
                    ],
                  },
                ],
              ],
            },
            alignment: 'center',
          },
          {
            table: {
              heights: 12,
              widths: ['auto', 'auto'],
              body: [
                [
                  {
                    text: '',
                    rowSpan: 2,
                    colSpan: 2,
                    border: [true, true, true, false],
                  },
                  '',
                ],
                ['', ''],
                [
                  {
                    text: 'ชื่อผู้ซื้อ......................................',
                    border: [true, false, false, false],
                  },
                  {
                    text: 'ชื่อผู้ขาย.....................................',
                    border: [false, false, true, false],
                  },
                ],
                [
                  {
                    text: 'ตรวจสอบความถูกต้องครบถ้วนก่อนลงนามและเก็บเอกสารนี้เป็นหลักฐานการเบิกจ่ายเงิน',
                    fontSize: '7',
                    border: [true, false, true, true],
                    colSpan: 2,
                    rowSpan: 2,
                    alignment: 'center',
                  },
                  '',
                ],
                ['', ''],
              ],
            },
          },
        ],
      },
    ],
    images: {},
  };
  pdfMake.createPdf(docDefinition).open();
};

export default printPDF;
