import {
  MAINTENANCE_NOTIFY_ALL,
  MAINTENANCE_NOTIFY_GET,
  MAINTENANCE_NOTIFY_POST,
  MAINTENANCE_NOTIFY_PUT,
  MAINTENANCE_NOTIFY_DELETE,
  MAINTENANCE_NOTIFY_LOADING,
  MAINTENANCE_NOTIFY_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const maintenanceNotifyCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_NOTIFY_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/maintenance-notify`,
      payload,
    );
    dispatch({ type: MAINTENANCE_NOTIFY_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_NOTIFY_ERROR });
  }
};

export const maintenanceNotifyAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;

    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/maintenance-notify?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MAINTENANCE_NOTIFY_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_NOTIFY_ERROR });
  }
};

export const maintenanceNotifyGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/maintenance-notify/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MAINTENANCE_NOTIFY_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_NOTIFY_ERROR });
  }
};

export const maintenanceNotifyPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_NOTIFY_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/maintenance-notify/${id}`,
      payload,
    );
    dispatch({ type: MAINTENANCE_NOTIFY_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_NOTIFY_ERROR });
  }
};
export const maintenanceNotifyDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MAINTENANCE_NOTIFY_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/maintenance-notify/${id}`,
    );
    dispatch({ type: MAINTENANCE_NOTIFY_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MAINTENANCE_NOTIFY_ERROR });
  }
};
