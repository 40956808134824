import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Card,
  CardContent,
  FormHelperText,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { DatePicker } from '../../Datepicker';
import { dayjs } from '../../../utils/functions';

const VehicleScheduleForm = ({
  control,
  errors,
  watch,
  setValue,
  vehicles,
  trailerTails,
  containers,
  drivers,
  vehicleAssignment,
  selectAssignmentType,
}) => {
  const [driverRendereing, setDriverRendering] = useState(true);
  console.log('vehicleAssignment', vehicleAssignment);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDriverRendering(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [driverRendereing]);

  return (
    <div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            {selectAssignmentType === 'IMPORT' && (
              <div className="w-full my-1 px-1 flex flex-wrap">
                <div className="w-full lg:w-1/2">
                  <div className="w-full text-sm py-2">วันเคลียร์สินค้า</div>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      vehicleAssignment
                        ? dayjs(vehicleAssignment?.start_date)?.toDate()
                        : dayjs().toDate()
                    }
                    render={({ field }) => (
                      <DatePicker
                        label="วันเคลียร์สินค้า"
                        value={field.value}
                        setValue={(value) => field.onChange(value)}
                      />
                    )}
                  />
                  {errors?.start_date && (
                    <FormHelperText>กรุณาใส่ข้อมูลวันเริ่มต้น</FormHelperText>
                  )}
                </div>
                <div className="w-full lg:w-1/2 px-1">
                  {' '}
                  <div className="w-full text-sm py-2">วันส่งสินค้า</div>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      vehicleAssignment
                        ? dayjs(vehicleAssignment?.end_date)?.toDate()
                        : dayjs().toDate()
                    }
                    render={({ field }) => (
                      <DatePicker
                        label="วันส่งสินค้า"
                        value={field.value}
                        setValue={(value) => field.onChange(value)}
                      />
                    )}
                  />
                  {errors?.end_date && (
                    <FormHelperText>กรุณาใส่ข้อมูลวันสิ้นสุด</FormHelperText>
                  )}
                </div>
              </div>
            )}
            {selectAssignmentType === 'EXPORT' && (
              <div className="w-full my-1 px-1 flex flex-wrap">
                <div className="w-full lg:w-1/2">
                  <div className="w-full text-sm py-2">วันรับสินค้า</div>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      vehicleAssignment
                        ? dayjs(vehicleAssignment?.start_date)?.toDate()
                        : dayjs().toDate()
                    }
                    render={({ field }) => (
                      <DatePicker
                        label="วันรับสินค้า"
                        value={field.value}
                        setValue={(value) => field.onChange(value)}
                      />
                    )}
                  />
                  {errors?.start_date && (
                    <FormHelperText>กรุณาใส่ข้อมูลวันเริ่มต้น</FormHelperText>
                  )}
                </div>
                <div className="w-full lg:w-1/2 px-1">
                  {' '}
                  <div className="w-full text-sm py-2">วันเคลียร์สินค้า</div>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      vehicleAssignment
                        ? dayjs(vehicleAssignment?.end_date)?.toDate()
                        : dayjs().toDate()
                    }
                    render={({ field }) => (
                      <DatePicker
                        label="วันเคลียร์สินค้า"
                        value={field.value}
                        setValue={(value) => field.onChange(value)}
                      />
                    )}
                  />
                  {errors?.end_date && (
                    <FormHelperText>กรุณาใส่ข้อมูลวันสิ้นสุด</FormHelperText>
                  )}
                </div>
              </div>
            )}
            <div className="w-full px-1 text-sm my-2">หัวรถ</div>
            <div className="w-full px-1 my-1 flex justify-between">
              <div className="w-4/5 self-center">
                {!_.isEmpty(vehicles?.rows) && (
                  <Controller
                    name="vehicle"
                    control={control}
                    defaultValue={
                      vehicleAssignment
                        ? vehicleAssignment?.vehicle
                        : vehicles?.rows?.[0]
                    }
                    render={({ field }) => (
                      <Autocomplete
                        name={field.name}
                        ref={field.ref}
                        defaultValue={field.value}
                        options={vehicles?.rows}
                        autoHighlight
                        getOptionLabel={(option) =>
                          `ป้ายทะเบียน ${option?.licence_number}`
                        }
                        size="small"
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            {`ป้ายทะเบียน ${option?.licence_number}`}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                          setDriverRendering(false);
                          if (!value?.driver?._id) {
                            setValue('driver', undefined);
                          } else {
                            setValue('driver', value?.driver);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="รถ (หัวรถ)"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            helperText={errors.vehicle && 'กรุณาใส่ข้อมูล'}
                          />
                        )}
                      />
                    )}
                  />
                )}
              </div>
              <div className="w-1/5 px-2 self-center flex justify-center">
                <Link to="/fms/vehicle">
                  <Button size="small" variant="contained">
                    เพิ่ม
                  </Button>
                </Link>
              </div>
            </div>
            <div className="w-full px-1 text-sm my-2">พนักงานขับรถ</div>
            <div className="w-full px-1">
              {!_.isEmpty(drivers?.rows) && driverRendereing && (
                <Controller
                  name={'driver'}
                  control={control}
                  rules={{ required: false }}
                  defaultValue={
                    vehicleAssignment ? vehicleAssignment?.driver : undefined
                  }
                  render={({ field }) => (
                    <Autocomplete
                      name={field.name}
                      ref={field.ref}
                      defaultValue={field.value}
                      options={drivers.rows}
                      autoHighlight
                      size="small"
                      getOptionLabel={(option) =>
                        `${option?.employee?.firstname} ${
                          option?.employee?.lastname
                        } ${
                          option?.employee?.nickname
                            ? `( ${option?.employee?.nickname} )`
                            : ''
                        }`
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: 15,
                            '& > span': { mr: '10px', fontSize: 18 },
                          }}
                          {...props}
                        >
                          {`${option?.employee?.firstname} ${
                            option?.employee?.lastname
                          } ${
                            option?.employee?.nickname
                              ? `( ${option?.employee?.nickname} )`
                              : ''
                          }`}
                        </Box>
                      )}
                      onChange={(e, value) => {
                        field.onChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="พนักงานขับรถ"
                          inputProps={{
                            ...params.inputProps,
                          }}
                          helperText={errors.driver && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  )}
                />
              )}
            </div>
            <div className="w-full px-1 text-sm my-2">หางรถ</div>
            <div className="w-full px-1 my-1 flex justify-between">
              <div className="w-4/5 self-center">
                {!_.isEmpty(trailerTails?.rows) && (
                  <Controller
                    name="trailer_tail"
                    control={control}
                    defaultValue={
                      vehicleAssignment
                        ? vehicleAssignment?.trailer_tail
                        : undefined
                    }
                    render={({ field }) => (
                      <Autocomplete
                        name={field.name}
                        ref={field.ref}
                        defaultValue={field.value}
                        options={trailerTails?.rows}
                        autoHighlight
                        getOptionLabel={(option) =>
                          `ป้ายทะเบียน ${option?.licence_number}`
                        }
                        size="small"
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            {`ป้ายทะเบียน ${option?.licence_number}`}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="หางรถ"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                          />
                        )}
                      />
                    )}
                  />
                )}
              </div>
              <div className="w-1/5 px-2 self-center flex justify-center">
                <Link to="/fms/trailer-tail">
                  <Button size="small" variant="contained">
                    เพิ่ม
                  </Button>
                </Link>
              </div>
            </div>
            <div className="w-full px-1 text-sm my-2">ตู้คอนเทนเนอร์</div>
            <div className="w-full px-1 my-1 flex justify-between">
              <div className="w-4/5 self-center">
                {!_.isEmpty(containers?.rows) && (
                  <Controller
                    name="container"
                    control={control}
                    defaultValue={
                      vehicleAssignment
                        ? vehicleAssignment?.container
                        : undefined
                    }
                    render={({ field }) => (
                      <Autocomplete
                        name={field.name}
                        ref={field.ref}
                        defaultValue={field.value}
                        options={containers?.rows}
                        autoHighlight
                        size="small"
                        getOptionLabel={(option) =>
                          `ทะเบียน ${option?.licence_number}`
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            {`ทะเบียน ${option?.licence_number}`}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="ตู้คอนเทนเนอร์"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            helperText={errors.container && 'กรุณาใส่ข้อมูล'}
                          />
                        )}
                      />
                    )}
                  />
                )}
              </div>
              <div className="w-1/5 px-1 self-center flex justify-center">
                <Link to="/fms/container">
                  <Button size="small" variant="contained">
                    เพิ่ม
                  </Button>
                </Link>
              </div>
            </div>
            <div className="w-full px-1 my-2">
              <Controller
                name="seal_number"
                control={control}
                defaultValue={
                  vehicleAssignment ? vehicleAssignment?.seal_number : ''
                }
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="หมายเลขซีล"
                    size="small"
                  />
                )}
              />
            </div>

            <div className="w-full px-1 my-1">
              <Controller
                name="remark"
                control={control}
                defaultValue={
                  vehicleAssignment ? vehicleAssignment?.remark : ''
                }
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    label="หมายเหตุ"
                    size="small"
                    rows={2}
                  />
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

VehicleScheduleForm.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  selectAssignmentType: PropTypes.object,
  vehicles: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  trailerTails: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  containers: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  drivers: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  vehicleAssignment: PropTypes.object,
};

export default VehicleScheduleForm;
