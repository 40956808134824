import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { dayjs, currencyFormatter } from '../../../utils/functions';

const MaterialUsageTable = ({
  processMaterial,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowPerPage,
}) => (
  <div>
    <TableContainer>
      <Paper>
        <Table>
          <TableHead>
            <TableCell>ลำดับที่</TableCell>
            <TableCell>วันที่</TableCell>
            <TableCell>วัสดุ</TableCell>
            <TableCell>กระบวนการ</TableCell>
            <TableCell>จำนวน</TableCell>
            <TableCell>ผู้ดำเนินการ</TableCell>
          </TableHead>
          <TableBody>
            {_.map(processMaterial?.rows, (eachProcessMaterial, index) => (
              <TableRow key={index}>
                <TableCell>{(page - 1) * size + index + 1}</TableCell>
                <TableCell>
                  {dayjs(eachProcessMaterial?.createdAt)?.format(
                    'D MMM BBBB HH:mm',
                  )}
                </TableCell>
                <TableCell>
                  <b>{eachProcessMaterial?.material?.type_code || ''}</b>
                  {eachProcessMaterial?.material?.name || '-'}
                </TableCell>
                <TableCell>
                  <div
                    className={`font-semibold ${
                      eachProcessMaterial?.direction === true
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    {eachProcessMaterial?.transaction_type}
                  </div>
                </TableCell>
                <TableCell>
                  {currencyFormatter.format(eachProcessMaterial?.quantity)}{' '}
                  {eachProcessMaterial?.material?.unit || ''}
                </TableCell>
                <TableCell>
                  {eachProcessMaterial?.employee?.firstname || '-'}{' '}
                  {eachProcessMaterial?.employee?.lastname || ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>{' '}
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </TableContainer>
  </div>
);

MaterialUsageTable.propTypes = {
  processMaterial: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowPerPage: PropTypes.func,
};

export default MaterialUsageTable;
