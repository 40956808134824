/* eslint-disable default-param-last */
import {
  ALLWORKTRANSACTION_ALL,
  ALLWORKTRANSACTION_GET,
  ALLWORKTRANSACTION_POST,
  ALLWORKTRANSACTION_PUT,
  ALLWORKTRANSACTION_DELETE,
  ALLWORKTRANSACTION_LOADING,
  ALLWORKTRANSACTION_ERROR,
} from '../../actions/types';

const initialState = {
  trailerTail: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case ALLWORKTRANSACTION_LOADING:
      return { isLoading: true, isCompleted: true };
    case ALLWORKTRANSACTION_ERROR:
      return { isLoading: false, isCompleted: false };
    case ALLWORKTRANSACTION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ALLWORKTRANSACTION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ALLWORKTRANSACTION_POST:
      return { isLoading: false, isCompleted: true };
    case ALLWORKTRANSACTION_PUT:
      return { isLoading: false, isCompleted: true };
    case ALLWORKTRANSACTION_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
