/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { navigate } from 'react-big-calendar/lib/utils/constants';

function RenderDayView({ events, date, onSelectEvent }) {
  const [importEvent, setImportEvent] = useState([]);
  const [exportEvent, setExportEvent] = useState([]);
  const processEventType = () => {
    const tempImport = [];
    const tempExport = [];
    _.map(events, (event) => {
      const startLimit = moment(date.toString()).startOf('day');
      const endLimit = moment(date.toString()).endOf('day');
      const isBetween = moment(event.start).isBetween(
        startLimit,
        endLimit,
        null,
        '[]',
      );

      if (event.type === 'IMPORT' && isBetween) {
        tempImport.push(event);
      } else if (event.type === 'EXPORT' && isBetween) {
        tempExport.push(event);
      }
    });
    setImportEvent(tempImport);
    setExportEvent(tempExport);
  };
  useEffect(() => {
    processEventType();
    return () => {};
  }, [date, events]);

  const renderEvent = (event) => (
    <div
      className="my-2 p-2 rounded-md cursor-pointer"
      style={{ backgroundColor: event.backgroundColor }}
      onClick={() => {
        onSelectEvent(event);
      }}
    >
      <div className="text-white">{event.title}</div>
    </div>
  );

  const renderImportByType = (status = '', isVehicleSchedule = false) => {
    const filterEvent = _.filter(importEvent, (event) => {
      if (
        event.status === status &&
        event.isVehicleSchedule === isVehicleSchedule
      ) {
        return event;
      }
    });
    return _.size(filterEvent) ? (
      _.map(filterEvent, (event) => renderEvent(event))
    ) : (
      <div className="text-center my-2 p-2 rounded-md bg-slate-50">
        {' '}
        ไม่มีข้อมูล
      </div>
    );
  };

  const renderExportByType = (status = '', isVehicleSchedule = false) => {
    const filterEvent = _.filter(exportEvent, (event) => {
      if (
        event.status === status &&
        event.isVehicleSchedule === isVehicleSchedule
      ) {
        return event;
      }
    });
    return _.size(filterEvent) ? (
      _.map(filterEvent, (event) => renderEvent(event))
    ) : (
      <div className="text-center my-2 p-2 rounded-md bg-slate-50">
        {' '}
        ไม่มีข้อมูล
      </div>
    );
  };

  return (
    <div className="border-2 border-gray-200 p-2 rounded-md">
      <div className="flex flex-row">
        <div className="w-full md:w-1/2 pr-1">
          <div
            className="text-center my-2 p-2 rounded-md "
            style={{ backgroundColor: '#8b5cf6' }}
          >
            นำเข้า/Import
          </div>
          <div>ของออกเดินทางเข้ามา</div>
          {renderImportByType('STARTED', false)}
          <div>ของพร้อมออกเดินทาง</div>
          {renderImportByType('READY', false)}
          {renderImportByType('STARTED', true)}
          <div>กำหนดเดินทางถึงปลายทาง</div>
          {renderImportByType('PENDING', true)}
          <div>ถึงปลายทางแล้ว</div>
          {renderImportByType('COMPLETED', true)}
        </div>
        <div className="w-full md:w-1/2 pl-1">
          <div
            className="text-center my-2 p-2 rounded-md "
            style={{ backgroundColor: '#ef4444' }}
          >
            ส่งออก/Export
          </div>
          <div>เดินทางไปรับของ</div>
          {renderExportByType('STARTED', false)}
          <div>พร้อมเดินทางไปรับของ</div>
          {renderExportByType('READY', false)}
          {renderExportByType('STARTED', true)}
          <div>กำหนดเดินทางถึงปลายทาง</div>
          {renderExportByType('PENDING', true)}
          <div>ถึงปลายทางแล้ว</div>
          {renderExportByType('COMPLETED', true)}
        </div>
      </div>
    </div>
  );
}

class DayView extends React.Component {
  render() {
    const { date, events, onSelectEvent } = this.props;

    return (
      <RenderDayView
        date={date}
        events={events}
        onSelectEvent={onSelectEvent}
      />
    );
  }
}

DayView.range = (date) => [moment().startOf(date, 'DayView')];

DayView.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return moment().add(date, -1, 'DayView');

    case navigate.NEXT:
      return moment().add(date, 1, 'DayView');

    default:
      return date;
  }
};

DayView.title = (date, { localizer }) =>
  localizer.format(date, 'dayHeaderFormat');

export default DayView;
