import {
  CONTAINER_ALL,
  CONTAINER_GET,
  CONTAINER_POST,
  CONTAINER_PUT,
  CONTAINER_DEL,
  CONTAINER_LOADING,
  CONTAINER_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const containerCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONTAINER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/container`,
      {
        ...payload,
      },
    );
    dispatch({ type: CONTAINER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONTAINER_ERROR });
  }
};

export const containerAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/container?licence_number=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: CONTAINER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONTAINER_ERROR });
  }
};

export const containerGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/container/${id}`,
    );
    if (status === 200) {
      dispatch({ type: CONTAINER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONTAINER_ERROR });
  }
};

export const containerPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: CONTAINER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/container/${id}`,
      payload,
    );
    dispatch({ type: CONTAINER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONTAINER_ERROR });
  }
};

export const containerDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: CONTAINER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/container/${id}`,
    );
    dispatch({ type: CONTAINER_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONTAINER_ERROR });
  }
};
