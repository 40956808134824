import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Card,
  createFilterOptions,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Typography,
  Switch,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { MATERIAL_STOCK_STATUS } from '../../../utils/constants';
import MaterialWithoutStockMiniForm from './MaterialWithoutStockMiniForm';

export function AddMaterialForm({
  material,
  selectMaterial,
  setSelectMaterial,
  handleCreateMaterial,
  materialType,
}) {
  const resetValues = {
    quantity: 0,
    price: 0,
    material: material?.[0],
    lot_number: '',
  };

  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;
  const [reload, setReload] = useState(false);
  const filter = createFilterOptions();
  const [openAddMaterial, setOpenAddMaterial] = useState(false);
  const [addedMaterialName, setAddedMaterialName] = useState('');

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
    getValues,
    handleSubmit,
  } = useForm({ defaultValues: { use_full_price: false } });

  const {
    control: materialCreateControl,
    handleSubmit: materialCreateHandleSubmit,
    formState: { errors: materialCreateErrors },
  } = useForm();
  useEffect(() => () => {}, [reload]);

  //  เตรียมข้อมููล จัดการเข้าไปใน Array ของ Select Material
  const handleAddMaterial = () => {
    if (
      (getValues('material') || getValues('price') || getValues('quantity')) ===
      ''
    ) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        material: getValues('material')._id,
        price: getValues('price') || 0,
        quantity: getValues('quantity'),
        amount: getValues('quantity'),
        lot_number: getValues('lot_number') || '',
        remark: getValues('remark') || '',
        status: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
      };
      selectMaterial.push(data);
      setSelectMaterial(selectMaterial);
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveMaterial = (index) => {
    selectMaterial.splice(index, 1);
    setSelectMaterial(selectMaterial);
    setReload(!reload);
  };

  // ปรับให้มันมี Index กันชื่อและรหัสซ้ำกัน
  const materialOption = _.map(material, (eachMaterial, index) => ({
    ...eachMaterial,
    index: index + 1,
  }));

  // สำหรับกรอกข้อมูล Material
  const materialOptions = () => (
    <Card>
      <form onSubmit={handleSubmit(handleAddMaterial)}>
        <div className="w-full flex flex-row flex-wrap p-4">
          <div className="w-full px-1 py-2">
            {!_.isEmpty(material) && (
              <Controller
                name={'material'}
                control={control}
                rules={{ required: true }}
                defaultValue={material?.[0]}
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    {...field}
                    options={materialOption}
                    defaultValue={field?.value}
                    getOptionLabel={(option) => {
                      // Input Case
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option?.inputValue) {
                        return `${option?.inputValue || ''}`;
                      }
                      // Default case
                      return `${option?.index || ' '} ${
                        option?.type_code || ' '
                      }  ${option?.name}`;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          fontSize: 15,
                          '& > span': { mr: '10px', fontSize: 18 },
                        }}
                        {...props}
                      >
                        {option?.index || ' '} {option?.type_code || ' '}{' '}
                        {option?.name}
                      </Box>
                    )}
                    required
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setTimeout(() => {
                          setOpenAddMaterial(true);
                          setAddedMaterialName(newValue);
                        });
                      } else if (newValue && newValue?.inputValue) {
                        setOpenAddMaterial(true);
                        setAddedMaterialName(newValue?.inputValue);
                      }
                      // Default Case
                      else {
                        field.onChange(newValue);
                        setReload(!reload);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params?.inputValue !== '') {
                        filtered.push({
                          inputValue: params?.inputValue,
                          name: `เพิ่ม ${params?.inputValue}  ลงในระบบ`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="วัสดุ" />
                    )}
                  />
                )}
              />
            )}
          </div>
          {imsSetting?.display?.lot_number && (
            <div className="w-full px-1 py-2">
              <Controller
                name={'lot_number'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="หมายเลขล็อต"
                    fullWidth
                    size={'small'}
                    helperText={errors.lot_number && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          <div className="w-full px-1 py-2">
            <div className="border p-2 rounded-md">
              <Controller
                name="use_full_price"
                defaultValue={true}
                control={control}
                render={({ field }) => (
                  <div className="flex justify-center">
                    <Typography className="self-center mx-1">
                      ใช้ราคาต่อหน่วย
                    </Typography>
                    <FormControlLabel
                      label="ใช้ราคารวม"
                      control={<Switch {...field} />}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          {imsSetting?.display?.cost && !watch('use_full_price') && (
            <div className="w-full lg:w-1/2 px-1 py-2">
              <Controller
                name={'price_per_unit'}
                control={control}
                defaultValue={0}
                rules={{ required: false, min: 0, default: 0 }}
                render={({ field }) => (
                  <TextField
                    type="Number"
                    {...field}
                    onChange={(event) => {
                      const currentPrice = event.target.value;
                      const allPrice =
                        currentPrice * parseFloat(watch('quantity'));
                      field.onChange(event.target.value);
                      setValue('price', allPrice);
                    }}
                    label="ราคาต่อหน่วย (บาท)"
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}{' '}
          {imsSetting?.display?.cost && !watch('use_full_price') && (
            <div className="w-full lg:w-1/2 font-sansserif flex gap-2 px-1 py-2">
              <div>ราคารวม</div>
              <div>{watch('price')} บาท</div>
            </div>
          )}
          {imsSetting?.display?.cost && watch('use_full_price') && (
            <div className="w-full lg:w-1/2 px-1 py-2">
              <Controller
                name={'price'}
                control={control}
                defaultValue={0}
                rules={{ required: false, min: 0, default: 0 }}
                render={({ field }) => (
                  <TextField
                    type="Number"
                    {...field}
                    label="ราคารวม (บาท)"
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          <div className="w-full px-1 py-2">
            <Controller
              name={'quantity'}
              control={control}
              defaultValue={0}
              rules={{ required: true, min: 0 }}
              render={({ field }) => (
                <TextField
                  type="Number"
                  required
                  {...field}
                  onChange={(event) => {
                    if (!watch('use_full_price')) {
                      const currentQuantity = event.target.value;
                      const allPrice =
                        currentQuantity * parseFloat(watch('price_per_unit'));
                      field.onChange(event.target.value);
                      setValue('price', allPrice);
                    } else {
                      field.onChange(event.target.value);
                    }
                  }}
                  label="จำนวน"
                  fullWidth
                  size={'small'}
                  helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  label="หมายเหตุ"
                  multiline
                  rows={3}
                />
              )}
            />
          </div>
          <div className="px-1">
            <Button
              type="button"
              variant="outlined"
              size={'small'}
              color={'success'}
              onClick={async (data) => {
                await handleSubmit(handleAddMaterial)(data);
                setOpenAddMaterial(false);
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );

  // แสดงรายการ Material ใน Select Material
  const displayMaterial = () => (
    <Card>
      {' '}
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ชื่อวัสดุ</div>
              </TableCell>
              {imsSetting?.display?.cost && (
                <TableCell>
                  <div className="font-bold"> ราคา</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>

              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(selectMaterial) ? (
              selectMaterial.map((_material, index) => {
                const findMaterial = _.find(material, {
                  id: _material?.material,
                });
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {imsSetting?.display?.lot_number && (
                      <TableCell>{_material?.lot_number || '-'}</TableCell>
                    )}
                    <TableCell>
                      <div className="flex flex-wrap">
                        <span className="font-bold mr-2">
                          {findMaterial?.type_code}
                        </span>
                        <span>{findMaterial?.name}</span>
                      </div>
                      {_material?.remark || ''}
                    </TableCell>
                    {imsSetting?.display?.cost && (
                      <TableCell>
                        {_material?.price || '-'} บาท
                        <br />(
                        {(
                          parseFloat(_material?.price) /
                          parseFloat(_material?.quantity)
                        ).toFixed(2)}{' '}
                        บาท/{findMaterial.unit})
                      </TableCell>
                    )}
                    <TableCell>
                      {_material?.quantity || '-'} {findMaterial?.unit}
                    </TableCell>

                    <TableCell>
                      <Button
                        color={'error'}
                        variant="contained"
                        size={'small'}
                        onClick={() => handleRemoveMaterial(index)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const renderAddMaterialDialog = () => (
    <Dialog open={openAddMaterial} onClose={() => setOpenAddMaterial(false)}>
      <form>
        <DialogTitle>เพิ่มวัสดุ</DialogTitle>
        <DialogContent>
          <MaterialWithoutStockMiniForm
            control={materialCreateControl}
            initialMaterialName={addedMaterialName}
            materialType={materialType}
            errors={materialCreateErrors}
          />
        </DialogContent>
        <DialogActions>
          <div className="my-2">
            <Button
              variant="contained"
              type="button"
              onClick={materialCreateHandleSubmit(async (data) => {
                console.log('data', data);
                await handleCreateMaterial(data);
                setOpenAddMaterial(false);
              })}
            >
              เพิ่ม
            </Button>
          </div>
        </DialogActions>{' '}
      </form>
    </Dialog>
  );
  const showSelectMaterial = () => displayMaterial();

  return (
    <div>
      {renderAddMaterialDialog()}
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/3">{materialOptions()}</div>
        <div className="w-full lg:w-2/3 lg:pl-2">{showSelectMaterial()}</div>
      </div>
    </div>
  );
}

AddMaterialForm.propTypes = {
  material: PropTypes.object,
  selectMaterial: PropTypes.object,
  setSelectMaterial: PropTypes.func,
  handleCreateMaterial: PropTypes.func,
  materialType: PropTypes.arrayOf(PropTypes.object),
};

AddMaterialForm.defaultProps = {
  material: null,
  selectMaterial: null,
};

export default AddMaterialForm;
