import React from 'react';
import _ from 'lodash';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Chip,
} from '@mui/material';
import { Check, X as XIcon } from 'react-feather';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Link } from 'react-router-dom';

import { currencyFormatter } from '../../../utils/functions';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const BillBookingTable = ({
  billBookings,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDelete,
  showActionButton = true,
  handleRestore,
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">วันที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เลขรายการ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เลขที่ Booking</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">งาน/ลูกค้า</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold"> ราคา</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(billBookings) ? (
            billBookings.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {(page - 1) * size + index + 1}
                </TableCell>{' '}
                <TableCell>{dayjs(row?.date)?.format('D MMM BBBB')}</TableCell>
                <TableCell>
                  <div className="text-center">{row?.running_number}</div>
                  <div className="flex justify-center">
                    {row?.completed && (
                      <div className="flex px-1 bg-green-300 rounded-md">
                        <Check size="16" className="self-center mx-1" />
                        <div className="self-center mx-1">ออกบิลแล้ว</div>
                      </div>
                    )}
                    {row?.deleted && (
                      <div className="flex px-1 bg-red-300 rounded-md">
                        <XIcon size="16" className="self-center mx-1" />
                        <div className="self-center mx-1">ยกเลิกแล้ว</div>
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="text-center">{row?.booking_number}</div>
                </TableCell>
                <TableCell>
                  {row?.from_vehicle_schedule ? (
                    <div></div>
                  ) : (
                    <div>
                      <div>
                        {row?.individual_input?.job?.name}{' '}
                        <Chip
                          label={row?.individual_input?.job?.assignment_type}
                          size="small"
                        />{' '}
                      </div>
                      <div>
                        {row?.individual_input?.job?.customer?.short
                          ? row?.individual_input?.job?.customer?.short
                          : row?.individual_input?.job?.customer?.name}
                      </div>
                      <div>หมายเหตุ {row?.remark || '-'}</div>
                    </div>
                  )}
                </TableCell>{' '}
                <TableCell>{currencyFormatter.format(row?.price)}</TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-1">
                    <Link to={`bill-booking/view/${row?._id}`}>
                      <Button variant="contained" size="small" color="info">
                        รายละเอียด
                      </Button>
                    </Link>{' '}
                    {showActionButton && (
                      <Link to={`bill-booking/edit/${row?._id}`}>
                        <Button
                          variant="contained"
                          size="small"
                          color="warning"
                        >
                          แก้ไข
                        </Button>
                      </Link>
                    )}
                    {showActionButton && (
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        disabled={row?.completed || row?.deleted}
                        onClick={() => handleDelete(row?._id)}
                      >
                        ลบ
                      </Button>
                    )}
                    {showActionButton && row?.deleted && (
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        disabled={!row?.deleted}
                        onClick={() => handleRestore(row?._id)}
                      >
                        Restore
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={handleChangePage}
    />
  </Paper>
);

BillBookingTable.propTypes = {
  billBookings: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleToInfoPage: PropTypes.func,
  handleToEditPage: PropTypes.func,
  handleDelete: PropTypes.func,
  showActionButton: PropTypes.bool,
  handleRestore: PropTypes.func,
};

export default BillBookingTable;
