import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Modal, Backdrop, Fade } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import {
  MaintenanceOrderCard,
  ProcessControlCard,
  CommentCard,
  MaterialCard,
  MaterialWithdrawRequestCard,
  MaintenanceImageCard,
  MaintenanceTopicCard,
  OuterServiceCard,
} from '../../components/Card';
import {
  DicutTypeTwoWithdrawMaterialReport,
  MaintenanceOrderReport,
  MaintenanceReport,
  TypeTwoWithdrawMaterialReport,
  WithdrawMaterialReport,
  TypeTwoMaintenanceOrder,
  TypeTwoMaintenanceOrderDicut,
} from '../../components/Report';
import { ProcessTimeline } from '../../components/Timeline';
import {
  CONFIG,
  PROCESS_TXN_TYPE,
  MAINTENANCE_ORDER_STATUS,
} from '../../utils/constants';
import { FinishMainatenanceOrderForm } from '../../components/Forms';
import MaintenanceFileCard from '../../components/Card/CMMS/MaintenanceFileCard';
import MainmaintenanceReport from '../../components/Report/MainmaintenanceReport';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

const defaultValue = { licence_number: '', type: '' };

export default function DetailMaintenanceOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const me = useSelector((state) => state.me);
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const information = useSelector((state) => state.information);
  const processTransactions = useSelector((state) => state.processTransactions);
  const comments = useSelector((state) => state.comments);
  const maintenanceTopics = useSelector((state) => state.maintenanceTopics);

  const materialRequest = useSelector((state) => state.materialRequest);
  const history = useHistory();

  const cmmsSetting = information?.setting?.cmms;

  const usingSparedPart = cmmsSetting?.use_spared_part; // TODO: Link data from setting

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValue: maintenanceOrder,
  });

  const [memoModalOpen, setMemoModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  // Get and Fetching Data
  const getComments = async () => {
    try {
      dispatch(
        actions.commentAll({
          maintenanceOrder: id,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Get All Attached Comments Error', error);
    }
  };

  // Get Transaction that who do something in process
  const getProcessTransaction = async () => {
    try {
      dispatch(
        actions.processTransactionAll({
          maintenanceOrder: id,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.log('Get process Error', error);
    }
  };

  // Get Transaction of Withdraw and Return of Material
  const getProcessMaterial = async () => {
    try {
      dispatch(
        actions.processMaterialAll({
          maintenanceOrder: id,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.log('Get Process Material Error', error);
    }
  };

  // Fetch Request Material Withdraw
  const fetchRequestMaterialWithdraw = async () => {
    try {
      dispatch(
        actions.materialRequestAll({
          maintenanceOrder: id,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Cannot Fetch Request of Material Withdraw', error);
    }
  };

  // MAIN - Get Maintenance Order
  const getMaintenanceOrder = async () => {
    try {
      await dispatch(actions.maintenanceOrderGet(id, { fetchHistory: false }));
    } catch (error) {
      console.error('Get Maintenance Order Error', error);
    }
  };

  // Manipulating Data
  const handleSubmitComment = async (payload) => {
    try {
      await dispatch(actions.commentCreate(payload));
      getComments();
    } catch (error) {
      window.alert('การสร้างความคิดเห็นผิดพลาด', error?.message);
      console.error(error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await dispatch(actions.commentDelete(commentId));
      getComments();
    } catch (error) {
      window.alert('การลบความคิดเห็นผิดพลาด', error?.message);
      console.error(error);
    }
  };

  const handleDeleteMaterialWithdrawRequest = async (materialRequestId) => {
    try {
      const confirm = window.confirm('ยืนยันการลบคำขอเบิกวัสดุ');
      if (confirm) {
        await dispatch(actions.materialRequestDelete(materialRequestId));
        alert('สำเร็จ');
        dispatch(
          actions.materialRequestAll({
            maintenanceOrder: id,
            size: CONFIG.infiniteLimit,
          }),
        );
      }
    } catch (error) {
      window.alert(`ลบคำขอเบิกวัlf6ไม่สำเร็จ ${error?.message}`);
    }
  };

  // Handle Work Accept
  // If it's status NEW Automatic change to PROCESS_ACCEPT
  const handleApprove = async () => {
    try {
      const confirm = window.confirm('ยืนยันการอนุมัติงานซ่อมบำรุง');
      if (confirm) {
        await dispatch(
          actions.maintenanceOrderPut(id, {
            allower: me?.userData?._id,
            allow_date: new Date(),
            // Add Employee Id and TransactionType for keep the transaction
            employee: me?.userData?._id,
            transaction_type: PROCESS_TXN_TYPE.PROCESS_APPROVE.status_code,
            status:
              maintenanceOrder?.status ===
              MAINTENANCE_ORDER_STATUS.NEW.status_code
                ? MAINTENANCE_ORDER_STATUS.APPROVE.status_code
                : maintenanceOrder.status,
          }),
        );
        getMaintenanceOrder();
        getProcessTransaction();
      }
    } catch (error) {
      window.alert(`การอนุมัติงานผิดพลาด ${error?.message}`);
    }
  };

  // Reject from work make work free of responsible employee again
  const handleRejectWork = async () => {
    try {
      await dispatch(
        actions.maintenanceOrderPut(id, {
          responsible: null,
          employee: me?.userData?._id,
          transaction_type: PROCESS_TXN_TYPE.PROCESS_REJECT.status_code,
        }),
      );
      getMaintenanceOrder();
      getProcessTransaction();
    } catch (error) {
      window.alert('การถอนตัวผิดพลาด', error?.message);
    }
  };

  // Handle Navigation or UI Overlay
  const handleSendWork = () => {
    setMemoModalOpen(true);
  };

  const handleToWithdrawMaterial = (orderId) => {
    history.push(`/cmms/material-withdraw/${orderId}`);
  };

  const handleToReturnMaterial = (orderId) => {
    history.push(`/cmms/material-return/${orderId}`);
  };

  const handleToRequestWithdrawMaterial = (orderId) => {
    history.push(`/cmms/material-withdraw-request/${orderId}`);
  };

  const handleCloseMemo = () => {
    setMemoModalOpen(false);
  };

  const handlePrintReport = () => {
    const data = { ...maintenanceOrder, price: true };
    MaintenanceReport(data, information, data?.process_materials, me);
  };

  const handlePrintMaintenanceOrder = () => {
    try {
      const data = { ...maintenanceOrder, price: true };
      MainmaintenanceReport(data, information, me);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleWithdrawMaterialReport = () => {
    try {
      WithdrawMaterialReport(maintenanceOrder, information, me);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleWithdrawMaterialReportTypeTwo = () => {
    try {
      TypeTwoWithdrawMaterialReport(maintenanceOrder, information, me);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleWithdrawMaterialReportTypeTwoDicut = () => {
    try {
      DicutTypeTwoWithdrawMaterialReport(maintenanceOrder, information, me);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handlePrintMaintenanceOrderTypeTwo = () => {
    try {
      TypeTwoMaintenanceOrder(
        maintenanceOrder,
        information,
        me,
        maintenanceTopics,
      );
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handlePrintMaintenanceOrderTypeTwoDicut = () => {
    try {
      TypeTwoMaintenanceOrderDicut(
        maintenanceOrder,
        information,
        me,
        maintenanceTopics,
      );
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };
  // Submit Either Edit or Complete Maintenance Order
  const onSubmitForm = async (data, markFinish = false) => {
    try {
      const payload = {
        ...data,
        maintenance_topic: _.map(data.maintenance_topic, (_eachTopic) => ({
          ..._eachTopic,
          topic: _eachTopic?.topic?._id || _eachTopic?.topic,
          topic_id: _eachTopic?.topic?._id || _eachTopic?.topic,
          _id: undefined,
        })),
        employee: me?.userData?._id,
        transaction_type: markFinish
          ? PROCESS_TXN_TYPE.PROCESS_FINISH.status_code
          : PROCESS_TXN_TYPE.PROCESS_OTHER.status_code,
        process: data.process,
      };

      const maintenanceImage = _.map(images, (image) => ({
        type: 'รูปภาพหลังการแจ้งซ่อม',
        image: image.data_url,
      }));

      console.log('maintenance order payload', payload);
      await dispatch(
        actions.maintenanceOrderPut(id, {
          ...payload,
          maintenance_image: maintenanceImage,
          status: markFinish
            ? MAINTENANCE_ORDER_STATUS.SUCCESS.status_code
            : maintenanceOrder.status,
        }),
      );

      reset(defaultValue);
      alert('สำเร็จ');

      handleCloseMemo();

      getMaintenanceOrder();
    } catch (error) {
      alert('ดำเนินการไม่สำเร็จ');
      console.error(error);
    }
  };

  useEffect(() => {
    getMaintenanceOrder();
    getProcessMaterial();
    getProcessMaterial();
    getProcessTransaction();
    getComments();
    fetchRequestMaterialWithdraw();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSparedPart = () => (
    <div>
      <div className="my-2">
        <MaterialCard
          materialArrays={maintenanceOrder?.process_materials}
          maintenanceOrderId={maintenanceOrder?._id}
        />
      </div>

      {!_.isEmpty(materialRequest?.rows) && (
        <div className="my-2">
          <MaterialWithdrawRequestCard
            materialRequests={materialRequest?.rows}
            handleDeleteMaterialRequest={handleDeleteMaterialWithdrawRequest}
          />
        </div>
      )}
      <div className="my-2">
        <ProcessTimeline container processTransaction={processTransactions} />
      </div>
    </div>
  );

  const renderMemoModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={memoModalOpen}
      onClose={handleCloseMemo}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={memoModalOpen}>
        <Card
          sx={style}
          className="max-w-4xl  max-h-screen   overflow-y-auto my-4"
        >
          <div className=" font-semibold text-xl">บันทึกผลการแจ้งซ่อม</div>
          <div className="my-2 ">
            <form
              onSubmit={handleSubmit(
                // eslint-disable-next-line no-return-await
                async (data) => await onSubmitForm(data, true),
              )}
            >
              <div>
                <FinishMainatenanceOrderForm
                  control={control}
                  errors={errors}
                />
              </div>

              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseMemo}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  if (maintenanceOrder.isLoading || maintenanceOrder.rows) {
    return <Loading />;
  }
  if (!maintenanceOrder.isLoading && maintenanceOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderMemoModal()}

        <div className="flex flex-row justify-between pb-4">
          <div>
            <BackButton />
          </div>
          <div className="flex gap-2">
            {cmmsSetting?.available_report?.main_maintenance_order && (
              <Button
                variant="contained"
                onClick={handlePrintMaintenanceOrder}
                color="secondary"
              >
                พิมพ์ใบแจ้ง
              </Button>
            )}
            {cmmsSetting?.available_report?.material_withdraw_report_1 && (
              <Button
                variant="contained"
                color="info"
                onClick={handleWithdrawMaterialReport}
              >
                พิมพ์ใบสั่งเบิกอะไหล่
              </Button>
            )}
            {cmmsSetting?.available_report?.material_withdraw_report_2 && (
              <Button
                variant="contained"
                color="info"
                onClick={handleWithdrawMaterialReportTypeTwo}
              >
                พิมพ์ใบขอเบิกวัตถุดิบ
              </Button>
            )}
            {cmmsSetting?.available_report
              ?.material_withdraw_report_2_dicut && (
              <Button
                variant="contained"
                color="info"
                onClick={handleWithdrawMaterialReportTypeTwoDicut}
              >
                พิมพ์ใบขอเบิกวัตถุดิบ (เฉพาะตัวอักษร)
              </Button>
            )}
            {cmmsSetting?.available_report?.main_maintenance_report && (
              <Button variant="contained" onClick={handlePrintReport}>
                พิมพ์รายงาน
              </Button>
            )}

            {cmmsSetting?.available_report?.maintenance_order_report_2 && (
              <Button
                variant="contained"
                onClick={handlePrintMaintenanceOrderTypeTwo}
              >
                ใบแจ้งซ่อม
              </Button>
            )}
            {cmmsSetting?.available_report
              ?.maintenance_order_report_2_dicut && (
              <Button
                variant="contained"
                onClick={handlePrintMaintenanceOrderTypeTwoDicut}
              >
                ใบแจ้งซ่อม (เฉพาะตัวอักษร)
              </Button>
            )}
          </div>
        </div>
        <div>
          <MaintenanceOrderCard
            maintenanceOrder={maintenanceOrder}
            showAllower={!information?.setting?.cmms?.automatic_work_accept}
            showMaintanence={true}
          />
          {usingSparedPart ? (
            <div className="my-2">
              <ProcessControlCard
                maintenanceOrder={maintenanceOrder}
                me={me}
                handleSendWork={handleSendWork}
                handleToWithdrawMaterial={handleToWithdrawMaterial}
                handleToReturnMaterial={handleToReturnMaterial}
                handleApprove={handleApprove}
                handleRejectWork={handleRejectWork}
                handleEdit={() => {
                  history.push(
                    `/cmms/maintenance-orders/edit/${maintenanceOrder?._id}`,
                  );
                }}
                information={information}
                handleToRequestWithdrawMaterial={
                  handleToRequestWithdrawMaterial
                }
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex flex-wrap lg:flex-nowrap gap-1 my-2 mb-5">
            {!_.isEmpty(maintenanceOrder?.maintenance_topic) && (
              <div
                className={`${
                  _.isEmpty(maintenanceOrder?.outer_services)
                    ? 'w-full'
                    : 'lg:w-1/2'
                }`}
              >
                <MaintenanceTopicCard
                  maintenanceTopics={maintenanceOrder?.maintenance_topic}
                  showFullPanel={_.isEmpty(maintenanceOrder?.outer_services)}
                />
              </div>
            )}
            {!_.isEmpty(maintenanceOrder?.outer_services) && (
              <div
                className={`${
                  _.isEmpty(maintenanceOrder?.maintenance_topic)
                    ? 'w-full'
                    : 'lg:w-1/2 '
                }`}
              >
                <OuterServiceCard
                  outerServices={maintenanceOrder?.outer_services}
                  showFullPanel={_.isEmpty(maintenanceOrder?.maintenance_topic)}
                />
              </div>
            )}
          </div>

          {usingSparedPart ? renderSparedPart() : <div></div>}
          {!_.isEmpty(maintenanceOrder?.maintenance_image) && (
            <MaintenanceImageCard
              mainenanceImages={maintenanceOrder?.maintenance_image}
            />
          )}

          <div className="">
            <MaintenanceFileCard
              maintenanceOrder_file={maintenanceOrder?.file}
            />
          </div>
          <div className="my-2">
            <CommentCard
              comment={comments}
              container
              showTitle
              handleSubmitComment={handleSubmitComment}
              handleDeleteComment={handleDeleteComment}
              maintenanceOrder={maintenanceOrder}
              me={me}
            />
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}
DetailMaintenanceOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaintenanceOrder.defaultProps = {
  title: '',
  subtitle: '',
};
