import dayjs from 'dayjs';
import appConfig from '../../configs/app';
import _ from 'lodash';

const columns = [
  {
    label: 'วันที่',
    value: (col) => `${dayjs(col?.createdAt).format('DD/MM/BB')}`,
  },
  {
    label: 'รหัสเครื่องจักร',
    value: (col) => col?.vehicle?.licence_number,
  },
  {
    label: 'ชื่อเครื่องจักร',
    value: (col) => `${col?.vehicle.engname}  ${col?.vehicle.thainame}` || '-',
  },
  {
    label: 'อาการ/ความเสียหาย',
    value: (col) =>
      `${_.map(col?.maintenance_topic, (each) => each?.topic?.name)}`,
  },
  {
    label: 'วิธีการซ่อมบำรุง',
    value: (col) => col?.modify || '-',
  },
  {
    label: 'แล้วเสร็จ',
    value: (col) => `${dayjs(col?.end_date).format('DD/MM/BB')}`,
  },
  {
    label: 'ผู้ปฏิบัติ/ผู้แจ้ง',
    value: (col) =>
      `ผู้แจ้ง ${col?.informer.firstname || ''}  ${
        col?.informer.lastname || ''
      }  ผู้ปฏิบัติ  ${col?.workrecip || ''}  `,
  },
  {
    label: 'หมายเหตุ',
    value: '-',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query };
