import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { VehicleAttributeCard, VehicleCard } from '../../components/Card';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { dayjs } from '../../utils/functions';

export default function DetailVehicle({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const vehicle = useSelector((state) => state.vehicle);

  useEffect(() => {
    dispatch(actions.vehicleGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (vehicle.isLoading || vehicle.rows) {
    return <Loading />;
  }
  if (!vehicle.isLoading && vehicle.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div className="my-2">
          <VehicleCard vehicleDetail={vehicle} />
        </div>{' '}
        <div className="my-2">
          <VehicleAttributeCard vehicleDetail={vehicle} />
        </div>
      </div>
    );
  }
  DetailVehicle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  DetailVehicle.defaultProps = {
    title: '',
    subtitle: '',
  };

  return <Error />;
}
