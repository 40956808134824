import {
  PROCESS_MATERIAL_ALL,
  PROCESS_MATERIAL_GET,
  PROCESS_MATERIAL_DELETE,
  PROCESS_MATERIAL_PUT,
  PROCESS_MATERIAL_POST,
  PROCESS_MATERIAL_LOADING,
  PROCESS_MATERIAL_ERROR,
  PROCESS_MATERIAL_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const processMaterialCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_MATERIAL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/process-material`,
      payload,
    );
    dispatch({ type: PROCESS_MATERIAL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const processMaterialAll =
  ({ size = '', page = 1, maintenanceOrder = '', employee = '' }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/process-material?size=${size}&page=${page}&maintenanceOrder=${maintenanceOrder}&employee=${employee}`,
      );
      if (status === 200) {
        dispatch({ type: PROCESS_MATERIAL_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: PROCESS_MATERIAL_ERROR });
      throw new Error(error);
    }
  };

export const processMaterialGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/process-material/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PROCESS_MATERIAL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const processMaterialPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_MATERIAL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/process-material/${id}`,
      payload,
    );
    dispatch({ type: PROCESS_MATERIAL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const processMaterialDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_MATERIAL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/process-material/${id}`,
    );
    dispatch({ type: PROCESS_MATERIAL_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const processMaterialReset = () => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_MATERIAL_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROCESS_MATERIAL_ERROR });
    throw new Error(error);
  }
};
