import { Card, TextField, Button } from '@mui/material';
import React from 'react';

// eslint-disable-next-line react/prop-types
export default function CreateWheelTypeCard({
  control,
  Controller,
  wheelType,
}) {
  console.log('wheelType ddd', wheelType);
  return (
    <div className="flex justify-center">
      <div className="w-1/2">
        <Card>
          <div className="w-full px-4 py-4">
            <Controller
              name={'name'}
              control={control}
              defaultValue={''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="ชื่อชนิดยาง"
                  required
                  fullWidth
                  // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-4 pb-4">
            <Controller
              name={'note'}
              control={control}
              defaultValue={'-'}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="หมายเหตุ"
                  fullWidth
                  multiline
                  rows={5}
                  // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="text-center pb-6">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
