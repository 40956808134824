import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import { ReportHeader } from './common';
import _ from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (Orders) => {
  const productList = _.map(Orders, (order, index) => [
    {
      text: dayjs(order?.start_date).format('DD MMM BB '),
      alignment: 'center',
    },
    {
      stack: [
        { text: order?.garage?.name || 'ซ่อมอู่ใน', style: 'subTitle' },
        _.map(order?.maintenance_topic, (topic, index) => [
          {
            text: `${index + 1}.${topic?.topic.name} (${topic?.price
              ?.toFixed(2)
              ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')})\n`,

            alignment: 'left',
          },
        ]),
        _.map(order?.outer_services, (topic, index) => [
          {
            stack: [
              {
                text: `${topic?.garage?.name || 'ซ่อมนอกอู่'}\n`,
                style: 'subTitle',
              },
              {
                text: `${index + 1}.${topic?.title || ''}(${
                  topic?.price
                    ? topic?.price
                        ?.toFixed(2)
                        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    : ''
                })\n`,
              },
            ],

            alignment: 'left',
          },
        ]),
      ],
    },

    {
      text: '',
    },
    {
      text: order?.total_price?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      alignment: 'right',
    },
  ]);

  return productList;
};

const MaintenVehicleMonthly = async (row, information, vehicleDetail) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  // console.log('info', information);
  // console.log('row', row);
  // console.log('vehicleDetail', vehicleDetail);
  const genItemBody = genBody(row);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 80, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: information?.name,
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `รายงานการซ่อม ${vehicleDetail?.licence_number || ''}`,
                style: 'header',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['18%', '56%', '6%', '20%'],
          body: [
            [
              {
                text: 'วันที่',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการซ่อม',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },

              {
                text: 'ราคารวมสุทธิ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      subTitle: {
        fontSize: 12,
        bold: true,
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default MaintenVehicleMonthly;
