import {
  MATERIAL_TRANSACTION_ALL,
  MATERIAL_TRANSACTION_GET,
  MATERIAL_TRANSACTION_DEL,
  MATERIAL_TRANSACTION_PUT,
  MATERIAL_TRANSACTION_POST,
  MATERIAL_TRANSACTION_SEARCH,
  MATERIAL_TRANSACTION_LOADING,
  MATERIAL_TRANSACTION_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const materialTransactionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-transaction`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_TRANSACTION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionAll =
  ({
    materialName = '',
    size = '',
    page = 1,
    selectType = '',
    vehicle = '',
    trailerTail = '',
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-transaction?materialName=${materialName}&size=${size}&page=${page}&selectType=${selectType}&vehicle=${vehicle}&trailerTail=${trailerTail}`,
      );
      if (status === 200) {
        dispatch({ type: MATERIAL_TRANSACTION_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: MATERIAL_TRANSACTION_ERROR });
      throw new Error(error);
    }
  };

export const materialTransactionSearch =
  ({
    materialName = '',
    size = '',
    page = 1,
    month = '',
    selectType = '',
    vehicle = '',
    trailerTail = '',
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-transaction?materialName=${materialName}&size=${size}&page=${page}&month=${month}&selectType=${selectType}&vehicle=${vehicle}&trailerTail=${trailerTail}`,
      );
      if (status === 200) {
        dispatch({ type: MATERIAL_TRANSACTION_SEARCH, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: MATERIAL_TRANSACTION_ERROR });
      throw new Error(error);
    }
  };

export const materialTransactionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_TRANSACTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const materialTransactionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-transaction/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_TRANSACTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
export const materialTransactionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TRANSACTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-transaction/${id}`,
    );
    dispatch({ type: MATERIAL_TRANSACTION_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
