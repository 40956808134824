/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import {
  Button,
  Card,
  CardContent,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';

// eslint-disable-next-line react/prop-types
export default function WheelAddForm({
  control,
  Controller,
  wheel,
  setSelectType,
  wheelSupply,
}) {
  // console.log('wheelType in edir form', wheelType);
  const handleChange = (event) => {
    setSelectType(event.target.value);
  };
  return (
    <div className="pt-2">
      <div>
        <Card>
          <CardContent>
            <div className="">
              <div className=" px-1 py-2">
                <Controller
                  name={'wheelNumber'}
                  control={control}
                  defaultValue={wheel ? wheel.wheelNumber : ''}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="รหัสยาง/สต็อก"
                      required
                      fullWidth
                      // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>
              <div className=" px-1 py-2">
                <Controller
                  name={'supply'}
                  control={control}
                  defaultValue={wheel ? wheel?.supply?._id : ''}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        ยี่ห้อ
                      </InputLabel>
                      <Select {...field} label="ประเภทยาง">
                        {_.map(wheelSupply?.rows, (each) => (
                          <MenuItem key={each?._id} value={each?._id}>
                            {each?.brand}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </div>

            <div className=" px-1 py-2">
              <Controller
                name={'note'}
                control={control}
                defaultValue={wheel ? wheel?.note : ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="รายละเอียดเพิ่มเติม"
                    fullWidth
                    multiline
                    rows={5}
                    // helperText={errors. && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="flex justify-end pb-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
