import React from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { dayjs, showVehicleScheduleStatus } from '../../../utils/functions';

const VehicleScheduleHistoryTable = ({
  handleChangeRowsPerPage,
  vehicleSchedule,
  handleChangePage,
  page,
  size,
  total,
  handleToInfoPage = () => {},
  handlePrintPdfDeliveryOrder = () => {},
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เริ่มจอง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">สิ้นสุด</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เลขที่รายการ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">สถานะ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">รายการงาน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">พนักงานขับรถ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ทะเบียนรถ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">หมายเลขซีล</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(vehicleSchedule?.rows) ? (
            vehicleSchedule?.rows.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {(page - 1) * size + index + 1}
                </TableCell>
                <TableCell>
                  {`${dayjs(row?.start_date).format('DD MMM BBBB')}`}
                </TableCell>
                <TableCell>
                  {`${dayjs(row?.end_date).format('DD MMM BBBB')}`}
                </TableCell>
                <TableCell>{row?.running_number}</TableCell>
                <TableCell>
                  <div className="flex flex-col gap-2 ">
                    <div className="flex justify-center font-bold">
                      {row?.schedule_type}
                    </div>
                    <div>{showVehicleScheduleStatus({ ...row })}</div>
                  </div>
                </TableCell>
                <TableCell>
                  {!_.isEmpty(row?.delivery_orders) ? (
                    _.map(row?.delivery_orders, (order, index) => (
                      <div className="mb-2">
                        <div>
                          {`${index + 1}. `}
                          <b>{order?.invoice_number}</b>
                          {'  '}
                          {order?.job?.name}
                        </div>

                        <div>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              handlePrintPdfDeliveryOrder(order, row);
                            }}
                          >
                            พิมพ์
                          </Button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div> - </div>
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex flex-col gap-2 ">
                    <div>{`ชื่อ: ${row?.driver?.employee?.firstname}`}</div>
                    <div>{`สกุล: ${row?.driver?.employee?.lastname}`}</div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col gap-2 ">
                    <div>{`หัวรถ: ${row?.vehicle?.licence_number}` || '-'}</div>
                    <div>
                      {`หางรถ: ${row?.trailer_tail?.licence_number}` || '-'}
                    </div>
                    <div>
                      {`ตู้คอนเทนเนอร์: ${row?.container?.licence_number}` ||
                        '-'}
                    </div>
                  </div>
                </TableCell>
                <TableCell>{row?.seal_number || '-'}</TableCell>
                <TableCell>
                  <div className="flex flex-col gap-1">
                    <Button
                      variant="outlined"
                      size="small"
                      className="text-white"
                      onClick={() => handleToInfoPage(row?._id)}
                    >
                      รายละเอียด
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={handleChangePage}
    />
  </Paper>
);

VehicleScheduleHistoryTable.propTypes = {
  handleChangeRowsPerPage: PropTypes.func,
  vehicleSchedule: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleToInfoPage: PropTypes.func,
  handlePrintPdfDeliveryOrder: PropTypes.func,
};

export default VehicleScheduleHistoryTable;
