import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { DeliveryOrderSelectorTable } from '../../components/Table';
import { VehicleScheduleForm } from '../../components/Forms';
import { ASSIGNMENT_TYPE, CONFIG } from '../../utils/constants';

const CreateVehicleSchedule = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const deliveryOrders = useSelector((state) => state.deliveryOrders);
  const vehicle = useSelector((state) => state.vehicle);
  const trailerTail = useSelector((state) => state.trailerTail);
  const container = useSelector((state) => state.container);
  const driver = useSelector((state) => state.driver);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [selectAssignmentType, setSelectAssignementType] = useState(
    ASSIGNMENT_TYPE.IMPORT.status_code,
  );

  const [selectedDeliveryOrder, setSelectedDeliveryOrder] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({});

  const onSubmit = async (data) => {
    try {
      await dispatch(
        actions.vehicleScheduleCreate({
          ...data,
          delivery_orders: selectedDeliveryOrder,
          schedule_type: selectAssignmentType,
        }),
      );
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert('สร้างการเดินรถไม่สำเร็จ', error?.message);
      console.error(error);
    }
  };

  const getVehicleSchedule = () =>
    dispatch(
      actions.deliveryOrderAll({
        name,
        page,
        size,
        ready: true,
        assignmentType: selectAssignmentType,
      }),
    );

  const getAllVehicle = async () => {
    try {
      await dispatch(
        actions.vehicleAll({ name: '', page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      console.error('Error on Fetch Vehicle', error);
    }
  };

  const getAllTrailerTails = async () => {
    try {
      await dispatch(
        actions.trailerTailAll({
          name: '',
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Error on Fetch Trailer Tail', error);
    }
  };

  const getAllContainers = async () => {
    try {
      await dispatch(
        actions.containerAll({
          name: '',
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Error on Fetch  Container', error);
    }
  };

  const getAllDrivers = async () => {
    try {
      await dispatch(
        actions.driverAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
    } catch (error) {
      console.error('Error on Fetch Driver', error);
    }
  };

  useEffect(() => {
    getVehicleSchedule();
    return () => {};
  }, [name, page, size, selectAssignmentType]);

  useEffect(() => {
    setTotal(deliveryOrders?.total);
    return () => {};
  }, [deliveryOrders]);

  useEffect(() => {
    getAllContainers();
    getAllTrailerTails();
    getAllVehicle();
    getAllDrivers();

    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (deliveryOrders.isLoading) {
    return <Loading />;
  }

  if (!deliveryOrders.isLoading && deliveryOrders.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-2/3 px-2">
            <div className="flex flex-wrap justify-between">
              <div className="font-semibold text-lg my-2">รายการจัดส่ง</div>
              <div className="w-1/2 lg:w-1/4">
                <FormControl fullWidth size="small">
                  <InputLabel>ประเภทการเดินรถ</InputLabel>
                  <Select
                    fullWidth
                    label="ประเภทการเดินรถ"
                    value={selectAssignmentType}
                    onChange={(event) =>
                      setSelectAssignementType(event.target.value)
                    }
                    size="small"
                    disabled={_.size(selectedDeliveryOrder) !== 0}
                  >
                    <MenuItem value={ASSIGNMENT_TYPE.EXPORT.status_code}>
                      {ASSIGNMENT_TYPE.EXPORT.description}{' '}
                    </MenuItem>
                    <MenuItem value={ASSIGNMENT_TYPE.IMPORT.status_code}>
                      {ASSIGNMENT_TYPE.IMPORT.description}{' '}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <DeliveryOrderSelectorTable
              deliveryOrders={deliveryOrders}
              handleChangePage={(event, newPage) => setPage(newPage + 1)}
              handleChangeRowsPerPage={(event) => {
                setSize(event.target.value);
                setPage(1);
              }}
              page={page}
              size={size}
              total={total}
              selectedDeliveryOrder={selectedDeliveryOrder}
              setSelectedDeliveryOrder={setSelectedDeliveryOrder}
            />
          </div>
          <div className="w-full lg:w-1/3 px-2 my-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <VehicleScheduleForm
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                vehicles={vehicle}
                trailerTails={trailerTail}
                containers={container}
                drivers={driver}
                selectAssignmentType={selectAssignmentType}
              />
              <div className="flex justify-end my-2">
                <Button variant="contained" type="submit">
                  สร้าง
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
};

CreateVehicleSchedule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateVehicleSchedule.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateVehicleSchedule;
