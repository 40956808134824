import React from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

const EditModePricingForm = ({ control }) => {
  const { fields: exportField } = useFieldArray({
    control,
    name: 'export_cost_matrix',
  });
  const { fields: importField } = useFieldArray({
    control,
    name: 'import_cost_matrix',
  });

  const renderPricingCostMatrix = (shippingType, fields) =>
    _.map(fields, (eachPrice, priceIndex) => (
      <div className="flex my-2 " key={eachPrice.id}>
        <div className="w-1/4 px-2">
          <Controller
            name={`${shippingType}_cost_matrix[${priceIndex}].gas_price`}
            control={control}
            defaultValue={eachPrice}
            render={({ field }) => (
              <TextField {...field} size="small" label="ราคาน้ำมัน" disabled />
            )}
          />
        </div>
        <div className="w-3/4 px-2">
          <Controller
            name={`${shippingType}_cost_matrix[${priceIndex}].price`}
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField {...field} size="small" label="ราคา" />
            )}
          />
        </div>
      </div>
    ));

  return (
    <div>
      {' '}
      <Card>
        <CardContent>
          <div className="my-2">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField {...field} size="small" label="ชื่อโซน" fullWidth />
              )}
            />
          </div>{' '}
          <div className="text-lg font-semibold">นำเข้า</div>
          <div className="grid grid-cols-2 lg:grid-cols-4">
            {renderPricingCostMatrix('import', importField)}
          </div>{' '}
          <div className="text-lg font-semibold">ส่งออก</div>
          <div className="grid grid-cols-2 lg:grid-cols-4">
            {renderPricingCostMatrix('export', exportField)}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

EditModePricingForm.propTypes = { control: PropTypes.object };

export default EditModePricingForm;
