import React from 'react';

const showDeliveryOrderStatus = ({ ready, completed, deleted }) => {
  if (deleted) {
    return (
      <div className="bg-red-300 px-2 rounded text-center font-semibold">
        ยกเลิก
      </div>
    );
  }
  if (completed) {
    return (
      <div className="bg-green-300 px-2 rounded text-center font-semibold">
        สำเร็จ
      </div>
    );
  }
  if (ready) {
    return (
      <div className="bg-teal-300 px-2 rounded text-center font-semibold">
        พร้อม
      </div>
    );
  }
  return (
    <div className="bg-blue-300 px-2 rounded text-center font-semibold">
      กำลังดำเนินการ{' '}
    </div>
  );
};

export default showDeliveryOrderStatus;
