import React, { useState } from 'react';
import _ from 'lodash';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Chip,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import { Check } from 'react-feather';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { DatePicker } from '../../Datepicker';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const SelectableBillBookingTable = ({
  billBookings,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  searchDate,
  setSearchDate,
  searchTerm,
  setSearchTerm,
  selectedBooking = [],
  setSelectedBooking,
  reload,
  setReload,
}) => (
  <div>
    <Card>
      <CardContent>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 px-1">
            <TextField
              fullWidth
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              label="ค้นหา"
              size="small"
            />
          </div>
          <div className="w-full lg:w-1/2 px-1">
            <div className="flex ">
              <div className="w-3/4">
                <DatePicker
                  label="วันที่ค้นหา"
                  value={searchDate}
                  setValue={setSearchDate}
                />
              </div>
              <div className="w-1/4">
                <Button
                  color="warning"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setSearchDate('');
                  }}
                >
                  เคลียร์
                </Button>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">วันที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">เลขที่ Booking</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">งาน/ลูกค้า</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">หมายเหตุ</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">เลือก</div>
              </TableCell>{' '}
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(billBookings) ? (
              billBookings.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {(page - 1) * size + index + 1}
                  </TableCell>{' '}
                  <TableCell>
                    {dayjs(row?.date)?.format('D MMM BBBB')}
                  </TableCell>
                  <TableCell>
                    <div className="text-center">{row?.booking_number}</div>
                    <div className="flex justify-center">
                      {row?.completed && (
                        <div className="flex px-1 bg-green-300 rounded-md">
                          <Check size="16" className="self-center mx-1" />
                          <div className="self-center mx-1">ออกบิลแล้ว</div>
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {row?.from_vehicle_schedule ? (
                      <div></div>
                    ) : (
                      <div>
                        <div>
                          {row?.individual_input?.job?.name}{' '}
                          <Chip
                            label={row?.individual_input?.job?.assignment_type}
                            size="small"
                          />{' '}
                        </div>
                        <div>{row?.individual_input?.job?.customer?.name}</div>
                      </div>
                    )}
                  </TableCell>{' '}
                  <TableCell>{row?.remark || ''}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        try {
                          if (_.isArray(selectedBooking)) {
                            selectedBooking.push(row);
                            setSelectedBooking(selectedBooking);
                          } else {
                            setSelectedBooking([row]);
                          }
                          setReload(!reload);
                        } catch (error) {
                          alert('เพิ่มไม่สำเร็จ', error?.message);
                          console.error('Cannot Add Bill Booking', error);
                        }
                      }}
                      disabled={_.includes(selectedBooking, row)}
                    >
                      เพิ่ม
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>{' '}
  </div>
);

SelectableBillBookingTable.propTypes = {
  billBookings: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  searchDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSearchDate: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  selectedBooking: PropTypes.arrayOf(PropTypes.object),
  setSelectedBooking: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
};

export default SelectableBillBookingTable;
