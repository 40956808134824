// TODO: setting what role/department can edit this one
import { MAINTENANCE_ORDER_STATUS } from '../constants';

export const processButtonAllowance = ({ currentProcess, me, information }) => {
  const approveRequire = !information?.setting?.cmms?.automatic_work_accept;

  const approveButtonAllow =
    currentProcess?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code &&
    me?.userData?.role?.level >= 1;

  const workingButtonAllow =
    (!approveRequire &&
      currentProcess?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code) ||
    currentProcess?.status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
    currentProcess?.status === MAINTENANCE_ORDER_STATUS.PENDING.status_code;

  return {
    acceptButton: approveButtonAllow,
    withdrawMaterialButton: workingButtonAllow,
    returnMaterialButton: workingButtonAllow,
    sendWorkButton: workingButtonAllow,
    rejectButton: workingButtonAllow,
  };
};

export default processButtonAllowance;
