/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFieldArray } from 'react-hook-form';
import { TextField, Button, Modal, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { dayjs } from '../../../utils/functions';
import { DatePicker, DateTimePicker } from '../../Datepicker';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const FinishMainatenanceOrderForm = ({ control, errors }) => {
  const information = useSelector((state) => state.information);
  const [detailTopic, setDetailTopic] = useState('');
  const {
    setting: { cmms: cmmsSetting },
  } = information;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [notifie, setNotifie] = useState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'process',
  });
  return (
    <div className="flex flex-wrap">
      <div className="w-full ">
        <div className="w-full px-1 my-2 py-2 font-semibold text-lg">
          วันที่แจ้งซ่อม
        </div>
        <div className="grid grid-cols-2 w-full">
          <div className="w-full  my-2 px-1 flex-wrap">
            <Controller
              name={'date.workorder'}
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  label="วันที่สั่งงาน(ถ้ามี)"
                  value={field.value}
                  setValue={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
          <div className="w-full  my-2 px-1 flex-wrap">
            <Controller
              name={'date.medium'}
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  label="เลื่อนแผ่น(ถ้ามี)"
                  value={field.value}
                  setValue={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-2 w-full">
          <div className="w-full px-1 my-2 flex-wrap">
            <Controller
              name={'end_date'}
              control={control}
              defaultValue={dayjs().format('YYYY-MM-DDTHH:mm')}
              render={({ field }) =>
                cmmsSetting?.attach_time ? (
                  <DateTimePicker
                    label="วันที่ซ่อมเสร็จ"
                    value={field.value}
                    setValue={(value) => field.onChange(value)}
                  />
                ) : (
                  <DatePicker
                    label="วันที่ซ่อมเสร็จ"
                    value={field.value}
                    setValue={(value) => field.onChange(value)}
                  />
                )
              }
            />
          </div>
          <div className="w-full  px-1 my-2 flex-wrap">
            <Controller
              name={'date.success'}
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  label="วันที่เก็บงาน(ถ้ามี)"
                  value={field.value}
                  setValue={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 w-full ">
        <div className="w-full py-2 px-1">
          <Controller
            name={'notifier'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                label="ผู้รับแจ้ง(ถ้ามี)"
                fullWidth
                helperText={errors.modify && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full py-2 px-1 ">
          <Controller
            name={'workrecip'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                label="ผู้รับงาน(ถ้ามี)"
                fullWidth
                helperText={errors.modify && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full  px-1 ">
          <Controller
            name={'approvemaintain'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                label="ผู้อนุมัติ(ถ้ามี)"
                fullWidth
                size={'small'}
                helperText={errors.modify && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 flex-wrap ">
          <Controller
            name={'date_aprove'}
            control={control}
            defaultValue={dayjs().format('YYYY-MM-DDTHH:mm')}
            render={({ field }) => (
              <DatePicker
                label="วันที่อนุมัติ"
                value={field.value}
                setValue={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
      </div>

      {/* <div className=" my-2">
        <FormControl>
          <TextField
            label="หัวข้อการบำรุงรักษา"
            value={detailTopic}
            fullWidth
            onChange={(event) => {
              setDetailTopic(event.target.value);
            }}
          />
        </FormControl>{' '}
      </div> */}
      {/* <DialogActions>
        <Button>เพิ่ม</Button>
      </DialogActions> */}
      <div className="w-full px-1 py-2">
        <Controller
          name={'modify'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={2}
              label="วิธีการแก้ไข"
              fullWidth
              helperText={errors.modify && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'comment'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={2}
              label="ความคิดเห็น(ถ้ามี)"
              fullWidth
              helperText={errors.comment && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <div className="w-full px-1 my-2 py-2 font-semibold text-sm">
          ขั้นตอนการปฏิบัติงาน
        </div>
        {_.map(fields, (eachField, index) => (
          <div className="py-2">
            <div key={eachField.id}>
              <div className="flex">
                <div className="p-2 mt-2  ">{index + 1}</div>
                <Controller
                  name={`process[${index}].operating`}
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ขั้นตอนการปฏิบัติงาน"
                      fullWidth
                    />
                  )}
                />
                <Button type="button" onClick={() => remove(index)}>
                  ลบ
                </Button>
              </div>
            </div>
          </div>
        ))}
        <Button type="button" onClick={() => append({})}>
          {' '}
          เพิ่ม
        </Button>
      </div>
    </div>
  );
};

FinishMainatenanceOrderForm.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
};

export default FinishMainatenanceOrderForm;
