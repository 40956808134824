import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button, Card, CardContent, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import thLocale from 'date-fns/locale/th';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import * as actions from '../../redux/actions';

import { MaterialHistoryTable } from '../../components/Table';

import {
  DetailMaterialHTypeAddReport,
  DetailMaterialHTypeDescReport,
} from '../../components/Report';

function MaterialTransactionContainer({
  vehicleId = '',
  information,
  trailerTailId = '',
}) {
  const dispatch = useDispatch();
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [month, setMonth] = useState(new Date());
  const [monthChang, setMonthChang] = useState();
  const history = useHistory();

  const handleMaterialTransactionAll = async () => {
    if (monthChang) {
      dispatch(
        actions.materialTransactionSearch({
          vehicle: vehicleId,
          trailerTail: trailerTailId,
          page,
          size,
          month: new Date(month).toISOString().split('T')[0],
        }),
      );
    } else {
      dispatch(
        actions.materialTransactionAll({
          page,
          size,
          trailerTail: trailerTailId,
          vehicle: vehicleId,
        }),
      );
    }
  };
  useEffect(() => {
    handleMaterialTransactionAll();
  }, [page, size, vehicleId]);

  useEffect(() => {
    setTotal(materialTransaction?.total);
    return () => {};
  }, [materialTransaction]);

  const handlePrintPdfTypeAdd = async (row) => {
    await DetailMaterialHTypeAddReport(row, information);
  };

  const handlePrintPdfTypeDesc = async (row) => {
    await DetailMaterialHTypeDescReport(row, information);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handlePushToDetailMaterailTransaction = async (id) => {
    history.push(`/ims/history/detail/${id}`);
  };

  const handleSeachMaterialTransaction = () => {
    setMonthChang(month);
    dispatch(
      actions.materialTransactionSearch({
        page,
        size,
        month: new Date(month).toISOString().split('T')[0],
        trailerTail: trailerTailId,
        vehicle: vehicleId,
      }),
    );
  };
  return (
    <div className="my-2">
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="flex gap-2">
              <div className="w-full  md:w-5/12 md:pl-1 my-2 md:my-0">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={thLocale}
                >
                  <DatePicker
                    views={['year', 'month']}
                    label="เดือน"
                    minDate={new Date('2012-03-01')}
                    maxDate={new Date('2030-06-01')}
                    value={month}
                    onChange={(newValue) => {
                      setMonth(newValue);
                      setMonthChang(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} size={'small'} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full md:w-1/12 ml-1 flex justify-items-center my-2 md:my-0">
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => handleSeachMaterialTransaction()}
                >
                  ค้นหา
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <MaterialHistoryTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePrintPdfTypeAdd={handlePrintPdfTypeAdd}
        handlePrintPdfTypeDesc={handlePrintPdfTypeDesc}
        handlePushToDetailMaterailTransaction={
          handlePushToDetailMaterailTransaction
        }
        imsSetting={information?.setting?.ims}
        materialTransaction={materialTransaction}
        page={page}
        size={size}
        total={total}
      />
    </div>
  );
}

export default MaterialTransactionContainer;

MaterialTransactionContainer.propTypes = {
  vehicleId: PropTypes.string,
  information: PropTypes.object,
  trailerTailId: PropTypes.string,
};
