import {
  VEHICLE_ASSIGNMENT_ALL,
  VEHICLE_ASSIGNMENT_GET,
  VEHICLE_ASSIGNMENT_DEL,
  VEHICLE_ASSIGNMENT_PUT,
  VEHICLE_ASSIGNMENT_POST,
  VEHICLE_ASSIGNMENT_LOADING,
  VEHICLE_ASSIGNMENT_ERROR,
} from '../../actions/types';

const initialState = {
  vehicle_ASSIGNMENTAssignment: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line default-param-last
export default function VehicleAssignmentReducer(state = initialState, action) {
  switch (action.type) {
    case VEHICLE_ASSIGNMENT_LOADING:
      return { isLoading: true, isCompleted: true };
    case VEHICLE_ASSIGNMENT_ERROR:
      return { isLoading: false, isCompleted: false };
    case VEHICLE_ASSIGNMENT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case VEHICLE_ASSIGNMENT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case VEHICLE_ASSIGNMENT_POST:
      return { isLoading: false, isCompleted: true };
    case VEHICLE_ASSIGNMENT_PUT:
      return { isLoading: false, isCompleted: true };
    case VEHICLE_ASSIGNMENT_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
