/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import WheelListTrailerTable from '../../components/Table/WMS/WheelListTrailerTable';

function TrailerList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const WheelTrailer = useSelector((state) => state.WheelTrailerTail);

  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const tableProps = {
    name,
    page,
    size,
    setName,
    setPage,
    setSize,
    handleDelete: async (id) => {
      console.log('id', id);
      try {
        const confirm = window.confirm('ยืนยันลบข้อมูล');
        if (confirm) {
          await dispatch(actions.wheelTrailerTailAll({}));
          await dispatch(actions.wheelTrailerTailDelete(id));
        }
      } catch (error) {
        alert('เกิดข้อผิดพลาด', error?.message);
      }
    },
    handleEdit: (id) => history.push(`/wms/trailertail/info/${id}`),
  };

  useEffect(() => {
    dispatch(actions.wheelTrailerTailAll({}));

    return () => {};
  }, [name, page, size]);

  const renderButton = () => (
    <div className="py-4">
      <Button
        variant="contained"
        onClick={() => history.push('/wms/trailer/create')}
      >
        เพิ่ม
      </Button>
    </div>
  );
  const renderTable = () => (
    <WheelListTrailerTable WheelTrailer={WheelTrailer} {...tableProps} />
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-end">{renderButton()}</div>
      {renderTable()}
    </div>
  );
}

TrailerList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default TrailerList;
