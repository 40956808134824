import _ from 'lodash';

export const materialOnProcessAnalyze = (processMaterialArray) => {
  const materialOnGroup = _.values(
    _.groupBy(processMaterialArray, 'material._id'),
  );

  const materialOnGroupInfo = _.map(materialOnGroup, (_materialOnGroup) => {
    // eslint-disable-next-line no-confusing-arrow
    const materialAmountList = _.map(
      _materialOnGroup,
      (eachMaterialOnGroup) =>
        // eslint-disable-next-line no-unsafe-optional-chaining
        (eachMaterialOnGroup?.quantity || 0) *
        (eachMaterialOnGroup?.direction === true ? 1 : -1),
    );

    const materialInfo = _.find(
      _materialOnGroup,
      (eachMaterialOnGroup) =>
        eachMaterialOnGroup?.material_stock_lot !== undefined,
    );

    return {
      materialInfo: materialInfo?.material,
      material_stock_lot: materialInfo?.material_stock_lot,
      amount: _.sum(materialAmountList),
    };
  });

  // Filter Function Go Herer

  return materialOnGroupInfo;
};

export default { materialOnProcessAnalyze };
