import React, { useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from 'react-feather';

export function MaterialAndStockForm({
  errors,
  material,
  control,
  materialType,
  place,
  initialMaterialName,
  customer,
  watch,
  customerType,
}) {
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;
  const [isAddToStock, setIsAddToStock] = useState(true);
  return (
    <div className="flex flex-row flex-wrap w-full">
      <div className="font-semibold">ข้อมูลวัสดุ</div>
      {imsSetting?.attribute?.type_code && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'type_code'}
            control={control}
            defaultValue={material ? material.type_code : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รหัสวัสดุ"
                fullWidth
                size={'small'}
                helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      <div className="w-full  px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={initialMaterialName || ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อวัสดุ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={material ? material.unit : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยวัสดุ"
              fullWidth
              size={'small'}
              helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'material_type'}
          control={control}
          defaultValue={materialType?.[0]}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <Autocomplete
                {...field}
                fullWidth
                size="small"
                options={materialType}
                autoHighlight
                getOptionLabel={(option) => `${option?.name}`}
                renderOption={(props, option) => (
                  <MenuItem {...props}>{option?.name} </MenuItem>
                )}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="ประเภทวัสดุ"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        />
      </div>

      {imsSetting?.attribute?.register_number && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'register_number'}
            control={control}
            defaultValue={material ? material.register_number : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขทะเบียน / Serial Number"
                fullWidth
                size={'small'}
                helperText={errors.register_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}

      {customer && !_.isEmpty(customer) && (
        <div className="w-full  px-1 py-2">
          {!watch('create_new_supplier') && (
            <Controller
              name={'supplier'}
              control={control}
              defaultValue={undefined}
              rules={{ required: false }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  size="small"
                  defaultValue={field.value}
                  options={customer}
                  autoHighlight
                  getOptionLabel={(option) => `${option?.name}`}
                  renderOption={(props, option) => (
                    <MenuItem {...props}>{option?.name} </MenuItem>
                  )}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="คู่ค้า"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )}
            />
          )}
          <Controller
            name="create_new_supplier"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                label="สร้างคู่ค้าใหม่"
                control={<Checkbox {...field} />}
              />
            )}
          />{' '}
          <div className="w-full flex flex-wrap">
            <div className="w-full lg:w-1/2 px-1">
              {watch('create_new_supplier') && (
                <Controller
                  name="created_supplier"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="ชื่อคู่ค้า"
                      fullWidth
                    />
                  )}
                />
              )}
            </div>{' '}
            <div className="w-full lg:w-1/2 px-1">
              {watch('create_new_supplier') && (
                <Controller
                  name="created_supplier_type"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>ประเภทคู่ค้า</InputLabel>
                      <Select size="small" {...field} label="ประเภทคู่ค้า">
                        {_.map(
                          customerType?.rows,
                          (eachCustomerType, index) => (
                            <MenuItem key={index} value={eachCustomerType?._id}>
                              {index + 1}. {eachCustomerType?.name}{' '}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="w-full  px-1 py-2">
        <Controller
          name={'quantity'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวน"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name="use_price_per_unit"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field?.value}
                  {...field}
                  onChange={(event) => field.onChange(event.target.checked)}
                />
              }
              label="ใส่เป็นราคาต่อหน่วยแทน"
            />
          )}
        />
      </div>

      <div className="w-full  px-1 ">
        {watch('use_price_per_unit') ? (
          <Controller
            name={'price_per_unit'}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="ราคาต่อหน่วย"
                fullWidth
                size={'small'}
              />
            )}
          />
        ) : (
          <Controller
            name={'price'}
            control={control}
            render={({ field }) => (
              <TextField {...field} label="ราคารวม" fullWidth size={'small'} />
            )}
          />
        )}
      </div>

      <div className="w-full  px-1 py-2">
        <FormControlLabel
          control={
            <Checkbox
              checked={isAddToStock}
              onChange={() => setIsAddToStock(!isAddToStock)}
            />
          }
          label="เพิ่มลงในคลังวัสดุ"
        />
      </div>

      {isAddToStock && (
        <div className="w-full">
          <div className="w-full px-1 py-2">
            <Controller
              name={'place'}
              control={control}
              defaultValue={place?.[0]}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    คลังวัสดุ
                  </InputLabel>
                  <Select {...field} label="คลังวัสดุ" size={'small'} fullWidth>
                    {_.size(place) ? (
                      _.map(place, (row) => (
                        <MenuItem key={row.id} value={row._id}>
                          {row.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}

MaterialAndStockForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    net: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    minimum_amount: PropTypes.object,
    register_number: PropTypes.object,
  }),
  material: PropTypes.object,
  materialType: PropTypes.object,
  control: PropTypes.object,
  initialMaterialName: PropTypes.string,
  place: PropTypes.arrayOf(PropTypes.object),
  customer: PropTypes.object,
  watch: PropTypes.func,
  customerType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

MaterialAndStockForm.defaultProps = {
  material: null,
  materialType: null,
};

export default MaterialAndStockForm;
