import {
  BILL_BOOKING_ALL,
  BILL_BOOKING_GET,
  BILL_BOOKING_PUT,
  BILL_BOOKING_DELETE,
  BILL_BOOKING_POST,
  BILL_BOOKING_RESET,
  BILL_BOOKING_LOADING,
  BILL_BOOKING_ERROR,
} from '../../actions/types';

const initialState = {
  billBooking: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function BillBookingReducer(state = initialState, action) {
  switch (action.type) {
    case BILL_BOOKING_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BILL_BOOKING_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BILL_BOOKING_POST:
      return { isLoading: false, isCompleted: true };
    case BILL_BOOKING_PUT:
      return { isLoading: false, isCompleted: true };
    case BILL_BOOKING_DELETE:
      return { isLoading: false, isCompleted: true };
    case BILL_BOOKING_RESET:
      return { BILL_BOOKING: null, isLoading: true, isCompleted: true };
    case BILL_BOOKING_LOADING:
      return { isLoading: true, isCompleted: true };
    case BILL_BOOKING_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
