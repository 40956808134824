/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';
import currencyFormatter from '../../utils/functions/currencyFormat';
import floatingCurrencyFormatter from '../../utils/functions/floatingCurrencyFormater';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

// For Suphasin Logistic
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

function printPDF(order, info, me, maintenanceTopic) {
  const {
    setting: { cmms: cmmsSetting },
  } = info;
  console.log('order', order);

  const renderDetail = () => {
    return _.map(order?.material_stock_lot, (each, index) => [
      {
        text: index + 1,
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: each?.lotId?.material?.type_code,
        colSpan: 1,
        border: [false, false, false, false],
      },
      {
        text: each?.lotId?.material?.name,
        colSpan: 1,
        border: [false, false, false, false],
      },
      {
        text: each?.amount,
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: `${
          each?.lotId?.price?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
          ''
        }`,
        colSpan: 1,
        alignment: 'right',
        border: [false, false, false, false],
      },
      {
        text: `${
          (each?.amount * each?.lotId?.price)
            ?.toFixed(2)
            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || ''
        }`,
        colSpan: 1,
        alignment: 'right',
        border: [false, false, false, false],
      },
    ]);
  };

  const genMaintenanceTopic = () => {
    return {
      style: 'tableExample',
      margin: [40, -4, 30, 0],
      fontSize: 11,
      table: {
        widths: ['8%', '19%', '29%', '15%', '15%', '19%'],
        body: [...renderDetail()],
      },
    };
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 300, 40, 240],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบตรวจรับของ',
    },
    header: [
      {
        text: '',
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 48, 0, 12],
        text: '',
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: '',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        text: '',
      },
      {
        style: 'tableExample',
        margin: [45, 20, 10, 2],
        fontSize: 10,
        table: {
          widths: ['8%', '52%', '8%', '32%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },

              {
                text: ` ${dayjs(order?.start_date).format('D MMMM  BBBB')}`,
                margin: [0, 0, 0, 0],
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.supplier?.name || ''
                }`,
                colSpan: 1,
                margin: [85, 18, 0, 0],
                border: [false, false, false, false],
              },
              { text: '', colSpan: 1, border: [false, false, false, false] },
              {
                text: '111',
                colSpan: 1,
                margin: [50, 10],
                alignment: 'right',
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              //ที่อยู่
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },

              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.bill_number ? '/' : ''
                } `,
                colSpan: 1,
                alignment: 'right',
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.bill_number || ''
                } `,
                margin: [60, 0, 0, 0],
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.bill_pickeup_number
                    ? '/'
                    : ''
                }`,
                colSpan: 1,
                alignment: 'right',
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.bill_pickeup_number ||
                  ''
                }`,
                margin: [60, 0, 0, 0],
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.supplier?.credit_term
                    ? ''
                    : '/'
                }`,
                colSpan: 1,
                alignment: 'right',
                margin: [0, 4, 0, 0],
                bold: true,
                border: [false, false, false, false],
              },
              { text: '', colSpan: 1, border: [false, false, false, false] },
              {
                text: '',
                colSpan: 1,
                alignment: 'right',
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.supplier?.credit_term
                    ? '/'
                    : ''
                }`,
                colSpan: 1,
                alignment: 'right',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.material_stock_lot?.[0]?.lotId?.supplier
                    ?.credit_term || ''
                }`,
                colSpan: 1,
                margin: [55, 0],
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                margin: [0, 0],
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                alignment: 'right',
                bold: true,
                border: [false, false, false, false],
              },
              { text: '', colSpan: 1, border: [false, false, false, false] },
              {
                text: '',
                colSpan: 1,
                alignment: 'right',
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        margin: [20, 5, 40, 0],

        fontSize: 11,
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: '',
                colSpan: 2,
                bold: true,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
    ],
    // footer(currentPage, pageCount) {
    //   return [
    //     {
    //       text: `${currentPage.toString()} of ${pageCount}`,
    //       alignment: 'right',
    //       margin: [10, 10, 10, 10],
    //       fontSize: '10',
    //     },
    //   ];
    // },

    content: [genMaintenanceTopic()],
    footer: [
      {
        style: 'tableExample',
        margin: [65, -15, 40, 0],
        fontSize: 11,

        table: {
          widths: ['100%'],
          heights: [10, 40],
          body: [
            [
              {
                text: order?.cause || '',
                colSpan: 1,
                bold: true,
                margin: [40, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                margin: [40, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [65, 20, 40, 10],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `      ${dayjs(order?.start_date).format(' D / M / BB')}`,
                colSpan: 1,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: `      ${dayjs(order?.createdAt).format(' D / M / BB')}`,
                colSpan: 1,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 0, 10, 30],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['10%', '*'],
          body: [
            [
              {
                text: '',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '   ',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
    ],
  };

  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
