import {
  WHEEL_TRANSECTION_ALL,
  WHEEL_TRANSECTION_GET,
  WHEEL_TRANSECTION_POST,
  WHEEL_TRANSECTION_PUT,
  WHEEL_TRANSECTION_DELETE,
  WHEEL_TRANSECTION_LOADING,
  WHEEL_TRANSECTION_ERROR,
} from '../../actions/types';

const initialState = {
  wheelTransection: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case WHEEL_TRANSECTION_LOADING:
      return { isLoading: true, isCompleted: false };
    case WHEEL_TRANSECTION_ERROR:
      return { isLoading: false, isCompleted: false };
    case WHEEL_TRANSECTION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WHEEL_TRANSECTION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WHEEL_TRANSECTION_POST:
      return { isLoading: false, isCompleted: true };
    case WHEEL_TRANSECTION_PUT:
      return { isLoading: false, isCompleted: true };
    case WHEEL_TRANSECTION_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
