import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { CONFIG, PROCESS_TXN_TYPE } from '../../utils/constants';
import { materialOnProcessAnalyze } from '../../utils/functions';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { MaintenanceOrderCard, MaterialCard } from '../../components/Card';

import {
  DecreaseMaterialForm,
  OnProcessMaterialDecreaseForm,
} from '../../components/Forms';

/**
 * @function MaterialWithdraw
 * @description Withdraw Material inside Process
 * @returns React.Component
 * @author @theethawat
 */
function MaterialWithdraw({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );

  const customer = useSelector((state) => state.customer);
  const materialType = useSelector((state) => state.materialType);
  const me = useSelector((state) => state.me);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const processMaterials = useSelector((state) => state.processMaterials);
  const customerType = useSelector((state) => state.customerType);

  const [filterMaterial, setFilterMaterial] = useState([]);
  const [filterPlace, setFilterPlace] = useState();
  const [selectedMaterialLot, setSelectedMaterialLot] = useState([]);
  const analyzedMaterials = materialOnProcessAnalyze(processMaterials?.rows);

  const { control, handleSubmit } = useForm();

  // Get Transaction of Withdraw and Return of Material
  const getProcessMaterial = async () => {
    try {
      dispatch(
        actions.processMaterialAll({
          maintenanceOrder: params.id,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.log('Get Process Material Error', error);
    }
  };

  const getmaintenanceOrder = async () => {
    try {
      dispatch(actions.maintenanceOrderGet(params.id));
    } catch (error) {
      console.log('Get process Error', error);
    }
  };

  useEffect(() => {
    getmaintenanceOrder();
    getProcessMaterial();
    dispatch(actions.informationAll());
  }, [params]);

  useEffect(() => {
    dispatch(actions.materialAll({}));
    dispatch(actions.placeAll({}));
    dispatch(actions.materialTransactionTypeAll({}));
    dispatch(actions.materialTypeAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.customerAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.customerTypeAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.materialTransactionTypeAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    if (place?.rows) {
      setFilterPlace(place?.rows?.[0]?._id);
    }
    return () => {};
  }, [place]);

  const handleCreateMaterial = async (data) => {
    try {
      await dispatch(actions.materialInsertOneWithStocklot(data));
      dispatch(actions.materialAll({}));
    } catch (error) {
      alert('ไม่สามารถสร้างวัสดุใหม่ได้', error?.message);
    }
  };

  const handleAddStockMaterial = async (data, event) => {
    console.log('Data', data);
    try {
      dispatch(actions.matrialReset());

      const stockPayload = {
        ...data,
        status: 'IN_STOCK',
        material_transaction_type: _.find(
          materialTransactionType?.rows,
          (eachMT) => eachMT?.direction === 'add',
        ),
        recording_date: dayjs().toDate(),
        purchase_date: dayjs().toDate(),
        receipt_date: dayjs().toDate(),
        issue_date: dayjs().toDate(),
        remark: 'เพิ่มในกระบวนการ ก่อนที่จะเบิกวัสดุ',
        recipient_name: `${me?.userData?.firstname || ' '}  ${
          me?.userData?.lastname || ' '
        }`,
      };
      const materialStockpayload = {
        ...data,
        amount: data.quantity,
        status: 'IN_STOCK',
      };
      await dispatch(
        actions.materialStockLotCreate({
          ...stockPayload,
          material: [materialStockpayload],
        }),
      );

      dispatch(actions.materialAll({}));
    } catch (error) {
      alert('ไม่สามารถสร้างวัสดุใหม่ได้', error?.message);
    }
  };

  const onSubmit = async (data) => {
    try {
      const confirm = window.confirm('ยืนยันการเบิกสินค้า');
      if (confirm) {
        if (!_.isEmpty(selectedMaterialLot)) {
          data.materialLots = selectedMaterialLot;
          data.process_number = params.id;
          const processMaterialPayload = {
            maintenance_order: params.id,
            transaction_type: PROCESS_TXN_TYPE.MATERIAL_WITHDRAW.status_code,
            employee: me?.userData?._id,
            direction: true,
            materialArrays: _.map(selectedMaterialLot, (_materialLot) => ({
              material: _materialLot?.material?._id,
              material_stock_lot: _materialLot?.lotId,
              begin: '',
              quantity: parseFloat(_materialLot?.quantity),
              amount: '',
            })),
          };

          // console.log('Data', data);
          // console.log('Process Material payload', processMaterialPayload);
          await dispatch(
            actions.materialStockLotDecrease({
              ...data,
              request_name: `${me?.userData?.firstname || ' '}  ${
                me?.userData?.lastname || ' '
              }`,
              employee: me?.userData?._id,
            }),
          );
          await dispatch(actions.processMaterialCreate(processMaterialPayload));
          alert('สำเร็จ');
          history.goBack();
        }
      }
    } catch (error) {
      alert('เกิดข้อผิดพลาดในการเบิกวัสดุ', error?.message);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  const renderMaintenanceOrder = () => (
    <div>
      <MaintenanceOrderCard
        maintenanceOrder={maintenanceOrder}
        showDate={false}
        showMaintanenceWithdraw={true}
        showDriver={false}
        showStatus={false}
        showImages={false}
      />
    </div>
  );

  const renderForm = () => (
    <div className="my-2">
      <form>
        <OnProcessMaterialDecreaseForm
          control={control}
          materialTransactionType={materialTransactionType}
        />
        <DecreaseMaterialForm
          filterMaterial={filterMaterial}
          filterPlace={filterPlace}
          material={material?.rows}
          selectMaterialLot={selectedMaterialLot}
          setFilterPlace={setFilterPlace}
          setSelectMaterialLot={setSelectedMaterialLot}
          setFilterMaterial={setFilterMaterial}
          place={place?.rows}
          type={false}
          materialType={materialType?.rows}
          materialState={material}
          handleCreateMaterial={handleCreateMaterial}
          customer={customer?.rows}
          handleAddStockMaterial={handleAddStockMaterial}
          allowMaterialLotFillIn
          vehicle={
            !_.isEmpty(maintenanceOrder?.vehicle)
              ? { rows: [maintenanceOrder?.vehicle] }
              : null
          }
          trailerTail={
            !_.isEmpty(maintenanceOrder?.trailer_tail)
              ? { rows: [maintenanceOrder?.trailer_tail] }
              : null
          }
          customerType={customerType}
        />
        <div className="w-full flex my-2 justify-end">
          <Button
            variant="contained"
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            ยืนยันการเบิก
          </Button>
        </div>
      </form>
    </div>
  );

  const renderMaterialCard = () => (
    <div>
      <div className="my-2">
        <MaterialCard materialArrays={maintenanceOrder?.process_materials} />
      </div>
    </div>
  );
  if (maintenanceOrder?.isLoading) {
    return <Loading />;
  }
  if (!maintenanceOrder?.isLoading && maintenanceOrder?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderMaintenanceOrder()}
        {renderMaterialCard()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

MaterialWithdraw.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialWithdraw.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialWithdraw;
