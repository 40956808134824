import React from 'react';
import { MAINTENANCE_LEVEL } from '../constants';

const showMaintenanceLevelOnBig = (level) => {
  if (level === MAINTENANCE_LEVEL.SCHEDULE.status_code) {
    return (
      <div className="text-green-500 rounded ">
        {MAINTENANCE_LEVEL.SCHEDULE.description}
      </div>
    );
  }
  if (level === MAINTENANCE_LEVEL.GENERAL.status_code) {
    return (
      <div className="text-yellow-500 rounded ">
        {MAINTENANCE_LEVEL.GENERAL.description}
      </div>
    );
  }
  if (level === MAINTENANCE_LEVEL.ACCIDENT.status_code) {
    return (
      <div className="text-red-500 rounded ">
        {MAINTENANCE_LEVEL.ACCIDENT.description}
      </div>
    );
  }
  if (level === MAINTENANCE_LEVEL.OVERHAUL.status_code) {
    return (
      <div className="text-orange-500 rounded ">
        {MAINTENANCE_LEVEL.OVERHAUL.description}
      </div>
    );
  }
  return <div className="text-gray-300 rounded ">{'-'}</div>;
};

export default showMaintenanceLevelOnBig;
