import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
// Feature
import CustomerReducer from './features/CustomerReducer';
import CustomerTypeReducer from './features/CustomerTypeReducer';
import LocationTypeReducer from './features/LocationTypeReducer';
import LocationReducer from './features/LocationReducer';
import DepartmentReducer from './features/DepartmentReducer';
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import DriverReducer from './features/DriverReducer';
import VehicleTypesReducer from './features/VehicleTypesReducer';
import VehicleReducer from './features/VehicleReducer';
import TrailerTailTypeReducer from './features/TrailerTailTypeReducer';
import TrailerTailReducer from './features/TrailerTailReducer';
import ContainerReducer from './features/ContainerReducer';
import InformationReducer from './features/InformationReducer';
import MaterialTypeReducer from './features/MaterialTypeReducer';

// CMMS
import MaintenanceOrderReducer from './features/MaintenanceOrderReducer';
import MaintenanceTypeReducer from './features/MaintenanceTypeReducer';
import MaintenanceTopicReducer from './features/MaintenanceTopicReducer';
import MaintenanceHistoryReducer from './features/MaintenanceHistoryReducer';
import GarageReducer from './features/GarageReducer';
import MaintenanceNotifyReducer from './features/MaintenanceNotifyReducer';

// TMS
import DeliveryOrderReducer from './features/DeliveryOrderReducer';
import VehicleAssignmentReducer from './features/VehicleAssignmentReducer';
import VehicleScheduleReducer from './features/VehicleScheduleReducer';
// IMS
import MaterialReducer from './features/MaterialReducer';
import MaterialTransactionTypeReducer from './features/MaterialTransactionTypeReducer';
import PlaceReducer from './features/PlaceReducer';
import MaterialTransactionReducer from './features/MaterialTransactionReducer';
import MaterialStockLotReducer from './features/MaterialStockLotReducer';
import MaterialWithdrawReducer from './features/MaterialWithdrawReducer';
import NotifyReducer from './features/NotifyReducer';
import MaterialRequestReducer from './features/MaterialRequestReducer';

// MMS
import MaintenanceProcessReducer from './features/MaintenanceProcessReducer';
import CommentReducer from './features/CommentReducer';
import ProcessTransactionReducer from './features/ProcessTransactionReducer';
import ProcessMaterialReducer from './features/ProcessMaterialReducer';

// JMS
import JobReducer from './features/JobReducer';
import ZonePriceReducer from './features/ZonePriceReducer';

// WMS
import WheelReducer from './features/WheelReducer';
import WheelRegisterReducer from './features/WheelRegisterReducer';
import WheelMaintenanceReducer from './features/WheelMaintenanceReducer';
import WheelMaintenanceHistoryReducer from './features/WheelMaintenanceHistoryReducer';
import WheelTypeReducer from './features/WheelTypeReducer';
import WheelSupplyReducer from './features/WheelSupplyReducer';

// BMS
import BillBookingReducer from './features/BillBookingReducer';
import BillReducer from './features/BillReducer';
import BillHeaderReducer from './features/BillHeaderReducer';
import WheelTransectionReducer from './features/WheelTransectionReducer';

// MECH
import MechineryReducer from './features/MechineryReducer';

// OTHER
import RefuelTransitionReducer from './features/RefuelTransitionReducer';
import MileageTransactionReducer from './features/MileageTransactionReducer';
import AllWorkTransactionReducer from './features/AllWorkTransactionReducer';
import WheelTrailerReducer from './features/WheelTrailerReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  comments: CommentReducer,
  container: ContainerReducer,
  customer: CustomerReducer,
  customerType: CustomerTypeReducer,
  deliveryOrders: DeliveryOrderReducer,
  department: DepartmentReducer,
  driver: DriverReducer,
  employee: EmployeeReducer,
  garage: GarageReducer,
  information: InformationReducer,
  job: JobReducer,
  location: LocationReducer,
  locationType: LocationTypeReducer,
  maintenanceHistorys: MaintenanceHistoryReducer,
  maintenanceNotify: MaintenanceNotifyReducer,
  maintenanceOrders: MaintenanceOrderReducer,
  maintenanceProcesses: MaintenanceProcessReducer,
  maintenanceTopics: MaintenanceTopicReducer,
  maintenanceTypes: MaintenanceTypeReducer,
  material: MaterialReducer,
  materialRequest: MaterialRequestReducer,
  materialStockLot: MaterialStockLotReducer,
  materialTransaction: MaterialTransactionReducer,
  materialTransactionType: MaterialTransactionTypeReducer,
  materialType: MaterialTypeReducer,
  materialWithdraw: MaterialWithdrawReducer,
  notify: NotifyReducer,
  place: PlaceReducer,
  processMaterials: ProcessMaterialReducer,
  processTransactions: ProcessTransactionReducer,
  roletype: RoleTypesReducer,
  trailerTail: TrailerTailReducer,
  trailerTailType: TrailerTailTypeReducer,
  user: UserReducers,
  vehicle: VehicleReducer,
  vehicleAssignments: VehicleAssignmentReducer,
  vehicletype: VehicleTypesReducer,
  vehicleSchedule: VehicleScheduleReducer,
  zonePrice: ZonePriceReducer,
  wheel: WheelReducer,
  WheelRegister: WheelRegisterReducer,
  WheelMaintenance: WheelMaintenanceReducer,
  WheelMaintenanceHistory: WheelMaintenanceHistoryReducer,
  bill: BillReducer,
  billBooking: BillBookingReducer,
  wheelType: WheelTypeReducer,
  wheelSupply: WheelSupplyReducer,
  wheelTransection: WheelTransectionReducer,
  billHeader: BillHeaderReducer,
  mechinery: MechineryReducer,
  RefuelTransition: RefuelTransitionReducer,
  MileageTracsaction: MileageTransactionReducer,
  AllWorkTransaction: AllWorkTransactionReducer,
  WheelTrailerTail: WheelTrailerReducer,
});
export default rootRuducer;
