import {
  TRAILER_TAIL_ALL,
  TRAILER_TAIL_GET,
  TRAILER_TAIL_POST,
  TRAILER_TAIL_PUT,
  TRAILER_TAIL_DEL,
  TRAILER_TAIL_LOADING,
  TRAILER_TAIL_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const trailerTailCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: TRAILER_TAIL_LOADING });
    if (payload?.file) {
      const formData = new FormData();
      formData.append('file', payload?.file);
      const { data: uploadData } = await api.post(
        `${process.env.REACT_APP_API_URL}/upload/file`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/trailer-tail`,
        {
          ...payload,
          file: uploadData?.id,
        },
      );
      dispatch({ type: TRAILER_TAIL_POST, payload: data });
    } else {
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/trailer-tail`,
        {
          ...payload,
        },
      );
      dispatch({ type: TRAILER_TAIL_POST, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: TRAILER_TAIL_ERROR });
  }
};

export const trailerTailAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, statusTail = false } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/trailer-tail?licence_number=${name}&size=${size}&page=${page}&statusTail=${statusTail}`,
    );
    if (status === 200) {
      dispatch({ type: TRAILER_TAIL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: TRAILER_TAIL_ERROR });
  }
};

export const trailerTailGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/trailer-tail/${id}`,
    );
    if (status === 200) {
      dispatch({ type: TRAILER_TAIL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: TRAILER_TAIL_ERROR });
  }
};

export const trailerTailPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: TRAILER_TAIL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/trailer-tail/${id}`,
      payload,
    );
    dispatch({ type: TRAILER_TAIL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: TRAILER_TAIL_ERROR });
  }
};

export const trailerTailDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRAILER_TAIL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/trailer-tail/${id}`,
    );
    dispatch({ type: TRAILER_TAIL_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: TRAILER_TAIL_ERROR });
  }
};
