import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Card,
  CardContent,
  TextField,
  Autocomplete,
} from '@mui/material';
import _ from 'lodash';
import { Controller, useFieldArray } from 'react-hook-form';

import { dayjs } from '../../../utils/functions';
import { DatePicker } from '../../Datepicker';

const BillForm = ({
  selectedBooking,
  setSelectedBooking,
  control,
  customer,
  errors,
  reload,
  setReload,
  handleChangeBillBookingInComplete,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additional_price',
  });
  const renderSelectedBookingTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>วันที่</TableCell>
                <TableCell>งาน/ลูกค้า</TableCell>
                <TableCell>หมายเหตุ</TableCell>
                <TableCell>ลบ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                _.filter(
                  selectedBooking,
                  (eachBooking) => !_.isEmpty(eachBooking),
                ),
                (eachBooking, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {dayjs(eachBooking?.date).format('D MMM BBBB')}{' '}
                    </TableCell>

                    <TableCell>
                      {' '}
                      {eachBooking?.from_vehicle_schedule ? (
                        <div></div>
                      ) : (
                        <div>
                          <div className="font-bold">
                            หมายเลข Booking {eachBooking?.booking_number}
                          </div>
                          <div>
                            {eachBooking?.individual_input?.job?.name}{' '}
                            <Chip
                              label={
                                eachBooking?.individual_input?.job
                                  ?.assignment_type
                              }
                              size="small"
                            />{' '}
                          </div>
                          <div>
                            {eachBooking?.individual_input?.job?.customer?.name}
                          </div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{eachBooking?.remark}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          try {
                            const foundedIndex = _.findIndex(
                              selectedBooking,
                              (eachFoundBooking) =>
                                eachFoundBooking?._id === eachBooking?._id,
                            );

                            console.log(
                              'Founded Index to delete',
                              foundedIndex,
                            );

                            if (eachBooking?.alreadyCreated) {
                              const confirm = window.confirm(
                                'ยีนยันการลบรายการออกบิลนี้ออกจากบิล การลบจะมีผลทันที',
                              );
                              if (confirm) {
                                delete selectedBooking[foundedIndex];
                                setSelectedBooking(selectedBooking);
                                setReload(!reload);
                                handleChangeBillBookingInComplete(
                                  eachBooking?._id,
                                  selectedBooking,
                                );
                              }
                            } else {
                              delete selectedBooking[foundedIndex];
                              setSelectedBooking(selectedBooking);
                              setReload(!reload);
                            }
                          } catch (error) {
                            alert('ลบไม่สำเร็จ', error?.message);
                            console.log('Cannot remove booking', error);
                          }
                        }}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <div>
      <div className="font-semibold my-2">รายการที่นำไปออกบิล</div>
      {renderSelectedBookingTable()}
      <div className="font-semibold my-2">รายละเอียดบิล</div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full px-1 py-2">
              <Controller
                control={control}
                name="invoice_number"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="เลขที่บิล"
                  />
                )}
              />
            </div>
            <div className="w-full px-1 py-2">
              <Controller
                control={control}
                name="date"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="วันที่ออกบิล"
                    setValue={(value) => field.onChange(value)}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div className="w-full px-1 py-2">
              {!_.isEmpty(customer?.rows) && (
                <Controller
                  control={control}
                  name="customer"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(e, value) => {
                        field.onChange(value);
                      }}
                      name={field.name}
                      size="small"
                      ref={field.ref}
                      defaultValue={field.value}
                      options={customer?.rows}
                      autoHighlight
                      getOptionLabel={(option) => `${option?.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="ลูกค้า"
                          required
                          inputProps={{
                            ...params.inputProps,
                          }}
                          helperText={errors?.customer && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  )}
                />
              )}
            </div>

            <div className="w-full px-1 py-2">
              <div className="text-base font-semibold my-1">
                ค่าใช้จ่ายเพิ่มเติม
              </div>
              {_.map(fields, (eachField, index) => (
                <div key={eachField?.id} className="flex w-full my-1 ">
                  <div className="w-7/12 px-1">
                    <Controller
                      control={control}
                      name={`additional_price[${index}].title`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="หัวข้อ"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div className="w-3/12 px-1">
                    <Controller
                      control={control}
                      name={`additional_price[${index}].price`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ราคา"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div className="w-2/12 px-1">
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </div>
                </div>
              ))}
              <div className="my-1">
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    append({});
                  }}
                >
                  เพิ่ม
                </Button>
              </div>
            </div>
            <div className="w-full px-1 py-2">
              <Controller
                control={control}
                name="remark"
                render={({ field }) => (
                  <TextField
                    {...field}
                    rows={3}
                    multiline
                    fullWidth
                    label="หมายเหตุ"
                  />
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

BillForm.propTypes = {
  selectedBooking: PropTypes.arrayOf(PropTypes.object),
  setSelectedBooking: PropTypes.func,
  control: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  handleChangeBillBookingInComplete: PropTypes.func,
};

export default BillForm;
