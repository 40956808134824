import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import PropTypes from 'prop-types';

export default function MachineryDetailCard({ machinery }) {
  return (
    <div className="py-2">
      <div>
        <Card>
          <CardContent>
            <div>
              <div className="w-full lg:w-1/4 m-2 px-2">
                <div className="text-3xl">{machinery?.name}</div>
                <div> เลขทะเบียน {machinery?.licence_number}</div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="py-2">
        <Card>
          <CardContent>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
                ยี่ห้อ{' '}
              </div>
              <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                {machinery?.attribute?.brand || '-'}
              </div>
              <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
                รุ่น{' '}
              </div>
              <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                {machinery?.attribute?.generation || '-'}
              </div>
              <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
                วันที่ติดตั้ง
              </div>
              <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                {dayjs(machinery?.attribute?.installation_date).format(
                  'DD MMMM BBBB',
                ) || '-'}
              </div>
              <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
                วันที่เริ่มประกัน
              </div>
              <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                {dayjs(machinery?.attribute?.insurance_start).format(
                  'DD MMMM BBBB',
                ) || '-'}
              </div>
              <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
                วันที่สิ้นสุดประกัน
              </div>
              <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
                {dayjs(machinery?.attribute?.insurance_end).format(
                  'DD MMMM BBBB',
                ) || '-'}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
MachineryDetailCard.propTypes = {
  machinery: PropTypes.object,
};
