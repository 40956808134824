import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import * as actions from '../../redux/actions';
import { MaintenanceOrderForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CONFIG } from '../../utils/constants';
import { MaintenanceTransactionTable } from '../../components/Table';
import { BackButton } from '../../components/Button';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const defaultValue = { licence_number: '', type: '' };

function EditMaintenanceOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const maintenanceTypes = useSelector((state) => state.maintenanceTypes);
  const maintenanceTopics = useSelector((state) => state.maintenanceTopics);
  const information = useSelector((state) => state.information);
  const garage = useSelector((state) => state.garage);
  const driver = useSelector((state) => state.driver);
  const employee = useSelector((state) => state.employee);
  const vehicle = useSelector((state) => state.vehicle);
  const trailerTail = useSelector((state) => state.trailerTail);
  const container = useSelector((state) => state.container);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    resetField,
    reset,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    dispatch(actions.maintenanceOrderReset(params.id));
    return () => {};
  }, []);

  useEffect(() => {
    if (maintenanceOrder?.isLoading) {
      dispatch(actions.maintenanceOrderGet(params.id, { fetchHistory: false }));
    }
    return () => {};
  }, [maintenanceOrder]);

  useEffect(() => {
    dispatch(actions.maintenanceTypeAll({ size: CONFIG.infiniteLimit }));
    dispatch(actions.informationAll());
    dispatch(actions.garageAll({ size: CONFIG.infiniteLimit, page: 1 }));
    dispatch(actions.driverAll({ size: CONFIG.infiniteLimit, page: 1 }));
    dispatch(actions.employeeAll({ size: CONFIG.infiniteLimit, page: 1 }));
    dispatch(actions.vehicleAll({ size: CONFIG.infiniteLimit, page: 1 }));
    dispatch(actions.trailerTailAll({ size: CONFIG.infiniteLimit, page: 1 }));
    dispatch(actions.containerAll({ size: CONFIG.infiniteLimit, page: 1 }));
  }, [params]);

  useEffect(() => {
    setValue('maintenance_topic', maintenanceOrder?.maintenance_topic);
    setValue('driver', maintenanceOrder?.driver);
    setValue('maintenance_level', maintenanceOrder?.maintenance_level);
    setValue('is_self_maintenance', maintenanceOrder?.is_self_maintenance);
    setValue('garage', maintenanceOrder?.garage);
    setValue(
      'start_date',
      dayjs(maintenanceOrder?.start_date).format('YYYY-MM-DDTHH:mm'),
    );
    setValue('cause', maintenanceOrder?.cause);
    if (!_.isEmpty(maintenanceOrder?.mainteners)) {
      setValue('mainteners', maintenanceOrder?.mainteners);
    }
    if (_.isEmpty(maintenanceOrder?.driver)) {
      setValue('no_driver_specify', true);
    }
    if (!_.isEmpty(maintenanceOrder?.driver)) {
      setValue('no_driver_specify', false);
    }

    if (!_.isEmpty(maintenanceOrder?.outer_services)) {
      setValue('outer_services', maintenanceOrder?.outer_services);
    }

    if (!_.isEmpty(maintenanceOrder?.vehicle)) {
      setValue('vehicle', maintenanceOrder?.vehicle);
      setValue('enable_vehicle', true);
    } else {
      setValue('vehicle', null);
      setValue('enable_vehicle', false);
    }

    if (!_.isEmpty(maintenanceOrder?.trailer_tail)) {
      setValue('trailer_tail', maintenanceOrder?.trailer_tail);
      setValue('enable_trailer_tail', true);
    } else {
      setValue('trailer_tail', null);
      setValue('enable_trailer_tail', false);
    }

    if (!_.isEmpty(maintenanceOrder?.container)) {
      setValue('container', maintenanceOrder?.container);
      setValue('enable_container', true);
    } else {
      setValue('container', null);
      setValue('enable_container', false);
    }

    dispatch(
      actions.maintenanceTopicAll({ page: 1, size: CONFIG.infiniteLimit }),
    );
    return () => {};
  }, [maintenanceOrder]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmitConsider = async (data) => {
    try {
      const payload = {
        ...data,
        driver:
          _.isEmpty(data.driver) || watch('no_driver_specify') === true
            ? null
            : data.driver,
        maintenance_topic: _.map(data.maintenance_topic, (_eachTopic) => ({
          ..._eachTopic,
          topic: _eachTopic?.topic?._id || _eachTopic?.topic,
          topic_id: _eachTopic?.topic?._id || _eachTopic?.topic,
          _id: undefined,
        })),
      };

      console.log('maintenance order payload', payload);
      await dispatch(
        actions.maintenanceOrderPut(maintenanceOrder?._id, payload),
      );
      reset(defaultValue);
      alert('สำเร็จ');

      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateMaintenanceTopic = async (maintenanceTopic) => {
    try {
      await dispatch(actions.maintenanceTopicCreate(maintenanceTopic));
      await dispatch(actions.maintenanceTopicAll({}));
    } catch (err) {
      alert(`สร้างหัวข้อการซ่อมบำรุงใหม่ไม่สำเร็จ${err?.message}`);
    }
  };

  const handleCreateGarage = async (data) => {
    try {
      await dispatch(actions.garageCreate(data));
      await dispatch(
        actions.garageAll({ page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      alert(`สร้างอู่ซ่อมบำรุงไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleCreateEmployee = async (data) => {
    try {
      await dispatch(actions.employeeCreate(data));
      await dispatch(
        actions.employeeAll({ page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      alert(`สร้างพนักงไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleCreateContainer = async (data) => {
    try {
      await dispatch(actions.containerCreate(data));
      await dispatch(
        actions.containerAll({ page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      alert(`สร้างตู้คอนเทนเนอร์ไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="my-2 ">
            <form onSubmit={handleSubmit(onSubmitConsider)}>
              <div>
                <MaintenanceOrderForm
                  drivers={driver}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  maintenanceTypes={maintenanceTypes}
                  resetField={resetField}
                  setValue={setValue}
                  showDriver
                  showVehiclePart={true}
                  showImageUpload={false}
                  maintenanceTopics={maintenanceTopics}
                  showPrice={true}
                  showCause={false}
                  maintenanceOrder={maintenanceOrder}
                  showGarage={true}
                  watch={watch}
                  garage={garage}
                  employee={employee}
                  containers={container}
                  vehicles={vehicle}
                  trailerTails={trailerTail}
                  handleCreateMaintenanceTopic={handleCreateMaintenanceTopic}
                  handleCreateGarage={handleCreateGarage}
                  handleCreateEmployee={handleCreateEmployee}
                  handleCreateContainer={handleCreateContainer}
                  showStartDateSelector
                />
              </div>

              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </div>{' '}
        </CardContent>
      </Card>
    </div>
  );

  if (maintenanceOrder.isLoading) {
    return <Loading />;
  }
  if (!maintenanceOrder.isLoading && maintenanceOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

EditMaintenanceOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaintenanceOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditMaintenanceOrder;
