/* eslint-disable radix */
/* eslint-disable react/prop-types */
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  TableBody,
  TablePagination,
  Button,
} from '@mui/material';
import React from 'react';
import _ from 'lodash';

export default function WheelSupplyTable({
  wheelSupply,
  page,
  size,
  setPage,
  setSize,
  handleEdit = () => {},
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value));
    setPage(1);
  };

  return (
    <div>
      <Card>
        <div className="px-2 py-1">
          <TableContainer>
            <Table>
              <colgroup>
                <col width="10%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ยี่ห้อ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ขนาดยาง</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ชนิดยาง</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ดำเนินการ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              {wheelSupply?.total !== 0 ? (
                <TableBody>
                  {_.map(wheelSupply?.rows, (e, index) => (
                    <TableRow key={index}>
                      <TableCell> {index + 1}</TableCell>
                      <TableCell>{e?.brand}</TableCell>
                      <TableCell>{e?.size}</TableCell>
                      <TableCell>{e?.type}</TableCell>

                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => handleEdit(e?._id)}
                        >
                          รายละเอียด
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <h1 className="text-center">ไม่มีรุ่นยาง</h1>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={wheelSupply?.total}
            rowsPerPage={size || 5}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Card>
    </div>
  );
}
