import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import { JobForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CONFIG } from '../../utils/constants';
import { BackButton } from '../../components/Button';

function EditJob({ title, subtitle }) {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job);
  const customer = useSelector((state) => state.customer);
  const zonePrice = useSelector((state) => state.zonePrice);

  const params = useParams();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({ defaultValues: job });

  useEffect(() => {
    dispatch(actions.jobReset());
    dispatch(actions.jobGet(params.id));
    dispatch(actions.customerAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(
      actions.zonePriceAll({
        job: params.id,
        page: 1,
        size: CONFIG.infiniteLimit,
      }),
    );
  }, [params]);

  useEffect(() => {
    if (job && !job.rows) {
      setValue('name', job?.name);
      setValue('customer', job?.customer);
      setValue('destination', job?.destination);
      setValue('is_fixed_cost', job?.is_fixed_cost);
      setValue('fixed_cost', job?.fixed_cost);
      setValue('assignment_type', job?.assignment_type);
      setValue('start', job?.start);
      setValue('zone_price', job?.zone_price);

      console.log('In Set Value assignment type', job?.assignment_type);
    }

    return () => {};
  }, [job]);

  const onSubmitJob = async (data) => {
    try {
      console.log('Job Payload', data);
      await dispatch(actions.jobPut(params.id, data));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );
  const renderForm = () => (
    <div className="my-2 ">
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmitJob)}>
            <JobForm
              control={control}
              customer={customer}
              errors={errors}
              watch={watch}
              job={job}
              zonePrice={zonePrice}
            />
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );

  if (job.isLoading || zonePrice.isLoading) {
    return <Loading />;
  }
  if (
    !job.isLoading &&
    job.isCompleted &&
    !zonePrice.isLoading &&
    zonePrice.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

EditJob.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditJob.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditJob;
