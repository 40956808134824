/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { dayjs, dateDurationGenerator } from '../../utils/functions';

export default function MaintenanceHistorys({ title, subtitle }) {
  const dispatch = useDispatch();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [monthArray, setMonthArray] = useState([]);

  useEffect(() => {
    dispatch(actions.maintenanceOrderAll({ page, size, includeHistory: true }));
    return () => {};
  }, [page, size]);

  useEffect(() => {
    setStartDate(dayjs(maintenanceOrder?.range?.range_first).toDate());
    setEndDate(dayjs(maintenanceOrder?.range?.range_last).toDate());

    return () => {};
  }, [maintenanceOrder]);

  useEffect(() => {
    const dateArray = dateDurationGenerator(startDate, endDate);
    setMonthArray(dateArray);
    return () => {};
  }, [startDate, endDate]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายละเอียด</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(monthArray) ? (
                monthArray.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>{`${dayjs(row).format('MMMM / BBBB')}`}</div>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex gap-2">
                        <Link
                          to={`maintenance-history/detail/${dayjs(row).format(
                            'YYYY-MM',
                          )}`}
                        >
                          <Button variant="outlined" size="small" color="info">
                            รายละเอียด
                          </Button>
                        </Link>
                        <Link
                          to={`monthy-maintenance-analysis/${dayjs(row).format(
                            'YYYY-MM',
                          )}`}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                          >
                            แดชบอร์ดวิเคราะห์
                          </Button>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (maintenanceOrder.isLoading) {
    return <Loading />;
  }
  if (!maintenanceOrder.isLoading && maintenanceOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={maintenanceOrder?.message} />;
}

MaintenanceHistorys.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaintenanceHistorys.defaultProps = {
  title: '',
  subtitle: '',
};
