import React from 'react';
import _ from 'lodash';
import { Avatar, Button, Card, CardContent } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineOppositeContent,
} from '@mui/lab';

import PropTypes from 'prop-types';

import { PROCESS_TXN_TYPE } from '../../utils/constants';
import { dayjs } from '../../utils/functions';

export default function ProcessTimeline({
  processTransaction,
  size,
  setSize,
  showTitle = true,
  container = true,
}) {
  const handleIncrementSize = () => {
    setSize(size + 5);
  };

  const renderTranactionList = () => (
    <div className="my-4 max-h-96 overflow-y-auto">
      {_.size(processTransaction?.rows) === 0 && (
        <div className="p-4 text-center">ยังไม่มีกระบวนการในการซ่อมนี้</div>
      )}
      {size < (processTransaction?.total || 0) && (
        <div>
          <Button onClick={handleIncrementSize}> โหลดเพิ่ม </Button>
        </div>
      )}
      <Timeline position="alternate">
        {_.map(processTransaction?.rows, (_processTransaction, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {dayjs(_processTransaction?.updatedAt).format('D MMM  HH.mm น.')}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <div className="p-2">
                <Avatar
                  src={_processTransaction?.employee?.image?.url}
                  className="uppercase  "
                  sx={{ width: 32, height: 32 }}
                >
                  {_.first(_processTransaction?.employee?.firstname)}
                  {_.first(_processTransaction?.employee?.lastname)}
                </Avatar>
              </div>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <div className="flex flex-col align-center">
                <div className="text-sm">
                  {_processTransaction?.employee?.firstname}{' '}
                  {_processTransaction?.employee?.lastname}{' '}
                </div>

                <div className="font-semibold text-black font-sans">
                  {
                    PROCESS_TXN_TYPE?.[_processTransaction?.transaction_type]
                      ?.description
                  }{' '}
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );

  return (
    <div>
      {container ? (
        <Card>
          <CardContent>
            {showTitle && <div className="font-semibold">รายการงาน</div>}
            {renderTranactionList()}
          </CardContent>
        </Card>
      ) : (
        <div>
          {showTitle && <div className="font-semibold">รายการงาน</div>}
          {renderTranactionList()}
        </div>
      )}
    </div>
  );
}

ProcessTimeline.propTypes = {
  processTransaction: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    currPage: PropTypes.number,
    isLoading: PropTypes.bool,
    isCompleted: PropTypes.bool,
    lastPage: PropTypes.number,
    total: PropTypes.number,
  }),
  size: PropTypes.number,
  showTitle: PropTypes.bool,
  setSize: PropTypes.func,
  container: PropTypes.bool,
};
