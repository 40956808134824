import React, { useState, useRef } from 'react';
import _ from 'lodash';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Trash as DeleteIcon, Image as ImageIcon } from 'react-feather';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import PropTypes from 'prop-types';

import { ImageUpload } from '../../ImageUpload';

dayjs.extend(BuddhistEra);

export default function CommentCard({
  comment,
  size,
  setSize,
  showTitle = true,
  container = true,
  me,
  maintenanceOrder,
  handleDeleteComment,
  handleSubmitComment,
}) {
  const { control, handleSubmit, reset } = useForm();
  const [image, setImage] = useState([]);
  const boxRef = useRef();

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      image: _.isEmpty(image)
        ? undefined
        : { image: image?.[0]?.data_url, imageType: 'comment', alt: '' },
      employee: me?.userData?._id,
      maintenance_order: maintenanceOrder?._id,
    };
    setImage([]);
    reset({ message: '' });
    handleSubmitComment(payload);
  };

  const handleIncrementSize = () => {
    setSize(size + 5);
  };

  const renderCommentLists = () => (
    <div className="mt-2 min-h-96 overflow-y-auto">
      {_.size(comment?.rows) === 0 && (
        <div className="p-4 text-center">ยังไม่มีความคิดเห็น</div>
      )}
      {size < (comment?.total || 0) && (
        <div>
          <Button onClick={handleIncrementSize}> โหลดเพิ่ม</Button>
        </div>
      )}
      {_.map(comment?.rows, (_comment, index) => (
        <div key={index} className="my-2">
          <div className="flex gap-2 ">
            <Avatar src={_comment?.employee?.image?.url} className="uppercase">
              {_.first(_comment?.employee?.firstname)}
              {_.first(_comment?.employee?.lastname)}
            </Avatar>
            <Card>
              <CardContent>
                <div className="text-xs text-gray-500 flex">
                  <div className="self-center">
                    {' '}
                    <span className="font-bold text-black">
                      {_comment?.employee?.firstname}{' '}
                      {_comment?.employee?.lastname}
                    </span>{' '}
                    <br />
                    เมื่อวันที่{' '}
                    {dayjs(_comment?.updatedAt).format(
                      'D MMM BBBB เวลา HH.mm น.',
                    )}
                  </div>
                  <div className="self-center">
                    {_comment?.employee?._id === me?.userData?._id && (
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleDeleteComment(_comment?._id);
                        }}
                      >
                        <DeleteIcon size={12} />
                      </IconButton>
                    )}
                  </div>
                </div>
                <div className="mt-1 font-sansserif">{_comment?.message}</div>
                {_comment?.image && !_.isEmpty(_comment?.image) && (
                  <div className="mt-1">
                    <img src={_comment?.image?.url} className="h-48" />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      ))}
      <div ref={boxRef}></div>
    </div>
  );

  const renderCommentCreate = () => (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="message"
          control={control}
          defaultValue=""
          placeholder="ใส่ข้อความของคุณ"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="ความคิดเห็น"
              fullWidth
              required
            />
          )}
        />
        {_.size(image) > 0 && (
          <div className="my-2 ">
            <Link>
              <div className="flex gap-2">
                <ImageIcon size={16} className="self-center" />
                <Typography className="self-center">
                  {image?.[0]?.file?.name}
                </Typography>
                <IconButton color="error" onClick={() => setImage([])}>
                  <DeleteIcon size={16} />
                </IconButton>
              </div>
            </Link>
          </div>
        )}

        <div className="flex justify-start gap-2 my-2">
          <ImageUpload images={image} setImages={setImage} maxNumber={1} />
        </div>
        <div className="flex justify-end mt-2 gap-2">
          <Button type="submit" color="primary" variant="contained">
            แสดงความเห็น
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {container ? (
        <Card>
          <CardContent>
            {showTitle && <div className="font-semibold ">ความคิดเห็น</div>}
            {renderCommentLists()}
            {renderCommentCreate()}
          </CardContent>
        </Card>
      ) : (
        <div>
          {showTitle && <div className="font-semibold ">ความคิดเห็น</div>}
          {renderCommentLists()}
          {renderCommentCreate()}
        </div>
      )}
    </div>
  );
}

CommentCard.propTypes = {
  comment: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    currPage: PropTypes.number,
    isLoading: PropTypes.bool,
    isCompleted: PropTypes.bool,
    lastPage: PropTypes.number,
    total: PropTypes.number,
  }),
  size: PropTypes.number,
  showTitle: PropTypes.bool,
  setSize: PropTypes.func,
  container: PropTypes.bool,
  me: PropTypes.object,
  handleDeleteComment: PropTypes.func,
  handleSubmitComment: PropTypes.func,
  maintenanceOrder: PropTypes.object,
};
