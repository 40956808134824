import {
    MAINTENANCE_TYPE_ALL,
    MAINTENANCE_TYPE_GET,
    MAINTENANCE_TYPE_PUT,
    MAINTENANCE_TYPE_DEL,
    MAINTENANCE_TYPE_POST,
    MAINTENANCE_TYPE_RESET,
    MAINTENANCE_TYPE_LOADING,
    MAINTENANCE_TYPE_ERROR,
  } from '../../actions/types';
  
  const initialState = {
    maintenanceTypes: null,
    isLoading: true,
    isCompleted: true,
  };
  // eslint-disable-next-line func-names
  export default function (state = initialState, action) {
    switch (action.type) {
      case MAINTENANCE_TYPE_ALL:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case MAINTENANCE_TYPE_GET:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case MAINTENANCE_TYPE_POST:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_TYPE_PUT:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_TYPE_DEL:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_TYPE_RESET:
        return { MAINTENANCE_TYPE: null, isLoading: true, isCompleted: true };
      case MAINTENANCE_TYPE_LOADING:
        return { isLoading: true, isCompleted: true };
      case MAINTENANCE_TYPE_ERROR:
        return { isLoading: false, isCompleted: false };
      default:
        return state;
    }
  }
  