import {
  MECHINERY_ALL,
  MECHINERY_GET,
  MECHINERY_POST,
  MECHINERY_PUT,
  MECHINERY_DELETE,
  MECHINERY_LOADING,
  MECHINERY_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const mechineryCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MECHINERY_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/mechinery`,
      {
        ...payload,
      },
    );
    dispatch({ type: MECHINERY_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MECHINERY_ERROR });
  }
};

export const mechineryAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;

    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mechinery?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MECHINERY_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MECHINERY_ERROR });
  }
};

export const mechineryGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mechinery/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MECHINERY_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MECHINERY_ERROR });
  }
};

export const mechineryPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MECHINERY_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/mechinery/${id}`,
      payload,
    );
    dispatch({ type: MECHINERY_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MECHINERY_ERROR });
  }
};
export const mechineryDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MECHINERY_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/mechinery/${id}`,
    );
    dispatch({ type: MECHINERY_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MECHINERY_ERROR });
  }
};
