/* eslint-disable react/no-unknown-property */
/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import BillBookingChart from '../../components/Chart/BillBookingChart';
import BarChartBillBooking from '../../components/Chart/BarChartBillBooking';
import BillBookingPriceChart from '../../components/Chart/BillBookingPriceChart';
import { Card, TextField } from '@mui/material';
import { currencyFormatter } from 'utils/functions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BillBookingDounutChart from '../../components/Chart/BillBookingDounutChart';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
dayjs.extend(quarterOfYear);
export default function DashBoardBooking({ title, subtitle }) {
  const dispatch = useDispatch();
  const billBooking = useSelector((state) => state.billBooking);
  const [year, setYear] = useState(new Date());

  useEffect(() => {
    dispatch(
      actions.billBookingAll({
        dashboard: true,
        startDate: dayjs(year).startOf('year'),
        endDate: dayjs(year).endOf('year'),
      }),
    );
  }, [year]);

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  const renderChart = () => (
    <Card sx={{ width: '75%' }}>
      <div>
        <BillBookingChart data={billBooking} />
      </div>
    </Card>
  );
  const renderBarchart = () => (
    <Card>
      <div className="p-2">
        <BarChartBillBooking data={billBooking} />
      </div>
      <div>
        <h1 className="text-center py-2 font-bold ">กราฟแสดงยอดบุ๊คกิ้ง</h1>
      </div>
    </Card>
  );

  const renderBarChartPrice = () => (
    <Card>
      <div className="p-2">
        <BillBookingPriceChart data={billBooking} />
      </div>
      <div>
        <h1 className="text-center py-2 font-bold ">กราฟแสดงยอดเงิน</h1>
      </div>
    </Card>
  );

  // const renderPieChart = () => (
  //   <Card sx={{ width: '75%' }}>
  //     <div>
  //       <BillBookingDounutChart data={billBooking} />
  //     </div>
  //   </Card>
  // );

  const renderSummary = () => (
    <div className=" lg:flex ">
      <div className="py-1 lg:px-1 ">
        <Card sx={{ width: 300, height: 120 }}>
          <div className="p-4">
            รายได้รวม
            <div className="text-3xl pl-4">
              <i class="fa-solid fa-baht-sign text-green-500 "></i>{' '}
              {currencyFormatter.format(
                _.sumBy(billBooking?.analyzed, (row) => row?.total_price),
              )}
            </div>
          </div>
        </Card>
      </div>
      <div className="py-1 lg:px-1">
        <Card sx={{ width: 300, height: 120 }}>
          <div className="p-4">
            บุ๊คกิ้งรวม
            <div className="text-3xl pl-4">
              <i class="fas fa-file-contract text-blue-500  "></i>{' '}
              {currencyFormatter.format(
                _.sumBy(billBooking?.analyzed, (row) => row?.count),
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
  const renderYearSelect = () => (
    <div className="">
      <Card>
        <div className="p-2 ">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year']}
              label="ปี"
              value={year}
              onChange={(newValue) => {
                setYear(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  size="small"
                  fullWidth
                />
              )}
            />{' '}
          </LocalizationProvider>
        </div>
      </Card>
    </div>
  );

  if (billBooking.isLoading) {
    return <Loading />;
  }
  if (!billBooking.isLoading && billBooking.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="py-2">{renderYearSelect()}</div>
        </div>
        <Card>
          <div className="p-6">
            <h1 className="text-2xl font-bold">
              รายการปี {dayjs(year).format('YYYY')}
            </h1>
          </div>
          <div className="lg:flex  ">
            <div className="w-1/2 flex mx-4 my-4 ">{renderSummary()}</div>
            <div className="lg:flex w-1/2">
              <div className="py-2 w-full ">{renderChart()}</div>
            </div>
          </div>
        </Card>
        <div className="py-2">
          <Card>
            <div className="p-6">
              <h1 className="font-bold text-xl">กราฟแสดงแยกแต่ละรายการ</h1>
            </div>
            <div className="lg:flex p-4 justify-center">
              <div className="px-4">{renderBarchart()}</div>
              {renderBarChartPrice()}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}
DashBoardBooking.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DashBoardBooking.defaultProps = {
  title: '',
  subtitle: '',
};
