/* eslint-disable react/prop-types */
import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function AddWheelTrailertail({
  control,
  wheel,
  wheelSupply,
  setWheelTypeSelect,
  position,
  Controller,
}) {
  const handleChange = (event) => {
    setWheelTypeSelect(event.target.value);
  };
  return (
    <div>
      <div>
        <div className="text-xl font-bold"> เพิ่มตำแหน่งยาง {position}</div>
      </div>
      <div>
        <div className="py-1">
          <Controller
            name={'mileageIn'}
            control={control}
            defaultValue={wheel ? wheel.wheelNumber : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="เลขไมล์"
                required
                fullWidth
                // helperText={errors. && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </div>
      <div className="py-1">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" size="small">
            ยี่ห้อยาง
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={age}
            label="ยี่ห้อยาง"
            size="small"
            onChange={handleChange}
          >
            {_.map(wheelSupply?.rows, (e) => (
              <MenuItem value={e._id}>{e?.brand}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="py-1">
        <Controller
          name={'type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label" fullWidth size="small">
                ประเภทยาง
              </InputLabel>
              <Select {...field} label="ประเภทยาง">
                <MenuItem value={'new'}>ยางใหม่</MenuItem>
                <MenuItem value={'retread'}>ยางหล่อดอก</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="py-1">
        <Controller
          name={'wheelNumber'}
          control={control}
          defaultValue={wheel ? wheel.wheelNumber : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="เลขยาง"
              required
              fullWidth
              // helperText={errors. && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="py-1">
        <Controller
          name={'tread'}
          control={control}
          // defaultValue={item?.price}
          render={({ field }) => (
            <TextField {...field} fullWidth label="ดอกยาง" size="small" />
          )}
        />
      </div>
      <div className="flex justify-center py-2 ">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
AddWheelTrailertail.propTypes = {
  control: PropTypes.object,
  wheel: PropTypes.object,
  wheelSupply: PropTypes.object,
  setWheelTypeSelect: PropTypes.object,
  position: PropTypes.object,
  Controller: PropTypes.object,
};
