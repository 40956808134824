import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BackButton } from '../../components/Button';
import MechForm from '../../components/Forms/MECH/MechForm';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

export default function CreateMechinery({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.mechineryCreate(data));
      history.goBack();
    }
  };

  const renderForm = () => (
    <div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MechForm control={control} Controller={Controller} errors={errors} />
        </form>
      </Card>
    </div>
  );
  const renderBackButton = () => <BackButton />;
  return (
    <div>
      {renderTitle()}
      {renderBackButton()}
      <div className="py-2">{renderForm()}</div>
    </div>
  );
}
CreateMechinery.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMechinery.defaultProps = {
  title: '',
  subtitle: '',
};
