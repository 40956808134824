import React from 'react';
import {
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import dayjs from '../../../utils/functions/thDayjs';

const DeliveryOrderShowTable = ({
    deliveryOrders,
}) => {
    const history = useHistory();
    return (
        <div>
            <Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <colgroup>
                            <col width="5%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="8%" />
                            <col width="20%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="10%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <div className="font-bold">วันรับ </div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">วันส่ง</div>
                                </TableCell>

                                <TableCell>
                                    <div className="font-bold">เลขที่ใบแจ้งหนี้</div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">ลูกค้า</div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">สถานที่รับ</div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">สถานที่ส่ง</div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">คอนเทนเนอร์</div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">น้ำหนัก</div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">พาเลท/หีบห่อ</div>
                                </TableCell>
                                <TableCell>
                                    <div className="font-bold">ดำเนินการ</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!_.isEmpty(deliveryOrders) ? (
                                deliveryOrders.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            {`${dayjs(row?.start_date).format('DD MMM BBBB')}`}
                                        </TableCell>
                                        <TableCell>
                                            {`${dayjs(row?.end_date).format('DD MMM BBBB')}`}
                                        </TableCell>

                                        <TableCell>{row?.invoice_number || '-'}</TableCell>
                                        <TableCell>
                                            <div>{row?.running_number}</div>
                                            <div className="bg-blue-300 px-2 py-1 my-2 rounded-md whitespace-nowrap">
                                                {row?.job?.name}
                                            </div>
                                            <div>{row?.customer?.name || '-'}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{row?.origin || '-'}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{row?.destination || '-'}</div>
                                        </TableCell>
                                        <TableCell>
                                            {row?.container?.licence_number || '-'}
                                        </TableCell>
                                        <TableCell>{row?.weight || '-'} กิโลกรัม</TableCell>
                                        <TableCell>{row?.amount || '-'}</TableCell>
                                        <TableCell component="th" scope="row">
                                            <Button
                                                variant="contained"
                                                color='warning'
                                                onClick={() => history.push(`/tms/delivery-order/edit/${row._id}`)}
                                            >แก้ไข</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <div className="text-center">ไม่มีข้อมูล</div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

DeliveryOrderShowTable.propTypes = {
    deliveryOrders: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.object),
    }),
};

export default DeliveryOrderShowTable;