import {
    MAINTENANCE_HISTORY_ALL,
    MAINTENANCE_HISTORY_GET,
    MAINTENANCE_HISTORY_PUT,
    MAINTENANCE_HISTORY_DEL,
    MAINTENANCE_HISTORY_POST,
    MAINTENANCE_HISTORY_RESET,
    MAINTENANCE_HISTORY_LOADING,
    MAINTENANCE_HISTORY_ERROR,
  } from '../../actions/types';
  
  const initialState = {
    maintenanceHistorys: null,
    isLoading: true,
    isCompleted: true,
  };
  // eslint-disable-next-line func-names
  export default function (state = initialState, action) {
    switch (action.type) {
      case MAINTENANCE_HISTORY_ALL:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case MAINTENANCE_HISTORY_GET:
        return {
          ...action.payload,
          isLoading: false,
          isCompleted: true,
        };
      case MAINTENANCE_HISTORY_POST:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_HISTORY_PUT:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_HISTORY_DEL:
        return { isLoading: false, isCompleted: true };
      case MAINTENANCE_HISTORY_RESET:
        return { MAINTENANCE_HISTORY: null, isLoading: true, isCompleted: true };
      case MAINTENANCE_HISTORY_LOADING:
        return { isLoading: true, isCompleted: true };
      case MAINTENANCE_HISTORY_ERROR:
        return { isLoading: false, isCompleted: false };
      default:
        return state;
    }
  }
  