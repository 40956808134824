/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
// import pdfMake, { JV } from 'addthaifont-pdfmake/build/pdfmake';
// import 'addthaifont-pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake-thaifont-2/build/pdfmake';
import 'pdfmake-thaifont-2/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import imageToBase64 from '../../utils/functions/imageToBase64';

import currencyFormat from '../../utils/functions/currencyFormat';
import HeaderMaintenanceReportType1 from './maintenanceOrder/HeadMaintenanceOrderType1';
import { HeaderMaintenanceReportType2 } from './maintenanceOrder/HeadMaintenanceOrderType2';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

async function MainmaintenanceReport(order, info, me) {
  const {
    setting: { cmms: cmmsSetting },
  } = info;
  console.log('order in quotation', order);

  pdfMake.fonts = {
    Sarabun: {
      normal: 'Sarabun-Light.ttf',
      bold: 'Sarabun-Regular.ttf',
      italics: 'Sarabun-LightItalic.ttf',
      bolditalics: 'Sarabun-Italic.ttf',
    },
    Impac: {
      normal: 'impac.ttf',
    },

    FontAwesome: {
      normal: 'FontAwesome_regular.ttf',
      bold: 'FontAwesome_regular.ttf',
    },
  };

  const genMaterial2 = (materials) => {
    return _.map(materials, (eachMaterial, index) => ({
      detail: `อะไหล่ ${eachMaterial?.material?.name} จำนวน ${eachMaterial?.amount} ${eachMaterial?.material?.unit}`,
    }));
  };
  const genProcess = (process) => {
    return _.map(process, (eachProcess, index) => ({
      topic: ` ${eachProcess?.operating} `,
    }));
  };

  const genTopic = (topic) => {
    return _.map(topic, (_topic, index) => [
      {
        text: `${_topic?.topic?.name} ${_topic?.topic?.amount || 1 || ''}`,
        border: [true, false, true, true],
        colSpan: cmmsSetting?.attach_topic_amount ? 1 : 2,
      },
    ]);
  };

  const genLine = (materialBody) => {
    if (!cmmsSetting?.printed_maintenance_order?.show_empty_space_topic) {
      return [];
    }

    return _.map(materialBody, (_topic, index) => [
      {
        text: _topic?.detail || _topic?.topic ? index + 1 : '    ',
        border: [true, false, true, true],
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: _topic?.topic || '',
        border: [true, false, true, true],
        colSpan: cmmsSetting?.attach_topic_amount ? 1 : 2,
      },
      cmmsSetting?.attach_topic_amount
        ? {
            text: '',
            border: [true, false, true, true],
            colSpan: 1,
          }
        : {},
      {
        text: _topic?.detail || '',
        border: [true, false, true, true],
        colSpan: 1,
        alignment: 'center',
      },
    ]);
  };

  let imageLeftBase64 = '';
  if (cmmsSetting?.allow_left_logo) {
    imageLeftBase64 = await imageToBase64(info?.image_subLogo);
  }

  const maintenanceTopic = genTopic(
    order?.process_materials?.material?.name,
    order?.process_materials?.amount,
  );

  const materialBody = genMaterial2(order?.process_materials);
  const materialBodyWithModify = [
    ...materialBody,
    { detail: ` ${order?.modify || ''}` },
  ];

  console.log(' Materail', materialBody);
  const processBody = genProcess(order?.process);

  const combineLines = _.zipWith(
    processBody,
    materialBodyWithModify,
    (a, b) => {
      return {
        topic: a?.topic,
        detail: b?.detail,
      };
    },
  );
  console.log('Combine Lines', combineLines);

  const sizeCombine = _.size(combineLines);
  const remainSize = sizeCombine >= 13 ? 0 : 13 - sizeCombine;
  const remainArray = Array(remainSize);

  const maintenanceline = genLine([...combineLines, ...remainArray]);
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 200, 40, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '8',
    },
    info: {
      title: 'ใบแจ้งซ่อม',
    },
    header: [
      {
        margin: [30, 20, 25, 0],
        style: 'tableExample',
        table: {
          widths: ['30%', '40%', '15%', '15%'],
          body: [
            [
              {
                height: 1000,
                width: 500,
                image: 'logo',
                rowSpan: 2,
                fit: [40, 40],
                alignment: 'center',
                margin: [0, 2, 0, 0],
                border: [true, true, true, false],
              },
              {
                text: '',
                border: [true, true, false, false],
              },
              {
                text: 'หมายเลขเอกสาร: FM-EN-001  ',
                style: 'header',
                alignment: 'left',
                fontSize: '11',
                colSpan: 2,
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, true, true] },
            ],
            [
              {},
              {
                text: 'ใบแจ้งซ่อม/สั่งทำ/เลื่อนแผน',
                alignment: 'center',
                bold: true,
                fontSize: 16,
                margin: [0, 0, 0, -5],
                border: [true, false, false, false],
              },
              {
                text: 'วันที่ประกาศใช้ : 01 มี.ค. 2553',
                style: 'header',
                colSpan: 2,
                margin: [0, 2, 0, 0],
                fontSize: '11',
                alignment: 'left',
                border: [true, true, true, true],
              },
              {},
            ],
            [
              {
                text: 'THAI TALLOW AND OIL CO.,LTD.',
                alignment: 'center',
                fontSize: 12,
                font: 'Impac',
                border: [true, false, true, true],
              },
              {
                text: '',
                border: [true, false, true, true],
              },

              {
                text: 'แก้ไขครั้งที่: 01',
                style: 'header',
                colSpan: 2,
                fontSize: '12',
                margin: [0, 2, 0, 0],
                alignment: 'left',
                border: [true, true, true, true],
              },
              {},
            ],
          ],
        },
      },
      {
        margin: [30, 0, 25, 0],
        style: 'tableExample',
        table: {
          widths: ['35%', '30%', '35%'],
          body: [
            [
              {
                text: 'เรื่อง : ใบแจ้งซ่อม/สั่งทำ/เลื่อนแผน',
                margin: [0, 5, 0, 5],
                alignment: 'center',
                bold: true,
                fontSize: '12',
                border: [true, true, false, true],
              },
              {
                text: `วันที่แจ้ง :${dayjs(order?.start_date).format(
                  'DD/MM/BB',
                )}`,
                bold: true,
                margin: [0, 5, 0, 5],
                fontSize: '11',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: `เลขที่  ${
                  order?.norepair || '.......................................'
                }`,
                bold: true,
                margin: [0, 5, 0, 5],
                fontSize: '12',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            [
              {
                text:
                  order?.maintenance_type?.name === 'บำรุงรักษา'
                    ? {
                        text: [
                          { text: 'ประเภทงาน :  ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' PM บำรุงรักษา ',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: 'ประเภทงาน :  ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' PM บำรุงรักษา ',
                          },
                        ],
                      },
                alignment: 'left',
                bold: true,
                fontSize: '10',

                margin: [0, 0, 2, 0],
                border: [true, false, true, false],
              },
              {
                text:
                  order?.departmenthead?.pd === true
                    ? {
                        text: [
                          { text: 'ผู้แจ้ง/แผนก  ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' แผนกผลิต ',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: 'ผู้แจ้ง/แผนก   ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' แผนกผลิต',
                          },
                        ],
                      },
                fontSize: '10',
                bold: true,
                margin: [4, 0, 0, 0],
                border: [true, false, true, false],
              },
              {
                text:
                  `ผู้แจ้ง  ${order?.informer?.firstname || ''} ${
                    order?.informer?.lastname || ''
                  }` ||
                  'ผู้แจ้ง........................................................',
                fontSize: '10',

                margin: [0, 2, 0, 0],
                alignment: 'left',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text:
                  order?.maintenance_type?.name === 'ซ่อมแซม' ||
                  order?.maintenance_type?.name === 'แก้ไข'
                    ? {
                        text: [
                          { text: '' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' CM ซ่อมแซม / แก้ไข',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: ' ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' CM ซ่อมแซม / แก้ไข',
                          },
                        ],
                      },
                alignment: 'left',
                bold: true,
                fontSize: '10',
                margin: [31, 0, 0, 0],
                border: [true, false, true, false],
              },
              {
                text:
                  order?.departmenthead?.bg === true
                    ? {
                        text: [
                          { text: ' ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' แผนกก๊าสชีวภาพ ',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: ' ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' แผนกก๊าสชีวภาพ',
                          },
                        ],
                      },
                fontSize: '10',
                alignment: 'right',
                bold: true,
                margin: [0, 0, 11, 0],
                border: [true, false, true, false],
              },

              {
                text: `ผู้รับแจ้ง  ${
                  order?.notifier || '.......................................'
                }`,
                margin: [0, 2, 0, 0],
                alignment: 'left',
                fontSize: '10',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text:
                  order?.maintenance_type?.name === 'ปรับปรุง'
                    ? {
                        text: [
                          { text: '' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' IM  ปรับปรุง',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: '' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' IM  ปรับปรุง',
                          },
                        ],
                      },
                alignment: 'left',
                bold: true,
                fontSize: '10',
                margin: [6, 0, 8, 2],
                border: [true, false, true, true],
              },

              {
                text:
                  order?.departmenthead?.ma === true
                    ? {
                        text: [
                          { text: ' ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' แผนกเครื่องกลไฟฟ้า ',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: ' ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' แผนกเครื่องกลไฟฟ้า',
                          },
                        ],
                      },
                border: [true, false, true, true],
                fontSize: '10',
                bold: true,
                margin: [4, 0, 0, 4],
                alignment: 'right',
              },
              {
                text: `ผู้รับงาน ${
                  order?.workrecip ||
                  '....................................................'
                } `,

                fontSize: '10',

                margin: [0, 2, 0, 2],
                alignment: 'left',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],

    content: [
      {
        margin: [10, -16, -15, 0],
        style: 'tableExample',
        table: {
          widths: ['60%', '40%'],
          body: [
            [
              {
                text: `ชื่อเครื่องจักร : ${order?.vehicle?.engname || ''}  ${
                  order?.vehicle?.thainame || '-'
                } `,
                alignment: 'left',
                fontSize: '10',
                bold: true,
                margin: [0, 10, 10, 10],
                border: [true, false, true, true],
              },
              {
                text: `รหัสเครื่องจักร : ${
                  order?.vehicle?.licence_number || ''
                } `,
                alignment: 'left',
                fontSize: '10',
                margin: [0, 10, 10, 10],
                bold: true,
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        margin: [10, 0, 10, 2],
        fontSize: 10,
        table: {
          widths: ['100%'],
          body: [
            [
              {
                text: 'อาการ / ความเสียหายของเครื่องจักร  ',
                decoration: 'underline',
                fontSize: 12,
                margin: [0, 20, 0, 0],
                border: [false, false, false, false],
              },
            ],
            // ..._.map(order?.maintenance_topic, (eachMaintenanceTopic) => [
            //   {
            //     text: `${
            //       eachMaintenanceTopic.topic.name ||
            //       '........................................................................................................................................................................................................'
            //     }`,
            //     colSpan: 1,
            //     bold: true,
            //     border: [false, false, false, false],
            //   },
            // ]),
            [
              order?.cause
                ? {
                    text: `${order?.cause}`,
                    colSpan: 1,
                    border: [false, false, false, false],
                  }
                : {
                    text: '........................................................................................................................................................................................................',
                    border: [false, false, false, false],
                  },
            ],

            [
              {
                text: '........................................................................................................................................................................................................',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '........................................................................................................................................................................................................',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
            ],
            // [
            //   cmmsSetting?.printed_maintenance_order?.show_garage
            //     ? {
            //         text: 'อู่ซ่อม',
            //         colSpan: 1,
            //         bold: true,
            //         border: [false, false, false, false],
            //       }
            //     : {
            //         text: '',
            //         border: [false, false, false, false],
            //       },
            //   cmmsSetting?.printed_maintenance_order?.show_garage
            //     ? {
            //         text: `${order?.garage?.name || 'อู่ภายใน'}`,
            //         colSpan: 1,
            //         border: [false, false, false, false],
            //       }
            //     : {
            //         text: '',
            //         border: [false, false, false, false],
            //       },
            //   {
            //     text: '',
            //     colSpan: 1,
            //     bold: true,
            //     border: [false, false, false, false],
            //   },
            //   {
            //     text: '',
            //     //   order?.mileage
            //     //     ? `${currencyFormat.format(order?.mileage)} km`
            //     //     : '-'
            //     // }`,
            //     // colSpan: 1,
            //     border: [false, false, false, false],
            //   },
            // ],
          ],
        },
      },
      {
        margin: [10, 20, -10, 0],
        style: 'tableExample',
        border: [true, true, true, true],
        fontSize: 11,
        layout: {
          fillColor(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        table: {
          widths: ['8%', '*', '15%', '50%'],
          border: [true, true, true, true],
          heights: [15],
          headerRows: 1,
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                alignment: 'center',
                border: [true, true, true, true],
                bold: true,
              },
              {
                text: 'ขั้นตอนการปฏิบัติงาน',
                colSpan: cmmsSetting?.attach_topic_amount ? 1 : 2,
                alignment: 'center',
                border: [true, true, true, true],
                bold: true,
              },
              cmmsSetting?.attach_topic_amount
                ? {
                    text: 'จำนวน',
                    colSpan: 1,
                    alignment: 'center',
                    bold: true,

                    border: [true, true, true, true],
                  }
                : {},
              {
                text: 'รายละเอียดที่ปฏิบัติงาน / การเปลี่ยนอะไหล่',
                colSpan: 1,
                alignment: 'center',
                bold: true,
                border: [true, true, true, true],
              },
            ],
            ...maintenanceTopic,
            ...maintenanceline,
          ],
        },
      },
      {
        margin: [10, 2, -10, 0],
        style: 'tableExample',
        fontSize: 9,
        table: {
          widths: ['40%', '35%', '25%'],
          border: [true, true, true, true],
          heights: [15],
          headerRows: 1,
          body: [
            [
              {
                text: order?.date?.workorder
                  ? {
                      text: [
                        { text: 'ผลการปฏิบัติงาน : ' },
                        {
                          text: '',
                          style: 'icon',
                        },
                        {
                          text: `   วันที่สั่งงาน ${dayjs(
                            order?.date?.workorder,
                          ).format('DD/MM/BB')}`,
                        },
                      ],
                    }
                  : {
                      text: [
                        { text: 'ผลการปฏิบัติงาน : ' },
                        {
                          text: '',
                          style: 'icon',
                        },
                        {
                          text: '  วันที่สั่งงาน....../....../....... ',
                        },
                      ],
                    },

                fontSize: 10,
                alignment: 'center',
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: 'ผลการตรวจสอบงาน / ทดลองเดินเครื่องจักร',
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: ' ผู้ตรวจสอบ',
                fontSize: 10,
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: order?.date?.medium
                  ? {
                      text: [
                        { text: '   ' },
                        {
                          text: '',
                          style: 'icon',
                        },
                        {
                          text: `   เลื่อนแผ่น ${dayjs(
                            order?.date?.meduim,
                          ).format('DD/MM/BB')}`,
                        },
                      ],
                    }
                  : {
                      text: [
                        { text: '   ' },
                        {
                          text: '',
                          style: 'icon',
                        },
                        {
                          text: '  เลื่อนแผ่น....../....../....... ',
                        },
                      ],
                    },
                alignment: 'right',
                margin: [0, 0, 16, 0],
                fontSize: 10,
                border: [true, false, true, false],
              },
              {
                text:
                  order?.status === 'SUCCESS'
                    ? {
                        text: [
                          { text: '   ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' งานซ่อมเสร็จเรียบร้อย',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: '   ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' งานซ่อมเสร็จเรียบร้อย',
                          },
                        ],
                      },
                border: [true, false, true, false],
              },

              {
                text: '',
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: order?.date?.success
                  ? {
                      text: [
                        { text: '  ' },
                        {
                          text: '',
                          style: 'icon',
                        },
                        {
                          text: `   เก็บวันที่ ${dayjs(
                            order?.date?.success,
                          ).format('DD/MM/BB')}`,
                        },
                      ],
                    }
                  : {
                      text: [
                        { text: '  ' },
                        {
                          text: '',
                          style: 'icon',
                        },
                        {
                          text: '  เก็บวันที่....../....../....... ',
                        },
                      ],
                    },

                alignment: 'right',
                fontSize: 10,
                margin: [0, 0, 22, 0],
                border: [true, false, true, false],
              },

              {
                text:
                  order?.status !== 'SUCCESS'
                    ? {
                        text: [
                          { text: '  ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: ' งานซ่อมไม่เรียบร้อย',
                          },
                        ],
                      }
                    : {
                        text: [
                          { text: '  ' },
                          {
                            text: '',
                            style: 'icon',
                          },
                          {
                            text: '  งานซ่อมไม่เรียบร้อย ',
                          },
                        ],
                      },
                border: [true, false, true, false],
              },

              {
                text: `ลงชื่อ   ${
                  order?.approvemaintain || '.......................'
                }`,
                fontSize: 10,
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, false, true, false],
              },
              // {
              //   text: `วันที่......... / ............ /.........`,

              //   margin: [0, 2, 0, 0],
              //   alignment: 'center',
              //   border: [true, false, true, true],
              // },
            ],
            [
              {
                text: '',
                margin: [0, 0, 16, 0],
                fontSize: 10,
                border: [true, false, false, false],
              },
              {
                text: 'อาการ.......................................................',
                alignment: 'right',

                border: [true, false, false, false],
              },

              {
                text: '(.............................................)',
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: '',
                margin: [0, 0, 16, 0],
                fontSize: 10,
                border: [true, false, true, true],
              },
              {
                text: '.......................................................',
                alignment: 'right',
                border: [true, false, true, true],
              },

              {
                text:
                  `วันที่ ${dayjs(order?.date_approve).format('DD/MM/BB')}
                  ` || 'วันที่.........../............/..........',
                bold: true,
                margin: [0, 2, 0, 0],
                alignment: 'center',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },

      cmmsSetting?.printed_maintenance_order?.additional_remark
        ? {
            margin: [10, 20, 0, 20],
            fontSize: 11,
            columns: [
              {
                text: 'หมายเหตุ : ',
                bold: true,
                width: '15%',
              },
              {
                text: `${
                  cmmsSetting?.printed_maintenance_order?.additional_remark ||
                  ''
                }`,
              },
            ],
          }
        : {},
    ],
    footer: [
      {
        margin: [20, 0, 0, 20],
        fontSize: 12,

        columns: [],
      },
    ],
    styles: {
      icon: {
        font: 'FontAwesome',
        alignment: 'center',
        fontSize: 10,
      },
    },
    images: {
      logo: info?.image_subLogo || info?.image_logo,
    },
  };

  pdfMake.createPdf(docDefinition).open();
}

export default MainmaintenanceReport;
