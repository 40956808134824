import {
  VEHICLE_SCHEDULE_ALL,
  VEHICLE_SCHEDULE_GET,
  VEHICLE_SCHEDULE_POST,
  VEHICLE_SCHEDULE_PUT,
  VEHICLE_SCHEDULE_DEL,
  VEHICLE_SCHEDULE_LOADING,
  VEHICLE_SCHEDULE_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const vehicleScheduleCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_SCHEDULE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/vehicle-schedule`,
      {
        ...payload,
      },
    );
    dispatch({ type: VEHICLE_SCHEDULE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLE_SCHEDULE_ERROR });
  }
};

export const vehicleScheduleAll =
  ({
    name = '',
    size = '',
    page = 1,
    scheduleType = '',
    history = '',
    start = '',
    end = '',
    statusCode = '',
    scheduleStatus = '',
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/vehicle-schedule?licence_number=${name}&size=${size}&page=${page}&scheduleType=${scheduleType}&history=${history}&start=${start}&end=${end}&statusCode=${statusCode}&scheduleStatus=${scheduleStatus}`,
      );
      if (status === 200) {
        dispatch({ type: VEHICLE_SCHEDULE_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: VEHICLE_SCHEDULE_ERROR });
    }
  };

export const vehicleScheduleGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/vehicle-schedule/${id}`,
    );
    if (status === 200) {
      dispatch({ type: VEHICLE_SCHEDULE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLE_SCHEDULE_ERROR });
  }
};

export const vehicleSchedulePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_SCHEDULE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/vehicle-schedule/${id}`,
      payload,
    );
    dispatch({ type: VEHICLE_SCHEDULE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLE_SCHEDULE_ERROR });
  }
};

export const vehicleScheduleDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_SCHEDULE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/vehicle-schedule/${id}`,
    );
    dispatch({ type: VEHICLE_SCHEDULE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: VEHICLE_SCHEDULE_ERROR });
  }
};
