import _ from 'lodash';

const accessRight = (me, moduleName, levelPage = 0) => {
  const {
    userData: {
      department: { access = [] },
      role: { level: meLevel },
    },
  } = me;
  if (access[`${_.toUpper(moduleName)}`] && meLevel >= levelPage) {
    return true;
  }
  return false;
};

export default accessRight;

/*
การใช้งาน

me คือข้อมูล user ผู้ใช้งานระบบที่ได้จาก server

module คือชื่อ module จาก layout เช่น TMS CRM เป็นต้น

levelPage สำหรับกำหนดระดับการเข้าถึงของ Page นั้น ๆ
ทั้งนี้หากต้องการตรวจสอบการเข้าถึงโมดูลไม่จำเป็นต้องส่งข้อมูลมาแต่ใช้ค่าเริ่มต้นในฟังก์ชันแทน

*/
