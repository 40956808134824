/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

import truck_wheel from '../../../assets/img/truck_wheel.jpg';

const WheelMaps = ({ control, Controller, append }) => {
  const handleAdd = (position) => {
    console.log(position);
    append({
      position: `${position}`,
      wheelNumber: '0',
    });
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="w-1/3">
          <div className=" flex justify-end">ล้อหน้าซ้าย</div>
          <div className=" flex justify-end  ">
            <FormControlLabel
              control={
                <Controller
                  name={'wheel_1'}
                  d
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (e.target.checked) {
                          const po = 1;
                          handleAdd(po);
                        }
                      }}
                      checked={field.value}
                    />
                  )}
                />
              }
              label={'1'}
            />
          </div>

          <div className=" flex justify-end pt-24 ">
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_3'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 3;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'3'}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_4'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 4;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'4'}
              />
            </div>
          </div>
          <div className=" flex justify-end pt-4 ">
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_7'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 7;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'7'}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_8'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 8;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'8'}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center pb-10">
          <img src={truck_wheel} />
        </div>
        <div className="w-1/3">
          <div className=" flex">ล้อหน้า</div>
          <div className=" ">
            <FormControlLabel
              control={
                <Controller
                  name={'wheel_2'}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (e.target.checked) {
                          const po = 2;
                          handleAdd(po);
                        }
                      }}
                      checked={field.value}
                    />
                  )}
                />
              }
              label={'2'}
            />
          </div>
          <div className=" flex pt-24 ">
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_5'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 5;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'5'}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_6'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 6;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'6'}
              />
            </div>
          </div>
          <div className="flex pt-4 ">
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_9'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 9;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'9'}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_10'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 10;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={' 10'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WheelMaps;
