import React from 'react';
import Chart from 'react-apexcharts';
import { dayjs } from '../../utils/functions';
import { currencyFormatter } from '../../utils/functions';
export default function BillBookingPriceChart({ data }) {
  const state = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: _.map(data?.analyzed, (row) =>
          dayjs(row?._id?.month).format('MMM YY'),
        ),
      },
      yaxis: {
        title: {
          text: 'จำนวนเงิน (บาท) ',
        },
      },
    },
    series: [
      {
        name: 'รวมเงิน',
        data: _.map(data?.analyzed, (row) => row?.total_price),
      },
    ],
  };
  const renderChart = () => (
    <div>
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        width="500"
      />
    </div>
  );

  return <div>{renderChart()}</div>;
}
