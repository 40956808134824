import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { BillBookingCard } from '../../components/Card';

function DetailBillBooking({ title, subtitle }) {
  const dispatch = useDispatch();
  const billBooking = useSelector((state) => state.billBooking);
  const params = useParams();

  useEffect(() => {
    dispatch(actions.billBookingGet(params.id));
    return () => {};
  }, [params]);

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderContent = () => (
    <div className="my-2">
      <BillBookingCard billBooking={billBooking} />
    </div>
  );

  if (billBooking.isLoading) {
    return <Loading />;
  }

  if (!billBooking.isLoading && billBooking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderContent()}
      </div>
    );
  }
  return <Error />;
}

DetailBillBooking.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailBillBooking.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailBillBooking;
