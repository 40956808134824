/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import trailer from '../../../assets/img/trailer.jpg';

export default function WheelTailMap4Wheel({ control, Controller, append }) {
  const handleAdd = (position) => {
    append({
      position: `${position}`,
      wheelNumber: '0',
    });
  };
  return (
    <div>
      <div className="flex justify-center">
        <div className="w-1/3">
          <div className=" flex justify-end pt-24 ">
            <div className="flex  pt-12">
              <div className="p-2"></div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_1'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 1;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'1'}
              />
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_2'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 2;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'2'}
              />
            </div>
          </div>
          <div className=" flex justify-end  ">
            <div className="flex  pt-4 pr-4">
              <div className="p-2"></div>
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_5'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 5;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'5'}
              />
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_6'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 6;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'6'}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center pb-10">
          <img src={trailer} />
        </div>
        <div className="w-1/3">
          <div className=" flex pt-24 ">
            <div className="flex pt-12 pl-2">
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_3'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 3;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'3'}
              />
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_4'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 4;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'4'}
              />
              <div className="pt-2"></div>
            </div>
          </div>
          <div className=" flex  ">
            <div className="flex pt-4 pl-6">
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_7'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 7;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'7'}
              />
              <FormControlLabel
                control={
                  <Controller
                    name={'wheel_8'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (e.target.checked) {
                            const po = 8;
                            handleAdd(po);
                          }
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'8'}
              />
              <div className="pt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
