/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

export const DetailDeliveryOrderHistory = (
  deliveryOrder,
  information,
  vehicleSchedule,
) => {
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 100, 40, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '11',
    },
    info: {
      title: 'DELIVERY ORDER',
    },
    header: ReportHeader(information, { language: 'en' }),
    content: [
      {
        alignment: 'center',
        margin: [0, 10, 0, 15],
        text: 'DELIVERY ORDER',
        fontSize: '14',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['15%', '15%', '10%', '10%', '15%', '15%', '10%', '10%'],
          heights: [10, 10, 10, 10, 30, 10, 10, 10],
          body: [
            [
              {
                text: [
                  {
                    text: 'CONSIGNMENT / INVOICE NO.\n',
                    fontSize: 11,
                    bold: true,
                  },
                  {
                    text: `${deliveryOrder?.invoice_number || ''}`, // เลขที่ใบแจ้งหนี้
                    fontSize: 11,
                  },
                ],

                colSpan: 2,
              },
              {},
              {
                text: [
                  {
                    text: 'DATE / TIME \n',
                    fontSize: 11,
                    bold: true,
                  },
                  {
                    text: `${dayjs(deliveryOrder?.createdAt)
                      .locale('en')
                      .format('ll')}`,
                    fontSize: 11,
                  },
                ],
                colSpan: 2,
              },
              {},
              {
                text: [
                  {
                    text: 'REFERENCE NO \n',
                    fontSize: 11,
                    bold: true,
                  },
                  {
                    text: '-',
                    fontSize: 11,
                  },
                ],
                colSpan: 2,
              },
              {},
              {
                text: [
                  {
                    text: 'JOB NO : \n',
                    fontSize: 11,
                    bold: true,
                  },
                ],
                colSpan: 1,
                fontSize: 11,
              },
              {
                text: `${deliveryOrder?.running_number || ''}`,
                colSpan: 1,
                fontSize: 11,
              },
            ],
            [
              {
                text: 'CONTAINER NO.',
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },
              {
                text: `${deliveryOrder?.container?.licence_number || ''}`,
                colSpan: 1,
                fontSize: 11,
              },
              {
                text: 'SIZE : ',
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },
              {
                text: `${deliveryOrder?.container?.size || ''}`,
                colSpan: 1,
                fontSize: 11,
              },
              {
                text: 'TRUCK NO.',
                colSpan: 2,
                fontSize: 11,
                bold: true,
              },
              {},
              {
                // eslint-disable-next-line no-useless-concat
                text:
                  `${
                    vehicleSchedule
                      ? vehicleSchedule?.vehicle?.licence_number
                      : ''
                  }` +
                  `${vehicleSchedule ? '/' : ''}` +
                  `${
                    vehicleSchedule
                      ? vehicleSchedule?.trailer_tail?.licence_number
                      : ''
                  }`,
                colSpan: 2,
                fontSize: 11,
              },
              {},
            ],
            [
              {
                text: 'FACTORY SEAL NO.',
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },
              {
                text: `${vehicleSchedule ? vehicleSchedule?.seal_number : ''}`,
                colSpan: 1,
                fontSize: 11,
              },
              {
                text: 'WEIGHT',
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },
              {
                text: `${deliveryOrder?.weight || ''} MT`,
                colSpan: 1,
                fontSize: 11,
              },
              {
                text: '',
                colSpan: 4,
                fontSize: 11,
              },
              {},
              {},
              {},
            ],

            [
              {
                text: 'SHIPPER',
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },
              {
                text: [
                  {
                    text: `${deliveryOrder?.customer?.name || ''} \n`,
                    colSpan: 3,
                    fontSize: 11,
                    bold: true,
                  },
                  {
                    text: `${deliveryOrder?.origin || ''}`,
                    colSpan: 3,
                    fontSize: 11,
                  },
                ],
                colSpan: 3,
              },
              {},
              {},
              {
                text: 'CONSIGNEE',
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },

              {
                text: [
                  {
                    text: '-',
                    colSpan: 3,
                    fontSize: 11,
                  },
                ],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                text: 'SPECIAL INSTRUCTION',
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },
              {
                text: `${deliveryOrder?.amount || ''} PCS`,
                colSpan: 3,
                fontSize: 11,
              },
              {},
              {},
              {
                text: 'DELIVERY ADDRESS',
                rowSpan: 1,
                colSpan: 1,
                fontSize: 11,
                bold: true,
              },

              {
                text: [
                  {
                    text: `${deliveryOrder?.destination || ''}`,
                    colSpan: 3,
                    fontSize: 11,
                  },
                ],
                rowSpan: 1,
                colSpan: 3,
                fontSize: 11,
              },
              {},
              {},
            ],
            [
              {
                text: 'Remark',
                colSpan: 2,
                fontSize: 11,
                bold: true,
              },
              {},
              {
                text: `${deliveryOrder?.remark || ''}`,
                colSpan: 6,
                fontSize: 11,
              },
              {},
              {},

              {},
              {},
              {},
            ],
            [
              {
                text: 'ISSUED ON BEHALF OF THE CARRIER',
                colSpan: 4,
                rowSpan: 2,
                fontSize: 11,
                bold: true,
              },
              {},
              {},
              {},
              {
                text: [
                  {
                    text: 'We here by acknowledge receipt above \n',
                    fontSize: 11,
                  },
                  {
                    text: 'SHIPMET in good order & condition \n \n ',
                    fontSize: 11,
                  },
                  {
                    text: '.................................................................................. \n \n',
                    fontSize: 11,
                  },
                  {
                    text: 'Date/Name/L.C.No :',
                    fontSize: 11,
                  },
                ],
                colSpan: 4,
                rowSpan: 2,
              },

              {},
              {},
              {},
            ],
            [{}, {}, {}, {}, {}, {}, {}, {}],
          ],
        },
      },
      {
        alignment: 'justify',
        columns: [
          {
            width: 'auto',

            text: [
              {
                text: 'TERM & CONDITIONS \n',
                fontSize: 10,
              },
              {
                text: '1) ALL CONTAINER DELIVERED ARE SUBJECT TO OUR STANDARD TRADING CONDITION \n',
                fontSize: 10,
              },
              {
                text: "2) ALL goods in transit from point of origin to final destination are at owner ' s risk. \n",
                fontSize: 10,
              },
              {
                text: '3) Shipper / Consignee should  always insure their goods under Cargo / Marine Inland Insurance policy. \n',
                fontSize: 10,
              },
              {
                text: '4) We will not responsible for damage or loss due to insufficient or improper packing or any unforeseen situation. \n',
                fontSize: 10,
              },
            ],
          },
          {
            width: 'auto',
            alignment: 'center',
            margin: [5, 5, 5, 5],
            text: [
              {
                text: '\n \n',
                fontSize: 10,
              },
              {
                text: '.................................................................................. \n \n',
                fontSize: 10,
              },
              {
                text: information?.name,
                fontSize: 11,
              },
            ],
          },
        ],
      },
    ],
    // images: {
    //   company_logo: information?.company_logo,
    // },
  };
  pdfMake.createPdf(docDefinition).open();
};
