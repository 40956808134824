import React from 'react';
import _ from 'lodash';
import { Card, CardContent } from '@mui/material';

import PropTypes from 'prop-types';

const MaintenanceImageCard = ({ mainenanceImages }) => (
  <div className="my-2">
    <Card>
      <CardContent>
        {' '}
        <div className="font-semibold">ภาพประกอบการซ่อม</div>
        <div className="flex overflow-x-auto">
          {_.map(mainenanceImages, (_maintenanceImage, imgIndex) => (
            <img
              key={imgIndex}
              className="w-52 p-2"
              src={_maintenanceImage?.url}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  </div>
);

MaintenanceImageCard.propTypes = {
  mainenanceImages: PropTypes.arrayOf(PropTypes.object),
};

export default MaintenanceImageCard;
