/* eslint-disable react/prop-types */

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Card,
  Paper,
  TablePagination,
  TableRow,
  Button,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function WheelListRegisterTable({
  wheelRegister,
  handleDelete = () => {},
  handleEdit = () => {},
  name,
  page,
  size,
  setName,
  setPage,
  setSize,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    console.log('row', event.target.value);
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  const renderButton = (id) => (
    <Stack spacing={1} direction="row">
      <Button variant="contained" onClick={() => handleEdit(id)}>
        ตรวจสอบ
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => handleDelete(id)}
      >
        ลบ
      </Button>
    </Stack>
  );
  return (
    <div>
      <div>
        <Card>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <h1 className="font-bold">ลำดับ </h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ป้ายทะเบียน</h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ประเภทรถ</h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ยี่ห้อรถ</h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ดำเนินการ</h1>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wheelRegister.total === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      ไม่มีที่ลงทะเบียนยาง
                    </TableCell>{' '}
                  </TableRow>
                ) : (
                  _.uniqBy(
                    wheelRegister?.rows,
                    (each) => each?.vehicle?.licence_number,
                  ).map((each, index) => (
                    <TableRow key={each?._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {each?.vehicle?.licence_number}
                      </TableCell>
                      <TableCell align="center">
                        {each?.vehicle?.type?.name}
                      </TableCell>
                      <TableCell align="center">
                        {each?.vehicle?.attribute?.brand}
                      </TableCell>
                      <TableCell align="center">
                        <div className="flex justify-center">
                          {renderButton(each?._id)}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={wheelRegister?.total}
            rowsPerPage={size || 5}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </div>
  );
}
WheelListRegisterTable.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
};
