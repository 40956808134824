/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import { useHistory, useParams } from 'react-router';
import WheelSupplyDetail from '../../components/Card/WMS/WheelSupplyDetail';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from 'components/Button';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

export default function DetailWheelSupply({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const wheelSupply = useSelector((state) => state.wheelSupply);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderDetail = () => <WheelSupplyDetail wheelSupply={wheelSupply} />;
  const renderBackButton = () => <BackButton />;
  const renderAddButtom = () => (
    <div className="flex flex-row justify-between pb-4 gap-1">
      {renderBackButton()}
      <Button
        variant="contained"
        color="warning"
        onClick={() =>
          history.push(`/wms/wheel-supply/detail/edit/${params.id}`)
        }
      >
        แก้ไข
      </Button>
    </div>
  );
  useEffect(() => {
    dispatch(actions.wheelSupplyGet(params.id));
    return () => {};
  }, []);

  return (
    <div>
      {renderTitle()}

      {renderAddButtom()}
      {renderDetail()}
    </div>
  );
}
DetailWheelSupply.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailWheelSupply.defaultProps = {
  title: '',
  subtitle: '',
};
