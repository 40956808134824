import React, { useEffect, useState } from 'react';
import { Button, Card } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';

import * as actions from '../../redux/actions';
import WheelMaintananceHistoryTable from '../../components/Table/WMS/WheelMaintananceHistoryTable';

export default function WheelHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const wheelMaintenanceHistory = useSelector(
    (state) => state.WheelMaintenanceHistory,
  );
  console.log('wheelMaintenances', wheelMaintenanceHistory);
  useEffect(() => {
    dispatch(actions.wheelMaintenanceHistoryAll({ name, page, size }));

    return () => {};
  }, [name, page, size]);
  const prop = {
    name,
    page,
    size,
    setName,
    setPage,
    setSize,
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Button variant="contained" component={Link} to={'/wms/wms/create'}>
        เพิ่ม
      </Button>
    </div>
  );
  const renderTable = () => (
    <div>
      <WheelMaintananceHistoryTable
        wheelMaintenanceHistory={wheelMaintenanceHistory}
        {...prop}
      />
    </div>
  );
  return (
    <div>
      <div>
        {renderTitle()}
        {renderAddButtom()}
        {renderTable()}
      </div>
    </div>
  );
}
WheelHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WheelHistory.defaultProps = {
  title: '',
  subtitle: '',
};
