import React from 'react';
import { MAINTENANCE_ORDER_STATUS } from '../constants';

const showMaintenanceStatus = (status) => {
  if (status === MAINTENANCE_ORDER_STATUS.NEW.status_code) {
    return (
      <div className="bg-orange-200 px-2 rounded text-center font-semibold">
        {MAINTENANCE_ORDER_STATUS.NEW.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.PENDING.status_code) {
    return (
      <div className="bg-yellow-300 px-2 rounded text-center font-semibold">
        {MAINTENANCE_ORDER_STATUS.PENDING.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code) {
    return (
      <div className="bg-teal-300 px-2 rounded text-center font-semibold">
        {MAINTENANCE_ORDER_STATUS.APPROVE.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.SUCCESS.status_code) {
    return (
      <div className="bg-green-300 px-2 rounded text-center font-semibold">
        {MAINTENANCE_ORDER_STATUS.SUCCESS.description}
      </div>
    );
  }
  if (status === MAINTENANCE_ORDER_STATUS.CENCLE.status_code) {
    return (
      <div className="bg-red-300 px-2 rounded text-center font-semibold">
        {MAINTENANCE_ORDER_STATUS.CENCLE.description}
      </div>
    );
  }
  return (
    <div className="bg-gray-300 px-2 rounded text-center font-semibold">
      {'-'}
    </div>
  );
};

export default showMaintenanceStatus;
