import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { ViewTitle } from '../../components/ViewTitle';
import MachineryListTable from '../../components/Table/MECH/MachineryListTable';
import * as actions from '../../redux/actions';

function MechineryList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const machinery = useSelector((state) => state.mechinery);

  useEffect(() => {
    dispatch(actions.mechineryAll({}));
    return () => {};
  }, []);

  const renderButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/mech/mech/create')}
    >
      เพิ่ม
    </Button>
  );
  const renderTable = () => <MachineryListTable machinery={machinery} />;

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-end">
        <div>{renderButton()}</div>
      </div>
      {renderTable()}
    </div>
  );
}
MechineryList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MechineryList.defaultProps = {
  title: '',
  subtitle: '',
};

export default MechineryList;
