import { FormControlLabel, TextField, Checkbox } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function DepartmentForm({ errors, department, Controller, control }) {
  const renderAccessList = () => (
    <div className="px-2">
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.DASHBOARD'}
          control={control}
          defaultValue={department ? department.access?.DASHBOARD : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการแดชบอร์ด"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.CMMS'}
          control={control}
          defaultValue={department ? department.access?.CMMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการการแจ้งซ่อมบำรุง"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.FMS'}
          control={control}
          defaultValue={department ? department.access?.FMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการข้อมูลเครื่องจักร"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.IMS'}
          control={control}
          defaultValue={department ? department.access?.IMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการคลัง"
            />
          )}
        />
      </div>
      {/* <div className="w-full px-1 py-1">
        <Controller
          name={'access.MECH'}
          control={control}
          defaultValue={department ? department.access?.MECH : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการเครื่องจักร"
            />
          )}
        />
      </div> */}
      {/* <div className="w-full px-1 py-1">
        <Controller
          name={'access.TMS'}
          control={control}
          defaultValue={department ? department.access?.TMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการการขนส่ง"
            />
          )}
        />
      </div> */}
      {/* <div className="w-full px-1 py-1">
        <Controller
          name={'access.JMS'}
          control={control}
          defaultValue={department ? department.access?.JMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการรายการงาน"
            />
          )}
        />
      </div> */}
      {/* <div className="w-full px-1 py-1">
        <Controller
          name={'access.BMMS'}
          control={control}
          defaultValue={department ? department.access?.BMMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="การออกบิล"
            />
          )}
        />
      </div> */}
      {/* <div className="w-full px-1 py-1">
        <Controller
          name={'access.BMS'}
          control={control}
          defaultValue={department ? department.access?.BMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการการออกบิล"
            />
          )}
        />
      </div> */}
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.CRM'}
          control={control}
          defaultValue={department ? department.access?.CRM : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ลูกค้าสัมพันธ์"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.HRMS'}
          control={control}
          defaultValue={department ? department.access?.HRMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ผู้ใช้ระบบ"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.INFORMATION'}
          control={control}
          defaultValue={department ? department.access?.INFORMATION : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ข้อมูลระบบ"
            />
          )}
        />
      </div>
    </div>
  );
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={department ? department.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อแผนก"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={department ? department.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div>{renderAccessList()}</div>
    </div>
  );
}

DepartmentForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  department: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

DepartmentForm.defaultProps = {
  department: null,
};

export default DepartmentForm;
