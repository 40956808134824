import {
  PROCESS_TRANSACTION_ALL,
  PROCESS_TRANSACTION_GET,
  PROCESS_TRANSACTION_PUT,
  PROCESS_TRANSACTION_DELETE,
  PROCESS_TRANSACTION_POST,
  PROCESS_TRANSACTION_RESET,
  PROCESS_TRANSACTION_LOADING,
  PROCESS_TRANSACTION_ERROR,
} from '../../actions/types';

const initialState = {
  maintenanceProcess: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PROCESS_TRANSACTION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_TRANSACTION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_TRANSACTION_POST:
      return { isLoading: false, isCompleted: true };
    case PROCESS_TRANSACTION_PUT:
      return { isLoading: false, isCompleted: true };
    case PROCESS_TRANSACTION_DELETE:
      return { isLoading: false, isCompleted: true };
    case PROCESS_TRANSACTION_RESET:
      return { PROCESS_TRANSACTION: null, isLoading: true, isCompleted: true };
    case PROCESS_TRANSACTION_LOADING:
      return { isLoading: true, isCompleted: true };
    case PROCESS_TRANSACTION_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
