import Dashboard from './Dashboard';
import ByVehicleMaintenanceOrder from './ByVehicleMaintenanceOrder';
import MaintenanceHistorys from './MaintenanceHistorys';
import DetailMaintenanceOrderHistory from '../CMMS/DetailMaintenanceOrderHistory';
import DetailByVehicleMaintenanceOrder from './DetailByVehicleMaintenanceOrder';
import MonthyMaintenanceAnalysis from './MonthyMaintenanceAnalysis';

export { Dashboard };

export default {
  Dashboard,
  ByVehicleMaintenanceOrder,
  MaintenanceHistorys,
  DetailMaintenanceOrderHistory,
  DetailByVehicleMaintenanceOrder,
  MonthyMaintenanceAnalysis,
};
