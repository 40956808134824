/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory } from 'react-router';
import WheelAddForm from '../../components/Forms/WMS/WheelAddForm';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { BackButton } from 'components/Button';
import WheelSupplyAddForm from 'components/Forms/WMS/WheelSupplyAddForm';

export default function EditWheelSupply({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const wheelType = useSelector((state) => state.wheelType);
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const [selectType, setSelectType] = useState();
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name: wheelSupply?.name,
      brand: wheelSupply?.brand,
      mfg: wheelSupply?.mfg,
      price: wheelSupply?.price,
      size: wheelSupply?.size,
    },
  });

  useEffect(() => {
    dispatch(actions.wheelTypeAll({}));
    dispatch(actions.wheelSupplyGet(id));
    setSelectType(wheelSupply?.type);
    return () => {};
  }, []);
  useEffect(() => {
    setSelectType(wheelSupply?.type);
    return () => {};
  }, [wheelSupply]);
  // console.log('selectType', selectType);
  // console.log('Whell Edid', wheel);
  // console.log('id', id);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const onSubmitData = async (data) => {
    const confirm = window.confirm('ยันยันบันทึกข้อมูล');
    if (confirm) {
      try {
        const dataSubmit = {
          ...data,
          supply: selectType._id,
        };
        await dispatch(actions.wheelSupplyPut(id, dataSubmit));
        await dispatch(actions.wheelSupplyGet(id));
        alert('บันทึกข้อมูลสำเร็จ');
        history.goBack();
      } catch (error) {}
    }
  };
  const renderBack = () => <BackButton />;
  const renderWheelAddForm = () => (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <WheelSupplyAddForm
        control={control}
        Controller={Controller}
        setValue={setValue}
        wheelType={wheelType}
        selectType={selectType}
        wheelSupply={wheelSupply}
        setSelectType={setSelectType}
      />
    </form>
  );
  if (wheelSupply.isLoading) {
    return <Loading />;
  }

  if (!wheelSupply.isLoading && wheelSupply.isCompleted && !wheelSupply?.rows) {
    return (
      <>
        {renderTitle()}
        {renderBack()}
        {renderWheelAddForm()}
        <div></div>
      </>
    );
  }

  return <div></div>;
}
