/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  TextField,
} from '@mui/material';
import React from 'react';

export default function Retread({
  setReTreadchecked,
  reTreadchecked,
  setWheelSelect,
  wheelSelect,
  control,
  Controller,
}) {
  console.log('');
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              setReTreadchecked(e.target.checked);
              if (!e.target.checked) {
                setWheelSelect({});
              }
            }}
          />
        }
        label="เลือกยาง"
      />
      {reTreadchecked && (
        <div className="py-1 font-bold text-xl ">
          ยางที่เลือก {wheelSelect?.wheelNumber || '-'}
        </div>
      )}

      <div className="py-1 ">
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="ราคา" fullWidth size="small" />
          )}
        />
      </div>
      <div className="py-1 ">
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="หมายเหตุ" fullWidth size="small" />
          )}
        />
      </div>
    </div>
  );
}
