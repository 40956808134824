import React, { useState } from 'react';
import _ from 'lodash';
import {
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { List as ListIcon, Grid as GridIcon } from 'react-feather';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

const MaintenanceTopicCard = ({ maintenanceTopics, showFullPanel = false }) => {
  const information = useSelector((state) => state.information);
  const {
    setting: { cmms: cmmsSetting },
  } = information;

  const [view, setView] = useState(
    window.localStorage.getItem('default_view') || 'card',
  );

  const handleSetView = (event, newView) => {
    if (newView !== null) {
      window.localStorage.setItem('default_view', newView);
      setView(newView);
    }
  };

  const columns = [
    {
      field: 'index',
      headerName: 'ลำดับที่',
    },
    {
      field: 'topicName',
      headerName: 'รายการ',
      // eslint-disable-next-line no-nested-ternary
      width: showFullPanel
        ? cmmsSetting?.show_detail_in_maintenance_topic
          ? 300
          : 900
        : cmmsSetting?.show_detail_in_maintenance_topic
        ? 200
        : 300,
      valueGetter: (params) => `${params?.row?.topic?.name || '-'} `,
    },
  ];

  if (cmmsSetting?.attach_topic_amount) {
    columns.push({
      field: 'amount',
      headerName: 'จำนวน',
      type: 'number',
    });
  }

  if (cmmsSetting?.show_price_in_maintenance_topic) {
    columns.push({
      field: 'price',
      headerName: 'ราคา',
      type: 'number',
    });
  }

  if (cmmsSetting?.show_detail_in_maintenance_topic) {
    columns.push({
      field: 'detail',
      headerName: 'รายละเอียด',
    });
  }

  const renderCardView = () => (
    <div className="flex flex-wrap  overflow-x-auto my-1 ">
      {_.map(maintenanceTopics, (_topic, index) => (
        <div
          className={`w-full ${showFullPanel ? 'md:w-1/6' : 'md:w-1/3 '} p-1`}
          key={index}
        >
          <div className="p-4 border rounded-md min-h-full ">
            <div className="font-semibold">{_topic?.topic?.name} </div>{' '}
            {cmmsSetting?.show_detail_in_maintenance_topic && (
              <div className=" my-1 font-sansserif ">{_topic?.detail}</div>
            )}
            <div className="flex my-1">
              {cmmsSetting?.attach_topic_amount ? (
                <div className="font-sansserif text-sm w-1/2">
                  <i className="fa-solid fa-list-ol mr-2"></i>{' '}
                  {_topic?.amount || 1} รายการ{' '}
                </div>
              ) : (
                ''
              )}{' '}
              {_topic?.price ? (
                <div className="font-sansserif text-sm w-1/2">
                  <i className="fa-solid fa-money-bill-wave mr-2"></i>
                  {currencyFormatter.format(_topic?.price)} บาท
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderTableView = () => (
    <div className="my-2">
      {' '}
      <div
        style={{
          height: _.size(maintenanceTopics) * 65 + 100,
          width: '100%',
        }}
      >
        <DataGrid
          rows={_.map(maintenanceTopics, (eachTopic, index) => ({
            ...eachTopic,
            id: index,
            index: index + 1,
          }))}
          columns={columns}
          pageSize={_.size(maintenanceTopics)}
          rowsPerPageOptions={[5]}
        />
      </div>
    </div>
  );
  return (
    <div className="my-2 h-full">
      <Card className="h-full">
        <CardContent>
          <div className="flex justify-between">
            <div className="font-semibold self-center">หัวข้อการซ่อมบำรุง</div>
            <div>
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleSetView}
              >
                <ToggleButton value="card">
                  <GridIcon size={16} />
                </ToggleButton>
                <ToggleButton value="table">
                  {' '}
                  <ListIcon size={16} />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          {view === 'card' && renderCardView()}
          {view === 'table' && renderTableView()}
        </CardContent>
      </Card>
    </div>
  );
};

MaintenanceTopicCard.propTypes = {
  maintenanceTopics: PropTypes.arrayOf(PropTypes.object),
  showFullPanel: PropTypes.bool,
};

export default MaintenanceTopicCard;
