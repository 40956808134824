import {
  MATERIAL_TRANSACTION_ALL,
  MATERIAL_TRANSACTION_GET,
  MATERIAL_TRANSACTION_DEL,
  MATERIAL_TRANSACTION_PUT,
  MATERIAL_TRANSACTION_POST,
  MATERIAL_TRANSACTION_SEARCH,
  MATERIAL_TRANSACTION_LOADING,
  MATERIAL_TRANSACTION_ERROR,
} from '../../actions/types';

const initialState = {
  materialTransaction: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIAL_TRANSACTION_LOADING:
      return { isLoading: true, isCompleted: true };
    case MATERIAL_TRANSACTION_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MATERIAL_TRANSACTION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_TRANSACTION_SEARCH:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_TRANSACTION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_TRANSACTION_POST:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_TRANSACTION_PUT:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_TRANSACTION_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
