import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  BillBookings,
  CreateBillBooking,
  EditBillBooking,
  Bills,
  CreateBill,
  EditBill,
  DetailBill,
  DetailBillBooking,
  ReportBillBooking,
  MonthyBillBookings,
} from '../views/BMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import BillHeader from 'views/BMS/BillHeader';
import BillHeaderCreate from 'views/BMS/BillHeaderCreate';
import BillheaderEdit from 'views/BMS/BillHeaderEdit';

export function BMMS() {
  const module = 'BMMS';
  const prefix = '/bmms';
  const name = 'การออกบิล';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const { pathname } = useLocation();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : BMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/bill`} />
              <Route exact path={`${prefix}/bill`}>
                <Bills title={'บิล'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill-header`}>
                <BillHeader title={'หัวบิล'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill-header/create`}>
                <BillHeaderCreate title={'เพิ่มหัวบิล'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill-header/edit/:id`}>
                <BillheaderEdit title={'แก้ไขหัวบิล'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill/create`}>
                <CreateBill title={'สร้างบิล'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill/edit/:id`}>
                <EditBill title={'แก้ไขบิล'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/bill/view/:id`}>
                <DetailBill title={'รายละเอียดบิล'} subtitle={name} />
              </Route>
              {/* {accessRight(me, module, 1) && <Switch></Switch>} */}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default BMMS;
