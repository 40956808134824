import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Profile } from '../views/Account';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function Account() {
  const module = 'Account';
  const prefix = '/account';
  const name = 'ข้อมูลส่วนตัว';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : Account');

    dispatch(actions.meGet());
    dispatch(actions.informationAll());

    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              {/* <Redirect exact from={`${prefix}`} to={`${prefix}`} /> */}
              <Route exact path={`${prefix}`}>
                <Profile title={'แก้ไขข้อมูลส่วนตัว'} subtitle={name} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default Account;
