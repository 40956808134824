import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import WheelListTable from '../../components/Table/WMS/WheelListTable';
import FilterCardWheel from '../../components/Card/WMS/FilterCardWheel';
import { CSVUploadModal } from '../../components/Modal';

export default function WheelStore({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const wheel = useSelector((state) => state.wheel);
  const wheelType = useSelector((state) => state.wheelType);
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [statusWheel, setStatusWheel] = useState('');
  const [wheelTypeSelect, setWheelTypeSelect] = useState('');
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  useEffect(() => {
    const type = wheelTypeSelect._id;
    dispatch(actions.wheelAll({ name, page, size, statusWheel }));
    dispatch(actions.wheelTypeAll({}));
    dispatch(actions.wheelSupplyAll({}));
    return () => {};
  }, [name, page, size, statusWheel]);

  const tableProps = {
    page,
    size,
    setPage,
    setSize,
    handleDelete: async (id) => {
      // console.log('id', id);
      try {
        const confirm = window.confirm('ยืนยันลบข้อมูล');
        if (confirm) {
          await dispatch(actions.wheelDelete(id));
          await dispatch(actions.wheelAll({ name, page, size, statusWheel }));
        }
      } catch (error) {
        alert('เกิดข้อผิดพลาด', error?.message);
      }
    },
    handleEdit: (id) => history.push(`/wms/store/editwheel/${id}`),
    handleInfo: (id) => history.push(`/wms/store/info/${id}`),
  };
  const filterProps = {
    statusWheel,
    setStatusWheel,
    setName,
    name,
    wheelTypeSelect,
    setWheelTypeSelect,
    wheelType,
    wheelSupply,
  };
  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.wheelCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.wheelAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มรถไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการรถได้');
    }
  };
  const renderCSVUploadModal = () => (
    <CSVUploadModal
      csvData={csvData}
      setCsvData={setCsvData}
      fileTemplateURL="/filetemplate/Wheel.csv"
      handleAddFromFile={handleAddFromFile}
      handleClose={handleCSVClose}
      isOpen={csvUploadOpen}
      titleName="รุ่นยาง"
      typeRows={wheelSupply}
    />
  );

  const rederTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <Stack direction="row" spacing={2}>
        <Button variant="contained" component={Link} to={'/wms/store/add'}>
          เพิ่ม
        </Button>
        {/* <Button variant="contained" color="success" onClick={handleCSVOpen}>
          อัปโหลดยาง
        </Button> */}
        <Button
          variant="contained"
          color="warning"
          component={Link}
          to={'/wms/store/manage'}
        >
          จัดการยาง
        </Button>
      </Stack>
    </div>
  );
  const renderFilterCard = () => <FilterCardWheel {...filterProps} />;
  const renderTableWheel = () => (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <div role="alert">
          <p>Something went wrong:</p>
          <pre>{error.message}</pre>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      )}
    >
      <WheelListTable wheel={wheel} {...tableProps} />
    </ErrorBoundary>
  );
  if (wheel.isLoading) {
    return <Loading isLoading />;
  }
  if (!wheel.isLoading && wheel.isCompleted) {
    return (
      <div>
        {rederTitle()}
        {renderAddButtom()}
        {renderCSVUploadModal()}
        {renderFilterCard()}
        {renderTableWheel()}
      </div>
    );
  }
  return (
    <div>
      <h1>404</h1>
    </div>
  );
}

WheelStore.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WheelStore.defaultProps = {
  title: '',
  subtitle: '',
};
