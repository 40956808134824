import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CONFIG } from '../../utils/constants';
import { BackButton } from '../../components/Button';
import { MaterialStockFillForm } from '../../components/Forms';

function MaterialStockFill({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const customer = useSelector((state) => state.customer);
  const place = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const { control, handleSubmit, setValue, watch } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [selectMaterialType, setSelectMaterialType] = useState('');

  const fetchMaterial = () => {
    dispatch(
      actions.materialAll({
        name,
        page,
        size,
        selectMaterialType,
        noStockLot: true,
        stockStatus: '',
      }),
    );
  };

  useEffect(() => {
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.materialTransactionTypeAll({}));
    dispatch(actions.customerAll({}));
    dispatch(actions.placeAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.materialEmpty());
    return () => {};
  }, []);

  useEffect(() => {
    if (selectMaterialType) {
      fetchMaterial();
    }
    return () => {};
  }, [name, page, size, selectMaterialType]);

  useEffect(() => {
    if (material?.rows && !_.isEmpty(material?.rows)) {
      setValue(
        'arr',
        _.map(material?.rows, (eachMaterialRow) => ({
          material: eachMaterialRow,
        })),
      );
    } else {
      setValue('arr', []);
    }

    return () => {};
  }, [material]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleFillStock = async (data) => {
    const materialStockPayload = _.map(
      _.filter(
        data.arr,
        (eachElement) =>
          eachElement?.quantity &&
          eachElement?.quantity !== '' &&
          eachElement?.quantity !== 0,
      ),
      (eachRequestAdding) => ({
        ...eachRequestAdding,
        status: 'IN_STOCK',
        amount: eachRequestAdding?.quantity,
        price:
          parseFloat(eachRequestAdding?.price_per_unit) *
          parseFloat(eachRequestAdding?.quantity),
      }),
    );

    const payload = {
      material_transaction_type: data.material_transaction_type,
      recording_date: new Date(),
      purchase_date: new Date(),
      receipt_date: new Date(),
      recipient_name: 'เพิ่มอัตโนมัติจากระบบเติมสต็อก',
      request_name: 'เพิ่มอัตโนมัติจากระบบเติมสต็อก',
      material: materialStockPayload,
    };

    console.log('Material Stock Lot Payload', payload);
    try {
      const confirm = window.confirm('ยืนยันการเพิ่มสต็อก');
      if (confirm) {
        await dispatch(actions.materialStockLotCreate(payload));
        alert('สำเร็จ');
        if (material?.currPage !== material?.lastPage) {
          setPage(page + 1);
        }
      }
    } catch (err) {
      alert(`การเพิ่มสต็อกผิดพลาด ${err?.message || ''}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              size="small"
              options={materialType?.rows}
              autoHighlight
              getOptionLabel={(option) => `${option?.name || ''}`}
              renderOption={(props, option) => (
                <MenuItem {...props}>{option?.name} </MenuItem>
              )}
              onChange={(e, value) => {
                setSelectMaterialType(value?._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="หมวดหมู่"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            {/* <Select
              label="เลือกหมวดหมู่"
              onChange={(event) => setSelectMaterialType(event.target.value)}
            >
              {_.map(materialType?.rows, (eachMaterialType, index) => (
                <MenuItem key={index} value={eachMaterialType?._id}>
                  {index + 1}. {eachMaterialType?.name}{' '}
                </MenuItem>
              ))}
            </Select> */}
          </FormControl>
        </CardContent>
      </Card>
    </div>
  );

  const renderForm = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <form>
            <div className="flex justify-between my-2">
              {' '}
              <div>
                หมวดหมู่
                {_.find(
                  materialType?.rows,
                  (eachMaterial) => eachMaterial?._id === selectMaterialType,
                )?.name || ''}{' '}
              </div>
              <div className="font-sansserif text-lg">
                หน้าที่ {material?.currPage} จาก {material?.lastPage}
              </div>
            </div>

            <MaterialStockFillForm
              control={control}
              material={material}
              customer={customer}
              watch={watch}
              setValue={setValue}
              place={place}
              materialTransactionType={materialTransactionType}
            />

            <div className="flex justify-center gap-2 my-2">
              {material?.currPage === 1 ? (
                ''
              ) : (
                <Button
                  variant="contained"
                  color="inherit"
                  type="button"
                  onClick={() => setPage(page - 1)}
                >
                  กลับไปก่อนหน้าโดยไม่บันทึก
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleSubmit(handleFillStock)}
              >
                เพิ่มลงในคลัง
                {material?.currPage === material?.lastPage
                  ? ''
                  : 'และไปหน้าถัดไป'}
              </Button>

              {material?.currPage === material?.lastPage ? (
                ''
              ) : (
                <Button
                  variant="contained"
                  color="inherit"
                  type="button"
                  onClick={() => setPage(page + 1)}
                >
                  ไปหน้าถัดไปโดยไม่บันทึก
                </Button>
              )}
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div>
          <BackButton />
        </div>
        {renderSearch()}
        {selectMaterialType && renderForm()}
      </div>
    );
  }
  return <Error message={material?.message} />;
}

MaterialStockFill.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialStockFill.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialStockFill;
