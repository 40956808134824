import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { Hvac } from '@mui/icons-material';
import Loading from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import WheelTypeTable from '../../components/Table/WMS/WheelTypeTable';

export default function WheelType({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const wheelType = useSelector((state) => state.wheelType);
  useEffect(() => {
    dispatch(actions.wheelTypeAll({}));

    return () => {};
  }, []);
  // console.log('wheelType', wheelType);
  const renderTable = () => <WheelTypeTable wheelType={wheelType} {...props} />;
  const renderButton = () => (
    <div className="flex justify-end">
      <div className="pb-2">
        <Button
          variant="contained"
          component={Link}
          to={'/wms/wheel-types/create'}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );
  const props = {
    handleEdit: (id) => history.push(`wheel-types/edit/${id}`),
    hanldeDelete: async (id) => {
      const confirm = window.confirm('ลบชนิดยาง');
      if (confirm) {
        try {
          await dispatch(actions.wheelTypeDelete(id));
          await dispatch(actions.wheelTypeAll({}));
        } catch (error) {}
      }
    },
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  if (wheelType.isLoading || !wheelType.isCompleted) {
    return <Loading />;
  }
  if (!wheelType.isLoading || wheelType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderButton()}
        {renderTable()}
      </div>
    );
  }
  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold text-red-500"> 404 </h1>
      </div>
    </div>
  );
}
WheelType.PropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
WheelType.defaultProps = {
  title: '',
  subtitle: '',
};
