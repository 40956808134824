import {
  REFUELTRANSITION_ALL,
  REFUELTRANSITION_GET,
  REFUELTRANSITION_POST,
  REFUELTRANSITION_PUT,
  REFUELTRANSITION_DELETE,
  REFUELTRANSITION_LOADING,
  REFUELTRANSITION_ERROR,
} from '../../actions/types';

const initialState = {
  trailerTail: null,
  isLoading: true,
  isCompleted: true,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case REFUELTRANSITION_LOADING:
      return { isLoading: true, isCompleted: true };
    case REFUELTRANSITION_ERROR:
      return { isLoading: false, isCompleted: false };
    case REFUELTRANSITION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case REFUELTRANSITION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case REFUELTRANSITION_POST:
      return { isLoading: false, isCompleted: true };
    case REFUELTRANSITION_PUT:
      return { isLoading: false, isCompleted: true };
    case REFUELTRANSITION_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
