/* eslint-disable import/prefer-default-export */
import {
  DELIVERY_ORDER_ALL,
  DELIVERY_ORDER_GET,
  DELIVERY_ORDER_POST,
  DELIVERY_ORDER_PUT,
  DELIVERY_ORDER_DEL,
  DELIVERY_ORDER_LOADING,
  DELIVERY_ORDER_ERROR,
  DELIVERY_ORDER_HISTORY,
} from '../types';

import api from '../../../utils/functions/api';

export const deliveryOrderCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DELIVERY_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/delivery-order`,
      payload,
    );
    dispatch({ type: DELIVERY_ORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DELIVERY_ORDER_ERROR });
    throw new Error(error);
  }
};

export const deliveryOrderAll =
  ({
    name = '',
    size = null,
    page = 1,
    ready = '',
    completed = '',
    deleted = '',
    assignmentType = '',
    jobStatus = '',
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/delivery-order?name=${name}&size=${size}&page=${page}&ready=${ready}&delete=${deleted}&completed=${completed}&assignmentType=${assignmentType}&jobStatus=${jobStatus}`,
      );
      if (status === 200) {
        dispatch({ type: DELIVERY_ORDER_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: DELIVERY_ORDER_ERROR });
      throw new Error(error);
    }
  };

export const deliveryOrderHistory =
  ({
    name = '',
    size = null,
    page = 1,
    completed = true,
    deleted = true,
    assignmentType = '',
    history = true,
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/delivery-order?name=${name}&size=${size}&page=${page}&delete=${deleted}&completed=${completed}&assignmentType=${assignmentType}&history=${history}`,
      );
      if (status === 200) {
        dispatch({ type: DELIVERY_ORDER_HISTORY, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: DELIVERY_ORDER_ERROR });
      throw new Error(error);
    }
  };

export const deliveryOrderGet = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELIVERY_ORDER_LOADING });
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/delivery-order/${id}`,
    );
    if (status === 200) {
      dispatch({ type: DELIVERY_ORDER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DELIVERY_ORDER_ERROR });
    throw new Error(error);
  }
};

export const deliveryOrderPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: DELIVERY_ORDER_LOADING });
    const { data, status } = await api.put(
      `${process.env.REACT_APP_API_URL}/delivery-order/${id}`,
      payload,
    );
    if (status === 200) {
      dispatch({ type: DELIVERY_ORDER_PUT, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DELIVERY_ORDER_ERROR });
    throw new Error(error);
  }
};

export const deliveryOrderDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELIVERY_ORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/delivery-order/${id}`,
    );
    dispatch({ type: DELIVERY_ORDER_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DELIVERY_ORDER_ERROR });
    throw new Error(error);
  }
};
