/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Card, TableBody, TableCell, TableRow, Table } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export default function WheelInfoCard({ wheel }) {
  console.log(' wheel', wheel);
  return (
    <div className="pt-4">
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <h1 className="font-bold">เลขยาง</h1>
              </TableCell>
              <TableCell>
                <h1>{wheel?.wheelNumber}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">ยี่ห้อ</h1>
              </TableCell>
              <TableCell>
                <h1>{wheel?.supply?.brand}</h1>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <h1 className="font-bold">วันที่ผลิต</h1>
              </TableCell>
              <TableCell>
                <h1>{wheel?.supply?.mfg}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">วันที่ใช้</h1>
              </TableCell>
              <TableCell>
                <h1>{dayjs(wheel?.dateIn).format('DD/MM/BBBB')}</h1>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <h1 className="font-bold">เลขไมล์วันใส่ (กม.)</h1>
              </TableCell>
              <TableCell>
                <h1>{wheel?.mileageIn}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">เลขไมล์วันถอด (กม.)</h1>
              </TableCell>
              <TableCell>
                <h1>{wheel?.mileageOut}</h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="font-bold text-l">ระยะทางใช้งาน (กม.)</h1>
              </TableCell>
              <TableCell>
                <h1>{wheel?.mileageOut - wheel?.mileageIn || 0}</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold">ดอกยาง</h1>
              </TableCell>
              <TableCell>
                <h1>{wheel?.thred}</h1>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}
WheelInfoCard.propTypes = {
  wheel: PropTypes.object,
};
