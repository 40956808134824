import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { GarageForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';

export default function EditGarage({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const garage = useSelector((state) => state.garage);
  const history = useHistory();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: garage,
  });

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(actions.garageGet(id));
    };

    fetchData();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log('data', data);
      await dispatch(actions.garagePut(id, data));
      await dispatch(actions.garageGet(id));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (garage.isLoading || garage.rows) {
    return <Loading />;
  }
  if (!garage.isLoading && garage.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <GarageForm control={control} errors={errors} garage={garage} />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}
EditGarage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditGarage.defaultProps = {
  title: '',
  subtitle: '',
};
