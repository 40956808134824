import React from 'react';

import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

import _ from 'lodash';

export default function DounutPriceChart({ data }) {
  console.log('DATA in price', data);
  const labels = data?.map((row) => (row?._id ? 'ซ่อมภายใน' : 'ซ่อมภายนอก'));
  return (
    <div>
      <ReactApexChart
        type="donut"
        series={data?.map((row) => row?.total_price)}
        options={{
          labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          legend: {
            position: 'top',
          },
        }}
      />
    </div>
  );
}
DounutPriceChart.propTypes = {
  data: PropTypes.object,
};
