import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { MaintenanceOrderCard } from '../../components/Card';
import { MaintenanceOrderReport } from '../../components/Report';

export default function DetailMaintenanceOrderHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const information = useSelector((state) => state.information);

  useEffect(() => {
    dispatch(actions.maintenanceOrderGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePrint = () =>
    MaintenanceOrderReport(maintenanceOrder, information);

  if (maintenanceOrder.isLoading || maintenanceOrder.rows) {
    return <Loading />;
  }
  if (!maintenanceOrder.isLoading && maintenanceOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <div>
            <BackButton />
          </div>
          <div>
            <Button variant="contained" onClick={handlePrint}>
              พิมพ์
            </Button>
          </div>
        </div>
        <div>
          <Card className="p-4">
            <MaintenanceOrderCard maintenanceOrder={maintenanceOrder} />
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}
DetailMaintenanceOrderHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaintenanceOrderHistory.defaultProps = {
  title: '',
  subtitle: '',
};
