/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import _ from 'lodash';
// TODO เพิ่มการทำงานส่วนของ delivery order อาจต้องปรับ filter ส่วนของสถานะและการดึงข้อมูลรายเดือน
const setPropertyVehicleScheduleEvent = (row) => {
  const type = row.schedule_type;
  const jobName = row?.delivery_orders[0]?.job?.name || '';
  const header = row?.vehicle?.licence_number
    ? `/ หัวรถ ${row?.vehicle?.licence_number}`
    : '';
  const trailerTail = row?.trailer_tail?.licence_number
    ? `/ หางรถ ${row?.vehicle?.licence_number}`
    : '';
  const container = row?.container?.licence_number
    ? `/ ตู้ ${row?.container?.licence_number}`
    : '';
  const containerSize = row?.container?.size
    ? `/ ขนาดตู้ ${row?.container?.size}`
    : '';

  if (row.schedule_type === 'IMPORT') {
    if (row.started && !row.pending) {
      // STARTED
      return {
        status: 'STARTED',
        title: `${type} ${jobName} ${header} ${trailerTail} ${container} ${containerSize}`,
        start: moment(row.start_date).startOf('day').toLocaleString(),
        end: moment(row.start_date).endOf('day').toLocaleString(),
        backgroundColor: '#22c55e',
      };
    }
    if (row.started && row.pending && !row.completed) {
      // PENDING
      return {
        status: 'PENDING',
        title: `${type} ${jobName} ${header} ${trailerTail} ${container} ${containerSize}`,
        start: moment(row.end_date).startOf('day').toLocaleString(),
        end: moment(row.end_date).endOf('day').toLocaleString(),
        backgroundColor: '#4c1d95',
      };
    }
    if (row.started && row.pending && row.completed && !row.billed) {
      // COMPLETED
      return {
        status: 'COMPLETED',
        title: `${type} ${jobName}`,
        start: moment(row.end_date).startOf('day').toLocaleString(),
        end: moment(row.end_date).endOf('day').toLocaleString(),
        backgroundColor: '#8b5cf6',
      };
    }
    if (row.started && row.pending && row.completed && row.billed) {
      // BILLED
      return {
        status: 'BILLED',
        title: `${type} ${jobName}`,
        start: moment(row.end_date).startOf('day').toLocaleString(),
        end: moment(row.end_date).endOf('day').toLocaleString(),
        backgroundColor: '#8b5cf6',
      };
    }
  } else if (row.schedule_type === 'EXPORT') {
    if (row.started && !row.pending) {
      // STARTED
      return {
        status: 'STARTED',
        title: `${type} ${jobName} ${header} ${trailerTail} ${container} ${containerSize}`,
        start: moment(row.start_date).startOf('day').toLocaleString(),
        end: moment(row.start_date).endOf('day').toLocaleString(),
        backgroundColor: '#22c55e',
      };
    }
    if (row.started && row.pending && !row.completed) {
      // PENDING
      return {
        status: 'PENDING',
        title: `${type} ${jobName} ${header} ${trailerTail} ${container} ${containerSize}`,
        start: moment(row.end_date).startOf('day').toLocaleString(),
        end: moment(row.end_date).endOf('day').toLocaleString(),
        backgroundColor: '#7f1d1d',
      };
    }
    if (row.started && row.pending && row.completed && !row.billed) {
      // COMPLETED
      return {
        status: 'COMPLETED',
        title: `${type} ${jobName}`,
        start: moment(row.end_date).startOf('day').toLocaleString(),
        end: moment(row.end_date).endOf('day').toLocaleString(),
        backgroundColor: '#ef4444',
      };
    }
    if (row.started && row.pending && row.completed && row.billed) {
      // BILLED
      return {
        status: 'BILLED',
        title: `${type} ${jobName}`,
        start: moment(row.end_date).startOf('day').toLocaleString(),
        end: moment(row.end_date).endOf('day').toLocaleString(),
        backgroundColor: '#ef4444',
      };
    }
  }

  return {};
};

const setPropertyDeliveryOrderEvent = (row) => {
  const type = row.assignment_type;
  const jobName = row?.job?.name || '';

  if (type === 'IMPORT') {
    if (row.started && !row.ready) {
      // STARTED
      return {
        status: 'STARTED',
        type,
        title: `${type} ${jobName}`,
        start: moment(row.start_date).startOf('day').toLocaleString(),
        end: moment(row.start_date).endOf('day').toLocaleString(),
        backgroundColor: '#d946ef',
      };
    }
    if (row.started && row.ready && !row.completed) {
      // READY
      return {
        status: 'READY',
        title: `${type} ${jobName}`,
        start: moment(row.start_date).startOf('day').toLocaleString(),
        end: moment(row.start_date).endOf('day').toLocaleString(),
        backgroundColor: '#22c55e',
      };
    }
  } else if (type === 'EXPORT') {
    if (row.started && !row.ready) {
      // STARTED
      return {
        status: 'STARTED',
        title: `${type} ${jobName}`,
        start: moment(row.start_date).startOf('day').toLocaleString(),
        end: moment(row.start_date).endOf('day').toLocaleString(),
        backgroundColor: '#f97316',
      };
    }
    if (row.started && row.ready && !row.completed) {
      // READY
      return {
        status: 'READY',
        title: `${type} ${jobName}`,
        start: moment(row.start_date).startOf('day').toLocaleString(),
        end: moment(row.start_date).endOf('day').toLocaleString(),
        backgroundColor: '#22c55e',
      };
    }
  }

  return {};
};

export function convertVehicleScheduelToEvent(rows) {
  return _.map(rows, (row) => {
    const convertRow = {
      type: row.schedule_type,
      id: row._id,
      allDay: true,
      isVehicleSchedule: true,
      ...setPropertyVehicleScheduleEvent(row),
    };
    return convertRow;
  });
}

export function convertDeliveryOrderToEvent(rows) {
  return _.map(rows, (row) => {
    const convertRow = {
      type: row.assignment_type,
      id: row._id,
      allDay: true,
      isVehicleSchedule: false,
      ...setPropertyDeliveryOrderEvent(row),
    };
    return convertRow;
  });
}
