import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { CONFIG } from '../../utils/constants';
import { BillForm } from '../../components/Forms';
import { dayjs } from '../../utils/functions';
import { SelectableBillBookingTable } from '../../components/Table';

function EditBill({ title, subtitle }) {
  const dispatch = useDispatch();
  const billBooking = useSelector((state) => state.billBooking);
  const bill = useSelector((state) => state.bill);
  const customer = useSelector((state) => state.customer);
  const params = useParams();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectedBooking, setSelectedBooking] = useState([]);
  const [reload, setReload] = useState(false);
  const [ready, setIsReady] = useState(false);

  const getAllBillBooking = async () => {
    try {
      dispatch(
        actions.billBookingAll({
          name,
          page,
          size,
          completed: false,
          date:
            selectedDate !== '' ? dayjs(selectedDate).format('YYYY-MM-DD') : '',
        }),
      );
    } catch (err) {
      alert(`ไม่สามารถดึงรายการออกบิลได้ ${err?.message}`);
    }
  };

  const handleChangeBillBookingInComplete = async (
    billBookingId,
    allSelectedBillBookings,
  ) => {
    try {
      await dispatch(
        actions.billPut(params.id, {
          bookings: allSelectedBillBookings,
          bookingOutOfBill: billBookingId,
        }),
      );
      alert('สำเร็จ');
      await getAllBillBooking();
      dispatch(actions.billGet(params.id));
    } catch (error) {
      alert(`แก้ไขไม่สำเร็จ ${error?.message}`);
      console.error('Error on Edit Bill', error);
    }
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    getAllBillBooking();
  }, [name, page, size, selectedDate]);

  useEffect(() => {
    setTotal(billBooking?.total);
    return () => {};
  }, [billBooking]);

  useEffect(() => {
    dispatch(actions.customerAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.billGet(params.id));
    return () => {};
  }, [params]);

  useEffect(() => {
    if (bill && !bill?.rows) {
      setSelectedBooking(
        _.map(bill?.bookings, (eachBooking) => ({
          ...eachBooking,
          alreadyCreated: true,
        })),
      );
      setValue('additional_price', bill?.additional_price);
      setValue('customer', bill?.customer);
      setValue('remark', bill?.remark);
      setValue('invoice_number', bill?.invoice_number);
      setValue('date', dayjs(bill?.date)?.format('YYYY-MM-DD'));
      setIsReady(true);
    }

    return () => {};
  }, [bill]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSubmitBill = async (data) => {
    try {
      await dispatch(
        actions.billPut(params.id, { ...data, bookings: selectedBooking }),
      );
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`แก้ไขบิลไม่สำเร็จ ${error?.message}`);
      console.error('Error on Create Bill', error);
    }
  };

  const tableFunction = {
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleDelete: async (id) => {
      const confirm = window.confirm('ยืนยันการลบ Booking');
      if (confirm) {
        await dispatch(actions.billBookingDelete(id));
        await dispatch(actions.billBookingAll({ name, page, size }));
      }
    },
    page,
    size,
    total,
    billBookings: billBooking?.rows,
    searchDate: selectedDate,
    setSearchDate: setSelectedDate,
    searchTerm,
    setSearchTerm,
    selectedBooking,
    setSelectedBooking,
    reload,
    setReload,
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-4">
      <form onSubmit={handleSubmit(handleSubmitBill)}>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 px-2">
            <SelectableBillBookingTable {...tableFunction} />
          </div>
          <div className="w-full lg:w-1/2 px-2">
            {ready && (
              <BillForm
                control={control}
                customer={customer}
                errors={errors}
                selectedBooking={selectedBooking}
                setSelectedBooking={setSelectedBooking}
                reload={reload}
                setReload={setReload}
                handleChangeBillBookingInComplete={
                  handleChangeBillBookingInComplete
                }
              />
            )}
          </div>
        </div>
        <div className="flex justify-end">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  if (bill.isLoading) {
    return <Loading />;
  }

  if (!bill.isLoading && bill.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

EditBill.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditBill.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditBill;
