import React from 'react';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const OnProcessMaterialDecreaseForm = ({
  control,
  materialTransactionType,
}) => (
  <div>
    <div className="w-full my-2">
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 py-2 px-2">
              <Controller
                control={control}
                name="material_transaction_type"
                defaultValue={
                  _.find(materialTransactionType?.rows, {
                    direction: 'desc',
                  })?._id
                }
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>ประเภทการดำเนินการ</InputLabel>{' '}
                    <Select
                      fullWidth
                      size="small"
                      label="ประเภทการดำเนินการ"
                      {...field}
                    >
                      {_.map(
                        _.filter(materialTransactionType?.rows, {
                          direction: 'desc',
                        }),
                        (_txnType, index) => (
                          <MenuItem key={index} value={_txnType?._id}>
                            {_txnType?.name}{' '}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                )}
              />
            </div>{' '}
            <div className="w-full lg:w-1/2 py-2 px-2">
              <Controller
                control={control}
                name="remark"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={2}
                    label="หมายเหตุ"
                  />
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
);

OnProcessMaterialDecreaseForm.propTypes = {
  control: PropTypes.func,
  materialTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default OnProcessMaterialDecreaseForm;
