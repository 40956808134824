import {
  ZONE_PRICE_ALL,
  ZONE_PRICE_GET,
  ZONE_PRICE_PUT,
  ZONE_PRICE_DELETE,
  ZONE_PRICE_POST,
  ZONE_PRICE_RESET,
  ZONE_PRICE_LOADING,
  ZONE_PRICE_ERROR,
} from '../../actions/types';

const initialState = {
  job: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function ZonePriceReducer(state = initialState, action) {
  switch (action.type) {
    case ZONE_PRICE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ZONE_PRICE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ZONE_PRICE_POST:
      return { isLoading: false, isCompleted: true };
    case ZONE_PRICE_PUT:
      return { isLoading: false, isCompleted: true };
    case ZONE_PRICE_DELETE:
      return { isLoading: false, isCompleted: true };
    case ZONE_PRICE_RESET:
      return { ZONE_PRICE: null, isLoading: true, isCompleted: true };
    case ZONE_PRICE_LOADING:
      return { isLoading: true, isCompleted: true };
    case ZONE_PRICE_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
