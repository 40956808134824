import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const PricingTable = ({ selectedCostMatrix }) => {
  const renderEachPricingTable = (costMatrix) => (
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <div className="font-bold">ราคาน้ำมัน</div>
          </TableCell>
          <TableCell>
            <div className="font-bold">ราคา</div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!_.isEmpty(costMatrix) ? (
          _.map(costMatrix, (row) => (
            <TableRow
              key={row._id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell>{row?.gas_price} บาท </TableCell>
              <TableCell>{row?.price} บาท </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <div className="text-center">ไม่มีข้อมูล</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  const constMatrixSize = _.size(selectedCostMatrix);
  const firstHalfData = _.take(selectedCostMatrix, constMatrixSize / 2);
  const secondHalfData = _.takeRight(selectedCostMatrix, constMatrixSize / 2);
  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-1/2 px-2">
          {renderEachPricingTable(firstHalfData)}
        </div>
        <div className="w-1/2 px-2">
          {renderEachPricingTable(secondHalfData)}
        </div>
      </div>
    </div>
  );
};

PricingTable.propTypes = {
  selectedCostMatrix: PropTypes.object,
};

export default PricingTable;
