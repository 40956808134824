import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { MaterialForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const defaultValues = {
  type_code: '',
  name: '',
  unit: '',
  material_type: '',
  net: '',
  unit_net: '',
  minimum_amount: '',
  detail: '',
  customer_type: '',
  register_number: '',
};
export default function CreateMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const customerType = useSelector((state) => state.customerType);
  const customer = useSelector((state) => state.customer);
  const place = useSelector((state) => state.place);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const history = useHistory();

  const [selectCustomerType, setSelectCustomerType] = useState();
  const [selectCustomer, setSelectCustomer] = useState([]);

  useEffect(() => {
    dispatch(actions.materialAll({}));
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.customerTypeAll({}));
    dispatch(actions.placeAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    if (selectCustomerType) {
      dispatch(actions.customerAll({ customerType: selectCustomerType }));
    }
    return () => {};
  }, [selectCustomerType]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (_.isEmpty(selectCustomer)) {
        const supplier = [];
        await dispatch(actions.materialCreate({ ...data, supplier }));
      } else {
        const supplier = _.map(selectCustomer, (select) => select?._id);
        await dispatch(actions.materialCreate({ ...data, supplier }));
      }
      alert('สำเร็จ');
      setSelectCustomer([]);
      setSelectCustomerType('');
      reset(defaultValues);
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  if (material.isLoading) {
    return <Loading />;
  }

  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <MaterialForm
                errors={errors}
                material={material}
                place={place}
                control={control}
                Controller={Controller}
                materialType={materialType}
                customerType={customerType}
                selectCustomerType={selectCustomerType}
                setSelectCustomerType={setSelectCustomerType}
                customer={customer}
                setSelectCustomer={setSelectCustomer}
                selectCustomer={selectCustomer}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }

  return <Error message={material?.message} />;
}

CreateMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
