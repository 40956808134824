import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CONFIG } from '../../utils/constants';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { MaintenanceOrderCard, MaterialCard } from '../../components/Card';

import { RequestMaterialForm } from '../../components/Forms';

export default function MaterialWithdrawRequest({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);

  const me = useSelector((state) => state.me);
  const material = useSelector((state) => state.material);
  const { control, handleSubmit } = useForm();

  // Get Transaction of Withdraw and Return of Material
  const getProcessMaterial = async () => {
    try {
      dispatch(
        actions.processMaterialAll({
          maintenanceOrder: params.id,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.log('Get Process Material Error', error);
    }
  };

  const getmaintenanceOrder = async () => {
    try {
      dispatch(actions.maintenanceOrderGet(params.id));
    } catch (error) {
      console.log('Get process Error', error);
    }
  };

  const fetchMaterials = async () => {
    try {
      await dispatch(
        actions.materialAll({
          stockStatus: '',
          noStockLot: true,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Error on Fetch Materials', error);
    }
  };

  useEffect(() => {
    getmaintenanceOrder();
    getProcessMaterial();
    fetchMaterials();
  }, [params]);

  const onSubmit = async (data) => {
    try {
      console.log('Data', data);
      const payload = {
        ...data,
        requester: me?.userData?._id,
        maintenance_order: maintenanceOrder?._id,
      };
      await dispatch(actions.materialRequestCreate(payload));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`เกิดข้อผิดพลาดในการขอเบิกวัสดุ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  const renderMaintenanceOrder = () => (
    <div>
      <MaintenanceOrderCard
        maintenanceOrder={maintenanceOrder}
        showDate={false}
        showDriver={false}
        showStatus={false}
        showImages={false}
      />
    </div>
  );

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <div className="font-semibold"> รายการวัสดุที่ต้องการ</div>
            <RequestMaterialForm control={control} material={material} />
          </CardContent>
        </Card>
        <div className="w-full flex my-2 justify-end">
          <Button variant="contained" type="submit">
            ยืนยันการเบิก
          </Button>
        </div>
      </form>
    </div>
  );

  const renderMaterialCard = () => (
    <div>
      <div className="my-2">
        <MaterialCard materialArrays={maintenanceOrder?.process_materials} />
      </div>
    </div>
  );
  if (maintenanceOrder?.isLoading) {
    return <Loading />;
  }
  if (!maintenanceOrder?.isLoading && maintenanceOrder?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderMaintenanceOrder()}
        {renderMaterialCard()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

MaterialWithdrawRequest.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialWithdrawRequest.defaultProps = {
  title: '',
  subtitle: '',
};
