import { BackButton } from 'components/Button';
import CreateWheelTypeCard from '../../components/Card/WMS/CreateWheelType';
import { ViewTitle } from 'components/ViewTitle';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { useHistory } from 'react-router';

export default function CreateWheelType({ title, subtitle }) {
  const { handleSubmit, control } = useForm({});
  const dispatch = useDispatch();
  const history = useHistory();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButtom = () => <BackButton />;
  const onSubmitData = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      try {
        console.log('data', data);
        await dispatch(actions.wheelTypeCreate(data));
        await dispatch(actions.wheelTypeAll({}));
        history.push('/wms/wheel-types');
      } catch (error) {
        console.log('error', error);
        alert('บันทึกข้อมูลผิดพลาด');
      }
    }
  };
  const renderCard = () => (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <CreateWheelTypeCard control={control} Controller={Controller} />
    </form>
  );
  return (
    <div>
      <div>
        {renderTitle()}
        {renderBackButtom()}
        {renderCard()}
      </div>
    </div>
  );
}
