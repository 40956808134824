/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Alert,
  Button,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes, { oneOfType } from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { ImageUpload } from '../../ImageUpload';
import { MAINTENANCE_LEVEL } from '../../../utils/constants';
import AttachMaintenanceTopicForm from './AttachMaintenanceTopicForm';
import PlaceForMaintenanceForm from './PlaceForMaintenanceForm';
import OuterServicesForm from './OuterServicesForm';
import AttachVehicleForm from './AttachVehicleForm';
import AttachDriverForm from './AttachDriverForm';
import MaintenerForm from './MaintenerForm';
import { DatePicker, DateTimePicker } from '../../Datepicker';
import AttachMachineryForm from './AttachMachineryForm';
import UploadFile from '../CRM/UploadFile';

const MaintenanceOrderForm = ({
  maintenanceTypes,
  maintenanceTopics,
  setSelectedType,
  containers,
  trailerTails,
  vehicles,
  images,
  maintenanceOrder,
  garage,
  setImages,
  drivers,
  employee,
  machinery,
  // Handling
  handleCreateMaintenanceTopic,
  handleCreateGarage,
  handleCreateEmployee,
  handleCreateContainer,
  // React Hook Form
  control,
  errors,
  setValue,
  resetField,
  watch = () => {},
  // Field Toggle
  showDriver = true,
  showVehiclePart = true,
  showImageUpload = true,
  showCause = true,
  showPrice = false,
  showModified = false,
  showGarage = false,
  showStartDateSelector = false,
  showEndDateSelector = false,
  showOuterService = true,

  file,
  setFiles,
  setFileOpen,
  FileOpen,
}) => {
  const information = useSelector((state) => state.information);
  const {
    setting: { cmms: cmmsSetting },
  } = information;
  const showLevel = cmmsSetting?.show_maintenance_level;
  const showMaintenanceType = cmmsSetting?.show_maintenance_type;
  const defaultShowTopicInSelfMaintenance =
    cmmsSetting?.default_show_topic_in_self_maintenance;
  const showPriceInMaintenanceTopic =
    cmmsSetting?.show_price_in_maintenance_topic;
  const showMaintener = cmmsSetting?.attach_maintener;
  const attachTime = cmmsSetting?.attach_time;

  const [driverRendereing, setDriverRendering] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDriverRendering(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [driverRendereing]);

  const renderStartDateSelector = () => (
    <div className="w-full ">
      {' '}
      <div className="w-full px-1 my-2 py-2 font-semibold text-lg">
        วันที่แจ้งซ่อม
      </div>{' '}
      {maintenanceOrder && !maintenanceOrder?.start_date && (
        <div className="my-2">
          <Alert severity="warning">
            ระบบอยู่ระหว่างการพัฒนาที่อาจจะซับซ้อนกับข้อมูลเดิม
            กรุณาตรวจสอบความถูกต้องของวันที่แจ้งซ่อมอีกครั้งนะครับ
          </Alert>
        </div>
      )}
      <div className="w-full  my-2 flex-wrap">
        {attachTime ? (
          <Controller
            name={'start_date'}
            control={control}
            defaultValue={
              maintenanceOrder
                ? maintenanceOrder?.start_date
                  ? dayjs(maintenanceOrder?.start_date).format(
                      'YYYY-MM-DDTHH:mm',
                    )
                  : dayjs(maintenanceOrder?.createdAt).format(
                      'YYYY-MM-DDTHH:mm',
                    )
                : dayjs().format('YYYY-MM-DDTHH:mm')
            }
            render={({ field }) => (
              <div>
                <DateTimePicker
                  label="วันที่แจ้งซ่อม (วัน/เดือน/ปี ชั่วโมง:นาที) "
                  value={field.value}
                  setValue={(value) => field.onChange(value)}
                />
              </div>
            )}
          />
        ) : (
          <Controller
            name={'start_date'}
            control={control}
            defaultValue={
              maintenanceOrder
                ? maintenanceOrder?.start_date
                  ? dayjs(maintenanceOrder?.start_date).format('YYYY-MM-DD')
                  : dayjs(maintenanceOrder?.createdAt).format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD')
            }
            render={({ field }) => (
              <DatePicker
                label="วันที่แจ้งซ่อม (วัน/เดือน/ปี)"
                value={field.value}
                setValue={(value) => field.onChange(value)}
              />
            )}
          />
        )}
      </div>
    </div>
  );

  const renderEndDateSelector = () => (
    <div className="w-full ">
      {' '}
      <div className="w-full px-1 my-2 py-2 font-semibold text-lg">
        วันที่ซ่อมสำเร็จ
      </div>
      <div className="w-full  my-2 flex-wrap">
        {attachTime ? (
          <Controller
            name={'end_date'}
            control={control}
            defaultValue={
              maintenanceOrder
                ? dayjs(maintenanceOrder?.end_date).format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD')
            }
            render={({ field }) => (
              <DateTimePicker
                label="วันที่ซ่อมเสร็จ (วัน/เดือน/ปี ชั่วโมง:นาที)"
                value={field.value}
                setValue={(value) => field.onChange(value)}
              />
            )}
          />
        ) : (
          <Controller
            name={'end_date'}
            control={control}
            defaultValue={
              maintenanceOrder
                ? dayjs(maintenanceOrder?.end_date).format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD')
            }
            render={({ field }) => (
              <DatePicker
                label="วันที่ซ่อมเสร็จ (วัน/เดือน/ปี)"
                value={field.value}
                setValue={(value) => field.onChange(value)}
              />
            )}
          />
        )}
      </div>
    </div>
  );

  const renderVehiclePart = () => (
    <div className="w-full">
      <AttachVehicleForm
        containers={containers}
        control={control}
        errors={errors}
        resetField={resetField}
        setDriverRendering={setDriverRendering}
        setValue={setValue}
        trailerTails={trailerTails}
        vehicles={vehicles}
        handleCreateContainer={handleCreateContainer}
        watch={watch}
      />
    </div>
  );

  const renderMachineryPart = () => (
    <div className="w-full">
      <AttachMachineryForm
        control={control}
        errors={errors}
        vehicles={vehicles}
        machinery={machinery}
      />
    </div>
  );

  const renderDriverPart = () => (
    <div className="w-full">
      <AttachDriverForm
        control={control}
        driverRendereing={driverRendereing}
        drivers={drivers}
        errors={errors}
        maintenanceOrder={maintenanceOrder}
        resetField={resetField}
        watch={watch}
        employee={employee}
        handleCreateEmployee={handleCreateEmployee}
      />
    </div>
  );

  const renderPriceAttachTopicForm = () => (
    <div className="w-full">
      {maintenanceTopics.isCompleted && !maintenanceTopics.isLoading && (
        <AttachMaintenanceTopicForm
          maintenanceTopics={maintenanceTopics}
          showPrice={showPrice}
          showPriceInMaintenanceTopic={showPriceInMaintenanceTopic}
          control={control}
          handleCreateMaintenanceTopic={handleCreateMaintenanceTopic}
          maintenanceTypes={maintenanceTypes}
        />
      )}
    </div>
  );

  // TODO: Add Place for Maintenance Form
  const renderPlaceForMaintenance = () => (
    <div className="w-full flex flex-wrap">
      <div className="w-full px-2">
        <PlaceForMaintenanceForm
          control={control}
          garage={garage}
          handleCreateGarage={handleCreateGarage}
          maintenanceOrder={maintenanceOrder}
          resetField={resetField}
          watch={watch}
        />
      </div>
      <div className="w-full px-2">
        {!watch('is_self_maintenance') ? (
          renderPriceAttachTopicForm()
        ) : (
          <div className="w-full">
            <div className="w-full px-4 ">
              <FormControlLabel
                control={
                  <Controller
                    name={'specify_topics'}
                    control={control}
                    defaultValue={defaultShowTopicInSelfMaintenance}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'ระบุหัวข้อการซ่อมบำรุง'}
              />
            </div>
            {watch('specify_topics') && renderPriceAttachTopicForm()}
          </div>
        )}
      </div>
    </div>
  );
  const renderheaddepartment = () => (
    <div>
      <br /> <div className="px-1  font-semibold text-lg ">แผนกที่แจ้งซ่อม</div>
      <div className="w-full px-4 ">
        <FormControlLabel
          control={
            <Controller
              name={'departmenthead.bg'}
              control={control}
              defaultValue={maintenanceOrder?.departmenthead?.bg}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  defaultChecked={field.value}
                />
              )}
            />
          }
          label={'BG'}
        />
        <FormControlLabel
          control={
            <Controller
              name={'departmenthead.bm'}
              control={control}
              defaultValue={maintenanceOrder?.departmenthead?.bm}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  defaultChecked={field.value}
                />
              )}
            />
          }
          label={'BM'}
        />
        <FormControlLabel
          control={
            <Controller
              name={'departmenthead.ma'}
              control={control}
              defaultValue={maintenanceOrder?.departmenthead?.ma}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'MA'}
        />
        <FormControlLabel
          control={
            <Controller
              name={'departmenthead.am'}
              control={control}
              defaultValue={maintenanceOrder?.departmenthead?.am}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'AM/VM'}
        />
        <FormControlLabel
          control={
            <Controller
              name={'departmenthead.me'}
              control={control}
              defaultValue={maintenanceOrder?.departmenthead?.me}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'ME'}
        />
        <FormControlLabel
          control={
            <Controller
              name={'departmenthead.pd'}
              control={control}
              defaultValue={maintenanceOrder?.departmenthead?.pd}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'PD'}
        />
        <FormControlLabel
          control={
            <Controller
              name={'departmenthead.qc'}
              control={control}
              defaultValue={maintenanceOrder?.departmenthead?.qc}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'QC'}
        />
      </div>
    </div>
  );
  const quickMaintenance = () => (
    <div>
      <div className="w-full px-4 ">
        <FormControlLabel
          control={
            <Controller
              name={'quick'}
              control={control}
              defaultValue={maintenanceOrder?.quick}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'งานด่วน'}
        />
      </div>
    </div>
  );

  const renderMaintenanceType = () => (
    <div className=" w-full">
      <div className="px-1 py-2  font-semibold text-lg ">
        ประเภทการบำรุงรักษา
      </div>
      <div className="w-full px-1 py-2">
        {!_.isEmpty(maintenanceTypes?.rows) && (
          <Controller
            name={'maintenance_type'}
            control={control}
            defaultValue={
              maintenanceOrder?.maintenance_type
                ? maintenanceOrder?.maintenance_type
                : maintenanceTypes?.rows?.[0]
            }
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                {...field}
                name={field.name}
                ref={field.ref}
                fullWidth
                defaultValue={field?.value}
                options={maintenanceTypes?.rows}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option?.type_code} | ${option?.name}`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      fontSize: 15,
                      '& > span': { mr: '10px', fontSize: 18 },
                    }}
                    {...props}
                  >
                    {`${option.type_code} | ${option.name}`}
                  </Box>
                )}
                onChange={(e, value) => {
                  //  console.log('Value', value);
                  if (typeof setSelectedType === 'function') {
                    console.log('SET SELETED TYPE');
                    setSelectedType(value._id);
                  }
                  // try {

                  // } catch (error) {
                  //   console.log('Cannot Set Selected Type');
                  // }
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="ประเภทการบำรุงรักษา"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    helperText={errors?.maintenanceTypes && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            )}
          />
        )}
      </div>{' '}
    </div>
  );

  const renderMaintenacneLevel = () => (
    <div className="w-full px-1 py-2 ">
      <Controller
        name={'maintenance_level'}
        control={control}
        defaultValue={maintenanceOrder?.maintenance_level}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel>ระดับของการแจ้งซ่อม</InputLabel>
            <Select {...field} label="ระดับของการแจ้งซ่อม" fullWidth>
              {_.map(MAINTENANCE_LEVEL, (_eachLevel, index) => (
                <MenuItem key={index} value={_eachLevel.status_code}>
                  {_eachLevel.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </div>
  );

  const renderCauseOfMaintenacne = () => (
    <div className="w-full px-1 py-2">
      <Controller
        name={'cause'}
        control={control}
        defaultValue={''}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={3}
            label="สาเหตุ"
            fullWidth
            helperText={errors.mileage && 'กรุณาใส่ข้อมูล'}
          />
        )}
      />
    </div>
  );

  const renderOuterSeriveMaintenance = () => (
    <div className="w-full px-1 py-2">
      <OuterServicesForm
        control={control}
        handleCreateGarage={handleCreateGarage}
        garage={garage}
      />
    </div>
  );

  const renderModifyMethod = () => (
    <div className="w-full px-1 py-2">
      <Controller
        name={'modify'}
        control={control}
        defaultValue={''}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={2}
            label="วิธีการแก้ไข"
            fullWidth
            helperText={errors.modify && 'กรุณาใส่ข้อมูล'}
          />
        )}
      />
    </div>
  );

  const renderMaintener = () => (
    <div className="w-full px-1 py-2">
      <MaintenerForm control={control} employee={employee} />
    </div>
  );

  const uploadfileMain = () => (
    <form>
      <UploadFile
        isOpen={FileOpen}
        files={file}
        setFiles={setFiles}
        handleClose={() => setFileOpen(false)}
      />
    </form>
  );

  return (
    <div>
      <div className=" flex-row flex-wrap">
        {showStartDateSelector && renderStartDateSelector()}
        <Controller
          name={'norepair'}
          control={control}
          defaultValue={maintenanceOrder?.norepair}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              label="เลขที่ใบแจ้งซ่อม(ถ้ามี)"
              fullWidth
              helperText={errors.norepair && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
        {cmmsSetting?.maintenance_vehicle && (
          <div className="w-full">
            {showVehiclePart && renderVehiclePart()}
            {/* {showDriver && renderDriverPart()} */}
          </div>
        )}
        {/* {cmmsSetting?.maintenance_machinery && renderMachineryPart()} */}
        {showLevel && (
          <div className="w-full px-1 py-2 text-lg font-semibold">
            ระดับของการแจ้งซ่อม
          </div>
        )}
        {showLevel && renderMaintenacneLevel()}
        {showMaintenanceType && renderMaintenanceType()}
        {quickMaintenance()}
        {renderheaddepartment()}
        {showGarage && renderPlaceForMaintenance()}
        {showOuterService && renderOuterSeriveMaintenance()}
        {showCause && renderCauseOfMaintenacne()}
        {showMaintener && renderMaintener()}
        {showEndDateSelector && renderEndDateSelector()}
        {showModified && (
          <div className="px-1 py-2  font-semibold ">วิธีการแก้ไข</div>
        )}
        {showModified && renderModifyMethod()}
        {showImageUpload && (
          <div className="w-full flex flex-wrap px-1 py-2 justify-center">
            <div>
              <ImageUpload
                images={images}
                setImages={setImages}
                maxNumber={5}
              />
            </div>
            <div>
              <div className="mt-2">{uploadfileMain()}</div>
            </div>
            <div className="px-2">
              <Button variant="contained" onClick={() => setFileOpen(true)}>
                อัพโหลดไฟล์
              </Button>
            </div>
            <div>
              {maintenanceOrder?.file && (
                <div className="pl-4">
                  <a href={maintenanceOrder?.file?.url} target="_blank">
                    <h1 className="text-blue-600 font-bold ">
                      {maintenanceOrder?.file?.file_name}
                    </h1>
                  </a>
                </div>
              )}
              {!_.isEmpty(file) && (
                <div className="pl-4">
                  <h1 className="font-bold">ไฟล์ใหม่</h1>
                  <h1>{file?.[0]?.name}</h1>
                </div>
              )}{' '}
            </div>
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
};

MaintenanceOrderForm.propTypes = {
  control: oneOfType([PropTypes.object, PropTypes.func]),
  maintenanceTypes: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  errors: PropTypes.object.isRequired,
  resetField: PropTypes.func.isRequired,
  drivers: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  vehicles: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  trailerTails: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func.isRequired,
  setSelectedType: PropTypes.func,
  containers: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  maintenanceTopics: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  images: PropTypes.arrayOf(PropTypes.object),
  setImages: PropTypes.func,
  showDriver: PropTypes.bool,
  showVehiclePart: PropTypes.bool,
  showImageUpload: PropTypes.bool,
  showPrice: PropTypes.bool,
  showModified: PropTypes.bool,
  showCause: PropTypes.bool,
  machinery: PropTypes.object,
  append: PropTypes.func,
  remove: PropTypes.func,
  showGarage: PropTypes.bool,
  maintenanceOrder: PropTypes.object,
  garage: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  handleCreateMaintenanceTopic: PropTypes.func,
  handleCreateGarage: PropTypes.func,
  showStartDateSelector: PropTypes.bool,
  showEndDateSelector: PropTypes.bool,
  showOuterService: PropTypes.bool,
  handleCreateEmployee: PropTypes.func,
};

export default MaintenanceOrderForm;
