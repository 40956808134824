/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { ArrowRight } from 'react-feather';
import _ from 'lodash';

import { dayjs, currencyFormatter } from '../../../utils/functions';
import { ASSIGNMENT_TYPE } from '../../../utils/constants';

const BillDetailCard = ({ bill }) => {
  const renderEachBookingData = (eachBooingData) => (
    <div className="my-2">
      <div className="text-sm">
        เลขที่บุ๊คกิ้ง {eachBooingData?.booking_number} วันที่{' '}
        {dayjs(eachBooingData?.date).format('D MMM BBBB')}
      </div>

      {!eachBooingData?.from_vehicle_schedule && (
        <div className="w-full flex my-2">
          <div className="w-1/3 ">
            <div className="font-semibold text-lg">
              {eachBooingData?.individual_input?.job?.name}
            </div>{' '}
            <div className="font-semibold">
              {currencyFormatter.format(eachBooingData?.price)} บาท
            </div>
          </div>
          <div className="w-2/3 self-center text-sm ">
            <div className="w-full flex gap-2">
              <div className="font-semibold">รูปแบบ </div>
              {
                ASSIGNMENT_TYPE?.[
                  eachBooingData?.individual_input?.job?.assignment_type
                ]?.description
              }{' '}
            </div>
            <div className="w-full flex gap-2">
              <div className="font-semibold">เส้นทาง </div>
              {eachBooingData?.individual_input?.job?.start}{' '}
              <ArrowRight size="16" />{' '}
              {eachBooingData?.individual_input?.job?.destination}
            </div>
            <div className="w-full flex gap-2">
              <div className="font-semibold">ทะเบียนรถ/หาง </div>
              {eachBooingData?.individual_input?.vehicle || ''} /{' '}
              {eachBooingData?.individual_input?.trailer_tail || ''}
            </div>
            <div className="w-full flex gap-2">
              <div className="font-semibold">หมายเลขตู้ </div>
              {eachBooingData?.individual_input?.container || ''}
            </div>
            <div className="w-full flex gap-2">
              <div className="font-semibold">รูปแบบการคิดเงิน </div>
              {eachBooingData?.individual_input?.job?.is_fixed_cost
                ? 'ราคาคงที่'
                : 'แปรผันตามราคาน้ำมัน'}
            </div>
            {!eachBooingData?.individual_input?.job?.is_fixed_cost && (
              <div className="w-full flex gap-2">
                <div className="font-semibold">ราคาน้ำมัน </div>
                {currencyFormatter.format(
                  eachBooingData?.individual_input?.gas_price,
                )}{' '}
                บาท
              </div>
            )}
          </div>
        </div>
      )}
      <hr className="my-2" />
    </div>
  );
  return (
    <div>
      <Card>
        <div className="p-4">
          <div className="flex flex-wrap ">
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              เลขที่บิล
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 text-lg font-semibold">
              {bill?.invoice_number}
            </div>{' '}
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              วันที่ออกบิล
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
              {dayjs(bill?.date).format('D MMMM BBBB')}
            </div>
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              ลูกค้า
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
              {bill?.customer?.name}
            </div>
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              รายการออกบิล
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
              {_.map(bill?.bookings, (eachBooking, index) => (
                <div key={index}>{renderEachBookingData(eachBooking)} </div>
              ))}
            </div>
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              ค่าใช้จ่ายเพิ่มเติม
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
              {_.map(bill?.additional_price, (eachAddPrice, index) => (
                <div key={index}>
                  {' '}
                  - {eachAddPrice?.title} ราคา{' '}
                  {currencyFormatter.format(eachAddPrice?.price)} บาท{' '}
                </div>
              ))}
            </div>{' '}
            <div className="w-full lg:w-1/3 md:w-1/2 py-2 font-semibold">
              ราคารวม
            </div>
            <div className="w-full lg:w-2/3 md:w-1/2 py-2 font-sansserif">
              {currencyFormatter.format(bill?.price)} บาท
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

BillDetailCard.propTypes = { bill: PropTypes.object };

export default BillDetailCard;
