/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-const-assign */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Card,
  TextField,
  Paper,
  Box,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import _ from 'lodash';
import { DatePicker, DateTimePicker } from '../../Datepicker';
import VehicleWheelInfoMaintenance from './VehicleWheelInfoMaintenance';
import TrailertailWheelInfoMaintenanceForm from './TrailertailWheelInfoMaintenanceForm';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import AdapterJalali from '@date-io/date-fns-jalali';

export default function VehcleInfoMaintenanceFrom({
  wheelRegister,
  wheelTrailer,
  garage,
  wheel,
  control,
  Controller,
  fields,
  append,
  remove,
  setValue,
  create,
  wheelInVehicle,
  setVehicleSelect,
  setTailertailSelect,
  tailertailSelect,
  vehicleSelect,
  date,
  setDate,
  arrayWheel,
  setArrayWheel,
  reset,
  watch,
}) {
  const [wheelCheck, setWheelCheck] = useState();
  const [wheelTailCheck, setWheelTailCheck] = useState();
  const [reload, setReload] = useState(false);
  // console.log('wheelTrailer', wheelTrailer);
  useEffect(() => {
    setValue('wheelArray', wheelCheck?.wheels);

    return () => {};
  }, [wheelCheck?.wheel]);

  const deleteArrayWheel = (index) => {
    const NewArrayArrayWheel = [...arrayWheel];
    NewArrayArrayWheel.splice(index, 1);
    setArrayWheel(NewArrayArrayWheel);
  };

  const selectWheel = (dataWheel) => {
    arrayWheel.push(dataWheel);
    setArrayWheel(arrayWheel);
    setReload(!reload);
  };

  useEffect(() => {
    console.log('reload');

    return () => {};
  }, [reload]);

  const renderWheel = () => (
    <div className="w-full pt-10 ">
      {_.map(arrayWheel, (eachWheel, index) => (
        <div className=" pr-4 pb-4 ">
          <Card color="">
            <div className="invisible">
              <Controller
                name={`arrayWheel.${index}.position`}
                control={control}
                defaultValue={eachWheel?.position}
                render={({ field }) => (
                  <TextField {...field} size="small" disabled label="ปัญหา" />
                )}
              />

              <Controller
                name={`arrayWheel.${index}.wheel_Id`}
                control={control}
                defaultValue={eachWheel ? eachWheel?._id : ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    disabled
                    value={eachWheel?.eachWheel?._id}
                    label="ยางปัจจุบัน"
                  />
                )}
              />
            </div>
            <div className="p-2 lg:flex w-full">
              <div className="w-full">
                <p className=" font-bold ">ยางที่ต้องการซ่อม</p>
                <p className="text-l font-bold ">
                  เลขยาง : {eachWheel?.wheelNumber}{' '}
                </p>
                <p className="text-l font-bold ">
                  ตำแหน่งยาง : {eachWheel?.position}{' '}
                </p>
              </div>
              <div className="lg:pl-2 pb-2 w-full">
                <Controller
                  name={`arrayWheel.${index}.cause`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="ปัญหา"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <div className="p-2">
                <Button
                  onClick={() => deleteArrayWheel(index)}
                  variant="contained"
                  color="error"
                  size="medium"
                >
                  ลบ
                </Button>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );

  const renderStartDateSelector = () => (
    <div className="w-full">
      <div className="w-full font-semibold text-lg">วันที่แจ้งซ่อมยาง</div>
      <div className=" pt-4  ">
        <Controller
          name={'start_date'}
          control={control}
          render={({ field }) => (
            <div>
              <DateTimePicker
                label="วันที่แจ้งซ่อม (วัน/เดือน/ปี ชั่วโมง:นาที) "
                value={field.value}
                setValue={(value) => field.onChange(value)}
              />
            </div>
          )}
        />
      </div>
    </div>
  );

  const renderCarPart = () => (
    <div className="">
      <div className="  font-semibold text-lg pt-2"> ส่วนของรถ </div>
      <div className="flex jusify-center flex-wrap ">
        <div className="w-1/3 px-4 py-2">
          <FormControlLabel
            control={
              <Controller
                name={'enable_vehicle'}
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      // resetField('vehicle');
                      // resetField('mileage');
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'หัวรถ'}
          />
        </div>
        <div className="w-1/3 px-4 py-2">
          <FormControlLabel
            control={
              <Controller
                name={'enable_trailer_tail'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      // resetField('trailer_tail');
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'หางรถ'}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="mx-auto ">
      <Paper>
        <div className=" p-4 border-b-4 border-indigo-500">
          {renderStartDateSelector()}
          {renderCarPart()}
          <div className="">
            <div className="">
              {watch('enable_vehicle') && (
                <div className=" flex">
                  <div className="w-full px-1 py-2 ">
                    <Controller
                      name={'vehicle'}
                      control={control}
                      // defaultValue={undefined}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          name={field.name}
                          ref={field.ref}
                          // defaultValue={field.value}
                          options={wheelRegister?.rows}
                          autoHighlight
                          getOptionLabel={(option) =>
                            `ป้ายทะเบียน ${option?.vehicle?.licence_number}`
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                fontSize: 15,
                                '& > span': { mr: '10px', fontSize: 18 },
                              }}
                              {...props}
                            >
                              {`ป้ายทะเบียน ${option?.vehicle?.licence_number}`}
                            </Box>
                          )}
                          onChange={(e, value) => {
                            field.onChange(value.vehicle._id);
                            setWheelCheck(value);
                            setVehicleSelect(value);
                            // setValue('wheelArray', value?.wheel);
                            // console.log('wheel on auto', value?.wheel.length);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              size="small"
                              label="ป้ายทะเบียน"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              // helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                            />
                          )}
                        />
                      )}
                    />
                  </div>

                  <div className="w-full py-2 ">
                    <Controller
                      name={'mileage'}
                      control={control}
                      // defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="เลขไมล์ปัจุบัน"
                          fullWidth
                          // helperText={errors.mileage && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            {watch('enable_trailer_tail') && (
              <div className="flex grid grid-cols-2  px-1 py-2">
                <Controller
                  name={'trailer_tail'}
                  control={control}
                  // defaultValue={undefined}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      name={field.name}
                      ref={field.ref}
                      // defaultValue={field.value}
                      options={wheelTrailer?.rows}
                      autoHighlight
                      getOptionLabel={(option) =>
                        `ป้ายทะเบียน ${option?.trailerTail?.licence_number}`
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: 15,
                            '& > span': { mr: '10px', fontSize: 18 },
                          }}
                          {...props}
                        >
                          {`ป้ายทะเบียน ${option?.trailerTail?.licence_number}`}
                        </Box>
                      )}
                      onChange={(e, value) => {
                        field.onChange(value.trailerTail._id);
                        setWheelTailCheck(value);
                        setTailertailSelect(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          size="small"
                          label="ป้ายทะเบียน"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </div>
            )}
            {/* <div className="w-full px-1 py-2">
              <Controller
                name={'mileage'}
                control={control}
                // defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="เลขไมล์ปัจุบัน"
                    fullWidth
                    // helperText={errors.mileage && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div> */}
          </div>
          {!create ? (
            <>
              <div className="  font-semibold text-lg pt-2">
                {' '}
                ส่วนของการซ่อม{' '}
              </div>
              <div className="flex">
                <div className="w-full px-1 py-2">
                  <Controller
                    name={'type_maintenance'}
                    control={control}
                    // defaultValue={undefined}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        name={field.name}
                        ref={field.ref}
                        defaultValue={field.value}
                        options={['อุบัติเหตุ', 'ตามระยะ']}
                        autoHighlight
                        getOptionLabel={(option) => `${option}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            {` ${option}`}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            size="small"
                            label="ประเภทการซ่อม"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            // helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                          />
                        )}
                      />
                    )}
                  />
                </div>
                <div className="w-full px-1 py-2">
                  <Controller
                    name={'garage'}
                    control={control}
                    // defaultValue={undefined}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        name={field.name}
                        ref={field.ref}
                        // defaultValue={field.value}
                        options={garage?.rows}
                        autoHighlight
                        getOptionLabel={(option) => `${option?.name}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            {` ${option?.name}`}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value._id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // required
                            fullWidth
                            size="small"
                            label="อู่ซ่อม"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            // helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                {/* <LocalizationProvider dateAdapter={AdapterJalali}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div>
          <div>
            <div className=" md:flex grid grid-cols-2">
              <div className="md:w-2/3">
                {watch('enable_vehicle') && (
                  <div>
                    <VehicleWheelInfoMaintenance
                      wheelCheck={wheelCheck}
                      vehicleSelect={vehicleSelect}
                      maintenance={false}
                      control={control}
                      Controller={Controller}
                      fields={fields}
                      append={append}
                      remove={remove}
                      setArrayWheel={setArrayWheel}
                      arrayWheel={arrayWheel}
                      selectWheel={selectWheel}
                    />
                  </div>
                )}
                {watch('enable_trailer_tail') && (
                  <div>
                    <TrailertailWheelInfoMaintenanceForm
                      setArrayWheel={setArrayWheel}
                      tailertailSelect={tailertailSelect}
                      wheelTailCheck={wheelTailCheck}
                      control={control}
                      Controller={Controller}
                      fields={fields}
                      append={append}
                      selectWheel={selectWheel}
                    />
                  </div>
                )}
              </div>
              <div className="md:w-1/2">{renderWheel()}</div>
            </div>

            <div className=" flex flex-row justify-end p-4">
              <Button type="submit" variant="outlined">
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
