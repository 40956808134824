import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function RetainingMaterialAddStockForm({
  selectedMaterial,
  control,
  place,
  customer,
}) {
  return (
    <div className="flex flex-row flex-wrap w-full">
      <div className="w-full text-lg font-semibold  px-1 py-2">
        <div>{selectedMaterial?.type_code || ''}</div>
        <div>{selectedMaterial?.name || ''}</div>
        {/* <Controller
          name={'material'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <input {...field} value={selectedMaterial?._id} />
          )}
        /> */}
      </div>

      {customer && !_.isEmpty(customer) && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'supplier'}
            control={control}
            defaultValue={undefined}
            rules={{ required: false }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                fullWidth
                size="small"
                defaultValue={field.value}
                options={customer}
                autoHighlight
                getOptionLabel={(option) => `${option?.name}`}
                renderOption={(props, option) => (
                  <MenuItem {...props}>{option?.name} </MenuItem>
                )}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="คู่ค้า"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </div>
      )}

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'quantity'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวน"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'price'}
          control={control}
          render={({ field }) => (
            <TextField {...field} label="ราคารวม" fullWidth size={'small'} />
          )}
        />
      </div>

      <div className="w-full">
        <div className="w-full px-1 py-2">
          <Controller
            name={'place'}
            control={control}
            defaultValue={place?.[0]}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  คลังวัสดุ
                </InputLabel>
                <Select {...field} label="คลังวัสดุ" size={'small'} fullWidth>
                  {_.size(place) ? (
                    _.map(place, (row) => (
                      <MenuItem key={row.id} value={row._id}>
                        {row.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
    </div>
  );
}

RetainingMaterialAddStockForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    net: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    minimum_amount: PropTypes.object,
    register_number: PropTypes.object,
  }),
  control: PropTypes.object,
  selectedMaterial: PropTypes.object,
  place: PropTypes.arrayOf(PropTypes.object),
  customer: PropTypes.object,
};

export default RetainingMaterialAddStockForm;
