/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  AlertTitle,
  Chip,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as actions from '../../redux/actions';
import { EmployeeForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CSVUploadModal } from '../../components/Modal';
import { EMPLOYEE_STATUS } from '../../utils/constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  firstname: '',
  lastname: '',
  department: '',
  role: '',
  username: '',
  password: '',
};

function Employees({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const roletype = useSelector((state) => state.roletype);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [addUser, setAddUser] = useState(true);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [image, setImage] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  useEffect(() => {
    dispatch(actions.employeeAll({ name, page, size }));
    dispatch(actions.departmentAll(''));
    dispatch(actions.roletypeAll(''));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(employee?.total);
    return () => {};
  }, [employee]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      // eslint-disable-next-line no-param-reassign
      console.log(data);
      if (!_.isEmpty(image)) {
        data.image = {
          image: image?.[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }
      if (addUser === true) {
        if (data.password === data.confirmPassword) {
          await dispatch(actions.userRegister(data));
          reset(defaultValue);
          alert('สำเร็จ');
          handleClose();
          setAddUser(false);
          await dispatch(actions.employeeAll({ name, page, size }));
        } else {
          alert('กรุณาใส่รหัสผ่านให้ตรงกัน');
        }
      } else {
        await dispatch(actions.employeeCreate(data));
        reset(defaultValue);
        alert('สำเร็จ');
        handleClose();
        setAddUser(false);
        await dispatch(actions.employeeAll({ name, page, size }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.employeeCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.employeeAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการได้');
    }
  };

  const handleDeleteEmployee = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.employeeDelete(id));
        await dispatch(actions.employeeAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditEmployee = (id) => {
    history.push(`employee/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div className="flex gap-1">
        <Button variant="contained" onClick={handleOpen}>
          เพิ่ม
        </Button>
        <Button variant="contained" onClick={handleCSVOpen} color="success">
          อัพโหลด
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2">เพิ่มข้อมูลพนักงาน</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EmployeeForm
                control={control}
                Controller={Controller}
                errors={errors}
                employee={employee}
                department={department}
                roletype={roletype}
                addUser={addUser}
                setAddUser={setAddUser}
                image={image}
                setImage={setImage}
                watch={watch}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <CSVUploadModal
      csvData={csvData}
      setCsvData={setCsvData}
      fileTemplateURL="/filetemplate/Employee.csv"
      handleAddFromFile={handleAddFromFile}
      handleClose={handleCSVClose}
      isOpen={csvUploadOpen}
      titleName="พนักงาน"
      typeRows={department}
      anotherComponent={
        <div>
          {' '}
          <AlertTitle>
            โปรดแทนที่ข้อมูลใน <strong>ตำแหน่ง</strong> ด้วยรหัสดังต่อไปนี้
          </AlertTitle>
          <div className="border rounded-sm h-80 overflow-y-auto">
            <Table
              sx={{
                '&:last-child td, &:last-child th': { border: 1 },
              }}
            >
              <TableHead>
                <TableCell>ตำแหน่ง</TableCell>
                <TableCell>รหัส</TableCell>
              </TableHead>
              <TableBody>
                {_.map(roletype?.rows, (_type, index) => (
                  <TableRow key={index}>
                    <TableCell>{_type?.name} </TableCell>
                    <TableCell>{_type?._id} </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      }
    />
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ-สกุล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> แผนก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> บทบาท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(employee.rows) ? (
                employee.rows.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <div className="mx-2">
                          {row?.image ? (
                            <img
                              src={row?.image?.url}
                              className="w-16 rounded-full border"
                            />
                          ) : (
                            <div className="w-16"></div>
                          )}
                        </div>
                        <div className="self-center font-sans">
                          <div className="flex">
                            <span className="text-lg font-semibold">
                              {' '}
                              {row.firstname} {row.lastname || ''}
                            </span>{' '}
                            {row?.uid && (
                              <Chip
                                className="mx-2"
                                size="small"
                                label={`${
                                  _.size(row?.uid?.username) < 20
                                    ? row?.uid?.username
                                    : 'Line User'
                                }`}
                              />
                            )}
                            {row?.attribute?.status &&
                              row?.attribute?.status !==
                                EMPLOYEE_STATUS.NORMAL.status_code && (
                                <div
                                  className={`bg-${
                                    EMPLOYEE_STATUS?.[row?.attribute?.status]
                                      ?.color
                                  }-500   self-center rounded-md px-2 mx-2 text-white `}
                                >
                                  {
                                    EMPLOYEE_STATUS?.[row?.attribute?.status]
                                      ?.description
                                  }{' '}
                                </div>
                              )}
                          </div>

                          <span>{row?.nickname || ''} </span>
                          <br />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{`${row?.department?.name}`}</TableCell>
                    <TableCell>{`${row?.role?.name}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Link to={`/hrms/employee/detail/${row?._id}`}>
                          <Button
                            variant="contained"
                            color="info"
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditEmployee(row._id)}
                        >
                          แก้ไข
                        </Button>

                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          disabled={row?.uid}
                          onClick={() => {
                            history.push(
                              `/hrms/employee/create-user/${row?._id}`,
                            );
                          }}
                        >
                          สร้างผู้ใช้
                        </Button>

                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteEmployee(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderCSVUploadModal()}
        {renderModal()}
        {renderTitle()}
        {renderAddButtom()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Employees.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Employees.defaultProps = {
  title: '',
  subtitle: '',
};

export default Employees;
