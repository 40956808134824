/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
} from '@mui/material';

import _ from 'lodash';
import img_pickup from '../../../assets/img/pickUp.PNG';
import img_truck from '../../../assets/img/truck_wheel.PNG';

const VehicleWheelInfoMaintenance = ({
  wheelRegister,
  wheelCheck,
  vehicleSelect,
  arrayWheel,
  selectWheel,
}) => {
  const checkButton = (position) => {
    const checked = _.find(arrayWheel, (e) => e?.position === position);
    if (checked) {
      return true;
    }
    return false;
  };

  const renderVehicleFromMaintenance = (wheel, wheelData) => {
    const wheels = vehicleSelect?.wheelVehicle;
    // console.log(wheels);
    if (wheel <= 4) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      console.log('ยาง1', wheel1);
      return (
        <div className=" p-8 drop-shadow-xl  ">
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="w-1/3 pt-6 ">
                <div className="flex justify-end">
                  <Button
                    onClick={() => selectWheel(wheel1)}
                    disabled={!wheel1?.wheelNumber || checkButton(1)}
                  >
                    เลขยาง1 : {wheel1?.wheelNumber}
                  </Button>
                </div>

                <div className="flex justify-end pt-28">
                  <Button
                    onClick={() => selectWheel(wheel3)}
                    disabled={!wheel3?.wheelNumber || checkButton(3)}
                  >
                    เลขยาง3 : {wheel3?.wheelNumber}
                  </Button>
                </div>
              </div>
              <div className="pb-8 pr-16">
                <img src={img_pickup}></img>
              </div>
              <div className="w-1/3 pt-6 ">
                <Button
                  onClick={() => selectWheel(wheel2)}
                  disabled={!wheel2?.wheelNumber || checkButton(2)}
                >
                  เลขยาง2 : {wheel2?.wheelNumber}
                </Button>
                <div className="flex  pt-28">
                  <Button
                    onClick={() => selectWheel(wheel4)}
                    disabled={!wheel4?.wheelNumber || checkButton(4)}
                  >
                    เลขยาง4 : {wheel4?.wheelNumber}
                  </Button>{' '}
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 6) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      const wheel5 = _.find(wheels, { position: 5 });
      const wheel6 = _.find(wheels, { position: 6 });
      return (
        <div className="p-8 drop-shadow-xl">
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end">
                  <Button
                    onClick={() => selectWheel(wheel1)}
                    disabled={!wheel1?.wheelNumber || checkButton(1)}
                  >
                    เลขยาง1 : {wheel1?.wheelNumber}
                  </Button>
                </div>

                <div className="flex justify-end pt-56">
                  <Button
                    onClick={() => selectWheel(wheel3)}
                    disabled={!wheel3?.wheelNumber || checkButton(3)}
                  >
                    {' '}
                    เลขยาง3 : {wheel3?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel4)}
                    disabled={!wheel4?.wheelNumber || checkButton(4)}
                  >
                    {' '}
                    เลขยาง4 : {wheel4?.wheelNumber}
                  </Button>
                </div>
              </div>
              <div>
                <img src={img_truck}></img>
              </div>
              <div className=" pt-12 ">
                <div className="flex">
                  <Button
                    onClick={() => selectWheel(wheel2)}
                    disabled={!wheel2?.wheelNumber || checkButton(2)}
                  >
                    เลขยาง2 : {wheel2?.wheelNumber}
                  </Button>
                </div>
                <div className="flex  pt-56">
                  <Button
                    onClick={() => selectWheel(wheel5)}
                    disabled={!wheel5?.wheelNumber || checkButton(5)}
                  >
                    เลขยาง5 : {wheel5?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel6)}
                    disabled={!wheel6?.wheelNumber || checkButton(6)}
                  >
                    เลขยาง6 : {wheel6?.wheelNumber}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 10) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      const wheel5 = _.find(wheels, { position: 5 });
      const wheel6 = _.find(wheels, { position: 6 });
      const wheel7 = _.find(wheels, { position: 7 });
      const wheel8 = _.find(wheels, { position: 8 });
      const wheel9 = _.find(wheels, { position: 9 });
      const wheel10 = _.find(wheels, { position: 10 });
      return (
        <div className="p-8 drop-shadow-xl">
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end ">
                  <Button
                    onClick={() => selectWheel(wheel1)}
                    disabled={!wheel1?.wheelNumber || checkButton(1)}
                  >
                    เลขยาง1 : {wheel1?.wheelNumber}
                  </Button>
                </div>

                <div className=" flex justify-end pt-40">
                  <Button
                    onClick={() => selectWheel(wheel3)}
                    disabled={!wheel3?.wheelNumber || checkButton(3)}
                  >
                    เลขยาง3 : {wheel3?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel4)}
                    disabled={!wheel4?.wheelNumber || checkButton(4)}
                  >
                    เลขยาง4 : {wheel4?.wheelNumber}
                  </Button>
                </div>

                <div className="flex justify-end pt-6">
                  <Button
                    onClick={() => selectWheel(wheel7)}
                    disabled={!wheel7?.wheelNumber || checkButton(7)}
                  >
                    เลขยาง7 : {wheel7?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel8)}
                    disabled={!wheel8?.wheelNumber || checkButton(8)}
                  >
                    เลขยาง8 : {wheel8?.wheelNumber}
                  </Button>
                </div>
              </div>
              <div>
                <img src={img_truck}></img>
              </div>
              <div className=" pt-12 ">
                <div className="flex">
                  <Button
                    onClick={() => selectWheel(wheel2)}
                    disabled={!wheel2?.wheelNumber || checkButton(2)}
                  >
                    เลขยาง2 :{wheel2?.wheelNumber}
                  </Button>
                </div>
                <div className="flex  pt-40">
                  <Button
                    onClick={() => selectWheel(wheel5)}
                    disabled={!wheel5?.wheelNumber || checkButton(5)}
                  >
                    เลขยาง5 : {wheel5?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel6)}
                    disabled={!wheel6?.wheelNumber || checkButton(6)}
                  >
                    เลขยาง6 : {wheel6?.wheelNumber}
                  </Button>
                </div>

                <div className="flex pt-6">
                  <Button
                    onClick={() => selectWheel(wheel9)}
                    disabled={!wheel9?.wheelNumber || checkButton(9)}
                  >
                    เลขยาง9: {wheel9?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel10)}
                    disabled={!wheel10?.wheelNumber || checkButton(10)}
                  >
                    เลขยาง10: {wheel10?.wheelNumber}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 12) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      const wheel5 = _.find(wheels, { position: 5 });
      const wheel6 = _.find(wheels, { position: 6 });
      const wheel7 = _.find(wheels, { position: 7 });
      const wheel8 = _.find(wheels, { position: 8 });
      const wheel9 = _.find(wheels, { position: 9 });
      const wheel10 = _.find(wheels, { position: 10 });
      const wheel11 = _.find(wheels, { position: 11 });
      const wheel12 = _.find(wheels, { position: 12 });
      return (
        <div className="p-8 drop-shadow-xl">
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end">
                  <Button
                    onClick={() => selectWheel(wheel1)}
                    disabled={!wheel1?.wheelNumber || checkButton(1)}
                  >
                    เลขยาง1 : {wheel1?.wheelNumber}
                  </Button>
                </div>

                <div className="flex justify-end pt-4">
                  <Button
                    onClick={() => selectWheel(wheel3)}
                    disabled={!wheel3?.wheelNumber || checkButton(3)}
                  >
                    เลขยาง3 : {wheel3?.wheelNumber}
                  </Button>
                </div>
                <div className="flex justify-end pt-40">
                  <Button
                    onClick={() => selectWheel(wheel5)}
                    disabled={!wheel5?.wheelNumber || checkButton(5)}
                  >
                    เลขยาง5 : {wheel5?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel6)}
                    disabled={!wheel6?.wheelNumber || checkButton(6)}
                  >
                    เลขยาง6 : {wheel6?.wheelNumber}
                  </Button>
                </div>
                <div className="flex justify-end pt-6">
                  <Button
                    onClick={() => selectWheel(wheel9)}
                    disabled={!wheel9?.wheelNumber || checkButton(9)}
                  >
                    {' '}
                    เลขยาง9 : {wheel9?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel10)}
                    disabled={!wheel10?.wheelNumber || checkButton(10)}
                  >
                    {' '}
                    เลขยาง10 : {wheel10?.wheelNumber}
                  </Button>
                </div>
              </div>
              <div className="pb-6">
                <img src={img_truck}></img>
              </div>
              <div className=" pt-12 ">
                <div className="font-bold ">
                  <Button
                    onClick={() => selectWheel(wheel2)}
                    disabled={!wheel2?.wheelNumber}
                  >
                    เลขยาง2 :{wheel2?.wheelNumber}{' '}
                  </Button>
                </div>
                <div className="font-bold pt-4 ">
                  <Button
                    onClick={() => selectWheel(wheel4)}
                    disabled={!wheel4?.wheelNumber}
                  >
                    เลขยาง4 :{wheel4?.wheelNumber}
                  </Button>
                </div>

                <div className="flex  pt-40">
                  <Button
                    onClick={() => selectWheel(wheel7)}
                    disabled={!wheel7?.wheelNumber}
                  >
                    เลขยาง7 : {wheel7?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel8)}
                    disabled={!wheel8?.wheelNumber}
                  >
                    เลขยาง8 : {wheel8?.wheelNumber}
                  </Button>
                </div>
                <div className="flex pt-6">
                  <Button
                    onClick={() => selectWheel(wheel11)}
                    disabled={!wheel11?.wheelNumber}
                  >
                    {' '}
                    เลขยาง11: {wheel11?.wheelNumber}
                  </Button>
                  <Button
                    onClick={() => selectWheel(wheel12)}
                    disabled={!wheel12?.wheelNumber}
                  >
                    เลขยาง12: {wheel12?.wheelNumber}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <div className="pt-2">
          {renderVehicleFromMaintenance(
            wheelCheck?.vehicle?.type?.wheel,
            wheelCheck?.wheels,
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleWheelInfoMaintenance;
