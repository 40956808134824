import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { MaintenanceOrderForm } from '../../components/Forms';
import { CONFIG, MAINTENANCE_ORDER_STATUS } from '../../utils/constants';

export default function CreateMaintenanceOrders({ title, subtitle }) {
  const dispatch = useDispatch();
  const maintenanceTopics = useSelector((state) => state.maintenanceTopics);
  const maintenanceTypes = useSelector((state) => state.maintenanceTypes);
  const drivers = useSelector((state) => state.driver);
  const vehicles = useSelector((state) => state.vehicle);
  const containers = useSelector((state) => state.container);
  const trailerTails = useSelector((state) => state.trailerTail);
  const machinery = useSelector((state) => state.mechinery);
  const me = useSelector((state) => state.me);
  const garage = useSelector((state) => state.garage);
  const information = useSelector((state) => state.information);
  const employee = useSelector((state) => state.employee);
  const [selectedType, setSelectedType] = useState(null);
  const [images, setImages] = useState([]);
  const [FileOpen, setFileOpen] = useState(false);
  const [file, setFile] = useState(false);
  const history = useHistory();
  const params = useParams();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
    resetField,
    watch,
  } = useForm({
    defaultValues: {
      type: maintenanceTopics.type,
      is_self_maintenance: true,
    },
  });

  const isInputFromNotedMaintenance = params.vehicleId;

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        actions.maintenanceTypeAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
      await dispatch(
        actions.driverAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
      await dispatch(
        actions.vehicleAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
      await dispatch(
        actions.trailerTailAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
      await dispatch(
        actions.containerAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
      await dispatch(
        actions.garageAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
      await dispatch(
        actions.employeeAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
      await dispatch(
        actions.mechineryAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
    };
    fetchData();
    return () => {};
  }, [selectedType]);

  useEffect(() => {
    if (information?.setting?.cmms?.default_show_topic_in_self_maintenance) {
      setValue('specify_topics', true);
    }
    return () => {};
  }, [information]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(actions.maintenanceTopicAll({}));
    };

    if (selectedType) {
      fetchData();
    }
    if (!selectedType && maintenanceTypes?.rows) {
      setSelectedType(maintenanceTypes?.rows?.[0]._id);
    }

    return () => {};
  }, [selectedType, maintenanceTypes]);

  useEffect(() => {
    if (vehicles?.rows && drivers?.rows) {
      if (params.vehicleId) {
        const preloadedVehilce = _.find(
          vehicles?.rows,
          (eachVehicle) => eachVehicle?._id === params.vehicleId,
        );

        setValue('vehicle', preloadedVehilce);
        setValue('enable_vehicle', true);

        if (preloadedVehilce?.mileage) {
          setValue('mileage', preloadedVehilce?.mileage);
        }

        const selectedDriver = _.find(
          drivers?.rows,
          (eachDriver) => eachDriver?.vehicle?._id === params.vehicleId,
        );
        setValue('driver', selectedDriver?.employee);
      }
    }
    return () => {};
  }, [vehicles, params, drivers]);
  console.log('INFO', information);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const calculateOrderStatus = (selectedTopics) => {
    if (information?.setting?.cmms?.automatic_work_accept) {
      return MAINTENANCE_ORDER_STATUS.APPROVE.status_code;
    }

    const isIncludePermissionTopic = _.find(selectedTopics, {
      require_confirmation: true,
    });

    if (isIncludePermissionTopic) {
      return MAINTENANCE_ORDER_STATUS.NEW.status_code;
    }
    return MAINTENANCE_ORDER_STATUS.APPROVE.status_code;
  };

  console.log('Form Error', errors);
  console.log('container', watch('container'));

  const onSubmit = async (data) => {
    console.log(data);
    try {
      data.maintenance_topic = _.map(data.maintenance_topic, (each) => ({
        ...each,
        topic_id: each.topic,
      }));
      const maintenanceImage = _.map(images, (image) => ({
        type: 'รูปภาพก่อนแจ้งซ่อม',
        image: image.data_url,
      }));
      const dataSubmit = {
        ...data,
        file: _.isEmpty(file) ? undefined : file[0],
      };

      await dispatch(
        actions.maintenanceOrderCreate({
          ...dataSubmit,
          informer: me?.userData?._id,
          maintenance_image: maintenanceImage,
          status: calculateOrderStatus(
            _.map(data.maintenance_topic, (eachTopic) => eachTopic?.topic),
          ),
        }),
      );
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`การสร้างการแจ้งซ่อมไม่สำเร็จ ${error.message}`);
      console.log(error);
    }
  };

  const handleCreateMaintenanceTopic = async (maintenanceTopic) => {
    try {
      await dispatch(actions.maintenanceTopicCreate(maintenanceTopic));
      await dispatch(actions.maintenanceTopicAll({}));
    } catch (err) {
      alert(`สร้างหัวข้อการซ่อมบำรุงใหม่ไม่สำเร็จ${err?.message}`);
    }
  };

  const handleCreateGarage = async (data) => {
    try {
      await dispatch(actions.garageCreate(data));
      await dispatch(
        actions.garageAll({ page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      alert(`สร้างอู่ซ่อมบำรุงไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleCreateContainer = async (data) => {
    try {
      await dispatch(actions.containerCreate(data));
      await dispatch(
        actions.containerAll({ page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      alert(`สร้างตู้คอนเทนเนอร์ไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleCreateEmployee = async (data) => {
    try {
      await dispatch(actions.employeeCreate(data));
      await dispatch(
        actions.employeeAll({ page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      alert(`สร้างพนักงานไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderForm = () => (
    <MaintenanceOrderForm
      containers={containers}
      FileOpen={FileOpen}
      setFileOpen={setFileOpen}
      file={file}
      setFiles={setFile}
      control={control}
      drivers={drivers}
      errors={errors}
      getValues={getValues}
      images={images}
      setImages={setImages}
      maintenanceTypes={maintenanceTypes}
      resetField={resetField}
      setSelectedType={setSelectedType}
      maintenanceTopics={maintenanceTopics}
      setValue={setValue}
      trailerTails={trailerTails}
      vehicles={vehicles}
      garage={garage}
      showGarage={true}
      watch={watch}
      attachMaterials={isInputFromNotedMaintenance}
      handleCreateMaintenanceTopic={handleCreateMaintenanceTopic}
      handleCreateGarage={handleCreateGarage}
      handleCreateEmployee={handleCreateEmployee}
      showStartDateSelector
      employee={employee}
      handleCreateContainer={handleCreateContainer}
      machinery={machinery}
    />
  );

  if (!vehicles?.rows || !drivers?.rows) {
    return <Loading />;
  }
  if (
    !maintenanceTypes.isLoading &&
    maintenanceTypes.isCompleted &&
    !vehicles.isLoading &&
    vehicles.isCompleted &&
    !trailerTails.isLoading &&
    trailerTails.isCompleted &&
    vehicles?.rows &&
    !drivers.isLoading &&
    drivers.isCompleted &&
    drivers?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderForm()}
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}
CreateMaintenanceOrders.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMaintenanceOrders.defaultProps = {
  title: '',
  subtitle: '',
};
