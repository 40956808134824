/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Card, Modal } from '@mui/material';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import RepairForm from '../../components/Forms/WMS/RepairForm';
import Loading from '../../components/Loading';
import * as actions from '../../redux/actions';
import CardDetailWheelMaintenance from '../../components/Card/WMS/DetailWheelMaintenance';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { WheelMaintenanceReport } from '../../components/Report';
import { WheelMaintenanceReportOther } from '../../components/Report';
import Retread from 'components/Forms/WMS/Retread';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const defaultValue = {
  repairTopic: '',
  price: '',
  note: '',
};

export default function DetailWheelMaintenance({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const wheelMaintenance = useSelector((state) => state.WheelMaintenance);
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const wheelTransection = useSelector((state) => state.wheelTransection);
  const processmaterials = useSelector((state) => state.processMaterials);

  const info = useSelector((state) => state.information);
  const [open, setOpen] = React.useState(false);
  const [openRetread, setOpenRetread] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const { handleSubmit, control, reset } = useForm();
  const [wheelSelect, setWheelSelect] = useState();
  const [checked, setChecked] = useState();
  const [retreadSelect, setRetreadSelect] = useState();
  const [reTreadchecked, setReTreadchecked] = useState();
  const [transectionCheck, setTransectionCheck] = useState(false);
  const checkTrandec = () => {
    setTransectionCheck(!transectionCheck);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenRetread = () => {
    setOpenRetread(true);
  };

  const handleCloseRetread = () => {
    setOpenRetread(false);
  };

  useEffect(() => {
    dispatch(actions.wheelMaintenanceGet(id));
    dispatch(actions.wheelTransectionAll({ wms: id }));
    dispatch(actions.processMaterialAll({ maintenanceOrder: id }));

    return () => {};
  }, []);
  const fetchRequestMaterialWithdraw = async () => {
    try {
      dispatch(
        actions.materialRequestAll({
          wheelMaintenance: id,
        }),
      );
    } catch (error) {
      console.error('Cannot Fetch Request of Material Withdraw', error);
    }
  };

  useEffect(() => {
    dispatch(actions.wheelTransectionAll({ wms: id }));

    return () => {};
  }, [transectionCheck]);

  const props = {
    handSuccessJob: async (id, data) => {
      const confirm = window.confirm('จบงาน');
      if (confirm) {
        try {
          // console.log(id);
          const dataSubmit = await {
            ...data,
            note: '-',
            statusSuccess: true,
            trailer_tail: data?.trailer_tail?._id,
          };
          console.log('data', dataSubmit);
          await dispatch(actions.wheelMaintenanceDelete(id));
          await dispatch(actions.wheelMaintenanceHistoryCreate(dataSubmit));
          history.goBack();
        } catch (error) {
          console.log(error);
        }
      }
    },
    handleAcessJob: async (id) => {
      try {
        const confirm = window.confirm('เริ่มงาน');
        if (confirm) {
          await dispatch(actions.wheelMaintenancePut(id, { statusJob: true }));
          await dispatch(actions.wheelMaintenanceHistoryCreate());
          await dispatch(actions.wheelMaintenanceGet(id));
        }
      } catch (error) {
        console.log('error');
      }
    },
    handleCancleJob: async (id, data) => {
      const confirm = window.confirm('ยืนยันการยกเลิก');
      if (confirm) {
        try {
          // console.log(id);
          const dataSubmit = await {
            ...data,
            note: '-',
            statusCancle: true,
          };
          await dispatch(actions.wheelMaintenanceDelete(id));
          await dispatch(actions.wheelMaintenanceHistoryCreate(dataSubmit));
          history.goBack();
        } catch (error) {
          console.log('error');
        }
      }
    },
    handleWheelMaintenance: (dataId) =>
      history.push(`/wms/wheelMaintenance/${id}`),
    handleWheelRepair: (dataId) => handleOpen(),

    handleWheelRetread: (dataId) => handleOpenRetread(),

    handleToMaterialRemove: (orderId) =>
      history.push(`/wms/material-return/${orderId}`),

    handleWheelMaterial: (orderId) =>
      history.push(`/wms/material-withdraw/${orderId}`),
    handleDelete: async (id) => {
      const confirm = window.confirm('ลบข้อมูล');
      if (confirm) {
        await dispatch(actions.wheelTransectionDelete(id));
        await dispatch(actions.wheelTransectionAll({ wms: id }));
        checkTrandec();
      }
    },
  };

  const handlePrint = () => {
    WheelMaintenanceReport(info, wheelMaintenance, wheelTransection);
  };
  const handlePrint1 = () => {
    WheelMaintenanceReportOther(info, wheelMaintenance, wheelTransection);
  };
  // const renderModel = () => {
  //   <Modal
  //     open={open}
  //     onClose={handleClose}
  //     aria-labelledby="modal-modal-title"
  //     aria-describedby="modal-modal-description"
  //   >
  //     <Box sx={style}>
  //       <Typography id="modal-modal-title" variant="h6" component="h2">
  //         Text in a modal
  //       </Typography>
  //       <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //         Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
  //       </Typography>
  //     </Box>
  //   </Modal>;
  // };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => <BackButton />;

  const renderDetail = () => (
    <CardDetailWheelMaintenance
      maintenanceOrder={maintenanceOrder}
      processmaterials={processmaterials}
      wheelMaintenance={wheelMaintenance}
      wheelTransection={wheelTransection}
      {...props}
    />
  );

  const rederWheel = () => (
    <div>
      <div className="p-2 font-bold ">ยางที่แจ้งซ่อม</div>
      <div className="">
        {_.map(wheelMaintenance?.wheelOrder, (each) => (
          <div className="p-2">
            <Card
              sx={{
                bgcolor:
                  wheelSelect?._id === each?.wheel_Id?._id
                    ? '#e5e5e5'
                    : 'white',
              }}
            >
              <div className="p-4 flex justify-between">
                <div>
                  <div className="text-xl font-bold ">
                    เลขยาง: {each?.wheel_Id?.wheelNumber}
                  </div>
                  <div>ตำแหน่งยาง: {each?.position}</div>
                  <div>สาเหตุ: {each?.cause}</div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    // disabled={each?.status_maintenance}
                    onClick={() => setWheelSelect(each?.wheel_Id)}
                  >
                    เลือกยาง
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );

  const onSubmit = async (data) => {
    if (checked) {
      if (_.isEmpty(wheelSelect)) {
        return alert('กรุณาเลือกยาง');
      }
    }
    const confirm = window.confirm('บันทึกรายการ');
    if (confirm) {
      const dataSubmit = {
        ...data,
        maintenanceOrder: id,
        wheel: wheelSelect?._id,
        vehicle: wheelMaintenance?.vehicle?._id,
        trailertail: wheelMaintenance?.trailer_tail?._id,
        repairStatus: true,
      };
      await dispatch(actions.wheelTransectionCreate(dataSubmit));
      reset(defaultValue);
      handleClose();
      setChecked(false);
      setWheelSelect();
      fetchRequestMaterialWithdraw();
      await dispatch(actions.wheelTransectionAll({ vehicle: id }));
      checkTrandec();
    }
  };

  const renderRepairDetail = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-4">
          <RepairForm
            setChecked={setChecked}
            checked={checked}
            wheelSelect={wheelSelect}
            Controller={Controller}
            control={control}
            setWheelSelect={setWheelSelect}
          />
          {checked && <div>{rederWheel()}</div>}
          <div className="flex justify-center py-2 ">
            <Button variant="contained" type="submit" size="medium">
              บันทึก
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
  const renderRetreadDetail = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-4">
          <Retread
            setReTreadchecked={setReTreadchecked}
            reTreadchecked={reTreadchecked}
            wheelSelect={wheelSelect}
            Controller={Controller}
            setWheelSelect={setWheelSelect}
            control={control}
          />
          {reTreadchecked && <div>{rederWheel()}</div>}
          <div className="flex justify-center py-2 ">
            <Button variant="contained" type="submit" size="medium">
              บันทึก
            </Button>
          </div>
        </div>
      </form>
    </div>
  );

  const renderModalMaintenance = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="w-full">{renderRepairDetail()}</div>
        </Box>
      </Modal>
    </div>
  );
  const renderWheelRetread = () => (
    <div>
      <Modal
        open={openRetread}
        onClose={handleCloseRetread}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="w-full">{renderRetreadDetail()}</div>
        </Box>
      </Modal>
    </div>
  );

  if (wheelMaintenance.isLoading && !wheelMaintenance.isCompleted) {
    return <Loading />;
  }
  if (!wheelMaintenance.isLoading && wheelMaintenance.isCompleted) {
    return (
      <div>
        {renderModalMaintenance()}
        {renderWheelRetread()}
        {renderTitle()}
        <div className="flex justify-between">
          {renderBackButton()}
          {info?.setting?.wms?.wheelmaintenanceReport1 && (
            <div>
              <Button onClick={handlePrint} variant="contained">
                พิมพ์ใบแจ้งซ่อม
              </Button>
            </div>
          )}
          {info?.setting?.wms?.wheelmaintenanceReport2 && (
            <div>
              <Button onClick={handlePrint1} variant="contained">
                พิมพ์ใบแจ้งซ่อม
              </Button>
            </div>
          )}
        </div>
        {renderDetail()}
      </div>
    );
  }
}
DetailWheelMaintenance.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
