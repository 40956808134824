import React from 'react';
import { Card, CardContent, Avatar, Button } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { processButtonAllowance } from '../../../utils/functions';
import { MAINTENANCE_ORDER_STATUS } from '../../../utils/constants';

const ProcessControlCard = ({
  maintenanceOrder,
  me,
  handleApprove,
  handleSendWork,
  handleToWithdrawMaterial,
  handleToReturnMaterial,
  handleEdit,
  information,
  handleToRequestWithdrawMaterial,
}) => {
  const buttonAllow = processButtonAllowance({
    currentProcess: maintenanceOrder,
    me,
    information,
  });

  const { setting: { cmms: cmmsSetting } = {} } = information || {};

  const canHandleButton = () => {
    // SUPERUSER
    if (me?.userData?.role?.level >= 2) {
      return true;
    }

    // ADMIN
    if (me?.userData?.role?.level === 1) {
      if (
        maintenanceOrder?.status ===
          MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        maintenanceOrder?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        maintenanceOrder?.status ===
          MAINTENANCE_ORDER_STATUS.PENDING.status_code
      ) {
        return true;
      }
    }

    if (
      maintenanceOrder?.informer?._id === me?.userData?._id &&
      (maintenanceOrder?.status ===
        MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        maintenanceOrder?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        maintenanceOrder?.status ===
          MAINTENANCE_ORDER_STATUS.PENDING.status_code)
    ) {
      return true;
    }

    return false;
  };

  const canMajorHandleButton = () => {
    // ADMIN
    if (me?.userData?.role?.level >= 1) {
      if (
        maintenanceOrder?.status ===
          MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        maintenanceOrder?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        maintenanceOrder?.status ===
          MAINTENANCE_ORDER_STATUS.PENDING.status_code
      ) {
        return true;
      }
    }

    if (
      maintenanceOrder?.informer?._id === me?.userData?._id &&
      (maintenanceOrder?.status ===
        MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        maintenanceOrder?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        maintenanceOrder?.status ===
          MAINTENANCE_ORDER_STATUS.PENDING.status_code)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="flex flex-wrap justify-end">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 p-2 gap-2 ">
        {!information?.setting?.cmms?.automatic_work_accept && (
          <Button
            variant="contained"
            color="primary"
            disabled={!buttonAllow.acceptButton}
            onClick={handleApprove}
          >
            <div className="flex gap-2">
              <i className="fas fa-circle-plus self-center" />
              <div className="font-sans">อนุมัติ</div>
            </div>
          </Button>
        )}
        {cmmsSetting?.must_approve_material_request ? (
          <Button
            variant="contained"
            color="secondary"
            disabled={!canMajorHandleButton()}
            onClick={() =>
              handleToRequestWithdrawMaterial(maintenanceOrder?._id)
            }
          >
            <div className="flex gap-2">
              <i className="fas fa-cart-flatbed  self-center" />
              <div className="font-sans">ขอเบิกวัสดุ</div>
            </div>
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            disabled={!canMajorHandleButton()}
            onClick={() => handleToWithdrawMaterial(maintenanceOrder?._id)}
          >
            <div className="flex gap-2">
              <i className="fas fa-cart-flatbed  self-center" />
              <div className="font-sans">เบิกวัสดุ</div>
            </div>
          </Button>
        )}
        <Button
          variant="contained"
          color="info"
          disabled={!canMajorHandleButton()}
          onClick={() => handleToReturnMaterial(maintenanceOrder?._id)}
        >
          <div className="flex gap-2">
            <i className="fas fa-dolly  self-center" />
            <div className="font-sans">คืนวัสดุ</div>
          </div>
        </Button>
        <Button
          variant="contained"
          color="warning"
          disabled={!canHandleButton()}
          onClick={handleEdit}
        >
          <div className="flex gap-2">
            <i className="fas fa-pencil-alt  self-center" />
            <div className="font-sans">แก้ไข</div>
          </div>
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={!canMajorHandleButton()}
          onClick={handleSendWork}
        >
          <div className="flex gap-2">
            <i className="fas fa-clipboard-check  self-center" />
            <div className="font-sans"> ส่งงาน</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

ProcessControlCard.propTypes = {
  maintenanceOrder: PropTypes.object,
  me: PropTypes.object,
  handleAccept: PropTypes.func,
  handleRejectWork: PropTypes.func,
  handleSendWork: PropTypes.func,
  handleStartWork: PropTypes.func,
  handleToWithdrawMaterial: PropTypes.func,
  handleToReturnMaterial: PropTypes.func,
  handleEdit: PropTypes.func,
  information: PropTypes.object,
  handleToRequestWithdrawMaterial: PropTypes.func,
};

export default ProcessControlCard;
