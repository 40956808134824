import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  Box,
  TextField,
  Checkbox,
  createFilterOptions,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import GarageForm from './GarageForm';

const PlaceForMaintenanceForm = ({
  handleCreateGarage,
  control,
  maintenanceOrder,
  watch,
  garage,
  resetField,
}) => {
  const filter = createFilterOptions();
  const [dialogTopic, setDialogTopic] = useState('');
  const [openAddGarage, setOpenAddGarage] = useState(false);
  // Use Form For Garage
  const {
    control: garageControl,
    handleSubmit: handleGarageSubmit,
    formState: { errors: garageError },
  } = useForm();
  const renderAddGarageDialog = () => (
    <Dialog onClose={() => setOpenAddGarage(false)} open={openAddGarage}>
      <DialogTitle>สร้างอู่ซ่อมบำรุงใหม่</DialogTitle>
      <form>
        <DialogContent>
          <GarageForm
            control={garageControl}
            errors={garageError}
            garage={{ name: dialogTopic }}
          />
          <DialogActions>
            <Button
              onClick={(event) => {
                handleGarageSubmit(handleCreateGarage)(event);
                setOpenAddGarage(false);
              }}
              variant="contained"
            >
              เพิ่ม
            </Button>
          </DialogActions>
        </DialogContent>{' '}
      </form>
    </Dialog>
  );
  return (
    <div className="w-full  py-2 flex flex-wrap">
      {' '}
      {renderAddGarageDialog()}
      <div className="w-full  py-2  font-semibold text-lg">
        วิธีการซ่อมบำรุง
      </div>
      <div className="w-full   py-2">
        <div className="flex gap-1 justify-center rounded-md  border-gray-300 border py-2">
          {/* <FormControlLabel
            control={
              <Controller
                name={'no_specify_garage'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      resetField('container');
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'ไม่ระบุอู่/ศูนย์บริการ'}
          /> */}

          <Typography className="self-center">
            ซ่อมที่อู่ / ศูนย์บริการ
          </Typography>
          <FormControlLabel
            control={
              <Controller
                name={'is_self_maintenance'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'ซ่อมบำรุงเอง'}
          />
        </div>
      </div>{' '}
      {!_.isEmpty(garage.rows) &&
        !watch('is_self_maintenance') &&
        !watch('no_specify_garage') && (
          <div className="w-full px-1 py-2">
            <Controller
              name={'garage'}
              control={control}
              defaultValue={
                maintenanceOrder?.garage ? maintenanceOrder?.garage : undefined
              }
              render={({ field }) => (
                <Autocomplete
                  disableClearable
                  name={field.name}
                  ref={field.ref}
                  defaultValue={field.value}
                  options={garage?.rows}
                  autoHighlight
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option?.inputValue) {
                      return option?.inputValue;
                    }
                    return option?.name;
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        fontSize: 15,
                        '& > span': { mr: '10px', fontSize: 18 },
                      }}
                      {...props}
                    >
                      {option?.name}
                    </Box>
                  )}
                  onChange={(e, value) => {
                    if (typeof value === 'string') {
                      setTimeout(() => {
                        setOpenAddGarage(true);
                        setDialogTopic(value);
                      });
                    } else if (value && value?.inputValue) {
                      setOpenAddGarage(true);
                      setDialogTopic(value?.inputValue);
                    } else {
                      field.onChange(value);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params?.inputValue !== '') {
                      filtered.push({
                        inputValue: params?.inputValue,
                        name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                      });
                    }

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="อู๋ซ่อมบำรุง"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )}
            />
          </div>
        )}
      {!watch('is_self_maintenance') && (
        <div className="w-2/3 px-4 py-2">
          <FormControlLabel
            control={
              <Controller
                name={'no_specify_garage'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      resetField('container');
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'ไม่ระบุอู่/ศูนย์บริการ'}
          />
        </div>
      )}{' '}
    </div>
  );
};

PlaceForMaintenanceForm.propTypes = {
  handleCreateGarage: PropTypes.func,
  control: PropTypes.object,
  maintenanceOrder: PropTypes.object,
  watch: PropTypes.func,
  garage: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  resetField: PropTypes.func,
};

export default PlaceForMaintenanceForm;
