/* eslint-disable import/no-named-as-default */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  TextField,
  InputAdornment,
  Button,
  ButtonGroup,
} from '@mui/material';

import PropTypes from 'prop-types';
import {
  DetailDeliveryOrderHistory,
  // eslint-disable-next-line import/no-unresolved
} from 'components/Report/index';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { DeliveryOrderHistoryTable } from '../../components/Table';

const DeliveryOrderHistory = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const deliveryOrders = useSelector((state) => state.deliveryOrders);
  const information = useSelector((state) => state.information);

  const [assignmentType, setAssignmentType] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  const getDeliveryOrders = () =>
    dispatch(
      actions.deliveryOrderHistory({ name, page, size, assignmentType }),
    );

  useEffect(() => {
    getDeliveryOrders();
    return () => {};
  }, [name, page, size, assignmentType]);

  useEffect(() => {
    setTotal(deliveryOrders?.total);
    return () => {};
  }, [deliveryOrders]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const tableProps = {
    page,
    size,
    total,
    handleChangePage: (evnet, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handlePrintPdfDeliveryOrder: async (row) => {
      await DetailDeliveryOrderHistory(row, information);
    },
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearchType = () => (
    <div className="flex flex-wrap mb-4">
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        color="gray"
      >
        <Button onClick={() => setAssignmentType('IMPORT')}>
          <div className="text-black">IMPORT</div>
        </Button>
        <Button onClick={() => setAssignmentType('EXPORT')}>
          <div className="text-black">EXPORT</div>
        </Button>
        <Button onClick={() => setAssignmentType('')}>
          <div className="text-black">ALL</div>
        </Button>
      </ButtonGroup>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row justify-between">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size="small"
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="self-center">{renderSearchType()}</div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <DeliveryOrderHistoryTable
        deliveryOrders={deliveryOrders}
        {...tableProps}
      />
    </div>
  );

  if (deliveryOrders.isLoading) {
    return <Loading />;
  }

  if (!deliveryOrders.isLoading && deliveryOrders.isCompleted) {
    return (
      <div>
        {renderTitle()}

        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
};

DeliveryOrderHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DeliveryOrderHistory.defaultProps = {
  title: '',
  subtitle: '',
};

export default DeliveryOrderHistory;
