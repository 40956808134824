import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { EditMaterialForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const customerType = useSelector((state) => state.customerType);
  const customer = useSelector((state) => state.customer);
  const place = useSelector((state) => state.place);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: material.name,
      unit: material.unit,
      net: material.net,
      unit_net: material.unit_net,
      detail: material.detail,
      type_code: material.type_code,
      minimum_amount: material.minimum_amount,
      register_number: material.register_number,
    },
  });
  const history = useHistory();
  const [selectCustomerType, setSelectCustomerType] = useState();
  const [selectCustomer, setSelectCustomer] = useState([]);

  useEffect(() => {
    dispatch(actions.materialGet(id, {}));
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.customerTypeAll({}));
    dispatch(actions.placeAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    if (selectCustomerType) {
      dispatch(actions.customerAll({ customerType: selectCustomerType }));
    }
    return () => {};
  }, [selectCustomerType]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (_.isEmpty(selectCustomer)) {
        await dispatch(actions.materialPut(id, data));
      } else {
        const supplier = _.map(selectCustomer, (select) => select?._id);
        await dispatch(actions.materialPut(id, { ...data, supplier }));
      }
      alert('สำเร็จ');
      await dispatch(actions.materialGet(id, {}));
      setSelectCustomer([]);
      setSelectCustomerType('');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (material.isLoading || material.rows) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditMaterialForm
                errors={errors}
                material={material}
                control={control}
                Controller={Controller}
                materialType={materialType}
                customerType={customerType}
                selectCustomerType={selectCustomerType}
                setSelectCustomerType={setSelectCustomerType}
                customer={customer}
                setSelectCustomer={setSelectCustomer}
                selectCustomer={selectCustomer}
                place={place}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
