import React from 'react';
/* eslint-disable no-underscore-dangle */

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import thLocale from 'date-fns/locale/th';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MUIDatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const DatePicker = ({
  value,
  setValue,
  minDate = new Date('2012-03-01'),
  maxDate = new Date('2030-06-01'),
  label = 'เริ่มต้นค้นหา',
  size = 'small',
}) => (
  <div>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
      <MUIDatePicker
        views={['year', 'month', 'day']}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        inputFormat={'dd MMM yyyy '}
        onChange={(newValue) => {
          setValue(newValue);
          //   setDateChange(newValue);
        }}
        renderInput={(params) => (
          <TextField fullWidth {...params} size={size} />
        )}
      />
    </LocalizationProvider>
  </div>
);

DatePicker.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
};

export default DatePicker;
