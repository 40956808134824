import React from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  dayjs,
  // addressToString,
  showDeliveryOrderStatus,
} from '../../../utils/functions';

const DeliveryOrderHistoryTable = ({
  handleChangeRowsPerPage,
  deliveryOrders,
  handleChangePage,
  page,
  size,
  total,
  handlePrintPdfDeliveryOrder,
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <colgroup>
          <col width="5%" />
          <col width="10%" />
          <col width="10%" />
          <col width="12%" />
          <col width="8%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="15%" />
          <col width="5%" />
          <col width="5%" />
          <col width="5%" />
          <col width="10%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">วันรับ </div>
            </TableCell>
            <TableCell>
              <div className="font-bold">วันส่ง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เลขที่รายการ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">สถานะ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เลขที่ใบแจ้งหนี้</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ลูกค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">สถานที่รับ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">สถานที่ส่ง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">คอนเทนเนอร์</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">น้ำหนัก</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">พาเลท/หีบห่อ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> หมายเหตุ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(deliveryOrders.rows) ? (
            deliveryOrders.rows.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {(page - 1) * size + index + 1}
                </TableCell>
                <TableCell>
                  {`${dayjs(row?.start_date).format('DD MMM BBBB')}`}
                </TableCell>
                <TableCell>
                  {`${dayjs(row?.end_date).format('DD MMM BBBB')}`}
                </TableCell>
                <TableCell>{row?.running_number || '-'}</TableCell>
                <TableCell>
                  <div className="flex flex-col gap-2 ">
                    <div className="flex justify-center font-bold">
                      {row?.assignment_type}
                    </div>
                    <div>{showDeliveryOrderStatus({ ...row })}</div>
                  </div>
                </TableCell>
                <TableCell>{row?.invoice_number || '-'}</TableCell>
                <TableCell>
                  <div>
                    <b>{row?.customer?.type_code || ''} </b>{' '}
                    {row?.customer?.name || '-'}
                  </div>
                </TableCell>
                <TableCell>
                  <div>{row?.origin || '-'}</div>
                </TableCell>
                <TableCell>
                  <div>{row?.destination || '-'}</div>
                </TableCell>
                <TableCell>{row?.container?.licence_number || '-'}</TableCell>
                <TableCell>{row?.weight || '-'} กิโลกรัม</TableCell>
                <TableCell>{row?.amount || '-'}</TableCell>
                <TableCell>{row?.remark || '-'}</TableCell>
                <TableCell>
                  <div className="mb-2">
                    <Button
                      variant="contained"
                      color="primary"
                      size={'small'}
                      onClick={() => {
                        handlePrintPdfDeliveryOrder(row);
                      }}
                    >
                      พิมพ์
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={handleChangePage}
    />
  </Paper>
);

DeliveryOrderHistoryTable.propTypes = {
  handleChangeRowsPerPage: PropTypes.func,
  deliveryOrders: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleReady: PropTypes.func,
  handleCancle: PropTypes.func,
  handleToEditPage: PropTypes.func,
  handleToInfoPage: PropTypes.func,
};

export default DeliveryOrderHistoryTable;
