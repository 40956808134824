import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
} from '@mui/material';
import _, { each } from 'lodash';
import { dayjs } from '../../../utils/functions';
import { useHistory } from 'react-router';

export default function MachineryListTable({ machinery }) {
  const history = useHistory();
  return (
    <div>
      <div>
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>ลำดับ</TableCell>
                  <TableCell>ชื่อเครื่องจักร</TableCell>
                  <TableCell>เลขทะเบียน</TableCell>
                  <TableCell>ข้อมูลเครื่องจักร</TableCell>
                  <TableCell>ดำเนินการ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(machinery?.rows, (each, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{each?.name}</TableCell>
                    <TableCell>{each?.licence_number}</TableCell>
                    <TableCell>
                      <div className="">ยี่ห้อ: {each?.attribute?.brand}</div>
                      <div className="">
                        รุ่น: {each?.attribute?.generation}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          history.push(`/mech/mech/detail/${each?._id}`)
                        }
                      >
                        รายละเอียด
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
