import React from 'react';
import PropTypes, { object } from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import _ from 'lodash';
import { Autocomplete, Box, Button, TextField } from '@mui/material';

const MaintenerForm = ({ control, employee }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'mainteners',
  });

  return (
    <div className="w-full">
      <div className="px-1 py-2  font-semibold text-lg ">ช่างซ่อมบำรุง</div>
      <div className="flex flex-wrap">
        {_.map(fields, (eachField, index) => (
          <div key={eachField?.id} className="w-full lg:w-1/2 px-2 py-1 flex">
            <div className="w-3/4">
              {!_.isEmpty(employee?.rows) && (
                <Controller
                  name={`mainteners[${index}]`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={employee?.rows}
                      disableClearable
                      fullWidth
                      defaultValue={field?.value}
                      autoHighlight
                      required
                      size="small"
                      getOptionLabel={(option) =>
                        `${option?.firstname || ''} ${
                          option?.lastname || ''
                        } (${option?.nickname || '-'})`
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: 15,
                            '& > span': { mr: '10px', fontSize: 18 },
                          }}
                          {...props}
                        >
                          {option?.firstname || ''} {option?.lastname || ''} (
                          {option?.nickname || '-'})
                        </Box>
                      )}
                      onChange={(e, value) => {
                        field.onChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="พนักงาน"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  )}
                />
              )}
            </div>
            <div className="w-1/4 px-1 self-center flex justify-center">
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => remove(index)}
              >
                ลบ
              </Button>
            </div>
          </div>
        ))}
      </div>{' '}
      <div className="w-full my-2 px-2">
        <Button
          size="small"
          variant="outlined"
          onClick={() => append(employee?.rows?.[0])}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );
};

MaintenerForm.propTypes = {
  control: PropTypes.object,
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default MaintenerForm;
