/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CreateWheelType from 'views/WMS/CreateWheelType';
import EditWheelType from 'views/WMS/EditWheelType';
import { Chip } from '@mui/material';
import {
  WheelOrder,
  CreateWheelMaintenOrder,
  WheelStore,
  WheelHistory,
  AddWheelStore,
  EditWheel,
  CreateWheelRegister,
  WheelRegister,
  InfoWheelRegister,
  DetailWheelMaintenance,
  WheelMaintenance,
  DetailWheelMaintenanceHistory,
  WheelType,
  DetailWheelInfo,
  CreateWheelsupply,
  DetailWheelSupply,
  EditWheelSupply,
  WheelSupply,
  CreateManageWheel,
  WheelRepair,
  TrailerList,
  CreateTrailer,
  InfoWheelTrailertail,
} from '../views/WMS';

import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import WheelMaterialWithdraw from '../views/WMS/WheelMaterialWithdraw';
import WheelMaterialRemove from '../views/WMS/WheelMaterialRemove';

export function WMS() {
  const module = 'WMS';
  const prefix = '/wms';
  const name = 'รายการแจ้งซ่อมยาง';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const me = useSelector((state) => state.me);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : CRM');

    dispatch(actions.meGet());
    dispatch(actions.informationAll());

    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            {/* <Chip
              label="*** กำลังพัฒนา งดเพิ่มข้อมูลจริงในระบบ ***"
              color="error"
            ></Chip> */}
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/wms`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/detail/:id`}>
                <DetailWheelMaintenance
                  title={'รายการซ่อมยาง'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/wheelMaintenance/:wms`}>
                <WheelMaintenance title={'เปลี่ยนยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/repair/:wms/:id`}>
                <WheelRepair title={'ซ่อมยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wms`}>
                <WheelOrder title={'รายการซ่อมยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wms/create`}>
                <CreateWheelMaintenOrder
                  title={'แจ้งซ่อมยาง'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/store`}>
                <WheelStore title={'คลังยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/store/manage`}>
                <CreateManageWheel title={'จัดการยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/store/info/:id`}>
                <DetailWheelInfo title={'ยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/store/add`}>
                <AddWheelStore title={'เพิ่มยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/store/editwheel/:id`}>
                <EditWheel title={'แก้ไขข้อมูลยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/register`}>
                <WheelRegister title={'รายการหัวรถ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/register/info/:id`}>
                <InfoWheelRegister title={'ข้อมูลยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/register/create`}>
                <CreateWheelRegister
                  title={'เพิ่มข้อมูลยางในหัวรถ'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/trailer`}>
                <TrailerList title={'รายการหางรถ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/trailer/create`}>
                <CreateTrailer title={'เพิ่มหางรถ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/trailertail/info/:id`}>
                <InfoWheelTrailertail
                  title={'ข้อมูลยางหางรถ'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-withdraw/:id`}>
                <WheelMaterialWithdraw title={'เบิกวัสดุ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-return/:id`}>
                <WheelMaterialRemove title={'คืนวัสดุ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history`}>
                <WheelHistory title={'ประวัติการซ่อมยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wheel-supply`}>
                <WheelSupply title={'ยี่ห้อยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wheel-supply/create`}>
                <CreateWheelsupply title={'เพิ่มยี่ห้อยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wheel-supply/detail/:id`}>
                <DetailWheelSupply title={'ข้อมูลยี่ห้อยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wheel-supply/detail/edit/:id`}>
                <EditWheelSupply title={'แก้ไขยี่ห้อยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wheel-types`}>
                <WheelType title={'ตั้งค่าประเภทยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wheel-types/create`}>
                <CreateWheelType title={'เพิ่มประเภทยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/wheel-types/edit/:id`}>
                <EditWheelType title={'แก้ไขประเภทยาง'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history/detail/:id`}>
                <DetailWheelMaintenanceHistory
                  title={'รายละเอียดการซ่อมยาง'}
                  subtitle={name}
                />
              </Route>

              {accessRight(me, module, 1) && <Switch></Switch>}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default WMS;
