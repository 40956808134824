/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { ViewTitle } from '../../components/ViewTitle';
import { useForm, Controller } from 'react-hook-form';
import { BackButton } from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import { useHistory } from 'react-router';
import Loading from 'components/Loading';
import WheelSupplyAddForm from '../../components/Forms/WMS/WheelSupplyAddForm';
import PropTypes from 'prop-types';

export default function CreateWheelSupply({ title, subtitle }) {
  const { handleSubmit, control } = useForm({});
  const dispatch = useDispatch();
  const history = useHistory();
  const wheelType = useSelector((state) => state.wheelType);
  const [selectType, setSelectType] = useState();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  useEffect(() => {
    dispatch(actions.wheelTypeAll({}));

    return () => {};
  }, []);
  // console.log('selextType', selectType);
  const onSubmitData = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      try {
        // console.log('data', data);
        const dataSubmit = { ...data };
        await dispatch(actions.wheelSupplyCreate(dataSubmit));
        await dispatch(actions.wheelSupplyAll({}));
        history.push('/wms/wheel-supply');
      } catch (error) {
        console.log('error', error);
        alert('บันทึกข้อมูลผิดพลาด');
      }
    }
  };

  const renderWheelAddForm = () => (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <WheelSupplyAddForm
        control={control}
        Controller={Controller}
        wheelType={wheelType}
        selectType={selectType}
        setSelectType={setSelectType}
      />
    </form>
  );
  if (wheelType.isLoading || !wheelType.isCompleted) {
    return <Loading />;
  }
  if (!wheelType.isLoading || wheelType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderWheelAddForm()}
      </div>
    );
  }
}
CreateWheelSupply.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateWheelSupply.defaultProps = {
  title: '',
  subtitle: '',
};
