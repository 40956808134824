import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { BillDetailCard } from '../../components/Card';

function DetailBill({ title, subtitle }) {
  const dispatch = useDispatch();
  const bill = useSelector((state) => state.bill);
  const params = useParams();

  useEffect(() => {
    dispatch(actions.billGet(params.id));
    return () => {};
  }, [params]);

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderContent = () => (
    <div className="my-2">
      <BillDetailCard bill={bill} />
    </div>
  );

  if (bill.isLoading) {
    return <Loading />;
  }

  if (!bill.isLoading && bill.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderContent()}
      </div>
    );
  }
  return <Error />;
}

DetailBill.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailBill.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailBill;
