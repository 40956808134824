/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import currencyFormatterTH from '../../utils/functions/currencyFormat';
import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genMaterialTransaction = (data) => {
  return _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.lot_number || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.material?.type_code || ''} ${
        _data?.lotId?.material?.name
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.place?.name}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity)} ${
        _data?.lotId?.material?.unit
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.amount)} ${
        _data?.lotId?.material?.unit
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);
};

export const DetailMaterialHTypeDescReport = (
  materialTransaction,
  information,
) => {
  const materialData = genMaterialTransaction(
    materialTransaction?.material_stock_lot,
  );
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 100, 40, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ประวิติการจัดการวัสดุ',
    },
    header: ReportHeader(information),
    content: [
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: `${materialTransaction?.material_transaction_type?.name}`,
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['15%', '37%', '15%', '37%'],
          heights: [15],
          body: [
            [
              {
                text: 'วันที่เบิกวัสดุ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(materialTransaction?.createdAt)
                  .locale('th')
                  .format('DD MMMM BBBB')} เวลา ${new Date(
                  materialTransaction?.createdAt,
                ).toLocaleTimeString('th')} น.`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ผู้เบิก',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${materialTransaction?.request_name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'แผนกที่ขอเบิก',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  materialTransaction?.material_withdraw?._id
                    ? materialTransaction?.material_withdraw?.receive_department
                        ?.name ||
                      materialTransaction?.receive_department?.name ||
                      '-'
                    : materialTransaction?.request_department?.name || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 15, 30, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '15%', '30%', '20%', '15%', '15%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อวัสดุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อคลัง',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'คงเหลือ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...materialData,
          ],
        },
      },
      {
        alignment: '่justify',
        margin: [0, 20, 0, 10],
        text: `หมายเหตุ : ${materialTransaction?.remark || ''}`,
        fontSize: '10',
      },
    ],
  };
  pdfMake.createPdf(docDefinition).open();
};
