import React from 'react';
import { Card, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import addressToString from '../../utils/functions/addressToString';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export function ShipmentOrderCard({
  value,
  toDetail,
  handleOpenSign,
  setHandleOpenSign,
  setOrderSusscess,
  setStatusIdSusscess,
  setStatusCodeSusscess,
  constant,
  status,
  type,
  setSuccess,
}) {
  const history = useHistory();
  const genCard = () =>
    _.map(value, (_value) => (
      <div key={_value?.id} className="w-full lg:w-4/12 p-2 cursor-pointer">
        <Card>
          <div className="p-4">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 py-2 font-semibold">
                เลขติดตามพัสดุ
              </div>
              <div className="w-full lg:w-2/3 md:w-2/3 sm:w-2/3 py-2 text-sm">
                {`${_value?.trackId}`}
              </div>
              <div className="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 py-2 font-semibold">
                ชื่อลูกค้า
              </div>
              <div className="w-full lg:w-2/3 md:w-2/3 sm:w-2/3 py-2 text-sm">
                {`${_value?.destination?.firstname} ${_value?.destination?.lastname}`}
              </div>
              <div className="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 py-2 font-semibold">
                เบอร์โทร
              </div>
              <div className="w-full lg:w-2/3 md:w-2/3 sm:w-2/3 py-2 text-sm">
                {`${_value?.destination?.telephone}`}
              </div>
              <div className="w-full lg:w-1/3 md:w-1/3 sm:w-1/3 py-2 font-semibold">
                ที่อยู่ส่งมอบ
              </div>
              <div className="w-full lg:w-2/3 md:w-2/3 sm:w-2/3 py-2 text-sm">
                {`${addressToString(_value?.destination?.address)}`}
              </div>
            </div>
            <div className="flex w-full py-2 justify-center">
              {_value?.signature ? (
                <img className="rounded-md" src={_value?.signature} />
              ) : (
                <div> </div>
              )}
            </div>
            {type === 'distribution' ? (
              <>
                {_.map(status, (_status) => {
                  if (
                    _status?.status_code ===
                    constant.ORDER_DELIVERY_SUCCESS.status_code
                  ) {
                    return (
                      <div className="mr-2 py-1 w-full" key={_status?.id}>
                        <Button
                          variant="outlined"
                          color={'secondary'}
                          size={'small'}
                          fullWidth
                          onClick={() => {
                            setHandleOpenSign(!handleOpenSign);
                            setOrderSusscess(_value?.id);
                            setStatusIdSusscess(_status?.id);
                            setStatusCodeSusscess(_status?.status_code);
                          }}
                        >
                          เซ็นรับสินค้า
                        </Button>
                      </div>
                    );
                  }
                  return <div key={_status?.id}></div>;
                })}
                <div className="flex justify-end">
                  <div className="mr-2 py-1 ">
                    <Button
                      variant="contained"
                      color={'info'}
                      size={'small'}
                      onClick={() => history.push(toDetail + _value?.id)}
                    >
                      รายละเอียด
                    </Button>
                  </div>
                  {_.map(status, (_status) => {
                    if (
                      _status?.status_code ===
                      constant.ORDER_SUCCESS.status_code
                    ) {
                      return (
                        <div className="py-1" key={_status?.id}>
                          <Button
                            variant="contained"
                            color={'success'}
                            size={'small'}
                            onClick={() => {
                              setSuccess({
                                orderId: _value?.id,
                                statusId: _status?.id,
                                statusCode: _status?.status_code,
                              });
                            }}
                          >
                            {`${_status.description}`}
                          </Button>
                        </div>
                      );
                    }
                    return <div key={_status?.id}></div>;
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    color={'info'}
                    size={'small'}
                    onClick={() => history.push(toDetail + _value?.id)}
                  >
                    รายละเอียด
                  </Button>
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    ));

  return (
    <div className="flex-auto">
      <div className="relative flex flex-wrap">{genCard()}</div>
    </div>
  );
}

ShipmentOrderCard.propTypes = {
  value: PropTypes.array,
  toDetail: PropTypes.string,
  handleOpenSign: PropTypes.func,
  setHandleOpenSign: PropTypes.func,
  setOrderSusscess: PropTypes.func,
  setStatusIdSusscess: PropTypes.func,
  setStatusCodeSusscess: PropTypes.func,
  constant: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  setSuccess: PropTypes.func,
};

ShipmentOrderCard.defaultProps = {
  value: '',
  toDetail: '',
  handleOpenSign: '',
  setHandleOpenSign: '',
  setOrderSusscess: '',
  setStatusIdSusscess: '',
  setStatusCodeSusscess: '',
  constant: '',
  status: '',
  type: '',
  setSuccess: '',
};

export default ShipmentOrderCard;
