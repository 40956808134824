/* eslint-disable no-plusplus */
/* eslint-disable no-sparse-arrays */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currencyFormatter } from 'utils/functions';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const renderMaintenaneWheel = (data) =>
  _.map(data, (each, index) => [
    {
      text: index + 1,
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.wheel_Id?.wheelNumber, alignment: 'center', colSpan: 1 },
    { text: each?.position, alignment: 'center', colSpan: 1 },
    { text: each?.cause, alignment: 'center', colSpan: 1 },
  ]);

const renderMaintenaneWheelAll = (data) =>
  _.map(data, (each, index) => [
    {
      text: index + 1,
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: each?.wheelReplace?.wheelNumber,
      alignment: 'center',
      colSpan: 1,
    },
    {
      text: each?.wheel?.position,
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.cause, alignment: 'center', colSpan: 1 },
    { text: each?.wheel?.wheelNumber || '', alignment: 'center', colSpan: 1 },
  ]);

const renderMaintenaneNewWheel = (data) =>
  _.map(data, (each, index) => [
    {
      text: index + 1,
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.wheel?.wheelNumber || '', alignment: 'center', colSpan: 1 },
    { text: each?.position || '', alignment: 'center', colSpan: 1 },
    { text: each?.wheel?.supply?.brand || '', alignment: 'center', colSpan: 1 },
  ]);

const renderTransection = (data) =>
  _.map(data, (each, index) => [
    {
      text: index + 1,
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.repairTopic || '', alignment: 'center', colSpan: 1 },
    {
      text: currencyFormatter.format(each?.price) || '',
      alignment: 'center',
      colSpan: 1,
    },
    { text: each?.note || '', alignment: 'center', colSpan: 1 },
  ]);

const WheelMaintenanceReportOther = async (info, row, transection) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  const wheelIn = _.filter(row?.wheelTransection, { topic: 'IN' });
  const wheelOut = _.filter(row?.wheelTransection, { topic: 'OUT' });

  const Transection = _.groupBy(row?.wheelTransection, 'position');

  const maintenanceData = renderMaintenaneWheel(row?.wheelOrder);
  const MaintenaneWheelAll = renderMaintenaneWheelAll(wheelIn);
  const matananceNewWheel = renderMaintenaneNewWheel(wheelIn);
  const transectionWheel = renderTransection(
    _.filter(transection?.rows, { repairStatus: true }),
  );
  console.log('wheelIn', wheelIn);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 50, 20, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '12',
    },
    header: [
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: 'สรุปรายการซ่อมยาง',
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],

    info: { title: 'ใบซ่อมยาง' },

    content: [
      {
        margin: [20, 20, 20, 20],
        table: {
          widths: ['10%', '40%', '15%', '30%'],
          body: [
            [
              {
                text: 'วันที่',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(row?.createdAt).format('DD MMMM BBBB')}`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'หัวรถ',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.vehicle?.licence_number || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: 'หางรถ',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.trailer_tail?.licence_number || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เลขไมล์',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: row?.mileage || '-',
                colSpan: 1,
                border: [false, false, false, false],
              },

              {
                text: '',
                border: [false, false, false, false],
              },

              {
                text: '',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 2,
          widths: ['10%', '20%', '20%', '30%', '20%'],
          body: [
            [
              {
                text: 'รายการเปลี่ยนยาง',
                border: [false, false, false, true],

                colSpan: 5,
              },
              {},
              {},
              {},
              {},
            ],
            [
              {
                text: 'ลำดับ',
                alignment: 'center',
                colSpan: 1,
              },
              { text: 'เลขยางเก่า', alignment: 'center', colSpan: 1 },
              { text: 'ตำแหน่ง', alignment: 'center', colSpan: 1 },
              { text: 'สาเหตุ' || '', alignment: 'center', colSpan: 1 },
              { text: 'เลขยางใหม่', alignment: 'center', colSpan: 1 },
            ],
            ...MaintenaneWheelAll,
          ],
        },
      },
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 2,
          widths: ['10%', '30%', '30%', '30%'],
          body: [
            [
              {
                text: 'รายการค่าใช้จ่าย',
                bold: true,
                margin: [0, 4],
                border: [false, false, false, true],
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
            [
              {
                text: 'ลำดับ',
                alignment: 'center',
                colSpan: 1,
              },
              { text: 'รายการ', alignment: 'center', colSpan: 1 },
              { text: 'ราคา', alignment: 'center', colSpan: 1 },
              { text: 'หมายเหตุ', alignment: 'center', colSpan: 1 },
            ],
            ...transectionWheel,
            [
              {
                text: 'รวม',
                alignment: 'center',
                colSpan: 3,
              },
              { text: '', alignment: 'center', colSpan: 1 },
              { text: 'หมายเหตุ', alignment: 'center', colSpan: 1 },
              {
                text: currencyFormatter.format(
                  _.sumBy(
                    _.filter(transection?.rows, { repairStatus: true }),
                    (e) => e?.price,
                  ),
                ),
                alignment: 'center',
                colSpan: 1,
              },
            ],
          ],
        },
      },
      {
        margin: [20, 20, 20, 20],
        table: {
          alignment: '',
          headerRows: 2,
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: '',
                alignment: 'center',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ...................................ผู้แจ้ง',
                alignment: 'center',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                alignment: 'center',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '(.................................................)',
                border: [false, false, false, false],
                alignment: 'center',
                colSpan: 1,
              },
            ],
          ],
        },
      },
    ],
    footer: [{}],

    styles: {
      headerPage: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default WheelMaintenanceReportOther;
