import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import { DatePicker } from '../../components/Datepicker';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BillSummaryTable } from '../../components/Table';
import { dayjs } from '../../utils/functions';
import { BillSummaryReport } from '../../components/Report';

function BillSummary({ title, subtitle }) {
  const dispatch = useDispatch();
  const billBooking = useSelector((state) => state.billBooking);
  const information = useSelector((state) => state.information);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [includeCompleted, setIsIncludeCompleted] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  useEffect(() => {
    dispatch(
      actions.billBookingAll({
        name,
        page,
        size,
        deleted: false,
        date:
          selectedDate !== '' ? dayjs(selectedDate).format('YYYY-MM-DD') : '',
      }),
    );
  }, [name, page, size, includeCompleted, selectedDate]);

  useEffect(() => {
    setTotal(billBooking?.total);
    return () => {};
  }, [billBooking]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const tableFunction = {
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleDelete: async (id) => {
      const confirm = window.confirm('ยืนยันการลบ Booking');
      if (confirm) {
        await dispatch(actions.billBookingDelete(id));
        await dispatch(actions.billBookingAll({ name, page, size }));
      }
    },
    page,
    size,
    total,
    billBookings: billBooking?.rows,
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <div className="self-center">
        <Button
          variant="contained"
          onClick={() => BillSummaryReport(billBooking?.rows, information)}
        >
          พิมพ์
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap">
        <div className="w-full lg:w-3/4 md:w-1/2 px-2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full lg:w-1/4 px-2 flex gap-1">
          <DatePicker
            label="วันที่ค้นหา"
            value={selectedDate}
            setValue={setSelectedDate}
          />
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setSelectedDate('')}
          >
            Clear
          </Button>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-4">
      <BillSummaryTable {...tableFunction} />
    </div>
  );

  if (billBooking.isLoading) {
    return <Loading />;
  }
  if (!billBooking.isLoading && billBooking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

BillSummary.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

BillSummary.defaultProps = {
  title: '',
  subtitle: '',
};

export default BillSummary;
