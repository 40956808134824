import {
  INFORMATION_ALL,
  INFORMATION_PUT,
  INFORMATION_LOADING,
  INFORMATION_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const informationAll = () => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/information`,
    );
    if (status === 200) {
      dispatch({ type: INFORMATION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: INFORMATION_ERROR });
    throw new Error(error);
  }
};

export const informationPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: INFORMATION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/information/${id}`,
      payload,
    );
    dispatch({ type: INFORMATION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: INFORMATION_ERROR });
    throw new Error(error);
  }
};
