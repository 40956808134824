/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';
import currencyFormatter from '../../utils/functions/currencyFormat';
import floatingCurrencyFormatter from '../../utils/functions/floatingCurrencyFormater';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

// For Suphasin Logistic
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

function printPDF(order, info, me) {
  const {
    setting: { cmms: cmmsSetting },
  } = info;

  const genMaterials = (processMaterials) => {
    const pageNumber = Math.floor(_.size(processMaterials) / 11) + 1;
    console.log('Page Number', pageNumber);
    const remainingAmount = 11 * pageNumber - _.size(processMaterials);
    console.log('Remainign Number', remainingAmount);
    const arrayOfRemainging = Array(remainingAmount);
    const processMaterialArray = _.concat(processMaterials, arrayOfRemainging);
    const mainData = _.map(processMaterialArray, (eachProcessMat, index) => [
      {
        text: `${index + 1}`,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: eachProcessMat?.material?.type_code || '',
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: eachProcessMat?.material?.name || '',
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: eachProcessMat?.amount
          ? currencyFormatter.format(eachProcessMat?.amount)
          : '',
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: eachProcessMat?.average_price
          ? floatingCurrencyFormatter.format(eachProcessMat?.average_price)
          : '',
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: eachProcessMat?.total_price
          ? floatingCurrencyFormatter.format(eachProcessMat?.total_price)
          : '',
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: '',
        colSpan: 1,
      },
    ]);

    return mainData;
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 280, 40, 300],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบขอเบิกวัตถุดิบ',
    },
    header: [
      {
        text: `${dayjs().format('DD/MM/BBBB')}`,
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 0, 0, 12],
        text: [
          {
            text: `${info?.owner?.name}\n`,
            fontSize: '18',
            bold: true,
          },
          {
            text: `${info?.owner?.address?.house_number || ''} ${
              info?.owner?.address?.village_number
                ? `หมู่ที่${info?.owner?.address?.village_number}`
                : ''
            } ${info?.owner?.address?.road || ''} ${
              info?.owner?.address?.subdistrict || ''
            } ${info?.owner?.address?.district || ''} ${
              info?.owner?.address?.province || ''
            } ${info?.owner?.address?.postcode || ''}\n`,
            fontSize: '10',
          },
          {
            text: `โทร. ${info?.owner.telephone || '-'} แฟกซ์ ${
              info?.owner.fax || '-'
            }\n`,
            fontSize: '10',
          },
          {
            text: `อีเมล ${info?.owner?.email || '-'} เลขที่ผู้เสียภาษี ${
              info?.owner?.tax || '-'
            }\n`,
            fontSize: '10',
          },
        ],
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: 'ใบขอเบิกวัตถุดิบ',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
      },
      {
        style: 'tableExample',
        margin: [25, 10, 10, 2],
        fontSize: 10,
        table: {
          widths: ['28%', '22%', '18%', '28%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `วันที่  ${dayjs(order?.start_date).format(
                  'D MMMM  BBBB',
                )}`,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: 'วัตถุประสงค์ในการเบิก',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: 'เอกสารแนบ',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '✓ เพื่อใช้ในกิจการ ทะเบียนรถ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `      ${
                  order?.vehicle?.licence_number || ''
                }                  `,
                colSpan: 1,
                decoration: 'underline',
                border: [false, false, false, false],
              },
              {
                text: '☐ ใบแจ้งซ่อมเลขที่',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: ` ${order?.running_number || ''}                  `,
                colSpan: 1,
                decoration: 'underline',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '☐ เพื่อบริการลูกค้า ทะเบียนรถ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '__________________________',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '☐ ใบส่งซ่อมเลขที่',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '__________________________',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '☐ ชื่อลูกค้า ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '__________________________',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '☐ อื่นๆ ระบุ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `__________________________`,
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '                                 ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `__________________________`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '☐ อื่นๆ ระบุ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '__________________________',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ข้าพเจ้ามีความประสงค์เบิกวัตถุดิบเพื่อใช้ในงานข้างต้นตามรายการต่อไปนี้',
                colSpan: 3,
                border: [false, false, false, false],
              },
              {},
              {},
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // footer(currentPage, pageCount) {
    //   return [
    //     {
    //       text: `${currentPage.toString()} of ${pageCount}`,
    //       alignment: 'right',
    //       margin: [10, 10, 10, 10],
    //       fontSize: '10',
    //     },
    //   ];
    // },

    content: [
      {
        style: 'tableExample',
        margin: [20, 20, 10, 2],
        layout: {
          fillColor(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        fontSize: 11,
        table: {
          widths: ['10%', '15%', '*', '10%', '15%', '12%', '12%'],
          body: [
            [
              {
                text: 'ลำดับที่',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'รหัสวัตถุดิบ',
                colSpan: 1,
              },
              {
                text: 'รายการ',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'ปริมาณ',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'ราคา/หน่วย',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'จำนวนเงิน',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                bold: true,
              },
            ],
            ...genMaterials(order?.process_materials),
          ],
        },
      },
    ],
    footer: [
      {
        margin: [40, 20, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['12%', '*', '20%'],
          body: [
            [
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '................................................................................................................................................................',
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '................................................................................................................................................................',
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
      {
        margin: [40, 20, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: 'ผู้ขอเบิก',
                colSpan: 1,
                border: [true, true, true, false],
              },
              {
                text: 'ผู้ตรวจสอบ',
                colSpan: 1,
                border: [true, true, true, false],
              },
              {
                text: 'ผู้บันทึก Stock',
                colSpan: 1,
                border: [true, true, true, false],
              },
              {
                text: 'ผู้อนุมัติ',
                colSpan: 1,
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: 'ชื่อ .............................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: `ชื่อ ...${
                  order?.allower?.firstname || '.....................'
                }....`,
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: `ชื่อ ....${
                  order?.allower?.firstname || '.....................'
                }....`,
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: 'ชื่อ .............................',
                colSpan: 1,
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: ` วันที่ ${dayjs(order?.start_date).format(
                  ' D / M / BB',
                )}`,
                colSpan: 1,
                border: [true, false, true, true],
              },
              {
                text: ` วันที่ ${dayjs(order?.start_date).format(
                  ' D / M / BB',
                )}`,
                colSpan: 1,
                border: [true, false, true, true],
              },
              {
                text: ` วันที่ ${dayjs(order?.start_date).format(
                  ' D / M / BB',
                )}`,
                colSpan: 1,
                border: [true, false, true, true],
              },
              {
                text: 'วันที่ ...../....../.........',
                colSpan: 1,
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [40, 20, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['10%', '*'],
          body: [
            [
              {
                text: 'หมายเหตุ',
                colSpan: 2,
                bold: true,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '1. ผู้ขอเบิกให้กรอกในช่องรายการและปริมาณวัตถุดิบ',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '2. ผู้บันทึกบัญชีให้กรอกในช่องราคา/หน่วยและจำนวนเงิน',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'โปรดระบุรายการในใบแจ้งซ่อมที่นอกเหนือจากรายการดังกลล่าวข้างต้น',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: 'ใบแจ้งซ่อมใช้ได้ภายใน 7 วัน นับตั้งแต่วันแจ้งซ่อม',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
    ],
  };

  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
