import {
  MATERIAL_REQUEST_ALL,
  MATERIAL_REQUEST_GET,
  MATERIAL_REQUEST_DEL,
  MATERIAL_REQUEST_PUT,
  MATERIAL_REQUEST_POST,
  MATERIAL_REQUEST_LOADING,
  MATERIAL_REQUEST_ERROR,
} from '../../actions/types';

const initialState = {
  materialRequest: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIAL_REQUEST_LOADING:
      return { isLoading: true, isCompleted: true };
    case MATERIAL_REQUEST_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MATERIAL_REQUEST_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_REQUEST_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_REQUEST_POST:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_REQUEST_PUT:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_REQUEST_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
