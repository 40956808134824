import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { EditModePricingForm } from '../../components/Forms';

function EditPricing({ title, subtitle }) {
  const dispatch = useDispatch();
  const zonePrice = useSelector((state) => state.zonePrice);
  const params = useParams();
  const history = useHistory();
  const { control, handleSubmit, setValue } = useForm({});

  useEffect(() => {
    dispatch(actions.zonePriceGet(params.id));
  }, [params]);

  useEffect(() => {
    if (zonePrice) {
      setValue('name', zonePrice?.name);
      setValue('import_cost_matrix', zonePrice?.import_cost_matrix);
      setValue('export_cost_matrix', zonePrice?.export_cost_matrix);
    }

    return () => {};
  }, [zonePrice]);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.zonePricePut(params.id, data));
      alert('แก้ไขโซนราคาสำเร็จ');
      history.goBack();
    } catch (error) {
      alert('ไม้สามารถแก้ไขโซนราคาได้');
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-2 ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditModePricingForm control={control} />
        <div className="my-2 flex justify-end">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  if (zonePrice.isLoading) {
    return <Loading />;
  }
  if (!zonePrice.isLoading && zonePrice.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

EditPricing.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPricing.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditPricing;
