/* eslint-disable func-names */
import {
  MATERIAL_TYPE_ALL,
  MATERIAL_TYPE_GET,
  MATERIAL_TYPE_POST,
  MATERIAL_TYPE_PUT,
  MATERIAL_TYPE_DEL,
  MATERIAL_TYPE_LOADING,
  MATERIAL_TYPE_ERROR,
} from '../../actions/types';

const initialState = {
  materialType: null,
  isLoading: true,
  isCompleted: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIAL_TYPE_LOADING:
      return { isLoading: true, isCompleted: true };
    case MATERIAL_TYPE_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MATERIAL_TYPE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_TYPE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_TYPE_POST:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_TYPE_PUT:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_TYPE_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
