import {
  WHEEL_MAINTENANCE_HISTORY_ALL,
  WHEEL_MAINTENANCE_HISTORY_GET,
  WHEEL_MAINTENANCE_HISTORY_POST,
  WHEEL_MAINTENANCE_HISTORY_PUT,
  WHEEL_MAINTENANCE_HISTORY_DELETE,
  WHEEL_MAINTENANCE_HISTORY_LOADING,
  WHEEL_MAINTENANCE_HISTORY_ERROR,
  WHEEL_MAINTENANCE_HISTORY_RESET,
} from '../../actions/types';

const initialState = {
  wheel: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case WHEEL_MAINTENANCE_HISTORY_LOADING:
      return { isLoading: true, isCompleted: false };
    case WHEEL_MAINTENANCE_HISTORY_ERROR:
      return { isLoading: false, isCompleted: false };
    case WHEEL_MAINTENANCE_HISTORY_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WHEEL_MAINTENANCE_HISTORY_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WHEEL_MAINTENANCE_HISTORY_POST:
      return { isLoading: false, isCompleted: true };
    case WHEEL_MAINTENANCE_HISTORY_PUT:
      return { isLoading: false, isCompleted: true };
    case WHEEL_MAINTENANCE_HISTORY_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
