import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Card } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const material = useSelector((state) => state.material);

  useEffect(() => {
    dispatch(actions.materialGet(id, { placeId: '' }));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading || material.rows) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'รหัสวัสดุ'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                material?.type_code || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>
              <div className="w-1/2 py-4 ">{`${material?.name || ''}`}</div>
              {material?.detail ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'รายละเอียด'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${
                    material?.detail || ''
                  }`}</div>
                </>
              ) : (
                <></>
              )}
              <div className="w-1/2 py-4 px-2 font-semibold ">{'หน่วย'}</div>
              <div className="w-1/2 py-4 ">{`${material?.unit || ''}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทวัสดุ'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                material?.material_type?.name || '-'
              }`}</div>

              {material?.register_number ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'เลขทะเบียน'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${
                    material?.register_number || ''
                  }`}</div>
                </>
              ) : (
                <></>
              )}
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'จำนวนขั้นต่ำของวัสดุ'}
              </div>
              <div className="w-1/2 py-4 ">{`${material?.minimum_amount || 0} ${
                material?.unit
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                จำนวนขั้นสูงสุดของวัสดุ
              </div>
              <div className="w-1/2 py-4 ">{`${material?.maximum_amount || 0} ${
                material?.unit
              }`}</div>
              {!_.isEmpty(material?.supplier) ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'คู่ค้า'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    <div className="flex flex-wrap">
                      {_.map(material?.supplier, (customer) => (
                        <div
                          key={customer?._id}
                          className="bg-gray-300 mb-2 p-3 rounded-md text-sm mr-2 "
                        >
                          <div>
                            <b>ชื่อ :</b> {`${customer?.name}`}
                          </div>
                          <div>
                            <b>อีเมล : </b>
                            {`${customer?.email}`}
                          </div>
                          <div>
                            <b>โทร :</b>
                            {` ${customer?.phone_number}`}
                          </div>
                          <div>
                            <b>ที่อยู่ :</b>
                            {` ${customer?.address}`}{' '}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
