/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';
import currencyFormatter from '../../utils/functions/currencyFormat';
import floatingCurrencyFormatter from '../../utils/functions/floatingCurrencyFormater';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

// For Suphasin Logistic
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};
const renderDetail = ({ order, topic }) => {
  console.log('data', topic);
  console.log('order', order?.maintenance_topic);
  const data = _.filter(
    order?.maintenance_topic,
    (e) => e?.topic?.name === topic,
  );
  console.log('dataaa', data);
  return _.map(data, (e) => ` - ${e.detail}\n`);
};

function printPDF(order, info, me, maintenanceTopic) {
  const {
    setting: { cmms: cmmsSetting },
  } = info;

  const genMaintenanceTopic = () => {
    return {
      style: 'tableExample',
      margin: [30, 0],
      fontSize: 11,
      table: {
        widths: ['50%', '50%'],
        heights: 70,
        body: [
          [
            {
              text: ` \n ${renderDetail({
                order,
                topic: 'ตรวจเช็คทั่วไป',
              })} `,
              colSpan: 1,
              border: [false, false, false, false],
            },
            {
              text: ` \n${renderDetail({
                order,
                topic: 'ระบบเกียร์',
              })}`,
              colSpan: 1,
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: ` \n${renderDetail({
                order,
                topic: 'เช็คระบบเครื่องยนต์',
              })}`,
              colSpan: 1,
              border: [false, false, false, false],
            },
            {
              text: `\n${renderDetail({
                order,
                topic: 'ระบบไฟฟ้า',
              })}`,
              border: [false, false, false, false],
              colSpan: 1,
            },
          ],
          [
            {
              text: `\n${renderDetail({
                order,
                topic: 'ระบบครัช',
              })}`,
              colSpan: 1,
              border: [false, false, false, false],
            },
            {
              text: `\n${renderDetail({
                order,
                topic: 'ระบบเพลา',
              })}`,
              border: [false, false, false, false],
              colSpan: 1,
            },
          ],
          [
            {
              text: `\n${renderDetail({
                order,
                topic: 'ระบบแอร์',
              })}`,
              colSpan: 1,

              border: [false, false, false, false],
            },
            {
              text: `\n${renderDetail({
                order,
                topic: 'ระบบยาง',
              })}`,
              border: [false, false, false, false],
              colSpan: 1,
            },
          ],
        ],
      },
    };
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 336, 40, 240],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบแจ้งซ่อม/ใบรับรถ',
    },
    header: [
      {
        text: '',
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 48, 0, 12],
        text: '',
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: '',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        text: '',
      },
      {
        style: 'tableExample',
        margin: [25, 45, 10, 2],
        fontSize: 10,
        table: {
          widths: ['5%', '20%', '20%', '32%', '22%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: ` ${dayjs(order?.start_date).format('D MMMM  BBBB')}`,
                margin: [0, 0, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 3,
                bold: true,
                border: [false, false, false, false],
              },
              {},
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `${order?.is_self_maintenance ? ' ' : '/'} `,
                colSpan: 1,
                margin: [0, 25, 4, 0],
                alignment: 'right',
                border: [false, false, false, false],
              },
              {
                text: `${order?.is_self_maintenance ? '/' : ' '} `,
                colSpan: 1,
                margin: [30, 25, 0, 0],
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `       ${info?.name || '                  '}       `,
                colSpan: 3,
                decoration: 'underline',
                margin: [25, 22, 0, 8],
                decorationStyle: 'dotted',
                border: [false, false, false, false],
              },
              {},
              {},
            ],
            [
              {
                text: '',
                colSpan: 2,
                bold: true,
                border: [false, false, false, false],
              },
              {},
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `${order?.vehicle?.licence_number || ''} `,
                colSpan: 1,
                margin: [8, 0, 0, 0],
                alignment: 'right',
                border: [false, false, false, false],
                decoration: 'underline',
                decorationStyle: 'dotted',
              },
              {
                text: `${order?.driver?.employee?.tel}`,
                colSpan: 3,
                border: [false, false, false, false],
                bold: true,
              },
              {},
              {},
            ],

            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: order?.mileage || '0',
                colSpan: 3,
                border: [false, false, false, false],
                bold: true,
              },
              {},
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 3,
                border: [false, false, false, false],
              },
              {},
              {},
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        margin: [20, 5, 40, 0],

        fontSize: 11,
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: '',
                colSpan: 2,
                bold: true,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
    ],
    // footer(currentPage, pageCount) {
    //   return [
    //     {
    //       text: `${currentPage.toString()} of ${pageCount}`,
    //       alignment: 'right',
    //       margin: [10, 10, 10, 10],
    //       fontSize: '10',
    //     },
    //   ];
    // },

    content: [genMaintenanceTopic()],
    footer: [
      {
        style: 'tableExample',
        margin: [20, 0, 40, 0],
        fontSize: 11,

        table: {
          widths: ['100%'],
          heights: [10, 40],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: order?.cause || '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [70, 40, 5, 10],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['20%', '20%', '20%', '20%', '20%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `      ${dayjs(order?.start_date).format(' D / M / BB')}`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `      ${dayjs(order?.createdAt).format(' D / M / BB')}`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 0, 10, 30],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['10%', '*'],
          body: [
            [
              {
                text: '',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '   ',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
    ],
  };

  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
