/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import { Button, Card } from '@mui/material';
import _ from 'lodash';
import RepairForm from '../../components/Forms/WMS/RepairForm';
import * as actions from '../../redux/actions';

function WheelRepair({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id, wms } = useParams();
  const history = useHistory();
  const wheelMaintenance = useSelector((state) => state.WheelMaintenance);
  const wheelRegister = useSelector((state) => state.WheelRegister);
  const { handleSubmit, control } = useForm();
  const [wheelSelect, setWheelSelect] = useState();
  const [checked, setChecked] = useState();

  useEffect(() => {
    dispatch(actions.wheelRegisterGet(id));
    dispatch(actions.wheelMaintenanceGet(wms));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => <BackButton />;

  const rederWheel = () => (
    <div>
      <div className="p-2 font-bold ">รายการแจ้งซ่อม</div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 ">
        {_.map(wheelMaintenance?.wheelOrder, (each) => (
          <div className="p-2">
            <Card>
              <div className="p-4 flex justify-between">
                <div>
                  <div className="text-xl font-bold ">
                    เลขยาง: {each?.wheel_Id?.wheelNumber}
                  </div>
                  <div>ตำแหน่งยาง: {each?.position}</div>
                  <div>สาเหตุ: {each?.cause}</div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    // disabled={each?.status_maintenance}
                    onClick={() => setWheelSelect(each?.wheel_Id)}
                  >
                    ซ่อมยาง
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
  const onSubmit = async (data) => {
    if (_.isEmpty(wheelSelect)) {
      return alert('กรุณาเลือกยาง');
    }
    const confirm = window.confirm('บันทึกรายการ');
    if (confirm) {
      const dataSubmit = {
        ...data,
        maintenanceOrder: wms,
        wheel: wheelSelect?._id,
        vehicle: wheelRegister?.vehicle?._id,
        repairStatus: true,
      };
      await dispatch(actions.wheelTransectionCreate(dataSubmit));
      await dispatch(actions.wheelTransectionAll({ vehicle: id }));
      history.goBack();
    }
  };

  const renderDetail = () => (
    <div className="lg:w-1/2 p-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          {checked && <div>{rederWheel()}</div>}
          <div className="p-4">
            <RepairForm
              setChecked={setChecked}
              checked={checked}
              wheelSelect={wheelSelect}
              Controller={Controller}
              control={control}
              setWheelSelect={setWheelSelect}
            />
          </div>
        </Card>
      </form>
    </div>
  );

  return (
    <div>
      <div>
        {renderTitle()}
        {renderBackButton()}
      </div>
      <div></div>
      {renderDetail()}
    </div>
  );
}

WheelRepair.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default WheelRepair;
