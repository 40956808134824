import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import {
  showVehicleScheduleStatus,
  dayjs,
  showDeliveryOrderStatus,
} from '../../../utils/functions';

const VehicleScheduleDisplayCard = ({
  vehicleSchedule,
  handlePending = () => {},
  handleComplete = () => {},
  handleBill = () => {},
  handleCancle = () => {},
}) => {
  const history = useHistory();
  return (
    <div>
      <div className="text-lg">
        <div className=" font-semibold  flex gap-2 justify-between my-2">
          <div className="flex flex-wrap">
            <div className="mr-2">รายการเดินรถ</div>
            {showVehicleScheduleStatus({ ...vehicleSchedule })}{' '}
          </div>
          <div className="flex gap-2">
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={() =>
                history.push(
                  `/tms/vehicle-schedule/edit/${vehicleSchedule?._id}`,
                )
              }
            >
              แก้ไข
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="text-white"
              disabled={vehicleSchedule?.pending}
              onClick={() => handlePending(vehicleSchedule._id)}
            >
              ดำเนินการ
            </Button>
            <Button
              variant="contained"
              size="small"
              color="success"
              className="text-white"
              disabled={vehicleSchedule?.completed}
              onClick={() => {
                if (vehicleSchedule?.pending) {
                  handleComplete(vehicleSchedule._id);
                } else {
                  alert(
                    'ไม่สามารถเปลี่ยนสถานะเป็น สำเร็จ ได้เนื่องจากรายการนี้ยังไม่เริ่มดำเนินการ',
                  );
                }
              }}
            >
              สำเร็จ
            </Button>
            <Button
              variant="contained"
              size="small"
              color="blue"
              className="text-white"
              disabled={vehicleSchedule?.billed}
              onClick={() => {
                if (vehicleSchedule?.completed) {
                  handleBill(vehicleSchedule._id);
                } else {
                  alert(
                    'ไม่สามารถเปลี่ยนสถานะเป็น ออกบิลแล้ว ได้เนื่องจากรายการนี้ยังดำเนินการไม่สำเร็จ',
                  );
                }
              }}
            >
              ออกบิลแล้ว
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              disabled={vehicleSchedule?.completed || vehicleSchedule?.billed}
              onClick={() => handleCancle(vehicleSchedule._id)}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
        <div className="w-full my-1 px-1 flex flex-wrap font-sansserif">
          <div className="w-full lg:w-1/2">
            <div className="w-full text-sm py-2 font-semibold">วันเริ่มต้น</div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {`${dayjs(vehicleSchedule?.start_date).format('DD MMMM BBBB')}`}
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="w-full text-sm py-2 font-semibold">วันเริ่มต้น</div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {`${dayjs(vehicleSchedule?.end_date).format('DD MMMM BBBB')}`}
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="w-full text-sm py-2 font-semibold">
              พนักงานขับรถ
            </div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {`ชื่อ: ${vehicleSchedule?.driver?.employee?.firstname} ${vehicleSchedule?.driver?.employee?.lastname}`}
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-9">
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {`ชื่อเล่น: ${vehicleSchedule?.driver?.employee?.nickname}` ||
                '-'}
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            <div className="w-full text-sm py-2 font-semibold">หัวรถ</div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {`ทะเบียน: ${vehicleSchedule?.vehicle?.licence_number}` || '-'}
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            <div className="w-full text-sm py-2 font-semibold">หางรถ</div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {`ทะเบียน: ${vehicleSchedule?.trailer_tail?.licence_number}` ||
                '-'}
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            <div className="w-full text-sm py-2 font-semibold">
              ตู้คอนเทนเนอร์
            </div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {`ทะเบียน: ${vehicleSchedule?.container?.licence_number}` || '-'}
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="w-full text-sm py-2 font-semibold">หมายเลขซีล</div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {vehicleSchedule?.seal_number || '-'}
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="w-full text-sm py-2 font-semibold">หมายเหตุ</div>
            <div className="rounded  border-2 p-2 mr-2 text-sm">
              {vehicleSchedule?.remark || '-'}
            </div>
          </div>
          <div className="w-full my-1 px-1 flex flex-wrap">
            <div className="w-full text-sm py-2 font-semibold">
              รายการจัดส่ง
            </div>
            {!_.isEmpty(vehicleSchedule?.delivery_orders) ? (
              _.map(vehicleSchedule?.delivery_orders, (order, index) => (
                <div className="lg:w-1/2 px-1 text-sm" id={index}>
                  <div className="rounded  border-2 px-2">
                    <div className="w-full flex flex-wrap p-2">
                      <div className="lg:w-2/3 font-semibold">
                        {`เลขที่ใบแจ้งหนี้ ${order?.invoice_number}` || '-'}
                      </div>
                      <div className="lg:w-1/3 flex justify-between">
                        <div className="flex">
                          {showDeliveryOrderStatus({ ...order })}
                        </div>
                        <Link to={`/tms/delivery-order/edit/${order?._id}`}>
                          <div className="bg-amber-400 border-b-2 border-slate-300 px-2 rounded  text-center">
                            แก้ไข
                          </div>
                        </Link>
                      </div>
                      <div className="lg:w-1/3 font-semibold mt-2">
                        {'วันที่รับสินค้า: '}
                      </div>
                      <div className="lg:w-2/3 mt-2">
                        {`${dayjs(order?.start_date).format('DD MMMM BBBB')}` ||
                          '-'}
                      </div>
                      <div className="lg:w-1/3 font-semibold">
                        {'วันที่ส่งสินค้า: '}
                      </div>
                      <div className="lg:w-2/3">
                        {`${dayjs(order?.end_date).format('DD MMMM BBBB')}` ||
                          '-'}
                      </div>
                      <div className="lg:w-1/3 font-semibold">{'ลูกค้า: '}</div>
                      <div className="lg:w-2/3">
                        {order?.customer?.name || '-'}
                      </div>
                      <div className="lg:w-1/3 font-semibold">{'งาน: '}</div>
                      <div className="lg:w-2/3">{order?.job?.name || '-'}</div>
                      <div className="lg:w-1/3 font-semibold">
                        {'สถานที่รับ: '}
                      </div>
                      <div className="lg:w-2/3">{order?.origin || '-'}</div>
                      <div className="lg:w-1/3 font-semibold">
                        {'สถานที่ส่ง: '}
                      </div>
                      <div className="lg:w-2/3">
                        {order?.destination || '-'}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="rounded  border-2 p-2 mr-2 text-sm">{'-'}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VehicleScheduleDisplayCard.propTypes = {
  vehicleSchedule: PropTypes.object,
  handlePending: PropTypes.func,
  handleComplete: PropTypes.func,
  handleBill: PropTypes.func,
  handleCancle: PropTypes.func,
};

export default VehicleScheduleDisplayCard;
