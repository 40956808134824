import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

const EachMaterialStockCard = ({
  material,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  placeId,
}) => (
  <div>
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
      {_.map(material?.rows, (eachMaterial, index) => (
        <Card key={index}>
          <CardContent>
            <Link
              to={`/ims/material-stock/${eachMaterial?._id}/${
                placeId || 'all'
              }`}
            >
              <div>{eachMaterial?.type_code || ''}</div>
              <div className="font-semibold text-lg">{eachMaterial?.name}</div>
            </Link>
            <div>
              {' '}
              {currencyFormatter.format(eachMaterial?.total)}{' '}
              {eachMaterial?.unit}{' '}
            </div>

            {eachMaterial?.total <= eachMaterial?.minimum_amount && (
              <div className="text-sm text-red-600 my-1 font-semibold font-sansserif">
                {' '}
                <i className="fas fa-sort-down"></i> ต่ำกว่ากำหนด{' '}
                {currencyFormatter.format(
                  (eachMaterial?.minimum_amount || 0) - eachMaterial?.total,
                )}{' '}
                {eachMaterial?.unit}
              </div>
            )}
          </CardContent>
        </Card>
      ))}
    </div>
    <div className="flex justify-center my-2">
      {total - page * size >= 1 && (
        <Button
          variant="contained"
          color="inherit"
          onClick={() =>
            handleChangeRowsPerPage({
              target: {
                value: size + 10,
              },
            })
          }
        >
          แสดงเพิ่มอีก{' '}
          {total - page * size > 10
            ? '10 รายการ'
            : `${total - page * size} รายการ`}
        </Button>
      )}
    </div>
  </div>
);

EachMaterialStockCard.propTypes = {
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  placeId: PropTypes.string,
};

export default EachMaterialStockCard;
