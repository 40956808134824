import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { CONFIG } from '../../utils/constants';
import { dayjs } from '../../utils/functions';
import { BillBookingForm } from '../../components/Forms';

function EditBillBooking({ title, subtitle }) {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job);
  const billBooking = useSelector((state) => state.billBooking);
  const history = useHistory();
  const params = useParams();
  const [ready, setIsReady] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(actions.jobAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.billBookingGet(params.id));
  }, [params]);

  useEffect(() => {
    if (billBooking && !billBooking?.rows) {
      setValue('date', dayjs(billBooking?.date).format('YYYY-MM-DD'));
      setValue('from_vehicle_schedule', billBooking?.from_vehicle_schedule);
      setValue('individual_input', {
        ...billBooking?.individual_input,
        job: _.find(
          job?.rows,
          (eachJob) => eachJob?._id === billBooking?.individual_input?.job?._id,
        ),
      });
      setValue('price', billBooking?.price);
      setValue('remark', billBooking?.remark);
      setIsReady(true);
    }
    return () => {};
  }, [billBooking]);

  const handleSubmitBillBooking = async (data) => {
    try {
      await dispatch(actions.billBookingPut(params.id, data));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert('สร้างรายการออกบิลไม่สำเร็จ');
      console.error('Error on Create Bill', error);
    }
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
    </div>
  );

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-4">
      <form onSubmit={handleSubmit(handleSubmitBillBooking)}>
        <Card>
          <CardContent>
            {ready && (
              <BillBookingForm
                control={control}
                errors={errors}
                job={job}
                setValue={setValue}
                watch={watch}
                billBooking={billBooking}
              />
            )}
            <div className="flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );

  if (billBooking.isLoading) {
    return <Loading />;
  }

  if (!billBooking.isLoading && billBooking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

EditBillBooking.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditBillBooking.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditBillBooking;
