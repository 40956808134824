import React from 'react';
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';

import dayjs from '../../../utils/functions/thDayjs';

const DeliveryOrderSelectorTable = ({
  deliveryOrders,
  page,
  size,
  handleChangePage,
  handleChangeRowsPerPage,
  total,
  selectedDeliveryOrder,
  setSelectedDeliveryOrder,
}) => {
  const isSelected = (selectedRow) =>
    selectedDeliveryOrder.indexOf(selectedRow?._id) !== -1;

  const handleSelectDO = (event, selectedRow) => {
    const selectedIndex = selectedDeliveryOrder.indexOf(selectedRow?._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDeliveryOrder, selectedRow?._id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDeliveryOrder.slice(1));
    } else if (selectedIndex === selectedDeliveryOrder.length - 1) {
      newSelected = newSelected.concat(selectedDeliveryOrder.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDeliveryOrder.slice(0, selectedIndex),
        selectedDeliveryOrder.slice(selectedIndex + 1),
      );
    }
    setSelectedDeliveryOrder(newSelected);
  };

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="8%" />
              <col width="20%" />
              <col width="15%" />
              <col width="15%" />
              <col width="5%" />
              <col width="5%" />
              <col width="5%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันรับ </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันส่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขที่ใบแจ้งหนี้</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สถานที่รับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สถานที่ส่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คอนเทนเนอร์</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">น้ำหนัก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">พาเลท/หีบห่อ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">น้ำมันที่ใช้</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ระยะทาง</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(deliveryOrders.rows) ? (
                deliveryOrders.rows.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    role="checkbox"
                    selected={isSelected(row)}
                    onClick={(event) => handleSelectDO(event, row)}
                    hover
                  >
                    <TableCell component="th" scope="row">
                      <Checkbox checked={isSelected(row)} />
                    </TableCell>
                    <TableCell>
                      {`${dayjs(row?.start_date).format('DD MMM BBBB')}`}
                    </TableCell>
                    <TableCell>
                      {`${dayjs(row?.end_date).format('DD MMM BBBB')}`}
                    </TableCell>

                    <TableCell>{row?.invoice_number || '-'}</TableCell>
                    <TableCell>
                      <div>{row?.running_number}</div>
                      <div className="bg-blue-300 px-2 py-1 my-2 rounded-md whitespace-nowrap">
                        {row?.job?.name}
                      </div>
                      <div>{row?.customer?.name || '-'}</div>
                    </TableCell>
                    <TableCell>
                      <div>{row?.origin || '-'}</div>
                    </TableCell>
                    <TableCell>
                      <div>{row?.destination || '-'}</div>
                    </TableCell>
                    <TableCell>
                      {row?.container?.licence_number || '-'}
                    </TableCell>
                    <TableCell>{row?.weight || '-'} กิโลกรัม</TableCell>
                    <TableCell>{row?.amount || '-'}</TableCell>
                    <TableCell>{row?.refuel || '-'} ลิตร</TableCell>
                    <TableCell>{row?.distance || '-'} กิโลเมตร</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
};

DeliveryOrderSelectorTable.propTypes = {
  deliveryOrders: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  selectedDeliveryOrder: PropTypes.arrayOf(PropTypes.object),
  setSelectedDeliveryOrder: PropTypes.func,
};

export default DeliveryOrderSelectorTable;
