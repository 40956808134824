/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Button } from '@mui/material';
import _ from 'lodash';
import img_trailer from '../../../assets/img/trailer.jpg';

export default function TrailertailWheelInfoMaintenanceForm({
  tailertailSelect,
  wheelTailCheck,
  setArrayWheel,
  arrayWheel,
  reload,
  setReload,
  selectWheel,
}) {
  const renderTrailerTailFromMaintenance = (wheel, wheelData) => {
    const wheels = tailertailSelect?.wheels;

    if (wheel <= 4) {
      const wheel1 = _.find(wheels, { position: 1 });
      const wheel2 = _.find(wheels, { position: 2 });
      const wheel3 = _.find(wheels, { position: 3 });
      const wheel4 = _.find(wheels, { position: 4 });
      return (
        <div className="p-8 drop-shadow-xl">
          <Card>
            <h1 className="p-4 font-bold">ตำแหน่งยางหาง</h1>

            <div className="flex w-full justify-center">
              <div className="w-1/3 pt-6 ">
                <div className="flex justify-end">
                  <Button onClick={() => selectWheel(wheel1)}>
                    เลขยาง1 : {wheel1?.wheelNumber}
                  </Button>
                </div>

                <div className="flex justify-end pt-36">
                  <Button onClick={() => selectWheel(wheel3)}>
                    เลขยาง3 : {wheel3?.wheelNumber}
                  </Button>
                </div>
              </div>
              <div className="mb-4">
                <img src={img_trailer}></img>
              </div>
              <div className="w-1 3 pt-6 ">
                <Button onClick={() => selectWheel(wheel2)}>
                  เลขยาง2 : {wheel2?.wheelNumber}
                </Button>
                <div className="flex  pt-36">
                  <Button onClick={() => selectWheel(wheel4)}>
                    เลขยาง4 : {wheel4?.wheelNumber}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 8) {
      const wheel1 = _.find(wheelData, { position: 1 });
      const wheel2 = _.find(wheelData, { position: 2 });
      const wheel3 = _.find(wheelData, { position: 3 });
      const wheel4 = _.find(wheelData, { position: 4 });
      const wheel5 = _.find(wheelData, { position: 5 });
      const wheel6 = _.find(wheelData, { position: 6 });
      const wheel7 = _.find(wheelData, { position: 7 });
      const wheel8 = _.find(wheelData, { position: 8 });
      console.log('wheel3', wheel3);
      return (
        <div className="p-8drop-shadow-xl">
          <Card>
            <h1 className="p-4 font-bold text-xl">ตำแหน่งยางหาง</h1>
            <div className="flex w-full justify-center">
              <div className="pt-12 ">
                <div className="flex justify-end pt-40">
                  <div className="flex pt-10 ">
                    <div className="fd pr-4">
                      <Button onClick={() => selectWheel(wheel1)}>
                        เลขยาง1 : {wheel1?.wheelNumber}
                      </Button>
                    </div>
                    <Button onClick={() => selectWheel(wheel2)}>
                      เลขยาง2 : {wheel2?.wheelNumber}{' '}
                    </Button>
                  </div>
                </div>
                <div className="flex justify-end pt-8">
                  <div className=" pr-4">
                    <Button onClick={() => selectWheel(wheel5)}>
                      เลขยาง5 : {wheel5?.wheelNumber}{' '}
                    </Button>
                  </div>
                  <Button onClick={() => selectWheel(wheel6)}>
                    เลขยาง6 : {wheel6?.wheelNumber}{' '}
                  </Button>
                </div>
              </div>
              <div className="mb-4">
                <img src={img_trailer}></img>
              </div>
              <div className="pt-12">
                <div className=" pt-40 ">
                  <div className="flex pt-10 ">
                    <div className=" pr-4">
                      <Button onClick={() => selectWheel(wheel3)}>
                        เลขยาง3 : {wheel3?.wheelNumber}
                      </Button>
                    </div>
                    <Button onClick={() => selectWheel(wheel4)}>
                      เลขยาง4 : {wheel4?.wheelNumber}{' '}
                    </Button>
                  </div>
                  <div className="flex  pt-8">
                    <div className=" pr-4">
                      <Button onClick={() => selectWheel(wheel7)}>
                        เลขยาง7 : {wheel7?.wheelNumber}{' '}
                      </Button>
                    </div>
                    <Button onClick={() => selectWheel(wheel8)}>
                      เลขยาง8 : {wheel8?.wheelNumber}{' '}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    if (wheel <= 12) {
      const wheel1 = _.find(wheelData, { position: 1 });
      const wheel2 = _.find(wheelData, { position: 2 });
      const wheel3 = _.find(wheelData, { position: 3 });
      const wheel4 = _.find(wheelData, { position: 4 });
      const wheel5 = _.find(wheelData, { position: 5 });
      const wheel6 = _.find(wheelData, { position: 6 });
      const wheel7 = _.find(wheelData, { position: 7 });
      const wheel8 = _.find(wheelData, { position: 8 });
      const wheel9 = _.find(wheelData, { position: 9 });
      const wheel10 = _.find(wheelData, { position: 10 });
      const wheel11 = _.find(wheelData, { position: 11 });
      const wheel12 = _.find(wheelData, { position: 12 });
      return (
        <div className="p-8 drop-shadow-xl">
          <Card>
            <div className="">
              <h1 className="p-4 font-bold ">ตำแหน่งยางหาง</h1>
              <div className="flex w-full justify-center">
                <div className="pt-12  ">
                  <div className="flex justify-end pt-14">
                    <div className=" pr-6 ">
                      <Button
                        onClick={() => selectWheel(wheel1)}
                        disabled={!wheel1?.wheelNumber}
                      >
                        เลขยาง1 : {wheel1?.wheelNumber}{' '}
                      </Button>
                    </div>
                    <Button
                      onClick={() => selectWheel(wheel2)}
                      disabled={!wheel2?.wheelNumber}
                    >
                      เลขยาง2:{wheel2?.wheelNumber}
                    </Button>
                  </div>
                  <div className="flex justify-end pt-4">
                    <div className=" pr-4">
                      <Button
                        onClick={() => selectWheel(wheel5)}
                        disabled={!wheel5?.wheelNumber}
                      >
                        เลขยาง5 : {wheel5?.wheelNumber}{' '}
                      </Button>
                    </div>
                    <Button
                      onClick={() => selectWheel(wheel6)}
                      disabled={!wheel6?.wheelNumber}
                    >
                      เลขยาง6 : {wheel6?.wheelNumber}{' '}
                    </Button>
                  </div>
                  <div className="flex justify-end pt-4">
                    <div className=" pr-4">
                      <Button
                        onClick={() => selectWheel(wheel9)}
                        disabled={!wheel9?.wheelNumber}
                      >
                        เลขยาง9 : {wheel9?.wheelNumber}{' '}
                      </Button>
                    </div>
                    <Button
                      onClick={() => selectWheel(wheel10)}
                      disabled={!wheel10?.wheelNumber}
                    >
                      เลขยาง10: {wheel10?.wheelNumber}{' '}
                    </Button>
                  </div>
                </div>
                <div className="mb-4">
                  <img src={img_trailer}></img>
                </div>
                <div className=" pt-12 ">
                  <div className="flex justify-end pt-14  pr-16">
                    <Button
                      onClick={() => selectWheel(wheel3)}
                      disabled={!wheel3?.wheelNumber}
                    >
                      เลขยาง3 : {wheel3?.wheelNumber}{' '}
                    </Button>
                    <Button
                      onClick={() => selectWheel(wheel4)}
                      disabled={!wheel4?.wheelNumber}
                    >
                      เลขยาง4:{wheel4?.wheelNumber}
                    </Button>
                  </div>
                  <div className="flex  pt-4">
                    <Button
                      onClick={() => selectWheel(wheel7)}
                      disabled={!wheel7?.wheelNumber}
                    >
                      เลขยาง7 : {wheel7?.wheelNumber}
                    </Button>
                    <Button
                      onClick={() => selectWheel(wheel8)}
                      disabled={!wheel8?.wheelNumber}
                    >
                      เลขยาง8 : {wheel8?.wheelNumber}
                    </Button>
                  </div>
                  <div className="flex pt-4">
                    <div className=" pr-4">
                      <Button
                        onClick={() => selectWheel(wheel11)}
                        disabled={!wheel11?.wheelNumber}
                      >
                        เลขยาง11 : {wheel11?.wheelNumber}
                      </Button>
                    </div>
                    <Button
                      onClick={() => selectWheel(wheel12)}
                      disabled={!wheel12?.wheelNumber}
                    >
                      เลขยาง12: {wheel12?.wheelNumber}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  };
  return (
    <div>
      <div>
        <div className="pt-2">
          {renderTrailerTailFromMaintenance(
            wheelTailCheck?.trailerTail?.type?.wheel,
            wheelTailCheck?.wheels,
          )}
        </div>
      </div>
    </div>
  );
}
