export const PROCESS_TXN_TYPE = {
  PROCESS_START: {
    status_code: 'PROCESS_START',
    description: 'เริ่มงาน',
    direction: true,
  },
  PROCESS_ACCEPT: {
    status_code: 'PROCESS_ACCEPT',
    description: 'รับงาน',
    direction: true,
  },
  PROCESS_APPROVE: {
    status_code: 'PROCESS_APPROVE',
    description: 'อนุมัติงาน',
    direction: true,
  },
  PROCESS_REJECT: {
    status_code: 'PROCESS_REJECT',
    description: 'ถอนตัว',
    direction: false,
  },
  PROCESS_FINISH: {
    status_code: 'PROCESS_FINISH',
    description: 'ปิดงานสำเร็จ',
    direction: true,
  },
  PROCESS_CANCLE: {
    status_code: 'PROCESS_CANCLE',
    description: 'ยกเลิกงาน',
    direction: true,
  },
  MATERIAL_WITHDRAW: {
    status_code: 'MATERIAL_WITHDRAW',
    description: 'เบิกวัสดุ',
    direction: true,
  },
  MATERIAL_RETURN: {
    status_code: 'MATERIAL_RETURN',
    description: 'คืนวัสดุ',
    direction: false,
  },
  PROCESS_OTHER: {
    status_code: 'PROCESS_OTHER',
    description: 'อื่นๆ',
    direction: false,
  },
};

export default PROCESS_TXN_TYPE;
