/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';
import currencyFormat from '../../utils/functions/currencyFormat';
import floatingCurrencyFormatter from '../../utils/functions/floatingCurrencyFormater';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

// Based from Asia Logistic
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

function printPDF(order, info, me) {
  const {
    setting: { cmms: cmmsSetting },
  } = info;

  const genMaterials = (processMaterials) =>
    _.map(processMaterials, (eachProcessMat, index) => [
      {
        text: `${index + 1}`,
        colSpan: 1,
      },
      {
        text: eachProcessMat?.material?.type_code || '-',
        colSpan: 1,
      },
      {
        text: eachProcessMat?.material?.name || '',
        colSpan: 1,
      },
      {
        text: floatingCurrencyFormatter.format(eachProcessMat?.amount),
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: eachProcessMat?.material?.unit || '',
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: '',
        colSpan: 1,
      },
      {
        text: '',
        colSpan: 1,
      },
    ]);

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 150, 40, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบแจ้งซ่อม',
    },
    header: [
      {
        text: `${dayjs().format('DD/MM/BBBB')}`,
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 0, 0, 12],
        text: [
          {
            text: `${info?.owner?.name}\n`,
            fontSize: '18',
            bold: true,
          },
          {
            text: `${info?.owner?.address?.house_number || ''} ${
              info?.owner?.address?.village_number
                ? `หมู่ที่${info?.owner?.address?.village_number}`
                : ''
            } ${info?.owner?.address?.road || ''} ${
              info?.owner?.address?.subdistrict || ''
            } ${info?.owner?.address?.district || ''} ${
              info?.owner?.address?.province || ''
            } ${info?.owner?.address?.postcode || ''}\n`,
            fontSize: '10',
          },
          {
            text: `โทร. ${info?.owner.telephone || '-'} แฟกซ์ ${
              info?.owner.fax || '-'
            }\n`,
            fontSize: '10',
          },
          {
            text: `อีเมล ${info?.owner?.email || '-'} เลขที่ผู้เสียภาษี ${
              info?.owner?.tax || '-'
            }\n`,
            fontSize: '10',
          },
        ],
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: 'ใบสั่งเบิกอะไหล่',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
      },
    ],
    // footer(currentPage, pageCount) {
    //   return [
    //     {
    //       text: `${currentPage.toString()} of ${pageCount}`,
    //       alignment: 'right',
    //       margin: [10, 10, 10, 10],
    //       fontSize: '10',
    //     },
    //   ];
    // },

    content: [
      {
        style: 'tableExample',
        margin: [10, 0, 10, 2],
        fontSize: 10,
        table: {
          widths: ['10%', '40%', '15%', '30%'],
          body: [
            [
              {
                text: 'วันที่',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(order?.start_date).format('D MMMM  BBBB')}`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: cmmsSetting?.attach_time ? 'เวลา' : '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: cmmsSetting?.attach_time
                  ? dayjs(order?.start_date)?.format('HH:mm')
                  : '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'หัวรถ',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${order?.vehicle?.type?.name || ''}  ${
                  order?.vehicle?.licence_number || '-'
                } `,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: 'หางรถ',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${order?.trailer_tail?.type?.name || ''} ${
                  order?.trailer_tail?.licence_number || '-'
                } `,
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เลขไมล์',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.mileage
                    ? `${currencyFormat.format(order?.mileage)} km`
                    : '-'
                }`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              cmmsSetting?.printed_maintenance_order?.show_garage
                ? {
                    text: 'อู่ซ่อม',
                    colSpan: 1,
                    bold: true,
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
              cmmsSetting?.printed_maintenance_order?.show_garage
                ? {
                    text: `${order?.garage?.name || 'อู่ภายใน'}`,
                    colSpan: 1,
                    border: [false, false, false, false],
                  }
                : {
                    text: '',
                    border: [false, false, false, false],
                  },
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        margin: [2, 20, 10, 2],
        layout: {
          fillColor(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        fontSize: 11,
        table: {
          widths: ['10%', '20%', '*', '10%', '10%', '12%', '12%'],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'รหัสอะไหล่',
                colSpan: 1,
              },
              {
                text: 'รายการอะไหล่',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'หน่วยนับ',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'ผู้มาเบิก',
                colSpan: 1,
                bold: true,
              },
              {
                text: 'หัวหน้าช่าง',
                colSpan: 1,
                bold: true,
              },
            ],
            ...genMaterials(order?.process_materials),
          ],
        },
      },
      {
        margin: [10, 20, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['20%', '*', '20%'],
          body: [
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ ................................................ พนักงานสต็อก',
                colSpan: 1,
                // bold: true,
                alignment: 'center',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
            ],
          ],
        },
      },
    ],
    footer: [
      {
        margin: [20, 0, 0, 20],
        fontSize: 12,

        columns: [],
      },
    ],
  };

  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
