import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';

export function ApprovalMaterialWithdrawForm({
  material,
  setSelectStockLot,
  materialWithdraw,
}) {
  const [reload, setReload] = useState(false);
  const selectMaterialWithdraw = _.map(
    materialWithdraw?.material,
    function (_material) {
      return {
        material: _material.material._id,
        lotId: '',
        quantity: 0,
        amount: 0,
      };
    },
  );
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      select_lot: selectMaterialWithdraw,
    },
  });
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'select_lot',
  });

  useEffect(() => {
    setSelectStockLot(getValues('select_lot'));
    return () => {};
  }, [reload, fields]);

  const showLot = (materialId) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.size(findLot?.stocklots) ? (
      _.map(findLot?.stocklots, (lot) => {
        return (
          <MenuItem key={lot._id} value={lot._id}>
            {lot?.place?.name}{' '}
            {lot?.lot_number ? `หมายเลขล็อต ${lot?.lot_number || ''} ` : 'ล็อต'}
            วันที่ผลิต{' '}
            {dayjs(lot?.production_date)
              .locale('th')
              .format('DD MMM BBBB')}{' '}
            คงเหลือ {lot?.amount} {findLot?.unit}
          </MenuItem>
        );
      })
    ) : (
      <MenuItem disabled value="สินค้าหมด" selected>
        <em>สินค้าหมด</em>
      </MenuItem>
    );
  };

  const calulateAmount = (index) => {
    const stockLots = _.find(material, function (_material) {
      return _material?._id === getValues(`select_lot.[${index}].material`);
    }).stocklots;
    fields[index].amount =
      parseInt(
        _.find(stockLots, function (_stockLots) {
          return _stockLots?._id === getValues(`select_lot.[${index}].lotId`);
        })?.amount,
        10,
      ) - parseInt(getValues(`select_lot.[${index}].quantity`), 10);
    setValue(`select_lot.[${index}].amount`, fields?.[index]?.amount);
  };

  const showAddButton = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <div></div>
    ) : (
      <div>
        <Button
          color={'secondary'}
          variant="contained"
          size={'small'}
          onClick={() =>
            insert(index, {
              material: getValues(`select_lot.[${index}].material`),
              lotId: '',
              quantity: 0,
              amount: 0,
            })
          }
        >
          เพิ่ม
        </Button>
      </div>
    );
  };

  const showSelectLot = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="text-center">
          <b className="text-red-500"> สินค้าหมด</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <Controller
          name={`select_lot.[${index}].lotId`}
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ล็อตวัสดุ
              </InputLabel>
              <Select
                {...field}
                label="ล็อตวัสดุ"
                size={'small'}
                // fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  calulateAmount(index);
                  setReload(!reload);
                }}
              >
                {showLot(getValues(`select_lot.[${index}].material`))}
              </Select>
            </FormControl>
          )}
        />{' '}
      </TableCell>
    );
  };

  const showSelectQuantity = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="text-center">
          <b className="text-red-500"> 0</b>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <Controller
          name={`select_lot.[${index}].quantity`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                calulateAmount(index);
                setReload(!reload);
              }}
              label="จำนวน"
              fullWidth
              inputProps={{
                min: 0,
                style: { textAlign: 'center' },
              }}
              size={'small'}
              helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </TableCell>
    );
  };

  const showDeleteButton = (materialId, index) => {
    const findLot = _.find(material, function (_material) {
      return _material._id === materialId;
    });
    return _.isEmpty(findLot?.stocklots) ? (
      <TableCell>
        {' '}
        <div className="flex">
          <div className="ml-2 mt-1">
            {showAddButton(getValues(`select_lot.[${index}].material`), index)}
          </div>
          <div>
            <Button color={'error'} variant="contained" size={'small'} disabled>
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    ) : (
      <TableCell>
        <div className="flex">
          <div className="mr-2">
            {showAddButton(getValues(`select_lot.[${index}].material`), index)}
          </div>
          <div>
            <Button
              color={'error'}
              variant="contained"
              size={'small'}
              onClick={() => remove(index)}
              disabled={
                _.filter(getValues('select_lot'), {
                  material: getValues(`select_lot.[${index}].material`),
                }).length === 1
              }
            >
              ลบ
            </Button>
          </div>
        </div>
      </TableCell>
    );
  };

  const showMaterial = (index) => {
    const findFromMaterial = _.find(material, function (_material) {
      return _material?._id === getValues(`select_lot.[${index}].material`);
    });

    const findFromMaterialWithdraw = _.find(
      materialWithdraw?.material,
      function (_material) {
        return (
          _material?.material?._id ===
          getValues(`select_lot.[${index}].material`)
        );
      },
    );
    return (
      <p>
        {findFromMaterial?.name} {findFromMaterialWithdraw?.quantity}{' '}
        {findFromMaterial?.unit}
      </p>
    );
  };

  const displaymaterial = () => (
    <TableContainer>
      <Table size="small" className="bmaterialWithdraw-lg">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> วัสดุ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ล็อตวัสดุ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fields.map((_field, index) => {
            return (
              <TableRow key={_field._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <div className="flex flex-col">
                    <div className="my-1">{showMaterial(index)}</div>
                  </div>
                </TableCell>
                {showSelectLot(
                  getValues(`select_lot.[${index}].material`),
                  index,
                )}
                {showSelectQuantity(
                  getValues(`select_lot.[${index}].material`),
                  index,
                )}
                {showDeleteButton(
                  getValues(`select_lot.[${index}].material`),
                  index,
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <div className="my-2">{displaymaterial()}</div>
    </div>
  );
}

ApprovalMaterialWithdrawForm.propTypes = {
  material: PropTypes.object,
  setSelectStockLot: PropTypes.func,
  materialWithdraw: PropTypes.object,
};

ApprovalMaterialWithdrawForm.defaultProps = {
  material: null,
  materialWithdraw: null,
};

export default ApprovalMaterialWithdrawForm;
