import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CardContent,
  Switch,
  FormControlLabel,
  Chip,
} from '@mui/material';
import { Check as CheckIcon, X as XIcon } from 'react-feather';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

export default function IndividualMaterialRequest({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialRequest = useSelector((state) => state.materialRequest);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [includeComplete, setIncludeComplete] = useState(false);

  const {
    setting: { cmms: cmmsSetting },
  } = information;

  const {
    userData: { role },
  } = me;

  const fetchMaterialRequest = () => {
    dispatch(
      actions.materialRequestAll({
        name,
        page,
        size,
        completed: includeComplete ? '' : false,
        deleted: includeComplete ? '' : false,
      }),
    );
  };

  useEffect(() => {
    fetchMaterialRequest();
    return () => {};
  }, [name, page, size, includeComplete]);

  useEffect(() => {
    setTotal(materialRequest?.total);
    return () => {};
  }, [materialRequest]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDeleteMaterialRequest = async (id) => {
    try {
      const confirm = window.confirm('ยีนยันการลบคำขอ');
      if (confirm) {
        await dispatch(actions.materialRequestDelete(id));
        alert('สำเร็จ');
        fetchMaterialRequest();
      }
    } catch (error) {
      alert(`ไม่สามารถลบรายการร้องขอวัสดุได้ ${error?.message}`);
    }
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2 justify-between">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/4">
          <FormControlLabel
            control={
              <Switch
                checked={includeComplete}
                onChange={(event) => setIncludeComplete(event.target.checked)}
              />
            }
            label="รวมรายการที่ผ่านไปแล้ว"
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เลขที่รายการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่ร้องขอ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วัสดุ / จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายการแจ้งบำรุงรักษา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้ร้องขอ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialRequest.rows) ? (
                materialRequest.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>

                    <TableCell>
                      {row?.running_number}
                      {row?.completed && (
                        <div>
                          <Chip
                            color="success"
                            size="small"
                            variant="filled"
                            icon={<CheckIcon size={14} />}
                            label="สำเร็จแล้ว"
                          />
                        </div>
                      )}
                      {row?.deleted && (
                        <div>
                          <Chip
                            color="error"
                            size="small"
                            variant="filled"
                            icon={<XIcon size={14} />}
                            label="ถูกปฏิเสธ"
                          />
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.createdAt).format('D MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      <ul>
                        {_.map(row?.material, (eachMaterial, materialIndex) => (
                          <li
                            key={`mat${index}-${materialIndex}`}
                            className="list-disc list-inside"
                          >
                            {eachMaterial?.material?.type_code || ''}{' '}
                            {eachMaterial?.material?.name || ''} จำนวน{' '}
                            {eachMaterial?.quantity}{' '}
                            {eachMaterial?.material?.unit || ''}
                          </li>
                        ))}{' '}
                      </ul>
                    </TableCell>
                    <TableCell>
                      {!_.isEmpty(row?.maintenance_order) ? (
                        <div>
                          {' '}
                          <div>
                            <b>{row?.maintenance_order?.running_number}</b>
                          </div>
                          <div>
                            หัวรถ:{' '}
                            {row?.maintenance_order?.vehicle?.licence_number ||
                              '-'}
                          </div>
                          <div>
                            หางรถ:{' '}
                            {row?.maintenance_order?.trailer_tail
                              ?.licence_number || '-'}
                          </div>
                          <div>
                            ตู้คอนเทนเนอร์:{' '}
                            {row?.maintenance_order?.container
                              ?.licence_number || '-'}
                          </div>
                        </div>
                      ) : (
                        <div>
                          เบิกวัสดุโดยตรง
                          <div>
                            หัวรถ: {row?.vehicle?.licence_number || '-'}
                          </div>
                          <div>
                            หางรถ: {row?.trailer_tail?.licence_number || '-'}
                          </div>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.requester?.firstname} {row?.requester?.lastname} (
                      {row?.requester?.nickname || '-'})
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        {/* <Link to={`/ims/material-approval/${row?._id}`}>
                          <Button
                            component={Button}
                            variant="contained"
                            color={'info'}
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>
                        </Link> */}
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          disabled={
                            row?.completed ||
                            row?.deleted ||
                            // Admin / Superuser ลบได้ของทุกอัน แต่ User ธรรมดาลบได้เฉพาะของตนเอง
                            (role?.level < 1 &&
                              row?.requester?._id !== me?.userData?._id)
                          }
                          onClick={() => handleDeleteMaterialRequest(row?._id)}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (!cmmsSetting?.must_approve_material_request) {
    return (
      <div>
        <div>
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
          </div>
          <div>
            <BackButton />
          </div>
          <Card className="my-2">
            <CardContent>
              <div className="my-2">
                ท่านไม่ได้เปิดใช้การขออนุญาติการเบิกวัสดุ
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  if (materialRequest.isLoading) {
    return <Loading />;
  }
  if (!materialRequest.isLoading && materialRequest.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="self-center">
            <Link to="/cmms/individual-material-request/create">
              <Button variant="contained">เพิ่ม</Button>
            </Link>
          </div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={materialRequest?.message} />;
}

IndividualMaterialRequest.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

IndividualMaterialRequest.defaultProps = {
  title: '',
  subtitle: '',
};
