/* eslint-disable react/prop-types */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Card,
  Paper,
  TablePagination,
  TableRow,
  Button,
  Chip,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function WheelListTable({
  wheel,
  handleDelete = () => {},
  handleEdit = () => {},
  handleInfo = () => {},
  page,
  size,
  setPage,
  setSize,
}) {
  const options = ['ตรวจสอบ', 'แก้ไข', 'ลบ'];

  console.log(wheel);

  const RenderMultiButton = ({ index, id }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef();
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
      if (options?.[selectedIndex] === 'แก้ไข') {
        handleEdit(id);
      }
      if (options?.[selectedIndex] === 'ลบ') {
        handleDelete(id);
      }
      if (options?.[selectedIndex] === 'ตรวจสอบ') {
        handleInfo(id);
        // console.info(`You clicked ${options[selectedIndex]}`);
      }
    };

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef?.current && anchorRef?.current?.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <div key={index}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={handleClick}>{options?.[selectedIndex]}</Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        // disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div>
      <div>
        <Card>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <colgroup>
                <col width="8%" />
                <col width="10%" />
                <col width="15%" />
                <col width="15%" />
                <col width="10%" />
                <col width="17%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {' '}
                    <h1 className="font-bold">ลำดับ </h1>
                  </TableCell>
                  <TableCell align="center">
                    {' '}
                    <h1 className="font-bold">รหัสยาง </h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ยี่ห้อ</h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ประเภทยาง</h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ชนิดยาง</h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">สถานะ</h1>
                  </TableCell>
                  <TableCell align="center">
                    <h1 className="font-bold">ดำเนินการ</h1>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wheel?.total === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      ไม่มียาง
                    </TableCell>{' '}
                  </TableRow>
                ) : (
                  wheel?.rows?.map((each, index) => (
                    <TableRow key={each?._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{each?.wheelNumber}</TableCell>
                      <TableCell align="center">
                        {each?.supply?.brand}
                      </TableCell>
                      <TableCell align="center">
                        {each?.supply?.type || ''}
                      </TableCell>
                      <TableCell align="center">
                        {each?.statusUseable ? (
                          <Chip label="เก่า"></Chip>
                        ) : (
                          <Chip label="ใหม่"></Chip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          {each?.statusWheel === 'ready' && (
                            <Chip label="พร้อมใช้งาน" color="success"></Chip>
                          )}
                          {each?.statusWheel === 'usable' && (
                            <Chip label="กำลังใช้งาน" color="warning"></Chip>
                          )}
                          {each?.statusWheel === 'old' && (
                            <Chip label="หมดสภาพ" color="error"></Chip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          <RenderMultiButton index={index} id={each?._id} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={wheel?.total}
            rowsPerPage={size}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </div>
  );
}
