import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import {
  DeliveryOrder,
  CreateDeliveryOrder,
  VehicleSchedule,
  VehicleScheduleDashboard,
  CreateVehicleSchedule,
  EditVehicleSchedule,
  DetailVehicleSchedule,
  EditDeliveryOrder,
  DeliveryOrderHistory,
  VehicleScheduleHistory,
} from '../views/TMS';

export function TMS() {
  const module = 'TMS';
  const prefix = '/tms';
  const name = 'จัดการการขนส่ง';

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : TMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());

    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/vehicle-schedule-dashboard`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/delivery-order`}>
                <DeliveryOrder title="คำสั่งจัดส่งสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/create-delivery-order`}>
                <CreateDeliveryOrder
                  title="สร้างคำสั่งจัดส่ง"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/delivery-order/edit/:id`}>
                <EditDeliveryOrder title="แก้ไขคำสั่งจัดส่ง" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/delivery-order-history`}>
                <DeliveryOrderHistory
                  title="ประวัติคำสั่งจัดส่ง"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/vehicle-schedule`}>
                <VehicleSchedule title="รายการเดินรถ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/vehicle-schedule/create`}>
                <CreateVehicleSchedule
                  title="สร้างรายการเดินรถ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/vehicle-schedule/edit/:id`}>
                <EditVehicleSchedule
                  title="แก้ไขรายการเดินรถ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/vehicle-schedule/detail/:id`}>
                <DetailVehicleSchedule
                  title="รายละเอียดรายการเดินรถ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/vehicle-schedule-history`}>
                <VehicleScheduleHistory
                  title="ประวัติรายการเดินรถ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/vehicle-schedule-dashboard`}>
                <VehicleScheduleDashboard title="แดชบอร์ด" subtitle={name} />
              </Route>

              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default TMS;
