import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import {
  Truck as TruckIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Sliders as SlidersIcon,
  User as UserIcon,
  Package as PackageIcon,
  File as FileIcon,
  Aperture,
  DollarSign,
  Trello,
  Tool,
} from 'react-feather';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SettingsIcon from '@mui/icons-material/Settings';
import NavItem from './NavItem';
import { useDispatch } from 'react-redux';
import { PassportAuth } from '../../contexts/AuthContext';
import { getStorage } from '../../utils/functions/localstorage';
import accessRight from '../../utils/functions/accessRight';
import * as action from '../../redux/actions';
import { useSelector } from 'react-redux';

const items = [
  {
    href: '/dashboard',
    icon: LeaderboardIcon,
    title: 'แดชบอร์ด',
    name: 'DASHBOARD',
    sub: [
      {
        href: '/dashboard/maintenance-order/vehicle',
        title: 'การบำรุงรักษารายเครื่อง',
        level: 0,
      },
      {
        href: '/dashboard/maintenance-historys',
        title: 'การบำรุงรักษารายเดือน',
        level: 0,
      },
      {
        href: '/dashboard/maintenance-dashboard',
        title: 'การบำรุงรักษารายปี',
      },
    ],
  },
  {
    href: '/cmms',
    icon: SettingsIcon,
    title: 'จัดการการแจ้งบำรุงรักษา',
    name: 'CMMS',
    sub: [
      {
        href: '/cmms/maintenance-orders',
        title: 'รายการแจ้งบำรุงรักษา',
        level: 0,
      },
      // {
      //   href: '/cmms/maintenance-dashboard',
      //   title: 'แดชบอร์ดการบำรุงรักษา',
      //   level: 0,
      // },

      {
        href: '/cmms/individual-material-request',
        title: 'การขอเบิกวัสดุโดยตรง',
        level: 0,
      },
      // {
      //   href: '/cmms/maintenance-notify',
      //   title: 'แจ้งเตือนการซ่อม',
      //   level: 0,
      // },
      {
        href: '/cmms/maintenance-types',
        title: 'ตั้งค่าการซ่อมบำรุง',
        level: 1,
        name: 'CMMS',
        sub: [
          {
            href: '/cmms/maintenance-types',
            title: 'ประเภทการบำรุงรักษา',
            level: 1,
          },
          {
            href: '/cmms/maintenance-topics',
            title: 'หัวข้อการบำรุงรักษา',
            level: 1,
          },
          {
            href: '/cmms/garages',
            title: 'ศูนย์ซ่อมภายนอก',
            level: 1,
          },
        ],
      },
    ],
  },
  {
    href: '/wms',
    icon: Aperture,
    title: 'จัดการยาง',
    name: 'WMS',
    sub: [
      {
        href: '/wms',
        title: 'รายการแจ้งซ่อมยาง',
        level: 0,
      },
      {
        href: '/wms/store',
        title: 'คลังยาง',
        level: 0,
      },
      {
        href: '/wms/register',
        title: 'จัดการหัวรถ',
        level: 0,
      },
      {
        href: '/wms/trailer',
        title: 'จัดการหางรถ',
        level: 0,
      },
      {
        href: '/wms/history',
        title: 'ประวัติการซ่อมยาง',
        level: 0,
      },

      {
        href: '/wms/history',
        title: 'ตั้งค่าการซ่อมบำรุง',
        level: 1,
        name: 'CMMS',
        sub: [
          {
            href: '/wms/wheel-supply',
            title: 'จัดการยี่ห้อยาง',
            level: 1,
          },
        ],
      },
    ],
  },
  {
    href: '/fms',
    icon: PrecisionManufacturingIcon,
    title: 'จัดการข้อมูลเครื่องจักร',
    name: 'FMS',
    sub: [
      {
        href: '/fms/vehicle',
        title: 'จัดการเครื่องจักร',
        level: 1,
        name: 'FMS',
        sub: [
          {
            href: '/fms/vehicle',
            title: 'รายการเครื่องจักร',
            level: 1,
          },
          {
            href: '/fms/vehicle-types',
            title: 'จัดการประเภทเครื่องจักร',
            level: 1,
          },
        ],
      },
      // {
      //   href: '/fms/trailer-tail',
      //   title: 'จัดการหางรถ',
      //   level: 1,
      //   name: 'FMS',
      //   sub: [
      //     {
      //       href: '/fms/trailer-tail',
      //       title: 'รายการหางรถ',
      //       level: 1,
      //     },
      //     {
      //       href: '/fms/trailer-tail-types',
      //       title: 'จัดการประเภทหางรถ',
      //       level: 1,
      //     },
      //   ],
      // },
      // {
      //   href: '/fms/container',
      //   title: 'จัดการตู้คอนเทนเนอร์',
      //   level: 1,
      //   name: 'FMS',
      //   sub: [
      //     {
      //       href: '/fms/container',
      //       title: 'รายการตู้คอนเทนเนอร์',
      //       level: 1,
      //     },
      //   ],
      // },
    ],
  },
  {
    href: '/mech',
    icon: Tool,
    title: 'จัดการเครื่องจักร',
    name: 'MECH',
    sub: [
      {
        href: '/mech/mech',
        title: 'รายการเครื่องจักร',
        level: 1,
        name: 'MECH',
      },
    ],
  },
  {
    href: '/ims',
    icon: PackageIcon,
    title: 'คลังวัสดุ',
    name: 'IMS',
    sub: [
      {
        href: '/ims/material-stock',
        title: 'จัดการสต็อกวัสดุ',
        level: 0,
        name: 'IMS',
      },
      {
        href: '/ims/materials',
        title: 'จัดการข้อมูลวัสดุ',
        level: 0,
        name: 'IMS',
      },
      {
        href: '/ims/material-request',
        title: 'คำขอเบิกวัสดุ',
        level: 0,
        name: 'IMS',
      },
      {
        href: '/ims/material-stock-fill',
        title: 'เติมสต็อกวัสดุ',
        level: 0,
        name: 'IMS',
      },

      {
        href: '/ims/history',
        title: 'ประวัติการจัดการ',
        level: 1,
        name: 'IMS',
        sub: [
          {
            href: '/ims/history',
            title: 'ประวัติการจัดการวัสดุ',
            level: 0,
            name: 'IMS',
          },
          {
            href: '/ims/material-stock-lot',
            title: 'รายการล็อตวัสดุ',
            name: 'IMS',
            level: 0,
          },
        ],
      },
      {
        href: '/ims/place',
        title: 'การตั้งค่าคลังวัสดุ',
        level: 1,
        name: 'IMS',
        sub: [
          {
            href: '/ims/place',
            title: 'จัดการคลังวัสดุ',
            level: 1,
          },
          {
            href: '/ims/material-type',
            title: 'จัดการประเภทวัสดุ',
            level: 1,
          },
          {
            href: '/ims/material-transaction-type',
            title: 'จัดการประเภทการดำเนินการ',
            level: 0,
          },
        ],
      },
    ],
  },
  {
    href: '/tms',
    icon: TruckIcon,
    title: 'จัดการการขนส่ง',
    name: 'TMS',
    sub: [
      {
        href: '/tms/vehicle-schedule-dashboard',
        title: 'แดชบอร์ด',
        level: 0,
      },
      {
        href: '/tms/delivery-order',
        title: 'คำสั่งจัดส่งสินค้า',
        level: 0,
        name: 'TMS',
        sub: [
          {
            href: '/tms/delivery-order',
            title: 'รายการคำสั่งจัดส่งสินค้า',
            level: 0,
          },
          {
            href: '/tms/delivery-order-history',
            title: 'ประวัติคำสั่งจัดส่งสินค้า',
            level: 0,
          },
        ],
      },
      {
        href: '/tms/vehicle-schedule',
        title: 'รายการเดินรถ',
        level: 0,
        name: 'TMS',
        sub: [
          {
            href: '/tms/vehicle-schedule',
            title: 'รายการเดินรถ',
            level: 0,
          },
          {
            href: '/tms/vehicle-schedule-history',
            title: 'ประวัติรายการเดินรถ',
            level: 0,
          },
        ],
      },
    ],
  },
  {
    href: '/jms',
    icon: FileIcon,
    title: 'จัดการรายการงาน',
    name: 'JMS',
    sub: [
      {
        href: '/jms/job',
        title: 'รายการงาน',
        level: 0, // TODO: Maybe add to higher permission
        name: 'JMS',
      },
      {
        href: '/jms/pricing',
        title: 'โซนราคา',
        level: 0, // TODO: Maybe add to higher permission
        name: 'JMS',
      },
    ],
  },
  {
    href: '/bms',
    icon: DollarSign,
    title: 'จัดการบุ๊คกิ้ง',
    name: 'BMS',
    sub: [
      {
        href: '/bms/dashboard',
        title: 'แดชบอร์ด',
        level: 0,
        name: 'BMS',
      },
      {
        href: '/bms/bill-booking',
        title: 'Booking งาน',
        level: 0,
        name: 'BMS',
      },
      {
        href: '/bms/bill-summary',
        title: 'สรุปงาน',
        level: 0,
        name: 'BMS',
      },

      {
        href: '/bms/bill-booking/report',
        title: 'Booking งานรายเดือน',
        level: 0,
        name: 'BMS',
      },
    ],
  },
  {
    href: '/bmms',
    icon: Trello,
    title: 'จัดการบิล',
    name: 'BMMS',
    sub: [
      {
        href: '/bmms/bill',
        title: 'บิล',
        level: 0,
        name: 'BMMS',
      },
      {
        href: '/bmms/bill-header',
        title: 'จัดการหัวบิล',
        level: 0,
        name: 'BMMS',
      },
    ],
  },
  {
    href: '/crm',
    icon: UsersIcon,
    title: 'ลูกค้าสัมพันธ์',
    name: 'CRM',
    sub: [
      {
        href: '/crm/customers',
        title: 'รายการลูกค้า',
        level: 0,
      },
      {
        href: '/crm/customer-types',
        title: 'ประเภทลูกค้า',
        level: 1,
      },
    ],
  },
  {
    href: '/hrms',
    icon: UserPlusIcon,
    title: 'ผู้ใช้ระบบ',
    name: 'HRMS',
    sub: [
      {
        href: '/hrms/employee',
        title: 'จัดการพนักงาน',
        level: 0,
      },
      // {
      //   href: '/hrms/driver',
      //   title: 'จัดการคนขับ',
      //   level: 0,
      // },
      {
        href: '/hrms/department',
        title: 'จัดการแผนก',
        level: 1,
      },
      {
        href: '/hrms/role-types',
        title: 'จัดการบทบาท',
        level: 1,
      },
      {
        href: '/hrms/user',
        title: 'จัดการผู้ใช้',
        level: 0,
      },
    ],
  },
  {
    href: '/information',
    icon: SlidersIcon,
    title: 'ข้อมูลระบบ',
    name: 'INFORMATION',
    sub: [
      {
        href: '/information',
        title: 'จัดการข้อมูลระบบ',
        level: 0,
      },
      {
        href: '/information/setting-ims',
        title: 'ตั้งค่าคลังวัสดุ',
        level: 1,
      },
      {
        href: '/information/setting-cmms',
        title: 'ตั้งค่าการแจ้งซ่อม',
        level: 1,
      },
      {
        href: '/information/quick-menu',
        title: 'ตั้งค่าเมนูด่วน',
        level: 1,
      },
    ],
  },
];

const DashboardSidebar = ({ me, onMobileClose, openMobile }) => {
  const {
    firstname = '',
    lastname = '',
    department: { name: departmentName = '' } = {},
  } = JSON.parse(getStorage('remember'));

  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  const user = {
    jobTitle: departmentName,
    name: `${firstname} ${lastname}`,
  };
  const handleRemoveNotify = async () => {
    await dispatch(
      // eslint-disable-next-line no-underscore-dangle
      action.employeePut(me?.userData?._id, { line_token: null }),
    );
    await dispatch(action.meGet());
  };

  const renderNofifyButton = () => {
    const lineNotifyAccessLink = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${information?.client_id}&redirect_uri=${information?.redirect_uri}&scope=notify&state=${me?.userData?._id}`;

    if (me?.userData?.line_token && me?.userData?.line_token !== '') {
      return (
        <div className="flex  gap-2">
          <a href={lineNotifyAccessLink}>
            <Button variant="contained" color="warning">
              เปลี่ยนบัญชี
            </Button>
          </a>{' '}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveNotify()}
          >
            ยกเลิก
          </Button>
        </div>
      );
    }
    return (
      <a href={lineNotifyAccessLink}>
        <Button variant="contained" color="success">
          รับการแจ้งเตือนผ่าน LINE
        </Button>
      </a>
    );
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box className="p-6">
        <div className="p-2 rounded-xl flex flex-wrap justify-center  bg-gray-50">
          <div className="lg:w-1/3 flex justify-center ">
            <div className="bg-gray-300 rounded-full p-1">
              <UserIcon size={40} color={'grey'} />
            </div>
          </div>
          <div className="w-full lg:w-2/3 text-center">
            <Typography color="textPrimary" variant="body2">
              {user.name}
            </Typography>
            <Typography color="palevioletred" variant="body2">
              {user.jobTitle}
            </Typography>
          </div>
        </div>
        {/* <div className="pl-2 py-2">{renderNofifyButton()}</div> */}
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (accessRight(me, item.name)) {
              return (
                <div key={item.title}>
                  <NavItem
                    href={item.href}
                    title={item.title}
                    icon={item.icon}
                    sub={item.sub}
                    me={me}
                    moduleName={item.name}
                  />
                </div>
              );
            }
            return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-fingerprint"></i>}
          variant="outlined"
          onClick={() => {
            onSignOut();
          }}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              zIndex: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  me: PropTypes.object,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
