import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Jobs,
  EditJob,
  DetailJob,
  JobPricing,
  CreatePricing,
  EditPricing,
  DetailPricing,
  CreateJob,
  Pricings,
} from '../views/JMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function JMS() {
  const module = 'JMS';
  const prefix = '/jms';
  const name = 'จัดการรายการงาน';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const { pathname } = useLocation();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : JMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/job`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/job`}>
                <Jobs title={'รายการงาน'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/job/create/`}>
                <CreateJob title={'สร้างงาน'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/job/edit/:id`}>
                <EditJob title={'แก้ไขงาน'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/job/view/:id`}>
                <DetailJob title={'รายละเอียดงาน'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/pricing`}>
                <Pricings title={'โซนราคา'} subtitle={name} />
              </Route>

              <Route exact path={`${prefix}/pricing/create`}>
                <CreatePricing title={'สร้างโซนราคา'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/pricing/edit/:id`}>
                <EditPricing title={'แก้ไขโซนราคา'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/pricing/view/:id`}>
                <DetailPricing title={'รายละเอียดโซนราคา'} subtitle={name} />
              </Route>
              {/* {accessRight(me, module, 1) && <Switch></Switch>} */}

              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default JMS;
