import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { EditMaterialStockLotForm } from '../../components/Forms';
import { CONFIG } from '../../utils/constants';

export default function EditMaterialStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const customer = useSelector((state) => state.customer);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit } = useForm({
    defaultValues: materialStockLot,
  });

  useEffect(() => {
    dispatch(actions.materialStockLotGet(params.id));
    dispatch(
      actions.placeAll({ name: '', page: 1, size: CONFIG.infiniteLimit }),
    );
    dispatch(
      actions.customerAll({ name: '', page: 1, size: CONFIG.infiniteLimit }),
    );
    return () => {};
  }, [params]);

  const onEditMaterialStocklot = async (data) => {
    try {
      await dispatch(actions.materialStockLotPut(params.id, data));
      alert('สำเร็จ');
      history.goBack();
    } catch (err) {
      console.error('Error on Edit', err);
      alert(`ไม่สามารถแก้ไขล็อตได้ ${err?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialStockLot?.isLoading || materialStockLot?.rows) {
    return <Loading />;
  }
  if (
    !materialStockLot?.isLoading &&
    materialStockLot?.isCompleted &&
    !materialStockLot?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div className="flex flex-row justify-start pb-4">
            <div>
              <BackButton />
            </div>
          </div>
        </div>
        <div>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onEditMaterialStocklot)}>
                <EditMaterialStockLotForm
                  control={control}
                  materialStockLot={materialStockLot}
                  place={place}
                  information={information}
                  customer={customer}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก{' '}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error message={materialStockLot?.message} />;
}

EditMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
