import { Button, TextField } from '@mui/material';
import React from 'react';
import { dayjs } from '../../../utils/functions';
import { DatePicker } from '../../Datepicker';

function MechForm({ control, Controller, errors }) {
  return (
    <div className="p-2">
      <div className=" lg:flex flex-wrap">
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'name'}
            control={control}
            // defaultValue={vehicle?.attribute?.invoice_number}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อเครื่องจักร"
                fullWidth
                size={'small'}
                // helperText={errors.attribute?.invoice_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'licence_number'}
            control={control}
            // defaultValue={vehicle?.attribute?.invoice_number}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขทะเบียน"
                fullWidth
                size={'small'}
                // helperText={errors.attribute?.invoice_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'attribute.brand'}
            control={control}
            // defaultValue={vehicle?.attribute?.invoice_number}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ยี่ห้อ"
                fullWidth
                size={'small'}
                // helperText={errors.attribute?.invoice_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'attribute.generation'}
            control={control}
            // defaultValue={vehicle?.attribute?.invoice_number}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รุ่น"
                fullWidth
                size={'small'}
                // helperText={errors.attribute?.invoice_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'attribute.installation_date'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <DatePicker
                {...field}
                setValue={field.onChange}
                label="วันที่ติดตั้ง"
                value={field?.value}
              />
            )}
          />
        </div>
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'attribute.installation_company'}
            control={control}
            // defaultValue={vehicle?.attribute?.invoice_number}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ผู้ติดตั้ง"
                fullWidth
                size={'small'}
                // helperText={errors.attribute?.invoice_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'attribute.insurance_start'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <DatePicker
                {...field}
                setValue={field.onChange}
                label="วันที่เริ่มประกัน"
                value={field?.value}
              />
            )}
          />
        </div>
        <div className="p-2 lg:w-1/2 ">
          <Controller
            name={'attribute.insurance_end'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <DatePicker
                {...field}
                setValue={field.onChange}
                label="วันที่สิ้นสุดประกัน"
                value={field?.value}
              />
            )}
          />
        </div>
      </div>
      <div className="flex justify-end p-2 ">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}

export default MechForm;
