import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export function SettingIMSForm({ control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2 "> การแสดงข้อมูลในการเบิก/คืน วัสดุ</div>
      <div className="w-1/2 py-2 px-2 ">ส่วนประกอบของข้อมูลล็อตวัสดุ</div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="setting.ims.display.purchase_date"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="วันที่ซื้อวัสดุ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.ims.display.lot_number"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="หมายเลขล็อต"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.ims.display.cost"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ราคาวัสดุ"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full px-1 py-2">การแสดงข้อมูลวัสดุ</div>
      <div className="w-1/2 py-2 px-2">ส่วนประกอบของข้อมูลวัสดุ</div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="setting.ims.attribute.type_code"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="รหัสวัสดุ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.ims.attribute.register_number"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="เลขทะเบียนวัสดุ"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

SettingIMSForm.propTypes = {
  control: PropTypes.object,
};

export default SettingIMSForm;
