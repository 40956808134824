/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { Topic } from '@mui/icons-material';
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';
import currencyFormatter from '../../utils/functions/currencyFormat';
import floatingCurrencyFormatter from '../../utils/functions/floatingCurrencyFormater';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

// For Suphasin Logistic
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};
const renderDetail = ({ order, topic }) => {
  console.log('data', topic);
  console.log('order', order?.maintenance_topic);
  const data = _.filter(
    order?.maintenance_topic,
    (e) => e?.topic?.name === topic,
  );
  console.log('dataaa', data);
  return _.map(data, (e) => ` - ${e.detail}\n`);
};

function printPDF(order, info, me, maintenanceTopic) {
  console.log('maintenanceTopic', maintenanceTopic);
  const {
    setting: { cmms: cmmsSetting },
  } = info;

  const genMaintenanceTopic = () => {
    return {
      style: 'tableExample',
      fontSize: 11,
      margin: [0, -2, 0, 0],
      table: {
        widths: ['50%', '50%'],
        heights: 60.25,
        body: [
          [
            {
              text: `ตรวจเช็คทั่วไป \n ${renderDetail({
                order,
                topic: 'ตรวจเช็คทั่วไป',
              })} `,
              colSpan: 1,
              border: [true, true, true, true],
            },
            {
              text: `ระบบเกียร์ \n${renderDetail({
                order,
                topic: 'ระบบเกียร์',
              })}`,
              colSpan: 1,
              border: [true, true, true, true],
            },
          ],
          [
            {
              text: `เช็คระบบเครื่องยนต์ \n${renderDetail({
                order,
                topic: 'เช็คระบบเครื่องยนต์',
              })}`,
              colSpan: 1,
              border: [true, true, true, true],
            },
            {
              text: `ระบบไฟฟ้า\n${renderDetail({
                order,
                topic: 'ระบบไฟฟ้า',
              })}`,
              border: [true, true, true, true],
              colSpan: 1,
            },
          ],
          [
            {
              text: `ระบบครัช\n${renderDetail({
                order,
                topic: 'ระบบครัช',
              })}`,
              colSpan: 1,
              border: [true, true, true, true],
            },
            {
              text: `ระบบเพลา\n${renderDetail({
                order,
                topic: 'ระบบเพลา',
              })}`,
              border: [true, true, true, true],
              colSpan: 1,
            },
          ],
          [
            {
              text: `ระบบแอร์\n${renderDetail({
                order,
                topic: 'ระบบแอร์',
              })}`,
              colSpan: 1,

              border: [true, false, true, true],
            },
            {
              text: `ระบบยาง\n${renderDetail({
                order,
                topic: 'ระบบยาง',
              })}`,
              border: [true, false, true, true],
              colSpan: 1,
            },
          ],
        ],
      },
    };
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 336, 40, 240],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบแจ้งซ่อม/ใบรับรถ',
    },
    header: [
      {
        text: `${dayjs().format('DD/MM/BBBB')}`,
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 0, 0, 12],
        text: [
          {
            text: `${info?.owner?.name}\n`,
            fontSize: '18',
            bold: true,
          },
          {
            text: `${info?.owner?.address?.house_number || ''} ${
              info?.owner?.address?.village_number
                ? `หมู่ที่${info?.owner?.address?.village_number}`
                : ''
            } ${info?.owner?.address?.road || ''} ${
              info?.owner?.address?.subdistrict || ''
            } ${info?.owner?.address?.district || ''} ${
              info?.owner?.address?.province || ''
            } ${info?.owner?.address?.postcode || ''}\n`,
            fontSize: '10',
          },
          {
            text: `โทร. ${info?.owner.telephone || '-'} แฟกซ์ ${
              info?.owner.fax || '-'
            }\n`,
            fontSize: '10',
          },
          {
            text: `อีเมล ${info?.owner?.email || '-'} เลขที่ผู้เสียภาษี ${
              info?.owner?.tax || '-'
            }\n`,
            fontSize: '10',
          },
        ],
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: 'ใบแจ้งซ่อม/ใบรับรถ',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
      },
      {
        style: 'tableExample',
        margin: [25, 10, 10, 2],
        fontSize: 10,
        table: {
          widths: ['18%', '32%', '10%', '18%', '22%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `วันที่  ${dayjs(order?.start_date).format(
                  'D MMMM  BBBB',
                )}`,
                colSpan: 3,
                margin: [0, 0, 10, 0],
                border: [false, false, false, false],
              },
              {},
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '☐ สถานที่ซ่อมบำรุงประเภท',
                colSpan: 3,
                bold: true,
                border: [false, false, false, false],
              },
              {},
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `${order?.is_self_maintenance ? '☐' : '/'} อู่นอก`,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `${order?.is_self_maintenance ? '/' : '☐'} อู่ใน`,
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ชื่อ (บริษัท / ห้าง/ ร้าน)',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `       ${info?.name || '                  '}       `,
                colSpan: 1,
                decoration: 'underline',
                decorationStyle: 'dotted',
                border: [false, false, false, false],
              },
              {
                text: '☐ เงื่อนไขการชำระเงิน',
                colSpan: 3,
                bold: true,
                border: [false, false, false, false],
              },
              {},
              {},
            ],
            [
              {
                text: 'โปรดตรวจเช็คและดำเนินการซ่อมรถให้กับผู้ถือตั๋วฉบับนี้ตามรายการดังต่อไปนี้',
                colSpan: 2,
                bold: true,
                border: [false, false, false, false],
              },
              {},
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '☐ เงินสด',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '☐ เงินเชื่อ ................',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ทะเบียนรถ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.vehicle?.licence_number || '..................'
                }  โทร ${order?.driver?.employee?.tel}`,
                colSpan: 1,
                border: [false, false, false, false],
                decoration: 'underline',
                decorationStyle: 'dotted',
              },
              {
                text: '☐ แจ้งซ่อมระหว่างทาง ',
                colSpan: 3,
                border: [false, false, false, false],
                bold: true,
              },
              {},
              {},
            ],

            [
              {
                text: 'หมายเลขกิโลเมตร',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: order?.mileage || '........................',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '☐ อื่นๆ .....................................',
                colSpan: 3,
                border: [false, false, false, false],
                bold: true,
              },
              {},
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: 'วันกำหนดรับรถ .....................................',
                colSpan: 3,
                border: [false, false, false, false],
              },
              {},
              {},
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        margin: [20, 5, 40, 0],
        layout: {
          fillColor(rowIndex, node, columnIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        fontSize: 11,
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'รายการ',
                colSpan: 2,
                bold: true,
                alignment: 'center',
              },
              {},
            ],
          ],
        },
      },
    ],
    // footer(currentPage, pageCount) {
    //   return [
    //     {
    //       text: `${currentPage.toString()} of ${pageCount}`,
    //       alignment: 'right',
    //       margin: [10, 10, 10, 10],
    //       fontSize: '10',
    //     },
    //   ];
    // },

    content: [genMaintenanceTopic()],
    footer: [
      {
        style: 'tableExample',
        margin: [20, -7, 40, 0],
        fontSize: 11,

        table: {
          widths: ['100%'],
          heights: [10, 40],
          body: [
            [
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                bold: true,
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: order?.cause || '',
                colSpan: 1,
              },
            ],
          ],
        },
      },
      {
        margin: [20, 20, 5, 10],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['20%', '20%', '20%', '20%', '20%'],
          body: [
            [
              {
                text: 'ผู้แจ้ง (พ.ข.ร.)',
                colSpan: 1,
                border: [true, true, true, false],
              },
              {
                text: 'ผู้จัดทำเอกสาร (อู่ซ่อม)',
                colSpan: 1,
                border: [true, true, true, false],
              },
              {
                text: 'ช่างซ่อม',
                colSpan: 1,
                border: [true, true, true, false],
              },
              {
                text: 'ผู้ตรวจสอบ',
                colSpan: 1,
                border: [true, true, true, false],
              },
              {
                text: 'ผู้อนุมัติ',
                colSpan: 1,
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: 'ชื่อ .............................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: 'ชื่อ .............................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: 'ชื่อ .............................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: 'ชื่อ .............................',
                colSpan: 1,
                border: [true, false, true, false],
              },
              {
                text: 'ชื่อ .............................',
                colSpan: 1,
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: ` วันที่ ${dayjs(order?.start_date).format(
                  ' D / M / BB',
                )}`,
                colSpan: 1,
                border: [true, false, true, true],
              },
              {
                text: ` วันที่ ${dayjs(order?.createdAt).format(
                  ' D / M / BB',
                )}`,
                colSpan: 1,
                border: [true, false, true, true],
              },
              {
                text: 'วันที่ ...../....../.........',
                colSpan: 1,
                border: [true, false, true, true],
              },
              {
                text: 'วันที่ ...../....../.........',
                colSpan: 1,
                border: [true, false, true, true],
              },
              {
                text: 'วันที่ ...../....../.........',
                colSpan: 1,
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 0, 10, 30],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['10%', '*'],
          body: [
            [
              {
                text: 'เพื่อประโยชน์ของท่านโปรดแนบเอกสารฉบับรี้พร้อมกับเอกสารการวางบิลของท่านเพื่อสะดวกในการตรวจสอบการชำระเงิน',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '   ระบุรายการในใบแจ้งซ่อมที่นอกเหนือจากรายการดังกล่าวข้างต้น',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: 'ใบแจ้งซ่อมใช้ได้ภายใน 7 วัน นับตั้งแต่วันแจ้งซ่อม',
                bold: true,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
    ],
  };

  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
