import React, { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  Button,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createFilterOptions,
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import GarageForm from './GarageForm';

const OuterServicesForm = ({ control, handleCreateGarage, garage }) => {
  // Outer Services Form Array
  const {
    fields: outerServiceField,
    append: outerServiceAppend,
    remove: outerServiceRemove,
  } = useFieldArray({ control, name: 'outer_services' });

  const [dialogTopic, setDialogTopic] = useState('');
  const [openAddGarage, setOpenAddGarage] = useState(false);
  const filter = createFilterOptions();
  const {
    control: garageControl,
    formState: { errors: garageError },
    handleSubmit: handleGarageSubmit,
  } = useForm();

  const renderAddGarageDialog = () => (
    <Dialog onClose={() => setOpenAddGarage(false)} open={openAddGarage}>
      <DialogTitle>สร้างอู่ซ่อมบำรุงใหม่</DialogTitle>
      <form>
        <DialogContent>
          <GarageForm
            control={garageControl}
            errors={garageError}
            garage={{ name: dialogTopic }}
          />
          <DialogActions>
            <Button
              onClick={(event) => {
                handleGarageSubmit(handleCreateGarage)(event);
                setOpenAddGarage(false);
              }}
              variant="contained"
            >
              เพิ่ม
            </Button>
          </DialogActions>
        </DialogContent>{' '}
      </form>
    </Dialog>
  );

  return (
    <div>
      {renderAddGarageDialog()}
      <div className="w-full text-lg font-semibold">
        การซ่อมบำรุงจากภายนอก (ค่าแรงนอกประกอบการซ่อมบำรุง)
      </div>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ลำดับที่</TableCell>
                <TableCell>หัวข้อ</TableCell>
                <TableCell>อู่/ร้าน</TableCell>
                <TableCell>ราคา</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(outerServiceField, (eachField, index) => (
                <TableRow key={eachField.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Controller
                      name={`outer_services[${index}].title`}
                      control={control}
                      render={({ field }) => (
                        <TextField label="ชื่อรายการ" {...field} size="small" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {!_.isEmpty(garage.rows) && (
                      <div className="w-48">
                        <Controller
                          name={`outer_services[${index}].garage`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              disableClearable
                              fullWidth
                              name={field.name}
                              ref={field.ref}
                              defaultValue={field.value}
                              options={garage?.rows}
                              autoHighlight
                              getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option?.inputValue) {
                                  return option?.inputValue;
                                }
                                return option?.name;
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    fontSize: 15,
                                    '& > span': { mr: '10px', fontSize: 18 },
                                  }}
                                  {...props}
                                >
                                  {option?.name}
                                </Box>
                              )}
                              onChange={(e, value) => {
                                if (typeof value === 'string') {
                                  setTimeout(() => {
                                    setOpenAddGarage(true);
                                    setDialogTopic(value);
                                  });
                                } else if (value && value?.inputValue) {
                                  setOpenAddGarage(true);
                                  setDialogTopic(value?.inputValue);
                                } else {
                                  field.onChange(value._id);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (params?.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params?.inputValue,
                                    name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                                  });
                                }

                                return filtered;
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="อู๋ซ่อมบำรุง"
                                  size="small"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`outer_services[${index}].price`}
                      control={control}
                      render={({ field }) => (
                        <TextField label="ราคา" {...field} size="small" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        outerServiceRemove(index);
                      }}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => {
                      outerServiceAppend({});
                    }}
                  >
                    เพิ่ม
                  </Button>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>{' '}
      </Paper>
    </div>
  );
};

OuterServicesForm.propTypes = {
  control: PropTypes.object,
  handleCreateGarage: PropTypes.func,
};

export default OuterServicesForm;
