import {
  WHEEL_MAINTENANCE_ALL,
  WHEEL_MAINTENANCE_GET,
  WHEEL_MAINTENANCE_POST,
  WHEEL_MAINTENANCE_PUT,
  WHEEL_MAINTENANCE_DELETE,
  WHEEL_MAINTENANCE_LOADING,
  WHEEL_MAINTENANCE_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const wheelMaintenanceCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_MAINTENANCE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance`,
      {
        ...payload,
      },
    );
    dispatch({ type: WHEEL_MAINTENANCE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_ERROR });
  }
};

export const wheelMaintenanceAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_MAINTENANCE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_ERROR });
  }
};

export const wheelMaintenanceGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance/${id}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_MAINTENANCE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_ERROR });
  }
};

export const wheelMaintenancePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_MAINTENANCE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance/${id}`,
      payload,
    );
    dispatch({ type: WHEEL_MAINTENANCE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_ERROR });
  }
};
export const wheelMaintenanceDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_MAINTENANCE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance/${id}`,
    );
    dispatch({ type: WHEEL_MAINTENANCE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_ERROR });
  }
};
