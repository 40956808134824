import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  createFilterOptions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { DatePicker } from '../../Datepicker';

function WheelMaintenanceCard({
  control,
  wheel,
  wheelSupply,
  setWheelTypeSelect,
  openAddWheel,
  setOpenAddWheel,
  newWheel,
  setNewWheel,
  handleAddNewWheel,
}) {
  console.log('wheel', wheel?.rows);
  const filter = createFilterOptions();
  const handleChange = (event) => {
    setWheelTypeSelect(event.target.value);
  };
  const [newWheelNumber, setNewWheelNumber] = useState('');
  const [newWheelType, setNewWheelType] = useState('');

  const renderAddWheelDialog = () => (
    <Dialog
      onClose={() => setOpenAddWheel(false)}
      open={openAddWheel}
      fullWidth
    >
      <DialogTitle>เพิ่มยาง</DialogTitle>
      <DialogContent>
        <div className=" my-2 w-full">
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="เลขยาง"
              // value={setNewWheelNumber}
              onChange={(event) => {
                setNewWheelNumber(event.target.value);
              }}
            />
          </FormControl>{' '}
        </div>
        <div className="w-full my-2">
          <FormControl fullWidth>
            <InputLabel>ยี่ห้อยาง</InputLabel>
            <Select
              value={newWheelType}
              label="ยี่ห้อยาง"
              onChange={(event) => {
                setNewWheelType(event.target.value);
              }}
              fullWidth
            >
              {_.map(wheelSupply?.rows, (eachWheelSupply, typeIndex) => (
                <MenuItem key={typeIndex} value={eachWheelSupply?._id}>
                  {eachWheelSupply?.brand}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <DialogActions>
          <Button
            onClick={async () => {
              await handleAddNewWheel({
                supply: newWheelType,
                wheelNumber: newWheelNumber,
                statusWheel: 'ready',
              });
              setOpenAddWheel(false);
            }}
            variant="contained"
          >
            เพิ่ม
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  return (
    <div>
      {renderAddWheelDialog()}
      <Card>
        <CardContent>
          <div>
            <div className="font-bold">เปลี่ยนยางออก</div>
            <div className="md:grid grid-cols-2 gap-1 ">
              <div className="py-1">
                <Controller
                  name={'date'}
                  control={control}
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <div>
                      <DatePicker
                        label="วันที่ทำรายการ "
                        value={field.value}
                        setValue={(value) => field.onChange(value)}
                      />
                    </div>
                  )}
                />
              </div>
              {/* <div className="py-1">
                <Controller
                  name={'mileage'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="เลขไมล์"
                      fullWidth
                    />
                  )}
                />
              </div> */}
              <div className="py-1">
                <Controller
                  name={'tread'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="ดอกยางคงเหลือ"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="py-1">
                <Controller
                  name={'cause'}
                  control={control}
                  // defaultValue={}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="สาเหตุ"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Controller
                    name={'reuse'}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={'เพิ่มยางเก่าลงคลัง'}
              />
            </div>
            <div className="font-bold py-2 ">เปลี่ยนยางเข้า</div>
            <div className="py-1">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" size="small">
                  ยี่ห้อยาง
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={age}
                  label="ยี่ห้อยาง"
                  size="small"
                  onChange={handleChange}
                >
                  {_.map(wheelSupply?.rows, (e) => (
                    <MenuItem value={e._id}>
                      {e?.brand}: {e?.type}: ขนาด {e?.size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <Controller
                name={'wheelIn'}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    name={field.name}
                    ref={field.ref}
                    defaultValue={field.value}
                    options={wheel?.rows}
                    autoHighlight
                    size="small"
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option?.inputValue) {
                        return option?.inputValue;
                      }
                      return option?.wheelNumber;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          fontSize: 15,
                          '& > span': { mr: '10px', fontSize: 18 },
                        }}
                        {...props}
                      >
                        {`เลขยางใหม่ ${option?.wheelNumber}  ${
                          option?.statusUseable ? '(ยางเก่า)' : '(ยางใหม่)'
                        } `}
                      </Box>
                    )}
                    onChange={(e, value) => {
                      if (typeof value === 'string') {
                        setTimeout(() => {
                          setOpenAddWheel(true);
                          setNewWheel(value);
                        });
                      } else if (value && value?.inputValue) {
                        setOpenAddWheel(true);
                        setNewWheel(value?.inputValue);
                      } else {
                        field.onChange(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        label="เลขยาง"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params?.inputValue !== '') {
                        filtered.push({
                          inputValue: params?.inputValue,
                          wheelNumber: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                        });
                      }

                      return filtered;
                    }}
                  />
                )}
              />
            </div>

            <div className="flex justify-end py-2 ">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

WheelMaintenanceCard.propTypes = {
  control: PropTypes.object,
  wheel: PropTypes.object,
  wheelSupply: PropTypes.object,
  setWheelTypeSelect: PropTypes.object,
  handleAddNewWheel: PropTypes.func,
  openAddWheel: PropTypes.object,
  setOpenAddWheel: PropTypes.object,
  newWheel: PropTypes.object,
  setNewWheel: PropTypes.object,
};

export default WheelMaintenanceCard;
