import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';

import { MATERIAL_STOCK_STATUS } from '../../../utils/constants';
import { Box } from 'react-feather';

const EditMaterialStockLotForm = ({
  control,
  materialStockLot,
  place,
  information,
  customer,
}) => {
  const {
    setting: { ims: imsSetting },
  } = information;

  return (
    <div className="flex flex-wrap">
      <div className="w-full  px-1">
        <div> หมายเลขอ้างอิงของระบบ {materialStockLot?.running_number} </div>
      </div>
      <div className="w-full lg:w-1/2 px-1">
        <span className="font-semibold">วัสดุ</span>{' '}
        {materialStockLot?.material?.type_code || ''}{' '}
        {materialStockLot?.material?.name}
      </div>

      <div className="w-full lg:w-1/2 px-1">
        <span className="font-semibold">จำนวน</span>{' '}
        {materialStockLot?.quantity} {materialStockLot?.material?.unit}
        {'  '}
        <span className="font-semibold">คงเหลือ</span>{' '}
        {materialStockLot?.amount} {materialStockLot?.material?.unit}
      </div>
      <hr className="w-full my-4" />
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name="quantity"
          defaultValue={materialStockLot?.quantity}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              label={`ปริมาณทั้งหมด (${materialStockLot?.material?.unit})`}
              fullWidth
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name="amount"
          defaultValue={materialStockLot?.amount}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              label={`คงเหลือ (${materialStockLot?.material?.unit})`}
              fullWidth
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name="place"
          defaultValue={materialStockLot?.place?._id}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel>คลังวัสดุ</InputLabel>
              <Select fullWidth size="small" label="คลังวัสดุ" {...field}>
                {_.map(place?.rows, (eachPlace, placeIndex) => (
                  <MenuItem value={eachPlace?._id} key={placeIndex}>
                    {eachPlace?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {imsSetting?.display?.lot_number && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name="lot_number"
            defaultValue={materialStockLot?.lot_number}
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                label="หมายเลขล็อต"
                fullWidth
              />
            )}
          />
        </div>
      )}
      {imsSetting?.display?.cost && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name="price"
            defaultValue={materialStockLot?.price}
            control={control}
            render={({ field }) => (
              <TextField size="small" {...field} label="ราคารวม" fullWidth />
            )}
          />
        </div>
      )}

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name="status"
          defaultValue={materialStockLot?.status}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth size="small" required>
              <InputLabel>สถานะ</InputLabel>
              <Select fullWidth size="small" label="สถานะ" {...field}>
                {_.map(MATERIAL_STOCK_STATUS, (eachStatus, statusIndex) => (
                  <MenuItem value={eachStatus?.status_code} key={statusIndex}>
                    {eachStatus?.status_code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        {!_.isEmpty(customer?.rows) && (
          <Controller
            name="supplier"
            defaultValue={materialStockLot?.supplier}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                defaultValue={field.value}
                options={customer?.rows}
                getOptionLabel={(option) => option?.name}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    label="คู่ค้า"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="remark"
          control={control}
          defaultValue={materialStockLot?.remark}
          render={({ field }) => (
            <TextField
              size="small"
              fullWidth
              label="หมายเหตุ"
              multiline
              rows={3}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};

EditMaterialStockLotForm.propTypes = {
  control: PropTypes.object,
  materialStockLot: PropTypes.object,
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  information: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default EditMaterialStockLotForm;
