const K_SIZE = 45;

const markerStyles = {
  position: 'absolute',
  width: K_SIZE,
  height: K_SIZE,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  border: '2px solid #3f51b5',
  borderRadius: 20,
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 16,
  fontWeight: 'bold',
  padding: 8,
  cursor: 'pointer',
};

const makerHoverStyles = {
  ...markerStyles,
  border: '5px solid #3f51b5',
  color: '#f44336',
};

export { markerStyles, makerHoverStyles, K_SIZE };
