import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Card } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ApprovalMaterialWithdrawForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import * as CONFIG from '../../utils/constants';

const ApprovalMaterialWithdrawOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const materialWithdraw = useSelector((state) => state.materialWithdraw);
  const material = useSelector((state) => state.material);
  const me = useSelector((state) => state.me);

  const history = useHistory();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(2000);
  const [selectStockLot, setSelectStockLot] = useState([]);

  const { handleSubmit } = useForm();

  useEffect(() => {
    const getMaterialWithdraw = async () => {
      try {
        await dispatch(actions.materialWithdrawGet(id));
      } catch (error) {
        console.error('Cannot Get MaterialWithdraw');
      }
    };

    const fetchMaterial = async () => {
      try {
        await dispatch(actions.materialAll({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Material');
      }
    };

    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Me');
      }
    };

    getMaterialWithdraw();
    fetchMaterial();
    fetchMe();

    return () => {};
  }, [id]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    const confirm = window.confirm('ยืนยันการเบิกวัสดุ');
    if (confirm) {
      try {
        const channgStatus = {
          ...materialWithdraw,
          request_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
          material_withdraw: materialWithdraw._id,
          status: CONFIG.MATERIAL_WITHDRAW_STATUS.APPROVAL.status_code,
          materialLots: selectStockLot,
          process_number: materialWithdraw?.process?._id,
        };
        event.preventDefault();
        await dispatch(actions.materialWithdrawPut(id, channgStatus));
        alert('สำเร็จ');
        await dispatch(actions.materialWithdrawGet(id));
        history.goBack();
      } catch (error) {
        console.log(error);
      }
    }
  };

  // prettier-ignore
  const isPageLoading = () => materialWithdraw.rows ||
  material.isLoading;

  // prettier-ignore
  const isPageNotLoading = () => !materialWithdraw.isLoading || !materialWithdraw.rows ||
    !material.isLoadin;

  // prettier-ignore
  const isPageComplete = () => materialWithdraw.isCompleted ||
  material.isCompleted;

  const renderSelectProduct = () => (
    <ApprovalMaterialWithdrawForm
      material={material.rows}
      setSelectStockLot={setSelectStockLot}
      materialWithdraw={materialWithdraw}
    />
  );

  if (isPageLoading()) {
    return <Loading />;
  }

  if (isPageNotLoading() && isPageComplete()) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="p-6">
            <div>
              <b>ข้อมูลวัสดุ</b>
              {renderSelectProduct()}
            </div>
            <div className="flex flex-row justify-end gap-1 py-2">
              <Button
                variant="contained"
                type="submit"
                disabled={_.find(selectStockLot, { lotId: '' })}
              >
                บันทึก
              </Button>
            </div>
          </Card>
        </form>
      </div>
    );
  }
  return <Error />;
};

ApprovalMaterialWithdrawOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ApprovalMaterialWithdrawOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default ApprovalMaterialWithdrawOrder;
