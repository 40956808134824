import {
  MAINTENANCE_ORDER_ALL,
  MAINTENANCE_ORDER_GET,
  MAINTENANCE_ORDER_PUT,
  MAINTENANCE_ORDER_DEL,
  MAINTENANCE_ORDER_POST,
  MAINTENANCE_ORDER_RESET,
  MAINTENANCE_ORDER_LOADING,
  MAINTENANCE_ORDER_ERROR,
} from '../../actions/types';

const initialState = {
  maintenanceOrders: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_ORDER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAINTENANCE_ORDER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAINTENANCE_ORDER_POST:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_ORDER_PUT:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_ORDER_DEL:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_ORDER_RESET:
      return { MAINTENANCE_ORDER: null, isLoading: true, isCompleted: true };
    case MAINTENANCE_ORDER_LOADING:
      return { isLoading: true, isCompleted: true };
    case MAINTENANCE_ORDER_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
