import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _ from 'lodash';
import { currencyFormatter } from '../../utils/functions';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const MaterialStockReport = async (rows, info, place = {}) => {
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 180, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'ใบรายงานสต็อกวัสดุ',
    },
    header: [
      {
        text: `${dayjs().format('DD/MM/BBBB')}`,
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 0, 0, 12],
        text: [
          {
            text: `${info?.owner?.name}\n`,
            fontSize: '18',
            bold: true,
          },
          {
            text: `${info?.owner?.address?.house_number || ''} ${
              info?.owner?.address?.village_number
                ? `หมู่ที่${info?.owner?.address?.village_number}`
                : ''
            } ${info?.owner?.address?.road || ''} ${
              info?.owner?.address?.subdistrict || ''
            } ${info?.owner?.address?.district || ''} ${
              info?.owner?.address?.province || ''
            } ${info?.owner?.address?.postcode || ''}\n`,
            fontSize: '10',
          },
          {
            text: `โทร. ${info?.owner.telephone || '-'} แฟกซ์ ${
              info?.owner.fax || '-'
            }\n`,
            fontSize: '10',
          },
          {
            text: `อีเมล ${info?.owner?.email || '-'} เลขที่ผู้เสียภาษี ${
              info?.owner?.tax || '-'
            }\n`,
            fontSize: '10',
          },
        ],
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 0],
        text: 'ใบรายงานสต็อกวัสดุ',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 5, 20, 10],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['10%', '*', '15%', '15%'],
          body: [
            [
              {
                text: 'คลังวัสดุ',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${place?.name || 'รวมทุกคลัง'}`,
                border: [false, false, false, false],
              },
              {
                text: 'วันที่พิมพ์ข้อมูล',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: dayjs().format('D MMMM BBBB'),
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 0, 0, 0],
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
      },
    ],

    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['8%', '15%', '32%', '15%', '10%', '10%', '10%'],
          body: [
            [
              {
                text: 'ลำดับที่',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รหัสวัสดุ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ชื่อวัสดุ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'คงเหลือ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },

              {
                text: 'ราคา/หน่วย',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ราคารวม',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'หมายเหตุ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ..._.map(rows, (eachRow, index) => [
              {
                text: index + 1,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: eachRow?.type_code || '',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: eachRow?.name || '',
                border: [true, true, true, true],
              },
              {
                text:
                  currencyFormatter.format(eachRow?.total || 0) +
                    `\t${eachRow?.unit}` || '',
                border: [true, true, true, true],
                alignment: 'center',
              },
              {
                text: currencyFormatter.format(eachRow?.value_per_unit || 0),
                border: [true, true, true, true],
                alignment: 'right',
              },

              {
                text: currencyFormatter.format(eachRow?.total_value || 0),
                alignment: 'right',
                border: [true, true, true, true],
              },
              {
                text:
                  eachRow?.total < eachRow?.minimum_amount
                    ? `ต่ำกว่าขั้นต่ำที่วางไว้ ${
                        currencyFormatter.format(
                          (eachRow?.minimum_amount || 0) - eachRow?.total,
                        ) || ''
                      }  ${eachRow?.unit || ''}`
                    : '',
                border: [true, true, true, true],
              },
            ]),
            [
              {
                text: 'รวม',
                colSpan: 5,
                alignment: 'right',
                bold: true,
                border: [true, true, true, true],
              },
              {
                text: '',

                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: '',

                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: '',

                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: '',

                alignment: 'center',
                border: [true, true, true, true],
              },

              {
                text: currencyFormatter.format(
                  _.sumBy(rows, (e) => e?.total_value) || 0,
                ),
                colSpan: 2,
                bold: true,
                alignment: 'right',
                border: [true, true, true, true],
              },
              {
                text: '',

                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      subTitle: {
        fontSize: 12,
        bold: true,
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default MaterialStockReport;
