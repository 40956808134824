import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
  Box,
  Typography,
  Modal,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import { dayjs } from '../../utils/functions';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BillTable } from '../../components/Table';
import { Invoice } from '../../components/Report';
import { DatePicker, MonthPicker } from '../../components/Datepicker';

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function Bills({ title, subtitle }) {
  const dispatch = useDispatch();
  const bill = useSelector((state) => state.bill);
  const information = useSelector((state) => state.information);
  const billHeader = useSelector((state) => state.billHeader);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [includeCompleted, setIsIncludeCompleted] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [open, setOpen] = React.useState(false);
  const [billSelect, setBillSelect] = useState();
  const [headerSelect, setHeaderSelect] = useState(information);
  const [checked, setChecked] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fetchAllBill = async () => {
    try {
      await dispatch(
        actions.billAll({
          name,
          page,
          size,
          completed: includeCompleted ? '' : false,
          date:
            selectedDate !== '' ? dayjs(selectedDate).format('YYYY-MM-DD') : '',
        }),
      );
    } catch (error) {
      console.error('Fetch Bill Error', error);
    }
  };

  useEffect(() => {
    fetchAllBill();
    dispatch(actions.billHeaderAll({}));
  }, [name, page, size, includeCompleted, selectedDate]);

  useEffect(() => {
    setTotal(bill?.total);
    return () => {};
  }, [bill]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const tableFunction = {
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleDelete: async (id) => {
      const confirm = window.confirm('ยืนยันการลบบิล');
      if (confirm) {
        await dispatch(actions.billDelete(id));
        fetchAllBill();
      }
    },
    handleSuccess: async (id) => {
      const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะบิล');
      if (confirm) {
        await dispatch(actions.billPut(id, { completed: true }));
        fetchAllBill();
      }
    },
    handlePrint: (row) => {
      handleOpen();
      setBillSelect(row);
    },

    page,
    size,
    total,
    bills: bill?.rows,
    information,
  };
  const handlePrintModal = () => {
    Invoice(headerSelect, billSelect);
    setChecked(false);
    setHeaderSelect(information);
    handleClose();
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // console.log('setHeaderSelect', headerSelect);
  const remderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            พิมพ์ บิล
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="เปลี่ยนหัวบิล"
          />

          {checked && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              เลือกหัวบิล
              <div>
                <Autocomplete
                  onChange={(e, value) => {
                    setHeaderSelect(value);
                  }}
                  options={billHeader?.rows}
                  autoHighlight
                  getOptionLabel={(option) => `${option?.owner?.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      size="small"
                      label="งาน"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
            </Typography>
          )}
          <div className="py-2 flex justify-center">
            <Button
              variant="contained"
              color="success"
              onClick={() => handlePrintModal()}
            >
              พิมพ์
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <Link to="bill/create" className="self-center">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap">
        <div className="w-full lg:w-2/4 md:w-1/2 px-2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full lg:w-1/4 px-2 flex gap-1">
          <MonthPicker
            label="เดือนค้นหา"
            openTo="year"
            value={selectedDate}
            setValue={setSelectedDate}
          />

          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setSelectedDate('')}
          >
            Clear
          </Button>
        </div>
        <div className="w-full lg:w-1/4 md:w-1/2 px-2">
          <FormControlLabel
            label="รวมรายการที่เสร็จแล้ว"
            control={
              <Switch
                checked={includeCompleted}
                onChange={(event) => {
                  setIsIncludeCompleted(event.target.checked);
                }}
              />
            }
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-4">
      <BillTable {...tableFunction} />
    </div>
  );

  if (bill.isLoading) {
    return <Loading />;
  }
  if (!bill.isLoading && bill.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderSearch()}
        {renderTable()}
        {remderModal()}
      </div>
    );
  }
  return <Error />;
}

Bills.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Bills.defaultProps = {
  title: '',
  subtitle: '',
};

export default Bills;
