import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';
import { BackButton } from '../../components/Button';
import VehcleForm from '../../components/Forms/WMS/VehcleInfoFrom';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

export default function CreateWheelRegister({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const vehicle = useSelector((state) => state.vehicle);
  const garage = useSelector((state) => state.garage);
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const { handleSubmit, control, setValue } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'wheelArray',
  });
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [wheelCheck, setWheelCheck] = useState();

  console.log('111', wheelSupply);
  useEffect(() => {
    dispatch(actions.vehicleAll({ statusVehicle: false }));
    // dispatch(actions.trailerTailAll({ name, page, size }));
    dispatch(actions.wheelSupplyAll({}));
    dispatch(actions.garageAll({}));
  }, [name, page, size]);

  const handleSave = async (data) => {
    const confirm = window.confirm('ยืนยันการบันทึก');
    if (confirm) {
      try {
        const dataSubmit = {
          vehicle: data.licence_number,
          wheel: data.wheelArray,
        };

        console.log('data', data);
        await dispatch(actions.wheelRegisterCreate(dataSubmit));
        await dispatch(
          actions.vehiclePut(data?.licence_number, { statusVehicle: true }),
        );
        await dispatch(actions.wheelRegisterAll());

        history.push('/wms/register');
      } catch (error) {
        alert(error);
      }
    }
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderVehcle = () => (
    <div className="pt-4">
      <form onSubmit={handleSubmit(handleSave)}>
        <VehcleForm
          vehicle={vehicle}
          garage={garage}
          control={control}
          Controller={Controller}
          setValue={setValue}
          fields={fields}
          append={append}
          remove={remove}
          create={true}
          wheelCheck={wheelCheck}
          setWheelCheck={setWheelCheck}
          wheelSupply={wheelSupply}
        />
      </form>
    </div>
  );
  const renderBackButton = () => <BackButton />;
  if (vehicle.isLoading) {
    return <Loading />;
  }
  if (!vehicle.isLoading && vehicle.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderVehcle()}
      </div>
    );
  }
  <h1>404 </h1>;
}
CreateWheelRegister.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateWheelRegister.defaultProps = {
  title: '',
  subtitle: '',
};
