import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  MaterialStock,
  MaterialHistory,
  DetailMaterialStock,
  CreateMaterialStockLot,
  DetailMaterialStockLot,
  MaterialWithdrawOrder,
  ApprovalMaterialWithdrawOrder,
  Material,
  EditMaterial,
  CreateMaterial,
  DetailMaterial,
  DetailMaterialTransaction,
  MaterialType,
  EditMaterilType,
  Place,
  EditPlace,
  MaterialTransactionType,
  EditMaterialTransactionType,
  ListMaterialStockLot,
  EditMaterialStockLot,
  MaterialRequests,
  MaterialApproval,
  MaterialStockFill,
} from '../views/IMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function IMS() {
  const module = 'IMS';
  const prefix = '/ims';
  const name = 'จัดการวัสดุ';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const me = useSelector((state) => state.me);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : IMS');
    dispatch(actions.informationAll());

    dispatch(actions.meGet());
    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/material-stock`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              {/* {accessRight(me, module, 1) && ( */}
              <Route exact path={`${prefix}/material-type`}>
                <MaterialType title={'ประเภทวัสดุ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-type/edit/:id`}>
                <EditMaterilType title={'แก้ไขประเภทวัสดุ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-transaction-type`}>
                <MaterialTransactionType
                  title={'ประเภทการดำเนินการ'}
                  subtitle={name}
                />
              </Route>
              <Route
                exact
                path={`${prefix}/material-transaction-type/edit/:id`}
              >
                <EditMaterialTransactionType
                  title={'แก้ไขประเภทการดำเนินการ'}
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock`}>
                <MaterialStock title="จัดการสต๊อกวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-stock/all`}>
                <DetailMaterialStock title="จัดการสต๊อกวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-stock/place/:id`}>
                <DetailMaterialStock title="จัดการสต๊อกวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-stock/create/:id`}>
                <CreateMaterialStockLot
                  title="เพิ่ม/ลด ล็อตวัสดุ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/:id/:placeId`}>
                <DetailMaterialStockLot
                  title="รายละเอียดล็อตวัสดุ"
                  subtitle={name}
                />
              </Route>

              <Route exact path={`${prefix}/materials`}>
                <Material title="จัดการข้อมูลวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material/edit/:id`}>
                <EditMaterial title="แก้ไขข้อมูลวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/materials/create`}>
                <CreateMaterial title="เพิ่มวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material/:id`}>
                <DetailMaterial title="รายละเอียดข้อมูลวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history`}>
                <MaterialHistory
                  title="ประวัติการจัดการวัสดุ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/history/detail/:id`}>
                <DetailMaterialTransaction
                  title="รายละเอียดการจัดการวัสดุ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock-lot/`}>
                <ListMaterialStockLot title="รายการล็อตวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/place`}>
                <Place title="จัดการคลังวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/place/edit/:id`}>
                <EditPlace title="แก้ไขการคลังวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-request`}>
                <MaterialRequests title="การขอเบิกวัสดุ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-approval/:id`}>
                <MaterialApproval
                  title="การอนุมัติการเบิกวัสดุ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock-fill`}>
                <MaterialStockFill
                  title="เพิ่มวัสดุลงในสต็อก"
                  subtitle={name}
                />
              </Route>
              {accessRight(me, module, 1) && (
                <Switch>
                  <Route exact path={`${prefix}/material-stock-lot/edit/:id`}>
                    <EditMaterialStockLot
                      title="แก้ไขล็อตวัสดุ"
                      subtitle={name}
                    />
                  </Route>

                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default IMS;
