import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { JobTable } from '../../components/Table';

function Jobs({ title, subtitle }) {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  useEffect(() => {
    dispatch(actions.jobAll({ name, page, size }));
  }, [name, page, size]);

  useEffect(() => {
    setTotal(job?.total);
    return () => {};
  }, [job]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const tableFunction = {
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleDelete: async (id) => {
      const confirm = window.confirm('ยืนยันการลบงาน');
      if (confirm) {
        await dispatch(actions.jobDelete(id));
        await dispatch(actions.jobAll({ name, page, size }));
      }
    },
    page,
    size,
    total,
    job: job?.rows,
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <Link to="job/create" className="self-center">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-4">
      <JobTable {...tableFunction} />
    </div>
  );

  if (job.isLoading) {
    return <Loading />;
  }
  if (!job.isLoading && job.isCompleted) {
    return (
      <div>
        {renderTitle()}

        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Jobs.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Jobs.defaultProps = {
  title: '',
  subtitle: '',
};

export default Jobs;
