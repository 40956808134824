/* eslint-disable default-param-last */
import {
  WHEELTRAILERTAIL_PUT,
  WHEELTRAILERTAIL_GET,
  WHEELTRAILERTAIL_POST,
  WHEELTRAILERTAIL_ALL,
  WHEELTRAILERTAIL_DELETE,
  WHEELTRAILERTAIL_LOADING,
  WHEELTRAILERTAIL_ERROR,
} from '../../actions/types';

const initialState = {
  wheel: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case WHEELTRAILERTAIL_LOADING:
      return { isLoading: true, isCompleted: false };
    case WHEELTRAILERTAIL_ERROR:
      return { isLoading: false, isCompleted: false };
    case WHEELTRAILERTAIL_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WHEELTRAILERTAIL_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WHEELTRAILERTAIL_POST:
      return { isLoading: false, isCompleted: true };
    case WHEELTRAILERTAIL_PUT:
      return { isLoading: false, isCompleted: true };
    case WHEELTRAILERTAIL_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
