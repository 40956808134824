/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';
import currencyFormatter from '../../utils/functions/currencyFormat';
import floatingCurrencyFormatter from '../../utils/functions/floatingCurrencyFormater';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

// For Suphasin Logistic
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

function printPDF(order, info, me) {
  const {
    setting: { cmms: cmmsSetting },
  } = info;

  const genMaterials = (processMaterials) => {
    const pageNumber = Math.floor(_.size(processMaterials) / 11) + 1;
    console.log('Page Number', pageNumber);
    const remainingAmount = 11 * pageNumber - _.size(processMaterials);
    console.log('Remainign Number', remainingAmount);
    const arrayOfRemainging = Array(remainingAmount);
    const processMaterialArray = _.concat(processMaterials, arrayOfRemainging);
    const mainData = _.map(processMaterialArray, (eachProcessMat, index) => [
      {
        text: '',
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: eachProcessMat?.material?.type_code || '',
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: eachProcessMat?.material?.name || '',
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: eachProcessMat?.amount
          ? currencyFormatter.format(eachProcessMat?.amount)
          : '',
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: eachProcessMat?.average_price
          ? floatingCurrencyFormatter.format(eachProcessMat?.average_price)
          : '',
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: eachProcessMat?.total_price
          ? floatingCurrencyFormatter.format(eachProcessMat?.total_price)
          : '',
        colSpan: 1,
        alignment: 'center',
        border: [false, false, false, false],
      },
      {
        text: '',
        colSpan: 1,
        border: [false, false, false, false],
      },
    ]);

    return mainData;
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 280, 40, 300],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบขอเบิกวัตถุดิบ',
    },
    header: [
      {
        text: '',
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 48, 0, 12],
        text: '',
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: '',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        text: '',
      },
      {
        style: 'tableExample',
        margin: [25, 10, 10, 2],
        fontSize: 10,
        table: {
          widths: ['28%', '22%', '18%', '28%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `  ${dayjs(order?.start_date).format('D MMMM  BBBB')}`,
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `      ${
                  order?.vehicle?.licence_number || ''
                }                  `,
                colSpan: 1,

                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: ` ${order?.running_number || ''}                  `,
                colSpan: 1,

                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '  ',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: ``,
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: ``,
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 3,
                border: [false, false, false, false],
              },
              {},
              {},
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    // footer(currentPage, pageCount) {
    //   return [
    //     {
    //       text: `${currentPage.toString()} of ${pageCount}`,
    //       alignment: 'right',
    //       margin: [10, 10, 10, 10],
    //       fontSize: '10',
    //     },
    //   ];
    // },

    content: [
      {
        style: 'tableExample',
        margin: [20, 20, 10, 2],

        fontSize: 11,
        table: {
          widths: ['10%', '15%', '*', '10%', '15%', '12%', '12%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
            ],
            ...genMaterials(order?.process_materials),
          ],
        },
      },
    ],
    footer: [
      {
        margin: [40, 20, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['12%', '*', '20%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
          ],
        },
      },
      {
        margin: [40, 20, 10, 0],
        style: 'tableExample',
        fontSize: 11,
        table: {
          heights: [15],
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
              },
              {
                text: `${order?.allower?.firstname || ''}`,
                colSpan: 1,
                border: [false, false, false, false],
                alignment: 'center',
              },
              {
                text: `${order?.allower?.firstname || ''}`,
                colSpan: 1,
                border: [false, false, false, false],
                alignment: 'center',
              },
              {
                text: '',
                colSpan: 1,
                border: [false, false, false, false],
                alignment: 'center',
              },
            ],
            [
              {
                text: `  ${dayjs(order?.start_date).format(' D / M / BB')}`,
                colSpan: 1,
                border: [false, false, false, false],
                alignment: 'center',
              },
              {
                text: `  ${dayjs(order?.start_date).format(' D / M / BB')}`,
                colSpan: 1,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: `  ${dayjs(order?.start_date).format(' D / M / BB')}`,
                colSpan: 1,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '',
                colSpan: 1,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
  };

  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
