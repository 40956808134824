import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';

const MaterialStockFillForm = ({
  control,
  customer,
  setValue,
  watch,
  materialTransactionType,
  place,
}) => {
  const { fields } = useFieldArray({ control, name: 'arr' });
  const filteredMaterialTxnType = _.filter(
    materialTransactionType?.rows,
    (eachMaterialTxnType) => eachMaterialTxnType?.direction === 'add',
  );
  return (
    <div>
      <div>
        <Controller
          control={control}
          name="material_transaction_type"
          defaultValue={filteredMaterialTxnType?.[0]?._id}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel>วิธีการดำเนินการ</InputLabel>
              <Select label="วิธีการดำเนินการ" {...field}>
                {_.map(
                  filteredMaterialTxnType,
                  (eachMaterialTxnType, index) => (
                    <MenuItem key={index} value={eachMaterialTxnType?._id}>
                      {eachMaterialTxnType?.name}{' '}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <Table>
        <TableHead>
          <TableCell>
            <div className="font-semibold"> ลำดับที่ </div>
          </TableCell>
          <TableCell>
            <div className="font-semibold"> วัสดุ </div>
          </TableCell>
          <TableCell>
            <div className="font-semibold"> จำนวน </div>
          </TableCell>
          <TableCell>
            <div className="font-semibold"> หน่วย </div>
          </TableCell>
          <TableCell>
            <div className="font-semibold"> คู่ค้า </div>
          </TableCell>
          <TableCell>
            <div className="font-semibold"> คลัง </div>
          </TableCell>
          <TableCell>
            <div className="font-semibold"> ราคา </div>
          </TableCell>
        </TableHead>
        <TableBody>
          {_.map(fields, (eachField, index) => (
            <TableRow key={eachField?.id}>
              <TableCell>{index + 1} </TableCell>
              <TableCell>
                {eachField?.material?.type_code || ''}{' '}
                {eachField?.material?.name}{' '}
              </TableCell>
              <TableCell>
                <Controller
                  control={control}
                  name={`arr[${index}].quantity`}
                  render={({ field }) => (
                    <TextField {...field} label="จำนวน" size="small" />
                  )}
                />{' '}
              </TableCell>
              <TableCell>{eachField?.material?.unit}</TableCell>
              <TableCell>
                <div className="w-48">
                  <Controller
                    control={control}
                    name={`arr[${index}].supplier`}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        size="small"
                        options={customer?.rows}
                        autoHighlight
                        getOptionLabel={(option) => `${option?.name || ''}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props}>{option?.name} </MenuItem>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="คู่ค้า"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    )}
                  />{' '}
                </div>
              </TableCell>
              <TableCell>
                <div className="w-48">
                  <Controller
                    control={control}
                    name={`arr[${index}].place`}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        size="small"
                        options={place?.rows}
                        autoHighlight
                        getOptionLabel={(option) => `${option?.name || ''}`}
                        renderOption={(props, option) => (
                          <MenuItem {...props}>{option?.name} </MenuItem>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="คลัง"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    )}
                  />{' '}
                </div>
              </TableCell>
              <TableCell>
                <div className="flex gap-2">
                  <Controller
                    control={control}
                    name={`arr[${index}].price_per_unit`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ราคาต่อหน่วย"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <div className="self-center">บาท</div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

MaterialStockFillForm.propTypes = {
  control: PropTypes.object,
  material: PropTypes.object,
  place: PropTypes.object,
  customer: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  materialTransactionType: PropTypes.object,
};

export default MaterialStockFillForm;
