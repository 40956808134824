import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  FormControl,
  Autocomplete,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
  createFilterOptions,
  DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';

const AttachMaintenanceTopicForm = ({
  showPrice,
  showPriceInMaintenanceTopic,
  maintenanceTopics,
  control,
  maintenanceTypes,
  handleCreateMaintenanceTopic,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'maintenance_topic',
  });
  const filter = createFilterOptions();
  const information = useSelector((state) => state.information);
  const [openAddMaintenanceTopic, setOpenAddMaintenanceTopic] = useState(false);
  const [dialogTopic, setDialogTopic] = useState('');
  const [
    selectedCreateTopicMaintenanceType,
    setSelectedCreateTopicMaintenanceType,
  ] = useState('');
  const {
    setting: { cmms: cmmsSetting },
  } = information;

  const renderAddMaintenanceDialog = () => (
    <Dialog
      onClose={() => setOpenAddMaintenanceTopic(false)}
      open={openAddMaintenanceTopic}
    >
      <DialogTitle>สร้างหัวข้อการบำรุงรักษา</DialogTitle>
      <DialogContent>
        <div className="w-full my-2">
          <FormControl fullWidth>
            <InputLabel>ประเภทการบำรุงรักษา</InputLabel>
            <Select
              value={selectedCreateTopicMaintenanceType}
              label="ประเภทการบำรุงรักษา"
              onChange={(event) => {
                setSelectedCreateTopicMaintenanceType(event.target.value);
              }}
              defaultValue={maintenanceTypes?.rows?._id}
              fullWidth
            >
              {_.map(
                maintenanceTypes?.rows,
                (eachMaintenanceType, typeIndex) => (
                  <MenuItem key={typeIndex} value={eachMaintenanceType?._id}>
                    {eachMaintenanceType?.name}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </div>
        <div className=" my-2">
          <FormControl>
            <TextField
              label="หัวข้อการบำรุงรักษา"
              value={dialogTopic}
              onChange={(event) => {
                setDialogTopic(event.target.value);
              }}
            />
          </FormControl>{' '}
        </div>
        <DialogActions>
          <Button
            onClick={async () => {
              await handleCreateMaintenanceTopic({
                type: selectedCreateTopicMaintenanceType,
                name: dialogTopic,
              });
              setOpenAddMaintenanceTopic(false);
            }}
            variant="contained"
          >
            เพิ่ม
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  return (
    <div>
      {renderAddMaintenanceDialog()}
      <div className="w-full my-2 mb-4">
        <div>
          <div
            className={`w-full px-1 py-2  font-semibold text-lg ${
              showPrice ? 'font-semibold' : ''
            } `}
          >
            หัวข้อการบำรุงรักษา
          </div>
        </div>
        <Paper>
          <Table size="small">
            <colgroup>
              <col width="10%" />
              <col width="30%" />
              {cmmsSetting?.attach_topic_amount && <col width="10%" />}
              {showPriceInMaintenanceTopic && <col width="30%" />}
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableCell>
                <div className="font-bold my-1">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold my-1">รายการ</div>
              </TableCell>
              {cmmsSetting?.attach_topic_amount && (
                <TableCell>
                  <div className="font-bold my-1">จำนวน</div>
                </TableCell>
              )}
              {showPriceInMaintenanceTopic && (
                <TableCell>
                  <div className="font-bold my-1">ราคา</div>
                </TableCell>
              )}

              {cmmsSetting?.show_detail_in_maintenance_topic && (
                <TableCell>
                  <div className="font-bold my-1">รายละเอียด</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold my-1">ดำเนินการ</div>
              </TableCell>
            </TableHead>
            <TableBody>
              {_.map(fields, (field, index) => (
                <TableRow key={field?.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {!_.isEmpty(maintenanceTopics?.rows) && (
                      <Controller
                        name={`maintenance_topic[${index}].topic`}
                        control={control}
                        defaultValue={maintenanceTopics?.rows?.[0]}
                        render={({ field: maintenanceTopicField }) => (
                          <FormControl fullWidth>
                            <Autocomplete
                              {...maintenanceTopicField}
                              defaultValue={maintenanceTopicField.value}
                              options={maintenanceTopics?.rows}
                              getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option?.inputValue) {
                                  return option?.inputValue;
                                }
                                return option?.name;
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    fontSize: 15,
                                    '& > span': { mr: '10px', fontSize: 18 },
                                  }}
                                  {...props}
                                >
                                  {option?.name}
                                </Box>
                              )}
                              onChange={(e, value) => {
                                if (typeof value === 'string') {
                                  setTimeout(() => {
                                    setOpenAddMaintenanceTopic(true);
                                    setDialogTopic(value);
                                  });
                                } else if (value && value?.inputValue) {
                                  setOpenAddMaintenanceTopic(true);
                                  setDialogTopic(value?.inputValue);
                                } else {
                                  maintenanceTopicField.onChange(value);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  size="small"
                                  label="หัวข้อการซ่อมบำรุง"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (params?.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params?.inputValue,
                                    name: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                                  });
                                }

                                return filtered;
                              }}
                            />
                          </FormControl>
                        )}
                      />
                    )}
                  </TableCell>
                  {cmmsSetting?.attach_topic_amount && (
                    <TableCell>
                      <Controller
                        name={`maintenance_topic[${index}].amount`}
                        control={control}
                        render={({ field: maintenanceTopicField }) => (
                          <TextField
                            {...maintenanceTopicField}
                            size="small"
                            label="จำนวน"
                            fullWidth
                          />
                        )}
                      />
                    </TableCell>
                  )}
                  {showPriceInMaintenanceTopic && (
                    <TableCell>
                      <Controller
                        name={`maintenance_topic[${index}].price`}
                        control={control}
                        render={({ field: maintenanceTopicField }) => (
                          <TextField
                            {...maintenanceTopicField}
                            size="small"
                            label="ราคา"
                            fullWidth
                          />
                        )}
                      />
                    </TableCell>
                  )}

                  {cmmsSetting?.show_detail_in_maintenance_topic && (
                    <TableCell>
                      <Controller
                        name={`maintenance_topic[${index}].detail`}
                        control={control}
                        render={({ field: maintenanceTopicField }) => (
                          <TextField
                            {...maintenanceTopicField}
                            size="small"
                            label="รายละเอียด"
                            multiline
                            rows={2}
                            fullWidth
                          />
                        )}
                      />
                    </TableCell>
                  )}

                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      append({
                        amount: 1,
                      })
                    }
                  >
                    เพิ่ม
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>
  );
};

AttachMaintenanceTopicForm.propTypes = {
  showPrice: PropTypes.bool,
  showPriceInMaintenanceTopic: PropTypes.bool,
  maintenanceTopics: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  control: PropTypes.object,
  maintenanceTypes: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handleCreateMaintenanceTopic: PropTypes.func,
};

export default AttachMaintenanceTopicForm;
