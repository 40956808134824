import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';

import * as actions from '../../redux/actions';

import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { OneZonePricingForm } from '../../components/Forms';

function CreatePricing({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit } = useForm({});

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.zonePriceCreate({ ...data }));
      alert('เพิ่มโซนราคาสำเร็จ');
      history.goBack();
    } catch (error) {
      alert('ไม้สามารถเพิ่มโซนราคาได้');
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-2 ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <OneZonePricingForm control={control} />
        <div className="my-2 flex justify-end">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {renderBackButton()}
      {renderForm()}
    </div>
  );
}

CreatePricing.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreatePricing.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreatePricing;
