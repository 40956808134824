/* eslint-disable react/prop-types */
import React from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  dayjs,
  // addressToString,
  showVehicleScheduleStatus,
} from '../../../utils/functions';

const VehicleScheduleTable = ({
  handleChangeRowsPerPage,
  vehicleSchedule,
  handleChangePage,
  page,
  size,
  total,
  handlePrintPdfDeliveryOrder = () => {},
  handlePending = () => {},
  handleComplete = () => {},
  handleBill = () => {},
  handleCancle = () => {},
  handleToEditPage = () => {},
  handleToInfoPage = () => {},
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">เริ่มจอง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">สิ้นสุด</div>
            </TableCell>{' '}
            <TableCell>
              <div className="font-bold">เลขที่รายการ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">สถานะ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">รายการงาน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ทะเบียนรถ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">หมายเลขซีล</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(vehicleSchedule?.rows) ? (
            vehicleSchedule?.rows.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {(page - 1) * size + index + 1}
                </TableCell>
                <TableCell>
                  {`${dayjs(row?.start_date).format('DD MMM BBBB')}`}
                </TableCell>
                <TableCell>
                  {`${dayjs(row?.end_date).format('DD MMM BBBB')}`}
                </TableCell>
                <TableCell>{row?.running_number}</TableCell>
                <TableCell>
                  <div className="flex flex-col gap-2 ">
                    <div className="flex justify-center font-bold">
                      {row?.schedule_type}
                    </div>
                    <div>{showVehicleScheduleStatus({ ...row })}</div>
                    <div className="w-full">
                      <Button
                        variant="outlined"
                        size="small"
                        className="text-white"
                        fullWidth
                        onClick={() => handleToInfoPage(row?._id)}
                      >
                        รายละเอียด
                      </Button>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  {!_.isEmpty(row?.delivery_orders) ? (
                    _.map(row?.delivery_orders, (order, index) => (
                      <div className="mb-2">
                        <div>
                          {`${index + 1}. `}
                          <b>{order?.invoice_number}</b>
                          {'  '}
                          {order?.job?.name}
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              handlePrintPdfDeliveryOrder(order, row);
                            }}
                          >
                            พิมพ์
                          </Button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div> - </div>
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex flex-col gap-2 ">
                    <div>{`หัวรถ: ${row?.vehicle?.licence_number}` || '-'}</div>
                    <div>
                      {`หางรถ: ${row?.trailer_tail?.licence_number}` || '-'}
                    </div>
                    <div>
                      {`ตู้คอนเทนเนอร์: ${row?.container?.licence_number}` ||
                        '-'}
                    </div>
                  </div>
                </TableCell>
                <TableCell>{row?.seal_number || '-'}</TableCell>
                <TableCell>
                  <div className="flex flex-col gap-1">
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      className="text-white"
                      onClick={() => handleToEditPage(row?._id)}
                    >
                      แก้ไข
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className="text-white"
                      disabled={row?.pending}
                      onClick={() => handlePending(row._id)}
                    >
                      ดำเนินการ
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      className="text-white"
                      disabled={row?.completed}
                      onClick={() => {
                        if (row?.pending) {
                          handleComplete(row._id);
                        } else {
                          alert(
                            'ไม่สามารถเปลี่ยนสถานะเป็น สำเร็จ ได้เนื่องจากรายการนี้ยังไม่เริ่มดำเนินการ',
                          );
                        }
                      }}
                    >
                      สำเร็จ
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="blue"
                      className="text-white"
                      disabled={row?.billed}
                      onClick={() => {
                        if (row?.completed) {
                          handleBill(row._id);
                        } else {
                          alert(
                            'ไม่สามารถเปลี่ยนสถานะเป็น ออกบิลแล้ว ได้เนื่องจากรายการนี้ยังดำเนินการไม่สำเร็จ',
                          );
                        }
                      }}
                    >
                      ออกบิลแล้ว
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      disabled={row?.completed || row?.billed}
                      onClick={() => handleCancle(row._id)}
                    >
                      ยกเลิก
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={handleChangePage}
    />
  </Paper>
);

VehicleScheduleTable.propTypes = {
  handleChangeRowsPerPage: PropTypes.func,
  vehicleSchedule: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleReady: PropTypes.func,
  handleCancle: PropTypes.func,
  handleToEditPage: PropTypes.func,
  handleToInfoPage: PropTypes.func,
  handlePrintPdfDeliveryOrder: PropTypes.func,
};

export default VehicleScheduleTable;
