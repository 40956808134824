import dayjs from 'dayjs';

export const renderRemark = (rows) =>
  _.map(rows, (order, index) => [
    { text: order?.title, colSpan: 3 },
    {},
    {},
    {
      text:
        'THB ' + order?.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      alignment: 'right',
    },
    {
      text:
        'THB ' + order?.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      alignment: 'right',
    },
  ]);

export default renderRemark;
