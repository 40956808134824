/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';

import { Box, Button, Tab, Tabs } from '@mui/material';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { VehicleCard } from '../../components/Card';

import MaterialTransactionContainer from '../DASHBOARD/MaterialTransactionContainer';
import MaintenanceOrderContainer from '../DASHBOARD/MaintenanceOrdersContainer';
import WheelMaintenHistoryVehicleTable from '../../components/Table/WMS/WheelMaintenHistoryVehicleTable';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function DetailByTailertailMaintenance({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const trailerTail = useSelector((state) => state.trailerTail);
  const information = useSelector((state) => state.information);
  const wheelMaintenanceHistory = useSelector(
    (state) => state.WheelMaintenanceHistory,
  );

  const [selectedTab, setSelectedTable] = useState(0);

  useEffect(() => {
    dispatch(actions.trailerTailGet(params.id));
    dispatch(
      actions.wheelMaintenanceHistoryAll({
        trailerTail: params.id,
      }),
    );
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderCard = () => (
    <div className="my-2">
      <VehicleCard vehicleDetail={trailerTail} showLink tail={true} />
    </div>
  );

  const renderTab = () => (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTable(newValue)}
          >
            <Tab
              label="รายการแจ้งบำรุงรักษา"
              id="tab-0"
              aria-controls="tabpanel-0"
            />
            <Tab
              label="รายการเบิกวัสดุ"
              id="tab-1"
              aria-controls="tabpanel-1"
            />
            {/* <Tab label="รายการซ่อมยาง" id="tab-2" aria-controls="tabpanel-1" /> */}
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <MaintenanceOrderContainer
          information={information}
          vehicleId={params.id}
          vehicleDetail={trailerTail}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <MaterialTransactionContainer
          information={information}
          vehicleId={params.id}
        />
      </TabPanel>
      {/* <TabPanel value={selectedTab} index={2}>
        <div>
          <WheelMaintenHistoryVehicleTable
            eachWheelMaintenance={wheelMaintenanceHistory?.rows}
          />
        </div>
      </TabPanel> */}
    </div>
  );

  const renderBackButton = () => <BackButton />;

  if (trailerTail.isLoading) {
    return <Loading />;
  }
  if (!trailerTail.isLoading && trailerTail.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <Link to={`../../create/${params.id}`} className="self-center">
            <Button variant="contained">เพิ่ม</Button>
          </Link>
        </div>
        {renderBackButton()}
        {renderCard()}
        {renderTab()}
      </div>
    );
  }
  return <Error />;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

DetailByTailertailMaintenance.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showAddButton: PropTypes.bool,
};

DetailByTailertailMaintenance.defaultProps = {
  title: '',
  subtitle: '',
  showAddButton: false,
};

export default DetailByTailertailMaintenance;
