/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import currencyFormatterTH from '../../utils/functions/currencyFormat';
import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genMaterialTransaction = (data) => {
  return _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.createdAt)
        .locale('th')
        .format('DD MMM BBBB')}\n เวลา ${new Date(
        _data?.createdAt,
      ).toLocaleTimeString('th')} น.`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.material_transaction_type?.name || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot) => `${lot?.lotId?.lot_number || '-'}\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot, indexLot) =>
          `${indexLot + 1}. ${lot?.lotId?.material?.type_code || ''} ${
            lot?.lotId?.material?.name || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot) => `${lot?.lotId?.place?.name || ''}\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot) =>
          `${currencyFormatterTH.format(lot?.lotId?.quantity) || '0'} ${
            lot?.lotId?.material?.unit || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.material_stock_lot,
        (lot) =>
          `${currencyFormatterTH.format(lot?.lotId?.amount) || '0'} ${
            lot?.lotId?.material?.unit || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${
        _data?.request_name
          ? _data?.request_name || '-'
          : _data?.material_stock_lot[0]?.lotId?.recipient_name || '-'
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.remark || ''}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);
};

export const MaterialHReport = (
  materialTransaction,
  information,
  monthChang,
) => {
  const materialData = genMaterialTransaction(materialTransaction);
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 100, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ประวิติการจัดการวัสดุ',
    },
    header: ReportHeader(information),
    content: [
      {
        margin: [0, 20, 30, 0],
        style: 'tableExample',
        layout: {
          fillColor(rowIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        table: {
          widths: [
            '5%',
            '12%',
            '7%',
            '10%',
            '20%',
            '10%',
            '7%',
            '7%',
            '20%',
            '10%',
          ],
          heights: [15],
          headerRows: 1,
          dontBreakRows: true,
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่ดำเนินการ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ประเภท',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วัสดุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อคลัง',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'คงเหลือ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ผู้ดำเนินการ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...materialData,
          ],
        },
      },
    ],
  };
  pdfMake.createPdf(docDefinition).open();
};
