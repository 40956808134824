/* eslint-disable import/no-named-as-default */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import _ from 'lodash';

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { DeliveryOrderForm } from '../../components/Forms';
import { CONFIG } from '../../utils/constants';

const CreateDeliveryOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const job = useSelector((state) => state.job);
  const container = useSelector((state) => state.container);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({});

  const [selectJob, setSelectJob] = useState();

  useEffect(() => {
    dispatch(actions.jobAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.containerAll({ page: 1, size: CONFIG.infiniteLimit }));
    return () => {};
  }, []);

  const handleCreateJob = () => {
    history.push('/jms/job');
  };

  const handleCreateContainer = async (data) => {
    try {
      await dispatch(actions.containerCreate(data));
      await dispatch(
        actions.containerAll({ page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      alert(`สร้างตู้คอนเทนเนอร์ไม่สำเร็จ ${error?.message}`);
    }
  };

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        job: data?.job?._id ? data?.job?._id : data?.job,
        price: parseFloat(data?.price),
        weight: parseFloat(data?.weight),
        amount: parseFloat(data?.amount),
      };
      await dispatch(actions.deliveryOrderCreate(payload));
      window.alert('สร้างคำสั่งจัดส่งสำเร็จ');
      history.push('/tms/delivery-order');
    } catch (error) {
      window.alert('สร้างคำสั่งจัดส่งไม่สำเร็จ', error?.message);
      // eslint-disable-next-line no-undef
      console.error('Create Manufacturing Order Fail', err);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderButton = () => (
    <div className="flex justify-start pb-4">
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DeliveryOrderForm
          control={control}
          errors={errors}
          container={container}
          job={job}
          selectJob={selectJob}
          setSelectJob={setSelectJob}
          handleCreateJob={handleCreateJob}
          handleCreateContainer={handleCreateContainer}
          setValue={setValue}
          watch={watch}
        />
        <div className="flex justify-end my-2">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  if (job.isLoading || container?.isLoading) {
    return <Loading />;
  }

  if (!job.isLoading && job.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
};

CreateDeliveryOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateDeliveryOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateDeliveryOrder;
