/* eslint-disable import/order */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import WheelInfoCard from '../../components/Card/WMS/Wheelinfo';
import { BackButton } from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { useParams } from 'react-router';
import WheelHistory from '../../components/Card/WMS/WheelHistory';
import WheelRepairHistory from '../../components/Card/WMS/WheelRepairHistory';
import WheelReport from '../../components/Report/WheelReport';
import { Button } from '@mui/material';

export default function DetailWheelInfo({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const wheel = useSelector((state) => state.wheel);
  const infomation = useSelector((state) => state.infomation);
  const wheelTransection = useSelector((state) => state.wheelTransection);
  useEffect(() => {
    dispatch(actions.wheelGet(id));
    dispatch(actions.wheelTransectionAll({ id }));

    return () => {};
  }, []);
  const handelPrint = () => WheelReport(infomation, wheel, wheelTransection);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderInfo = () => <WheelInfoCard wheel={wheel} />;
  const renderBack = () => (
    <div className="flex justify-between">
      <BackButton />
      <div>
        <Button variant="contained" onClick={() => handelPrint()}>
          พิมพ์รายงาน
        </Button>
      </div>
    </div>
  );
  const renderHistoryList = () => (
    <WheelHistory wheelTransection={wheelTransection} />
  );

  const renderRepairList = () => (
    <WheelRepairHistory wheelTransection={wheelTransection} />
  );

  return (
    <div>
      <div>
        {renderTitle()}
        {renderBack()}
        {renderInfo()}
        {renderHistoryList()}
        {renderRepairList()}
      </div>
    </div>
  );
}
DetailWheelInfo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailWheelInfo.defaultProps = {
  title: '',
  subtitle: '',
};
