import {
  MAINTENANCE_NOTIFY_ALL,
  MAINTENANCE_NOTIFY_GET,
  MAINTENANCE_NOTIFY_PUT,
  MAINTENANCE_NOTIFY_DELETE,
  MAINTENANCE_NOTIFY_POST,
  MAINTENANCE_NOTIFY_RESET,
  MAINTENANCE_NOTIFY_LOADING,
  MAINTENANCE_NOTIFY_ERROR,
} from '../../actions/types';

const initialState = {
  maintenanceNotify: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line default-param-last
export default function MaintenanceNotifyReducer(state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_NOTIFY_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAINTENANCE_NOTIFY_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MAINTENANCE_NOTIFY_POST:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_NOTIFY_PUT:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_NOTIFY_DELETE:
      return { isLoading: false, isCompleted: true };
    case MAINTENANCE_NOTIFY_RESET:
      return { JOB: null, isLoading: true, isCompleted: true };
    case MAINTENANCE_NOTIFY_LOADING:
      return { isLoading: true, isCompleted: true };
    case MAINTENANCE_NOTIFY_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
