export const EMPLOYEE_STATUS = {
  PUNISH: {
    status_code: 'PUNISH',
    description: 'โดนลงโทษ',
    color: 'red',
  },

  NORMAL: {
    status_code: 'NORMAL',
    description: 'ปกติ',
    color: 'green',
  },
  ADMONISH: {
    status_code: 'ADMONISH',
    description: 'ตักเตือน',
    color: 'yellow',
  },
  DEPART: {
    status_code: 'DEPART',
    description: 'ลาออก',
    color: 'gray',
  },
};

export default EMPLOYEE_STATUS;
