import _ from 'lodash';
import * as actions from '../../redux/actions';
import { PROCESS_TXN_TYPE } from '../constants';

export const handleReturnMaterial = async ({
  dispatch,
  data,
  me,
  maintenanceOrderId,
}) => {
  try {
    const filteredDataMaterial = _.filter(
      data?.material,
      (eachMaterial) => eachMaterial?.enable_return === true,
    );

    // Validation
    const falseArray = _.map(filteredDataMaterial, (_material) => {
      if (parseFloat(_material?.amount) < 0) {
        window.alert('ไม่สามารถคืนวัสดุเป็นจำนวนติดลบได้');
        return false;
      }

      return true;
    });

    // กรณีที่เลือกจำนวนเป็น 0 และ lotID เป็นไม่เลือกล็อตใด ทุกอัน หรือ มันเท่ากับไม่ได้คืนอะไรเลย
    const isEmptyRequest = _.filter(
      filteredDataMaterial,
      // prettier-ignore
      (_material) => parseFloat(_material?.amount) === 0 && _material?.material_stock_lot === 'NO',
    );

    if (_.size(isEmptyRequest) >= _.size(filteredDataMaterial)) {
      window.alert('ท่านไม่ได้เลือกคืนวัสดุชิ้นใด');
    }

    if (
      !_.includes(falseArray, false) &&
      _.size(isEmptyRequest) < _.size(filteredDataMaterial)
    ) {
      // Create List of Lot that must be update
      const updateMaterialList = [];
      _.map(data.material, (eachMaterial) => {
        _.map(eachMaterial?.lot, (eachMaterialLot) => {
          if (parseFloat(eachMaterialLot?.amount) > 0) {
            updateMaterialList.push(eachMaterialLot);
          }
        });
      });

      // Create Payload
      const payload = {
        ...data,
        increaseMode: true,
        materialLots: updateMaterialList,
        request_name: `${me?.userData?.firstname} ${me?.userData?.lastname}`,
        request_department:
          me?.userData?.department?._id || me?.userData?.department,
        remark: data?.remark,
        employee: me?.userData?._id,
        employeeId: me?.userData?._id,
        employee_name: `${me?.userData?.firstname || '-'} ${
          me?.userData?.lastname || ''
        }`,
        maintenance_order: maintenanceOrderId,
        transaction_type: PROCESS_TXN_TYPE.MATERIAL_RETURN.status_code,
        direction: false,
      };

      console.log('Payload', payload);

      await dispatch(actions.materialStockLotIncrease(payload));

      window.alert('คืนวัสดุสำเร็จ');
    }
  } catch (error) {
    console.log('Error on Return Material ', error);
    window.alert('คืนวัสดุไม่สำเร็จ');
  }
};

export default handleReturnMaterial;
