import {
  WHEEL_MAINTENANCE_HISTORY_ALL,
  WHEEL_MAINTENANCE_HISTORY_GET,
  WHEEL_MAINTENANCE_HISTORY_POST,
  WHEEL_MAINTENANCE_HISTORY_PUT,
  WHEEL_MAINTENANCE_HISTORY_DELETE,
  WHEEL_MAINTENANCE_HISTORY_LOADING,
  WHEEL_MAINTENANCE_HISTORY_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const wheelMaintenanceHistoryCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-history`,
      {
        ...payload,
      },
    );
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_ERROR });
  }
};

export const wheelMaintenanceHistoryAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, vehicleId = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-history?name=${name}&size=${size}&page=${page}&vehicleId=${vehicleId}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_MAINTENANCE_HISTORY_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_ERROR });
  }
};

export const wheelMaintenanceHistoryGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-history/${id}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_MAINTENANCE_HISTORY_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_ERROR });
  }
};

export const wheelMaintenanceHistoryPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-history/${id}`,
      payload,
    );
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_ERROR });
  }
};
export const wheelMaintenanceHistoryDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-history/${id}`,
    );
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_MAINTENANCE_HISTORY_ERROR });
  }
};
