export const MAINTENANCE_ORDER_STATUS = {
  NEW: {
    status_code: 'NEW',
    description: 'รายการใหม่',
  },
  PENDING: {
    status_code: 'PENDING',
    description: 'รอดำเนินการ',
  },
  APPROVE: {
    status_code: 'APPROVE',
    description: 'อนุมัติ',
  },
  SUCCESS: {
    status_code: 'SUCCESS',
    description: 'สำเร็จ',
  },
  CENCLE: {
    status_code: 'CENCLE',
    description: 'ยกเลิก',
  },
};
export default MAINTENANCE_ORDER_STATUS;
