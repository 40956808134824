import {
  ZONE_PRICE_ALL,
  ZONE_PRICE_GET,
  ZONE_PRICE_DELETE,
  ZONE_PRICE_PUT,
  ZONE_PRICE_POST,
  ZONE_PRICE_LOADING,
  ZONE_PRICE_ERROR,
  ZONE_PRICE_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const zonePriceCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: ZONE_PRICE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/zone-price`,
      payload,
    );
    dispatch({ type: ZONE_PRICE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ZONE_PRICE_ERROR });
    throw new Error(error);
  }
};

export const zonePriceAll =
  ({ size = '', page = 1, name = '', job = '' }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/zone-price?size=${size}&page=${page}&name=${name}&job=${job}`,
      );
      if (status === 200) {
        dispatch({ type: ZONE_PRICE_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: ZONE_PRICE_ERROR });
      throw new Error(error);
    }
  };

export const zonePriceGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/zone-price/${id}`,
    );
    if (status === 200) {
      dispatch({ type: ZONE_PRICE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ZONE_PRICE_ERROR });
    throw new Error(error);
  }
};

export const zonePricePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: ZONE_PRICE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/zone-price/${id}`,
      payload,
    );
    dispatch({ type: ZONE_PRICE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ZONE_PRICE_ERROR });
    throw new Error(error);
  }
};
export const zonePriceDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: ZONE_PRICE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/zone-price/${id}`,
    );
    dispatch({ type: ZONE_PRICE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ZONE_PRICE_ERROR });
    throw new Error(error);
  }
};

export const zonePriceReset = () => async (dispatch) => {
  try {
    dispatch({ type: ZONE_PRICE_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: ZONE_PRICE_ERROR });
    throw new Error(error);
  }
};
