import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
// eslint-disable-next-line import/order
import { useHistory } from 'react-router-dom';
import { DetailDeliveryOrderHistory } from '../../components/Report';
// eslint-disable-next-line import/order
import { DeliveryOrderTable } from '../../components/Table';

export default function TabfilterDeliveryOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const deliveryOrders = useSelector((state) => state.deliveryOrders);
  const information = useSelector((state) => state.information);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  const getDeliveryOrders = () =>
    dispatch(actions.deliveryOrderAll({ jobStatus: true, page, size }));

  useEffect(() => {
    getDeliveryOrders();
    return () => {};
  }, []);

  useEffect(() => {
    setTotal(deliveryOrders?.total);

    return () => {};
  }, [deliveryOrders]);

  const tableProps = {
    page,
    size,
    total,
    handleChangePage: (evnet, newPage) => {
      setPage(newPage + 1);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleReady: async (id) => {
      try {
        const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะเป็น พร้อม');
        if (confirm) {
          await dispatch(actions.deliveryOrderPut(id, { ready: true }));
          getDeliveryOrders();
        }
      } catch (error) {
        alert('แก้ไขคำสั่งจัดส่งไม่ถูกต้อง', error?.message);
      }
    },
    handleCancle: async (id) => {
      try {
        await dispatch(actions.deliveryOrderPut(id, { deleted: true }));
        getDeliveryOrders();
      } catch (error) {
        alert('แก้ไขคำสั่งจัดส่งไม่ถูกต้อง', error?.message);
      }
    },
    handleToEditPage: (id) => {
      history.push(`/tms/delivery-order/edit/${id}`);
    },
    handleToInfoPage: (id) => {
      history.push(`/tms/delivery-order/detail/${id}`);
    },
    handlePrint: (data) => {
      DetailDeliveryOrderHistory(data, information);
    },
  };

  const renderTable = () => (
    <div className="my-2">
      <DeliveryOrderTable deliveryOrders={deliveryOrders} {...tableProps} />
    </div>
  );
  return <div>{renderTable()}</div>;
}
