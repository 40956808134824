/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import currencyFormatterTH from '../../utils/functions/currencyFormat';
import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

const genMaterialTransaction = (data) => {
  return _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.lot_number || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.material?.type_code || ''} ${
        _data?.lotId?.material?.name
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.lotId?.price)} บาท`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity)} ${
        _data?.lotId?.material?.unit
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.lotId?.production_date)
        .locale('th')
        .format('DD MMMM BBBB')}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.lotId?.expiration_date)
        .locale('th')
        .format('DD MMMM BBBB')}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);
};

export const DetailMaterialHTypeAddReport = (
  materialTransaction,
  information,
) => {
  const materialData = genMaterialTransaction(
    materialTransaction?.material_stock_lot,
  );
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 100, 40, 50],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ประวิติการจัดการวัสดุ',
    },
    header: ReportHeader(information),
    content: [
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: `${materialTransaction?.material_transaction_type?.name}`,
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['15%', '37%', '15%', '37%'],
          heights: [15],
          body: [
            [
              {
                text: 'วันที่ซื้อ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(
                  materialTransaction?.material_stock_lot[0]?.lotId
                    ?.purchase_order?.purchase_date ||
                    materialTransaction?.material_stock_lot[0]?.lotId
                      ?.recording_date,
                )
                  .locale('th')
                  .format('DD MMMM BBBB')} เวลา ${new Date(
                  materialTransaction?.material_stock_lot[0]?.lotId
                    ?.purchase_order?.purchase_date ||
                    materialTransaction?.material_stock_lot[0]?.lotId
                      ?.recording_date,
                ).toLocaleTimeString('th')} น.`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'วันที่รับวัสดุ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(
                  materialTransaction?.material_stock_lot[0]?.lotId
                    ?.receipt_date,
                )
                  .locale('th')
                  .format('DD MMMM BBBB')} `,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เลขที่บิล',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  materialTransaction?.material_stock_lot[0]?.lotId
                    ?.bill_number || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'คู่ค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  materialTransaction?.material_stock_lot[0]?.lotId?.supplier
                    ?.name || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'คลัง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${materialTransaction?.place?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ผู้รับวัสดุ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  materialTransaction?.material_stock_lot[0]?.lotId
                    ?.recipient_name || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'แหล่งวัสดุ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  materialTransaction?.material_stock_lot[0]?.lotId?.source ||
                  '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'แผนกผู้สั่งซื้อ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  materialTransaction?.material_stock_lot[0]?.lotId
                    ?.order_department?.name || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 15, 30, 0],
        style: 'tableExample',
        table: {
          widths: ['8%', '15%', '21%', '15%', '15%', '20%', '20%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อวัสดุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ราคา',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่ผลิต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่หมดอายุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...materialData,
          ],
        },
      },
      {
        alignment: '่justify',
        margin: [0, 20, 0, 10],
        text: `หมายเหตุ : ${materialTransaction?.remark}`,
        fontSize: '10',
      },
    ],
  };
  console.log('docDefinition', docDefinition);
  pdfMake.createPdf(docDefinition).open();
};
