export const HeaderMaintenanceReportType2 = (info) => [
  {
    alignment: 'center',
    margin: [0, 0, 0, 12],
    text: [
      {
        text: `${info?.owner?.name}\n`,
        fontSize: '18',
        bold: true,
      },
      {
        text: `${info?.owner?.address?.house_number || ''} ${
          info?.owner?.address?.village_number
            ? `หมู่ที่${info?.owner?.address?.village_number}`
            : ''
        } ${info?.owner?.address?.road || ''} ${
          info?.owner?.address?.subdistrict || ''
        } ${info?.owner?.address?.district || ''} ${
          info?.owner?.address?.province || ''
        } ${info?.owner?.address?.postcode || ''}\n`,
        fontSize: '10',
      },
      {
        text: `โทร. ${info?.owner.telephone || '-'} แฟกซ์ ${
          info?.owner.fax || '-'
        }\n`,
        fontSize: '10',
      },
      {
        text: `อีเมล ${info?.owner?.email || '-'} เลขที่ผู้เสียภาษี ${
          info?.owner?.tax || '-'
        }\n`,
        fontSize: '10',
      },
    ],
  },
];
export default HeaderMaintenanceReportType2;
