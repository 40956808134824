import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { addressToString } from '../../utils/functions';

export default function DetailMaterialTransaction({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const information = useSelector((state) => state.information);

  const {
    setting: { ims: imsSetting },
  } = information;

  useEffect(() => {
    dispatch(actions.materialTransactionGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const displayAddMaterialTransaction = () => (
    <TableContainer>
      <Table size="medium" className="border-lg mt-2">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">หมายเลขอ้างอิงของระบบ</div>
            </TableCell>
            {imsSetting?.display?.lot_number && (
              <TableCell>
                <div className="font-bold"> หมายเลขล็อต</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> ชื่อวัสดุ</div>
            </TableCell>
            {imsSetting?.display?.cost && (
              <TableCell>
                <div className="font-bold"> ราคา</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(materialTransaction?.material_stock_lot) ? (
            materialTransaction?.material_stock_lot.map((_material, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{_material?.lotId?.running_number}</TableCell>
                {imsSetting?.display?.lot_number && (
                  <TableCell>{_material?.lotId?.lot_number || '-'}</TableCell>
                )}
                <TableCell>
                  <div>
                    <span className="font-bold">
                      {_material?.lotId?.material?.type_code}
                    </span>{' '}
                    <span>{_material?.lotId?.material?.name}</span>
                  </div>
                </TableCell>
                {imsSetting?.display?.cost && (
                  <TableCell>{_material?.lotId?.price} บาท</TableCell>
                )}
                <TableCell>
                  {_material?.quantity} {_material?.lotId?.material?.unit}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const displayDescMaterialTransaction = () => (
    <TableContainer>
      <Table size="medium" className="border-lg mt-2">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            {imsSetting?.display?.lot_number && (
              <TableCell>
                <div className="font-bold"> หมายเลขล็อต</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> ชื่อวัสดุ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อคลัง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> คงเหลือ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(materialTransaction?.material_stock_lot) ? (
            materialTransaction?.material_stock_lot.map((_material, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                {imsSetting?.display?.lot_number && (
                  <TableCell>{_material?.lotId?.lot_number || '-'}</TableCell>
                )}
                <TableCell>
                  <div>
                    <span className="font-bold">
                      {_material?.lotId?.material?.type_code}
                    </span>{' '}
                    <span>{_material?.lotId?.material?.name}</span>
                  </div>
                </TableCell>
                <TableCell>{_material?.lotId?.place?.name}</TableCell>
                <TableCell>
                  {_material?.quantity} {_material?.lotId?.material?.unit}
                </TableCell>
                <TableCell>
                  {_material?.amount} {_material?.lotId?.material?.unit}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetailAddTransaction = () => (
    <div className="flex flex-wrap divide-y">
      <div className="w-full py-4 text-lg font-semibold ">{'รายละเอียด'}</div>
      {<div className="w-1/2 py-4 px-2 font-semibold ">{'วันที่ซื้อ'}</div>}
      {materialTransaction?.material_stock_lot[0]?.lotId?.purchase_order
        ?._id ? (
        <div className="w-1/2 py-4 ">
          {' '}
          {dayjs(
            materialTransaction?.material_stock_lot[0]?.lotId?.purchase_order
              ?.purchase_date,
          )
            .locale('th')
            .format('DD MMM BBBB')}{' '}
          เวลา{' '}
          {new Date(
            materialTransaction?.material_stock_lot[0]?.lotId?.purchase_order?.purchase_date,
          ).toLocaleTimeString('th')}{' '}
          น.
        </div>
      ) : (
        <div className="w-1/2 py-4 ">
          {' '}
          {dayjs(
            materialTransaction?.material_stock_lot[0]?.lotId?.recording_date,
          )
            .locale('th')
            .format('DD MMM BBBB')}{' '}
          เวลา{' '}
          {new Date(
            materialTransaction?.material_stock_lot[0]?.lotId?.recording_date,
          ).toLocaleTimeString('th')}{' '}
          น.
        </div>
      )}
      <div className="w-1/2 py-4 px-2 font-semibold ">{'วันที่รับวัสดุ'}</div>
      <div className="w-1/2 py-4 ">
        {' '}
        {dayjs(materialTransaction?.material_stock_lot[0]?.lotId?.receipt_date)
          .locale('th')
          .format('DD MMM BBBB')}{' '}
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold ">{'ประเภท'}</div>
      <div className="w-1/2 py-4 text-green-500">{`${
        materialTransaction?.material_transaction_type?.name || '-'
      }`}</div>
      <div className="w-1/2 py-4 px-2 font-semibold ">{'คลัง'}</div>
      <div className="w-1/2 py-4 text-blue-500">{`${
        materialTransaction?.place?.name || '-'
      }`}</div>
      <div className="w-1/2 py-4 px-2 font-semibold ">{'เลขที่บิล'}</div>
      <div className="w-1/2 py-4 ">{`${
        materialTransaction?.material_stock_lot[0]?.lotId?.bill_number || '-'
      }`}</div>
      {materialTransaction?.material_stock_lot[0]?.lotId?.supplier?._id ? (
        <div className="w-1/2 py-4 px-2 font-semibold ">{'คู่ค้า'}</div>
      ) : (
        <div></div>
      )}
      {materialTransaction?.material_stock_lot[0]?.lotId?.supplier?._id ? (
        <div className="w-1/2 py-4">
          <div className="bg-gray-100 p-3 rounded-md text-sm">
            <div>
              <b>ชื่อ : </b>{' '}
              {materialTransaction?.material_stock_lot[0]?.lotId?.supplier
                ?.name || ' - '}
            </div>
            <div>
              <b>อีเมล : </b>
              {materialTransaction?.material_stock_lot[0]?.lotId?.supplier
                ?.email || ' - '}
            </div>
            <div>
              <b>โทร : </b>
              {materialTransaction?.material_stock_lot[0]?.lotId?.supplier
                ?.phone_number || ' - '}
            </div>
            <div>
              <b>ที่อยู่ : </b>
              {addressToString(
                materialTransaction?.material_stock_lot[0]?.lotId?.supplier
                  ?.address,
              ) || ' - '}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {materialTransaction?.material_stock_lot[0]?.lotId?.source ? (
        <div className="w-1/2 py-4 px-2 font-semibold ">{'แหล่งวัสดุ'}</div>
      ) : (
        <div></div>
      )}
      {materialTransaction?.material_stock_lot[0]?.lotId?.source ? (
        <div className="w-1/2 py-4 ">{`${
          materialTransaction?.material_stock_lot[0]?.lotId?.source || '-'
        }`}</div>
      ) : (
        <div></div>
      )}

      <div className="w-1/2 py-4 px-2 font-semibold ">{'ผู้รับวัสดุ'}</div>
      <div className="w-1/2 py-4 ">
        {`${materialTransaction?.material_stock_lot[0]?.lotId?.recipient_name}`}
      </div>
      {materialTransaction?.material_stock_lot[0]?.lotId?.purchase_order
        ?._id ? (
        <div></div>
      ) : (
        <div className="w-1/2 py-4 px-2 font-semibold ">
          {'แผนกผู้สั่งซื้อ'}
        </div>
      )}
      {materialTransaction?.material_stock_lot[0]?.lotId?.purchase_order
        ?._id ? (
        <div></div>
      ) : (
        <div className="w-1/2 py-4 ">
          {`แผนก${materialTransaction?.material_stock_lot[0]?.lotId?.order_department?.name}`}
        </div>
      )}
      <div className="w-1/2 py-4 px-2 font-semibold ">{'หมายเหตุ'}</div>
      <div className="w-1/2 py-4 ">{`${
        materialTransaction?.remark || ''
      }`}</div>
    </div>
  );

  const renderDetailDescTransaction = () => (
    <div className="flex flex-wrap divide-y">
      <div className="w-full py-4 text-lg font-semibold ">{'รายละเอียด'}</div>
      <div className="w-1/2 py-4 px-2 font-semibold ">{'วันที่เบิกวัสดุ'}</div>
      <div className="w-1/2 py-4 ">
        {' '}
        {dayjs(materialTransaction?.createdAt)
          .locale('th')
          .format('DD MMM BBBB')}{' '}
        เวลา {new Date(materialTransaction?.createdAt).toLocaleTimeString('th')}{' '}
        น.
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold ">{'ประเภท'}</div>
      <div className="w-1/2 py-4 text-red-600">{`${
        materialTransaction?.material_transaction_type?.name || '-'
      }`}</div>

      <div className="w-1/2 py-4 px-2 font-semibold ">{'ผู้เบิก'}</div>
      <div className="w-1/2 py-4 ">
        <div>{`${materialTransaction?.request_name}`}</div>
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold ">{'แผนกที่ขอเบิก'}</div>
      {materialTransaction?.material_withdraw?._id ? (
        <div className="w-1/2 py-4 ">
          {`แผนก${
            materialTransaction?.material_withdraw?.receive_department?.name ||
            materialTransaction?.receive_department?.name
          }`}
        </div>
      ) : (
        <div className="w-1/2 py-4 ">
          {`แผนก${materialTransaction?.request_department?.name}`}
        </div>
      )}
      <div className="w-1/2 py-4 px-2 font-semibold ">{'หมายเหตุ'}</div>
      <div className="w-1/2 py-4 ">{`${
        materialTransaction?.remark || ''
      }`}</div>
    </div>
  );

  const renderDetail = () => {
    if (materialTransaction?.material_transaction_type?.direction === 'add') {
      console.log(materialTransaction);
      return (
        <div>
          <Card className="p-4 ">
            {renderDetailAddTransaction()}
            <div className="w-full  py-4 ">
              {displayAddMaterialTransaction()}
            </div>
          </Card>
        </div>
      );
    }
    return (
      <div>
        <Card className="p-4 ">
          <div className="flex flex-wrap divide-y"></div>
          {renderDetailDescTransaction()}
          <div className="w-full  py-4 ">
            {displayDescMaterialTransaction()}
          </div>
        </Card>
      </div>
    );
  };
  if (materialTransaction.isLoading || materialTransaction.rows) {
    return <Loading />;
  }
  if (!materialTransaction.isLoading && materialTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderDetail()}
      </div>
    );
  }
  return <Error />;
}

DetailMaterialTransaction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialTransaction.defaultProps = {
  title: '',
  subtitle: '',
};
