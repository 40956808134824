import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from '@mui/material';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { QuickMenuForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function QuickMenu({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const { handleSubmit, control } = useForm({
    defaultValues: { mobile_quick_menu: information?.mobile_quick_menu },
  });

  useEffect(() => {
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      await dispatch(actions.informationPut(information._id, data));
      await dispatch(actions.informationAll());
      alert('สำเร็จ');
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <Card className="p-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <QuickMenuForm control={control} />
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </Card>
  );

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
QuickMenu.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuickMenu.defaultProps = {
  title: '',
  subtitle: '',
};

export default QuickMenu;
