import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Card, Backdrop, Button, Fade, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MapPin as MapPinIcon } from 'react-feather';
import Map from '../Map';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

export default function AlternateTimeline({ messages }) {
  const [open, setOpen] = useState(false);
  const [geo, setGeo] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setGeo(null);
  };
  const renderFirstMessageItem = (message) => (
    <TimelineItem>
      <TimelineOppositeContent color="text.secondary">
        <div className={'p-2'}>
          {`${dayjs(message.createdAt).format('DD MMMM BBBB HH:mm a')}`}
        </div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
      </TimelineSeparator>
      <TimelineContent>
        <Card className="p-2">
          <div>
            <div className="font-semibold">{message?.message?.name}</div>
            <div className="text-sm">{message?.message?.description}</div>
            <div className="text-sm">{message?.description}</div>
          </div>
          <div className="py-1">
            <Button
              variant="contained"
              size="small"
              startIcon={<MapPinIcon width="16" height="16" />}
              onClick={() => {
                setGeo({
                  lat: parseFloat(message.lat),
                  lng: parseFloat(message.lng),
                });
                handleOpen();
              }}
            >
              ตำแหน่ง
            </Button>
          </div>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
  const renderMessageItem = (message) => (
    <TimelineItem>
      <TimelineOppositeContent color="text.secondary">
        <div className={'p-2'}>
          {`${dayjs(message.createdAt).format('DD MMMM BBBB HH:mm a')}`}
        </div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot />
      </TimelineSeparator>
      <TimelineContent>
        <Card className="p-2">
          <div>
            <div className="font-semibold">{message?.message?.name}</div>
            <div className="text-sm">{message?.message?.description}</div>
            <div className="text-sm">{message?.description}</div>
          </div>
          <div className="py-1">
            <Button
              variant="contained"
              size="small"
              startIcon={<MapPinIcon width="16" height="16" />}
              onClick={() => {
                setGeo({
                  lat: parseFloat(message.lat),
                  lng: parseFloat(message.lng),
                });
                handleOpen();
              }}
            >
              ตำแหน่ง
            </Button>
          </div>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2"> ตำแหน่ง</div>
          <div className="py-2">
            {geo && <Map lat={geo.lat} lng={geo.lng} />}
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  return (
    <div>
      {renderModal()}
      <Timeline>
        {_.map(messages, (message, index) => {
          if (index === 0) {
            return <div key={index}>{renderFirstMessageItem(message)}</div>;
          }
          return <div key={index}>{renderMessageItem(message)}</div>;
        })}
      </Timeline>
    </div>
  );
}
AlternateTimeline.propTypes = {
  messages: PropTypes.array,
};
