import {
  BILL_BOOKING_ALL,
  BILL_BOOKING_GET,
  BILL_BOOKING_DELETE,
  BILL_BOOKING_PUT,
  BILL_BOOKING_POST,
  BILL_BOOKING_LOADING,
  BILL_BOOKING_ERROR,
  BILL_BOOKING_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const billBookingCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: BILL_BOOKING_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/bill-booking`,
      payload,
    );
    dispatch({ type: BILL_BOOKING_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_BOOKING_ERROR });
    throw new Error(error);
  }
};

export const billBookingAll =
  ({
    size = '',
    page = 1,
    name = '',
    completed = '',
    type = '',
    date = '',
    startDate = '',
    endDate = '',
    dashboard = false,
    deleted = '',
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/bill-booking?size=${size}&page=${page}&name=${name}&completed=${completed}&type=${type}&date=${date}&start_date=${startDate}&end_date=${endDate}&deleted=${deleted}&dashboard=${dashboard}`,
      );
      if (status === 200) {
        dispatch({ type: BILL_BOOKING_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: BILL_BOOKING_ERROR });
      throw new Error(error);
    }
  };

export const billBookingGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/bill-booking/${id}`,
    );
    if (status === 200) {
      dispatch({ type: BILL_BOOKING_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_BOOKING_ERROR });
    throw new Error(error);
  }
};

export const billBookingPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: BILL_BOOKING_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/bill-booking/${id}`,
      payload,
    );
    dispatch({ type: BILL_BOOKING_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_BOOKING_ERROR });
    throw new Error(error);
  }
};
export const billBookingDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: BILL_BOOKING_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/bill-booking/${id}`,
      { deleted: true },
    );
    dispatch({ type: BILL_BOOKING_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_BOOKING_ERROR });
    throw new Error(error);
  }
};

export const billBookingReset = () => async (dispatch) => {
  try {
    dispatch({ type: BILL_BOOKING_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: BILL_BOOKING_ERROR });
    throw new Error(error);
  }
};
