import {
  WHEEL_ALL,
  WHEEL_GET,
  WHEEL_POST,
  WHEEL_PUT,
  WHEEL_DELETE,
  WHEEL_LOADING,
  WHEEL_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const wheelCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_LOADING });

    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/wheel`, {
      ...payload,
    });
    dispatch({ type: WHEEL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_ERROR });
  }
};

export const wheelAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      statusWheel = '',
      type = '',
      vehicle = '',
      supply = '',
    } = params;

    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel?name=${name}&size=${size}&page=${page}&statusWheel=${statusWheel}&type=${type}&vehicle=${vehicle}&supply=${supply}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_ERROR });
  }
};

export const wheelGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel/${id}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_ERROR });
  }
};

export const wheelPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/wheel/${id}`,
      payload,
    );
    dispatch({ type: WHEEL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_ERROR });
  }
};
export const wheelDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/wheel/${id}`,
    );
    dispatch({ type: WHEEL_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_ERROR });
  }
};
