import {
  WHEEL_REGISTER_ALL,
  WHEEL_REGISTER_GET,
  WHEEL_REGISTER_POST,
  WHEEL_REGISTER_PUT,
  WHEEL_REGISTER_DELETE,
  WHEEL_REGISTER_LOADING,
  WHEEL_REGISTER_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const wheelRegisterCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_REGISTER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-register`,
      {
        ...payload,
      },
    );
    dispatch({ type: WHEEL_REGISTER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_REGISTER_ERROR });
  }
};

export const wheelRegisterAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-register?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_REGISTER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_REGISTER_ERROR });
  }
};

export const wheelRegisterGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-register/${id}`,
    );
    if (status === 200) {
      dispatch({ type: WHEEL_REGISTER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_REGISTER_ERROR });
  }
};

export const wheelRegisterPut = (id, payload) => async (dispatch) => {
  console.log('payload', payload);
  try {
    dispatch({ type: WHEEL_REGISTER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-register/${id}`,
      payload,
    );
    dispatch({ type: WHEEL_REGISTER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_REGISTER_ERROR });
  }
};
export const wheelRegisterDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: WHEEL_REGISTER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/wheel-maintenance-register/${id}`,
    );
    dispatch({ type: WHEEL_REGISTER_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WHEEL_REGISTER_ERROR });
  }
};
