import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';

function AttachMachineryForm({ control, machinery }) {
  return (
    <div className="w-full">
      <div className=" py-2">
        <Controller
          name={'machinery'}
          control={control}
          defaultValue={machinery?.rows?.[0]}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              ref={field.ref}
              defaultValue={field.value}
              options={machinery?.rows}
              autoHighlight
              getOptionLabel={(option) => ` ${option?.name}`}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    '& > span': { mr: '10px', fontSize: 18 },
                  }}
                  {...props}
                >
                  {` ${option?.name}`}
                </Box>
              )}
              onChange={(e, value) => {
                field.onChange(value._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="เครื่องจักร"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          )}
        />
      </div>
    </div>
  );
}

AttachMachineryForm.propTypes = {
  machinery: PropTypes.object,
};

export default AttachMachineryForm;
