import React from 'react';
import _ from 'lodash';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { showMaintenanceStatus } from '../../../utils/functions';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const MaintenanceTransactionTable = ({ orderHistory, page = 1, size = 10 }) => (
  <div className="max-h-72 overflow-y-auto">
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">วันที่แจ้งซ่อม</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">วันที่ซ่อมสำเร็จ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> เลขไมล์</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ประเภทการแจ้งซ่อม</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">รายละเอียดการซ่อม</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">สถานะ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(orderHistory) ? (
              orderHistory.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {(page - 1) * size + index + 1}
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-wrap items-center">
                      {`${
                        row.createdAt
                          ? dayjs(row?.createdAt).format('DD MMMM BBBB HH:mm')
                          : ''
                      }`}
                    </div>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <div className="flex flex-wrap items-center">
                      {`${
                        row.createdAt
                          ? dayjs(row?.updatedAt).format('DD MMMM BBBB HH:mm')
                          : ''
                      }`}
                    </div>
                  </TableCell>
                  <TableCell>{`${
                    row?.mileage?.toLocaleString() || ' - '
                  }`}</TableCell>
                  <TableCell>{`${
                    row?.maintenance_type?.name || ' - '
                  }`}</TableCell>
                  <TableCell>
                    <div>
                      {_.map(row.maintenance_topic, (topic) => (
                        <div>
                          {topic?.topic?.name}{' '}
                          {`${
                            topic.price
                              ? `${topic.price.toLocaleString()} บาท`
                              : ''
                          }`}
                        </div>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>{showMaintenanceStatus(row?.status)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </div>
);

MaintenanceTransactionTable.propTypes = {
  orderHistory: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
};

export default MaintenanceTransactionTable;
