import React from 'react';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export function SettingCMMSForm({ control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2 "> การตั้งค่ากระบวนการแจ้งซ่อม</div>
      <div className="w-1/2 py-2 px-2 ">เมนูส่วนต่างๆ</div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="setting.cmms.use_spared_part"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="เปิดใช้งานส่วนของ Spared Part Management มีการเบิก/คืน วัสดุจากคลัง"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.maintenance_vehicle"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="เปิดใช้งานส่วนของซ่อมรถ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.maintenance_machinery"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="เปิดใช้งานส่วนของซ่อมเครื่องจักร"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.show_maintenance_type"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงประเภทการแจ้งซ่อม"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.default_show_topic_in_self_maintenance"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงให้เลือกหัวข้อการบำรุงรักษา ในการแจ้งแบบซ่อมแบบซ่อมบำรุงเอง"
              />
            )}
          />{' '}
          <Controller
            control={control}
            name="setting.cmms.show_price_in_maintenance_topic"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงราคาในหัวข้อบำรุงรักษา"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.show_detail_in_maintenance_topic"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ให้กรอกรายละเอียดเพิ่มเติมในแต่ละหัวข้อบำรุงรักษา"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.must_approve_material_request"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ต้องใช้การอนุมัติการเบิกวัสดุ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.automatic_work_accept"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="อนุมัติการซ่อมโดยอัตโนมัติ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.show_maintenance_level"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงระดับการแจ้งซ่อม"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.attach_maintener"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ใส่ผู้ดำเนินการซ่อม"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.attach_time"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ใส่เวลาในการเริ่ม/สิ้นสุดการซ่อม"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.attach_topic_amount"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="ใส่จำนวนในหัวข้อการซ่อมแต่ละหัวข้อ"
              />
            )}
          />
        </div>
      </div>
      <div className="w-1/2 py-2 px-2 ">
        ส่วนประกอบในรายงานการแจ้งซ่อมแต่ละรายการ
      </div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="setting.cmms.maintenance_report.topic_price"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงราคาของการซ่อมแต่ละหัวข้อ"
              />
            )}
          />
        </div>
        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="setting.cmms.maintenance_report.material_source"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงแหล่งที่มาของวัสดุ"
              />
            )}
          />
        </div>
        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="setting.cmms.maintenance_report.show_total_price"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงราคารวมทั้งหมด"
              />
            )}
          />
        </div>
      </div>

      <div className="w-1/2 py-2 px-2 ">ส่วนประกอบในใบแจ้งซ่อม</div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.sign_informer"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงช่องให้ลงชื่อผู้แจ้ง"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.sign_maintener"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงช่องให้ลงชื่อช่าง"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.sign_allower"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงช่องให้ลงชื่อผู้อนุมัติ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.sign_acceptance"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงช่องให้ลงชื่อผู้รับเครื่องจักรหลังการซ่อม"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.sign_chief_maintenaner"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงช่องให้ลงชื่อหัวหน้าช่าง"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.sign_transaction_creator"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงชื่อผู้ทำรายการ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.show_comment_box"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงพื้นที่สำหรับใส่ความคิดเห็น"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.show_empty_space_topic"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงช่องว่างสำหรับใส่รายการบำรุงรักษาเพิ่มเติม"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.show_empty_space_material"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงช่องว่างสำหรับใส่อะไหล่ / วัสดุ"
              />
            )}
          />
          <Controller
            control={control}
            name="setting.cmms.printed_maintenance_order.show_garage"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox defaultChecked={field.value} />}
                label="แสดงชื่ออู่"
              />
            )}
          />
        </div>
      </div>

      <div className="w-1/2 py-2 px-2 ">รายละเอียดในใบแจ้งซ่อม</div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.printed_maintenance_order.additional_title"
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="หัวข้อใบแจ้งซ่อม"
                  {...field}
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.printed_maintenance_order.additional_remark"
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={3}
                  label="หมายเหตุในใบแจ้งซ่อม"
                  {...field}
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.printed_maintenance_order.chief_maintener_name"
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="ชื่อหัวหน้าช่าง"
                  {...field}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="w-1/2 py-2 px-2 ">ใบแจ้งซ่อมและรายงานที่เปิดใช้งาน</div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.main_maintenance_order"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใบแจ้งซ่อมหลัก"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.maintenance_order_report_2"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใบแจ้งซ่อม แบบที่ 2"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.maintenance_order_report_2_dicut"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใบแจ้งซ่อม แบบที่ 2 (เฉพาะตัวอักษร)"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.main_maintenance_report"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="รายงานการแจ้งซ่อมหลัก"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.material_withdraw_report_1"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใบเบิกวัสดุ รูปแบบที่ 1"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.material_withdraw_report_2"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใบเบิกวัสดุ รูปแบบที่ 2"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.material_withdraw_report_2_dicut"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใบเบิกวัสดุ รูปแบบที่ 2 (เฉพาะตัวอักษร)"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.available_report.maintenance_pickeUp_order_report_2_dicut"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ใบรับสินค้า รูปแบบที่ 2 (เฉพาะตัวอักษร)"
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="w-1/2 py-2 px-2 ">การแสดงโลโก้ในใบแจ้งซ่อม</div>
      <div className="w-1/2 py-2 px-2">
        <div className="flex flex-col w-full">
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.allow_left_logo"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงโลโก้หลัก (ด้านซ้าย)"
                />
              )}
            />
          </div>
          <div className="w-full my-1">
            <Controller
              control={control}
              name="setting.cmms.allow_right_logo"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แสดงโลโก้รอง(ด้านขวา่)"
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SettingCMMSForm.propTypes = {
  control: PropTypes.object,
};

export default SettingCMMSForm;
