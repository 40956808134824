/* eslint-disable react/prop-types */
import React from 'react';
import {
  TextField,
  Paper,
  Box,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import _ from 'lodash';
import WheelMaps from './WheelMaps10Wheel';
import WheelMaps4Wheel from './WheelMaps4Wheel';
import WheelMaps10Wheel from './WheelMaps6Wheel';
import WheelMaps12Wheel from './WheelMaps12Wheel';

export default function VehcleForm({
  vehicle,
  garage,
  control,
  Controller,
  fields,
  append,
  remove,
  create,
  wheelCheck,
  setWheelCheck,
  wheelSupply,
}) {
  const handleDelete = (index) => {
    console.log('index', index);
    remove(index);
  };
  return (
    <div className="mx-auto ">
      <Paper>
        <div className=" p-4 border-b-4 border-indigo-500">
          <div className="flex">
            <div className=" w-full px-1 py-2">
              <Controller
                name={'licence_number'}
                control={control}
                // defaultValue={undefined}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    disableClearable
                    name={field.name}
                    ref={field.ref}
                    // defaultValue={field.value}
                    options={vehicle?.rows}
                    autoHighlight
                    getOptionLabel={(option) =>
                      `ป้ายทะเบียน ${option?.licence_number}`
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          fontSize: 15,
                          '& > span': { mr: '10px', fontSize: 18 },
                        }}
                        {...props}
                      >
                        {`ป้ายทะเบียน ${option?.licence_number}`}
                      </Box>
                    )}
                    onChange={(e, value) => {
                      field.onChange(value._id);
                      setWheelCheck(value.type.wheel);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        size="small"
                        label="ป้ายทะเบียน"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        // helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                )}
              />
            </div>{' '}
          </div>
          {create ? (
            <>
              <div className="flex">
                <div className="w-full px-1 py-2">
                  {/* <Controller
                    name={'maintenanceWheelType'}
                    control={control}
                    // defaultValue={undefined}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        name={field.name}
                        ref={field.ref}
                        defaultValue={field.value}
                        options={['อุบัติเหตุ', 'ตามระยะ']}
                        autoHighlight
                        getOptionLabel={(option) => `${option}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            {` ${option}`}
                          </Box>
                        )}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            size="small"
                            label="ประเภทการซ่อม"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            // helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                          />
                        )}
                      />
                    )}
                  /> */}
                </div>
                {/* <div className="w-full px-1 py-2">
                  <Controller
                    name={'garag'}
                    control={control}
                    // defaultValue={undefined}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        name={field.name}
                        ref={field.ref}
                        // defaultValue={field.value}
                        options={garage?.rows}
                        autoHighlight
                        getOptionLabel={(option) => `${option?.name}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              '& > span': { mr: '10px', fontSize: 18 },
                            }}
                            {...props}
                          >
                            {` ${option?.name}`}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          field.onChange(value._id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            size="small"
                            label="ประเภทการซ่อม"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            // helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                          />
                        )}
                      />
                    )}
                  />
                </div> */}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {wheelCheck ? (
          <div>
            <div>
              <div>
                <h1 className="p-4">เลือกตำแหน่งยาง</h1>
                <div>
                  {wheelCheck === 10 && (
                    <WheelMaps
                      control={control}
                      Controller={Controller}
                      fields={fields}
                      append={append}
                      remove={remove}
                    />
                  )}
                  {wheelCheck === 4 && (
                    <WheelMaps4Wheel
                      control={control}
                      Controller={Controller}
                      fields={fields}
                      append={append}
                      remove={remove}
                    />
                  )}
                  {wheelCheck === 6 && (
                    <WheelMaps10Wheel
                      control={control}
                      Controller={Controller}
                      fields={fields}
                      append={append}
                      remove={remove}
                    />
                  )}
                  {wheelCheck === 12 && (
                    <WheelMaps12Wheel
                      control={control}
                      Controller={Controller}
                      fields={fields}
                      append={append}
                      remove={remove}
                    />
                  )}
                </div>
                <div>
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-aria-label="simple table"
                      >
                        <TableHead className="bg-indigo-500  ">
                          <TableRow>
                            <TableCell>
                              {' '}
                              <h1 className="text-center font-bold text-white">
                                ตำแหน่ง
                              </h1>
                            </TableCell>
                            <TableCell>
                              <h1 className="text-center font-bold text-white">
                                เลขยาง
                              </h1>
                            </TableCell>
                            <TableCell>
                              <h1 className="text-center font-bold text-white">
                                เลขไมล์
                              </h1>
                            </TableCell>
                            <TableCell>
                              <h1 className="text-center font-bold text-white">
                                ความลึกดอกยาง (มิลลิเมตร)
                              </h1>
                            </TableCell>
                            <TableCell>
                              <h1 className="text-center font-bold text-white">
                                ยี่ห้อ
                              </h1>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_.map(fields, (item, index) => (
                            <TableRow
                              key={item?.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <div className="pb-1">
                                  <Controller
                                    name={`wheelArray.${index}.position`}
                                    control={control}
                                    // defaultValue={item?.price}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        id="outlined-basic"
                                        label="ตำแหน่ง"
                                        variant="outlined"
                                        disabled
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div className="py-1">
                                  <Controller
                                    name={`wheelArray.${index}.wheelNumber`}
                                    control={control}
                                    // defaultValue={item?.price}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        label="เลขยาง"
                                        size="small"
                                      />
                                    )}
                                  />
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div className="py-1">
                                  <Controller
                                    name={`wheelArray.${index}.mileageIn`}
                                    control={control}
                                    // defaultValue={item?.price}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        label="เลขไมล์"
                                        size="small"
                                      />
                                    )}
                                  />
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div className="py-1">
                                  <Controller
                                    name={`wheelArray.${index}.tread`}
                                    control={control}
                                    // defaultValue={item?.price}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        type="number"
                                        label="ดอกยาง"
                                        size="small"
                                      />
                                    )}
                                  />
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div className=" px-1 py-2">
                                  <Controller
                                    name={`wheelArray.${index}.supply`}
                                    control={control}
                                    // defaultValue={
                                    //   wheel ? wheel?.supply?._id : ''
                                    // }
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          ยี่ห้อ
                                        </InputLabel>
                                        <Select {...field} label="ยี่ห้อ">
                                          {_.map(wheelSupply?.rows, (each) => (
                                            <MenuItem
                                              key={each?._id}
                                              value={each?._id}
                                            >
                                              {each?.brand}: ขนาด{each?.size}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    )}
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                <Button
                                  color="error"
                                  variant="contained"
                                  className="w-full "
                                  onClick={() => {
                                    handleDelete(index);
                                  }}
                                >
                                  <Delete />
                                  ลบ
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>{' '}
                      </Table>
                    </TableContainer>{' '}
                  </div>
                </div>
                <div className=" flex flex-row justify-end p-4">
                  <Button type="submit" variant="outlined">
                    บันทึก
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center  text-xl p-4">เลือกทะเบียนรถ</div>
        )}
      </Paper>
    </div>
  );
}
