import {
  Table,
  TableContainer,
  TableHead,
  Paper,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Stack,
} from '@mui/material';
import React from 'react';

export default function WheelTypeTable({
  wheelType,
  handleEdit = () => {},
  hanldeDelete = () => {},
}) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <h1 className="font-bold text-center">ลำดับ</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold text-center">ชนิดยาง</h1>
              </TableCell>
              <TableCell>
                <h1 className="font-bold text-center">ดำเนินการ</h1>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wheelType?.total === 0 ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              wheelType?.rows?.map((each, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <h1 className="font-bold text-center">{index + 1}</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold text-center">{each?.name}</h1>
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-center">
                      <div>
                        <Stack spacing={1} direction="row">
                          <Button
                            variant="contained"
                            onClick={() => handleEdit(each._id)}
                          >
                            แก้ไข
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => hanldeDelete(each._id)}
                          >
                            ลบ
                          </Button>
                        </Stack>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
