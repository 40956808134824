import {
  MECHINERY_ALL,
  MECHINERY_GET,
  MECHINERY_POST,
  MECHINERY_PUT,
  MECHINERY_DELETE,
  MECHINERY_LOADING,
  MECHINERY_ERROR,
  MECHINERY_RESET,
} from '../../actions/types';

const initialState = {
  mechinery: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names,
export default function (state = initialState, action) {
  switch (action.type) {
    case MECHINERY_LOADING:
      return { isLoading: true, isCompleted: false };
    case MECHINERY_ERROR:
      return { isLoading: false, isCompleted: false };
    case MECHINERY_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MECHINERY_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MECHINERY_POST:
      return { isLoading: false, isCompleted: true };
    case MECHINERY_PUT:
      return { isLoading: false, isCompleted: true };
    case MECHINERY_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
