/* eslint-disable no-underscore-dangle */
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

export function GarageForm({ errors, garage, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={garage ? garage?.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              required
              helperText={errors?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'address'}
          control={control}
          defaultValue={garage ? garage?.address : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              multiline
              rows={2}
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'tel'}
          control={control}
          defaultValue={garage ? garage?.tel : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเลขโทรศัพท์"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={garage ? garage?.remark : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              multiline
              rows={2}
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <FormControlLabel
          control={
            <Controller
              name={'is_official_service_center'}
              control={control}
              defaultValue={garage ? garage.is_official_service_center : false}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'เป็นศูนย์บริการ'}
        />
      </div>
    </div>
  );
}

GarageForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
  }),
  garage: PropTypes.object,
  control: PropTypes.object,
};

export default GarageForm;
