/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import TrailerForm from '../../components/Forms/WMS/TrailerForm';
import * as actions from '../../redux/actions';
import { CONFIG } from '../../utils/constants';

function CreateTrailer({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const trailerTail = useSelector((state) => state.trailerTail);
  const garage = useSelector((state) => state.garage);
  const wheelSupply = useSelector((state) => state.wheelSupply);

  const { handleSubmit, control, setValue } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'wheelTrailerArray',
  });
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(CONFIG.infiniteLimit);
  const [wheelTailCheck, setwheelTailCheck] = useState();
  console.log('wheelTailCheck', wheelTailCheck);

  useEffect(() => {
    dispatch(
      actions.trailerTailAll({
        name,
        page,
        size: CONFIG.infiniteLimit,
        statusTail: false,
      }),
    );
    dispatch(actions.wheelSupplyAll({}));
    dispatch(actions.garageAll({}));
    return () => {};
  }, [name, page, size]);

  const handleSave = async (data) => {
    const confirm = window.confirm('ยืนยันการบันทึก');
    if (confirm) {
      try {
        const dataSubmit = {
          trailer: data.licence_number,
          wheel: data.wheelTrailerArray,
        };
        console.log('dataSubmit', dataSubmit);
        await dispatch(actions.wheelTrailerTailCreate(dataSubmit));
        await dispatch(
          actions.trailerTailPut(data?.licence_number, { statusTail: true }),
        );
        await dispatch(actions.wheelTrailerTailAll());

        history.push('/wms/trailer');
      } catch (error) {
        alert(error);
      }
    }
  };
  const renderTrailer = () => (
    <div className="pt-4">
      <form onSubmit={handleSubmit(handleSave)}>
        <TrailerForm
          trailerTail={trailerTail}
          control={control}
          setValue={setValue}
          fields={fields}
          append={append}
          remove={remove}
          wheelTailCheck={wheelTailCheck}
          setwheelTailCheck={setwheelTailCheck}
          garage={garage}
          wheelSupply={wheelSupply}
          Controller={Controller}
        />
      </form>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  if (trailerTail.isLoading) {
    return <Loading />;
  }
  if (!trailerTail.isLoading && trailerTail.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderTrailer()}
      </div>
    );
  }
  <h1>404 </h1>;
}

CreateTrailer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default CreateTrailer;
