import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import { ReportHeader } from './common';
import _ from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (Orders) => {
  const productList = _.map(Orders, (order, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: dayjs(order?.start_date).format('DD MMM BB '),
      alignment: 'center',
    },
    {
      text: `หัวรถ${order?.vehicle?.licence_number || '-'}  หางรถ${
        order?.trailer_tail?.licence_number || '-'
      }\n`,
    },

    {
      text: _.map(
        order?.maintenance_topic,
        (topic, index) => `${index + 1}.${topic.topic.name}\n`,
      ),

      alignment: 'left',
    },
    {
      text: order?.total_price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      alignment: 'right',
    },
    { text: order?.status, alignment: 'center' },
  ]);

  return productList;
};

const MaintenanceReportMonthly = async (row, information) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  console.log('info', information);
  console.log('row', row);
  const genItemBody = genBody(row?.maintenance_orders);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 80, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: information?.name,
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'รายงานการแจ้งซ่อม',
                style: 'header',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `ประจำเดือน ${dayjs(row?.month).format('MMMM BBBB')} `,
                style: 'header',
                border: [false, false, false, false],
              },
            ],
            // ...genItemBody,
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '18%', '31%', '26%', '10%', '10%'],
          body: [
            [
              {
                text: 'ลำดับ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วันที่',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ทะเบียนรถ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการซ่อม',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ราคารวม',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'สถานะ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default MaintenanceReportMonthly;
