import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Link } from 'react-router-dom';

export function MainFooter() {
  const [information, setInformation] = useState(null);
  async function fetchInfo() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/information`,
    );
    setInformation(data);
  }

  useEffect(() => {
    if (!information) {
      fetchInfo();
    }
    return () => {};
  }, [information]);

  const renderBottomNavigation = () => (
    <div className="fixed  bottom-0  w-full z-40 ">
      <BottomNavigation showLabels>
        {_.map(information?.mobile_quick_menu, (eachQuickMenu, index) => (
          <BottomNavigationAction
            label={eachQuickMenu?.name}
            key={index}
            LinkComponent={Link}
            to={eachQuickMenu?.link}
            icon={<i className={`fas ${eachQuickMenu?.icon}`}></i>}
          />
        ))}
      </BottomNavigation>
    </div>
  );

  return (
    <div>
      <div className="w-full lg:hidden">
        {!_.isEmpty(information?.mobile_quick_menu) && renderBottomNavigation()}
      </div>
      <footer className="container mx-auto items-center bottom-0 font-sansserif z-10">
        <hr className="mb-4 border-b-1 border-gray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4">
            <div className="text-sm text-gray-600 font-normal py-1 text-center md:text-left">
              Copyright © {new Date().getFullYear()}{' '}
              <a
                href={'https://iarc.psu.ac.th/'}
                className="text-gray-600 hover:text-gray-800 text-sm font-normal py-1"
              >
                {information?.owner?.name}
              </a>
              , <br />
              <a
                href={information?.sponsor?.url}
                className="text-gray-600 hover:text-gray-800 text-sm font-normal py-1"
              >
                {information?.sponsor?.name}
              </a>{' '}
              and Powered By{' '}
              <a
                href="https://iarc.psu.ac.th/"
                className="text-gray-600 hover:text-gray-800 text-sm font-normal py-1"
              >
                IARC PSU
              </a>{' '}
              {process.env.REACT_APP_VERSION_RELEASE}
            </div>
          </div>
          <div className="w-full md:w-8/12 px-4">
            <ul className="flex flex-wrap list-none md:justify-end  justify-center">
              <li>
                <a
                  href="https://iarc.psu.ac.th/"
                  className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                >
                  IARC PSU
                </a>
              </li>
              <li>
                <a
                  href="https://iarc.psu.ac.th/about/"
                  className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/iarc.psu"
                  className="text-gray-700 hover:text-gray-900 text-sm font-normal block py-1 px-3"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>{' '}
    </div>
  );
}

export default MainFooter;
