import {
  COMMENT_ALL,
  COMMENT_GET,
  COMMENT_DELETE,
  COMMENT_PUT,
  COMMENT_POST,
  COMMENT_LOADING,
  COMMENT_ERROR,
  COMMENT_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const commentCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/comment`,
      payload,
    );
    dispatch({ type: COMMENT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentAll =
  ({ size = '', page = 1, maintenanceOrder = '' }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/comment?size=${size}&page=${page}&maintenanceOrder=${maintenanceOrder}`,
      );
      if (status === 200) {
        dispatch({ type: COMMENT_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: COMMENT_ERROR });
      throw new Error(error);
    }
  };

export const commentGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/comment/${id}`,
    );
    if (status === 200) {
      dispatch({ type: COMMENT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/comment/${id}`,
      payload,
    );
    dispatch({ type: COMMENT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};
export const commentDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/comment/${id}`,
    );
    dispatch({ type: COMMENT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};

export const commentReset = () => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: COMMENT_ERROR });
    throw new Error(error);
  }
};
