/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import WheelListRegisterTable from '../../components/Table/WMS/WheelListRegisterTable';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

export default function WheelRegister({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const wheelRegister = useSelector((state) => state.WheelRegister);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const tableProps = {
    name,
    page,
    size,
    setName,
    setPage,
    setSize,
    handleDelete: async (id) => {
      console.log('id', id);
      try {
        const confirm = window.confirm('ยืนยันลบข้อมูล');
        if (confirm) {
          await dispatch(actions.wheelRegisterDelete(id));
          await dispatch(actions.wheelRegisterAll({}));
        }
      } catch (error) {
        alert('เกิดข้อผิดพลาด', error?.message);
      }
    },
    handleEdit: (id) => history.push(`/wms/register/info/${id}`),
  };

  useEffect(() => {
    dispatch(actions.wheelRegisterAll({ name, page, size }));

    return () => {};
  }, [name, page, size]);

  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderTable = () => (
    <WheelListRegisterTable wheelRegister={wheelRegister} {...tableProps} />
  );
  const renderButton = () => (
    <div className="flex justify-end pb-4">
      <Button variant="contained" component={Link} to={'/wms/register/create'}>
        เพิ่ม
      </Button>
    </div>
  );
  return (
    <div>
      {renderViewTitle()}
      {renderButton()}
      {renderTable()}
    </div>
  );
}
WheelRegister.PropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
WheelRegister.defaultProps = {
  title: '',
  subtitle: '',
};
