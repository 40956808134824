/* eslint-disable camelcase */
import React from 'react';
import _ from 'lodash';
import { Card, CardContent } from '@mui/material';

import PropTypes from 'prop-types';

const MaintenanceFileCard = ({ maintenanceOrder_file }) => (
  <div className="my-2">
    {console.log('maintenanceOrder_file', maintenanceOrder_file)};
    <Card>
      <CardContent>
        {' '}
        <div className="font-semibold">ไฟล์แนบประกอบการซ่อม</div>
        <div className="py-4">
          <a href={maintenanceOrder_file?.url} target="_blank">
            <div className="text-blue-600 font-bold">
              {maintenanceOrder_file?.file_name}
            </div>
          </a>
        </div>
      </CardContent>
    </Card>
  </div>
);

export default MaintenanceFileCard;
