import {
  VEHICLETYPES_ALL,
  VEHICLETYPES_GET,
  VEHICLETYPES_DEL,
  VEHICLETYPES_PUT,
  VEHICLETYPES_POST,
  VEHICLETYPES_LOADING,
  VEHICLETYPES_ERROR,
} from '../../actions/types';

const initialState = {
  vehicletype: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case VEHICLETYPES_LOADING:
      return { isLoading: true, isCompleted: true };
    case VEHICLETYPES_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case VEHICLETYPES_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case VEHICLETYPES_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case VEHICLETYPES_POST:
      return { isLoading: false, isCompleted: true };
    case VEHICLETYPES_PUT:
      return { isLoading: false, isCompleted: true };
    case VEHICLETYPES_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
