/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import { Card, Box, Modal, Button } from '@mui/material';
import { BackButton } from '../../components/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import VehicleWheelInfo from '../../components/Forms/WMS/VehicleWheelInfo';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { useForm, Controller } from 'react-hook-form';
import WheelMaintenHistoryVehicleTable from '../../components/Table/WMS/WheelMaintenHistoryVehicleTable';
import _ from 'lodash';
import WheelInfoCard from '../../components/Card/WMS/Wheelinfo';
import AddWheelOnVehicle from '../../components/Forms/WMS/AddWheelOnVehicle';

const style = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function InfoWheelRegister({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [open, setOpen] = useState(false);
  const [addWheel, setAddWheel] = useState(false);
  const [wheelInfo, setWheelInfo] = useState(false);
  const [wheelTypeSelect, setWheelTypeSelect] = useState();
  const [position, setPosition] = useState();
  const handleClose = () => setOpen(false);
  const modalClose = () => setAddWheel(false);
  const wheelRegister = useSelector((state) => state.WheelRegister);
  const wheel = useSelector((state) => state.wheel);
  const wheelTransection = useSelector((state) => state.wheelTransection);
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const { handleSubmit, control } = useForm();

  useEffect(() => {
    dispatch(actions.wheelRegisterGet(id));
    dispatch(actions.wheelSupplyAll({}));

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.wheelTransectionAll({
        vehicle: wheelRegister?.vehicle?._id,
        page,
        size,
      }),
    );
    return () => {};
  }, [wheelRegister, page, size]);
  useEffect(() => {
    dispatch(
      actions.wheelAll({ statusWheel: 'ready', supply: wheelTypeSelect }),
    );
    return () => {};
  }, [wheelTypeSelect]);

  const renderViweTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  const renderInfoVehicle = () => (
    <VehicleWheelInfo
      wheelRegister={wheelRegister}
      setWheelInfo={setWheelInfo}
      setOpen={setOpen}
      setAddWheel={setAddWheel}
      maintain={false}
      wheelInVehicle={wheelRegister?.wheelVehicle}
      addWheelButton={true}
      setPosition={setPosition}
    />
  );

  const propModal = {
    wheel,
    setWheelTypeSelect,
    wheelSupply,
    control,
    Controller,
    position,
  };

  const submitAddWheel = async (data) => {
    const dataSubmit = {
      ...data,
      vehicle: wheelRegister?.vehicle?._id,
      supply: wheelTypeSelect,
      statusWheel: 'usable',
      position,
    };
    await dispatch(actions.wheelCreate(dataSubmit));
    // await dispatch(
    //   actions.vehiclePut(wheelRegister?.vehicle?._id, { status: true }),
    // );
    await dispatch(actions.wheelRegisterGet(id));
    modalClose();
  };

  const renderModalAddWheel = () => (
    <Modal
      open={addWheel}
      onClose={modalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(submitAddWheel)}>
          <AddWheelOnVehicle {...propModal} />
        </form>
      </Box>
    </Modal>
  );

  const prop = {
    name,
    page,
    size,
    setName,
    setPage,
    setSize,
  };
  const renderTable = () => (
    <WheelMaintenHistoryVehicleTable
      {...prop}
      wheelTransection={wheelTransection}
    />
  );
  const renderInfoWheelModal = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <WheelInfoCard wheel={wheelInfo} />
      </Box>
    </Modal>
  );

  const renderBasicInfo = () => (
    <div className="pt-4">
      <Card>
        <div className="py-4">
          <h1 className="pl-4 pt-2 font-bold">ข้อมูลรถ</h1>
          <div className="p-4">
            <div className="flex ">
              <h1 className="font-bold w-1/6">ป้ายทะเบียน</h1>
              <h1 className="pl-6">{wheelRegister?.vehicle?.licence_number}</h1>
            </div>
            <div className="flex ">
              <h1 className="font-bold w-1/6">ยี่ห้อ</h1>
              <h1 className="pl-6">
                {wheelRegister?.vehicle?.attribute.brand}
              </h1>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  return (
    <>
      {renderViweTitle()}
      {renderBackButton()}
      {renderModalAddWheel()}
      {renderBasicInfo()}
      {renderInfoVehicle()}
      {renderInfoWheelModal()}
      {renderTable()}
    </>
  );
}
