export const MAINTENANCE_LEVEL = {
  SCHEDULE: {
    status_code: 'SCHEDULE',
    description: 'การดูแลตามระยะเวลา',
  },

  GENERAL: {
    status_code: 'GENERAL',
    description: 'การซ่อมบำรุงทั่วไป',
  },
  ACCIDENT: {
    status_code: 'ACCIDENT',
    description: 'การซ่อมฉุกเฉิน',
  },
  OVERHAUL: {
    status_code: 'OVERHAUL',
    description: 'การซ่อมแบบยกเครื่อง',
  },
};

export default MAINTENANCE_LEVEL;
