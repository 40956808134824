import React from 'react';
import _ from 'lodash';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Link } from 'react-router-dom';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const ZonePriceTable = ({
  zonePrices,
  page,
  size,
  handleDelete,
  handleChangeRowsPerPage,
  handleChangePage,
  total,
}) => (
  <Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">ชื่อ Zone</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(zonePrices) ? (
            zonePrices.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {(page - 1) * size + index + 1}
                </TableCell>
                <TableCell>{row?.name} </TableCell>

                <TableCell>
                  <div className="flex flex-wrap gap-1">
                    {' '}
                    <Link to={`/jms/pricing/view/${row?._id}`}>
                      <Button variant="contained" size="small">
                        รายละเอียด
                      </Button>
                    </Link>
                    <Link to={`/jms/pricing/edit/${row?._id}`}>
                      <Button variant="contained" size="small" color="warning">
                        แก้ไข
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => handleDelete(row?._id)}
                    >
                      ลบ
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>{' '}
      </Table>{' '}
      <TablePagination
        component="div"
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  </Paper>
);

ZonePriceTable.propTypes = {
  zonePrices: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleDelete: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
};

export default ZonePriceTable;
