import {
  GARAGE_ALL,
  GARAGE_GET,
  GARAGE_POST,
  GARAGE_PUT,
  GARAGE_DELETE,
  GARAGE_LOADING,
  GARAGE_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const garageCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GARAGE_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/garage`, {
      ...payload,
    });
    dispatch({ type: GARAGE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GARAGE_ERROR });
  }
};

export const garageAll =
  ({ name = '', size = '', page = 1, isOfficial = '' }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/garage?name=${name}&size=${size}&page=${page}&isOfficial=${isOfficial}`,
      );
      if (status === 200) {
        dispatch({ type: GARAGE_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: GARAGE_ERROR });
    }
  };

export const garageGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/garage/${id}`,
    );
    if (status === 200) {
      dispatch({ type: GARAGE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: GARAGE_ERROR });
  }
};

export const garagePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: GARAGE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/garage/${id}`,
      payload,
    );
    dispatch({ type: GARAGE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GARAGE_ERROR });
  }
};

export const garageDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: GARAGE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/garage/${id}`,
    );
    dispatch({ type: GARAGE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GARAGE_ERROR });
  }
};
