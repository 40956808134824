import {
  DRIVER_ALL,
  DRIVER_GET,
  DRIVER_DEL,
  DRIVER_PUT,
  DRIVER_POST,
  DRIVER_LOADING,
  DRIVER_ERROR,
} from '../../actions/types';

const initialState = {
  driver: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case DRIVER_LOADING:
      return { isLoading: true, isCompleted: true };
    case DRIVER_ERROR:
      return { isLoading: false, isCompleted: false };
    case DRIVER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DRIVER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DRIVER_POST:
      return { isLoading: false, isCompleted: true };
    case DRIVER_PUT:
      return { isLoading: false, isCompleted: true };
    case DRIVER_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
