import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as actions from '../../redux/actions';
import CreateWheelTypeCard from '../../components/Card/WMS/CreateWheelType';

export default function EditWheelType({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const wheelType = useSelector((state) => state.wheelType);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name: wheelType.name,
    },
  });
  useEffect(() => {
    setValue('name', wheelType?.name);
    setValue('note', wheelType?.note);

    return () => {};
  }, [wheelType]);

  const onSubmitData = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      try {
        console.log('data', data);
        await dispatch(actions.wheelTypePut(id, data));
        await dispatch(actions.wheelTypeAll({}));
        history.push('/wms/wheel-types');
      } catch (error) {
        console.log('error', error);
        alert('บันทึกข้อมูลผิดพลาด');
      }
    }
  };
  const renderEditCard = () => (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <CreateWheelTypeCard
        control={control}
        Controller={Controller}
        wheelType={wheelType}
      />
    </form>
  );
  useEffect(() => {
    dispatch(actions.wheelTypeGet(id));

    return () => {};
  }, []);
  console.log('wheelType', wheelType);
  return (
    <div>
      <div>
        {renderTitle()}
        {renderEditCard()}
      </div>
    </div>
  );
}
