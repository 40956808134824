import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { Button, Card } from '@mui/material';
import WheelMaintenanceCard from '../../components/Card/WMS/WheelMaintenanceCard';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import Loading from '../../components/Loading';
import * as actions from '../../redux/actions';
import VehicleWheelInfo from '../../components/Forms/WMS/VehicleWheelInfo';

import WheelInfoMaintenanceCard from '../../components/Card/WMS/WheelInfoMaintenanceCard';

export default function WheelMaintenance({ title, subTitle }) {
  const dispatch = useDispatch();
  const { id, wms } = useParams();
  const history = useHistory();

  const wheelMaintenance = useSelector((state) => state.WheelMaintenance);
  const wheel = useSelector((state) => state.wheel);
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const [wheelSelect, setWheelSelect] = useState('');
  const [wheelTypeSelect, setWheelTypeSelect] = useState('');
  const [openAddWheel, setOpenAddWheel] = useState(false);
  const [newWheel, setNewWheel] = useState();

  const { handleSubmit, control } = useForm();
  const renderTitle = () => <ViewTitle title={title} subTitle={subTitle} />;
  const renderBackButton = () => <BackButton />;

  useEffect(() => {
    dispatch(actions.wheelSupplyAll({}));
    dispatch(actions.wheelMaintenanceGet(wms));

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.wheelAll({ statusWheel: 'ready', supply: wheelTypeSelect }),
    );
    return () => {};
  }, [wheelTypeSelect]);

  const handleAddNewWheel = async (data) => {
    try {
      // console.log('newWheel', data);
      await dispatch(actions.wheelCreate(data));
      await dispatch(
        actions.wheelAll({ statusWheel: 'ready', supply: wheelTypeSelect }),
      );
    } catch (error) {
      alert('สร้างยางไม่สำเร็จ');
    }
  };

  // const renderTableMaintenance = () => (
  //   <VehicleWheelInfo
  //     wheelRegister={wheelRegisters}
  //     setWheelSelect={setWheelSelect}
  //     maintain={true}
  //     wheelInVehicle={wheelRegisters?.wheelVehicle}
  //   />
  // );

  const onSubmit = async (data) => {
    const dataSubmit = {
      ...data,
      mileageWheel: wheelSelect?.mileageIn,
      mileage: wheelMaintenance?.mileage,
      wheelOut: wheelSelect?._id,
      vehicle: wheelSelect?.vehicle,
      trailertail: wheelSelect?.trailertail,
      position: wheelSelect?.position,
      // cause: wheelSelect?.cause,
      maintenanceOrder: wms,
      repairStatus: false,
    };

    const index = _.findIndex(
      wheelMaintenance?.wheelOrder,
      (e) => e?.wheel_Id?._id === wheelSelect?._id,
    );

    const maintenance = {
      ...wheelMaintenance?.wheelOrder[index],
      status_maintenance: true,
      note: '',
    };

    const submit = _.merge(
      _.merge(wheelMaintenance?.wheelOrder[index], maintenance),
      { ...wheelMaintenance, note: '' },
    );

    await dispatch(actions.wheelTransectionCreate(dataSubmit));
    await dispatch(actions.wheelMaintenancePut(wms, submit));
    await dispatch(actions.wheelMaintenanceGet(wms));
    alert('เปลี่ยนยางสำเร็จ');
    setWheelSelect();
    history.goBack();
  };

  const rederMap = () => (
    <div>
      <div className="p-2 font-bold ">รายการแจ้งซ่อม</div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 ">
        {_.map(wheelMaintenance?.wheelOrder, (each) => (
          <div className="p-2">
            <Card
              sx={{
                bgcolor:
                  wheelSelect?._id === each?.wheel_Id?._id
                    ? '#ff9e99'
                    : 'white',
              }}
            >
              <div className="p-4 flex justify-between">
                <div>
                  <div className="text-xl font-bold ">
                    เลขยาง: {each?.wheel_Id?.wheelNumber}
                  </div>
                  <div>ตำแหน่งยาง: {each?.position}</div>
                  <div>สาเหตุ: {each?.cause}</div>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={each?.status_maintenance}
                    onClick={() => setWheelSelect(each?.wheel_Id)}
                  >
                    เปลี่ยนยาง
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );

  const renderWheelCard = () => (
    <div className=" lg:flex">
      <div className="lg:w-1/2 lg:px-1 py-1 ">
        <WheelInfoMaintenanceCard
          wheelSelect={wheelSelect}
          setWheelSelect={setWheelSelect}
          control={control}
          Controller={Controller}
          //  wheelInVehicle={wheelRegisters?.wheelVehicle}
        />
      </div>
      <div className="lg:w-1/2 lg:px-1 py-1 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <WheelMaintenanceCard
            wheelSelect={wheelSelect}
            control={control}
            wheel={wheel}
            wheelSupply={wheelSupply}
            setWheelTypeSelect={setWheelTypeSelect}
            // wheelInVehicle={wheelRegisters?.wheelVehicle}
            openAddWheel={openAddWheel}
            setOpenAddWheel={setOpenAddWheel}
            newWheel={newWheel}
            setNewWheel={setNewWheel}
            handleAddNewWheel={handleAddNewWheel}
          />
        </form>
      </div>
    </div>
  );
  const renderWheelWaitCard = () => (
    <div className="py-2">
      <Card>
        <div className="py-4 text-center font-blod text-xl">
          กรุณาตำแหน่งยางที่ต้องการ
        </div>
      </Card>
    </div>
  );

  if (wheel.isLoading) {
    return <Loading />;
  }
  if (!wheel.isLoading && wheel.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {/* {renderTableMaintenance()} */}
        {rederMap()}
        <div className="p-2 font-bold">ตำแหน่งการซ่อมยาง</div>
        {wheelSelect && renderWheelCard()}
        {!wheelSelect && renderWheelWaitCard()}
      </div>
    );
  }
}
WheelMaintenance.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

WheelMaintenance.defaultProps = {
  title: '',
  subTitle: '',
};
