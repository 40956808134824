import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import * as actions from '../../redux/actions';
import {
  Button,
} from '@mui/material';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { DeliveryOrderShowTable } from '../../components/Table';
import { VehicleScheduleForm } from '../../components/Forms';
import { CONFIG } from '../../utils/constants';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

const EditVehicleSchedule = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const vehicleSchedule = useSelector((state) => state.vehicleSchedule);
  const deliveryOrders = useSelector((state) => state.deliveryOrders);
  const vehicle = useSelector((state) => state.vehicle);
  const trailerTail = useSelector((state) => state.trailerTail);
  const container = useSelector((state) => state.container);
  const driver = useSelector((state) => state.driver);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: vehicleSchedule
  });

  const onSubmit = async (data) => {
    try {
      await dispatch(
        actions.vehicleSchedulePut(
          params?.id,
          {
            ...data,
          }),
      );
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert('แก้ไขการเดินรถไม่สำเร็จ', error?.message);
      console.error(error);
    }
  };

  const getVehicleSchedule = async () => {
    try {
      await dispatch(
        actions.vehicleScheduleGet(params?.id)
      )
    } catch (error) {
      console.error('Error on Fetch Vehicle Schedule', error);
    }
  }

  const getAllVehicle = async () => {
    try {
      await dispatch(
        actions.vehicleAll({ name: '', page: 1, size: CONFIG.infiniteLimit }),
      );
    } catch (error) {
      console.error('Error on Fetch Vehicle', error);
    }
  };

  const getAllTrailerTails = async () => {
    try {
      await dispatch(
        actions.trailerTailAll({
          name: '',
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Error on Fetch Trailer Tail', error);
    }
  };

  const getAllContainers = async () => {
    try {
      await dispatch(
        actions.containerAll({
          name: '',
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Error on Fetch  Container', error);
    }
  };

  const getAllDrivers = async () => {
    try {
      await dispatch(
        actions.driverAll({ size: CONFIG.infiniteLimit, page: 1 }),
      );
    } catch (error) {
      console.error('Error on Fetch Driver', error);
    }
  };

  useEffect(() => {
    getVehicleSchedule();
    return () => { };
  }, []);

  useEffect(() => {
    getAllContainers();
    getAllTrailerTails();
    getAllVehicle();
    getAllDrivers();
    return () => { };
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderButton = () => (
    <div className="flex justify-start pb-4">
      <BackButton />
    </div>
  );

  if (
    vehicleSchedule.isLoading ||
    deliveryOrders.isLoading ||
    vehicle.isLoading ||
    trailerTail.isLoading ||
    container.isLoading ||
    driver.isLoading ||
    vehicleSchedule.rows
  ) {
    return <Loading />;
  }

  if (
    !vehicleSchedule.rows &&
    !vehicleSchedule.isLoading && vehicleSchedule.isCompleted &&
    !deliveryOrders.isLoading && deliveryOrders.isCompleted &&
    !vehicle.isLoading && vehicle.isCompleted &&
    !trailerTail.isLoading && trailerTail.isCompleted &&
    !container.isLoading && container.isCompleted &&
    !driver.isLoading && driver.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderButton()}
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-2/3 px-2">
            <div className="flex flex-wrap justify-between">
              <div className="font-semibold text-lg my-1">รายการจัดส่ง</div>
            </div>

            <DeliveryOrderShowTable
              deliveryOrders={vehicleSchedule?.delivery_orders}
            />
          </div>
          <div className="w-full lg:w-1/3 px-2 my-9">
            <form onSubmit={handleSubmit(onSubmit)}>
              <VehicleScheduleForm
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                vehicles={vehicle}
                trailerTails={trailerTail}
                containers={container}
                drivers={driver}
                vehicleAssignment={vehicleSchedule}
              />
              <div className="flex justify-end my-2">
                <Button variant="contained" type="submit" color='warning'>
                  แก้ไข
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
  return <Error />;
};

EditVehicleSchedule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditVehicleSchedule.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditVehicleSchedule;
