import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { CONFIG, PROCESS_TXN_TYPE } from '../../utils/constants';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import { MaintenanceOrderCard, MaterialCard } from '../../components/Card';

import {
  DecreaseMaterialForm,
  OnProcessMaterialDecreaseForm,
} from '../../components/Forms';

/**
 * @function MaterialWithdraw
 * @description Withdraw Material inside Process
 * @returns React.Component
 * @author @theethawat
 */
function MaterialApproval({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );

  const customer = useSelector((state) => state.customer);
  const materialType = useSelector((state) => state.materialType);
  const me = useSelector((state) => state.me);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const customerType = useSelector((state) => state.customerType);
  const materialRequest = useSelector((state) => state.materialRequest);

  const [filterMaterial, setFilterMaterial] = useState([]);
  const [filterPlace, setFilterPlace] = useState();
  const [selectedMaterialLot, setSelectedMaterialLot] = useState([]);

  const { control, handleSubmit } = useForm();

  const getMaterialRequest = async () => {
    try {
      dispatch(actions.materialRequestGet(params.id));
    } catch (error) {
      console.log('Get process Error', error);
    }
  };

  useEffect(() => {
    getMaterialRequest();
  }, [params]);

  useEffect(() => {
    dispatch(actions.materialAll({}));
    dispatch(actions.placeAll({}));
    dispatch(actions.materialTransactionTypeAll({}));
    dispatch(actions.materialTypeAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.customerAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.customerTypeAll({ page: 1, size: CONFIG.infiniteLimit }));
    dispatch(actions.materialTransactionTypeAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    if (place?.rows) {
      setFilterPlace(place?.rows?.[0]?._id);
    }
    return () => {};
  }, [place]);

  const handleCreateMaterial = async (data) => {
    try {
      await dispatch(actions.materialInsertOneWithStocklot(data));
      dispatch(actions.materialAll({}));
    } catch (error) {
      alert('ไม่สามารถสร้างวัสดุใหม่ได้', error?.message);
    }
  };

  const handleAddStockMaterial = async (data) => {
    try {
      dispatch(actions.matrialReset());
      const stockPayload = {
        ...data,
        status: 'IN_STOCK',
        material_transaction_type: _.find(
          materialTransactionType?.rows,
          (eachMT) => eachMT?.direction === 'add',
        ),
        recording_date: dayjs().toDate(),
        purchase_date: dayjs().toDate(),
        receipt_date: dayjs().toDate(),
        issue_date: dayjs().toDate(),
        remark: 'เพิ่มในกระบวนการ ก่อนที่จะเบิกวัสดุ',
        recipient_name: `${me?.userData?.firstname || ' '}  ${
          me?.userData?.lastname || ' '
        }`,
      };
      const materialStockpayload = {
        ...data,
        amount: data.quantity,
        status: 'IN_STOCK',
      };
      await dispatch(
        actions.materialStockLotCreate({
          ...stockPayload,
          material: [materialStockpayload],
        }),
      );

      dispatch(actions.materialAll({}));
    } catch (error) {
      alert('ไม่สามารถสร้างวัสดุใหม่ได้', error?.message);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (!_.isEmpty(selectedMaterialLot)) {
        data.materialLots = selectedMaterialLot;
        const processMaterialPayload = {
          maintenance_order: materialRequest?.maintenance_order?._id,
          transaction_type: PROCESS_TXN_TYPE.MATERIAL_WITHDRAW.status_code,
          employee: me?.userData?._id,
          direction: true,
          materialArrays: _.map(selectedMaterialLot, (_materialLot) => ({
            material: _materialLot?.material?._id,
            material_stock_lot: _materialLot?.lotId,
            begin: '',
            quantity: parseFloat(_materialLot?.quantity),
            amount: '',
          })),
        };

        console.log('Data', data);
        console.log('Process Material payload', processMaterialPayload);
        await dispatch(
          actions.materialStockLotDecrease({
            ...data,
            request_name: `${me?.userData?.firstname || ' '}  ${
              me?.userData?.lastname || ' '
            }`,
            employee: me?.userData?._id,
          }),
        );
        await dispatch(actions.processMaterialCreate(processMaterialPayload));
        await dispatch(
          actions.materialRequestPut(params.id, {
            approver: me?.userData?._id,
            approve_date: new Date(),
            completed: true,
          }),
        );
        alert('สำเร็จ');
        history.goBack();
      }
    } catch (error) {
      alert(`เกิดข้อผิดพลาดในการเบิกวัสดุ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  const displayMaterialRequest = () => (
    <div>
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="font-semibold my-2">รายการขอเบิกวัสดุ</div>
            <div className="font-sansserif flex flex-wrap">
              <div className="w-full lg:w-1/6  font-semibold">
                หมายเลขรายการ
              </div>
              <div className="w-full lg:w-2/6">
                {materialRequest?.running_number}{' '}
              </div>
              <div className="w-full lg:w-1/6  font-semibold">ผู้ร้องขอ</div>
              <div className="w-full lg:w-2/6">
                {materialRequest?.requester?.firstname}{' '}
                {materialRequest?.requester?.lastname}
              </div>
              <div className="w-full lg:w-1/6  font-semibold">
                รายการแจ้งบำรุงรักษา
              </div>
              <div className="w-full lg:w-2/6">
                {materialRequest?.maintenance_order?.running_number || '-'}{' '}
              </div>
              <div className="w-full lg:w-1/6  font-semibold">ยานพาหนะ</div>
              {!_.isEmpty(materialRequest?.maintenance_order) ? (
                <div className="w-full lg:w-2/6">
                  <div>
                    หัวรถ{' '}
                    {materialRequest?.maintenance_order?.vehicle
                      ?.licence_number || '-'}{' '}
                  </div>
                  <div>
                    หางรถ{' '}
                    {materialRequest?.maintenance_order?.trailer_tail
                      ?.licence_number || '-'}{' '}
                  </div>
                  <div>
                    ตู้คอนเทนเนอร์{' '}
                    {materialRequest?.maintenance_order?.container
                      ?.licence_number || '-'}{' '}
                  </div>
                </div>
              ) : (
                <div className="w-full lg:w-2/6">
                  <div>การขอเบิกวัสดุโดยตรง</div>
                  <div>
                    หัวรถ {materialRequest?.vehicle?.licence_number || '-'}{' '}
                  </div>
                  <div>
                    หางรถ {materialRequest?.trailer_tail?.licence_number || '-'}{' '}
                  </div>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardContent>
          <div className="font-semibold">วัสดุที่ขอเบิก</div>
          <div className="grid grid-cols-4 gap-2 my-2">
            {_.map(materialRequest?.material, (eachMaterial, index) => (
              <div key={index} className="p-2 rounded-md border">
                <div>{eachMaterial?.material?.type_code} </div>
                <div className="text-lg font-semibold">
                  {eachMaterial?.material?.name}{' '}
                </div>
                <div>
                  จำนวน {eachMaterial?.quantity} {eachMaterial?.material?.unit}{' '}
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderForm = () => (
    <div className="my-2">
      {displayMaterialRequest()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <OnProcessMaterialDecreaseForm
          control={control}
          materialTransactionType={materialTransactionType}
        />
        <DecreaseMaterialForm
          filterMaterial={filterMaterial}
          filterPlace={filterPlace}
          material={material?.rows}
          selectMaterialLot={selectedMaterialLot}
          setFilterPlace={setFilterPlace}
          setSelectMaterialLot={setSelectedMaterialLot}
          setFilterMaterial={setFilterMaterial}
          place={place?.rows}
          type={false}
          materialType={materialType?.rows}
          materialState={material}
          handleCreateMaterial={handleCreateMaterial}
          customer={customer?.rows}
          handleAddStockMaterial={handleAddStockMaterial}
          allowMaterialLotFillIn
          vehicle={
            !_.isEmpty(materialRequest?.vehicle)
              ? { rows: [materialRequest?.vehicle] }
              : null
          }
          trailerTail={
            !_.isEmpty(materialRequest?.trailer_tail)
              ? { rows: [materialRequest?.trailer_tail] }
              : null
          }
          customerType={customerType}
        />
        <div className="w-full flex my-2 justify-end">
          <Button variant="contained" type="submit">
            ยืนยันการเบิก
          </Button>
        </div>
      </form>
    </div>
  );

  if (materialRequest?.isLoading) {
    return <Loading />;
  }
  if (!materialRequest?.isLoading && materialRequest?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}

        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

MaterialApproval.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialApproval.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialApproval;
