import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MATERIAL_TRANSACTION_TYPE } from '../../../utils/constants';

export function MaterialTransactionTypeForm({
  errors,
  materialTransactionType,
  Controller,
  control,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={
            materialTransactionType ? materialTransactionType.name : ''
          }
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อประเภทการดำเนินการ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'direction'}
          control={control}
          defaultValue={
            materialTransactionType ? materialTransactionType?.direction : ''
          }
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทการดำเนินการ
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการดำเนินการ"
                size={'small'}
                fullWidth
              >
                 {_.size(MATERIAL_TRANSACTION_TYPE) ? (
                  _.map(MATERIAL_TRANSACTION_TYPE, (transactionType) => (
                    <MenuItem key={transactionType.name} value={transactionType.direction}>
                      {transactionType.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
    </div>
  );
}

MaterialTransactionTypeForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    direction: PropTypes.object,
  }),
  materialTransactionType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

MaterialTransactionTypeForm.defaultProps = {
  materialTransactionType: null,
};

export default MaterialTransactionTypeForm;
