import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

const RequestMaterialForm = ({ control, material, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'material',
  });

  const materialOption = _.map(material?.rows, (eachMaterial, index) => ({
    ...eachMaterial,
    index,
  }));

  return (
    <div>
      <div>
        <Table>
          <TableHead>
            <TableCell>ลำดับที่</TableCell>
            <TableCell>วัสดุ</TableCell>
            <TableCell>ปริมาณ</TableCell>
            <TableCell>ดำเนินการ</TableCell>
          </TableHead>
          <TableBody>
            {_.map(fields, (eachField, index) => (
              <TableRow key={eachField?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <div className="w-80">
                    {!_.isEmpty(material) && (
                      <Controller
                        name={`material[${index}].material`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={material?.[0]}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            name={field.name}
                            ref={field.ref}
                            defaultValue={field.value}
                            size="small"
                            options={materialOption}
                            fullWidth
                            autoHighlight
                            getOptionLabel={(option) =>
                              `${option.index || ''} ${
                                option.type_code || ''
                              } | ${option.name}`
                            }
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{
                                  fontSize: 15,
                                  '& > span': { mr: '10px', fontSize: 18 },
                                }}
                                {...props}
                              >
                                {`${option.index || ''} ${
                                  option.type_code || ''
                                } | ${option.name}`}
                              </Box>
                            )}
                            onChange={(e, value) => {
                              field.onChange(value._id);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                label="วัสดุ"
                                inputProps={{
                                  ...params.inputProps,
                                }}
                                helperText={
                                  errors?.material?.[index]?.material &&
                                  'กรุณาใส่ข้อมูล'
                                }
                              />
                            )}
                          />
                        )}
                      />
                    )}{' '}
                  </div>
                </TableCell>
                <TableCell>
                  <Controller
                    name={`material[${index}].quantity`}
                    control={control}
                    defaultValue={1}
                    render={({ field }) => (
                      <TextField
                        label="จำนวน"
                        fullWidth
                        {...field}
                        size="small"
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="w-full my-2">
        <Button
          size="small"
          onClick={() => append({})}
          variant="contained"
          color="primary"
        >
          เพิ่มวัสดุ
        </Button>
      </div>
      <div className="w-full my-2 py-2">
        <Controller
          control={control}
          name="remark"
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              size="small"
              fullWidth
              multiline
              rows={3}
            />
          )}
        />
      </div>
    </div>
  );
};

RequestMaterialForm.propTypes = {
  control: PropTypes.object,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
};

export default RequestMaterialForm;
