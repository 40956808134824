import {
  JOB_ALL,
  JOB_GET,
  JOB_PUT,
  JOB_DELETE,
  JOB_POST,
  JOB_RESET,
  JOB_LOADING,
  JOB_ERROR,
} from '../../actions/types';

const initialState = {
  job: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function JobReducer(state = initialState, action) {
  switch (action.type) {
    case JOB_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case JOB_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case JOB_POST:
      return { isLoading: false, isCompleted: true };
    case JOB_PUT:
      return { isLoading: false, isCompleted: true };
    case JOB_DELETE:
      return { isLoading: false, isCompleted: true };
    case JOB_RESET:
      return { JOB: null, isLoading: true, isCompleted: true };
    case JOB_LOADING:
      return { isLoading: true, isCompleted: true };
    case JOB_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
