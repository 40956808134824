import {
  PROCESS_MATERIAL_ALL,
  PROCESS_MATERIAL_GET,
  PROCESS_MATERIAL_PUT,
  PROCESS_MATERIAL_DELETE,
  PROCESS_MATERIAL_POST,
  PROCESS_MATERIAL_RESET,
  PROCESS_MATERIAL_LOADING,
  PROCESS_MATERIAL_ERROR,
} from '../../actions/types';

const initialState = {
  maintenanceProcess: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PROCESS_MATERIAL_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_MATERIAL_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_MATERIAL_POST:
      return { isLoading: false, isCompleted: true };
    case PROCESS_MATERIAL_PUT:
      return { isLoading: false, isCompleted: true };
    case PROCESS_MATERIAL_DELETE:
      return { isLoading: false, isCompleted: true };
    case PROCESS_MATERIAL_RESET:
      return { PROCESS_MATERIAL: null, isLoading: true, isCompleted: true };
    case PROCESS_MATERIAL_LOADING:
      return { isLoading: true, isCompleted: true };
    case PROCESS_MATERIAL_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
