/* eslint-disable react/prop-types */
import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export function TrailerTailTypeForm({
  errors,
  trailerTailType,
  setWheel,
  wheel,
  Controller,
  control,
}) {
  const handleChange = (event) => {
    setWheel(event.target.value);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={trailerTailType ? trailerTailType.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อประเภทหางรถ"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">จำนวนล้อ</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={wheel}
            defaultValue={trailerTailType?.wheel || ''}
            label="จำนวนล้อ"
            onChange={handleChange}
          >
            <MenuItem value={4}>4 ล้อ</MenuItem>
            <MenuItem value={6}>6 ล้อ</MenuItem>
            <MenuItem value={8}>8 ล้อ</MenuItem>
            <MenuItem value={10}>10 ล้อ</MenuItem>
            <MenuItem value={12}>12 ล้อ</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={trailerTailType ? trailerTailType.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              rows={3}
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

TrailerTailTypeForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    type_code: PropTypes.object,
  }),
  trailerTailType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

TrailerTailTypeForm.defaultProps = {
  trailerTailType: null,
};

export default TrailerTailTypeForm;
