/* eslint-disable no-underscore-dangle */
import {
  TextField,
  FormControl,
  Autocomplete,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import { ASSIGNMENT_TYPE } from '../../../utils/constants';

export function JobForm({ errors, customer, control, job, watch, zonePrice }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              required
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name="assignment_type"
          rules={{ require: true }}
          control={control}
          defaultValue={job?.assignment_type}
          render={({ field }) => (
            <FormControl size="small" required fullWidth>
              <InputLabel>ประเภทของการขนส่ง</InputLabel>
              <Select {...field} fullWidth label="ประเภทของการขนส่ง">
                {_.map(ASSIGNMENT_TYPE, (eachType, index) => (
                  <MenuItem key={index} value={eachType?.status_code}>
                    {eachType?.description}{' '}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        {!_.isEmpty(customer?.rows) && (
          <Controller
            name={'customer'}
            control={control}
            defaultValue={job ? job?.customer : customer?.rows?.[0]}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disableClearable
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={customer?.rows}
                autoHighlight
                fullWidth
                size="small"
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      fontSize: 15,
                      '& > span': { mr: '10px', fontSize: 18 },
                    }}
                    {...props}
                  >
                    {option.name || ''}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="ลูกค้า"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    helperText={errors?.customer && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="จุดเริ่มต้น"
              fullWidth
              size={'small'}
              multiline
              rows={3}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="destination"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="จุดหมายปลายทาง"
              fullWidth
              size={'small'}
              multiline
              rows={3}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="is_fixed_cost"
          control={control}
          defaultValue={job?.is_fixed_cost || true}
          render={({ field }) => (
            <FormControl>
              <FormLabel>การคิดราคา</FormLabel>
              <RadioGroup {...field}>
                <FormControlLabel
                  value={true}
                  onSelect={() => (field.value = true)}
                  control={<Radio />}
                  label="ค่าคงที่"
                />
                <FormControlLabel
                  value={false}
                  onSelect={() => (field.value = false)}
                  control={<Radio />}
                  label="จากตารางราคา"
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </div>
      {watch('is_fixed_cost') === 'true' || watch('is_fixed_cost') === true ? (
        <div className="w-full px-1 py-2">
          <Controller
            name="fixed_cost"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label="ราคา" />
            )}
          />
        </div>
      ) : (
        <div className="w-full px-1 py-2">
          {' '}
          {!_.isEmpty(zonePrice?.rows) && (
            <Controller
              name={'zone_price'}
              control={control}
              defaultValue={job ? job?.zone_price : null}
              rules={{ required: false }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  name={field.name}
                  ref={field.ref}
                  defaultValue={field.value}
                  options={zonePrice?.rows}
                  autoHighlight
                  fullWidth
                  size="small"
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        fontSize: 15,
                        '& > span': { mr: '10px', fontSize: 18 },
                      }}
                      {...props}
                    >
                      {option.name || ''}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="โซนราคา"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )}
            />
          )}
        </div>
      )}
    </div>
  );
}

JobForm.propTypes = {
  errors: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  control: PropTypes.object,
  job: PropTypes.object,
  watch: PropTypes.func,
  zonePrice: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

JobForm.defaultProps = {
  customer: null,
};

export default JobForm;
