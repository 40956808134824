/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  TextField,
} from '@mui/material';
import React from 'react';

export default function RepairForm({
  wheelSelect,
  Controller,
  control,
  setChecked,
  setWheelSelect,
  checked,
}) {
  console.log('Wheel', wheelSelect);
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              setChecked(e.target.checked);
              if (!e.target.checked) {
                setWheelSelect({});
              }
            }}
          />
        }
        label="ซ่อมยาง"
      />
      {checked && (
        <div className="py-1 font-bold text-xl ">
          ยางที่เลือก {wheelSelect?.wheelNumber || '-'}
        </div>
      )}

      <div className="py-1 ">
        <div className="py-1 ">
          <Controller
            name="repairTopic"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="หัวข้อการซ่อม"
                fullWidth
                size="small"
              />
            )}
          />
        </div>
        <div className="py-1 ">
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="ราคา" fullWidth size="small" />
            )}
          />
        </div>
        <div className="py-1 ">
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="หมายเหตุ" fullWidth size="small" />
            )}
          />
        </div>
      </div>
    </div>
  );
}
