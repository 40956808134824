import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CONFIG } from '../../utils/constants';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

import {
  RequestMaterialForm,
  IndividualMaterialRequestForm,
} from '../../components/Forms';

export default function CreateIndividualMaterialRequest({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const me = useSelector((state) => state.me);
  const material = useSelector((state) => state.material);
  const vehicle = useSelector((state) => state.vehicle);
  const trailerTail = useSelector((state) => state.trailerTail);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const fetchMaterials = async () => {
    try {
      await dispatch(
        actions.materialAll({
          stockStatus: '',
          noStockLot: true,
          page: 1,
          size: CONFIG.infiniteLimit,
        }),
      );
    } catch (error) {
      console.error('Error on Fetch Materials', error);
    }
  };

  const fetchVehicle = () => {
    dispatch(
      actions.vehicleAll({
        page: 1,
        size: CONFIG.infiniteLimit,
      }),
    );
  };
  const fetchTrailerTail = () => {
    dispatch(
      actions.trailerTailAll({
        page: 1,
        size: CONFIG.infiniteLimit,
      }),
    );
  };

  useEffect(() => {
    fetchMaterials();
    fetchVehicle();
    fetchTrailerTail();
  }, [params]);

  const onSubmit = async (data) => {
    try {
      console.log('Data', data);
      const useTrailerTail = data.use_trailer_tail;

      const payload = {
        ...data,
        requester: me?.userData?._id,
        maintenance_order: null,
        individual_request: true,
        vehicle: useTrailerTail ? null : data.vehicle,
        trailer_tail: useTrailerTail ? data.trailer_tail : null,
      };
      await dispatch(actions.materialRequestCreate(payload));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`เกิดข้อผิดพลาดในการขอเบิกวัสดุ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <IndividualMaterialRequestForm
              control={control}
              errors={errors}
              trailerTail={trailerTail}
              vehicle={vehicle}
              watch={watch}
            />
            <div className="px-2">
              <div className="font-semibold"> รายการวัสดุที่ต้องการ</div>
              <RequestMaterialForm control={control} material={material} />
            </div>
          </CardContent>
        </Card>
        <div className="w-full flex my-2 justify-end">
          <Button variant="contained" type="submit">
            ยืนยันการเบิก
          </Button>
        </div>
      </form>
    </div>
  );

  if (material?.isLoading) {
    return <Loading />;
  }
  if (!material?.isLoading && material?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}

CreateIndividualMaterialRequest.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateIndividualMaterialRequest.defaultProps = {
  title: '',
  subtitle: '',
};
