/* eslint-disable react/prop-types */
import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export function MaterialForm({
  errors,
  material,
  Controller,
  control,
  materialType,
  customerType,
  selectCustomerType,
  setSelectCustomerType,
  customer,
  place,
  selectCustomer,
  setSelectCustomer,
}) {
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;

  const handlePushtoSelectCustomer = (select) => {
    const removeIndex = _.indexOf(selectCustomer, select);
    console.log('removeIndex', removeIndex);
    if (removeIndex > -1) {
      const newArr = _.filter(
        selectCustomer,
        (_customer, index) => index !== removeIndex,
      );
      setSelectCustomer(newArr);
    } else {
      setSelectCustomer([...selectCustomer, select]);
    }
  };
  return (
    <div className="flex flex-row flex-wrap w-full">
      <div className="font-semibold">ข้อมูลวัสดุ</div>
      {imsSetting?.attribute?.type_code && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'type_code'}
            control={control}
            defaultValue={material ? material.type_code : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รหัสวัสดุ"
                fullWidth
                size={'small'}
                helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      <div className="w-full  px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={material ? material.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อวัสดุ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={material ? material.unit : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยวัสดุ"
              fullWidth
              size={'small'}
              helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'place'}
          control={control}
          defaultValue={material ? material?.place?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                คลังวัสดุที่ควรอยู่
              </InputLabel>
              <Select
                {...field}
                label="คลังวัสดุที่ควรอยู่"
                size={'small'}
                fullWidth
              >
                {_.map(place?.rows, (row) => (
                  <MenuItem key={row.id} value={row._id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {console.log('Place', place)}
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'minimum_amount'}
          control={control}
          defaultValue={material ? material.minimum_amount : 0}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนขั้นต่ำของวัสดุ"
              fullWidth
              type="number"
              size={'small'}
              helperText={errors.minimum_amount && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'maximum_amount'}
          control={control}
          defaultValue={material ? material.maximum_amount : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนขั้นสูงสุดของวัสดุ"
              fullWidth
              type="number"
              size={'small'}
              helperText={errors.maximum_amount && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'material_type'}
          control={control}
          defaultValue={material ? material?.material_type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                ประเภทวัสดุ
              </InputLabel>
              <Select {...field} label="ประเภทวัสดุ" size={'small'} fullWidth>
                {_.size(materialType?.rows) ? (
                  _.map(materialType.rows, (row) => (
                    <MenuItem key={row.id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>

      {/* {imsSetting?.attribute?.register_number && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'register_number'}
            control={control}
            defaultValue={material ? material.register_number : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขทะเบียน / Serial Number"
                fullWidth
                size={'small'}
                helperText={errors.register_number && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )} */}

      <div className="w-full px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={material ? material.detail : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              rows={2}
              multiline={true}
              size={'small'}
              helperText={errors.detail && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="font-semibold">ข้อมูลคู่ค้า</div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'customer_type'}
          control={control}
          defaultValue={material ? material?.supplier?.[0]?.type?.name : ''}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทลูกค้า
              </InputLabel>
              <Select
                {...field}
                label="ประเภทลูกค้า"
                size={'small'}
                fullWidth
                onChange={(e) => {
                  setSelectCustomerType(e.target.value);
                }}
              >
                {_.size(customerType?.rows) ? (
                  _.map(customerType.rows, (row, index) => (
                    <MenuItem key={index} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        {selectCustomerType ? (
          <>
            <div className="">
              <div className="text-sm font-semibold mb-1">คู่ค้าที่มี</div>
              <Paper>
                <TableContainer component={Paper} style={{ maxHeight: 400 }}>
                  <Table sx={{ minWidth: 650 }}>
                    <colgroup>
                      <col width="5%" />
                      <col width="10%" />
                      <col width="20%" />
                      <col width="5%" />
                      <col width="10%" />
                    </colgroup>
                    <TableHead className="bg-blue-100">
                      <TableRow>
                        <TableCell>
                          <div className="font-bold">ลำดับที่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">ชื่อ</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">ที่อยู่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">เบอร์โทร</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold"> ดำเนินการ</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(customer?.rows) ? (
                        _.map(customer?.rows, (row, index) => (
                          <TableRow
                            key={row?._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div>
                                {row?.address?.house_number}{' '}
                                {row?.address?.road} {row?.address?.subdistrict}
                                {row?.address?.district}{' '}
                                {row?.address?.province}{' '}
                                {row?.address?.postcode}
                              </div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.phone_number}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                variant="contained"
                                color={'secondary'}
                                onClick={() => {
                                  handlePushtoSelectCustomer(row);
                                }}
                                disabled={_.includes(selectCustomer, row)}
                              >
                                เพิ่ม
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div className="text-center">ไม่มีข้อมูล</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        {selectCustomerType ? (
          <>
            <div className="">
              <div className="text-sm font-semibold mb-1">คู่ค้าที่เลือก</div>
              <Paper>
                <TableContainer component={Paper} style={{ maxHeight: 400 }}>
                  <Table sx={{ minWidth: 650 }}>
                    <colgroup>
                      <col width="5%" />
                      <col width="10%" />
                      <col width="20%" />
                      <col width="5%" />
                      <col width="10%" />
                    </colgroup>
                    <TableHead className="bg-blue-100">
                      <TableRow>
                        <TableCell>
                          <div className="font-bold">ลำดับที่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">ชื่อ</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">ที่อยู่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">เบอร์โทร</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold"> ดำเนินการ</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(selectCustomer) ? (
                        selectCustomer.map((select, index) => (
                          <TableRow
                            key={select._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {select?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {select?.address?.house_number}{' '}
                              {select?.address?.road}{' '}
                              {select?.address?.subdistrict}
                              {select?.address?.district}{' '}
                              {select?.address?.province}{' '}
                              {select?.address?.postcode}{' '}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {select?.phone_number}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                variant="contained"
                                color={'error'}
                                onClick={() => {
                                  handlePushtoSelectCustomer(select);
                                }}
                              >
                                ลบ
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div className="text-center">ไม่มีข้อมูล</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

MaterialForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    net: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    place: PropTypes.object,
    minimum_amount: PropTypes.object,
    register_number: PropTypes.object,
  }),
  material: PropTypes.object,
  materialType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  customerType: PropTypes.object,
  selectCustomerType: PropTypes.object,
  setSelectCustomerType: PropTypes.object,
  customer: PropTypes.object,
  selectCustomer: PropTypes.object,
  setSelectCustomer: PropTypes.object,
};

MaterialForm.defaultProps = {
  material: null,
  materialType: null,
  customerType: null,
};

export default MaterialForm;
