/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory } from 'react-router';
import WheelAddForm from '../../components/Forms/WMS/WheelAddForm';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { BackButton } from 'components/Button';

export default function EditWheel({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const wheelType = useSelector((state) => state.wheelType);
  const wheelSupply = useSelector((state) => state.wheelSupply);
  const wheel = useSelector((state) => state.wheel);
  const [selectType, setSelectType] = useState();
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { wheel_id: wheel.wheel_id },
  });

  useEffect(() => {
    dispatch(actions.wheelGet(id));
    dispatch(actions.wheelTypeAll({}));
    dispatch(actions.wheelSupplyAll({}));

    return () => {};
  }, []);
  // useEffect(() => {
  //   setSelectType(wheel?.supply);
  //   return () => {};
  // }, [wheel]);
  // console.log('selectType', selectType);
  // console.log('Whell Edid', wheel);
  // console.log('id', id);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const onSubmitData = async (data) => {
    const confirm = window.confirm('ยันยันบันทึกข้อมูล');
    if (confirm) {
      try {
        const dataSubmit = {
          ...data,
        };
        await dispatch(actions.wheelPut(id, dataSubmit));
        await dispatch(actions.wheelGet(id));
        alert('บันทึกข้อมูลสำเร็จ');
        history.goBack();
      } catch (error) {}
    }
  };
  const renderBack = () => <BackButton />;
  const renderWheelAddForm = () => (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <WheelAddForm
        control={control}
        Controller={Controller}
        wheel={wheel}
        setValue={setValue}
        wheelType={wheelType}
        selectType={selectType}
        wheelSupply={wheelSupply}
        setSelectType={setSelectType}
      />
    </form>
  );
  if (wheel.isLoading) {
    return <Loading />;
  }

  if (!wheel.isLoading && wheel.isCompleted && !wheel?.rows) {
    return (
      <>
        {renderTitle()}
        {renderBack()}
        {renderWheelAddForm()}
        <div></div>
      </>
    );
  }

  return <div></div>;
}
