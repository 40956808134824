import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { dayjs } from '../../../utils/functions';
import StatusTransaction from './StatusTransaction';

const DetailVehicleScheduleForm = ({ vehicleSchedule }) => (
  <div className="">
    <Card>
      <CardContent>
        <div className="w-full flex mt-2">
          <div className="w-1/3 ml-4">
            <div className="font-bold mb-4 text-lg">{'เลขที่รายการ:'}</div>
            <div className="font-bold mb-4 text-lg">{'ประเภท:'}</div>
            <div className="font-bold mb-4 text-lg">{'วันที่เริ่มจอง:'}</div>
            <div className="font-bold mb-4 text-lg">{'วันที่สิ้นสุด:'}</div>
            <div className="font-bold mb-4 text-lg">{'พนักงานขับรถ:'}</div>
            <div className="font-bold mb-16 text-lg">{'ทะเบียนรถ:'}</div>
            <div className="font-bold mb-4 text-lg">{'หมายเลขซีล:'}</div>
            <div className="font-bold mb-4 text-lg">{'หมายเหตุ:'}</div>
            <div className="font-bold mb-2 text-lg">{'รายการงาน:'}</div>
            <div className="font-bold mb-4 text-lg">{'สถานะการเดินรถ:'}</div>
          </div>
          <div className="w-2/3 ">
            <div className="mb-4 text-lg">
              {vehicleSchedule?.running_number || '-'}
            </div>
            <div className="mb-4 text-lg">
              {vehicleSchedule?.schedule_type || '-'}
            </div>
            <div className="mb-4 text-lg">
              {`${dayjs(vehicleSchedule?.start_date).format('DD MMM BBBB')}`}
            </div>
            <div className="mb-4 text-lg">
              {`${dayjs(vehicleSchedule?.end_date).format('DD MMM BBBB')}`}
            </div>
            <div className="mb-4 text-lg">
              {`${vehicleSchedule?.driver?.employee?.firstname} ${
                vehicleSchedule?.driver?.employee?.lastname || ''
              }`}
            </div>
            <div className="mb-4 text-lg">
              <div>
                {`หัวรถ: ${vehicleSchedule?.vehicle?.licence_number}` || '-'}
              </div>
              <div>
                {`หางรถ: ${vehicleSchedule?.trailer_tail?.licence_number}` ||
                  '-'}
              </div>
              <div>
                {`ตู้คอนเทนเนอร์: ${vehicleSchedule?.container?.licence_number}` ||
                  '-'}
              </div>
            </div>
            <div className="mb-4 text-lg">
              {vehicleSchedule?.seal_number || '-'}
            </div>
            <div className="mb-4 text-lg">{vehicleSchedule?.remark || '-'}</div>
          </div>
        </div>
        <div className="mb-4 text-lg  ">
          <StatusTransaction vehicleSchedule={vehicleSchedule} />
        </div>
        <div className="mt-6 w-full ml-4 flex flex-wrap  grid grid-col-2 gap-1">
          {!_.isEmpty(vehicleSchedule?.delivery_orders) ? (
            _.map(vehicleSchedule?.delivery_orders, (order) => (
              <div className="px-1 mb-1 lg:w-1/2">
                <div className="w-full flex flex-wrap ml-4">
                  <div className="lg:w-1/3">
                    <div className="font-bold">{'เลขที่รายการ: '}</div>
                    <div className="font-bold">{'เลขที่ใบแจ้งหนี้: '}</div>
                    <div className="font-bold">{'วันที่รับสินค้า: '}</div>
                    <div className="font-bold">{'วันที่ส่งสินค้า: '}</div>
                    <div className="font-bold">{'ลูกค้า: '}</div>
                    <div className="font-bold">{'งาน: '}</div>
                    <div className="font-bold">{'สถานที่รับ: '}</div>
                    <div className="font-bold">{'สถานที่ส่ง: '}</div>
                  </div>
                  <div className="w-2/3">
                    {console.log('2', vehicleSchedule)}
                    <div>{order?.prefix + order?.running_number}</div>
                    <div>{order?.invoice_number || '-'}</div>
                    <div>
                      {`${dayjs(order.start_date).format('DD MMM BBBB')}`}
                    </div>
                    <div>
                      {`${dayjs(order.end_date).format('DD MMM BBBB')}`}
                    </div>
                    <div>{order?.customer?.name || '-'}</div>
                    <div>{order?.job?.name || '-'}</div>
                    <div>{order?.origin || '-'}</div>
                    <div>{order?.destination | '-'}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-center"> ไม่มีรายการงาน </div>
          )}
          <div className="">
            <div className="font-bold mb-2 text-lg">ค่าใช้จ่าย</div>
            {/* {_.isEmpty(vehicleSchedule?.transection) ? (
              <div className="flex justify-center"> ไม่มีรายการงาน </div>
            ) : (
              <div>
                {_.map(vehicleSchedule?.transection, (eachdata) => (
                  <Card>
                    <CardContent>
                      <div className="w-full flex flex-wrap ml-4">
                        <div className="lg:w-1/3">
                          <div className="font-bold">{'รายการ: '}</div>
                          <div className="font-bold">{'เงิน: '}</div>
                        </div>
                        <div className="w-2/3">
                          <div>{eachdata?.name}</div>
                          <div>{eachdata?.price || '-'}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )} */}
            <div className="m-9   text-center">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 100 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">ลำดับที่</TableCell>
                      <TableCell align="center">วันที่</TableCell>
                      <TableCell align="center">รายการ</TableCell>
                      <TableCell align="center">เงิน</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(vehicleSchedule?.transection, (eachdata, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {' '}
                          {dayjs(eachdata?.updatedAt).format(
                            'DD/MM/YYYY  HH:mm:ss',
                          )}{' '}
                          {}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {eachdata?.name}
                        </TableCell>
                        <TableCell align="center">
                          {eachdata?.price || '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        รวม
                      </TableCell>
                      <TableCell align="center">
                        {_.sumBy(vehicleSchedule?.transection, (e) => e?.price)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </div>
);

DetailVehicleScheduleForm.propTypes = {
  vehicleSchedule: PropTypes.object,
};

export default DetailVehicleScheduleForm;
